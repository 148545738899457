/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { ClinicSlot, ClinicSlotFromJSON, ClinicSlotFromJSONTyped, ClinicSlotToJSON } from "./";

export const ClinicDateTypename = "ClinicDate" as const;

/**
 *
 * @export
 * @interface ClinicDate
 */
export interface ClinicDate {
  /**
   *
   * @type {string}
   * @memberof ClinicDate
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof ClinicDate
   */
  dayOfWeek: string;
  /**
   *
   * @type {boolean}
   * @memberof ClinicDate
   */
  publicHoliday?: boolean;
  /**
   *
   * @type {Array<ClinicSlot>}
   * @memberof ClinicDate
   */
  slots: Array<ClinicSlot>;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicDate
   */
  readonly __typename?: string;
}

export function ClinicDateFromJSON(json: any): ClinicDate {
  return ClinicDateFromJSONTyped(json, false);
}

export function ClinicDateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicDate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicDateTypename,

    date: json["date"],
    dayOfWeek: json["day_of_week"],
    publicHoliday: !exists(json, "public_holiday") ? undefined : json["public_holiday"],
    slots: (json["slots"] as Array<any>).map(ClinicSlotFromJSON),
  };
}

export function ClinicDateToJSON(object?: ClinicDate | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date: value.date,
    day_of_week: value.dayOfWeek,
    public_holiday: value.publicHoliday,
    slots: (value.slots as Array<any>).map(ClinicSlotToJSON),
  };
}
