/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutMedicineTakenLogRequestBodyTypename = "PutMedicineTakenLogRequestBody" as const;

/**
 *
 * @export
 * @interface PutMedicineTakenLogRequestBody
 */
export interface PutMedicineTakenLogRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutMedicineTakenLogRequestBody
   */
  medicineTakenStatus: PutMedicineTakenLogRequestBodyMedicineTakenStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutMedicineTakenLogRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PutMedicineTakenLogRequestBodyMedicineTakenStatusEnum {
  Taken = "taken",
  Forgotten = "forgotten",
}

export function PutMedicineTakenLogRequestBodyFromJSON(json: any): PutMedicineTakenLogRequestBody {
  return PutMedicineTakenLogRequestBodyFromJSONTyped(json, false);
}

export function PutMedicineTakenLogRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutMedicineTakenLogRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutMedicineTakenLogRequestBodyTypename,

    medicineTakenStatus: json["medicine_taken_status"],
  };
}

export function PutMedicineTakenLogRequestBodyToJSON(object?: PutMedicineTakenLogRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    medicine_taken_status: value.medicineTakenStatus,
  };
}
