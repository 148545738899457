import parseDate from "@mgdx/shared/src/utils/parseDate";
import { TalkMessageDocument } from "@mgdx/talk/models";
import Nl2Br from "@mgdx/ui/components/Nl2Br";
import clsx from "clsx";
import React from "react";

import TalkAvatar from "../TalkAvatar";
import * as styles from "./ReceivedMessage.module.css";
import * as mainStyles from "./TalkMessage.module.css";
import { TalkMessageImage } from "./TalkMessageImage";

export type ReceivedMessageProps = {
  message: TalkMessageDocument;
  isFirstMessage?: boolean;
  isLastMessage?: boolean;
};

export const ReceivedMessage: React.FC<ReceivedMessageProps> = ({ message, isFirstMessage, isLastMessage }) => {
  return (
    <div
      className={clsx(
        styles.receivedMessage,
        isFirstMessage && mainStyles.isFirstMessage,
        isFirstMessage && styles.isFirstMessage,
        isLastMessage && styles.isLastMessage
      )}
    >
      {isLastMessage && (
        <div className={styles.avatar}>
          <TalkAvatar senderType={message.senderType} />
        </div>
      )}

      <div className={styles.content}>
        {message.body.image ? (
          <TalkMessageImage image={message.body.image} />
        ) : (
          <div className={clsx(mainStyles.balloon, styles.balloon)}>
            <p className={mainStyles.balloonText}>
              <Nl2Br body={message.body.content} />
            </p>
          </div>
        )}
      </div>

      {isLastMessage && (
        <div className={clsx(mainStyles.timestamp, styles.timestamp)}>{parseDate(message.sentAt)?.format("HH:mm")}</div>
      )}
    </div>
  );
};
