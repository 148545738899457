/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicDoctorUpdatableTypename = "ClinicDoctorUpdatable" as const;

/**
 *
 * @export
 * @interface ClinicDoctorUpdatable
 */
export interface ClinicDoctorUpdatable {
  /**
   *
   * @type {string}
   * @memberof ClinicDoctorUpdatable
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicDoctorUpdatable
   */
  lastName?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicDoctorUpdatable
   */
  readonly __typename?: string;
}

export function ClinicDoctorUpdatableFromJSON(json: any): ClinicDoctorUpdatable {
  return ClinicDoctorUpdatableFromJSONTyped(json, false);
}

export function ClinicDoctorUpdatableFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicDoctorUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicDoctorUpdatableTypename,

    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
  };
}

export function ClinicDoctorUpdatableToJSON(object?: ClinicDoctorUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    first_name: value.firstName,
    last_name: value.lastName,
  };
}
