/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyPharmacist,
  PharmacyPharmacistFromJSON,
  PharmacyPharmacistFromJSONTyped,
  PharmacyPharmacistToJSON,
} from "./";

export const PharmacyPharmacistsTypename = "PharmacyPharmacists" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacists
 */
export interface PharmacyPharmacists {
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacists
   */
  count?: number;
  /**
   *
   * @type {Array<PharmacyPharmacist>}
   * @memberof PharmacyPharmacists
   */
  pharmacists: Array<PharmacyPharmacist>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacists
   */
  readonly __typename?: string;
}

export function PharmacyPharmacistsFromJSON(json: any): PharmacyPharmacists {
  return PharmacyPharmacistsFromJSONTyped(json, false);
}

export function PharmacyPharmacistsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPharmacists {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacistsTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    pharmacists: (json["pharmacists"] as Array<any>).map(PharmacyPharmacistFromJSON),
  };
}

export function PharmacyPharmacistsToJSON(object?: PharmacyPharmacists | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    pharmacists: (value.pharmacists as Array<any>).map(PharmacyPharmacistToJSON),
  };
}
