/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicCounselorCounselingReportTypename = "ClinicCounselorCounselingReport" as const;

/**
 *
 * @export
 * @interface ClinicCounselorCounselingReport
 */
export interface ClinicCounselorCounselingReport {
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorCounselingReport
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorCounselingReport
   */
  deliveryCost?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  departmentName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  insuranceCoverage: string;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorCounselingReport
   */
  insuranceMedicalExpense?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  kana: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  managementNo: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorCounselingReport
   */
  ownExpense?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  time: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  type: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorCounselingReport
   */
  readonly __typename?: string;
}

export function ClinicCounselorCounselingReportFromJSON(json: any): ClinicCounselorCounselingReport {
  return ClinicCounselorCounselingReportFromJSONTyped(json, false);
}

export function ClinicCounselorCounselingReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClinicCounselorCounselingReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorCounselingReportTypename,

    amount: !exists(json, "amount") ? undefined : json["amount"],
    deliveryCost: !exists(json, "delivery_cost") ? undefined : json["delivery_cost"],
    departmentName: json["department_name"],
    insuranceCoverage: json["insurance_coverage"],
    insuranceMedicalExpense: !exists(json, "insurance_medical_expense") ? undefined : json["insurance_medical_expense"],
    kana: json["kana"],
    managementNo: json["management_no"],
    name: json["name"],
    ownExpense: !exists(json, "own_expense") ? undefined : json["own_expense"],
    status: json["status"],
    time: json["time"],
    type: json["type"],
  };
}

export function ClinicCounselorCounselingReportToJSON(object?: ClinicCounselorCounselingReport | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    amount: value.amount,
    delivery_cost: value.deliveryCost,
    department_name: value.departmentName,
    insurance_coverage: value.insuranceCoverage,
    insurance_medical_expense: value.insuranceMedicalExpense,
    kana: value.kana,
    management_no: value.managementNo,
    name: value.name,
    own_expense: value.ownExpense,
    status: value.status,
    time: value.time,
    type: value.type,
  };
}
