import "react-toastify/dist/ReactToastify.css";
import "./Toast.css";

import { logger } from "@mgdx-libs/logger";
import React from "react";
import { Id, toast, ToastContainer as ReactToastContainer, ToastContent, ToastOptions } from "react-toastify";

/**
 * @see https://fkhadra.github.io/react-toastify/api/toast-container
 */
export const ToastContainer = () => <ReactToastContainer position="top-center" hideProgressBar={true} icon={false} />;

type Notify = (
  toastId: Required<ToastOptions>["toastId"],
  content: ToastContent,
  options?: ToastOptions
) => ReturnType<typeof toast.info>;

const notify: Notify = (toastId, content, options) => {
  let resultId: Id = "";

  if (toast.isActive(toastId)) {
    logger.debug(`${toastId} is active.`);
  } else {
    resultId = toast.success(content, {
      ...options,
      toastId,
      onClose: () => {
        toast.dismiss(toastId);
      },
    });
  }

  toast.clearWaitingQueue();

  return resultId;
};

export const toastSuccess = (content: ToastContent, options?: ToastOptions) =>
  notify("toastSuccess", content, {
    ...options,
    type: "success",
  });

export const toastInfo = (content: ToastContent, options?: ToastOptions) =>
  notify("toastInfo", content, {
    ...options,
    type: "info",
  });

export const toastNotice = (content: ToastContent, options?: ToastOptions) =>
  notify("toastNotice", content, {
    ...options,
    type: "warning",
  });

export const toastError = (content: ToastContent, options?: ToastOptions) =>
  notify("toastError", content, {
    ...options,
    type: "error",
  });

export { toast };
