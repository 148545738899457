/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import { PatientMCCMJAHISSyncJob, PatientMCCMJAHISSyncJobFromJSON, PatientMCCMJAHISSyncJobToJSON } from "../models";

export interface RegisterMCCMSyncMedicineNotebookJAHISJobRequest {
  chainId: number;
}

/**
 *
 */
export class MccmJahisSyncJobApi extends runtime.BaseAPI {
  /**
   * Register MCCM sync medicine notebook JAHIS job
   */
  async registerMCCMSyncMedicineNotebookJAHISJobRaw(
    requestParameters: RegisterMCCMSyncMedicineNotebookJAHISJobRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMCCMJAHISSyncJob>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling registerMCCMSyncMedicineNotebookJAHISJob."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/mccm-jahis-sync-jobs`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMCCMJAHISSyncJobFromJSON(jsonValue));
  }

  /**
   * Register MCCM sync medicine notebook JAHIS job
   */
  async registerMCCMSyncMedicineNotebookJAHISJob(
    requestParameters: RegisterMCCMSyncMedicineNotebookJAHISJobRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMCCMJAHISSyncJob> {
    return promiseRetry(
      (retry) =>
        this.registerMCCMSyncMedicineNotebookJAHISJobRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
