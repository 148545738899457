/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostMedicineNotebookAutoLinkRequestBodyTypename = "PostMedicineNotebookAutoLinkRequestBody" as const;

/**
 *
 * @export
 * @interface PostMedicineNotebookAutoLinkRequestBody
 */
export interface PostMedicineNotebookAutoLinkRequestBody {
  /**
   *
   * @type {number}
   * @memberof PostMedicineNotebookAutoLinkRequestBody
   */
  familyAccountId?: number;
  /**
   *
   * @type {number}
   * @memberof PostMedicineNotebookAutoLinkRequestBody
   */
  pharmacyId: number;
  /**
   *
   * @type {string}
   * @memberof PostMedicineNotebookAutoLinkRequestBody
   */
  requestType?: PostMedicineNotebookAutoLinkRequestBodyRequestTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostMedicineNotebookAutoLinkRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PostMedicineNotebookAutoLinkRequestBodyRequestTypeEnum {
  Web = "web",
  Line = "line",
}

export function PostMedicineNotebookAutoLinkRequestBodyFromJSON(json: any): PostMedicineNotebookAutoLinkRequestBody {
  return PostMedicineNotebookAutoLinkRequestBodyFromJSONTyped(json, false);
}

export function PostMedicineNotebookAutoLinkRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostMedicineNotebookAutoLinkRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostMedicineNotebookAutoLinkRequestBodyTypename,

    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    pharmacyId: json["pharmacy_id"],
    requestType: !exists(json, "request_type") ? undefined : json["request_type"],
  };
}

export function PostMedicineNotebookAutoLinkRequestBodyToJSON(
  object?: PostMedicineNotebookAutoLinkRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    family_account_id: value.familyAccountId,
    pharmacy_id: value.pharmacyId,
    request_type: value.requestType,
  };
}
