/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyControlPanelPharmacyTypename = "PharmacyControlPanelPharmacy" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanelPharmacy
 */
export interface PharmacyControlPanelPharmacy {
  /**
   *
   * @type {number}
   * @memberof PharmacyControlPanelPharmacy
   */
  groupId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanelPharmacy
   */
  groupName?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyControlPanelPharmacy
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanelPharmacy
   */
  name?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanelPharmacy
   */
  readonly __typename?: string;
}

export function PharmacyControlPanelPharmacyFromJSON(json: any): PharmacyControlPanelPharmacy {
  return PharmacyControlPanelPharmacyFromJSONTyped(json, false);
}

export function PharmacyControlPanelPharmacyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyControlPanelPharmacy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelPharmacyTypename,

    groupId: !exists(json, "group_id") ? undefined : json["group_id"],
    groupName: !exists(json, "group_name") ? undefined : json["group_name"],
    id: json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function PharmacyControlPanelPharmacyToJSON(object?: PharmacyControlPanelPharmacy | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    group_id: value.groupId,
    group_name: value.groupName,
    id: value.id,
    name: value.name,
  };
}
