import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import { MedicineNotebookAccountRequestConfig } from "../providers/MedicineNotebookAccountProvider";

export const medicineNotebookContentKeys = createQueryKeyStore({
  patientOnetimeCode: {
    detail: (requestConfig: MedicineNotebookAccountRequestConfig) => ["patientOnetimeCode", requestConfig],
  },
  dispenseRecords: {
    list: (requestConfig: MedicineNotebookAccountRequestConfig) => [requestConfig],
    detail: (requestConfig: MedicineNotebookAccountRequestConfig, dispenseRecordId: number) => [
      requestConfig,
      dispenseRecordId,
    ],
    master: (requestConfig: MedicineNotebookAccountRequestConfig, yjCode: string) => [requestConfig, yjCode],
  },
  otcMedicines: {
    list: (requestConfig: MedicineNotebookAccountRequestConfig) => [requestConfig],
    detail: (requestConfig: MedicineNotebookAccountRequestConfig, otcMedicineId: number) => [
      requestConfig,
      otcMedicineId,
    ],
  },
  medicationReconciliation: {
    detail: (requestConfig: MedicineNotebookAccountRequestConfig) => [requestConfig],
  },
  medicationReconciliationMaster: {
    detail: (requestConfig: MedicineNotebookAccountRequestConfig) => [requestConfig.chainId],
  },
});
