/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorPublicCrowdStatusTypename = "PharmacyCounselorPublicCrowdStatus" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorPublicCrowdStatus
 */
export interface PharmacyCounselorPublicCrowdStatus {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorPublicCrowdStatus
   */
  pharmacyId?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorPublicCrowdStatus
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPublicCrowdStatus
   */
  unitType: PharmacyCounselorPublicCrowdStatusUnitTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorPublicCrowdStatus
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorPublicCrowdStatusUnitTypeEnum {
  People = "people",
  Minute = "minute",
}

export function PharmacyCounselorPublicCrowdStatusFromJSON(json: any): PharmacyCounselorPublicCrowdStatus {
  return PharmacyCounselorPublicCrowdStatusFromJSONTyped(json, false);
}

export function PharmacyCounselorPublicCrowdStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorPublicCrowdStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorPublicCrowdStatusTypename,

    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
    quantity: !exists(json, "quantity") ? undefined : json["quantity"],
    unitType: json["unit_type"],
  };
}

export function PharmacyCounselorPublicCrowdStatusToJSON(object?: PharmacyCounselorPublicCrowdStatus | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    pharmacy_id: value.pharmacyId,
    quantity: value.quantity,
    unit_type: value.unitType,
  };
}
