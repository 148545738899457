/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicMeritTags,
  ClinicMeritTagsFromJSON,
  ClinicMeritTagsFromJSONTyped,
  ClinicMeritTagsToJSON,
  ClinicSNS,
  ClinicSNSFromJSON,
  ClinicSNSFromJSONTyped,
  ClinicSNSToJSON,
  ClinicTokushoho,
  ClinicTokushohoFromJSON,
  ClinicTokushohoFromJSONTyped,
  ClinicTokushohoToJSON,
} from "./";

export const ClinicClinicUpdatableTypename = "ClinicClinicUpdatable" as const;

/**
 *
 * @export
 * @interface ClinicClinicUpdatable
 */
export interface ClinicClinicUpdatable {
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  address?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ClinicClinicUpdatable
   */
  alliancePharmacyIds?: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicUpdatable
   */
  businessHours?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicUpdatable
   */
  clinicalDepartments?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicUpdatable
   */
  closedDays?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  fax?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  homepageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  imageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  institutionCode?: string;
  /**
   *
   * @type {number}
   * @memberof ClinicClinicUpdatable
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof ClinicClinicUpdatable
   */
  longitude?: number;
  /**
   *
   * @type {ClinicMeritTags}
   * @memberof ClinicClinicUpdatable
   */
  meritTag?: ClinicMeritTags;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicUpdatable
   */
  nearestStations?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  note?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicUpdatable
   */
  onlinePaymentMethods?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicUpdatable
   */
  paymentMethods?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  recommendation?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClinicClinicUpdatable
   */
  screeningStatus?: boolean;
  /**
   *
   * @type {ClinicSNS}
   * @memberof ClinicClinicUpdatable
   */
  sns?: ClinicSNS;
  /**
   *
   * @type {number}
   * @memberof ClinicClinicUpdatable
   */
  talkroomExpireDays?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  tel?: string;
  /**
   *
   * @type {ClinicTokushoho}
   * @memberof ClinicClinicUpdatable
   */
  tokushoho?: ClinicTokushoho;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  visibility?: ClinicClinicUpdatableVisibilityEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicClinicUpdatable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ClinicClinicUpdatableVisibilityEnum {
  Public = "public",
  Private = "private",
  Limited = "limited",
}

export function ClinicClinicUpdatableFromJSON(json: any): ClinicClinicUpdatable {
  return ClinicClinicUpdatableFromJSONTyped(json, false);
}

export function ClinicClinicUpdatableFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicClinicUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicClinicUpdatableTypename,

    address: !exists(json, "address") ? undefined : json["address"],
    alliancePharmacyIds: !exists(json, "alliance_pharmacy_ids") ? undefined : json["alliance_pharmacy_ids"],
    businessHours: !exists(json, "business_hours") ? undefined : json["business_hours"],
    clinicalDepartments: !exists(json, "clinical_departments") ? undefined : json["clinical_departments"],
    closedDays: !exists(json, "closed_days") ? undefined : json["closed_days"],
    email: !exists(json, "email") ? undefined : json["email"],
    fax: !exists(json, "fax") ? undefined : json["fax"],
    homepageUrl: !exists(json, "homepage_url") ? undefined : json["homepage_url"],
    imageUrl: !exists(json, "image_url") ? undefined : json["image_url"],
    institutionCode: !exists(json, "institution_code") ? undefined : json["institution_code"],
    latitude: !exists(json, "latitude") ? undefined : json["latitude"] == "" ? undefined : json["latitude"],
    longitude: !exists(json, "longitude") ? undefined : json["longitude"] == "" ? undefined : json["longitude"],
    meritTag: !exists(json, "merit_tag") ? undefined : ClinicMeritTagsFromJSON(json["merit_tag"]),
    name: !exists(json, "name") ? undefined : json["name"],
    nearestStations: !exists(json, "nearest_stations") ? undefined : json["nearest_stations"],
    note: !exists(json, "note") ? undefined : json["note"],
    onlinePaymentMethods: !exists(json, "online_payment_methods") ? undefined : json["online_payment_methods"],
    paymentMethods: !exists(json, "payment_methods") ? undefined : json["payment_methods"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    recommendation: !exists(json, "recommendation") ? undefined : json["recommendation"],
    screeningStatus: !exists(json, "screening_status") ? undefined : json["screening_status"],
    sns: !exists(json, "sns") ? undefined : ClinicSNSFromJSON(json["sns"]),
    talkroomExpireDays: !exists(json, "talkroom_expire_days") ? undefined : json["talkroom_expire_days"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
    tokushoho: !exists(json, "tokushoho") ? undefined : ClinicTokushohoFromJSON(json["tokushoho"]),
    visibility: !exists(json, "visibility") ? undefined : json["visibility"],
  };
}

export function ClinicClinicUpdatableToJSON(object?: ClinicClinicUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address: value.address,
    alliance_pharmacy_ids: value.alliancePharmacyIds,
    business_hours: value.businessHours,
    clinical_departments: value.clinicalDepartments,
    closed_days: value.closedDays,
    email: value.email,
    fax: value.fax,
    homepage_url: value.homepageUrl,
    image_url: value.imageUrl,
    institution_code: value.institutionCode,
    latitude: (value.latitude as unknown as string) == "" ? undefined : value.latitude,
    longitude: (value.longitude as unknown as string) == "" ? undefined : value.longitude,
    merit_tag: ClinicMeritTagsToJSON(value.meritTag),
    name: value.name,
    nearest_stations: value.nearestStations,
    note: value.note,
    online_payment_methods: value.onlinePaymentMethods,
    payment_methods: value.paymentMethods,
    postal_code: value.postalCode,
    recommendation: value.recommendation,
    screening_status: value.screeningStatus,
    sns: ClinicSNSToJSON(value.sns),
    talkroom_expire_days: value.talkroomExpireDays,
    tel: value.tel,
    tokushoho: ClinicTokushohoToJSON(value.tokushoho),
    visibility: value.visibility,
  };
}
