import { PharmacyChain } from "@mgdx/api/lib/pharmacy/pharmacy";
import pharmacyApi from "@mgdx/api/lib/pharmacyApi";
import pharmacyChainApi from "@mgdx/api/lib/pharmacyChainApi";
import { Pharmacy } from "@mgdx/shared/src/models/Pharmacy";
import { createRequestAction } from "@mgdx-libs/redux-requests";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";

export const findPharmacyById = createRequestAction<{ pharmacyId: string }, Pharmacy>(
  "requests/counseling/findPharmacyById",
  ({ pharmacyId }, meta) => ({
    request: {
      promise: pharmacyApi.getPharmacy({
        pharmacyId: toInteger(pharmacyId),
      }),
    },
    meta: {
      requestKey: pharmacyId,
      ...meta,
    },
  })
);

export const findPharmaciesByIds = createRequestAction<{ pharmacyIds: number[] }, Pharmacy[]>(
  "requests/pharmacy/findPharmaciesByIds",
  ({ pharmacyIds }, meta) => ({
    request: {
      promise: Promise.all(
        pharmacyIds.map((pharmacyId) =>
          pharmacyApi.getPharmacy({
            pharmacyId,
          })
        )
      ),
    },
    meta: {
      requestsCapacity: 3,
      requestKey: pharmacyIds.join(","),
      ...meta,
    },
  })
);

export const findPharmacyChainById = createRequestAction<{ chainId: number }, PharmacyChain>(
  "requests/pharmacy/findPharmacyChainById",
  ({ chainId }, meta) => ({
    request: {
      promise: pharmacyChainApi.getChain({
        chainId,
      }),
    },
    meta: {
      requestsCapacity: 3,
      requestKey: toString(chainId),
      ...meta,
    },
  })
);

export const findPharmacyChainsByIds = createRequestAction<{ chainIds: number[] }, PharmacyChain[]>(
  "requests/pharmacy/findPharmacyChainsByIds",
  ({ chainIds }, meta) => ({
    request: {
      promise: Promise.all(
        chainIds.map((chainId) =>
          pharmacyChainApi.getChain({
            chainId,
          })
        )
      ),
    },
    meta: {
      requestsCapacity: 3,
      requestKey: chainIds.join(","),
      ...meta,
    },
  })
);

export const allPharmacyChains = createRequestAction<void, PharmacyChain[]>(
  "requests/pharmacy/allPharmacyChains",
  (_, meta) => {
    return {
      request: {
        promise: pharmacyChainApi.getAllChain({}),
      },
      meta,
    };
  }
);

export const fetchChainFromAppName = createRequestAction<{ appName?: string }, PharmacyChain[]>(
  "requests/pharmacy/fetchChainFromAppName",
  ({ appName }, meta) => {
    return {
      request: {
        promise: pharmacyChainApi.getAllChain({ appName: appName }),
      },
      meta,
    };
  }
);
