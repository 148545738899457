import clsx from "clsx";
import React from "react";

import { Size, Variant, WidthType } from "./OutlinedButton";
import * as styles from "./OutlinedButton.module.css";

export type OutlinedExternalLinkButtonProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "title"> & {
  variant: `${Variant}`;
  widthType?: `${WidthType}`;
  title: string;
  size?: `${Size}`;
  multiline?: boolean;
  disabled?: boolean;
  loading?: boolean;
  variableClassName?: string;
};

export const OutlinedExternalButton: React.FC<OutlinedExternalLinkButtonProps> = ({
  variant,
  widthType = WidthType.FitContent,
  size = Size.M,
  multiline,
  disabled,
  loading,
  children,
  className,
  target = "_blank",
  title = "",
  rel = "noopener noreferrer",
  variableClassName,
  ...props
}) => (
  <div
    className={clsx(
      styles.base,
      variant === Variant.Key && styles.key,
      variant === Variant.Link && styles.link,
      variant === Variant.Weak && styles.weak,
      variant === Variant.Variable && variableClassName,
      widthType === WidthType.FitContent && styles.fitContent,
      widthType === WidthType.FullWidth && styles.fullWidth,
      size === Size.S && styles.sizeS,
      size === Size.M && styles.sizeM,
      size === Size.L && styles.sizeL,
      multiline && styles.multiline,
      disabled && styles.disabled,
      className
    )}
  >
    <a
      {...props}
      title={`${title}(別ウィンドウで開きます)`}
      target={target}
      rel={rel}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : undefined}
      className={styles.button}
    >
      {children}
    </a>
  </div>
);
