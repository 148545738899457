/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PayerCommonShopUpdatableTypename = "PayerCommonShopUpdatable" as const;

/**
 *
 * @export
 * @interface PayerCommonShopUpdatable
 */
export interface PayerCommonShopUpdatable {
  /**
   *
   * @type {number}
   * @memberof PayerCommonShopUpdatable
   */
  chainId?: number;
  /**
   *
   * @type {string}
   * @memberof PayerCommonShopUpdatable
   */
  gmoShopId?: string;
  /**
   *
   * @type {number}
   * @memberof PayerCommonShopUpdatable
   */
  groupId?: number;
  /**
   *
   * @type {string}
   * @memberof PayerCommonShopUpdatable
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PayerCommonShopUpdatable
   */
  serviceId?: string;
  /**
   *
   * @type {string}
   * @memberof PayerCommonShopUpdatable
   */
  status?: PayerCommonShopUpdatableStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PayerCommonShopUpdatable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PayerCommonShopUpdatableStatusEnum {
  Enabled = "enabled",
  Disabled = "disabled",
}

export function PayerCommonShopUpdatableFromJSON(json: any): PayerCommonShopUpdatable {
  return PayerCommonShopUpdatableFromJSONTyped(json, false);
}

export function PayerCommonShopUpdatableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayerCommonShopUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PayerCommonShopUpdatableTypename,

    chainId: !exists(json, "chain_id") ? undefined : json["chain_id"],
    gmoShopId: !exists(json, "gmo_shop_id") ? undefined : json["gmo_shop_id"],
    groupId: !exists(json, "group_id") ? undefined : json["group_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    serviceId: !exists(json, "service_id") ? undefined : json["service_id"],
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function PayerCommonShopUpdatableToJSON(object?: PayerCommonShopUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    gmo_shop_id: value.gmoShopId,
    group_id: value.groupId,
    name: value.name,
    service_id: value.serviceId,
    status: value.status,
  };
}
