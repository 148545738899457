/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientPharmacyVerifyEmailOptionTypename = "PatientPharmacyVerifyEmailOption" as const;

/**
 *
 * @export
 * @interface PatientPharmacyVerifyEmailOption
 */
export interface PatientPharmacyVerifyEmailOption {
  /**
   *
   * @type {number}
   * @memberof PatientPharmacyVerifyEmailOption
   */
  pharmacyId?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PatientPharmacyVerifyEmailOption
   */
  preferredDates?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientPharmacyVerifyEmailOption
   */
  readonly __typename?: string;
}

export function PatientPharmacyVerifyEmailOptionFromJSON(json: any): PatientPharmacyVerifyEmailOption {
  return PatientPharmacyVerifyEmailOptionFromJSONTyped(json, false);
}

export function PatientPharmacyVerifyEmailOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientPharmacyVerifyEmailOption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientPharmacyVerifyEmailOptionTypename,

    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
    preferredDates: !exists(json, "preferred_dates") ? undefined : json["preferred_dates"],
  };
}

export function PatientPharmacyVerifyEmailOptionToJSON(object?: PatientPharmacyVerifyEmailOption | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    pharmacy_id: value.pharmacyId,
    preferred_dates: value.preferredDates,
  };
}
