/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorInterviewQuestionnaireTypename = "PharmacyCounselorInterviewQuestionnaire" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorInterviewQuestionnaire
 */
export interface PharmacyCounselorInterviewQuestionnaire {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewQuestionnaire
   */
  answer?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorInterviewQuestionnaire
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewQuestionnaire
   */
  question: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorInterviewQuestionnaire
   */
  readonly __typename?: string;
}

export function PharmacyCounselorInterviewQuestionnaireFromJSON(json: any): PharmacyCounselorInterviewQuestionnaire {
  return PharmacyCounselorInterviewQuestionnaireFromJSONTyped(json, false);
}

export function PharmacyCounselorInterviewQuestionnaireFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorInterviewQuestionnaire {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorInterviewQuestionnaireTypename,

    answer: !exists(json, "answer") ? undefined : json["answer"],
    id: !exists(json, "id") ? undefined : json["id"],
    question: json["question"],
  };
}

export function PharmacyCounselorInterviewQuestionnaireToJSON(
  object?: PharmacyCounselorInterviewQuestionnaire | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer: value.answer,
    id: value.id,
    question: value.question,
  };
}
