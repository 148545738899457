/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutPharmacyLogoImageResponseTypename = "PutPharmacyLogoImageResponse" as const;

/**
 *
 * @export
 * @interface PutPharmacyLogoImageResponse
 */
export interface PutPharmacyLogoImageResponse {
  /**
   *
   * @type {string}
   * @memberof PutPharmacyLogoImageResponse
   */
  logoImageUrl?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutPharmacyLogoImageResponse
   */
  readonly __typename?: string;
}

export function PutPharmacyLogoImageResponseFromJSON(json: any): PutPharmacyLogoImageResponse {
  return PutPharmacyLogoImageResponseFromJSONTyped(json, false);
}

export function PutPharmacyLogoImageResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutPharmacyLogoImageResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutPharmacyLogoImageResponseTypename,

    logoImageUrl: !exists(json, "logo_image_url") ? undefined : json["logo_image_url"],
  };
}

export function PutPharmacyLogoImageResponseToJSON(object?: PutPharmacyLogoImageResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    logo_image_url: value.logoImageUrl,
  };
}
