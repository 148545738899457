/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyPharmacyListImage,
  PharmacyPharmacyListImageFromJSON,
  PharmacyPharmacyListImageFromJSONTyped,
  PharmacyPharmacyListImageToJSON,
} from "./";

export const PostPharmacyListImagesResponseTypename = "PostPharmacyListImagesResponse" as const;

/**
 *
 * @export
 * @interface PostPharmacyListImagesResponse
 */
export interface PostPharmacyListImagesResponse {
  /**
   *
   * @type {Array<PharmacyPharmacyListImage>}
   * @memberof PostPharmacyListImagesResponse
   */
  listImage?: Array<PharmacyPharmacyListImage>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostPharmacyListImagesResponse
   */
  readonly __typename?: string;
}

export function PostPharmacyListImagesResponseFromJSON(json: any): PostPharmacyListImagesResponse {
  return PostPharmacyListImagesResponseFromJSONTyped(json, false);
}

export function PostPharmacyListImagesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostPharmacyListImagesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostPharmacyListImagesResponseTypename,

    listImage: !exists(json, "list_image")
      ? undefined
      : (json["list_image"] as Array<any>).map(PharmacyPharmacyListImageFromJSON),
  };
}

export function PostPharmacyListImagesResponseToJSON(object?: PostPharmacyListImagesResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    list_image:
      value.listImage === undefined ? undefined : (value.listImage as Array<any>).map(PharmacyPharmacyListImageToJSON),
  };
}
