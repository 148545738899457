/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyDeliverySettingsTypename = "PharmacyPharmacyDeliverySettings" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyDeliverySettings
 */
export interface PharmacyPharmacyDeliverySettings {
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyDeliverySettings
   */
  area?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyDeliverySettings
   */
  providers?: Array<PharmacyPharmacyDeliverySettingsProvidersEnum>;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyDeliverySettings
   */
  usableRegisterDeliveryOnly?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyDeliverySettings
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyPharmacyDeliverySettingsProvidersEnum {
  Cbcloud = "cbcloud",
  Wolt = "wolt",
  Yamato = "yamato",
  YamatoStandard = "yamato_standard",
}

export function PharmacyPharmacyDeliverySettingsFromJSON(json: any): PharmacyPharmacyDeliverySettings {
  return PharmacyPharmacyDeliverySettingsFromJSONTyped(json, false);
}

export function PharmacyPharmacyDeliverySettingsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPharmacyDeliverySettings {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyDeliverySettingsTypename,

    area: !exists(json, "area") ? undefined : json["area"],
    providers: !exists(json, "providers") ? undefined : json["providers"],
    usableRegisterDeliveryOnly: !exists(json, "usable_register_delivery_only")
      ? undefined
      : json["usable_register_delivery_only"],
  };
}

export function PharmacyPharmacyDeliverySettingsToJSON(object?: PharmacyPharmacyDeliverySettings | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    area: value.area,
    providers: value.providers,
    usable_register_delivery_only: value.usableRegisterDeliveryOnly,
  };
}
