/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientDispenseRecord,
  PatientDispenseRecordFromJSON,
  PatientDispenseRecordFromJSONTyped,
  PatientDispenseRecordToJSON,
} from "./";

export const PatientDispenseRecordsTypename = "PatientDispenseRecords" as const;

/**
 *
 * @export
 * @interface PatientDispenseRecords
 */
export interface PatientDispenseRecords {
  /**
   *
   * @type {Array<PatientDispenseRecord>}
   * @memberof PatientDispenseRecords
   */
  dispenseRecords?: Array<PatientDispenseRecord>;
  /**
   *
   * @type {number}
   * @memberof PatientDispenseRecords
   */
  totalCount?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientDispenseRecords
   */
  readonly __typename?: string;
}

export function PatientDispenseRecordsFromJSON(json: any): PatientDispenseRecords {
  return PatientDispenseRecordsFromJSONTyped(json, false);
}

export function PatientDispenseRecordsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientDispenseRecords {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientDispenseRecordsTypename,

    dispenseRecords: !exists(json, "dispense_records")
      ? undefined
      : (json["dispense_records"] as Array<any>).map(PatientDispenseRecordFromJSON),
    totalCount: !exists(json, "total_count") ? undefined : json["total_count"],
  };
}

export function PatientDispenseRecordsToJSON(object?: PatientDispenseRecords | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    dispense_records:
      value.dispenseRecords === undefined
        ? undefined
        : (value.dispenseRecords as Array<any>).map(PatientDispenseRecordToJSON),
    total_count: value.totalCount,
  };
}
