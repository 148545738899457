/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostMedicineTakenLogRequestBodyTypename = "PostMedicineTakenLogRequestBody" as const;

/**
 *
 * @export
 * @interface PostMedicineTakenLogRequestBody
 */
export interface PostMedicineTakenLogRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostMedicineTakenLogRequestBody
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof PostMedicineTakenLogRequestBody
   */
  medicineTakenStatus: PostMedicineTakenLogRequestBodyMedicineTakenStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostMedicineTakenLogRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PostMedicineTakenLogRequestBodyMedicineTakenStatusEnum {
  Taken = "taken",
  Forgotten = "forgotten",
}

export function PostMedicineTakenLogRequestBodyFromJSON(json: any): PostMedicineTakenLogRequestBody {
  return PostMedicineTakenLogRequestBodyFromJSONTyped(json, false);
}

export function PostMedicineTakenLogRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostMedicineTakenLogRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostMedicineTakenLogRequestBodyTypename,

    date: json["date"],
    medicineTakenStatus: json["medicine_taken_status"],
  };
}

export function PostMedicineTakenLogRequestBodyToJSON(object?: PostMedicineTakenLogRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date: value.date,
    medicine_taken_status: value.medicineTakenStatus,
  };
}
