export const ZendeskArticleTypename = "ZendeskArticle" as const;

/**
 *
 * @export
 * @interface ZendeskArticle
 * @see https://developer.zendesk.com/api-reference/help_center/help-center-api/articles/#json-format
 */
export interface ZendeskArticle {
  /**
   *
   * @type {number}
   * @memberof ZendeskArticle
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ZendeskArticle
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof ZendeskArticle
   */
  htmlUrl: string;
  /**
   *
   * @type {number}
   * @memberof ZendeskArticle
   */
  authorId: number;
  /**
   *
   * @type {boolean}
   * @memberof ZendeskArticle
   */
  commentsDisabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ZendeskArticle
   */
  draft: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ZendeskArticle
   */
  promoted: boolean;
  /**
   *
   * @type {number}
   * @memberof ZendeskArticle
   */
  position: number;
  /**
   *
   * @type {number}
   * @memberof ZendeskArticle
   */
  voteSum: number;
  /**
   *
   * @type {number}
   * @memberof ZendeskArticle
   */
  voteCount: number;
  /**
   *
   * @type {number}
   * @memberof ZendeskArticle
   */
  sectionId: number;
  /**
   *
   * @type {string}
   * @memberof ZendeskArticle
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ZendeskArticle
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof ZendeskArticle
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ZendeskArticle
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof ZendeskArticle
   */
  editedAt: string;
  /**
   *
   * @type {number}
   * @memberof ZendeskArticle
   */
  permissionGroupId: number;
  /**
   *
   * @type {Array<string>;}
   * @memberof ZendeskArticle
   */
  labelNames: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ZendeskArticle
   */
  body: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskArticle
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskArticleFromJSON(json: any): ZendeskArticle {
  return ZendeskArticleFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskArticle {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ZendeskArticleTypename,
    id: json["id"],
    url: json["url"],
    htmlUrl: json["html_url"],
    authorId: json["author_id"],
    commentsDisabled: json["comments_disabled"],
    draft: json["draft"],
    promoted: json["promoted"],
    position: json["position"],
    voteSum: json["vote_sum"],
    voteCount: json["vote_count"],
    sectionId: json["section_id"],
    createdAt: json["created_at"],
    updatedAt: json["updated_at"],
    name: json["name"],
    title: json["title"],
    editedAt: json["edited_at"],
    permissionGroupId: json["permission_group_id"],
    labelNames: json["label_names"],
    body: json["body"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskArticleToJSON(object?: ZendeskArticle | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    url: value.url,
    html_url: value.htmlUrl,
    author_id: value.authorId,
    comments_disabled: value.commentsDisabled,
    draft: value.draft,
    promoted: value.promoted,
    position: value.position,
    vote_sum: value.voteSum,
    vote_count: value.voteCount,
    section_id: value.sectionId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    name: value.name,
    title: value.title,
    edited_at: value.editedAt,
    permission_group_id: value.permissionGroupId,
    label_names: value.labelNames,
    body: value.body,
  };
}
