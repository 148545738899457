/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicExpenseTypename = "ClinicExpense" as const;

/**
 *
 * @export
 * @interface ClinicExpense
 */
export interface ClinicExpense {
  /**
   * medical expenses are covered by insurance
   * @type {boolean}
   * @memberof ClinicExpense
   */
  insurance?: boolean;
  /**
   * medical expenses are payed their own
   * @type {boolean}
   * @memberof ClinicExpense
   */
  own?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicExpense
   */
  readonly __typename?: string;
}

export function ClinicExpenseFromJSON(json: any): ClinicExpense {
  return ClinicExpenseFromJSONTyped(json, false);
}

export function ClinicExpenseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicExpense {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicExpenseTypename,

    insurance: !exists(json, "insurance") ? undefined : json["insurance"],
    own: !exists(json, "own") ? undefined : json["own"],
  };
}

export function ClinicExpenseToJSON(object?: ClinicExpense | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    insurance: value.insurance,
    own: value.own,
  };
}
