/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicCounselorRequestTime,
  ClinicCounselorRequestTimeFromJSON,
  ClinicCounselorRequestTimeFromJSONTyped,
  ClinicCounselorRequestTimeToJSON,
} from "./";

export const PatchCounselingRequestBodyTypename = "PatchCounselingRequestBody" as const;

/**
 *
 * @export
 * @interface PatchCounselingRequestBody
 */
export interface PatchCounselingRequestBody {
  /**
   *
   * @type {number}
   * @memberof PatchCounselingRequestBody
   */
  doctorId?: number;
  /**
   *
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  status: PatchCounselingRequestBodyStatusEnum;
  /**
   * Only evaluated when the status is canceled.
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  statusDetail?: string;
  /**
   *
   * @type {ClinicCounselorRequestTime}
   * @memberof PatchCounselingRequestBody
   */
  time?: ClinicCounselorRequestTime;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchCounselingRequestBodyStatusEnum {
  Accepted = "accepted",
  CounselingStarted = "counseling_started",
  CounselingCompleted = "counseling_completed",
  Canceled = "canceled",
}

export function PatchCounselingRequestBodyFromJSON(json: any): PatchCounselingRequestBody {
  return PatchCounselingRequestBodyFromJSONTyped(json, false);
}

export function PatchCounselingRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchCounselingRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchCounselingRequestBodyTypename,

    doctorId: !exists(json, "doctor_id") ? undefined : json["doctor_id"],
    status: json["status"],
    statusDetail: !exists(json, "status_detail") ? undefined : json["status_detail"],
    time: !exists(json, "time") ? undefined : ClinicCounselorRequestTimeFromJSON(json["time"]),
  };
}

export function PatchCounselingRequestBodyToJSON(object?: PatchCounselingRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    doctor_id: value.doctorId,
    status: value.status,
    status_detail: value.statusDetail,
    time: ClinicCounselorRequestTimeToJSON(value.time),
  };
}
