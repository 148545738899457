import { exists } from "@mgdx/api/lib/runtime";

import { PharmacyCustomInterviewOption, PharmacyCustomInterviewOptionFromJSON } from "./PharmacyCustomInterviewOption";
import {
  PharmacyCustomInterviewQuestionnaireOptions,
  PharmacyCustomInterviewQuestionnaireOptionsFromJSON,
  PharmacyCustomInterviewQuestionnaireOptionsToJSON,
} from "./PharmacyCustomInterviewQuestionnaireOptions";
export const PharmacyCustomInterviewInterviewTypename = "PharmacyCustomInterviewInterview" as const;

/**
 *
 * @export
 * @interface PharmacyCustomInterviewInterview
 * @see PharmacyCustomInterviewInterview
 */
export interface PharmacyCustomInterviewInterview {
  id: number;
  type: string;
  label: string;
  value: string;
  answers: string[];
  options: PharmacyCustomInterviewOption[];
  questionnaireOptions: PharmacyCustomInterviewQuestionnaireOptions[];
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewInterviewFromJSON(json: any): PharmacyCustomInterviewInterview {
  return PharmacyCustomInterviewInterviewFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewInterviewFromJSONTyped(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCustomInterviewInterview {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCustomInterviewInterviewTypename,
    id: json["id"],
    type: json["type"],
    label: json["label"],
    value: json["value"],
    answers: !exists(json, "answers") ? [] : json["answers"],
    options: !exists(json, "options") ? [] : json["options"].map(PharmacyCustomInterviewOptionFromJSON),
    questionnaireOptions: !exists(json, "questionnaire_options")
      ? []
      : json["questionnaire_options"].map(PharmacyCustomInterviewQuestionnaireOptionsFromJSON),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewInterviewToJSON(object?: PharmacyCustomInterviewInterview | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    type: value.type,
    label: value.label,
    value: value.value,
    answers: value.answers,
    options: value.options,
    questionnaire_options: value.questionnaireOptions.map(PharmacyCustomInterviewQuestionnaireOptionsToJSON),
  };
}

export const textFieldPlaceholder = (value: string): string => {
  switch (value) {
    case "medication_fit_detail":
      return "合わなかったお薬の名前と症状を入力してください";
    case "allergy_detail_others":
      return "いずれも当てはまらない場合は入力してください";
  }
  return "";
};

export const gridStartText = (value: string): string => {
  switch (value) {
    case "alcohol_detail_week":
      return "週";
    case "alcohol_detail_day":
      return "1日";
    case "tobacco_detail":
      return "1日";
    case "age_detail":
      return "";
  }
  return "";
};

export const gridEndText = (value: string): string => {
  switch (value) {
    case "alcohol_detail_week":
      return "回程度";
    case "alcohol_detail_day":
      return "杯程度";
    case "tobacco_detail":
      return "本程度";
    case "age_detail":
      return "ヶ月";
  }
  return "";
};

export type Question = Omit<PharmacyCustomInterviewInterview, "questionnaireOptions">;

export const answerText = (question: Question) => {
  if (question.type === "radio") {
    const o = question.options.find((o) => o.value === question.answers[0]);
    return o?.label || undefined;
  }

  if (question.value === "alcohol_detail_week") {
    const answer = question.answers && question.answers[0];
    return answer ? `週${answer}回程度` : undefined;
  }

  if (question.value === "alcohol_detail_day") {
    const answer = question.answers && question.answers[0];
    return answer ? `1日${answer}杯程度` : undefined;
  }

  if (question.value === "tobacco_detail") {
    const answer = question.answers && question.answers[0];
    return answer ? `1日${answer}本程度` : undefined;
  }

  return question.answers ? question.answers.join(", ") : undefined;
};
