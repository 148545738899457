import clsx from "clsx";
import React from "react";

import CircularProgress from "../CircularProgress";
import * as styles from "./OutlinedButton.module.css";

export type OutlinedButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
  variant: `${Variant}`;
  type?: `${ButtonType}`;
  widthType?: `${WidthType}`;
  size?: `${Size}`;
  multiline?: boolean;
  disabled?: boolean;
  loading?: boolean;
  variableClassName?: string;
};

export enum Variant {
  Key = "key",
  Link = "link",
  Weak = "weak",
  Variable = "variable",
}

export enum ButtonType {
  Normal = "normal",
  Submit = "submit",
}

export enum Size {
  S = "s",
  M = "m",
  L = "l",
}

export enum WidthType {
  FitContent = "fit-content",
  FullWidth = "full-width",
}

export const OutlinedButton: React.FC<OutlinedButtonProps> = ({
  variant,
  type = ButtonType.Normal,
  widthType = WidthType.FitContent,
  size = Size.M,
  multiline,
  disabled,
  loading,
  children,
  className,
  variableClassName,
  ...props
}) => (
  <div
    className={clsx(
      styles.base,
      variant === Variant.Key && styles.key,
      variant === Variant.Link && styles.link,
      variant === Variant.Weak && styles.weak,
      variant === Variant.Variable && variableClassName,
      widthType === WidthType.FitContent && styles.fitContent,
      widthType === WidthType.FullWidth && styles.fullWidth,
      size === Size.S && styles.sizeS,
      size === Size.M && styles.sizeM,
      size === Size.L && styles.sizeL,
      multiline && styles.multiline,
      disabled && styles.disabled,
      className
    )}
  >
    <button
      type={type === ButtonType.Normal ? "button" : type === ButtonType.Submit ? "submit" : "reset"}
      disabled={disabled}
      className={styles.button}
      {...props}
    >
      {loading ? <CircularProgress /> : children}
    </button>
  </div>
);
