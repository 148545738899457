import { WrapPageElementNodeArgs } from "gatsby";
import * as minimatch from "minimatch";
import * as React from "react";
import Helmet from "react-helmet";

const withoutTrailingSlash = (pathname) => (pathname === `/` ? pathname : pathname.replace(/\/$/, ``));

const matchExcludes = (excludes, pathname) => {
  return excludes.some((exclude) => minimatch(withoutTrailingSlash(pathname), withoutTrailingSlash(exclude)));
};

type WrapPageElement = (
  args: WrapPageElementNodeArgs,
  options: {
    excludes?: string[];
    alternates?: string[];
    canonical?: string;
  }
) => React.ReactElement;

export const wrapPageElement: WrapPageElement = (
  { element, props: { location } },
  pluginOptions = { excludes: [], alternates: [], canonical: "" }
) => {
  const pathname = location.pathname || "/";
  const isExcluded = matchExcludes(pluginOptions.excludes || [], pathname);

  if (isExcluded) {
    return element;
  }

  if (pluginOptions.canonical) {
    const url = `${pluginOptions.canonical}${pathname}` + location.search + location.hash;

    return (
      <React.Fragment>
        <Helmet
          link={[
            {
              rel: "canonical",
              href: url,
            },
          ]}
        />
        {element}
      </React.Fragment>
    );
  }

  if (pluginOptions.alternates) {
    return (
      <React.Fragment>
        <Helmet
          link={pluginOptions.alternates.map((alternate) => {
            const url = `${alternate}${pathname}` + location.search + location.hash;

            return {
              rel: "alternate",
              href: url,
            };
          })}
        />
        {element}
      </React.Fragment>
    );
  }

  return element;
};
