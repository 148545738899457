import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const StorefrontIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M109.9,1536.89 L108.85,1532.52 C108.63,1531.62 107.85,1531 106.94,1531 L104.73,1531 L102.72,1531 L101,1531 L99,1531 L97.28,1531 L95.26,1531 L93.05,1531 C92.15,1531 91.36,1531.63 91.15,1532.52 L90.1,1536.89 C89.86,1537.91 90.08,1538.95 90.72,1539.77 C90.8,1539.88 90.91,1539.96 91,1540.06 L91,1547 C91,1548.1 91.9,1549 93,1549 L107,1549 C108.1,1549 109,1548.1 109,1547 L109,1540.06 C109.09,1539.97 109.2,1539.88 109.28,1539.78 C109.92,1538.96 110.15,1537.91 109.9,1536.89 Z M95.02,1533 L94.44,1537.86 C94.36,1538.51 93.84,1539 93.23,1539 C92.74,1539 92.43,1538.71 92.3,1538.53 C92.04,1538.2 91.95,1537.77 92.05,1537.36 L93.05,1533 L95.02,1533 Z M106.91,1532.99 L107.96,1537.36 C108.06,1537.78 107.97,1538.2 107.71,1538.53 C107.57,1538.71 107.27,1539 106.77,1539 C106.16,1539 105.63,1538.51 105.56,1537.86 L104.98,1533 L106.91,1532.99 Z M103.51,1537.52 C103.56,1537.91 103.44,1538.3 103.18,1538.59 C102.95,1538.85 102.63,1539 102.22,1539 C101.55,1539 101,1538.41 101,1537.69 L101,1533 L102.96,1533 L103.51,1537.52 Z M99,1537.69 C99,1538.41 98.45,1539 97.71,1539 C97.37,1539 97.06,1538.85 96.82,1538.59 C96.57,1538.3 96.45,1537.91 96.49,1537.52 L97.04,1533 L99,1533 L99,1537.69 Z M106,1547 L94,1547 C93.45,1547 93,1546.55 93,1546 L93,1540.97 C93.08,1540.98 93.15,1541 93.23,1541 C94.1,1541 94.89,1540.64 95.47,1540.05 C96.07,1540.65 96.87,1541 97.78,1541 C98.65,1541 99.43,1540.64 100.01,1540.07 C100.6,1540.64 101.4,1541 102.3,1541 C103.14,1541 103.94,1540.65 104.54,1540.05 C105.12,1540.64 105.91,1541 106.78,1541 C106.86,1541 106.93,1540.98 107.01,1540.97 L107.01,1546 C107,1546.55 106.55,1547 106,1547 Z"
        transform="translate(-88 -1528)"
      />
    </svg>
  );
};

export default StorefrontIcon;
