/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatchMedicineNotebookAutoLinkRequestBodyTypename = "PatchMedicineNotebookAutoLinkRequestBody" as const;

/**
 *
 * @export
 * @interface PatchMedicineNotebookAutoLinkRequestBody
 */
export interface PatchMedicineNotebookAutoLinkRequestBody {
  /**
   * required if status is completed
   * @type {string}
   * @memberof PatchMedicineNotebookAutoLinkRequestBody
   */
  dateOfBirth?: string;
  /**
   * required if status is completed
   * @type {string}
   * @memberof PatchMedicineNotebookAutoLinkRequestBody
   */
  gender?: PatchMedicineNotebookAutoLinkRequestBodyGenderEnum;
  /**
   * required if status is completed
   * @type {string}
   * @memberof PatchMedicineNotebookAutoLinkRequestBody
   */
  name?: string;
  /**
   * required if status is completed
   * @type {string}
   * @memberof PatchMedicineNotebookAutoLinkRequestBody
   */
  receiptCode?: string;
  /**
   *
   * @type {string}
   * @memberof PatchMedicineNotebookAutoLinkRequestBody
   */
  status: PatchMedicineNotebookAutoLinkRequestBodyStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchMedicineNotebookAutoLinkRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchMedicineNotebookAutoLinkRequestBodyGenderEnum {
  Male = "male",
  Female = "female",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchMedicineNotebookAutoLinkRequestBodyStatusEnum {
  Completed = "completed",
  Canceled = "canceled",
}

export function PatchMedicineNotebookAutoLinkRequestBodyFromJSON(json: any): PatchMedicineNotebookAutoLinkRequestBody {
  return PatchMedicineNotebookAutoLinkRequestBodyFromJSONTyped(json, false);
}

export function PatchMedicineNotebookAutoLinkRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchMedicineNotebookAutoLinkRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchMedicineNotebookAutoLinkRequestBodyTypename,

    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    name: !exists(json, "name") ? undefined : json["name"],
    receiptCode: !exists(json, "receipt_code") ? undefined : json["receipt_code"],
    status: json["status"],
  };
}

export function PatchMedicineNotebookAutoLinkRequestBodyToJSON(
  object?: PatchMedicineNotebookAutoLinkRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    gender: value.gender,
    name: value.name,
    receipt_code: value.receiptCode,
    status: value.status,
  };
}
