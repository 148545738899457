/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostLinkedPatientRequestBodyTypename = "PostLinkedPatientRequestBody" as const;

/**
 *
 * @export
 * @interface PostLinkedPatientRequestBody
 */
export interface PostLinkedPatientRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostLinkedPatientRequestBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PostLinkedPatientRequestBody
   */
  invitationId?: string;
  /**
   *
   * @type {string}
   * @memberof PostLinkedPatientRequestBody
   */
  linkUserToken?: string;
  /**
   *
   * @type {string}
   * @memberof PostLinkedPatientRequestBody
   */
  password?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostLinkedPatientRequestBody
   */
  readonly __typename?: string;
}

export function PostLinkedPatientRequestBodyFromJSON(json: any): PostLinkedPatientRequestBody {
  return PostLinkedPatientRequestBodyFromJSONTyped(json, false);
}

export function PostLinkedPatientRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostLinkedPatientRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostLinkedPatientRequestBodyTypename,

    email: !exists(json, "email") ? undefined : json["email"],
    invitationId: !exists(json, "invitation_id") ? undefined : json["invitation_id"],
    linkUserToken: !exists(json, "link_user_token") ? undefined : json["link_user_token"],
    password: !exists(json, "password") ? undefined : json["password"],
  };
}

export function PostLinkedPatientRequestBodyToJSON(object?: PostLinkedPatientRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    email: value.email,
    invitation_id: value.invitationId,
    link_user_token: value.linkUserToken,
    password: value.password,
  };
}
