/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientOTCMedicine,
  PatientOTCMedicineFromJSON,
  PatientOTCMedicineFromJSONTyped,
  PatientOTCMedicineToJSON,
} from "./";

export const OTCMedicineResponseTypename = "OTCMedicineResponse" as const;

/**
 *
 * @export
 * @interface OTCMedicineResponse
 */
export interface OTCMedicineResponse {
  /**
   *
   * @type {PatientOTCMedicine}
   * @memberof OTCMedicineResponse
   */
  otcMedicine?: PatientOTCMedicine;
  /**
   * Resource type name
   * @type {string}
   * @memberof OTCMedicineResponse
   */
  readonly __typename?: string;
}

export function OTCMedicineResponseFromJSON(json: any): OTCMedicineResponse {
  return OTCMedicineResponseFromJSONTyped(json, false);
}

export function OTCMedicineResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OTCMedicineResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: OTCMedicineResponseTypename,

    otcMedicine: !exists(json, "otc_medicine") ? undefined : PatientOTCMedicineFromJSON(json["otc_medicine"]),
  };
}

export function OTCMedicineResponseToJSON(object?: OTCMedicineResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    otc_medicine: PatientOTCMedicineToJSON(value.otcMedicine),
  };
}
