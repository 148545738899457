import isClient from "@mgdx/shared/src/utils/isClient";

export const isInDaifukuApp = () => {
  if (!isClient()) {
    return false;
  }
  // TODO dev開発用にlocalStorageの値を見て強制的にtrueにする処理を追加予定

  return / DaifukuApp\/v(\d+\.\d+\.\d+)/.test(window.navigator.userAgent);
};
