import clsx from "clsx";
import React from "react";

import CircularProgress from "../CircularProgress";
import * as styles from "./FilledButton.module.css";

export type FilledButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
  variant: `${Variant}`;
  type?: `${ButtonType}`;
  size?: `${Size}`;
  fontSize?: `${FontSize}`;
  widthType?: `${WidthType}`;
  multiline?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

export enum Variant {
  Primary = "primary",
  PrimaryWeak = "primary-weak",
  Secondary = "secondary",
  Caution = "caution",
}

export enum ButtonType {
  Normal = "normal",
  Submit = "submit",
}

export enum Size {
  S = "s",
  M = "m",
  L = "l",
}

export enum FontSize {
  M = "m",
}

export enum WidthType {
  FitContent = "fit-content",
  FullWidth = "full-width",
}

export const FilledButton: React.FC<FilledButtonProps> = ({
  variant,
  type = ButtonType.Normal,
  size = Size.M,
  fontSize,
  widthType = WidthType.FitContent,
  multiline,
  disabled,
  loading,
  children,
  className,
  ...props
}) => (
  <div
    className={clsx(
      styles.base,
      variant === Variant.Primary && styles.primary,
      variant === Variant.PrimaryWeak && styles.primaryWeak,
      variant === Variant.Secondary && styles.secondary,
      variant === Variant.Caution && styles.caution,
      widthType === WidthType.FitContent && styles.fitContent,
      widthType === WidthType.FullWidth && styles.fullWidth,
      size === Size.S && styles.sizeS,
      size === Size.M && styles.sizeM,
      size === Size.L && styles.sizeL,
      fontSize === FontSize.M && styles.fontSizeM,
      multiline && styles.multiline,
      disabled && styles.disabled,
      className
    )}
  >
    <button
      type={type === ButtonType.Normal ? "button" : type === ButtonType.Submit ? "submit" : "reset"}
      disabled={disabled}
      className={clsx(styles.button)}
      {...props}
    >
      {loading ? <CircularProgress /> : children}
    </button>
  </div>
);
