import { ZendeskTicket, ZendeskTicketFromJSON, ZendeskTicketToJSON } from "./ZendeskTicket";
import { ZendeskTicketComment, ZendeskTicketCommentFromJSON, ZendeskTicketCommentToJSON } from "./ZendeskTicketComment";

export const ZendeskRequestSenderTypename = "ZendeskRequestSender" as const;

interface ZendeskRequestSender {
  name?: string;
  email?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskRequest
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ZendeskRequestSenderFromJSON(json: any): ZendeskRequestSender {
  return ZendeskRequestSenderFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ZendeskRequestSenderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskRequestSender {
  if (json === undefined || json === null) {
    return json;
  }

  return {
    __typename: ZendeskRequestTypename,
    name: json["name"],
    email: json["emai"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskRequestSenderToJSON(object?: ZendeskRequestSender | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    name: value.name,
    email: value.email,
  };
}

export const ZendeskRequestTypename = "ZendeskZendeskRequest" as const;

export type ZendeskRequest = {
  request: Partial<ZendeskTicket> & {
    requester: ZendeskRequestSender;
    comment: Partial<Omit<ZendeskTicketComment, "body" | "__typename">> & Pick<ZendeskTicketComment, "body">;
    fields: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  };
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskRequest
   */
  readonly __typename?: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskRequestFromJSON(json: any): ZendeskRequest {
  return ZendeskRequestFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskRequest {
  if (json === undefined || json === null) {
    return json;
  }
  const request = ZendeskTicketFromJSON(json.reqest);

  return {
    __typename: ZendeskRequestTypename,
    request: {
      ...request,
      requester: ZendeskRequestSenderFromJSON(json.request.requester),
      comment: ZendeskTicketCommentFromJSON(json.request.comment),
      fields: json.request.fields,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskRequestToJSON(object?: ZendeskRequest | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    request: {
      ...ZendeskTicketToJSON(value.request as ZendeskTicket),
      requester: {
        name: value.request.requester.name,
        email: value.request.requester.email,
      },
      comment: ZendeskTicketCommentToJSON(value.request.comment as ZendeskTicketComment),
      fields: value.request.fields,
    },
  };
}
