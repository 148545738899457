/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyPatchPharmacies,
  PharmacyPatchPharmaciesFromJSON,
  PharmacyPatchPharmaciesFromJSONTyped,
  PharmacyPatchPharmaciesToJSON,
} from "./";

export const PatchPharmaciesRequestBodyTypename = "PatchPharmaciesRequestBody" as const;

/**
 *
 * @export
 * @interface PatchPharmaciesRequestBody
 */
export interface PatchPharmaciesRequestBody {
  /**
   *
   * @type {Array<PharmacyPatchPharmacies>}
   * @memberof PatchPharmaciesRequestBody
   */
  pharmacies: Array<PharmacyPatchPharmacies>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchPharmaciesRequestBody
   */
  readonly __typename?: string;
}

export function PatchPharmaciesRequestBodyFromJSON(json: any): PatchPharmaciesRequestBody {
  return PatchPharmaciesRequestBodyFromJSONTyped(json, false);
}

export function PatchPharmaciesRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchPharmaciesRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchPharmaciesRequestBodyTypename,

    pharmacies: (json["pharmacies"] as Array<any>).map(PharmacyPatchPharmaciesFromJSON),
  };
}

export function PatchPharmaciesRequestBodyToJSON(object?: PatchPharmaciesRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    pharmacies: (value.pharmacies as Array<any>).map(PharmacyPatchPharmaciesToJSON),
  };
}
