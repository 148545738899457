import hasAbortController from "@mgdx/shared/src/utils/hasAbortController";

export const createFetchTimeout =
  (timeout: number): WindowOrWorkerGlobalScope["fetch"] =>
  (input, init): Promise<Response> => {
    const fetch = window.fetch.bind(window);

    if (hasAbortController()) {
      const controller = new AbortController();

      const timerId = setTimeout(() => {
        controller.abort();
      }, timeout);

      return fetch(input, {
        ...init,
        signal: controller.signal,
      }).finally(() => {
        clearTimeout(timerId);
      });
    }

    return fetch(input, {
      ...init,
    });
  };
