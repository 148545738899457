const readFile = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event): void => {
      resolve(event.target?.result as string);
    };

    reader.onerror = (event): void => {
      reader.abort();
      reject(event);
    };

    reader.readAsDataURL(file);
  });

export default readFile;
