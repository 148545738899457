/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PharmacyCity,
  PharmacyCityFromJSON,
  PharmacyCityToJSON,
  PharmacyPrefecture,
  PharmacyPrefectureFromJSON,
  PharmacyPrefectureToJSON,
} from "../models";

export interface GetPrefectureCitiesRequest {
  prefCode: string;
}

/**
 *
 */
export class PrefectureApi extends runtime.BaseAPI {
  /**
   * Get cities in prefecture
   */
  async getPrefectureCitiesRaw(
    requestParameters: GetPrefectureCitiesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyCity>>> {
    if (requestParameters.prefCode === null || requestParameters.prefCode === undefined) {
      throw new runtime.RequiredError(
        "prefCode",
        "Required parameter requestParameters.prefCode was null or undefined when calling getPrefectureCities."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/prefectures/{pref_code}/cities`.replace(
          `{${"pref_code"}}`,
          encodeURIComponent(String(requestParameters.prefCode))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyCityFromJSON));
  }

  /**
   * Get cities in prefecture
   */
  async getPrefectureCities(
    requestParameters: GetPrefectureCitiesRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyCity>> {
    return promiseRetry(
      (retry) =>
        this.getPrefectureCitiesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get prefectures
   */
  async getPrefecturesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PharmacyPrefecture>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/prefectures`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPrefectureFromJSON));
  }

  /**
   * Get prefectures
   */
  async getPrefectures(initOverrides?: RequestInit): Promise<Array<PharmacyPrefecture>> {
    return promiseRetry(
      (retry) =>
        this.getPrefecturesRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
