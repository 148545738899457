import { createQueryKeyStore } from "@lukemorales/query-key-factory";

export const counselingAcceptCodeKeys = createQueryKeyStore({
  acceptCodes: {
    list: (pharmacyId) => [pharmacyId],
    preparing: (pharmacyId) => [pharmacyId],
    notified: (pharmacyId) => [pharmacyId],
    detail: (id) => [id],
  },
});
