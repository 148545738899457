/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchMedicineNotebookRequestBody,
  PatchMedicineNotebookRequestBodyFromJSON,
  PatchMedicineNotebookRequestBodyToJSON,
  PatchMedicineNotebookResponse,
  PatchMedicineNotebookResponseFromJSON,
  PatchMedicineNotebookResponseToJSON,
  PatientCustomBadRequest2,
  PatientCustomBadRequest2FromJSON,
  PatientCustomBadRequest2ToJSON,
  PostAutoLinkReceiptCodesRequestBody,
  PostAutoLinkReceiptCodesRequestBodyFromJSON,
  PostAutoLinkReceiptCodesRequestBodyToJSON,
  PutMedicineNotebookByPatientRequestBody,
  PutMedicineNotebookByPatientRequestBodyFromJSON,
  PutMedicineNotebookByPatientRequestBodyToJSON,
  PutMedicineNotebookByPatientResponse,
  PutMedicineNotebookByPatientResponseFromJSON,
  PutMedicineNotebookByPatientResponseToJSON,
  PutMedicineNotebookRequestBody,
  PutMedicineNotebookRequestBodyFromJSON,
  PutMedicineNotebookRequestBodyToJSON,
  PutMedicineNotebookResponse,
  PutMedicineNotebookResponseFromJSON,
  PutMedicineNotebookResponseToJSON,
} from "../models";

export interface DeleteAutoLinkReceiptCodesRequest {
  patientId: number;
  chainId: number;
  pharmacyId: number;
  familyAccountId?: number;
}

export interface PatchMedicineNotebookRequest {
  chainId: number;
  patchMedicineNotebookRequestBody: PatchMedicineNotebookRequestBody;
}

export interface PostAutoLinkReceiptCodesRequest {
  patientId: number;
  chainId: number;
  postAutoLinkReceiptCodesRequestBody: PostAutoLinkReceiptCodesRequestBody;
  familyAccountId?: number;
}

export interface PutMedicineNotebookByOperatorRequest {
  chainId: number;
  patientId: number;
  putMedicineNotebookRequestBody: PutMedicineNotebookRequestBody;
  familyAccountId?: number;
}

export interface PutMedicineNotebookByPatientRequest {
  chainId: number;
  putMedicineNotebookByPatientRequestBody: PutMedicineNotebookByPatientRequestBody;
  familyAccountId?: number;
}

/**
 *
 */
export class MedicineNotebookApi extends runtime.BaseAPI {
  /**
   * Delete update receipt auto link to the pharmacy
   */
  async deleteAutoLinkReceiptCodesRaw(
    requestParameters: DeleteAutoLinkReceiptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling deleteAutoLinkReceiptCodes."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteAutoLinkReceiptCodes."
      );
    }

    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling deleteAutoLinkReceiptCodes."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.pharmacyId !== undefined) {
      queryParameters["pharmacy_id"] = requestParameters.pharmacyId;
    }

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/auto-link-receipt-codes`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete update receipt auto link to the pharmacy
   */
  async deleteAutoLinkReceiptCodes(
    requestParameters: DeleteAutoLinkReceiptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteAutoLinkReceiptCodesRaw(requestParameters, initOverrides);
  }

  /**
   * Agreed medicine notebook rules by auth_id in JWT
   */
  async patchMedicineNotebookRaw(
    requestParameters: PatchMedicineNotebookRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatchMedicineNotebookResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling patchMedicineNotebook."
      );
    }

    if (
      requestParameters.patchMedicineNotebookRequestBody === null ||
      requestParameters.patchMedicineNotebookRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchMedicineNotebookRequestBody",
        "Required parameter requestParameters.patchMedicineNotebookRequestBody was null or undefined when calling patchMedicineNotebook."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchMedicineNotebookRequestBodyToJSON(requestParameters.patchMedicineNotebookRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatchMedicineNotebookResponseFromJSON(jsonValue));
  }

  /**
   * Agreed medicine notebook rules by auth_id in JWT
   */
  async patchMedicineNotebook(
    requestParameters: PatchMedicineNotebookRequest,
    initOverrides?: RequestInit
  ): Promise<PatchMedicineNotebookResponse> {
    return promiseRetry(
      (retry) =>
        this.patchMedicineNotebookRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register and update receipt code auto link to the pharmacy
   */
  async postAutoLinkReceiptCodesRaw(
    requestParameters: PostAutoLinkReceiptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PutMedicineNotebookResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling postAutoLinkReceiptCodes."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postAutoLinkReceiptCodes."
      );
    }

    if (
      requestParameters.postAutoLinkReceiptCodesRequestBody === null ||
      requestParameters.postAutoLinkReceiptCodesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postAutoLinkReceiptCodesRequestBody",
        "Required parameter requestParameters.postAutoLinkReceiptCodesRequestBody was null or undefined when calling postAutoLinkReceiptCodes."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/auto-link-receipt-codes`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostAutoLinkReceiptCodesRequestBodyToJSON(requestParameters.postAutoLinkReceiptCodesRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PutMedicineNotebookResponseFromJSON(jsonValue));
  }

  /**
   * Register and update receipt code auto link to the pharmacy
   */
  async postAutoLinkReceiptCodes(
    requestParameters: PostAutoLinkReceiptCodesRequest,
    initOverrides?: RequestInit
  ): Promise<PutMedicineNotebookResponse> {
    return promiseRetry(
      (retry) =>
        this.postAutoLinkReceiptCodesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register or update medicine notebook. if the specified pharmacy_id is not registered as a my pharmacy, it will be registered at the same time
   */
  async putMedicineNotebookByOperatorRaw(
    requestParameters: PutMedicineNotebookByOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PutMedicineNotebookResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putMedicineNotebookByOperator."
      );
    }

    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling putMedicineNotebookByOperator."
      );
    }

    if (
      requestParameters.putMedicineNotebookRequestBody === null ||
      requestParameters.putMedicineNotebookRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putMedicineNotebookRequestBody",
        "Required parameter requestParameters.putMedicineNotebookRequestBody was null or undefined when calling putMedicineNotebookByOperator."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutMedicineNotebookRequestBodyToJSON(requestParameters.putMedicineNotebookRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PutMedicineNotebookResponseFromJSON(jsonValue));
  }

  /**
   * Register or update medicine notebook. if the specified pharmacy_id is not registered as a my pharmacy, it will be registered at the same time
   */
  async putMedicineNotebookByOperator(
    requestParameters: PutMedicineNotebookByOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<PutMedicineNotebookResponse> {
    return promiseRetry(
      (retry) =>
        this.putMedicineNotebookByOperatorRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register or update medicine notebook by patient_id in JWT
   */
  async putMedicineNotebookByPatientRaw(
    requestParameters: PutMedicineNotebookByPatientRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PutMedicineNotebookByPatientResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putMedicineNotebookByPatient."
      );
    }

    if (
      requestParameters.putMedicineNotebookByPatientRequestBody === null ||
      requestParameters.putMedicineNotebookByPatientRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putMedicineNotebookByPatientRequestBody",
        "Required parameter requestParameters.putMedicineNotebookByPatientRequestBody was null or undefined when calling putMedicineNotebookByPatient."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutMedicineNotebookByPatientRequestBodyToJSON(requestParameters.putMedicineNotebookByPatientRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PutMedicineNotebookByPatientResponseFromJSON(jsonValue)
    );
  }

  /**
   * Register or update medicine notebook by patient_id in JWT
   */
  async putMedicineNotebookByPatient(
    requestParameters: PutMedicineNotebookByPatientRequest,
    initOverrides?: RequestInit
  ): Promise<PutMedicineNotebookByPatientResponse> {
    return promiseRetry(
      (retry) =>
        this.putMedicineNotebookByPatientRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
