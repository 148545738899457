/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutMedicalInformationResponseTypename = "PutMedicalInformationResponse" as const;

/**
 *
 * @export
 * @interface PutMedicalInformationResponse
 */
export interface PutMedicalInformationResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof PutMedicalInformationResponse
   */
  medicalInformationPaths?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutMedicalInformationResponse
   */
  readonly __typename?: string;
}

export function PutMedicalInformationResponseFromJSON(json: any): PutMedicalInformationResponse {
  return PutMedicalInformationResponseFromJSONTyped(json, false);
}

export function PutMedicalInformationResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutMedicalInformationResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutMedicalInformationResponseTypename,

    medicalInformationPaths: !exists(json, "medical_information_paths") ? undefined : json["medical_information_paths"],
  };
}

export function PutMedicalInformationResponseToJSON(object?: PutMedicalInformationResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    medical_information_paths: value.medicalInformationPaths,
  };
}
