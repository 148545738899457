/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import { PutRefillRequestBody, PutRefillRequestBodyFromJSON, PutRefillRequestBodyToJSON } from "../models";

export interface PutRefillRequest {
  counselingId: number;
  putRefillRequestBody: PutRefillRequestBody;
}

/**
 *
 */
export class RefillApi extends runtime.BaseAPI {
  /**
   * Update refill information
   */
  async putRefillRaw(
    requestParameters: PutRefillRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling putRefill."
      );
    }

    if (requestParameters.putRefillRequestBody === null || requestParameters.putRefillRequestBody === undefined) {
      throw new runtime.RequiredError(
        "putRefillRequestBody",
        "Required parameter requestParameters.putRefillRequestBody was null or undefined when calling putRefill."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/refill`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutRefillRequestBodyToJSON(requestParameters.putRefillRequestBody),
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Update refill information
   */
  async putRefill(requestParameters: PutRefillRequest, initOverrides?: RequestInit): Promise<any> {
    return promiseRetry(
      (retry) =>
        this.putRefillRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
