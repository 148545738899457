import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import { MedicineNotebookAccountRequestConfig } from "../providers/MedicineNotebookAccountProvider";

export const medicineTakenLogKeys = createQueryKeyStore({
  tokenLog: {
    detail: (
      requestConfig: MedicineNotebookAccountRequestConfig,
      date: string,
      dispenseRecordId?: number,
      otcMedicineId?: number
    ) => [requestConfig, date, dispenseRecordId, otcMedicineId],
  },
});
