/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyBookmarkTypename = "PharmacyBookmark" as const;

/**
 *
 * @export
 * @interface PharmacyBookmark
 */
export interface PharmacyBookmark {
  /**
   *
   * @type {string}
   * @memberof PharmacyBookmark
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyBookmark
   */
  url: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyBookmark
   */
  readonly __typename?: string;
}

export function PharmacyBookmarkFromJSON(json: any): PharmacyBookmark {
  return PharmacyBookmarkFromJSONTyped(json, false);
}

export function PharmacyBookmarkFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyBookmark {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyBookmarkTypename,

    title: json["title"],
    url: json["url"],
  };
}

export function PharmacyBookmarkToJSON(object?: PharmacyBookmark | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    title: value.title,
    url: value.url,
  };
}
