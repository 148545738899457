/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyControlPanelAutoPrint,
  PharmacyControlPanelAutoPrintFromJSON,
  PharmacyControlPanelAutoPrintFromJSONTyped,
  PharmacyControlPanelAutoPrintToJSON,
  PharmacyControlPanelEnvironment,
  PharmacyControlPanelEnvironmentFromJSON,
  PharmacyControlPanelEnvironmentFromJSONTyped,
  PharmacyControlPanelEnvironmentToJSON,
  PharmacyControlPanelSounds,
  PharmacyControlPanelSoundsFromJSON,
  PharmacyControlPanelSoundsFromJSONTyped,
  PharmacyControlPanelSoundsToJSON,
} from "./";

export const PharmacyControlPanelSettingTypename = "PharmacyControlPanelSetting" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanelSetting
 */
export interface PharmacyControlPanelSetting {
  /**
   *
   * @type {PharmacyControlPanelAutoPrint}
   * @memberof PharmacyControlPanelSetting
   */
  autoPrint?: PharmacyControlPanelAutoPrint;
  /**
   *
   * @type {PharmacyControlPanelEnvironment}
   * @memberof PharmacyControlPanelSetting
   */
  environment?: PharmacyControlPanelEnvironment;
  /**
   *
   * @type {PharmacyControlPanelSounds}
   * @memberof PharmacyControlPanelSetting
   */
  sounds?: PharmacyControlPanelSounds;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanelSetting
   */
  readonly __typename?: string;
}

export function PharmacyControlPanelSettingFromJSON(json: any): PharmacyControlPanelSetting {
  return PharmacyControlPanelSettingFromJSONTyped(json, false);
}

export function PharmacyControlPanelSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyControlPanelSetting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelSettingTypename,

    autoPrint: !exists(json, "auto_print") ? undefined : PharmacyControlPanelAutoPrintFromJSON(json["auto_print"]),
    environment: !exists(json, "environment")
      ? undefined
      : PharmacyControlPanelEnvironmentFromJSON(json["environment"]),
    sounds: !exists(json, "sounds") ? undefined : PharmacyControlPanelSoundsFromJSON(json["sounds"]),
  };
}

export function PharmacyControlPanelSettingToJSON(object?: PharmacyControlPanelSetting | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    auto_print: PharmacyControlPanelAutoPrintToJSON(value.autoPrint),
    environment: PharmacyControlPanelEnvironmentToJSON(value.environment),
    sounds: PharmacyControlPanelSoundsToJSON(value.sounds),
  };
}
