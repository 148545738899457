/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyOptionalParameterTypename = "PharmacyOptionalParameter" as const;

/**
 *
 * @export
 * @interface PharmacyOptionalParameter
 */
export interface PharmacyOptionalParameter {
  /**
   *
   * @type {number}
   * @memberof PharmacyOptionalParameter
   */
  displayIndex: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyOptionalParameter
   */
  displayName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyOptionalParameter
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyOptionalParameter
   */
  required: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyOptionalParameter
   */
  type: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyOptionalParameter
   */
  values?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyOptionalParameter
   */
  readonly __typename?: string;
}

export function PharmacyOptionalParameterFromJSON(json: any): PharmacyOptionalParameter {
  return PharmacyOptionalParameterFromJSONTyped(json, false);
}

export function PharmacyOptionalParameterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyOptionalParameter {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyOptionalParameterTypename,

    displayIndex: json["display_index"],
    displayName: json["display_name"],
    name: json["name"],
    required: json["required"],
    type: json["type"],
    values: !exists(json, "values") ? undefined : json["values"],
  };
}

export function PharmacyOptionalParameterToJSON(object?: PharmacyOptionalParameter | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    display_index: value.displayIndex,
    display_name: value.displayName,
    name: value.name,
    required: value.required,
    type: value.type,
    values: value.values,
  };
}
