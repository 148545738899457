import clsx from "clsx";
import React from "react";

import * as styles from "./ButtonGroup.module.css";

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  fullWidth?: boolean;
  pair?: boolean;
  wrap?: boolean;
  vertical?: boolean;
};

const ButtonGroup = ({ fullWidth, pair, className, children, wrap, vertical, ...props }: Props) => (
  <div
    className={clsx(
      !vertical && styles.grid,
      vertical && styles.vertical,
      fullWidth && styles.fullWidth,
      pair && styles.pair,
      wrap && styles.wrap,
      className
    )}
  >
    {children}
  </div>
);

export default ButtonGroup;
