import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const ArrowDownwardIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M202.78625,146.79625 L191.61625,146.79625 L196.49625,141.91625 C196.88625,141.52625 196.88625,140.88625 196.49625,140.49625 L196.49625,140.49625 C196.10625,140.10625 195.47625,140.10625 195.08625,140.49625 L188.49625,147.08625 C188.10625,147.47625 188.10625,148.10625 188.49625,148.49625 L195.08625,155.08625 C195.47625,155.47625 196.10625,155.47625 196.49625,155.08625 L196.49625,155.08625 C196.88625,154.69625 196.88625,154.06625 196.49625,153.67625 L191.61625,148.79625 L202.78625,148.79625 C203.33625,148.79625 203.78625,148.34625 203.78625,147.79625 L203.78625,147.79625 C203.78625,147.24625 203.33625,146.79625 202.78625,146.79625 Z"
        transform="rotate(-90 35.995 171.791)"
      />
    </svg>
  );
};

export default ArrowDownwardIcon;
