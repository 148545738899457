/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineNotebookShareFamilyAccount,
  PatientMedicineNotebookShareFamilyAccountFromJSON,
  PatientMedicineNotebookShareFamilyAccountFromJSONTyped,
  PatientMedicineNotebookShareFamilyAccountToJSON,
  PatientMedicineNotebookSharePatient,
  PatientMedicineNotebookSharePatientFromJSON,
  PatientMedicineNotebookSharePatientFromJSONTyped,
  PatientMedicineNotebookSharePatientToJSON,
} from "./";

export const PatientMedicineNotebookShareScopeTypename = "PatientMedicineNotebookShareScope" as const;

/**
 *
 * @export
 * @interface PatientMedicineNotebookShareScope
 */
export interface PatientMedicineNotebookShareScope {
  /**
   *
   * @type {Array<PatientMedicineNotebookShareFamilyAccount>}
   * @memberof PatientMedicineNotebookShareScope
   */
  familyAccounts?: Array<PatientMedicineNotebookShareFamilyAccount>;
  /**
   *
   * @type {PatientMedicineNotebookSharePatient}
   * @memberof PatientMedicineNotebookShareScope
   */
  patient?: PatientMedicineNotebookSharePatient;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineNotebookShareScope
   */
  readonly __typename?: string;
}

export function PatientMedicineNotebookShareScopeFromJSON(json: any): PatientMedicineNotebookShareScope {
  return PatientMedicineNotebookShareScopeFromJSONTyped(json, false);
}

export function PatientMedicineNotebookShareScopeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineNotebookShareScope {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineNotebookShareScopeTypename,

    familyAccounts: !exists(json, "family_accounts")
      ? undefined
      : (json["family_accounts"] as Array<any>).map(PatientMedicineNotebookShareFamilyAccountFromJSON),
    patient: !exists(json, "patient") ? undefined : PatientMedicineNotebookSharePatientFromJSON(json["patient"]),
  };
}

export function PatientMedicineNotebookShareScopeToJSON(object?: PatientMedicineNotebookShareScope | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    family_accounts:
      value.familyAccounts === undefined
        ? undefined
        : (value.familyAccounts as Array<any>).map(PatientMedicineNotebookShareFamilyAccountToJSON),
    patient: PatientMedicineNotebookSharePatientToJSON(value.patient),
  };
}
