export const vibrate = (pattern?: VibratePattern) => {
  window?.navigator?.vibrate?.(pattern || 300);
};

let timeoutId: number | null = null;

export const blinkCursorColor = (cursor: HTMLDivElement | null, colorType: "SCAN" | "HIT") => {
  if (!cursor) return;

  if (timeoutId) {
    window.clearTimeout(timeoutId);
    timeoutId = null;
  }

  if (colorType === "SCAN") {
    cursor.style.borderColor = "#33DD55";

    timeoutId = window.setTimeout(() => {
      cursor.style.borderColor = "";
    }, 500);

    return;
  }

  if (colorType === "HIT") {
    cursor.style.borderColor = "#FFCC00";

    timeoutId = window.setTimeout(() => {
      cursor.style.borderColor = "";
    }, 300);

    return;
  }
};
