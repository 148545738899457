/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineNotebookSharePatient,
  PatientMedicineNotebookSharePatientFromJSON,
  PatientMedicineNotebookSharePatientFromJSONTyped,
  PatientMedicineNotebookSharePatientToJSON,
  PatientMedicineNotebookShareScope,
  PatientMedicineNotebookShareScopeFromJSON,
  PatientMedicineNotebookShareScopeFromJSONTyped,
  PatientMedicineNotebookShareScopeToJSON,
} from "./";

export const PatientMedicineNotebookShareIssueTypename = "PatientMedicineNotebookShareIssue" as const;

/**
 *
 * @export
 * @interface PatientMedicineNotebookShareIssue
 */
export interface PatientMedicineNotebookShareIssue {
  /**
   *
   * @type {Date}
   * @memberof PatientMedicineNotebookShareIssue
   */
  expiredAt: string;
  /**
   *
   * @type {PatientMedicineNotebookSharePatient}
   * @memberof PatientMedicineNotebookShareIssue
   */
  fromPatient: PatientMedicineNotebookSharePatient;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebookShareIssue
   */
  id: string;
  /**
   *
   * @type {PatientMedicineNotebookShareScope}
   * @memberof PatientMedicineNotebookShareIssue
   */
  scope: PatientMedicineNotebookShareScope;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebookShareIssue
   */
  shareCode: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineNotebookShareIssue
   */
  readonly __typename?: string;
}

export function PatientMedicineNotebookShareIssueFromJSON(json: any): PatientMedicineNotebookShareIssue {
  return PatientMedicineNotebookShareIssueFromJSONTyped(json, false);
}

export function PatientMedicineNotebookShareIssueFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineNotebookShareIssue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineNotebookShareIssueTypename,

    expiredAt: json["expired_at"],
    fromPatient: PatientMedicineNotebookSharePatientFromJSON(json["from_patient"]),
    id: json["id"],
    scope: PatientMedicineNotebookShareScopeFromJSON(json["scope"]),
    shareCode: json["share_code"],
  };
}

export function PatientMedicineNotebookShareIssueToJSON(object?: PatientMedicineNotebookShareIssue | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    expired_at: moment(value.expiredAt).tz("Asia/Tokyo").format(),
    from_patient: PatientMedicineNotebookSharePatientToJSON(value.fromPatient),
    id: value.id,
    scope: PatientMedicineNotebookShareScopeToJSON(value.scope),
    share_code: value.shareCode,
  };
}
