/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientFavoritePharmacyTypename = "PatientFavoritePharmacy" as const;

/**
 *
 * @export
 * @interface PatientFavoritePharmacy
 */
export interface PatientFavoritePharmacy {
  /**
   *
   * @type {Array<number>}
   * @memberof PatientFavoritePharmacy
   */
  favoritePharmacies?: Array<number>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientFavoritePharmacy
   */
  readonly __typename?: string;
}

export function PatientFavoritePharmacyFromJSON(json: any): PatientFavoritePharmacy {
  return PatientFavoritePharmacyFromJSONTyped(json, false);
}

export function PatientFavoritePharmacyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientFavoritePharmacy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientFavoritePharmacyTypename,

    favoritePharmacies: !exists(json, "favorite_pharmacies") ? undefined : json["favorite_pharmacies"],
  };
}

export function PatientFavoritePharmacyToJSON(object?: PatientFavoritePharmacy | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    favorite_pharmacies: value.favoritePharmacies,
  };
}
