/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostAutoLinkReceiptCodesRequestBodyTypename = "PostAutoLinkReceiptCodesRequestBody" as const;

/**
 *
 * @export
 * @interface PostAutoLinkReceiptCodesRequestBody
 */
export interface PostAutoLinkReceiptCodesRequestBody {
  /**
   *
   * @type {number}
   * @memberof PostAutoLinkReceiptCodesRequestBody
   */
  pharmacyId: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostAutoLinkReceiptCodesRequestBody
   */
  readonly __typename?: string;
}

export function PostAutoLinkReceiptCodesRequestBodyFromJSON(json: any): PostAutoLinkReceiptCodesRequestBody {
  return PostAutoLinkReceiptCodesRequestBodyFromJSONTyped(json, false);
}

export function PostAutoLinkReceiptCodesRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostAutoLinkReceiptCodesRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostAutoLinkReceiptCodesRequestBodyTypename,

    pharmacyId: json["pharmacy_id"],
  };
}

export function PostAutoLinkReceiptCodesRequestBodyToJSON(object?: PostAutoLinkReceiptCodesRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    pharmacy_id: value.pharmacyId,
  };
}
