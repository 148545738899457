/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ChatterTextMessage,
  ChatterTextMessageFromJSON,
  ChatterTextMessageFromJSONTyped,
  ChatterTextMessageToJSON,
} from "./";

export const GetTextMessagesResponseTypename = "GetTextMessagesResponse" as const;

/**
 *
 * @export
 * @interface GetTextMessagesResponse
 */
export interface GetTextMessagesResponse {
  /**
   *
   * @type {number}
   * @memberof GetTextMessagesResponse
   */
  count?: number;
  /**
   *
   * @type {Array<ChatterTextMessage>}
   * @memberof GetTextMessagesResponse
   */
  textMessages: Array<ChatterTextMessage>;
  /**
   * Resource type name
   * @type {string}
   * @memberof GetTextMessagesResponse
   */
  readonly __typename?: string;
}

export function GetTextMessagesResponseFromJSON(json: any): GetTextMessagesResponse {
  return GetTextMessagesResponseFromJSONTyped(json, false);
}

export function GetTextMessagesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTextMessagesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: GetTextMessagesResponseTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    textMessages: (json["textMessages"] as Array<any>).map(ChatterTextMessageFromJSON),
  };
}

export function GetTextMessagesResponseToJSON(object?: GetTextMessagesResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    textMessages: (value.textMessages as Array<any>).map(ChatterTextMessageToJSON),
  };
}
