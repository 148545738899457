/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientDispensaryTypename = "PatientDispensary" as const;

/**
 *
 * @export
 * @interface PatientDispensary
 */
export interface PatientDispensary {
  /**
   *
   * @type {string}
   * @memberof PatientDispensary
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PatientDispensary
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PatientDispensary
   */
  prefecture?: string;
  /**
   *
   * @type {string}
   * @memberof PatientDispensary
   */
  type?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientDispensary
   */
  readonly __typename?: string;
}

export function PatientDispensaryFromJSON(json: any): PatientDispensary {
  return PatientDispensaryFromJSONTyped(json, false);
}

export function PatientDispensaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientDispensary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientDispensaryTypename,

    code: !exists(json, "code") ? undefined : json["code"],
    name: !exists(json, "name") ? undefined : json["name"],
    prefecture: !exists(json, "prefecture") ? undefined : json["prefecture"],
    type: !exists(json, "type") ? undefined : json["type"],
  };
}

export function PatientDispensaryToJSON(object?: PatientDispensary | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    code: value.code,
    name: value.name,
    prefecture: value.prefecture,
    type: value.type,
  };
}
