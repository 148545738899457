/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyChainUsageSummaryTypename = "PharmacyChainUsageSummary" as const;

/**
 *
 * @export
 * @interface PharmacyChainUsageSummary
 */
export interface PharmacyChainUsageSummary {
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  activeMember?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  appPushMessage?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  chainId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyChainUsageSummary
   */
  chainName: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  directVisit?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  dispensing?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  fax?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  lineOaMessage?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  member?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyChainUsageSummary
   */
  month: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  nonMember?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  onlineCounseling?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyChainUsageSummary
   */
  sms?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyChainUsageSummary
   */
  readonly __typename?: string;
}

export function PharmacyChainUsageSummaryFromJSON(json: any): PharmacyChainUsageSummary {
  return PharmacyChainUsageSummaryFromJSONTyped(json, false);
}

export function PharmacyChainUsageSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyChainUsageSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyChainUsageSummaryTypename,

    activeMember: !exists(json, "active_member") ? undefined : json["active_member"],
    appPushMessage: !exists(json, "app_push_message") ? undefined : json["app_push_message"],
    chainId: json["chain_id"],
    chainName: json["chain_name"],
    directVisit: !exists(json, "direct_visit") ? undefined : json["direct_visit"],
    dispensing: !exists(json, "dispensing") ? undefined : json["dispensing"],
    fax: !exists(json, "fax") ? undefined : json["fax"],
    lineOaMessage: !exists(json, "line_oa_message") ? undefined : json["line_oa_message"],
    member: !exists(json, "member") ? undefined : json["member"],
    month: json["month"],
    nonMember: !exists(json, "non_member") ? undefined : json["non_member"],
    onlineCounseling: !exists(json, "online_counseling") ? undefined : json["online_counseling"],
    sms: !exists(json, "sms") ? undefined : json["sms"],
  };
}

export function PharmacyChainUsageSummaryToJSON(object?: PharmacyChainUsageSummary | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    active_member: value.activeMember,
    app_push_message: value.appPushMessage,
    chain_id: value.chainId,
    chain_name: value.chainName,
    direct_visit: value.directVisit,
    dispensing: value.dispensing,
    fax: value.fax,
    line_oa_message: value.lineOaMessage,
    member: value.member,
    month: value.month,
    non_member: value.nonMember,
    online_counseling: value.onlineCounseling,
    sms: value.sms,
  };
}
