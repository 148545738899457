import { QolAccount, QolAccountFromJSONTyped } from "./QolAccount";

export const QolAccountsTypename = "QolAccounts" as const;

/**
 *
 * @export
 * @interface QolAccounts
 * @see QolAccounts
 */
export interface QolAccounts {
  /**
   *
   * @type {string}
   * @memberof QolAccounts
   */
  parentIndex: string;
  /**
   *
   * @type {string}
   * @memberof QolAccounts
   */
  users: QolAccount[];
  /**
   * Resource type name
   * @type {string}
   * @memberof QolAccounts
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolAccountsFromJSON(json: any): QolAccounts {
  return QolAccountsFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolAccountsFromJSONTyped(json: any, ignoreDiscriminator: boolean): QolAccounts {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: QolAccountsTypename,
    parentIndex: json["parentIndex"],
    users: json["users"].map(QolAccountFromJSONTyped),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function QolAccountsToJSON(object?: QolAccounts | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    parentIndex: value.parentIndex,
    users: value.users,
  };
}
