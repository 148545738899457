import moment from "@mgdx-libs/moment";
import { createAction, createReducer } from "@reduxjs/toolkit";

export const linkContents = [
  "counselings",
  "creditcard",
  "family-account",
  "medicine-notebook",
  "profile",
  "dispensing",
  "counseling",
  "inquiry",
  "rules",
  "medicine-notebook-auto-link-pharmacies",
  "medicine-notebook-check-prescription-dispensed",
  "medicine-notebook-check-otc-dispensed",
  "followups",
  "pick-up",
  "payments",
  "family-pharmacist",
  "direct-visit-reception",
  "campaign",
  "medicine-notebook-share",
  "prescription-record-link",
  "phr-myna-linkage-processing",
  "phr-myna-linkage-canceled",
  "phr",
  "mission-campaign",
] as const;
export type LinkContent = (typeof linkContents)[number];

export type LiffState = {
  pharmacyId?: string;
  pharmacyCounselingId?: string;
  familyPharmacistId?: string;
  skipAccountSelect?: boolean;
  linkContent?: LinkContent;
  expiredTime?: string;
  campaignId?: string;
  invitationId?: string;
  medicineNotebookShareCode?: string;
  prescriptionRecordLink?: string;
  phrAccountId?: string;
  familyAccountId?: string;
  dispenseRecordId?: string;
  otcMedicineId?: string;
};

const initialState: LiffState = {
  pharmacyId: undefined,
  pharmacyCounselingId: undefined,
  familyPharmacistId: undefined,
  skipAccountSelect: undefined,
  linkContent: undefined,
  expiredTime: undefined,
  campaignId: undefined,
  invitationId: undefined,
  medicineNotebookShareCode: undefined,
  prescriptionRecordLink: undefined,
  phrAccountId: undefined,
  familyAccountId: undefined,
  dispenseRecordId: undefined,
  otcMedicineId: undefined,
};

export const setLiffParams = createAction<LiffState>("liff/setLiffParams");
export const clearLiffParams = createAction("liff/clearLiffParams");

const liffReducer = createReducer<LiffState>(
  initialState,

  (builder) => {
    builder
      .addCase(setLiffParams, (state, action) => {
        state.expiredTime = moment().toISOString();
        state.pharmacyId = action.payload.pharmacyId;
        state.familyPharmacistId = action.payload.familyPharmacistId;
        state.pharmacyCounselingId = action.payload.pharmacyCounselingId;
        state.linkContent = action.payload.linkContent;
        state.skipAccountSelect = action.payload.skipAccountSelect;
        state.campaignId = action.payload.campaignId;
        state.invitationId = action.payload.invitationId;
        state.medicineNotebookShareCode = action.payload.medicineNotebookShareCode;
        state.prescriptionRecordLink = action.payload.prescriptionRecordLink;
        state.phrAccountId = action.payload.phrAccountId;
        state.familyAccountId = action.payload.familyAccountId;
        state.dispenseRecordId = action.payload.dispenseRecordId;
        state.otcMedicineId = action.payload.otcMedicineId;
      })
      .addCase(clearLiffParams, (state, action) => {
        return {};
      });
  }
);

export default liffReducer;
