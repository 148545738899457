/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientSymptomTypename = "PatientSymptom" as const;

/**
 *
 * @export
 * @interface PatientSymptom
 */
export interface PatientSymptom {
  /**
   *
   * @type {string}
   * @memberof PatientSymptom
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof PatientSymptom
   */
  masterCode: string;
  /**
   *
   * @type {string}
   * @memberof PatientSymptom
   */
  masterName?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientSymptom
   */
  readonly __typename?: string;
}

export function PatientSymptomFromJSON(json: any): PatientSymptom {
  return PatientSymptomFromJSONTyped(json, false);
}

export function PatientSymptomFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientSymptom {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientSymptomTypename,

    content: !exists(json, "content") ? undefined : json["content"],
    masterCode: json["master_code"],
    masterName: !exists(json, "master_name") ? undefined : json["master_name"],
  };
}

export function PatientSymptomToJSON(object?: PatientSymptom | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    content: value.content,
    master_code: value.masterCode,
    master_name: value.masterName,
  };
}
