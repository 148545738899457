/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyControlPanelPharmacy,
  PharmacyControlPanelPharmacyFromJSON,
  PharmacyControlPanelPharmacyFromJSONTyped,
  PharmacyControlPanelPharmacyToJSON,
  PharmacyControlPanelSetting,
  PharmacyControlPanelSettingFromJSON,
  PharmacyControlPanelSettingFromJSONTyped,
  PharmacyControlPanelSettingToJSON,
} from "./";

export const PharmacyControlPanelTypename = "PharmacyControlPanel" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanel
 */
export interface PharmacyControlPanel {
  /**
   *
   * @type {number}
   * @memberof PharmacyControlPanel
   */
  chainId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanel
   */
  id: string;
  /**
   *
   * @type {PharmacyControlPanelPharmacy}
   * @memberof PharmacyControlPanel
   */
  pharmacy?: PharmacyControlPanelPharmacy;
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanel
   */
  platform: PharmacyControlPanelPlatformEnum;
  /**
   *
   * @type {PharmacyControlPanelSetting}
   * @memberof PharmacyControlPanel
   */
  setting?: PharmacyControlPanelSetting;
  /**
   *
   * @type {Date}
   * @memberof PharmacyControlPanel
   */
  updatedTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanel
   */
  version: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanel
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyControlPanelPlatformEnum {
  Ios = "ios",
  Windows = "windows",
}

export function PharmacyControlPanelFromJSON(json: any): PharmacyControlPanel {
  return PharmacyControlPanelFromJSONTyped(json, false);
}

export function PharmacyControlPanelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyControlPanel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelTypename,

    chainId: json["chain_id"],
    id: json["id"],
    pharmacy: !exists(json, "pharmacy") ? undefined : PharmacyControlPanelPharmacyFromJSON(json["pharmacy"]),
    platform: json["platform"],
    setting: !exists(json, "setting") ? undefined : PharmacyControlPanelSettingFromJSON(json["setting"]),
    updatedTime:
      !exists(json, "updated_time") ||
      (typeof json["updated_time"] === "string" && json["updated_time"].substring(0, 4) === "0001")
        ? undefined
        : json["updated_time"],
    version: json["version"],
  };
}

export function PharmacyControlPanelToJSON(object?: PharmacyControlPanel | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    id: value.id,
    pharmacy: PharmacyControlPanelPharmacyToJSON(value.pharmacy),
    platform: value.platform,
    setting: PharmacyControlPanelSettingToJSON(value.setting),
    updated_time: value.updatedTime === undefined ? undefined : moment(value.updatedTime).tz("Asia/Tokyo").format(),
    version: value.version,
  };
}
