/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PayerShopTestResultTypename = "PayerShopTestResult" as const;

/**
 *
 * @export
 * @interface PayerShopTestResult
 */
export interface PayerShopTestResult {
  /**
   *
   * @type {string}
   * @memberof PayerShopTestResult
   */
  historyUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PayerShopTestResult
   */
  reason?: string;
  /**
   *
   * @type {string}
   * @memberof PayerShopTestResult
   */
  result: PayerShopTestResultResultEnum;
  /**
   *
   * @type {Date}
   * @memberof PayerShopTestResult
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof PayerShopTestResult
   */
  transactionId?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PayerShopTestResult
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PayerShopTestResultResultEnum {
  Ok = "OK",
  Ng = "NG",
}

export function PayerShopTestResultFromJSON(json: any): PayerShopTestResult {
  return PayerShopTestResultFromJSONTyped(json, false);
}

export function PayerShopTestResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayerShopTestResult {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PayerShopTestResultTypename,

    historyUrl: !exists(json, "history_url") ? undefined : json["history_url"],
    reason: !exists(json, "reason") ? undefined : json["reason"],
    result: json["result"],
    time:
      !exists(json, "time") || (typeof json["time"] === "string" && json["time"].substring(0, 4) === "0001")
        ? undefined
        : json["time"],
    transactionId: !exists(json, "transaction_id") ? undefined : json["transaction_id"],
  };
}

export function PayerShopTestResultToJSON(object?: PayerShopTestResult | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    history_url: value.historyUrl,
    reason: value.reason,
    result: value.result,
    time: value.time === undefined ? undefined : moment(value.time).tz("Asia/Tokyo").format(),
    transaction_id: value.transactionId,
  };
}
