/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PharmacyPharmacyGroup,
  PharmacyPharmacyGroupFromJSON,
  PharmacyPharmacyGroupToJSON,
  PharmacyPharmacyGroupRegistrable,
  PharmacyPharmacyGroupRegistrableFromJSON,
  PharmacyPharmacyGroupRegistrableToJSON,
  PharmacyPharmacyGroupUpdatable,
  PharmacyPharmacyGroupUpdatableFromJSON,
  PharmacyPharmacyGroupUpdatableToJSON,
} from "../models";

export interface GetPharmacyGroupRequest {
  pharmacyGroupId: number;
}

export interface RegisterPharmacyGroupRequest {
  pharmacyPharmacyGroupRegistrable: Array<PharmacyPharmacyGroupRegistrable>;
}

export interface SearchPharmacyGroupsRequest {
  chainId: number;
}

export interface UpdatePharmacyGroupRequest {
  pharmacyGroupId: number;
  pharmacyPharmacyGroupUpdatable: PharmacyPharmacyGroupUpdatable;
}

/**
 *
 */
export class PharmacyGroupApi extends runtime.BaseAPI {
  /**
   * Get pharmacy group
   */
  async getPharmacyGroupRaw(
    requestParameters: GetPharmacyGroupRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacyGroup>> {
    if (requestParameters.pharmacyGroupId === null || requestParameters.pharmacyGroupId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyGroupId",
        "Required parameter requestParameters.pharmacyGroupId was null or undefined when calling getPharmacyGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacy-groups/{pharmacy_group_id}`.replace(
          `{${"pharmacy_group_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyGroupId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacyGroupFromJSON(jsonValue));
  }

  /**
   * Get pharmacy group
   */
  async getPharmacyGroup(
    requestParameters: GetPharmacyGroupRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacyGroup> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyGroupRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register pharmacy group
   */
  async registerPharmacyGroupRaw(
    requestParameters: RegisterPharmacyGroupRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacyGroup>>> {
    if (
      requestParameters.pharmacyPharmacyGroupRegistrable === null ||
      requestParameters.pharmacyPharmacyGroupRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyPharmacyGroupRegistrable",
        "Required parameter requestParameters.pharmacyPharmacyGroupRegistrable was null or undefined when calling registerPharmacyGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacy-groups`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.pharmacyPharmacyGroupRegistrable.map(PharmacyPharmacyGroupRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyGroupFromJSON));
  }

  /**
   * Register pharmacy group
   */
  async registerPharmacyGroup(
    requestParameters: RegisterPharmacyGroupRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacyGroup>> {
    return promiseRetry(
      (retry) =>
        this.registerPharmacyGroupRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search pharmacy groups
   */
  async searchPharmacyGroupsRaw(
    requestParameters: SearchPharmacyGroupsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacyGroup>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling searchPharmacyGroups."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacy-groups`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyGroupFromJSON));
  }

  /**
   * Search pharmacy groups
   */
  async searchPharmacyGroups(
    requestParameters: SearchPharmacyGroupsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacyGroup>> {
    return promiseRetry(
      (retry) =>
        this.searchPharmacyGroupsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update pharmacy group
   */
  async updatePharmacyGroupRaw(
    requestParameters: UpdatePharmacyGroupRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyPharmacyGroup>> {
    if (requestParameters.pharmacyGroupId === null || requestParameters.pharmacyGroupId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyGroupId",
        "Required parameter requestParameters.pharmacyGroupId was null or undefined when calling updatePharmacyGroup."
      );
    }

    if (
      requestParameters.pharmacyPharmacyGroupUpdatable === null ||
      requestParameters.pharmacyPharmacyGroupUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyPharmacyGroupUpdatable",
        "Required parameter requestParameters.pharmacyPharmacyGroupUpdatable was null or undefined when calling updatePharmacyGroup."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacy-groups/{pharmacy_group_id}`.replace(
          `{${"pharmacy_group_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyGroupId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyPharmacyGroupUpdatableToJSON(requestParameters.pharmacyPharmacyGroupUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyPharmacyGroupFromJSON(jsonValue));
  }

  /**
   * Update pharmacy group
   */
  async updatePharmacyGroup(
    requestParameters: UpdatePharmacyGroupRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyPharmacyGroup> {
    return promiseRetry(
      (retry) =>
        this.updatePharmacyGroupRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
