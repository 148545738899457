import clsx from "clsx";
import React, { PropsWithChildren } from "react";

import { MessagePlain } from "../MessagePlain";
import * as styles from "./InputFieldMessage.module.css";

export type InputFieldMessageProps = PropsWithChildren<{
  id: string;
  error?: boolean;
  className?: string;
}>;

export const InputFieldMessage: React.FC<InputFieldMessageProps> = ({ id, error, children, className }) => (
  <MessagePlain
    id={id}
    variant={error ? "caution" : "weak"}
    aria-live="polite"
    className={clsx(styles.message, className)}
  >
    {children || "\u00A0"}
  </MessagePlain>
);

export type CommonInputWithMessageProps = {
  message?: React.ReactNode;
  noMessageArea?: boolean;
};
