/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostLinkPatientAuthenticationRequestBodyTypename = "PostLinkPatientAuthenticationRequestBody" as const;

/**
 *
 * @export
 * @interface PostLinkPatientAuthenticationRequestBody
 */
export interface PostLinkPatientAuthenticationRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostLinkPatientAuthenticationRequestBody
   */
  linkUserToken: string;
  /**
   *
   * @type {string}
   * @memberof PostLinkPatientAuthenticationRequestBody
   */
  netUserId?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostLinkPatientAuthenticationRequestBody
   */
  readonly __typename?: string;
}

export function PostLinkPatientAuthenticationRequestBodyFromJSON(json: any): PostLinkPatientAuthenticationRequestBody {
  return PostLinkPatientAuthenticationRequestBodyFromJSONTyped(json, false);
}

export function PostLinkPatientAuthenticationRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostLinkPatientAuthenticationRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostLinkPatientAuthenticationRequestBodyTypename,

    linkUserToken: json["link_user_token"],
    netUserId: !exists(json, "net_user_id") ? undefined : json["net_user_id"],
  };
}

export function PostLinkPatientAuthenticationRequestBodyToJSON(
  object?: PostLinkPatientAuthenticationRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    link_user_token: value.linkUserToken,
    net_user_id: value.netUserId,
  };
}
