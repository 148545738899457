/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const AdminPostOperatorValueTypename = "AdminPostOperatorValue" as const;

/**
 *
 * @export
 * @interface AdminPostOperatorValue
 */
export interface AdminPostOperatorValue {
  /**
   *
   * @type {string}
   * @memberof AdminPostOperatorValue
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof AdminPostOperatorValue
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof AdminPostOperatorValue
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof AdminPostOperatorValue
   */
  password: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof AdminPostOperatorValue
   */
  readonly __typename?: string;
}

export function AdminPostOperatorValueFromJSON(json: any): AdminPostOperatorValue {
  return AdminPostOperatorValueFromJSONTyped(json, false);
}

export function AdminPostOperatorValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminPostOperatorValue {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: AdminPostOperatorValueTypename,

    email: json["email"],
    firstName: json["first_name"],
    lastName: json["last_name"],
    password: json["password"],
  };
}

export function AdminPostOperatorValueToJSON(object?: AdminPostOperatorValue | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    password: value.password,
  };
}
