/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientRefreshTokenTypename = "PatientRefreshToken" as const;

/**
 *
 * @export
 * @interface PatientRefreshToken
 */
export interface PatientRefreshToken {
  /**
   *
   * @type {Date}
   * @memberof PatientRefreshToken
   */
  expiresIn?: string;
  /**
   *
   * @type {string}
   * @memberof PatientRefreshToken
   */
  token?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientRefreshToken
   */
  readonly __typename?: string;
}

export function PatientRefreshTokenFromJSON(json: any): PatientRefreshToken {
  return PatientRefreshTokenFromJSONTyped(json, false);
}

export function PatientRefreshTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientRefreshToken {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientRefreshTokenTypename,

    expiresIn:
      !exists(json, "expires_in") ||
      (typeof json["expires_in"] === "string" && json["expires_in"].substring(0, 4) === "0001")
        ? undefined
        : json["expires_in"],
    token: !exists(json, "token") ? undefined : json["token"],
  };
}

export function PatientRefreshTokenToJSON(object?: PatientRefreshToken | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    expires_in: value.expiresIn === undefined ? undefined : moment(value.expiresIn).tz("Asia/Tokyo").format(),
    token: value.token,
  };
}
