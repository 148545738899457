import { errorHandlerReport } from "@mgdx-libs/error-handler";
import { logger } from "@mgdx-libs/logger";
import type { GatsbyBrowser } from "gatsby";
import * as ReactDOM from "react-dom/client";

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  window.global = window;

  window.addEventListener("error", async (event: unknown) => {
    if (event instanceof ErrorEvent) {
      await errorHandlerReport({
        ...event.error,
        name: `${event.filename} (${event.lineno}:${event.colno})`,
        message: event.message,
      });
    } else if (event instanceof Event || event instanceof UIEvent) {
      await errorHandlerReport(`window.error: ${event.type} ${event.target}`);
    }
  });
  window.addEventListener("unhandledrejection", async (event) => {
    if (event) {
      await errorHandlerReport(`Unhandled promise rejection.  ${event.reason}`);
    }
  });

  if (process.env.ENABLE_DEBUG === "true") {
    localStorage.debug = "app:*";
  } else {
    localStorage.debug = "";
  }
};

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = (args, _options) => {
  const { routerProps, prevRouterProps } = args;

  if (routerProps && prevRouterProps) {
    if (routerProps.location.pathname === prevRouterProps.location.pathname) {
      logger.debug("Same location. Do not update scroll position. %o", args);
      return false;
    }
  }

  return true;
};

// @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
// Replace the ReactDOM.createRoot / render function.
export const replaceHydrateFunction: GatsbyBrowser["replaceHydrateFunction"] =
  (_args, _options) => (element, container, _callback) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
    return element;
  };
