/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientServiceNeeds,
  PatientServiceNeedsFromJSON,
  PatientServiceNeedsFromJSONTyped,
  PatientServiceNeedsToJSON,
} from "./";

export const PatchPatientRequestBodyTypename = "PatchPatientRequestBody" as const;

/**
 *
 * @export
 * @interface PatchPatientRequestBody
 */
export interface PatchPatientRequestBody {
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  dateOfBirth?: string;
  /**
   *
   * @type {object}
   * @memberof PatchPatientRequestBody
   */
  externalIds?: object;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  gender?: PatchPatientRequestBodyGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  hasMedicineNotebook?: PatchPatientRequestBodyHasMedicineNotebookEnum;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  insuranceCard?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  notifyType?: PatchPatientRequestBodyNotifyTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof PatchPatientRequestBody
   */
  otherMedicalImages?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  postalCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatchPatientRequestBody
   */
  sendFollowUp?: boolean;
  /**
   *
   * @type {PatientServiceNeeds}
   * @memberof PatchPatientRequestBody
   */
  serviceNeeds?: PatientServiceNeeds;
  /**
   *
   * @type {boolean}
   * @memberof PatchPatientRequestBody
   */
  smsAvailable?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  tel?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  wantsGeneric?: PatchPatientRequestBodyWantsGenericEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchPatientRequestBodyGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchPatientRequestBodyHasMedicineNotebookEnum {
  None = "none",
  Paper = "paper",
  Digital = "digital",
  Yqb = "yqb",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchPatientRequestBodyNotifyTypeEnum {
  EmailOrSms = "email_or_sms",
  App = "app",
  All = "all",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchPatientRequestBodyWantsGenericEnum {
  None = "none",
  Want = "want",
  Consultation = "consultation",
}

export function PatchPatientRequestBodyFromJSON(json: any): PatchPatientRequestBody {
  return PatchPatientRequestBodyFromJSONTyped(json, false);
}

export function PatchPatientRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchPatientRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchPatientRequestBodyTypename,

    addressLine1: !exists(json, "address_line1") ? undefined : json["address_line1"],
    addressLine2: !exists(json, "address_line2") ? undefined : json["address_line2"],
    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    externalIds: !exists(json, "external_ids") ? undefined : json["external_ids"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    hasMedicineNotebook: !exists(json, "has_medicine_notebook") ? undefined : json["has_medicine_notebook"],
    insuranceCard: !exists(json, "insurance_card") ? undefined : json["insurance_card"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    notifyType: !exists(json, "notify_type") ? undefined : json["notify_type"],
    otherMedicalImages: !exists(json, "other_medical_images") ? undefined : json["other_medical_images"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    sendFollowUp: !exists(json, "send_follow_up") ? undefined : json["send_follow_up"],
    serviceNeeds: !exists(json, "service_needs") ? undefined : PatientServiceNeedsFromJSON(json["service_needs"]),
    smsAvailable: !exists(json, "sms_available") ? undefined : json["sms_available"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
    wantsGeneric: !exists(json, "wants_generic") ? undefined : json["wants_generic"],
  };
}

export function PatchPatientRequestBodyToJSON(object?: PatchPatientRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    date_of_birth: value.dateOfBirth,
    external_ids: value.externalIds,
    first_name: value.firstName,
    gender: value.gender,
    has_medicine_notebook: value.hasMedicineNotebook,
    insurance_card: value.insuranceCard,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    notify_type: value.notifyType,
    other_medical_images: value.otherMedicalImages,
    postal_code: value.postalCode,
    send_follow_up: value.sendFollowUp,
    service_needs: PatientServiceNeedsToJSON(value.serviceNeeds),
    sms_available: value.smsAvailable,
    tel: value.tel,
    wants_generic: value.wantsGeneric,
  };
}
