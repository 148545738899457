import appName from "@mgdx/shared/src/appName";
import appVersion from "@mgdx/shared/src/appVersion";

import { HTTPHeaders } from "./runtime";

const requestHeaders: HTTPHeaders = {
  "X-App-Version": appVersion,
  "X-MGDX-APP-NAME": appName,
};

export default requestHeaders;
