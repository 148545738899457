import { createFirestoreDataConverter, getFirebaseFirestore, useCollection } from "@mgdx-libs/firebase";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useMemo } from "react";

import { ROOM_COLLECTION_NAME } from "../constants";
import { TalkRoomDocument, TalkRoomDocumentWithId } from "../models";

const roomConverter = createFirestoreDataConverter<TalkRoomDocument>();

export type UseTalkRoomsCollectionProps = {
  patientId?: number;
  clinicId?: number;
  pharmacyId?: number;
};

export type UseTalkRoomsCollection = (props: UseTalkRoomsCollectionProps) => {
  rooms?: TalkRoomDocumentWithId[];
  isLoading: boolean;
};

export const useTalkRoomsCollection: UseTalkRoomsCollection = ({ patientId, clinicId, pharmacyId }) => {
  const [rooms, isLoading] = useCollection(
    query(
      collection(getFirebaseFirestore(), ROOM_COLLECTION_NAME),
      patientId
        ? where("patient.id", "==", patientId)
        : clinicId
        ? where("clinic.id", "==", clinicId)
        : pharmacyId
        ? where("pharmacy.id", "==", pharmacyId)
        : where("id", "==", "none"),
      orderBy(patientId ? "patientUnreadMessageCount" : "medicalUnreadMessageCount", "desc"),
      orderBy("timestamp", "desc")
    ).withConverter(roomConverter),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return useMemo(
    () => ({
      rooms: rooms?.docs.map((doc) => doc.data()),
      isLoading,
    }),
    [isLoading, rooms]
  );
};
