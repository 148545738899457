import { createAction, createReducer } from "@reduxjs/toolkit";

export type UiState = {
  isSearchMenu: boolean;
  isOpenFooterMenu: boolean;
  unreadCounselingCount?: number;
  isWatchPayment: boolean;
};

const initialState: UiState = {
  isSearchMenu: false,
  isOpenFooterMenu: false,
  unreadCounselingCount: undefined,
  isWatchPayment: false,
};

export const openSearch = createAction("ui/openSearch");
export const closeSearch = createAction("ui/closeSearch");
export const openFooterMenu = createAction("ui/openFooterMenu");
export const closeFooterMenu = createAction("ui/closeFooterMenu");
export const setUnreadCounselingCount = createAction<number>("ui/setUnreadCounselingCount");
export const setWatchPayment = createAction<boolean>("ui/setWatchPayment");

const uiReducer = createReducer<UiState>(initialState, (builder) => {
  builder
    .addCase(openSearch, (state, action) => {
      state.isSearchMenu = true;
      state.isOpenFooterMenu = false;
    })
    .addCase(closeSearch, (state, action) => {
      state.isSearchMenu = false;
    })
    .addCase(openFooterMenu, (state, action) => {
      state.isSearchMenu = false;
      state.isOpenFooterMenu = true;
    })
    .addCase(closeFooterMenu, (state, action) => {
      state.isOpenFooterMenu = false;
    })
    .addCase(setUnreadCounselingCount, (state, action) => {
      state.unreadCounselingCount = action.payload;
    })
    .addCase(setWatchPayment, (state, action) => {
      state.isWatchPayment = action.payload;
    });
});

export default uiReducer;
