/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatchAcceptCodeResponseTypename = "PatchAcceptCodeResponse" as const;

/**
 *
 * @export
 * @interface PatchAcceptCodeResponse
 */
export interface PatchAcceptCodeResponse {
  /**
   *
   * @type {number}
   * @memberof PatchAcceptCodeResponse
   */
  acceptCode?: number;
  /**
   *
   * @type {string}
   * @memberof PatchAcceptCodeResponse
   */
  dispensingType?: PatchAcceptCodeResponseDispensingTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatchAcceptCodeResponse
   */
  isVisible?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchAcceptCodeResponse
   */
  type: PatchAcceptCodeResponseTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchAcceptCodeResponse
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchAcceptCodeResponseDispensingTypeEnum {
  Normal = "normal",
  Quick = "quick",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchAcceptCodeResponseTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  DirectVisit = "direct_visit",
}

export function PatchAcceptCodeResponseFromJSON(json: any): PatchAcceptCodeResponse {
  return PatchAcceptCodeResponseFromJSONTyped(json, false);
}

export function PatchAcceptCodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchAcceptCodeResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchAcceptCodeResponseTypename,

    acceptCode: !exists(json, "accept_code") ? undefined : json["accept_code"],
    dispensingType: !exists(json, "dispensing_type") ? undefined : json["dispensing_type"],
    isVisible: !exists(json, "is_visible") ? undefined : json["is_visible"],
    type: json["type"],
  };
}

export function PatchAcceptCodeResponseToJSON(object?: PatchAcceptCodeResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    accept_code: value.acceptCode,
    dispensing_type: value.dispensingType,
    is_visible: value.isVisible,
    type: value.type,
  };
}
