/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutPatientEmailRequestBodyTypename = "PutPatientEmailRequestBody" as const;

/**
 *
 * @export
 * @interface PutPatientEmailRequestBody
 */
export interface PutPatientEmailRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutPatientEmailRequestBody
   */
  email: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutPatientEmailRequestBody
   */
  readonly __typename?: string;
}

export function PutPatientEmailRequestBodyFromJSON(json: any): PutPatientEmailRequestBody {
  return PutPatientEmailRequestBodyFromJSONTyped(json, false);
}

export function PutPatientEmailRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutPatientEmailRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutPatientEmailRequestBodyTypename,

    email: json["email"],
  };
}

export function PutPatientEmailRequestBodyToJSON(object?: PutPatientEmailRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    email: value.email,
  };
}
