import RefreshIcon, { Props as RefreshIconProps } from "@mgdx/assets/icons/RefreshIcon";
import clsx from "clsx";
import React from "react";

import * as styles from "./CircularProgress.module.css";

export type Props = RefreshIconProps;

const CircularProgress = ({ className, ...props }: Props) => (
  <RefreshIcon className={clsx(styles.circularProgress, className)} {...props} />
);

export default CircularProgress;
