/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicSymptomFeedTypename = "ClinicSymptomFeed" as const;

/**
 *
 * @export
 * @interface ClinicSymptomFeed
 */
export interface ClinicSymptomFeed {
  /**
   *
   * @type {number}
   * @memberof ClinicSymptomFeed
   */
  id?: number;
  /**
   *
   * @type {boolean}
   * @memberof ClinicSymptomFeed
   */
  isDisplay?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClinicSymptomFeed
   */
  name?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicSymptomFeed
   */
  readonly __typename?: string;
}

export function ClinicSymptomFeedFromJSON(json: any): ClinicSymptomFeed {
  return ClinicSymptomFeedFromJSONTyped(json, false);
}

export function ClinicSymptomFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicSymptomFeed {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicSymptomFeedTypename,

    id: !exists(json, "id") ? undefined : json["id"],
    isDisplay: !exists(json, "is_display") ? undefined : json["is_display"],
    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function ClinicSymptomFeedToJSON(object?: ClinicSymptomFeed | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    is_display: value.isDisplay,
    name: value.name,
  };
}
