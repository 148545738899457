export const PharmacyCustomInterviewOptionTypename = "PharmacyCustomInterviewOption" as const;

/**
 *
 * @export
 * @interface PharmacyCustomInterviewOption
 * @see PharmacyCustomInterviewOption
 */
export interface PharmacyCustomInterviewOption {
  /**
   *
   * @type {string}
   * @memberof PharmacyCustomInterviewOption
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCustomInterviewOption
   */
  value: string;

  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCustomInterviewOption
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewOptionFromJSON(json: any): PharmacyCustomInterviewOption {
  return PharmacyCustomInterviewOptionFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewOptionFromJSONTyped(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCustomInterviewOption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCustomInterviewOptionTypename,
    label: json["label"],
    value: json["value"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewOptionToJSON(object?: PharmacyCustomInterviewOption | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    label: value.label,
    value: value.value,
  };
}
