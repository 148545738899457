/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ChatterTextMessagePatient,
  ChatterTextMessagePatientFromJSON,
  ChatterTextMessagePatientFromJSONTyped,
  ChatterTextMessagePatientToJSON,
} from "./";

export const ChatterTextMessageRegistrableTypename = "ChatterTextMessageRegistrable" as const;

/**
 *
 * @export
 * @interface ChatterTextMessageRegistrable
 */
export interface ChatterTextMessageRegistrable {
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessageRegistrable
   */
  message: string;
  /**
   *
   * @type {ChatterTextMessagePatient}
   * @memberof ChatterTextMessageRegistrable
   */
  patient: ChatterTextMessagePatient;
  /**
   *
   * @type {Date}
   * @memberof ChatterTextMessageRegistrable
   */
  sendTime: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterTextMessageRegistrable
   */
  readonly __typename?: string;
}

export function ChatterTextMessageRegistrableFromJSON(json: any): ChatterTextMessageRegistrable {
  return ChatterTextMessageRegistrableFromJSONTyped(json, false);
}

export function ChatterTextMessageRegistrableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChatterTextMessageRegistrable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterTextMessageRegistrableTypename,

    message: json["message"],
    patient: ChatterTextMessagePatientFromJSON(json["patient"]),
    sendTime: json["send_time"],
  };
}

export function ChatterTextMessageRegistrableToJSON(object?: ChatterTextMessageRegistrable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    message: value.message,
    patient: ChatterTextMessagePatientToJSON(value.patient),
    send_time: moment(value.sendTime).tz("Asia/Tokyo").format(),
  };
}
