import { detect } from "detect-browser";
import { useMemo } from "react";

export type UseIsPCPlatformProps = {
  withoutMacOs?: boolean;
};

export type UseIsPCPlatform = (props?: UseIsPCPlatformProps) => boolean;

export const useIsPCPlatform: UseIsPCPlatform = ({ withoutMacOs } = {}) => {
  const isSupportBrowser = useMemo<boolean>(() => {
    const browser = detect();
    if (!browser || !browser.os) return false;

    if (["iOS", "Android OS", "BlackBerry OS", "Windows Mobile", "Amazon OS"].includes(browser.os)) return false;

    if (withoutMacOs && browser.os === "Mac OS") return false;

    return true;
  }, [withoutMacOs]);

  return isSupportBrowser;
};
