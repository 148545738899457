/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorCustomBadRequest3Typename = "PharmacyCounselorCustomBadRequest3" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCustomBadRequest3
 */
export interface PharmacyCounselorCustomBadRequest3 {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCustomBadRequest3
   */
  code: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCustomBadRequest3
   */
  detailCode?: PharmacyCounselorCustomBadRequest3DetailCodeEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCustomBadRequest3
   */
  message: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCustomBadRequest3
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCustomBadRequest3DetailCodeEnum {
  PCounselorE005 = "P-COUNSELOR-E005",
}

export function PharmacyCounselorCustomBadRequest3FromJSON(json: any): PharmacyCounselorCustomBadRequest3 {
  return PharmacyCounselorCustomBadRequest3FromJSONTyped(json, false);
}

export function PharmacyCounselorCustomBadRequest3FromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCustomBadRequest3 {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCustomBadRequest3Typename,

    code: json["code"],
    detailCode: !exists(json, "detail_code") ? undefined : json["detail_code"],
    message: json["message"],
  };
}

export function PharmacyCounselorCustomBadRequest3ToJSON(object?: PharmacyCounselorCustomBadRequest3 | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    code: value.code,
    detail_code: value.detailCode,
    message: value.message,
  };
}
