import { createHandleRequestConfig } from "@mgdx-libs/redux-requests";
import { handleRequests } from "@redux-requests/core";

export * from "./clinic";
export * from "./clinicCounseling";
export * from "./creditCard";
export * from "./familyAccount";
export * from "./familyPharmacist";
export * from "./followup";
export * from "./linkedPatient";
export * from "./medicineNotebookAutoLink";
export * from "./patient";
export * from "./payment";
export * from "./pharmacy";
export * from "./pharmacyCounseling";
export * from "./platformDelivery";
export * from "./qolOkusuri";
export * from "./recaptcha";
export * from "./zendesk";

const requests = handleRequests(createHandleRequestConfig());

export const requestsMiddleware = requests.requestsMiddleware;

const requestsReducer = requests.requestsReducer;

export default requestsReducer;
