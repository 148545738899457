/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorDispensingTypename = "PharmacyCounselorDispensing" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorDispensing
 */
export interface PharmacyCounselorDispensing {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorDispensing
   */
  completedMessage?: string;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorDispensing
   */
  notifiedTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorDispensing
   */
  status?: PharmacyCounselorDispensingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorDispensing
   */
  type?: PharmacyCounselorDispensingTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorDispensing
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorDispensingStatusEnum {
  Initialized = "initialized",
  Dispensed = "dispensed",
  Notified = "notified",
  Completed = "completed",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorDispensingTypeEnum {
  Normal = "normal",
  Quick = "quick",
}

export function PharmacyCounselorDispensingFromJSON(json: any): PharmacyCounselorDispensing {
  return PharmacyCounselorDispensingFromJSONTyped(json, false);
}

export function PharmacyCounselorDispensingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorDispensing {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorDispensingTypename,

    completedMessage: !exists(json, "completed_message") ? undefined : json["completed_message"],
    notifiedTime:
      !exists(json, "notified_time") ||
      (typeof json["notified_time"] === "string" && json["notified_time"].substring(0, 4) === "0001")
        ? undefined
        : json["notified_time"],
    status: !exists(json, "status") ? undefined : json["status"],
    type: !exists(json, "type") ? undefined : json["type"],
  };
}

export function PharmacyCounselorDispensingToJSON(object?: PharmacyCounselorDispensing | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    completed_message: value.completedMessage,
    notified_time: value.notifiedTime === undefined ? undefined : moment(value.notifiedTime).tz("Asia/Tokyo").format(),
    status: value.status,
    type: value.type,
  };
}
