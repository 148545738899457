/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicCounselorTimeTypename = "ClinicCounselorTime" as const;

/**
 *
 * @export
 * @interface ClinicCounselorTime
 */
export interface ClinicCounselorTime {
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorTime
   */
  date?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorTime
   */
  from?: string;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorTime
   */
  slotNumber?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorTime
   */
  to?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorTime
   */
  readonly __typename?: string;
}

export function ClinicCounselorTimeFromJSON(json: any): ClinicCounselorTime {
  return ClinicCounselorTimeFromJSONTyped(json, false);
}

export function ClinicCounselorTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicCounselorTime {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorTimeTypename,

    date: !exists(json, "date") ? undefined : json["date"],
    from: !exists(json, "from") ? undefined : json["from"],
    slotNumber: !exists(json, "slot_number") ? undefined : json["slot_number"],
    to: !exists(json, "to") ? undefined : json["to"],
  };
}

export function ClinicCounselorTimeToJSON(object?: ClinicCounselorTime | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date: value.date,
    from: value.from,
    slot_number: value.slotNumber,
    to: value.to,
  };
}
