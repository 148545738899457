/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyControlPanelAutoPrinter,
  PharmacyControlPanelAutoPrinterFromJSON,
  PharmacyControlPanelAutoPrinterFromJSONTyped,
  PharmacyControlPanelAutoPrinterToJSON,
  PharmacyControlPanelBrowser,
  PharmacyControlPanelBrowserFromJSON,
  PharmacyControlPanelBrowserFromJSONTyped,
  PharmacyControlPanelBrowserToJSON,
  PharmacyControlPanelCounselingAutoPrinter,
  PharmacyControlPanelCounselingAutoPrinterFromJSON,
  PharmacyControlPanelCounselingAutoPrinterFromJSONTyped,
  PharmacyControlPanelCounselingAutoPrinterToJSON,
  PharmacyControlPanelProxy,
  PharmacyControlPanelProxyFromJSON,
  PharmacyControlPanelProxyFromJSONTyped,
  PharmacyControlPanelProxyToJSON,
} from "./";

export const PharmacyControlPanelEnvironmentTypename = "PharmacyControlPanelEnvironment" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanelEnvironment
 */
export interface PharmacyControlPanelEnvironment {
  /**
   *
   * @type {PharmacyControlPanelBrowser}
   * @memberof PharmacyControlPanelEnvironment
   */
  browser?: PharmacyControlPanelBrowser;
  /**
   *
   * @type {PharmacyControlPanelCounselingAutoPrinter}
   * @memberof PharmacyControlPanelEnvironment
   */
  counselingAutoPrinter?: PharmacyControlPanelCounselingAutoPrinter;
  /**
   *
   * @type {PharmacyControlPanelAutoPrinter}
   * @memberof PharmacyControlPanelEnvironment
   */
  directVisitAutoPrinter?: PharmacyControlPanelAutoPrinter;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelEnvironment
   */
  enableDirectVisitOnly?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelEnvironment
   */
  enableRequestLoadTime?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanelEnvironment
   */
  printPageFormat?: string;
  /**
   *
   * @type {PharmacyControlPanelProxy}
   * @memberof PharmacyControlPanelEnvironment
   */
  proxy?: PharmacyControlPanelProxy;
  /**
   *
   * @type {number}
   * @memberof PharmacyControlPanelEnvironment
   */
  requestLoadTime?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanelEnvironment
   */
  readonly __typename?: string;
}

export function PharmacyControlPanelEnvironmentFromJSON(json: any): PharmacyControlPanelEnvironment {
  return PharmacyControlPanelEnvironmentFromJSONTyped(json, false);
}

export function PharmacyControlPanelEnvironmentFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyControlPanelEnvironment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelEnvironmentTypename,

    browser: !exists(json, "browser") ? undefined : PharmacyControlPanelBrowserFromJSON(json["browser"]),
    counselingAutoPrinter: !exists(json, "counseling_auto_printer")
      ? undefined
      : PharmacyControlPanelCounselingAutoPrinterFromJSON(json["counseling_auto_printer"]),
    directVisitAutoPrinter: !exists(json, "direct_visit_auto_printer")
      ? undefined
      : PharmacyControlPanelAutoPrinterFromJSON(json["direct_visit_auto_printer"]),
    enableDirectVisitOnly: !exists(json, "enable_direct_visit_only") ? undefined : json["enable_direct_visit_only"],
    enableRequestLoadTime: !exists(json, "enable_request_load_time") ? undefined : json["enable_request_load_time"],
    printPageFormat: !exists(json, "print_page_format") ? undefined : json["print_page_format"],
    proxy: !exists(json, "proxy") ? undefined : PharmacyControlPanelProxyFromJSON(json["proxy"]),
    requestLoadTime: !exists(json, "request_load_time") ? undefined : json["request_load_time"],
  };
}

export function PharmacyControlPanelEnvironmentToJSON(object?: PharmacyControlPanelEnvironment | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    browser: PharmacyControlPanelBrowserToJSON(value.browser),
    counseling_auto_printer: PharmacyControlPanelCounselingAutoPrinterToJSON(value.counselingAutoPrinter),
    direct_visit_auto_printer: PharmacyControlPanelAutoPrinterToJSON(value.directVisitAutoPrinter),
    enable_direct_visit_only: value.enableDirectVisitOnly,
    enable_request_load_time: value.enableRequestLoadTime,
    print_page_format: value.printPageFormat,
    proxy: PharmacyControlPanelProxyToJSON(value.proxy),
    request_load_time: value.requestLoadTime,
  };
}
