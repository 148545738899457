/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutLinkedPatientNotificationAuthorizationRequestBodyTypename =
  "PutLinkedPatientNotificationAuthorizationRequestBody" as const;

/**
 *
 * @export
 * @interface PutLinkedPatientNotificationAuthorizationRequestBody
 */
export interface PutLinkedPatientNotificationAuthorizationRequestBody {
  /**
   *
   * @type {number}
   * @memberof PutLinkedPatientNotificationAuthorizationRequestBody
   */
  id?: number;
  /**
   *
   * @type {object}
   * @memberof PutLinkedPatientNotificationAuthorizationRequestBody
   */
  notificationAuthorization?: object;
  /**
   *
   * @type {string}
   * @memberof PutLinkedPatientNotificationAuthorizationRequestBody
   */
  patientProviderTypeId?: PutLinkedPatientNotificationAuthorizationRequestBodyPatientProviderTypeIdEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutLinkedPatientNotificationAuthorizationRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PutLinkedPatientNotificationAuthorizationRequestBodyPatientProviderTypeIdEnum {
  Sundrug = "line-sundrug",
  Qol = "line-qol",
  Tanpopo = "line-tanpopo",
}

export function PutLinkedPatientNotificationAuthorizationRequestBodyFromJSON(
  json: any
): PutLinkedPatientNotificationAuthorizationRequestBody {
  return PutLinkedPatientNotificationAuthorizationRequestBodyFromJSONTyped(json, false);
}

export function PutLinkedPatientNotificationAuthorizationRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutLinkedPatientNotificationAuthorizationRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutLinkedPatientNotificationAuthorizationRequestBodyTypename,

    id: !exists(json, "id") ? undefined : json["id"],
    notificationAuthorization: !exists(json, "notification_authorization")
      ? undefined
      : json["notification_authorization"],
    patientProviderTypeId: !exists(json, "patient_provider_type_id") ? undefined : json["patient_provider_type_id"],
  };
}

export function PutLinkedPatientNotificationAuthorizationRequestBodyToJSON(
  object?: PutLinkedPatientNotificationAuthorizationRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    notification_authorization: value.notificationAuthorization,
    patient_provider_type_id: value.patientProviderTypeId,
  };
}
