/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineMaster,
  PatientMedicineMasterFromJSON,
  PatientMedicineMasterFromJSONTyped,
  PatientMedicineMasterToJSON,
} from "./";

export const PatientMedicineTypename = "PatientMedicine" as const;

/**
 *
 * @export
 * @interface PatientMedicine
 */
export interface PatientMedicine {
  /**
   *
   * @type {Array<string>}
   * @memberof PatientMedicine
   */
  comment?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PatientMedicine
   */
  hidden?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatientMedicine
   */
  hotCode?: string;
  /**
   *
   * @type {PatientMedicineMaster}
   * @memberof PatientMedicine
   */
  master?: PatientMedicineMaster;
  /**
   *
   * @type {number}
   * @memberof PatientMedicine
   */
  medicineId?: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicine
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicine
   */
  quantity?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicine
   */
  receiptCode?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicine
   */
  unit?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicine
   */
  yjCode?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicine
   */
  readonly __typename?: string;
}

export function PatientMedicineFromJSON(json: any): PatientMedicine {
  return PatientMedicineFromJSONTyped(json, false);
}

export function PatientMedicineFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMedicine {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineTypename,

    comment: !exists(json, "comment") ? undefined : json["comment"],
    hidden: !exists(json, "hidden") ? undefined : json["hidden"],
    hotCode: !exists(json, "hot_code") ? undefined : json["hot_code"],
    master: !exists(json, "master") ? undefined : PatientMedicineMasterFromJSON(json["master"]),
    medicineId: !exists(json, "medicine_id") ? undefined : json["medicine_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    quantity: !exists(json, "quantity") ? undefined : json["quantity"],
    receiptCode: !exists(json, "receipt_code") ? undefined : json["receipt_code"],
    unit: !exists(json, "unit") ? undefined : json["unit"],
    yjCode: !exists(json, "yj_code") ? undefined : json["yj_code"],
  };
}

export function PatientMedicineToJSON(object?: PatientMedicine | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    comment: value.comment,
    hidden: value.hidden,
    hot_code: value.hotCode,
    master: PatientMedicineMasterToJSON(value.master),
    medicine_id: value.medicineId,
    name: value.name,
    quantity: value.quantity,
    receipt_code: value.receiptCode,
    unit: value.unit,
    yj_code: value.yjCode,
  };
}
