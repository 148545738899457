/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorPharmacyTypename = "PharmacyCounselorPharmacy" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorPharmacy
 */
export interface PharmacyCounselorPharmacy {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorPharmacy
   */
  chainId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPharmacy
   */
  chainName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPharmacy
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorPharmacy
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPharmacy
   */
  name: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorPharmacy
   */
  readonly __typename?: string;
}

export function PharmacyCounselorPharmacyFromJSON(json: any): PharmacyCounselorPharmacy {
  return PharmacyCounselorPharmacyFromJSONTyped(json, false);
}

export function PharmacyCounselorPharmacyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorPharmacy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorPharmacyTypename,

    chainId: json["chain_id"],
    chainName: json["chain_name"],
    email: json["email"],
    id: json["id"],
    name: json["name"],
  };
}

export function PharmacyCounselorPharmacyToJSON(object?: PharmacyCounselorPharmacy | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    chain_name: value.chainName,
    email: value.email,
    id: value.id,
    name: value.name,
  };
}
