/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientMasterVaccineTypename = "PatientMasterVaccine" as const;

/**
 *
 * @export
 * @interface PatientMasterVaccine
 */
export interface PatientMasterVaccine {
  /**
   *
   * @type {string}
   * @memberof PatientMasterVaccine
   */
  masterCode: string;
  /**
   *
   * @type {string}
   * @memberof PatientMasterVaccine
   */
  masterGender?: PatientMasterVaccineMasterGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PatientMasterVaccine
   */
  masterName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMasterVaccine
   */
  masterSeason?: PatientMasterVaccineMasterSeasonEnum;
  /**
   *
   * @type {string}
   * @memberof PatientMasterVaccine
   */
  masterVaccineType?: PatientMasterVaccineMasterVaccineTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMasterVaccine
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMasterVaccineMasterGenderEnum {
  Both = "both",
  Male = "male",
  Female = "female",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientMasterVaccineMasterSeasonEnum {
  Regular = "regular",
  Option = "option",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientMasterVaccineMasterVaccineTypeEnum {
  Inactivated = "inactivated",
  Live = "live",
}

export function PatientMasterVaccineFromJSON(json: any): PatientMasterVaccine {
  return PatientMasterVaccineFromJSONTyped(json, false);
}

export function PatientMasterVaccineFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMasterVaccine {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMasterVaccineTypename,

    masterCode: json["master_code"],
    masterGender: !exists(json, "master_gender") ? undefined : json["master_gender"],
    masterName: !exists(json, "master_name") ? undefined : json["master_name"],
    masterSeason: !exists(json, "master_season") ? undefined : json["master_season"],
    masterVaccineType: !exists(json, "master_vaccine_type") ? undefined : json["master_vaccine_type"],
  };
}

export function PatientMasterVaccineToJSON(object?: PatientMasterVaccine | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    master_code: value.masterCode,
    master_gender: value.masterGender,
    master_name: value.masterName,
    master_season: value.masterSeason,
    master_vaccine_type: value.masterVaccineType,
  };
}
