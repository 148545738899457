/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PayerShopAuthentication,
  PayerShopAuthenticationFromJSON,
  PayerShopAuthenticationFromJSONTyped,
  PayerShopAuthenticationToJSON,
} from "./";

export const PostShopsRequestBodyTypename = "PostShopsRequestBody" as const;

/**
 *
 * @export
 * @interface PostShopsRequestBody
 */
export interface PostShopsRequestBody {
  /**
   *
   * @type {PayerShopAuthentication}
   * @memberof PostShopsRequestBody
   */
  authentication: PayerShopAuthentication;
  /**
   *
   * @type {number}
   * @memberof PostShopsRequestBody
   */
  chainId?: number;
  /**
   *
   * @type {string}
   * @memberof PostShopsRequestBody
   */
  gmoShopId: string;
  /**
   *
   * @type {number}
   * @memberof PostShopsRequestBody
   */
  groupId?: number;
  /**
   *
   * @type {string}
   * @memberof PostShopsRequestBody
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PostShopsRequestBody
   */
  serviceId: string;
  /**
   *
   * @type {string}
   * @memberof PostShopsRequestBody
   */
  temporaryPassword: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostShopsRequestBody
   */
  readonly __typename?: string;
}

export function PostShopsRequestBodyFromJSON(json: any): PostShopsRequestBody {
  return PostShopsRequestBodyFromJSONTyped(json, false);
}

export function PostShopsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostShopsRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostShopsRequestBodyTypename,

    authentication: PayerShopAuthenticationFromJSON(json["authentication"]),
    chainId: !exists(json, "chain_id") ? undefined : json["chain_id"],
    gmoShopId: json["gmo_shop_id"],
    groupId: !exists(json, "group_id") ? undefined : json["group_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    serviceId: json["service_id"],
    temporaryPassword: json["temporary_password"],
  };
}

export function PostShopsRequestBodyToJSON(object?: PostShopsRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    authentication: PayerShopAuthenticationToJSON(value.authentication),
    chain_id: value.chainId,
    gmo_shop_id: value.gmoShopId,
    group_id: value.groupId,
    name: value.name,
    service_id: value.serviceId,
    temporary_password: value.temporaryPassword,
  };
}
