/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicTokushohoTypename = "ClinicTokushoho" as const;

/**
 *
 * @export
 * @interface ClinicTokushoho
 */
export interface ClinicTokushoho {
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  address?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  cancel?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  cost?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  deliveryTiming?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  payment?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  representative?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  sellingCost?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  serviceProvider?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicTokushoho
   */
  tel?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicTokushoho
   */
  readonly __typename?: string;
}

export function ClinicTokushohoFromJSON(json: any): ClinicTokushoho {
  return ClinicTokushohoFromJSONTyped(json, false);
}

export function ClinicTokushohoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicTokushoho {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicTokushohoTypename,

    address: !exists(json, "address") ? undefined : json["address"],
    cancel: !exists(json, "cancel") ? undefined : json["cancel"],
    cost: !exists(json, "cost") ? undefined : json["cost"],
    deliveryTiming: !exists(json, "delivery_timing") ? undefined : json["delivery_timing"],
    payment: !exists(json, "payment") ? undefined : json["payment"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    representative: !exists(json, "representative") ? undefined : json["representative"],
    sellingCost: !exists(json, "selling_cost") ? undefined : json["selling_cost"],
    serviceProvider: !exists(json, "service_provider") ? undefined : json["service_provider"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
  };
}

export function ClinicTokushohoToJSON(object?: ClinicTokushoho | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address: value.address,
    cancel: value.cancel,
    cost: value.cost,
    delivery_timing: value.deliveryTiming,
    payment: value.payment,
    postal_code: value.postalCode,
    representative: value.representative,
    selling_cost: value.sellingCost,
    service_provider: value.serviceProvider,
    tel: value.tel,
  };
}
