import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import { PhrAccountRequestConfig } from "../providers/PhrAccountProvider";

export const phrKeys = createQueryKeyStore({
  mynaLinkStatus: {
    detail: (requestConfig: PhrAccountRequestConfig) => [requestConfig],
  },
  personalHealthRecord: {
    allData: (requestConfig: PhrAccountRequestConfig) => [requestConfig],
    medicalExpenseNotification: (requestConfig: PhrAccountRequestConfig) => [requestConfig],
    medicalTreatment: (requestConfig: PhrAccountRequestConfig) => [requestConfig],
    specificHealthCheckup: (requestConfig: PhrAccountRequestConfig) => [requestConfig],
  },
  shareSetting: {
    detail: (requestConfig: PhrAccountRequestConfig) => [requestConfig],
  },
});
