import { getAccessToken } from "@mgdx-libs/firebase";

import { createFetchTimeout } from "./createFetchTimeout";
import { FamilyAccountApi } from "./patient/patient";
import requestHeaders from "./requestHeaders";
import { Configuration } from "./runtime";

const familyApi = new FamilyAccountApi(
  new Configuration({
    basePath: process.env.MGDX_API_PATIENT_URL,
    credentials: "include",
    accessToken: getAccessToken,
    headers: requestHeaders,
    fetchApi: createFetchTimeout(60000),
  })
);

export default familyApi;
