/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineTakenLog,
  PatientMedicineTakenLogFromJSON,
  PatientMedicineTakenLogFromJSONTyped,
  PatientMedicineTakenLogToJSON,
} from "./";

export const PatientMedicineTakenLogsTypename = "PatientMedicineTakenLogs" as const;

/**
 *
 * @export
 * @interface PatientMedicineTakenLogs
 */
export interface PatientMedicineTakenLogs {
  /**
   *
   * @type {number}
   * @memberof PatientMedicineTakenLogs
   */
  count?: number;
  /**
   *
   * @type {Array<PatientMedicineTakenLog>}
   * @memberof PatientMedicineTakenLogs
   */
  logs?: Array<PatientMedicineTakenLog>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineTakenLogs
   */
  readonly __typename?: string;
}

export function PatientMedicineTakenLogsFromJSON(json: any): PatientMedicineTakenLogs {
  return PatientMedicineTakenLogsFromJSONTyped(json, false);
}

export function PatientMedicineTakenLogsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineTakenLogs {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineTakenLogsTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    logs: !exists(json, "logs") ? undefined : (json["logs"] as Array<any>).map(PatientMedicineTakenLogFromJSON),
  };
}

export function PatientMedicineTakenLogsToJSON(object?: PatientMedicineTakenLogs | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    logs: value.logs === undefined ? undefined : (value.logs as Array<any>).map(PatientMedicineTakenLogToJSON),
  };
}
