/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyStationsTypename = "PharmacyPharmacyStations" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyStations
 */
export interface PharmacyPharmacyStations {
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyStations
   */
  station?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyStations
   */
  readonly __typename?: string;
}

export function PharmacyPharmacyStationsFromJSON(json: any): PharmacyPharmacyStations {
  return PharmacyPharmacyStationsFromJSONTyped(json, false);
}

export function PharmacyPharmacyStationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPharmacyStations {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyStationsTypename,

    station: !exists(json, "station") ? undefined : json["station"],
  };
}

export function PharmacyPharmacyStationsToJSON(object?: PharmacyPharmacyStations | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    station: value.station,
  };
}
