import { toastError } from "@mgdx/ui/components/Toast";
import { vibrate } from "@mgdx/user/src/ui/CodeScanner/utils";
import { errorHandlerReport } from "@mgdx-libs/error-handler";
import { logger } from "@mgdx-libs/logger";
import { useEffect, useMemo, useState } from "react";

import { useAutoStopMediaStream, useMediaDevice } from "./MediaDeviceProvider";

export type SwitchVideoDevice = (() => void) | undefined;

export const useMediaDeviceForScanner = () => {
  useAutoStopMediaStream();

  const { videoRef, mediaDeviceManager } = useMediaDevice();

  const [isStartedMediaStream, setIsStartedMediaStream] = useState(false);

  const [videoDevice, setVideoDevice] = useState<MediaDeviceInfo>();

  const switchVideoDevice = useMemo<SwitchVideoDevice>(() => {
    if (mediaDeviceManager.videoInputRearDevices.length > 1) {
      return () => {
        const video = videoRef.current;
        if (!video) return;

        const deviceId = videoDevice?.deviceId || mediaDeviceManager.currentVideoDeviceId();

        if (deviceId) {
          const index = mediaDeviceManager.videoInputRearDevices.findIndex((device) => device.deviceId === deviceId);
          const device =
            mediaDeviceManager.videoInputRearDevices[index + 1] || mediaDeviceManager.videoInputRearDevices[0];

          mediaDeviceManager.stopMediaStream();
          if (video) {
            video.srcObject = null;
          }

          setIsStartedMediaStream(false);
          setVideoDevice(device);
        }
      };
    }

    return undefined;
  }, [mediaDeviceManager, videoDevice?.deviceId, videoRef]);

  useEffect(() => {
    const timer = setTimeout(async () => {
      try {
        const video = videoRef.current;

        if (!video) return;
        if (!mediaDeviceManager.isGetUserMediaSupported) return;

        vibrate();
        const mediaStream = await mediaDeviceManager.fetchMediaStreamByVideoDeviceId(videoDevice?.deviceId, {
          aspectRatio: 1,
          advanced: [
            { brightness: 100 },
            { contrast: 100 },
            { exposureMode: "continuous" },
            { focusMode: "continuous" },
            { height: 2000 },
            { ideal: 1000 },
            { whiteBalanceMode: "continuous" },
            { width: 2000 },
          ],
        });

        video.muted = true;
        video.poster = "null";
        video.volume = 0;
        video.setAttribute("playsinline", "playsinline");

        let timeoutId: number | null = null;

        video.onloadstart = (e) => {
          timeoutId = window.setTimeout(() => {
            toastError("カメラを起動できませんでした");
          }, 2000);
        };

        video.oncanplay = async (e) => {
          if (timeoutId) {
            clearTimeout(timeoutId);
          }
          await video.play();
          setIsStartedMediaStream(true);
          const newDeviceId = mediaDeviceManager.currentVideoDeviceId();
          const newDevice = mediaDeviceManager.videoInputRearDevices.find((device) => device.deviceId === newDeviceId);
          setVideoDevice(newDevice);
        };

        video.srcObject = mediaStream;
      } catch (error) {
        toastError("カメラを起動できませんでした");
        logger.error(error);
        errorHandlerReport(error);
        setIsStartedMediaStream(false);
      }
    }, 0);

    return () => {
      clearTimeout(timer);
    };
  }, [mediaDeviceManager, videoDevice, videoRef]);

  return useMemo(
    () => ({
      isStartedMediaStream,
      mediaDeviceManager,
      switchVideoDevice,
      videoDevice,
      videoRef,
    }),
    [isStartedMediaStream, mediaDeviceManager, switchVideoDevice, videoDevice, videoRef]
  );
};
