/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyRequestFamilyPharmacistImageTypename = "PharmacyRequestFamilyPharmacistImage" as const;

/**
 *
 * @export
 * @interface PharmacyRequestFamilyPharmacistImage
 */
export interface PharmacyRequestFamilyPharmacistImage {
  /**
   *
   * @type {string}
   * @memberof PharmacyRequestFamilyPharmacistImage
   */
  image?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyRequestFamilyPharmacistImage
   */
  type: PharmacyRequestFamilyPharmacistImageTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyRequestFamilyPharmacistImage
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyRequestFamilyPharmacistImageTypeEnum {
  Template1 = "template1",
  Template2 = "template2",
  Template3 = "template3",
  Template4 = "template4",
  Specified = "specified",
}

export function PharmacyRequestFamilyPharmacistImageFromJSON(json: any): PharmacyRequestFamilyPharmacistImage {
  return PharmacyRequestFamilyPharmacistImageFromJSONTyped(json, false);
}

export function PharmacyRequestFamilyPharmacistImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyRequestFamilyPharmacistImage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyRequestFamilyPharmacistImageTypename,

    image: !exists(json, "image") ? undefined : json["image"],
    type: json["type"],
  };
}

export function PharmacyRequestFamilyPharmacistImageToJSON(object?: PharmacyRequestFamilyPharmacistImage | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    image: value.image,
    type: value.type,
  };
}
