/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostOrderEventResponseTypename = "PostOrderEventResponse" as const;

/**
 *
 * @export
 * @interface PostOrderEventResponse
 */
export interface PostOrderEventResponse {
  /**
   *
   * @type {string}
   * @memberof PostOrderEventResponse
   */
  message?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostOrderEventResponse
   */
  readonly __typename?: string;
}

export function PostOrderEventResponseFromJSON(json: any): PostOrderEventResponse {
  return PostOrderEventResponseFromJSONTyped(json, false);
}

export function PostOrderEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostOrderEventResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostOrderEventResponseTypename,

    message: !exists(json, "message") ? undefined : json["message"],
  };
}

export function PostOrderEventResponseToJSON(object?: PostOrderEventResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    message: value.message,
  };
}
