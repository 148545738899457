/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatchPharmaciesShopsRequestBodyTypename = "PatchPharmaciesShopsRequestBody" as const;

/**
 *
 * @export
 * @interface PatchPharmaciesShopsRequestBody
 */
export interface PatchPharmaciesShopsRequestBody {
  /**
   *
   * @type {number}
   * @memberof PatchPharmaciesShopsRequestBody
   */
  shopId: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchPharmaciesShopsRequestBody
   */
  readonly __typename?: string;
}

export function PatchPharmaciesShopsRequestBodyFromJSON(json: any): PatchPharmaciesShopsRequestBody {
  return PatchPharmaciesShopsRequestBodyFromJSONTyped(json, false);
}

export function PatchPharmaciesShopsRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchPharmaciesShopsRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchPharmaciesShopsRequestBodyTypename,

    shopId: json["shop_id"],
  };
}

export function PatchPharmaciesShopsRequestBodyToJSON(object?: PatchPharmaciesShopsRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    shop_id: value.shopId,
  };
}
