/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PayerCreditCardTypename = "PayerCreditCard" as const;

/**
 *
 * @export
 * @interface PayerCreditCard
 */
export interface PayerCreditCard {
  /**
   *
   * @type {string}
   * @memberof PayerCreditCard
   */
  brand?: string;
  /**
   *
   * @type {string}
   * @memberof PayerCreditCard
   */
  cardId?: string;
  /**
   *
   * @type {string}
   * @memberof PayerCreditCard
   */
  cardNumber?: string;
  /**
   *
   * @type {boolean}
   * @memberof PayerCreditCard
   */
  debitCard?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PayerCreditCard
   */
  defaultCard?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PayerCreditCard
   */
  domesticCard?: boolean;
  /**
   *
   * @type {string}
   * @memberof PayerCreditCard
   */
  expire?: string;
  /**
   *
   * @type {number}
   * @memberof PayerCreditCard
   */
  expiredAt?: number;
  /**
   *
   * @type {string}
   * @memberof PayerCreditCard
   */
  holderName?: string;
  /**
   *
   * @type {number}
   * @memberof PayerCreditCard
   */
  monthPayAmount?: number;
  /**
   *
   * @type {boolean}
   * @memberof PayerCreditCard
   */
  prepaidCard?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PayerCreditCard
   */
  readonly __typename?: string;
}

export function PayerCreditCardFromJSON(json: any): PayerCreditCard {
  return PayerCreditCardFromJSONTyped(json, false);
}

export function PayerCreditCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayerCreditCard {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PayerCreditCardTypename,

    brand: !exists(json, "brand") ? undefined : json["brand"],
    cardId: !exists(json, "card_id") ? undefined : json["card_id"],
    cardNumber: !exists(json, "card_number") ? undefined : json["card_number"],
    debitCard: !exists(json, "debit_card") ? undefined : json["debit_card"],
    defaultCard: !exists(json, "default_card") ? undefined : json["default_card"],
    domesticCard: !exists(json, "domestic_card") ? undefined : json["domestic_card"],
    expire: !exists(json, "expire") ? undefined : json["expire"],
    expiredAt: !exists(json, "expired_at") ? undefined : json["expired_at"],
    holderName: !exists(json, "holder_name") ? undefined : json["holder_name"],
    monthPayAmount: !exists(json, "month_pay_amount") ? undefined : json["month_pay_amount"],
    prepaidCard: !exists(json, "prepaid_card") ? undefined : json["prepaid_card"],
  };
}

export function PayerCreditCardToJSON(object?: PayerCreditCard | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    brand: value.brand,
    card_id: value.cardId,
    card_number: value.cardNumber,
    debit_card: value.debitCard,
    default_card: value.defaultCard,
    domestic_card: value.domesticCard,
    expire: value.expire,
    expired_at: value.expiredAt,
    holder_name: value.holderName,
    month_pay_amount: value.monthPayAmount,
    prepaid_card: value.prepaidCard,
  };
}
