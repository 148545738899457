/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyOperatorTypename = "PharmacyOperator" as const;

/**
 *
 * @export
 * @interface PharmacyOperator
 */
export interface PharmacyOperator {
  /**
   *
   * @type {number}
   * @memberof PharmacyOperator
   */
  chainId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyOperator
   */
  chainName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyOperator
   */
  dashboardToken?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyOperator
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyOperator
   */
  firstName: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyOperator
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyOperator
   */
  initialPasswordChanged?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyOperator
   */
  lastName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyOperator
   */
  readonly __typename?: string;
}

export function PharmacyOperatorFromJSON(json: any): PharmacyOperator {
  return PharmacyOperatorFromJSONTyped(json, false);
}

export function PharmacyOperatorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyOperator {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyOperatorTypename,

    chainId: json["chain_id"],
    chainName: json["chain_name"],
    dashboardToken: !exists(json, "dashboard_token") ? undefined : json["dashboard_token"],
    email: json["email"],
    firstName: json["first_name"],
    id: json["id"],
    initialPasswordChanged: !exists(json, "initial_password_changed") ? undefined : json["initial_password_changed"],
    lastName: json["last_name"],
  };
}

export function PharmacyOperatorToJSON(object?: PharmacyOperator | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    chain_name: value.chainName,
    dashboard_token: value.dashboardToken,
    email: value.email,
    first_name: value.firstName,
    id: value.id,
    initial_password_changed: value.initialPasswordChanged,
    last_name: value.lastName,
  };
}
