import moment from "@mgdx-libs/moment";
import { createAction, createReducer } from "@reduxjs/toolkit";

export type LoadingState = {
  loading: boolean;
  expiredTime?: string;
};

const initialState: LoadingState = {
  loading: false,
  expiredTime: undefined,
};

export const startLoading = createAction("oidc/startLoading");
export const clearLoading = createAction("oidc/clearLoading");

const loadingReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(startLoading, (state, action) => {
      return {
        expiredTime: moment().toISOString(),
        loading: true,
      };
    })
    .addCase(clearLoading, (state, action) => {
      return {
        expiredTime: undefined,
        loading: false,
      };
    });
});

export default loadingReducer;
