import { PatientProviderTypeEnum } from "@mgdx/shared/src/models/Patient";
import React, { createContext, useContext, useMemo } from "react";

import { CurrentUserState, initialState } from "../../ducks/currentUser";
import useInAppView from "../../hooks/useIsInAppView";

type FeatureToggles = {
  medicineNotebook: {
    isFullOpen: boolean;
    isPreOpen: boolean;
    isClose: boolean;
  };
  topView: {
    selectableCounseling: boolean;
    searchCautionMessage: boolean;
  };
  followUp: {
    editSendFollowUp: boolean;
  };
};

const defaultFeatureToggles: FeatureToggles = {
  medicineNotebook: {
    isFullOpen: process.env.MEDICINE_NOTEBOOK_FEATURE_STATE === "FULL_OPEN",
    isPreOpen: process.env.MEDICINE_NOTEBOOK_FEATURE_STATE === "PRE_OPEN",
    isClose: process.env.MEDICINE_NOTEBOOK_FEATURE_STATE === "CLOSE",
  },
  topView: {
    selectableCounseling: process.env.ENABLE_SELECT_COUNSELING_TOP === "true",
    searchCautionMessage: process.env.ENABLE_SEARCH_CAUTION_MESSAGE === "true" || process.env.MGDX_APP_NAME === "yqb",
  },
  followUp: {
    editSendFollowUp: process.env.ENABLE_EDIT_SEND_FOLLOW_UP === "true",
  },
};

type AppStatusContextValue = {
  isInLineApp: CurrentUserState["isInLineApp"];
  isPharmacyDispensingEnabled: boolean;
  isPharmacyOnlineEnabled: boolean;
  isInApp: ReturnType<typeof useInAppView>;
  featureToggles: FeatureToggles;
};

const AppStatusContext = createContext<AppStatusContextValue>({
  isInLineApp: initialState.isLiffLogined,
  isPharmacyDispensingEnabled: process.env.DISABLE_DISPENSING !== "true",
  isPharmacyOnlineEnabled:
    process.env.ENABLE_ONLINE_COUNSELING !== "false" &&
    ((initialState.userInfo?.loginProviderType !== PatientProviderTypeEnum.LineSundrug &&
      initialState.userInfo?.loginProviderType !== PatientProviderTypeEnum.LineQol &&
      initialState.userInfo?.loginProviderType !== PatientProviderTypeEnum.LineTanpopo) ||
      process.env.ENABLE_ONLINE_COUNSELING === "true"),
  isInApp: false,
  featureToggles: defaultFeatureToggles,
});

export type AppStatusProviderPropsWithoutChildren = Omit<AppStatusContextValue, "featureToggles"> &
  Partial<Pick<AppStatusContextValue, "featureToggles">>;

export type AppStatusProviderProps = React.PropsWithChildren<AppStatusProviderPropsWithoutChildren>;

export const AppStatusProvider: React.FC<AppStatusProviderProps> = ({
  children,
  isInApp,
  isInLineApp,
  isPharmacyDispensingEnabled,
  isPharmacyOnlineEnabled,
  featureToggles = defaultFeatureToggles,
}) => {
  const value = useMemo<AppStatusContextValue>(
    () => ({
      isInLineApp,
      isPharmacyDispensingEnabled,
      isPharmacyOnlineEnabled,
      isInApp,
      featureToggles: {
        ...defaultFeatureToggles,
        ...featureToggles,
      },
    }),
    [isInLineApp, isPharmacyDispensingEnabled, isPharmacyOnlineEnabled, isInApp, featureToggles]
  );

  return <AppStatusContext.Provider value={value}>{children}</AppStatusContext.Provider>;
};

export const useAppStatus = (): AppStatusContextValue => useContext(AppStatusContext);
