/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchMedicineNotebookAutoLinkRequestBody,
  PatchMedicineNotebookAutoLinkRequestBodyFromJSON,
  PatchMedicineNotebookAutoLinkRequestBodyToJSON,
  PharmacyCounselorMedicineNotebookAutoLink,
  PharmacyCounselorMedicineNotebookAutoLinkFromJSON,
  PharmacyCounselorMedicineNotebookAutoLinkToJSON,
  PharmacyCounselorMedicineNotebookAutoLinks,
  PharmacyCounselorMedicineNotebookAutoLinksFromJSON,
  PharmacyCounselorMedicineNotebookAutoLinksToJSON,
  PostMedicineNotebookAutoLinkRequestBody,
  PostMedicineNotebookAutoLinkRequestBodyFromJSON,
  PostMedicineNotebookAutoLinkRequestBodyToJSON,
} from "../models";

export interface DeleteMedicineNotebookAutoLinksPatientReceiptCodeRequest {
  medicineNotebookAutoLinkId: number;
}

export interface GetMedicineNotebookAutoLinkRequest {
  medicineNotebookAutoLinkId: number;
}

export interface GetMedicineNotebookAutoLinksRequest {
  from?: string;
  to?: string;
  patientId?: number;
  patientName?: string;
  pharmacyId?: number;
  counselingId?: Array<number>;
  status?: GetMedicineNotebookAutoLinksStatusEnum;
  limit?: number;
  offset?: number;
  orderBy?: GetMedicineNotebookAutoLinksOrderByEnum;
}

export interface PatchMedicineNotebookAutoLinkRequest {
  medicineNotebookAutoLinkId: number;
  patchMedicineNotebookAutoLinkRequestBody: PatchMedicineNotebookAutoLinkRequestBody;
}

export interface PostMedicineNotebookAutoLinksRequest {
  postMedicineNotebookAutoLinkRequestBody: PostMedicineNotebookAutoLinkRequestBody;
}

/**
 *
 */
export class MedicineNotebookAutoLinkApi extends runtime.BaseAPI {
  /**
   * delete the receipt code for the medicine notebook auto link
   */
  async deleteMedicineNotebookAutoLinksPatientReceiptCodeRaw(
    requestParameters: DeleteMedicineNotebookAutoLinksPatientReceiptCodeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.medicineNotebookAutoLinkId === null ||
      requestParameters.medicineNotebookAutoLinkId === undefined
    ) {
      throw new runtime.RequiredError(
        "medicineNotebookAutoLinkId",
        "Required parameter requestParameters.medicineNotebookAutoLinkId was null or undefined when calling deleteMedicineNotebookAutoLinksPatientReceiptCode."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/medicine-notebook-auto-links/{medicine_notebook_auto_link_id}/patient/receipt-code`.replace(
          `{${"medicine_notebook_auto_link_id"}}`,
          encodeURIComponent(String(requestParameters.medicineNotebookAutoLinkId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * delete the receipt code for the medicine notebook auto link
   */
  async deleteMedicineNotebookAutoLinksPatientReceiptCode(
    requestParameters: DeleteMedicineNotebookAutoLinksPatientReceiptCodeRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteMedicineNotebookAutoLinksPatientReceiptCodeRaw(requestParameters, initOverrides);
  }

  /**
   * Get medicine notebook auto link using JWT
   */
  async getMedicineNotebookAutoLinkRaw(
    requestParameters: GetMedicineNotebookAutoLinkRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorMedicineNotebookAutoLink>> {
    if (
      requestParameters.medicineNotebookAutoLinkId === null ||
      requestParameters.medicineNotebookAutoLinkId === undefined
    ) {
      throw new runtime.RequiredError(
        "medicineNotebookAutoLinkId",
        "Required parameter requestParameters.medicineNotebookAutoLinkId was null or undefined when calling getMedicineNotebookAutoLink."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/medicine-notebook-auto-links/{medicine_notebook_auto_link_id}`.replace(
          `{${"medicine_notebook_auto_link_id"}}`,
          encodeURIComponent(String(requestParameters.medicineNotebookAutoLinkId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyCounselorMedicineNotebookAutoLinkFromJSON(jsonValue)
    );
  }

  /**
   * Get medicine notebook auto link using JWT
   */
  async getMedicineNotebookAutoLink(
    requestParameters: GetMedicineNotebookAutoLinkRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorMedicineNotebookAutoLink> {
    return promiseRetry(
      (retry) =>
        this.getMedicineNotebookAutoLinkRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get medicine notebook auto link using JWT. If you are a patient, you can get only your own, if you are a pharmacist, you can get all of your pharmacy\'s.
   */
  async getMedicineNotebookAutoLinksRaw(
    requestParameters: GetMedicineNotebookAutoLinksRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorMedicineNotebookAutoLinks>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.patientId !== undefined) {
      queryParameters["patient_id"] = requestParameters.patientId;
    }

    if (requestParameters.patientName !== undefined) {
      queryParameters["patient_name"] = requestParameters.patientName;
    }

    if (requestParameters.pharmacyId !== undefined) {
      queryParameters["pharmacy_id"] = requestParameters.pharmacyId;
    }

    if (requestParameters.counselingId) {
      queryParameters["counseling_id"] = requestParameters.counselingId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["order_by"] = requestParameters.orderBy;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/medicine-notebook-auto-links`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyCounselorMedicineNotebookAutoLinksFromJSON(jsonValue)
    );
  }

  /**
   * Get medicine notebook auto link using JWT. If you are a patient, you can get only your own, if you are a pharmacist, you can get all of your pharmacy\'s.
   */
  async getMedicineNotebookAutoLinks(
    requestParameters: GetMedicineNotebookAutoLinksRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorMedicineNotebookAutoLinks> {
    return promiseRetry(
      (retry) =>
        this.getMedicineNotebookAutoLinksRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update medicine notebook auto link using JWT
   */
  async patchMedicineNotebookAutoLinkRaw(
    requestParameters: PatchMedicineNotebookAutoLinkRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorMedicineNotebookAutoLink>> {
    if (
      requestParameters.medicineNotebookAutoLinkId === null ||
      requestParameters.medicineNotebookAutoLinkId === undefined
    ) {
      throw new runtime.RequiredError(
        "medicineNotebookAutoLinkId",
        "Required parameter requestParameters.medicineNotebookAutoLinkId was null or undefined when calling patchMedicineNotebookAutoLink."
      );
    }

    if (
      requestParameters.patchMedicineNotebookAutoLinkRequestBody === null ||
      requestParameters.patchMedicineNotebookAutoLinkRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchMedicineNotebookAutoLinkRequestBody",
        "Required parameter requestParameters.patchMedicineNotebookAutoLinkRequestBody was null or undefined when calling patchMedicineNotebookAutoLink."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/medicine-notebook-auto-links/{medicine_notebook_auto_link_id}`.replace(
          `{${"medicine_notebook_auto_link_id"}}`,
          encodeURIComponent(String(requestParameters.medicineNotebookAutoLinkId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchMedicineNotebookAutoLinkRequestBodyToJSON(
          requestParameters.patchMedicineNotebookAutoLinkRequestBody
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyCounselorMedicineNotebookAutoLinkFromJSON(jsonValue)
    );
  }

  /**
   * Update medicine notebook auto link using JWT
   */
  async patchMedicineNotebookAutoLink(
    requestParameters: PatchMedicineNotebookAutoLinkRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorMedicineNotebookAutoLink> {
    return promiseRetry(
      (retry) =>
        this.patchMedicineNotebookAutoLinkRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register medicine notebook auto link using JWT
   */
  async postMedicineNotebookAutoLinksRaw(
    requestParameters: PostMedicineNotebookAutoLinksRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorMedicineNotebookAutoLink>> {
    if (
      requestParameters.postMedicineNotebookAutoLinkRequestBody === null ||
      requestParameters.postMedicineNotebookAutoLinkRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postMedicineNotebookAutoLinkRequestBody",
        "Required parameter requestParameters.postMedicineNotebookAutoLinkRequestBody was null or undefined when calling postMedicineNotebookAutoLinks."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/medicine-notebook-auto-links`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostMedicineNotebookAutoLinkRequestBodyToJSON(requestParameters.postMedicineNotebookAutoLinkRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyCounselorMedicineNotebookAutoLinkFromJSON(jsonValue)
    );
  }

  /**
   * Register medicine notebook auto link using JWT
   */
  async postMedicineNotebookAutoLinks(
    requestParameters: PostMedicineNotebookAutoLinksRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorMedicineNotebookAutoLink> {
    return promiseRetry(
      (retry) =>
        this.postMedicineNotebookAutoLinksRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetMedicineNotebookAutoLinksStatusEnum {
  Requested = "requested",
  Completed = "completed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetMedicineNotebookAutoLinksOrderByEnum {
  Date = "date",
  DateDesc = "date_desc",
}
