/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  AdminVerifyFailures,
  AdminVerifyFailuresFromJSON,
  AdminVerifyFailuresToJSON,
  PostVerifyTokenRequestBody,
  PostVerifyTokenRequestBodyFromJSON,
  PostVerifyTokenRequestBodyToJSON,
  PostVerifyTokenResponse,
  PostVerifyTokenResponseFromJSON,
  PostVerifyTokenResponseToJSON,
} from "../models";

export interface GetVerifyFailuresRequest {
  from?: string;
  to?: string;
}

export interface PostVerifyTokenRequest {
  postVerifyTokenRequestBody: PostVerifyTokenRequestBody;
}

/**
 *
 */
export class RecaptchaApi extends runtime.BaseAPI {
  /**
   * Get verify failure
   */
  async getVerifyFailuresRaw(
    requestParameters: GetVerifyFailuresRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<AdminVerifyFailures>>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/verify-failures`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminVerifyFailuresFromJSON));
  }

  /**
   * Get verify failure
   */
  async getVerifyFailures(
    requestParameters: GetVerifyFailuresRequest,
    initOverrides?: RequestInit
  ): Promise<Array<AdminVerifyFailures>> {
    return promiseRetry(
      (retry) =>
        this.getVerifyFailuresRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * verify recapture token
   */
  async postVerifyTokenRaw(
    requestParameters: PostVerifyTokenRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PostVerifyTokenResponse>> {
    if (
      requestParameters.postVerifyTokenRequestBody === null ||
      requestParameters.postVerifyTokenRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postVerifyTokenRequestBody",
        "Required parameter requestParameters.postVerifyTokenRequestBody was null or undefined when calling postVerifyToken."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/verify-token`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostVerifyTokenRequestBodyToJSON(requestParameters.postVerifyTokenRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PostVerifyTokenResponseFromJSON(jsonValue));
  }

  /**
   * verify recapture token
   */
  async postVerifyToken(
    requestParameters: PostVerifyTokenRequest,
    initOverrides?: RequestInit
  ): Promise<PostVerifyTokenResponse> {
    return promiseRetry(
      (retry) =>
        this.postVerifyTokenRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
