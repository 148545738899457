/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyCrowdStatusSettingTypename = "PharmacyPharmacyCrowdStatusSetting" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyCrowdStatusSetting
 */
export interface PharmacyPharmacyCrowdStatusSetting {
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyCrowdStatusSetting
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyCrowdStatusSetting
   */
  unitType: PharmacyPharmacyCrowdStatusSettingUnitTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyCrowdStatusSetting
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyPharmacyCrowdStatusSettingUnitTypeEnum {
  People = "people",
  Minute = "minute",
}

export function PharmacyPharmacyCrowdStatusSettingFromJSON(json: any): PharmacyPharmacyCrowdStatusSetting {
  return PharmacyPharmacyCrowdStatusSettingFromJSONTyped(json, false);
}

export function PharmacyPharmacyCrowdStatusSettingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPharmacyCrowdStatusSetting {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyCrowdStatusSettingTypename,

    quantity: !exists(json, "quantity") ? undefined : json["quantity"],
    unitType: json["unit_type"],
  };
}

export function PharmacyPharmacyCrowdStatusSettingToJSON(object?: PharmacyPharmacyCrowdStatusSetting | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    quantity: value.quantity,
    unit_type: value.unitType,
  };
}
