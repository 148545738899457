/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchPatientRequestBody,
  PatchPatientRequestBodyFromJSON,
  PatchPatientRequestBodyToJSON,
  PharmacyCounselorCounselingPatient,
  PharmacyCounselorCounselingPatientFromJSON,
  PharmacyCounselorCounselingPatientToJSON,
  PharmacyCounselorCustomBadRequest3,
  PharmacyCounselorCustomBadRequest3FromJSON,
  PharmacyCounselorCustomBadRequest3ToJSON,
  PutPatientRequestBody,
  PutPatientRequestBodyFromJSON,
  PutPatientRequestBodyToJSON,
} from "../models";

export interface DeleteCounselingMedicineNotebookAutoLinkRequest {
  counselingId: number;
}

export interface PatchCounselingPatientRequest {
  counselingId: number;
  patchPatientRequestBody: PatchPatientRequestBody;
}

export interface PutCounselingPatientRequest {
  counselingId: number;
  putPatientRequestBody: PutPatientRequestBody;
}

/**
 *
 */
export class PatientApi extends runtime.BaseAPI {
  /**
   * delete medicine notebook the pharmacy counseling auto link
   */
  async deleteCounselingMedicineNotebookAutoLinkRaw(
    requestParameters: DeleteCounselingMedicineNotebookAutoLinkRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling deleteCounselingMedicineNotebookAutoLink."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/patient/medicine-notebook/auto-link`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * delete medicine notebook the pharmacy counseling auto link
   */
  async deleteCounselingMedicineNotebookAutoLink(
    requestParameters: DeleteCounselingMedicineNotebookAutoLinkRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteCounselingMedicineNotebookAutoLinkRaw(requestParameters, initOverrides);
  }

  /**
   * Update counseling patient. valid for follow_up_only or delivery_only counselings using JWT. valid for direct_visit using RequestToken.
   */
  async patchCounselingPatientRaw(
    requestParameters: PatchCounselingPatientRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorCounselingPatient>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling patchCounselingPatient."
      );
    }

    if (requestParameters.patchPatientRequestBody === null || requestParameters.patchPatientRequestBody === undefined) {
      throw new runtime.RequiredError(
        "patchPatientRequestBody",
        "Required parameter requestParameters.patchPatientRequestBody was null or undefined when calling patchCounselingPatient."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/patient`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchPatientRequestBodyToJSON(requestParameters.patchPatientRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorCounselingPatientFromJSON(jsonValue));
  }

  /**
   * Update counseling patient. valid for follow_up_only or delivery_only counselings using JWT. valid for direct_visit using RequestToken.
   */
  async patchCounselingPatient(
    requestParameters: PatchCounselingPatientRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorCounselingPatient> {
    return promiseRetry(
      (retry) =>
        this.patchCounselingPatientRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Replace counseling patient using JWT
   */
  async putCounselingPatientRaw(
    requestParameters: PutCounselingPatientRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorCounselingPatient>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling putCounselingPatient."
      );
    }

    if (requestParameters.putPatientRequestBody === null || requestParameters.putPatientRequestBody === undefined) {
      throw new runtime.RequiredError(
        "putPatientRequestBody",
        "Required parameter requestParameters.putPatientRequestBody was null or undefined when calling putCounselingPatient."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/patient`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutPatientRequestBodyToJSON(requestParameters.putPatientRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorCounselingPatientFromJSON(jsonValue));
  }

  /**
   * Replace counseling patient using JWT
   */
  async putCounselingPatient(
    requestParameters: PutCounselingPatientRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorCounselingPatient> {
    return promiseRetry(
      (retry) =>
        this.putCounselingPatientRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
