/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorInterviewQuestionnaire,
  PharmacyCounselorInterviewQuestionnaireFromJSON,
  PharmacyCounselorInterviewQuestionnaireFromJSONTyped,
  PharmacyCounselorInterviewQuestionnaireToJSON,
} from "./";

export const PharmacyCounselorCounselingReportMedicationReconciliationFormTypename =
  "PharmacyCounselorCounselingReportMedicationReconciliationForm" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReportMedicationReconciliationForm
 */
export interface PharmacyCounselorCounselingReportMedicationReconciliationForm {
  /**
   *
   * @type {Array<PharmacyCounselorInterviewQuestionnaire>}
   * @memberof PharmacyCounselorCounselingReportMedicationReconciliationForm
   */
  questionnaires: Array<PharmacyCounselorInterviewQuestionnaire>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportMedicationReconciliationForm
   */
  readonly __typename?: string;
}

export function PharmacyCounselorCounselingReportMedicationReconciliationFormFromJSON(
  json: any
): PharmacyCounselorCounselingReportMedicationReconciliationForm {
  return PharmacyCounselorCounselingReportMedicationReconciliationFormFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportMedicationReconciliationFormFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReportMedicationReconciliationForm {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportMedicationReconciliationFormTypename,

    questionnaires: (json["questionnaires"] as Array<any>).map(PharmacyCounselorInterviewQuestionnaireFromJSON),
  };
}

export function PharmacyCounselorCounselingReportMedicationReconciliationFormToJSON(
  object?: PharmacyCounselorCounselingReportMedicationReconciliationForm | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    questionnaires: (value.questionnaires as Array<any>).map(PharmacyCounselorInterviewQuestionnaireToJSON),
  };
}
