export const ZendeskSourceTypename = "ZendeskSource" as const;

/**
 *
 * @export
 * @interface ZendeskSource
 * @see https://developer.zendesk.com/documentation/ticketing/reference-guides/via-object-reference/
 */
export interface ZendeskSource {
  /**
   *
   * @type {string}
   * @memberof ZendeskSource
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof ZendeskSource
   */
  rel: string;
  /**
   *
   * @type {string}
   * @memberof ZendeskSource
   */
  to?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskSource
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskSourceFromJSON(json: any): ZendeskSource {
  return ZendeskSourceFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskSource {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ZendeskSourceTypename,
    from: json["from"],
    rel: json["rel"],
    to: json["to"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskSourceToJSON(object?: ZendeskSource | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    from: value.from,
    rel: value.rel,
    to: value.to,
  };
}
