/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicCounselorExaminerTypename = "ClinicCounselorExaminer" as const;

/**
 *
 * @export
 * @interface ClinicCounselorExaminer
 */
export interface ClinicCounselorExaminer {
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorExaminer
   */
  dateOfBirth?: string;
  /**
   * 受診者が本人だった場合0になる
   * @type {number}
   * @memberof ClinicCounselorExaminer
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorExaminer
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorExaminer
   */
  insuranceCardPath?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorExaminer
   */
  kanaFirstName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorExaminer
   */
  kanaLastName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorExaminer
   */
  lastName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicCounselorExaminer
   */
  otherMedicalImagePaths?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorExaminer
   */
  readonly __typename?: string;
}

export function ClinicCounselorExaminerFromJSON(json: any): ClinicCounselorExaminer {
  return ClinicCounselorExaminerFromJSONTyped(json, false);
}

export function ClinicCounselorExaminerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicCounselorExaminer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorExaminerTypename,

    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    firstName: json["first_name"],
    insuranceCardPath: !exists(json, "insurance_card_path") ? undefined : json["insurance_card_path"],
    kanaFirstName: json["kana_first_name"],
    kanaLastName: json["kana_last_name"],
    lastName: json["last_name"],
    otherMedicalImagePaths: !exists(json, "other_medical_image_paths") ? undefined : json["other_medical_image_paths"],
  };
}

export function ClinicCounselorExaminerToJSON(object?: ClinicCounselorExaminer | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    family_account_id: value.familyAccountId,
    first_name: value.firstName,
    insurance_card_path: value.insuranceCardPath,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    other_medical_image_paths: value.otherMedicalImagePaths,
  };
}
