import clsx from "clsx";
import React from "react";
import ReactModal, { Props as ReactModalProps } from "react-modal";

import * as styles from "./FullscreenDialog.module.css";

ReactModal.setAppElement("body");

export type Props = React.PropsWithChildren<ReactModalProps>;

const FullscreenDialog = ({ className, overlayClassName, bodyOpenClassName, htmlOpenClassName, ...props }: Props) => (
  <ReactModal
    {...props}
    className={clsx(styles.modal, className)}
    overlayClassName={clsx(styles.overlay, overlayClassName)}
    bodyOpenClassName={clsx(styles.bodyOpen, bodyOpenClassName)}
    htmlOpenClassName={clsx("fullscreen-dialog-open", htmlOpenClassName)}
  />
);

export default FullscreenDialog;
