import { fullName } from "@mgdx/shared/src/models/Patient";
import { useMemo } from "react";

import { SenderTypeEnum, TalkRoomDocument } from "../models";

export type UseTalkRoomTitleProps = {
  defaultTitle?: string;
  room?: TalkRoomDocument;
  talkSenderType: SenderTypeEnum;
};

export type UseTalkRoomTitle = (props: UseTalkRoomTitleProps) => string;

export const useTalkRoomTitle: UseTalkRoomTitle = ({ defaultTitle = "", room, talkSenderType }) => {
  return useMemo(() => {
    if (!room) return defaultTitle;

    if (talkSenderType === SenderTypeEnum.Patient) {
      if (room.pharmacy?.id) {
        return room?.pharmacy?.name || "";
      }

      if (room.clinic?.id) {
        return room?.clinic?.name || "";
      }
    }

    return fullName(room.patient);
  }, [defaultTitle, room, talkSenderType]);
};
