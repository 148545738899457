module.exports = [{
      plugin: require('../plugins/duplicate-content-plugin/gatsby-browser.js'),
      options: {"plugins":[],"excludes":["/**/complete/","/backlogs/**/","/change-password/","/cheat/**/","/cheat/","/clinic-*/","/clinics/*/*","/counselings/","/j/**/","/kotobuki_transaction/","/pharmacy-*/","/settings/**/","/sign-in/","/sign-out/","/talks/","/medicine-notebook/**","/phr/**","/oidc/**/","/**/__stories__/**"],"canonical":"https://yqb.jp"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KWMFC2C","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
