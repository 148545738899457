import { createQueryKeyStore } from "@lukemorales/query-key-factory";

export const medicineNotebookShareKeys = createQueryKeyStore({
  medicineNotebookShare: {
    list: null,
  },
  medicineNotebookShareIssue: {
    list: null,
    detail: (medicineNotebookShareIssueId: string) => [medicineNotebookShareIssueId],
    detailByShareCode: (shareCode: string) => [shareCode],
  },
  medicineNotebookShareMineRequest: {
    list: null,
  },
  medicineNotebookShareTheirsRequest: {
    list: null,
  },
});
