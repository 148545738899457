export const ZendeskCollabratorTypename = "ZendeskCollabrator" as const;

/**
 *
 * @export
 * @interface ZendeskCollabrator
 * @see ZendeskCollabrator
 */
export interface ZendeskCollabrator {
  /**
   *
   * @type {string}
   * @memberof ZendeskCollabrator
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof ZendeskCollabrator
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ZendeskCollabrator
   */
  name: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskCollabrator
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskCollabratorFromJSON(json: any): ZendeskCollabrator {
  return ZendeskCollabratorFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskCollabratorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskCollabrator {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ZendeskCollabratorTypename,
    email: json["email"],
    id: json["id"],
    name: json["name"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskCollabratorToJSON(object?: ZendeskCollabrator | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    email: value.email,
    id: value.id,
    name: value.name,
  };
}
