export const NEWLINE_REGEX = /\r\n|\r|\n/;

const INCLUDE_NEWLINE_REGEX = /(\r\n|\r|\n)/;

type SplitNewlineOptions = {
  isIncludeNewline?: boolean;
};

type SplitNewline = (text?: string, options?: SplitNewlineOptions) => string[];

export const splitNewline: SplitNewline = (text, options) => {
  if (typeof text !== "string") return [];

  if (options?.isIncludeNewline) {
    return text.split(INCLUDE_NEWLINE_REGEX);
  }

  return text.split(NEWLINE_REGEX);
};
