/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCurrentInformationTypename = "PharmacyCurrentInformation" as const;

/**
 *
 * @export
 * @interface PharmacyCurrentInformation
 */
export interface PharmacyCurrentInformation {
  /**
   *
   * @type {string}
   * @memberof PharmacyCurrentInformation
   */
  closingHour?: string;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCurrentInformation
   */
  hasBreakTime?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCurrentInformation
   */
  open: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyCurrentInformation
   */
  openingHour?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCurrentInformation
   */
  readonly __typename?: string;
}

export function PharmacyCurrentInformationFromJSON(json: any): PharmacyCurrentInformation {
  return PharmacyCurrentInformationFromJSONTyped(json, false);
}

export function PharmacyCurrentInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCurrentInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCurrentInformationTypename,

    closingHour: !exists(json, "closing_hour") ? undefined : json["closing_hour"],
    hasBreakTime: !exists(json, "has_break_time") ? undefined : json["has_break_time"],
    open: json["open"],
    openingHour: !exists(json, "opening_hour") ? undefined : json["opening_hour"],
  };
}

export function PharmacyCurrentInformationToJSON(object?: PharmacyCurrentInformation | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    closing_hour: value.closingHour,
    has_break_time: value.hasBreakTime,
    open: value.open,
    opening_hour: value.openingHour,
  };
}
