/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  MedicationReconciliationFormResponse,
  MedicationReconciliationFormResponseFromJSON,
  MedicationReconciliationFormResponseToJSON,
  OTCMedicineResponse,
  OTCMedicineResponseFromJSON,
  OTCMedicineResponseToJSON,
  PatientDispenseRecord,
  PatientDispenseRecordFromJSON,
  PatientDispenseRecordToJSON,
  PatientDispenseRecordPDF,
  PatientDispenseRecordPDFFromJSON,
  PatientDispenseRecordPDFToJSON,
  PatientDispenseRecords,
  PatientDispenseRecordsFromJSON,
  PatientDispenseRecordsToJSON,
  PatientMedicationReconciliationFormMaster,
  PatientMedicationReconciliationFormMasterFromJSON,
  PatientMedicationReconciliationFormMasterToJSON,
  PatientMedicineMaster,
  PatientMedicineMasterFromJSON,
  PatientMedicineMasterToJSON,
  PatientOTCMedicineRegistrable,
  PatientOTCMedicineRegistrableFromJSON,
  PatientOTCMedicineRegistrableToJSON,
  PatientOTCMedicineUpdatable,
  PatientOTCMedicineUpdatableFromJSON,
  PatientOTCMedicineUpdatableToJSON,
  PatientOTCMedicines,
  PatientOTCMedicinesFromJSON,
  PatientOTCMedicinesToJSON,
  PatientOnetimeCode,
  PatientOnetimeCodeFromJSON,
  PatientOnetimeCodeToJSON,
  PatientPutOTCMedicineHiddenBody,
  PatientPutOTCMedicineHiddenBodyFromJSON,
  PatientPutOTCMedicineHiddenBodyToJSON,
  PatientUpdatableMedicationReconciliationForm,
  PatientUpdatableMedicationReconciliationFormFromJSON,
  PatientUpdatableMedicationReconciliationFormToJSON,
  PostDispenseRecordImagesRequestBody,
  PostDispenseRecordImagesRequestBodyFromJSON,
  PostDispenseRecordImagesRequestBodyToJSON,
  PostDispenseRecordRequestBody,
  PostDispenseRecordRequestBodyFromJSON,
  PostDispenseRecordRequestBodyToJSON,
  PostMemoRequestBody,
  PostMemoRequestBodyFromJSON,
  PostMemoRequestBodyToJSON,
  PostOnetimeCodeRequestBody,
  PostOnetimeCodeRequestBodyFromJSON,
  PostOnetimeCodeRequestBodyToJSON,
  PutDispenseRecordHiddenRequestBody,
  PutDispenseRecordHiddenRequestBodyFromJSON,
  PutDispenseRecordHiddenRequestBodyToJSON,
  PutMemoRequestBody,
  PutMemoRequestBodyFromJSON,
  PutMemoRequestBodyToJSON,
  PutNameRequestBody,
  PutNameRequestBodyFromJSON,
  PutNameRequestBodyToJSON,
} from "../models";

export interface DeleteDispenseRecordRequest {
  chainId: number;
  dispenseRecordId: number;
  familyAccountId?: number;
}

export interface DeleteDispenseRecordImageRequest {
  chainId: number;
  dispenseRecordId: number;
  imageId: number;
  familyAccountId?: number;
}

export interface DeleteMemoRequest {
  chainId: number;
  dispenseRecordId: number;
  memoId: number;
  familyAccountId?: number;
}

export interface DeleteOTCMedicineRequest {
  chainId: number;
  otcMedicineId: number;
  familyAccountId?: number;
}

export interface DeleteSharedDispenseRecordRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  familyAccountId?: number;
}

export interface DeleteSharedDispenseRecordImageRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  imageId: number;
  familyAccountId?: number;
}

export interface DeleteSharedMemoRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  memoId: number;
  familyAccountId?: number;
}

export interface DeleteSharedOTCMedicineRequest {
  patientId: number;
  chainId: number;
  otcMedicineId: number;
  familyAccountId?: number;
}

export interface GetDispenseRecordRequest {
  chainId: number;
  dispenseRecordId: number;
  familyAccountId?: number;
}

export interface GetDispenseRecordMasterRequest {
  chainId: number;
  yjCode: string;
  familyAccountId?: number;
}

export interface GetDispenseRecordPDFRequest {
  chainId: number;
  dispenseRecordId: number;
  familyAccountId?: number;
}

export interface GetDispenseRecordsRequest {
  chainId: number;
  familyAccountId?: number;
  page?: string;
  per?: string;
  sort?: GetDispenseRecordsSortEnum;
  order?: GetDispenseRecordsOrderEnum;
}

export interface GetMasterMedicationReconciliationFormRequest {
  chainId: number;
}

export interface GetMedicationReconciliationFormRequest {
  chainId: number;
  familyAccountId?: number;
}

export interface GetOTCMedicineRequest {
  chainId: number;
  otcMedicineId: number;
  familyAccountId?: number;
}

export interface GetOTCMedicinesRequest {
  chainId: number;
  familyAccountId?: number;
  page?: string;
  per?: string;
  sort?: GetOTCMedicinesSortEnum;
  order?: GetOTCMedicinesOrderEnum;
}

export interface GetOnetimeCodeRequest {
  chainId: number;
  postOnetimeCodeRequestBody: PostOnetimeCodeRequestBody;
}

export interface GetSharedDispenseRecordRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  familyAccountId?: number;
}

export interface GetSharedDispenseRecordMasterRequest {
  chainId: number;
  patientId: number;
  yjCode: string;
  familyAccountId?: number;
}

export interface GetSharedDispenseRecordPDFRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  familyAccountId?: number;
}

export interface GetSharedDispenseRecordsRequest {
  patientId: number;
  chainId: number;
  familyAccountId?: number;
  page?: string;
  per?: string;
  sort?: GetSharedDispenseRecordsSortEnum;
  order?: GetSharedDispenseRecordsOrderEnum;
}

export interface GetSharedMasterMedicationReconciliationFormRequest {
  patientId: number;
  chainId: number;
}

export interface GetSharedMedicationReconciliationFormRequest {
  patientId: number;
  chainId: number;
  familyAccountId?: number;
}

export interface GetSharedOTCMedicineRequest {
  patientId: number;
  chainId: number;
  otcMedicineId: number;
  familyAccountId?: number;
}

export interface GetSharedOTCMedicinesRequest {
  patientId: number;
  chainId: number;
  familyAccountId?: number;
  page?: string;
  per?: string;
  sort?: GetSharedOTCMedicinesSortEnum;
  order?: GetSharedOTCMedicinesOrderEnum;
}

export interface GetSharedOnetimeCodeRequest {
  patientId: number;
  chainId: number;
  postOnetimeCodeRequestBody: PostOnetimeCodeRequestBody;
}

export interface PostDispenseRecordRequest {
  chainId: number;
  postDispenseRecordRequestBody: PostDispenseRecordRequestBody;
  familyAccountId?: number;
}

export interface PostDispenseRecordImagesRequest {
  chainId: number;
  postDispenseRecordImagesRequestBody: PostDispenseRecordImagesRequestBody;
  familyAccountId?: number;
}

export interface PostMemoRequest {
  chainId: number;
  dispenseRecordId: number;
  postMemoRequestBody: PostMemoRequestBody;
  familyAccountId?: number;
}

export interface PostOTCMedicineRequest {
  chainId: number;
  patientOTCMedicineRegistrable: PatientOTCMedicineRegistrable;
  familyAccountId?: number;
}

export interface PostSharedDispenseRecordRequest {
  patientId: number;
  chainId: number;
  postDispenseRecordRequestBody: PostDispenseRecordRequestBody;
  familyAccountId?: number;
}

export interface PostSharedDispenseRecordImagesRequest {
  patientId: number;
  chainId: number;
  postDispenseRecordImagesRequestBody: PostDispenseRecordImagesRequestBody;
  familyAccountId?: number;
}

export interface PostSharedMemoRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  postMemoRequestBody: PostMemoRequestBody;
  familyAccountId?: number;
}

export interface PostSharedOTCMedicineRequest {
  patientId: number;
  chainId: number;
  patientOTCMedicineRegistrable: PatientOTCMedicineRegistrable;
  familyAccountId?: number;
}

export interface PutDispenseRecordHiddenRequest {
  chainId: number;
  dispenseRecordId: number;
  putDispenseRecordHiddenRequestBody: PutDispenseRecordHiddenRequestBody;
  familyAccountId?: number;
}

export interface PutDispenseRecordNameRequest {
  chainId: number;
  dispenseRecordId: number;
  putNameRequestBody: PutNameRequestBody;
  familyAccountId?: number;
}

export interface PutMedicationReconciliationFormRequest {
  chainId: number;
  patientUpdatableMedicationReconciliationForm: PatientUpdatableMedicationReconciliationForm;
  familyAccountId?: number;
}

export interface PutMemoRequest {
  chainId: number;
  dispenseRecordId: number;
  memoId: number;
  putMemoRequestBody: PutMemoRequestBody;
  familyAccountId?: number;
}

export interface PutOTCMedicineRequest {
  chainId: number;
  otcMedicineId: number;
  patientOTCMedicineUpdatable: PatientOTCMedicineUpdatable;
  familyAccountId?: number;
}

export interface PutOTCMedicineHiddenRequest {
  chainId: number;
  otcMedicineId: number;
  patientPutOTCMedicineHiddenBody: PatientPutOTCMedicineHiddenBody;
  familyAccountId?: number;
}

export interface PutSharedDispenseRecordHiddenRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  putDispenseRecordHiddenRequestBody: PutDispenseRecordHiddenRequestBody;
  familyAccountId?: number;
}

export interface PutSharedDispenseRecordNameRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  putNameRequestBody: PutNameRequestBody;
  familyAccountId?: number;
}

export interface PutSharedMedicationReconciliationFormRequest {
  patientId: number;
  chainId: number;
  patientUpdatableMedicationReconciliationForm: PatientUpdatableMedicationReconciliationForm;
  familyAccountId?: number;
}

export interface PutSharedMemoRequest {
  patientId: number;
  chainId: number;
  dispenseRecordId: number;
  memoId: number;
  putMemoRequestBody: PutMemoRequestBody;
  familyAccountId?: number;
}

export interface PutSharedOTCMedicineRequest {
  patientId: number;
  chainId: number;
  otcMedicineId: number;
  patientOTCMedicineUpdatable: PatientOTCMedicineUpdatable;
  familyAccountId?: number;
}

export interface PutSharedOTCMedicineHiddenRequest {
  patientId: number;
  chainId: number;
  otcMedicineId: number;
  patientPutOTCMedicineHiddenBody: PatientPutOTCMedicineHiddenBody;
  familyAccountId?: number;
}

/**
 *
 */
export class MedicineNotebookContentApi extends runtime.BaseAPI {
  /**
   * Delete dispense record from medicine notebook by dispense record id by auth_id in JWT
   */
  async deleteDispenseRecordRaw(
    requestParameters: DeleteDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteDispenseRecord."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling deleteDispenseRecord."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete dispense record from medicine notebook by dispense record id by auth_id in JWT
   */
  async deleteDispenseRecord(
    requestParameters: DeleteDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteDispenseRecordRaw(requestParameters, initOverrides);
  }

  /**
   * delete dispense record image from medicine notebook by auth_id in JWT
   */
  async deleteDispenseRecordImageRaw(
    requestParameters: DeleteDispenseRecordImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteDispenseRecordImage."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling deleteDispenseRecordImage."
      );
    }

    if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
      throw new runtime.RequiredError(
        "imageId",
        "Required parameter requestParameters.imageId was null or undefined when calling deleteDispenseRecordImage."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/images/{image_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId)))
          .replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * delete dispense record image from medicine notebook by auth_id in JWT
   */
  async deleteDispenseRecordImage(
    requestParameters: DeleteDispenseRecordImageRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteDispenseRecordImageRaw(requestParameters, initOverrides);
  }

  /**
   * Delete memo for dispense record by auth_id in JWT
   */
  async deleteMemoRaw(
    requestParameters: DeleteMemoRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteMemo."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling deleteMemo."
      );
    }

    if (requestParameters.memoId === null || requestParameters.memoId === undefined) {
      throw new runtime.RequiredError(
        "memoId",
        "Required parameter requestParameters.memoId was null or undefined when calling deleteMemo."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/memos/{memo_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId)))
          .replace(`{${"memo_id"}}`, encodeURIComponent(String(requestParameters.memoId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Delete memo for dispense record by auth_id in JWT
   */
  async deleteMemo(requestParameters: DeleteMemoRequest, initOverrides?: RequestInit): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.deleteMemoRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Delete OTC medicines from medicine notebook by auth_id in JWT
   */
  async deleteOTCMedicineRaw(
    requestParameters: DeleteOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteOTCMedicine."
      );
    }

    if (requestParameters.otcMedicineId === null || requestParameters.otcMedicineId === undefined) {
      throw new runtime.RequiredError(
        "otcMedicineId",
        "Required parameter requestParameters.otcMedicineId was null or undefined when calling deleteOTCMedicine."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/otc-medicines/{otc_medicine_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"otc_medicine_id"}}`, encodeURIComponent(String(requestParameters.otcMedicineId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete OTC medicines from medicine notebook by auth_id in JWT
   */
  async deleteOTCMedicine(requestParameters: DeleteOTCMedicineRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteOTCMedicineRaw(requestParameters, initOverrides);
  }

  /**
   * Delete dispense record from shared medicine notebook by dispense record id
   */
  async deleteSharedDispenseRecordRaw(
    requestParameters: DeleteSharedDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling deleteSharedDispenseRecord."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteSharedDispenseRecord."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling deleteSharedDispenseRecord."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete dispense record from shared medicine notebook by dispense record id
   */
  async deleteSharedDispenseRecord(
    requestParameters: DeleteSharedDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteSharedDispenseRecordRaw(requestParameters, initOverrides);
  }

  /**
   * delete dispense record image from a shared medicine notebook
   */
  async deleteSharedDispenseRecordImageRaw(
    requestParameters: DeleteSharedDispenseRecordImageRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling deleteSharedDispenseRecordImage."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteSharedDispenseRecordImage."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling deleteSharedDispenseRecordImage."
      );
    }

    if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
      throw new runtime.RequiredError(
        "imageId",
        "Required parameter requestParameters.imageId was null or undefined when calling deleteSharedDispenseRecordImage."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/images/{image_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId)))
          .replace(`{${"image_id"}}`, encodeURIComponent(String(requestParameters.imageId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * delete dispense record image from a shared medicine notebook
   */
  async deleteSharedDispenseRecordImage(
    requestParameters: DeleteSharedDispenseRecordImageRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteSharedDispenseRecordImageRaw(requestParameters, initOverrides);
  }

  /**
   * Delete memo from a shared medicine notebook for dispense record
   */
  async deleteSharedMemoRaw(
    requestParameters: DeleteSharedMemoRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling deleteSharedMemo."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteSharedMemo."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling deleteSharedMemo."
      );
    }

    if (requestParameters.memoId === null || requestParameters.memoId === undefined) {
      throw new runtime.RequiredError(
        "memoId",
        "Required parameter requestParameters.memoId was null or undefined when calling deleteSharedMemo."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/memos/{memo_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId)))
          .replace(`{${"memo_id"}}`, encodeURIComponent(String(requestParameters.memoId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Delete memo from a shared medicine notebook for dispense record
   */
  async deleteSharedMemo(
    requestParameters: DeleteSharedMemoRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.deleteSharedMemoRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Delete OTC medicines from a shared medicine notebook
   */
  async deleteSharedOTCMedicineRaw(
    requestParameters: DeleteSharedOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling deleteSharedOTCMedicine."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling deleteSharedOTCMedicine."
      );
    }

    if (requestParameters.otcMedicineId === null || requestParameters.otcMedicineId === undefined) {
      throw new runtime.RequiredError(
        "otcMedicineId",
        "Required parameter requestParameters.otcMedicineId was null or undefined when calling deleteSharedOTCMedicine."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/otc-medicines/{otc_medicine_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"otc_medicine_id"}}`, encodeURIComponent(String(requestParameters.otcMedicineId))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete OTC medicines from a shared medicine notebook
   */
  async deleteSharedOTCMedicine(
    requestParameters: DeleteSharedOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteSharedOTCMedicineRaw(requestParameters, initOverrides);
  }

  /**
   * Get dispense record from medicine notebook by dispense record id by auth_id in JWT
   */
  async getDispenseRecordRaw(
    requestParameters: GetDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getDispenseRecord."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling getDispenseRecord."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Get dispense record from medicine notebook by dispense record id by auth_id in JWT
   */
  async getDispenseRecord(
    requestParameters: GetDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.getDispenseRecordRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get dispense record master from medicine notebook by dispense record id by auth_id in JWT
   */
  async getDispenseRecordMasterRaw(
    requestParameters: GetDispenseRecordMasterRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineMaster>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getDispenseRecordMaster."
      );
    }

    if (requestParameters.yjCode === null || requestParameters.yjCode === undefined) {
      throw new runtime.RequiredError(
        "yjCode",
        "Required parameter requestParameters.yjCode was null or undefined when calling getDispenseRecordMaster."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-record-masters/{yj_code}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"yj_code"}}`, encodeURIComponent(String(requestParameters.yjCode))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineMasterFromJSON(jsonValue));
  }

  /**
   * Get dispense record master from medicine notebook by dispense record id by auth_id in JWT
   */
  async getDispenseRecordMaster(
    requestParameters: GetDispenseRecordMasterRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineMaster> {
    return promiseRetry(
      (retry) =>
        this.getDispenseRecordMasterRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get dispense record pdf from medicine notebook by dispense record id by auth_id in JWT
   */
  async getDispenseRecordPDFRaw(
    requestParameters: GetDispenseRecordPDFRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecordPDF>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getDispenseRecordPDF."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling getDispenseRecordPDF."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/pdf`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordPDFFromJSON(jsonValue));
  }

  /**
   * Get dispense record pdf from medicine notebook by dispense record id by auth_id in JWT
   */
  async getDispenseRecordPDF(
    requestParameters: GetDispenseRecordPDFRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecordPDF> {
    return promiseRetry(
      (retry) =>
        this.getDispenseRecordPDFRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get dispense records from medicine notebook by auth_id in JWT
   */
  async getDispenseRecordsRaw(
    requestParameters: GetDispenseRecordsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecords>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getDispenseRecords."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.per !== undefined) {
      queryParameters["per"] = requestParameters.per;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordsFromJSON(jsonValue));
  }

  /**
   * Get dispense records from medicine notebook by auth_id in JWT
   */
  async getDispenseRecords(
    requestParameters: GetDispenseRecordsRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecords> {
    return promiseRetry(
      (retry) =>
        this.getDispenseRecordsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get master medication reconciliation form from medicine notebook by auth_id in JWT
   */
  async getMasterMedicationReconciliationFormRaw(
    requestParameters: GetMasterMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicationReconciliationFormMaster>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getMasterMedicationReconciliationForm."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medication-reconciliation-form/master`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientMedicationReconciliationFormMasterFromJSON(jsonValue)
    );
  }

  /**
   * Get master medication reconciliation form from medicine notebook by auth_id in JWT
   */
  async getMasterMedicationReconciliationForm(
    requestParameters: GetMasterMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicationReconciliationFormMaster> {
    return promiseRetry(
      (retry) =>
        this.getMasterMedicationReconciliationFormRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get medication reconciliation form from medicine notebook by auth_id in JWT
   */
  async getMedicationReconciliationFormRaw(
    requestParameters: GetMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MedicationReconciliationFormResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getMedicationReconciliationForm."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medication-reconciliation-form`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MedicationReconciliationFormResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get medication reconciliation form from medicine notebook by auth_id in JWT
   */
  async getMedicationReconciliationForm(
    requestParameters: GetMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<MedicationReconciliationFormResponse> {
    return promiseRetry(
      (retry) =>
        this.getMedicationReconciliationFormRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get OTC medicines from medicine notebook by auth_id in JWT
   */
  async getOTCMedicineRaw(
    requestParameters: GetOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OTCMedicineResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getOTCMedicine."
      );
    }

    if (requestParameters.otcMedicineId === null || requestParameters.otcMedicineId === undefined) {
      throw new runtime.RequiredError(
        "otcMedicineId",
        "Required parameter requestParameters.otcMedicineId was null or undefined when calling getOTCMedicine."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/otc-medicines/{otc_medicine_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"otc_medicine_id"}}`, encodeURIComponent(String(requestParameters.otcMedicineId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OTCMedicineResponseFromJSON(jsonValue));
  }

  /**
   * Get OTC medicines from medicine notebook by auth_id in JWT
   */
  async getOTCMedicine(
    requestParameters: GetOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<OTCMedicineResponse> {
    return promiseRetry(
      (retry) =>
        this.getOTCMedicineRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get OTC medicines from medicine notebook by auth_id in JWT
   */
  async getOTCMedicinesRaw(
    requestParameters: GetOTCMedicinesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientOTCMedicines>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getOTCMedicines."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.per !== undefined) {
      queryParameters["per"] = requestParameters.per;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/otc-medicines`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientOTCMedicinesFromJSON(jsonValue));
  }

  /**
   * Get OTC medicines from medicine notebook by auth_id in JWT
   */
  async getOTCMedicines(
    requestParameters: GetOTCMedicinesRequest,
    initOverrides?: RequestInit
  ): Promise<PatientOTCMedicines> {
    return promiseRetry(
      (retry) =>
        this.getOTCMedicinesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Generate e-kusu Link onetime code by auth_id in JWT
   */
  async getOnetimeCodeRaw(
    requestParameters: GetOnetimeCodeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientOnetimeCode>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getOnetimeCode."
      );
    }

    if (
      requestParameters.postOnetimeCodeRequestBody === null ||
      requestParameters.postOnetimeCodeRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postOnetimeCodeRequestBody",
        "Required parameter requestParameters.postOnetimeCodeRequestBody was null or undefined when calling getOnetimeCode."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/onetime-code`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostOnetimeCodeRequestBodyToJSON(requestParameters.postOnetimeCodeRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientOnetimeCodeFromJSON(jsonValue));
  }

  /**
   * Generate e-kusu Link onetime code by auth_id in JWT
   */
  async getOnetimeCode(
    requestParameters: GetOnetimeCodeRequest,
    initOverrides?: RequestInit
  ): Promise<PatientOnetimeCode> {
    return promiseRetry(
      (retry) =>
        this.getOnetimeCodeRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get dispense record from shared medicine notebook by dispense record id
   */
  async getSharedDispenseRecordRaw(
    requestParameters: GetSharedDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedDispenseRecord."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedDispenseRecord."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling getSharedDispenseRecord."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Get dispense record from shared medicine notebook by dispense record id
   */
  async getSharedDispenseRecord(
    requestParameters: GetSharedDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.getSharedDispenseRecordRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get dispense record master from shared medicine notebook by dispense record
   */
  async getSharedDispenseRecordMasterRaw(
    requestParameters: GetSharedDispenseRecordMasterRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineMaster>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedDispenseRecordMaster."
      );
    }

    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedDispenseRecordMaster."
      );
    }

    if (requestParameters.yjCode === null || requestParameters.yjCode === undefined) {
      throw new runtime.RequiredError(
        "yjCode",
        "Required parameter requestParameters.yjCode was null or undefined when calling getSharedDispenseRecordMaster."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-record-masters/{yj_code}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"yj_code"}}`, encodeURIComponent(String(requestParameters.yjCode))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineMasterFromJSON(jsonValue));
  }

  /**
   * Get dispense record master from shared medicine notebook by dispense record
   */
  async getSharedDispenseRecordMaster(
    requestParameters: GetSharedDispenseRecordMasterRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineMaster> {
    return promiseRetry(
      (retry) =>
        this.getSharedDispenseRecordMasterRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get dispense record pdf from a shared medicine notebook by dispense record
   */
  async getSharedDispenseRecordPDFRaw(
    requestParameters: GetSharedDispenseRecordPDFRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecordPDF>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedDispenseRecordPDF."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedDispenseRecordPDF."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling getSharedDispenseRecordPDF."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/pdf`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordPDFFromJSON(jsonValue));
  }

  /**
   * Get dispense record pdf from a shared medicine notebook by dispense record
   */
  async getSharedDispenseRecordPDF(
    requestParameters: GetSharedDispenseRecordPDFRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecordPDF> {
    return promiseRetry(
      (retry) =>
        this.getSharedDispenseRecordPDFRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get dispense records from a shared medicine notebook
   */
  async getSharedDispenseRecordsRaw(
    requestParameters: GetSharedDispenseRecordsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecords>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedDispenseRecords."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedDispenseRecords."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.per !== undefined) {
      queryParameters["per"] = requestParameters.per;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordsFromJSON(jsonValue));
  }

  /**
   * Get dispense records from a shared medicine notebook
   */
  async getSharedDispenseRecords(
    requestParameters: GetSharedDispenseRecordsRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecords> {
    return promiseRetry(
      (retry) =>
        this.getSharedDispenseRecordsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get master medication reconciliation form from a shared medicine notebook
   */
  async getSharedMasterMedicationReconciliationFormRaw(
    requestParameters: GetSharedMasterMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicationReconciliationFormMaster>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedMasterMedicationReconciliationForm."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedMasterMedicationReconciliationForm."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/medication-reconciliation-form/master`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PatientMedicationReconciliationFormMasterFromJSON(jsonValue)
    );
  }

  /**
   * Get master medication reconciliation form from a shared medicine notebook
   */
  async getSharedMasterMedicationReconciliationForm(
    requestParameters: GetSharedMasterMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicationReconciliationFormMaster> {
    return promiseRetry(
      (retry) =>
        this.getSharedMasterMedicationReconciliationFormRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get medication reconciliation form from a shared medicine notebook
   */
  async getSharedMedicationReconciliationFormRaw(
    requestParameters: GetSharedMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MedicationReconciliationFormResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedMedicationReconciliationForm."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedMedicationReconciliationForm."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/medication-reconciliation-form`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MedicationReconciliationFormResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get medication reconciliation form from a shared medicine notebook
   */
  async getSharedMedicationReconciliationForm(
    requestParameters: GetSharedMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<MedicationReconciliationFormResponse> {
    return promiseRetry(
      (retry) =>
        this.getSharedMedicationReconciliationFormRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get OTC medicines from a shared medicine notebook
   */
  async getSharedOTCMedicineRaw(
    requestParameters: GetSharedOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OTCMedicineResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedOTCMedicine."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedOTCMedicine."
      );
    }

    if (requestParameters.otcMedicineId === null || requestParameters.otcMedicineId === undefined) {
      throw new runtime.RequiredError(
        "otcMedicineId",
        "Required parameter requestParameters.otcMedicineId was null or undefined when calling getSharedOTCMedicine."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/otc-medicines/{otc_medicine_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"otc_medicine_id"}}`, encodeURIComponent(String(requestParameters.otcMedicineId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OTCMedicineResponseFromJSON(jsonValue));
  }

  /**
   * Get OTC medicines from a shared medicine notebook
   */
  async getSharedOTCMedicine(
    requestParameters: GetSharedOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<OTCMedicineResponse> {
    return promiseRetry(
      (retry) =>
        this.getSharedOTCMedicineRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get OTC medicines from a shared medicine notebook
   */
  async getSharedOTCMedicinesRaw(
    requestParameters: GetSharedOTCMedicinesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientOTCMedicines>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedOTCMedicines."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedOTCMedicines."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.page !== undefined) {
      queryParameters["page"] = requestParameters.page;
    }

    if (requestParameters.per !== undefined) {
      queryParameters["per"] = requestParameters.per;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.order !== undefined) {
      queryParameters["order"] = requestParameters.order;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/otc-medicines`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientOTCMedicinesFromJSON(jsonValue));
  }

  /**
   * Get OTC medicines from a shared medicine notebook
   */
  async getSharedOTCMedicines(
    requestParameters: GetSharedOTCMedicinesRequest,
    initOverrides?: RequestInit
  ): Promise<PatientOTCMedicines> {
    return promiseRetry(
      (retry) =>
        this.getSharedOTCMedicinesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Generate e-kusu Link onetime code
   */
  async getSharedOnetimeCodeRaw(
    requestParameters: GetSharedOnetimeCodeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientOnetimeCode>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedOnetimeCode."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedOnetimeCode."
      );
    }

    if (
      requestParameters.postOnetimeCodeRequestBody === null ||
      requestParameters.postOnetimeCodeRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postOnetimeCodeRequestBody",
        "Required parameter requestParameters.postOnetimeCodeRequestBody was null or undefined when calling getSharedOnetimeCode."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/onetime-code`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostOnetimeCodeRequestBodyToJSON(requestParameters.postOnetimeCodeRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientOnetimeCodeFromJSON(jsonValue));
  }

  /**
   * Generate e-kusu Link onetime code
   */
  async getSharedOnetimeCode(
    requestParameters: GetSharedOnetimeCodeRequest,
    initOverrides?: RequestInit
  ): Promise<PatientOnetimeCode> {
    return promiseRetry(
      (retry) =>
        this.getSharedOnetimeCodeRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register dispense record from medicine notebook by auth_id in JWT
   */
  async postDispenseRecordRaw(
    requestParameters: PostDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postDispenseRecord."
      );
    }

    if (
      requestParameters.postDispenseRecordRequestBody === null ||
      requestParameters.postDispenseRecordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postDispenseRecordRequestBody",
        "Required parameter requestParameters.postDispenseRecordRequestBody was null or undefined when calling postDispenseRecord."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostDispenseRecordRequestBodyToJSON(requestParameters.postDispenseRecordRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Register dispense record from medicine notebook by auth_id in JWT
   */
  async postDispenseRecord(
    requestParameters: PostDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.postDispenseRecordRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register dispense record images from medicine notebook by auth_id in JWT
   */
  async postDispenseRecordImagesRaw(
    requestParameters: PostDispenseRecordImagesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postDispenseRecordImages."
      );
    }

    if (
      requestParameters.postDispenseRecordImagesRequestBody === null ||
      requestParameters.postDispenseRecordImagesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postDispenseRecordImagesRequestBody",
        "Required parameter requestParameters.postDispenseRecordImagesRequestBody was null or undefined when calling postDispenseRecordImages."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-record-images`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostDispenseRecordImagesRequestBodyToJSON(requestParameters.postDispenseRecordImagesRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Register dispense record images from medicine notebook by auth_id in JWT
   */
  async postDispenseRecordImages(
    requestParameters: PostDispenseRecordImagesRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.postDispenseRecordImagesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register memo for dispense record by auth_id in JWT
   */
  async postMemoRaw(
    requestParameters: PostMemoRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postMemo."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling postMemo."
      );
    }

    if (requestParameters.postMemoRequestBody === null || requestParameters.postMemoRequestBody === undefined) {
      throw new runtime.RequiredError(
        "postMemoRequestBody",
        "Required parameter requestParameters.postMemoRequestBody was null or undefined when calling postMemo."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/memos`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostMemoRequestBodyToJSON(requestParameters.postMemoRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Register memo for dispense record by auth_id in JWT
   */
  async postMemo(requestParameters: PostMemoRequest, initOverrides?: RequestInit): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.postMemoRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register OTC medicine from medicine notebook by auth_id in JWT
   */
  async postOTCMedicineRaw(
    requestParameters: PostOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OTCMedicineResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postOTCMedicine."
      );
    }

    if (
      requestParameters.patientOTCMedicineRegistrable === null ||
      requestParameters.patientOTCMedicineRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "patientOTCMedicineRegistrable",
        "Required parameter requestParameters.patientOTCMedicineRegistrable was null or undefined when calling postOTCMedicine."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/otc-medicines`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PatientOTCMedicineRegistrableToJSON(requestParameters.patientOTCMedicineRegistrable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OTCMedicineResponseFromJSON(jsonValue));
  }

  /**
   * Register OTC medicine from medicine notebook by auth_id in JWT
   */
  async postOTCMedicine(
    requestParameters: PostOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<OTCMedicineResponse> {
    return promiseRetry(
      (retry) =>
        this.postOTCMedicineRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register dispense record in a shared medicine notebook
   */
  async postSharedDispenseRecordRaw(
    requestParameters: PostSharedDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling postSharedDispenseRecord."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postSharedDispenseRecord."
      );
    }

    if (
      requestParameters.postDispenseRecordRequestBody === null ||
      requestParameters.postDispenseRecordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postDispenseRecordRequestBody",
        "Required parameter requestParameters.postDispenseRecordRequestBody was null or undefined when calling postSharedDispenseRecord."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostDispenseRecordRequestBodyToJSON(requestParameters.postDispenseRecordRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Register dispense record in a shared medicine notebook
   */
  async postSharedDispenseRecord(
    requestParameters: PostSharedDispenseRecordRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.postSharedDispenseRecordRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register dispense record images from a shared medicine notebook
   */
  async postSharedDispenseRecordImagesRaw(
    requestParameters: PostSharedDispenseRecordImagesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling postSharedDispenseRecordImages."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postSharedDispenseRecordImages."
      );
    }

    if (
      requestParameters.postDispenseRecordImagesRequestBody === null ||
      requestParameters.postDispenseRecordImagesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postDispenseRecordImagesRequestBody",
        "Required parameter requestParameters.postDispenseRecordImagesRequestBody was null or undefined when calling postSharedDispenseRecordImages."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-record-images`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostDispenseRecordImagesRequestBodyToJSON(requestParameters.postDispenseRecordImagesRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Register dispense record images from a shared medicine notebook
   */
  async postSharedDispenseRecordImages(
    requestParameters: PostSharedDispenseRecordImagesRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.postSharedDispenseRecordImagesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register memo from a shared medicine notebook for dispense record
   */
  async postSharedMemoRaw(
    requestParameters: PostSharedMemoRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling postSharedMemo."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postSharedMemo."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling postSharedMemo."
      );
    }

    if (requestParameters.postMemoRequestBody === null || requestParameters.postMemoRequestBody === undefined) {
      throw new runtime.RequiredError(
        "postMemoRequestBody",
        "Required parameter requestParameters.postMemoRequestBody was null or undefined when calling postSharedMemo."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/memos`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostMemoRequestBodyToJSON(requestParameters.postMemoRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Register memo from a shared medicine notebook for dispense record
   */
  async postSharedMemo(
    requestParameters: PostSharedMemoRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.postSharedMemoRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register OTC medicine from a shared medicine notebook
   */
  async postSharedOTCMedicineRaw(
    requestParameters: PostSharedOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OTCMedicineResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling postSharedOTCMedicine."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postSharedOTCMedicine."
      );
    }

    if (
      requestParameters.patientOTCMedicineRegistrable === null ||
      requestParameters.patientOTCMedicineRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "patientOTCMedicineRegistrable",
        "Required parameter requestParameters.patientOTCMedicineRegistrable was null or undefined when calling postSharedOTCMedicine."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/otc-medicines`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PatientOTCMedicineRegistrableToJSON(requestParameters.patientOTCMedicineRegistrable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OTCMedicineResponseFromJSON(jsonValue));
  }

  /**
   * Register OTC medicine from a shared medicine notebook
   */
  async postSharedOTCMedicine(
    requestParameters: PostSharedOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<OTCMedicineResponse> {
    return promiseRetry(
      (retry) =>
        this.postSharedOTCMedicineRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Put hidden for dispense record by auth_id in JWT
   */
  async putDispenseRecordHiddenRaw(
    requestParameters: PutDispenseRecordHiddenRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putDispenseRecordHidden."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling putDispenseRecordHidden."
      );
    }

    if (
      requestParameters.putDispenseRecordHiddenRequestBody === null ||
      requestParameters.putDispenseRecordHiddenRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putDispenseRecordHiddenRequestBody",
        "Required parameter requestParameters.putDispenseRecordHiddenRequestBody was null or undefined when calling putDispenseRecordHidden."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/hidden`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutDispenseRecordHiddenRequestBodyToJSON(requestParameters.putDispenseRecordHiddenRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Put hidden for dispense record by auth_id in JWT
   */
  async putDispenseRecordHidden(
    requestParameters: PutDispenseRecordHiddenRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.putDispenseRecordHiddenRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update dispense record name from medicine notebook by dispense record id by auth_id in JWT
   */
  async putDispenseRecordNameRaw(
    requestParameters: PutDispenseRecordNameRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putDispenseRecordName."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling putDispenseRecordName."
      );
    }

    if (requestParameters.putNameRequestBody === null || requestParameters.putNameRequestBody === undefined) {
      throw new runtime.RequiredError(
        "putNameRequestBody",
        "Required parameter requestParameters.putNameRequestBody was null or undefined when calling putDispenseRecordName."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/name`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutNameRequestBodyToJSON(requestParameters.putNameRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update dispense record name from medicine notebook by dispense record id by auth_id in JWT
   */
  async putDispenseRecordName(
    requestParameters: PutDispenseRecordNameRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.putDispenseRecordNameRaw(requestParameters, initOverrides);
  }

  /**
   * register and update medication reconciliation form from medicine notebook by auth_id in JWT
   */
  async putMedicationReconciliationFormRaw(
    requestParameters: PutMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MedicationReconciliationFormResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putMedicationReconciliationForm."
      );
    }

    if (
      requestParameters.patientUpdatableMedicationReconciliationForm === null ||
      requestParameters.patientUpdatableMedicationReconciliationForm === undefined
    ) {
      throw new runtime.RequiredError(
        "patientUpdatableMedicationReconciliationForm",
        "Required parameter requestParameters.patientUpdatableMedicationReconciliationForm was null or undefined when calling putMedicationReconciliationForm."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/medication-reconciliation-form`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientUpdatableMedicationReconciliationFormToJSON(
          requestParameters.patientUpdatableMedicationReconciliationForm
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MedicationReconciliationFormResponseFromJSON(jsonValue)
    );
  }

  /**
   * register and update medication reconciliation form from medicine notebook by auth_id in JWT
   */
  async putMedicationReconciliationForm(
    requestParameters: PutMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<MedicationReconciliationFormResponse> {
    return promiseRetry(
      (retry) =>
        this.putMedicationReconciliationFormRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update memo for dispense record by auth_id in JWT
   */
  async putMemoRaw(requestParameters: PutMemoRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putMemo."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling putMemo."
      );
    }

    if (requestParameters.memoId === null || requestParameters.memoId === undefined) {
      throw new runtime.RequiredError(
        "memoId",
        "Required parameter requestParameters.memoId was null or undefined when calling putMemo."
      );
    }

    if (requestParameters.putMemoRequestBody === null || requestParameters.putMemoRequestBody === undefined) {
      throw new runtime.RequiredError(
        "putMemoRequestBody",
        "Required parameter requestParameters.putMemoRequestBody was null or undefined when calling putMemo."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/memos/{memo_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId)))
          .replace(`{${"memo_id"}}`, encodeURIComponent(String(requestParameters.memoId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutMemoRequestBodyToJSON(requestParameters.putMemoRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update memo for dispense record by auth_id in JWT
   */
  async putMemo(requestParameters: PutMemoRequest, initOverrides?: RequestInit): Promise<void> {
    await this.putMemoRaw(requestParameters, initOverrides);
  }

  /**
   * Put OTC medicines from medicine notebook by auth_id in JWT
   */
  async putOTCMedicineRaw(
    requestParameters: PutOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OTCMedicineResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putOTCMedicine."
      );
    }

    if (requestParameters.otcMedicineId === null || requestParameters.otcMedicineId === undefined) {
      throw new runtime.RequiredError(
        "otcMedicineId",
        "Required parameter requestParameters.otcMedicineId was null or undefined when calling putOTCMedicine."
      );
    }

    if (
      requestParameters.patientOTCMedicineUpdatable === null ||
      requestParameters.patientOTCMedicineUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "patientOTCMedicineUpdatable",
        "Required parameter requestParameters.patientOTCMedicineUpdatable was null or undefined when calling putOTCMedicine."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/otc-medicines/{otc_medicine_id}`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"otc_medicine_id"}}`, encodeURIComponent(String(requestParameters.otcMedicineId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientOTCMedicineUpdatableToJSON(requestParameters.patientOTCMedicineUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OTCMedicineResponseFromJSON(jsonValue));
  }

  /**
   * Put OTC medicines from medicine notebook by auth_id in JWT
   */
  async putOTCMedicine(
    requestParameters: PutOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<OTCMedicineResponse> {
    return promiseRetry(
      (retry) =>
        this.putOTCMedicineRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Put OTC medicines hidden for medicine notebook by auth_id in JWT
   */
  async putOTCMedicineHiddenRaw(
    requestParameters: PutOTCMedicineHiddenRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OTCMedicineResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putOTCMedicineHidden."
      );
    }

    if (requestParameters.otcMedicineId === null || requestParameters.otcMedicineId === undefined) {
      throw new runtime.RequiredError(
        "otcMedicineId",
        "Required parameter requestParameters.otcMedicineId was null or undefined when calling putOTCMedicineHidden."
      );
    }

    if (
      requestParameters.patientPutOTCMedicineHiddenBody === null ||
      requestParameters.patientPutOTCMedicineHiddenBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patientPutOTCMedicineHiddenBody",
        "Required parameter requestParameters.patientPutOTCMedicineHiddenBody was null or undefined when calling putOTCMedicineHidden."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/otc-medicines/{otc_medicine_id}/hidden`
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"otc_medicine_id"}}`, encodeURIComponent(String(requestParameters.otcMedicineId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientPutOTCMedicineHiddenBodyToJSON(requestParameters.patientPutOTCMedicineHiddenBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OTCMedicineResponseFromJSON(jsonValue));
  }

  /**
   * Put OTC medicines hidden for medicine notebook by auth_id in JWT
   */
  async putOTCMedicineHidden(
    requestParameters: PutOTCMedicineHiddenRequest,
    initOverrides?: RequestInit
  ): Promise<OTCMedicineResponse> {
    return promiseRetry(
      (retry) =>
        this.putOTCMedicineHiddenRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Put hidden from a shared medicine notebook for dispense record
   */
  async putSharedDispenseRecordHiddenRaw(
    requestParameters: PutSharedDispenseRecordHiddenRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientDispenseRecord>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling putSharedDispenseRecordHidden."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putSharedDispenseRecordHidden."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling putSharedDispenseRecordHidden."
      );
    }

    if (
      requestParameters.putDispenseRecordHiddenRequestBody === null ||
      requestParameters.putDispenseRecordHiddenRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putDispenseRecordHiddenRequestBody",
        "Required parameter requestParameters.putDispenseRecordHiddenRequestBody was null or undefined when calling putSharedDispenseRecordHidden."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/hidden`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutDispenseRecordHiddenRequestBodyToJSON(requestParameters.putDispenseRecordHiddenRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientDispenseRecordFromJSON(jsonValue));
  }

  /**
   * Put hidden from a shared medicine notebook for dispense record
   */
  async putSharedDispenseRecordHidden(
    requestParameters: PutSharedDispenseRecordHiddenRequest,
    initOverrides?: RequestInit
  ): Promise<PatientDispenseRecord> {
    return promiseRetry(
      (retry) =>
        this.putSharedDispenseRecordHiddenRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update dispense record name from a shared medicine notebook by dispense record
   */
  async putSharedDispenseRecordNameRaw(
    requestParameters: PutSharedDispenseRecordNameRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling putSharedDispenseRecordName."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putSharedDispenseRecordName."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling putSharedDispenseRecordName."
      );
    }

    if (requestParameters.putNameRequestBody === null || requestParameters.putNameRequestBody === undefined) {
      throw new runtime.RequiredError(
        "putNameRequestBody",
        "Required parameter requestParameters.putNameRequestBody was null or undefined when calling putSharedDispenseRecordName."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/name`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutNameRequestBodyToJSON(requestParameters.putNameRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update dispense record name from a shared medicine notebook by dispense record
   */
  async putSharedDispenseRecordName(
    requestParameters: PutSharedDispenseRecordNameRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.putSharedDispenseRecordNameRaw(requestParameters, initOverrides);
  }

  /**
   * Register and Update medication reconciliation form from a shared medicine notebook
   */
  async putSharedMedicationReconciliationFormRaw(
    requestParameters: PutSharedMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MedicationReconciliationFormResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling putSharedMedicationReconciliationForm."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putSharedMedicationReconciliationForm."
      );
    }

    if (
      requestParameters.patientUpdatableMedicationReconciliationForm === null ||
      requestParameters.patientUpdatableMedicationReconciliationForm === undefined
    ) {
      throw new runtime.RequiredError(
        "patientUpdatableMedicationReconciliationForm",
        "Required parameter requestParameters.patientUpdatableMedicationReconciliationForm was null or undefined when calling putSharedMedicationReconciliationForm."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/medication-reconciliation-form`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientUpdatableMedicationReconciliationFormToJSON(
          requestParameters.patientUpdatableMedicationReconciliationForm
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MedicationReconciliationFormResponseFromJSON(jsonValue)
    );
  }

  /**
   * Register and Update medication reconciliation form from a shared medicine notebook
   */
  async putSharedMedicationReconciliationForm(
    requestParameters: PutSharedMedicationReconciliationFormRequest,
    initOverrides?: RequestInit
  ): Promise<MedicationReconciliationFormResponse> {
    return promiseRetry(
      (retry) =>
        this.putSharedMedicationReconciliationFormRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update memo from a shared medicine notebook for dispense record
   */
  async putSharedMemoRaw(
    requestParameters: PutSharedMemoRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling putSharedMemo."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putSharedMemo."
      );
    }

    if (requestParameters.dispenseRecordId === null || requestParameters.dispenseRecordId === undefined) {
      throw new runtime.RequiredError(
        "dispenseRecordId",
        "Required parameter requestParameters.dispenseRecordId was null or undefined when calling putSharedMemo."
      );
    }

    if (requestParameters.memoId === null || requestParameters.memoId === undefined) {
      throw new runtime.RequiredError(
        "memoId",
        "Required parameter requestParameters.memoId was null or undefined when calling putSharedMemo."
      );
    }

    if (requestParameters.putMemoRequestBody === null || requestParameters.putMemoRequestBody === undefined) {
      throw new runtime.RequiredError(
        "putMemoRequestBody",
        "Required parameter requestParameters.putMemoRequestBody was null or undefined when calling putSharedMemo."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/dispense-records/{dispense_record_id}/memos/{memo_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"dispense_record_id"}}`, encodeURIComponent(String(requestParameters.dispenseRecordId)))
          .replace(`{${"memo_id"}}`, encodeURIComponent(String(requestParameters.memoId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutMemoRequestBodyToJSON(requestParameters.putMemoRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update memo from a shared medicine notebook for dispense record
   */
  async putSharedMemo(requestParameters: PutSharedMemoRequest, initOverrides?: RequestInit): Promise<void> {
    await this.putSharedMemoRaw(requestParameters, initOverrides);
  }

  /**
   * Put OTC medicines from a shared medicine notebook
   */
  async putSharedOTCMedicineRaw(
    requestParameters: PutSharedOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OTCMedicineResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling putSharedOTCMedicine."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putSharedOTCMedicine."
      );
    }

    if (requestParameters.otcMedicineId === null || requestParameters.otcMedicineId === undefined) {
      throw new runtime.RequiredError(
        "otcMedicineId",
        "Required parameter requestParameters.otcMedicineId was null or undefined when calling putSharedOTCMedicine."
      );
    }

    if (
      requestParameters.patientOTCMedicineUpdatable === null ||
      requestParameters.patientOTCMedicineUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "patientOTCMedicineUpdatable",
        "Required parameter requestParameters.patientOTCMedicineUpdatable was null or undefined when calling putSharedOTCMedicine."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/otc-medicines/{otc_medicine_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"otc_medicine_id"}}`, encodeURIComponent(String(requestParameters.otcMedicineId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientOTCMedicineUpdatableToJSON(requestParameters.patientOTCMedicineUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OTCMedicineResponseFromJSON(jsonValue));
  }

  /**
   * Put OTC medicines from a shared medicine notebook
   */
  async putSharedOTCMedicine(
    requestParameters: PutSharedOTCMedicineRequest,
    initOverrides?: RequestInit
  ): Promise<OTCMedicineResponse> {
    return promiseRetry(
      (retry) =>
        this.putSharedOTCMedicineRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Put OTC medicines hidden for shared medicine notebook
   */
  async putSharedOTCMedicineHiddenRaw(
    requestParameters: PutSharedOTCMedicineHiddenRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OTCMedicineResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling putSharedOTCMedicineHidden."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putSharedOTCMedicineHidden."
      );
    }

    if (requestParameters.otcMedicineId === null || requestParameters.otcMedicineId === undefined) {
      throw new runtime.RequiredError(
        "otcMedicineId",
        "Required parameter requestParameters.otcMedicineId was null or undefined when calling putSharedOTCMedicineHidden."
      );
    }

    if (
      requestParameters.patientPutOTCMedicineHiddenBody === null ||
      requestParameters.patientPutOTCMedicineHiddenBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patientPutOTCMedicineHiddenBody",
        "Required parameter requestParameters.patientPutOTCMedicineHiddenBody was null or undefined when calling putSharedOTCMedicineHidden."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/otc-medicines/{otc_medicine_id}/hidden`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId)))
          .replace(`{${"otc_medicine_id"}}`, encodeURIComponent(String(requestParameters.otcMedicineId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientPutOTCMedicineHiddenBodyToJSON(requestParameters.patientPutOTCMedicineHiddenBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => OTCMedicineResponseFromJSON(jsonValue));
  }

  /**
   * Put OTC medicines hidden for shared medicine notebook
   */
  async putSharedOTCMedicineHidden(
    requestParameters: PutSharedOTCMedicineHiddenRequest,
    initOverrides?: RequestInit
  ): Promise<OTCMedicineResponse> {
    return promiseRetry(
      (retry) =>
        this.putSharedOTCMedicineHiddenRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetDispenseRecordsSortEnum {
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
  SupplyDate = "supply_date",
}
/**
 * @export
 * @enum {string}
 */
export enum GetDispenseRecordsOrderEnum {
  Asc = "asc",
  Desc = "desc",
}
/**
 * @export
 * @enum {string}
 */
export enum GetOTCMedicinesSortEnum {
  Name = "name",
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
  StartedOn = "started_on",
  FinishedOn = "finished_on",
}
/**
 * @export
 * @enum {string}
 */
export enum GetOTCMedicinesOrderEnum {
  Asc = "asc",
  Desc = "desc",
}
/**
 * @export
 * @enum {string}
 */
export enum GetSharedDispenseRecordsSortEnum {
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
  SupplyDate = "supply_date",
}
/**
 * @export
 * @enum {string}
 */
export enum GetSharedDispenseRecordsOrderEnum {
  Asc = "asc",
  Desc = "desc",
}
/**
 * @export
 * @enum {string}
 */
export enum GetSharedOTCMedicinesSortEnum {
  Name = "name",
  CreatedAt = "created_at",
  UpdatedAt = "updated_at",
  StartedOn = "started_on",
  FinishedOn = "finished_on",
}
/**
 * @export
 * @enum {string}
 */
export enum GetSharedOTCMedicinesOrderEnum {
  Asc = "asc",
  Desc = "desc",
}
