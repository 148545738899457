/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorCounselingReportOption,
  PharmacyCounselorCounselingReportOptionFromJSON,
  PharmacyCounselorCounselingReportOptionFromJSONTyped,
  PharmacyCounselorCounselingReportOptionToJSON,
} from "./";

export const PharmacyCounselorCounselingReportQuestionnaireTypename =
  "PharmacyCounselorCounselingReportQuestionnaire" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReportQuestionnaire
 */
export interface PharmacyCounselorCounselingReportQuestionnaire {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportQuestionnaire
   */
  answerPattern?: PharmacyCounselorCounselingReportQuestionnaireAnswerPatternEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportQuestionnaire
   */
  category?: PharmacyCounselorCounselingReportQuestionnaireCategoryEnum;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReportQuestionnaire
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorCounselingReportQuestionnaire
   */
  isRequiredAnswer?: boolean;
  /**
   *
   * @type {Array<PharmacyCounselorCounselingReportOption>}
   * @memberof PharmacyCounselorCounselingReportQuestionnaire
   */
  options?: Array<PharmacyCounselorCounselingReportOption>;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportQuestionnaire
   */
  question: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportQuestionnaire
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportQuestionnaireAnswerPatternEnum {
  SingleSelection = "single_selection",
  MultipleSelection = "multiple_selection",
  FreeEntry = "free_entry",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportQuestionnaireCategoryEnum {
  ConcomitantMedication = "concomitant_medication",
  MedicationEffect = "medication_effect",
  MedicationStatus = "medication_status",
  Consultation = "consultation",
  PatientInformation = "patient_information",
  LifeFunctioning = "life_functioning",
  LifeCharacteristic = "life_characteristic",
}

export function PharmacyCounselorCounselingReportQuestionnaireFromJSON(
  json: any
): PharmacyCounselorCounselingReportQuestionnaire {
  return PharmacyCounselorCounselingReportQuestionnaireFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportQuestionnaireFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReportQuestionnaire {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportQuestionnaireTypename,

    answerPattern: !exists(json, "answer_pattern") ? undefined : json["answer_pattern"],
    category: !exists(json, "category") ? undefined : json["category"],
    id: json["id"],
    isRequiredAnswer: !exists(json, "is_required_answer") ? undefined : json["is_required_answer"],
    options: !exists(json, "options")
      ? undefined
      : (json["options"] as Array<any>).map(PharmacyCounselorCounselingReportOptionFromJSON),
    question: json["question"],
  };
}

export function PharmacyCounselorCounselingReportQuestionnaireToJSON(
  object?: PharmacyCounselorCounselingReportQuestionnaire | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer_pattern: value.answerPattern,
    category: value.category,
    id: value.id,
    is_required_answer: value.isRequiredAnswer,
    options:
      value.options === undefined
        ? undefined
        : (value.options as Array<any>).map(PharmacyCounselorCounselingReportOptionToJSON),
    question: value.question,
  };
}
