import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const OpenInNewIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M51,1579 C51.5522847,1579 52,1579.44772 52,1580 C52,1580.51284 51.6139598,1580.93551 51.1166211,1580.99327 L51,1581 L48,1581 C46.4023191,1581 45.0963391,1582.24892 45.0050927,1583.82373 L45,1584 L45,1592 C45,1593.59768 46.24892,1594.90366 47.8237272,1594.99491 L48,1595 L56,1595 C57.5976809,1595 58.9036609,1593.75108 58.9949073,1592.17627 L59,1592 L59,1589 C59,1588.44772 59.4477153,1588 60,1588 C60.5128358,1588 60.9355072,1588.38604 60.9932723,1588.88338 L61,1589 L61,1592 C61,1594.68875 58.8776933,1596.88182 56.2168896,1596.99538 L56,1597 L48,1597 C45.3112453,1597 43.1181819,1594.87769 43.0046195,1592.21689 L43,1592 L43,1584 C43,1581.31125 45.1223067,1579.11818 47.7831104,1579.00462 L48,1579 L51,1579 Z M60,1579 C60.55,1579 61,1579.45 61,1580 L61,1580 L61,1585 C61,1585.55 60.55,1586 60,1586 C59.45,1586 59,1585.55 59,1585 L59,1585 L59,1582.41 L51.87,1589.54 C51.48,1589.93 50.85,1589.93 50.46,1589.54 C50.07,1589.15 50.07,1588.52 50.46,1588.13 L50.46,1588.13 L57.59,1581 L55,1581 C54.45,1581 54,1580.55 54,1580 C54,1579.45 54.45,1579 55,1579 L55,1579 Z"
        transform="translate(-40 -1576)"
      />
    </svg>
  );
};

export default OpenInNewIcon;
