/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const DeliveryOrderTimeTypename = "DeliveryOrderTime" as const;

/**
 *
 * @export
 * @interface DeliveryOrderTime
 */
export interface DeliveryOrderTime {
  /**
   *
   * @type {Date}
   * @memberof DeliveryOrderTime
   */
  from?: string;
  /**
   *
   * @type {Date}
   * @memberof DeliveryOrderTime
   */
  to?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryOrderTime
   */
  readonly __typename?: string;
}

export function DeliveryOrderTimeFromJSON(json: any): DeliveryOrderTime {
  return DeliveryOrderTimeFromJSONTyped(json, false);
}

export function DeliveryOrderTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryOrderTime {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryOrderTimeTypename,

    from:
      !exists(json, "from") || (typeof json["from"] === "string" && json["from"].substring(0, 4) === "0001")
        ? undefined
        : json["from"],
    to:
      !exists(json, "to") || (typeof json["to"] === "string" && json["to"].substring(0, 4) === "0001")
        ? undefined
        : json["to"],
  };
}

export function DeliveryOrderTimeToJSON(object?: DeliveryOrderTime | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    from: value.from === undefined ? undefined : moment(value.from).tz("Asia/Tokyo").format(),
    to: value.to === undefined ? undefined : moment(value.to).tz("Asia/Tokyo").format(),
  };
}
