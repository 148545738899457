import { errorHandlerReport } from "@mgdx-libs/error-handler";
import { logger } from "@mgdx-libs/logger";
import * as Sentry from "@sentry/react";
import { signOut } from "firebase/auth";
import { useCallback } from "react";

import { getFirebaseAuth } from "../utils/getFirebaseAuth";

export type SignOutFirebase = () => void | Promise<void>;

export const useSignOutFirebase = () =>
  useCallback<SignOutFirebase>(async () => {
    const firebaseAuth = getFirebaseAuth();
    if (firebaseAuth) {
      await signOut(firebaseAuth)
        .then(() => {
          logger.debug("Sign out Firebase");
          if (process.env.ENABLE_SENTRY === "true") {
            Sentry.configureScope((scope) => {
              scope.setUser(null);
            });
          }
        })
        .catch(async (error) => {
          logger.error(error);
          await errorHandlerReport(error);
        });
    }
  }, []);
