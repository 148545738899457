/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostShopReregisterAuthenticationRequestBodyTypename =
  "PostShopReregisterAuthenticationRequestBody" as const;

/**
 *
 * @export
 * @interface PostShopReregisterAuthenticationRequestBody
 */
export interface PostShopReregisterAuthenticationRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostShopReregisterAuthenticationRequestBody
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof PostShopReregisterAuthenticationRequestBody
   */
  temporaryPassword: string;
  /**
   *
   * @type {string}
   * @memberof PostShopReregisterAuthenticationRequestBody
   */
  user: string;
  /**
   *
   * @type {string}
   * @memberof PostShopReregisterAuthenticationRequestBody
   */
  userPoolId: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostShopReregisterAuthenticationRequestBody
   */
  readonly __typename?: string;
}

export function PostShopReregisterAuthenticationRequestBodyFromJSON(
  json: any
): PostShopReregisterAuthenticationRequestBody {
  return PostShopReregisterAuthenticationRequestBodyFromJSONTyped(json, false);
}

export function PostShopReregisterAuthenticationRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostShopReregisterAuthenticationRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostShopReregisterAuthenticationRequestBodyTypename,

    clientId: json["client_id"],
    temporaryPassword: json["temporary_password"],
    user: json["user"],
    userPoolId: json["user_pool_id"],
  };
}

export function PostShopReregisterAuthenticationRequestBodyToJSON(
  object?: PostShopReregisterAuthenticationRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    client_id: value.clientId,
    temporary_password: value.temporaryPassword,
    user: value.user,
    user_pool_id: value.userPoolId,
  };
}
