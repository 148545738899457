import { ZendeskSource, ZendeskSourceFromJSON, ZendeskSourceToJSON } from "./ZendeskSource";

export const ZendeskViaTypename = "ZendeskVia" as const;

/**
 *
 * @export
 * @interface ZendeskVia
 * @see https://developer.zendesk.com/documentation/ticketing/reference-guides/via-object-reference/
 */
export interface ZendeskVia {
  /**
   *
   * @type {string}
   * @memberof ZendeskVia
   */
  channel: ZendeskChannelEnum;
  /**
   *
   * @type {ZendeskSource}
   * @memberof ZendeskVia
   */
  source: ZendeskSource;
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskVia
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskViaFromJSON(json: any): ZendeskVia {
  return ZendeskViaFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskViaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskVia {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ZendeskViaTypename,
    channel: json["channel"],
    source: ZendeskSourceFromJSON(json["source"]),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskViaToJSON(object?: ZendeskVia | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    channel: value.channel,
    source: ZendeskSourceToJSON(value.source),
  };
}

export enum ZendeskChannelEnum {
  Web = "web",
  Mobile = "mobile",
  Rule = "rule",
  System = "system",
}
