/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientVerifyEmailOption,
  PatientVerifyEmailOptionFromJSON,
  PatientVerifyEmailOptionFromJSONTyped,
  PatientVerifyEmailOptionToJSON,
} from "./";

export const PostPatientRequestBodyTypename = "PostPatientRequestBody" as const;

/**
 *
 * @export
 * @interface PostPatientRequestBody
 */
export interface PostPatientRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  gender?: PostPatientRequestBodyGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  hasMedicineNotebook?: PostPatientRequestBodyHasMedicineNotebookEnum;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  insuranceCard?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  invitationId?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  lastName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PostPatientRequestBody
   */
  otherMedicalImages?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  password: string;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  postalCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof PostPatientRequestBody
   */
  smsAvailable?: boolean;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  tel?: string;
  /**
   *
   * @type {PatientVerifyEmailOption}
   * @memberof PostPatientRequestBody
   */
  verifyEmailOption?: PatientVerifyEmailOption;
  /**
   *
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  wantsGeneric?: PostPatientRequestBodyWantsGenericEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostPatientRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PostPatientRequestBodyGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}
/**
 * @export
 * @enum {string}
 */
export enum PostPatientRequestBodyHasMedicineNotebookEnum {
  None = "none",
  Paper = "paper",
  Digital = "digital",
  Yqb = "yqb",
}
/**
 * @export
 * @enum {string}
 */
export enum PostPatientRequestBodyWantsGenericEnum {
  None = "none",
  Want = "want",
  Consultation = "consultation",
}

export function PostPatientRequestBodyFromJSON(json: any): PostPatientRequestBody {
  return PostPatientRequestBodyFromJSONTyped(json, false);
}

export function PostPatientRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPatientRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostPatientRequestBodyTypename,

    addressLine1: !exists(json, "address_line1") ? undefined : json["address_line1"],
    addressLine2: !exists(json, "address_line2") ? undefined : json["address_line2"],
    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    email: !exists(json, "email") ? undefined : json["email"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    hasMedicineNotebook: !exists(json, "has_medicine_notebook") ? undefined : json["has_medicine_notebook"],
    insuranceCard: !exists(json, "insurance_card") ? undefined : json["insurance_card"],
    invitationId: !exists(json, "invitation_id") ? undefined : json["invitation_id"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    otherMedicalImages: !exists(json, "other_medical_images") ? undefined : json["other_medical_images"],
    password: json["password"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    smsAvailable: !exists(json, "sms_available") ? undefined : json["sms_available"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
    verifyEmailOption: !exists(json, "verify_email_option")
      ? undefined
      : PatientVerifyEmailOptionFromJSON(json["verify_email_option"]),
    wantsGeneric: !exists(json, "wants_generic") ? undefined : json["wants_generic"],
  };
}

export function PostPatientRequestBodyToJSON(object?: PostPatientRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    date_of_birth: value.dateOfBirth,
    email: value.email,
    first_name: value.firstName,
    gender: value.gender,
    has_medicine_notebook: value.hasMedicineNotebook,
    insurance_card: value.insuranceCard,
    invitation_id: value.invitationId,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    other_medical_images: value.otherMedicalImages,
    password: value.password,
    postal_code: value.postalCode,
    sms_available: value.smsAvailable,
    tel: value.tel,
    verify_email_option: PatientVerifyEmailOptionToJSON(value.verifyEmailOption),
    wants_generic: value.wantsGeneric,
  };
}
