/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PharmacyOperator,
  PharmacyOperatorFromJSON,
  PharmacyOperatorToJSON,
  PharmacyOperatorRegistrable,
  PharmacyOperatorRegistrableFromJSON,
  PharmacyOperatorRegistrableToJSON,
  PharmacyOperatorUpdatable,
  PharmacyOperatorUpdatableFromJSON,
  PharmacyOperatorUpdatableToJSON,
  PharmacyOperators,
  PharmacyOperatorsFromJSON,
  PharmacyOperatorsToJSON,
  PostOperatorResetPasswordRequestBody,
  PostOperatorResetPasswordRequestBodyFromJSON,
  PostOperatorResetPasswordRequestBodyToJSON,
  PutOperatorPasswordRequestBody,
  PutOperatorPasswordRequestBodyFromJSON,
  PutOperatorPasswordRequestBodyToJSON,
} from "../models";

export interface DeleteOperatorRequest {
  operatorId: number;
}

export interface GetOperatorsRequest {
  id?: Array<number>;
  chainId?: number;
  limit?: number;
  offset?: number;
}

export interface PatchOperatorPasswordRequest {
  putOperatorPasswordRequestBody: PutOperatorPasswordRequestBody;
}

export interface PostOperatorResetPasswordsRequest {
  postOperatorResetPasswordRequestBody: PostOperatorResetPasswordRequestBody;
}

export interface PostOperatorsRequest {
  pharmacyOperatorRegistrable: Array<PharmacyOperatorRegistrable>;
}

export interface UpdateOperatorRequest {
  operatorId: number;
  pharmacyOperatorUpdatable: PharmacyOperatorUpdatable;
}

/**
 *
 */
export class OperatorApi extends runtime.BaseAPI {
  /**
   * Delete operator
   */
  async deleteOperatorRaw(
    requestParameters: DeleteOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.operatorId === null || requestParameters.operatorId === undefined) {
      throw new runtime.RequiredError(
        "operatorId",
        "Required parameter requestParameters.operatorId was null or undefined when calling deleteOperator."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operators/{operator_id}`.replace(
          `{${"operator_id"}}`,
          encodeURIComponent(String(requestParameters.operatorId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete operator
   */
  async deleteOperator(requestParameters: DeleteOperatorRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteOperatorRaw(requestParameters, initOverrides);
  }

  /**
   * Logout operator by auth_id from JWT
   */
  async deleteOperatorSessionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operator/session`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Logout operator by auth_id from JWT
   */
  async deleteOperatorSession(initOverrides?: RequestInit): Promise<void> {
    await this.deleteOperatorSessionRaw(initOverrides);
  }

  /**
   * Get operator by auth_id from JWT
   */
  async getOperatorRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PharmacyOperator>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operator`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyOperatorFromJSON(jsonValue));
  }

  /**
   * Get operator by auth_id from JWT
   */
  async getOperator(initOverrides?: RequestInit): Promise<PharmacyOperator> {
    return promiseRetry(
      (retry) =>
        this.getOperatorRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search operator by condition
   */
  async getOperatorsRaw(
    requestParameters: GetOperatorsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyOperators>> {
    const queryParameters: any = {};

    if (requestParameters.id) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operators`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyOperatorsFromJSON(jsonValue));
  }

  /**
   * Search operator by condition
   */
  async getOperators(requestParameters: GetOperatorsRequest, initOverrides?: RequestInit): Promise<PharmacyOperators> {
    return promiseRetry(
      (retry) =>
        this.getOperatorsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update own password with auth_id in JWT
   */
  async patchOperatorPasswordRaw(
    requestParameters: PatchOperatorPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyOperator>> {
    if (
      requestParameters.putOperatorPasswordRequestBody === null ||
      requestParameters.putOperatorPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putOperatorPasswordRequestBody",
        "Required parameter requestParameters.putOperatorPasswordRequestBody was null or undefined when calling patchOperatorPassword."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operator/password`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutOperatorPasswordRequestBodyToJSON(requestParameters.putOperatorPasswordRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyOperatorFromJSON(jsonValue));
  }

  /**
   * Update own password with auth_id in JWT
   */
  async patchOperatorPassword(
    requestParameters: PatchOperatorPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyOperator> {
    return promiseRetry(
      (retry) =>
        this.patchOperatorPasswordRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Accept password reset complete by auth_id from JWT
   */
  async postOperatorPasswordResetCompletionEmailsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operator/reset-password-completions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept password reset complete by auth_id from JWT
   */
  async postOperatorPasswordResetCompletionEmails(initOverrides?: RequestInit): Promise<void> {
    await this.postOperatorPasswordResetCompletionEmailsRaw(initOverrides);
  }

  /**
   * Send a password reset email
   */
  async postOperatorResetPasswordsRaw(
    requestParameters: PostOperatorResetPasswordsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.postOperatorResetPasswordRequestBody === null ||
      requestParameters.postOperatorResetPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postOperatorResetPasswordRequestBody",
        "Required parameter requestParameters.postOperatorResetPasswordRequestBody was null or undefined when calling postOperatorResetPasswords."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/operator-reset-passwords`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostOperatorResetPasswordRequestBodyToJSON(requestParameters.postOperatorResetPasswordRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send a password reset email
   */
  async postOperatorResetPasswords(
    requestParameters: PostOperatorResetPasswordsRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.postOperatorResetPasswordsRaw(requestParameters, initOverrides);
  }

  /**
   * Register operator
   */
  async postOperatorsRaw(
    requestParameters: PostOperatorsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyOperator>>> {
    if (
      requestParameters.pharmacyOperatorRegistrable === null ||
      requestParameters.pharmacyOperatorRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyOperatorRegistrable",
        "Required parameter requestParameters.pharmacyOperatorRegistrable was null or undefined when calling postOperators."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operators`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.pharmacyOperatorRegistrable.map(PharmacyOperatorRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyOperatorFromJSON));
  }

  /**
   * Register operator
   */
  async postOperators(
    requestParameters: PostOperatorsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyOperator>> {
    return promiseRetry(
      (retry) =>
        this.postOperatorsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update operator
   */
  async updateOperatorRaw(
    requestParameters: UpdateOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyOperator>> {
    if (requestParameters.operatorId === null || requestParameters.operatorId === undefined) {
      throw new runtime.RequiredError(
        "operatorId",
        "Required parameter requestParameters.operatorId was null or undefined when calling updateOperator."
      );
    }

    if (
      requestParameters.pharmacyOperatorUpdatable === null ||
      requestParameters.pharmacyOperatorUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyOperatorUpdatable",
        "Required parameter requestParameters.pharmacyOperatorUpdatable was null or undefined when calling updateOperator."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/operators/{operator_id}`.replace(
          `{${"operator_id"}}`,
          encodeURIComponent(String(requestParameters.operatorId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyOperatorUpdatableToJSON(requestParameters.pharmacyOperatorUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyOperatorFromJSON(jsonValue));
  }

  /**
   * Update operator
   */
  async updateOperator(
    requestParameters: UpdateOperatorRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyOperator> {
    return promiseRetry(
      (retry) =>
        this.updateOperatorRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
