/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientCompany,
  PatientCompanyFromJSON,
  PatientCompanyFromJSONTyped,
  PatientCompanyToJSON,
  PatientIngredient,
  PatientIngredientFromJSON,
  PatientIngredientFromJSONTyped,
  PatientIngredientToJSON,
} from "./";

export const PatientMedicineMasterTypename = "PatientMedicineMaster" as const;

/**
 *
 * @export
 * @interface PatientMedicineMaster
 */
export interface PatientMedicineMaster {
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  aftereffect?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  attention?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  comment?: string;
  /**
   *
   * @type {PatientCompany}
   * @memberof PatientMedicineMaster
   */
  company?: PatientCompany;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  handle?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  headnote?: string;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineMaster
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  imageUrl?: string;
  /**
   *
   * @type {PatientIngredient}
   * @memberof PatientMedicineMaster
   */
  ingredient?: PatientIngredient;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  madeMonth?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  mentioned?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  operation?: string;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineMaster
   */
  receiptCode?: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  shape?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  yjCode?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineMaster
   */
  readonly __typename?: string;
}

export function PatientMedicineMasterFromJSON(json: any): PatientMedicineMaster {
  return PatientMedicineMasterFromJSONTyped(json, false);
}

export function PatientMedicineMasterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMedicineMaster {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineMasterTypename,

    aftereffect: !exists(json, "aftereffect") ? undefined : json["aftereffect"],
    attention: !exists(json, "attention") ? undefined : json["attention"],
    comment: !exists(json, "comment") ? undefined : json["comment"],
    company: !exists(json, "company") ? undefined : PatientCompanyFromJSON(json["company"]),
    handle: !exists(json, "handle") ? undefined : json["handle"],
    headnote: !exists(json, "headnote") ? undefined : json["headnote"],
    id: !exists(json, "id") ? undefined : json["id"],
    imageUrl: !exists(json, "image_url") ? undefined : json["image_url"],
    ingredient: !exists(json, "ingredient") ? undefined : PatientIngredientFromJSON(json["ingredient"]),
    madeMonth: !exists(json, "made_month") ? undefined : json["made_month"],
    mentioned: !exists(json, "mentioned") ? undefined : json["mentioned"],
    message: !exists(json, "message") ? undefined : json["message"],
    name: !exists(json, "name") ? undefined : json["name"],
    operation: !exists(json, "operation") ? undefined : json["operation"],
    receiptCode: !exists(json, "receipt_code") ? undefined : json["receipt_code"],
    shape: !exists(json, "shape") ? undefined : json["shape"],
    yjCode: !exists(json, "yj_code") ? undefined : json["yj_code"],
  };
}

export function PatientMedicineMasterToJSON(object?: PatientMedicineMaster | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    aftereffect: value.aftereffect,
    attention: value.attention,
    comment: value.comment,
    company: PatientCompanyToJSON(value.company),
    handle: value.handle,
    headnote: value.headnote,
    id: value.id,
    image_url: value.imageUrl,
    ingredient: PatientIngredientToJSON(value.ingredient),
    made_month: value.madeMonth,
    mentioned: value.mentioned,
    message: value.message,
    name: value.name,
    operation: value.operation,
    receipt_code: value.receiptCode,
    shape: value.shape,
    yj_code: value.yjCode,
  };
}
