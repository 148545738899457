import isClient from "@mgdx/shared/src/utils/isClient";
import { useMemo } from "react";

const IS_IN_APP_VIEW_STORAGE_KEY = "isInAppView";

const hasSessionStorage = (): boolean => isClient() && typeof window.sessionStorage !== "undefined";

export const getInAppView = (): boolean => {
  if (hasSessionStorage()) {
    return sessionStorage.getItem(IS_IN_APP_VIEW_STORAGE_KEY) === "yes" || false;
  }

  return false;
};

export const useInAppView = (): boolean => useMemo(() => getInAppView(), []);

export const setInAppView = (inAppView: boolean): void => {
  if (hasSessionStorage()) {
    sessionStorage.setItem(IS_IN_APP_VIEW_STORAGE_KEY, inAppView ? "yes" : "no");
  }
};

export const clearInAppView = (): void => {
  if (hasSessionStorage()) {
    sessionStorage.removeItem(IS_IN_APP_VIEW_STORAGE_KEY);
  }
};

export default useInAppView;
