/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostDispenseRecordRequestBodyTypename = "PostDispenseRecordRequestBody" as const;

/**
 *
 * @export
 * @interface PostDispenseRecordRequestBody
 */
export interface PostDispenseRecordRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostDispenseRecordRequestBody
   */
  jahistcData: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostDispenseRecordRequestBody
   */
  readonly __typename?: string;
}

export function PostDispenseRecordRequestBodyFromJSON(json: any): PostDispenseRecordRequestBody {
  return PostDispenseRecordRequestBodyFromJSONTyped(json, false);
}

export function PostDispenseRecordRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostDispenseRecordRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostDispenseRecordRequestBodyTypename,

    jahistcData: json["jahistc_data"],
  };
}

export function PostDispenseRecordRequestBodyToJSON(object?: PostDispenseRecordRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    jahistc_data: value.jahistcData,
  };
}
