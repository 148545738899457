/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineNotebook,
  PatientMedicineNotebookFromJSON,
  PatientMedicineNotebookFromJSONTyped,
  PatientMedicineNotebookToJSON,
} from "./";

export const PatientFamilyAccountTypename = "PatientFamilyAccount" as const;

/**
 *
 * @export
 * @interface PatientFamilyAccount
 */
export interface PatientFamilyAccount {
  /**
   *
   * @type {string}
   * @memberof PatientFamilyAccount
   */
  dateOfBirth: string;
  /**
   *
   * @type {object}
   * @memberof PatientFamilyAccount
   */
  externalIds?: object;
  /**
   *
   * @type {string}
   * @memberof PatientFamilyAccount
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PatientFamilyAccount
   */
  gender: PatientFamilyAccountGenderEnum;
  /**
   *
   * @type {number}
   * @memberof PatientFamilyAccount
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PatientFamilyAccount
   */
  insuranceCardPath?: string;
  /**
   *
   * @type {string}
   * @memberof PatientFamilyAccount
   */
  kanaFirstName: string;
  /**
   *
   * @type {string}
   * @memberof PatientFamilyAccount
   */
  kanaLastName: string;
  /**
   *
   * @type {string}
   * @memberof PatientFamilyAccount
   */
  lastName: string;
  /**
   *
   * @type {Array<PatientMedicineNotebook>}
   * @memberof PatientFamilyAccount
   */
  medicineNotebooks?: Array<PatientMedicineNotebook>;
  /**
   *
   * @type {Array<string>}
   * @memberof PatientFamilyAccount
   */
  otherMedicalImagePaths?: Array<string>;
  /**
   *
   * @type {object}
   * @memberof PatientFamilyAccount
   */
  receiptCodes?: object;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientFamilyAccount
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientFamilyAccountGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PatientFamilyAccountFromJSON(json: any): PatientFamilyAccount {
  return PatientFamilyAccountFromJSONTyped(json, false);
}

export function PatientFamilyAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientFamilyAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientFamilyAccountTypename,

    dateOfBirth: json["date_of_birth"],
    externalIds: !exists(json, "external_ids") ? undefined : json["external_ids"],
    firstName: json["first_name"],
    gender: json["gender"],
    id: json["id"],
    insuranceCardPath: !exists(json, "insurance_card_path") ? undefined : json["insurance_card_path"],
    kanaFirstName: json["kana_first_name"],
    kanaLastName: json["kana_last_name"],
    lastName: json["last_name"],
    medicineNotebooks: !exists(json, "medicine_notebooks")
      ? undefined
      : (json["medicine_notebooks"] as Array<any>).map(PatientMedicineNotebookFromJSON),
    otherMedicalImagePaths: !exists(json, "other_medical_image_paths") ? undefined : json["other_medical_image_paths"],
    receiptCodes: !exists(json, "receipt_codes") ? undefined : json["receipt_codes"],
  };
}

export function PatientFamilyAccountToJSON(object?: PatientFamilyAccount | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    external_ids: value.externalIds,
    first_name: value.firstName,
    gender: value.gender,
    id: value.id,
    insurance_card_path: value.insuranceCardPath,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    medicine_notebooks:
      value.medicineNotebooks === undefined
        ? undefined
        : (value.medicineNotebooks as Array<any>).map(PatientMedicineNotebookToJSON),
    other_medical_image_paths: value.otherMedicalImagePaths,
    receipt_codes: value.receiptCodes,
  };
}
