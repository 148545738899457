/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostBulkEmailResponseTypename = "PostBulkEmailResponse" as const;

/**
 *
 * @export
 * @interface PostBulkEmailResponse
 */
export interface PostBulkEmailResponse {
  /**
   *
   * @type {string}
   * @memberof PostBulkEmailResponse
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof PostBulkEmailResponse
   */
  title: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PostBulkEmailResponse
   */
  to: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostBulkEmailResponse
   */
  readonly __typename?: string;
}

export function PostBulkEmailResponseFromJSON(json: any): PostBulkEmailResponse {
  return PostBulkEmailResponseFromJSONTyped(json, false);
}

export function PostBulkEmailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostBulkEmailResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostBulkEmailResponseTypename,

    body: json["body"],
    title: json["title"],
    to: json["to"],
  };
}

export function PostBulkEmailResponseToJSON(object?: PostBulkEmailResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    body: value.body,
    title: value.title,
    to: value.to,
  };
}
