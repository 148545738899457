import { SenderTypeEnum } from "@mgdx/talk/models";
import Avatar from "@mgdx/ui/components/Avatar";
import React from "react";

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  senderType: SenderTypeEnum;
};

const TalkAvatar = ({ senderType }: Props) => {
  if (senderType === SenderTypeEnum.Patient) {
    return <Avatar situation="patient" />;
  }

  if (senderType === SenderTypeEnum.Clinic) {
    return <Avatar situation="clinic" />;
  }

  if (senderType === SenderTypeEnum.Pharmacy) {
    return <Avatar situation="pharmacy" />;
  }

  return null;
};

export default TalkAvatar;
