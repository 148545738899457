/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyPharmacyUsables,
  PharmacyPharmacyUsablesFromJSON,
  PharmacyPharmacyUsablesFromJSONTyped,
  PharmacyPharmacyUsablesToJSON,
} from "./";

export const PharmacyPaymentMethodTypename = "PharmacyPaymentMethod" as const;

/**
 *
 * @export
 * @interface PharmacyPaymentMethod
 */
export interface PharmacyPaymentMethod {
  /**
   *
   * @type {PharmacyPharmacyUsables}
   * @memberof PharmacyPaymentMethod
   */
  usableCreditCard?: PharmacyPharmacyUsables;
  /**
   *
   * @type {PharmacyPharmacyUsables}
   * @memberof PharmacyPaymentMethod
   */
  usableElectronicMoney?: PharmacyPharmacyUsables;
  /**
   *
   * @type {PharmacyPharmacyUsables}
   * @memberof PharmacyPaymentMethod
   */
  usableQrPayment?: PharmacyPharmacyUsables;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPaymentMethod
   */
  readonly __typename?: string;
}

export function PharmacyPaymentMethodFromJSON(json: any): PharmacyPaymentMethod {
  return PharmacyPaymentMethodFromJSONTyped(json, false);
}

export function PharmacyPaymentMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPaymentMethod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPaymentMethodTypename,

    usableCreditCard: !exists(json, "usable_credit_card")
      ? undefined
      : PharmacyPharmacyUsablesFromJSON(json["usable_credit_card"]),
    usableElectronicMoney: !exists(json, "usable_electronic_money")
      ? undefined
      : PharmacyPharmacyUsablesFromJSON(json["usable_electronic_money"]),
    usableQrPayment: !exists(json, "usable_qr_payment")
      ? undefined
      : PharmacyPharmacyUsablesFromJSON(json["usable_qr_payment"]),
  };
}

export function PharmacyPaymentMethodToJSON(object?: PharmacyPaymentMethod | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    usable_credit_card: PharmacyPharmacyUsablesToJSON(value.usableCreditCard),
    usable_electronic_money: PharmacyPharmacyUsablesToJSON(value.usableElectronicMoney),
    usable_qr_payment: PharmacyPharmacyUsablesToJSON(value.usableQrPayment),
  };
}
