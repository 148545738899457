/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  DeliveryClient,
  DeliveryClientFromJSON,
  DeliveryClientFromJSONTyped,
  DeliveryClientToJSON,
  DeliveryCounseling,
  DeliveryCounselingFromJSON,
  DeliveryCounselingFromJSONTyped,
  DeliveryCounselingToJSON,
  DeliveryDelivery,
  DeliveryDeliveryFromJSON,
  DeliveryDeliveryFromJSONTyped,
  DeliveryDeliveryToJSON,
  DeliveryDesiredTime,
  DeliveryDesiredTimeFromJSON,
  DeliveryDesiredTimeFromJSONTyped,
  DeliveryDesiredTimeToJSON,
  DeliveryDriver,
  DeliveryDriverFromJSON,
  DeliveryDriverFromJSONTyped,
  DeliveryDriverToJSON,
  DeliveryOrderEvents,
  DeliveryOrderEventsFromJSON,
  DeliveryOrderEventsFromJSONTyped,
  DeliveryOrderEventsToJSON,
  DeliveryOrderTime,
  DeliveryOrderTimeFromJSON,
  DeliveryOrderTimeFromJSONTyped,
  DeliveryOrderTimeToJSON,
  DeliveryPickup,
  DeliveryPickupFromJSON,
  DeliveryPickupFromJSONTyped,
  DeliveryPickupToJSON,
} from "./";

export const DeliveryOrderTypename = "DeliveryOrder" as const;

/**
 *
 * @export
 * @interface DeliveryOrder
 */
export interface DeliveryOrder {
  /**
   *
   * @type {boolean}
   * @memberof DeliveryOrder
   */
  cancelable?: boolean;
  /**
   *
   * @type {DeliveryCounseling}
   * @memberof DeliveryOrder
   */
  counseling?: DeliveryCounseling;
  /**
   *
   * @type {DeliveryDelivery}
   * @memberof DeliveryOrder
   */
  delivery?: DeliveryDelivery;
  /**
   *
   * @type {DeliveryDesiredTime}
   * @memberof DeliveryOrder
   */
  desiredTime?: DeliveryDesiredTime;
  /**
   *
   * @type {DeliveryDriver}
   * @memberof DeliveryOrder
   */
  driver?: DeliveryDriver;
  /**
   *
   * @type {DeliveryOrderEvents}
   * @memberof DeliveryOrder
   */
  events?: DeliveryOrderEvents;
  /**
   *
   * @type {number}
   * @memberof DeliveryOrder
   */
  fee?: number;
  /**
   *
   * @type {DeliveryClient}
   * @memberof DeliveryOrder
   */
  from?: DeliveryClient;
  /**
   *
   * @type {string}
   * @memberof DeliveryOrder
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryOrder
   */
  lastEvent: DeliveryOrderLastEventEnum;
  /**
   *
   * @type {string}
   * @memberof DeliveryOrder
   */
  note?: string;
  /**
   *
   * @type {number}
   * @memberof DeliveryOrder
   */
  patientId?: number;
  /**
   *
   * @type {DeliveryPickup}
   * @memberof DeliveryOrder
   */
  pickup?: DeliveryPickup;
  /**
   *
   * @type {string}
   * @memberof DeliveryOrder
   */
  provider?: DeliveryOrderProviderEnum;
  /**
   *
   * @type {Date}
   * @memberof DeliveryOrder
   */
  registeredTime?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryOrder
   */
  shortId: string;
  /**
   *
   * @type {DeliveryOrderTime}
   * @memberof DeliveryOrder
   */
  time?: DeliveryOrderTime;
  /**
   *
   * @type {DeliveryClient}
   * @memberof DeliveryOrder
   */
  to?: DeliveryClient;
  /**
   *
   * @type {string}
   * @memberof DeliveryOrder
   */
  trackingUrl?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryOrder
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DeliveryOrderLastEventEnum {
  Registered = "registered",
  Requested = "requested",
  Accepted = "accepted",
  Started = "started",
  Departed = "departed",
  Arrived = "arrived",
  Finished = "finished",
  Canceled = "canceled",
  Rejected = "rejected",
  Absence = "absence",
  Returned = "returned",
}
/**
 * @export
 * @enum {string}
 */
export enum DeliveryOrderProviderEnum {
  Cbcloud = "cbcloud",
  Wolt = "wolt",
  Yamato = "yamato",
  YamatoStandard = "yamato_standard",
}

export function DeliveryOrderFromJSON(json: any): DeliveryOrder {
  return DeliveryOrderFromJSONTyped(json, false);
}

export function DeliveryOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryOrderTypename,

    cancelable: !exists(json, "cancelable") ? undefined : json["cancelable"],
    counseling: !exists(json, "counseling") ? undefined : DeliveryCounselingFromJSON(json["counseling"]),
    delivery: !exists(json, "delivery") ? undefined : DeliveryDeliveryFromJSON(json["delivery"]),
    desiredTime: !exists(json, "desired_time") ? undefined : DeliveryDesiredTimeFromJSON(json["desired_time"]),
    driver: !exists(json, "driver") ? undefined : DeliveryDriverFromJSON(json["driver"]),
    events: !exists(json, "events") ? undefined : DeliveryOrderEventsFromJSON(json["events"]),
    fee: !exists(json, "fee") ? undefined : json["fee"],
    from: !exists(json, "from") ? undefined : DeliveryClientFromJSON(json["from"]),
    id: !exists(json, "id") ? undefined : json["id"],
    lastEvent: json["last_event"],
    note: !exists(json, "note") ? undefined : json["note"],
    patientId: !exists(json, "patient_id") ? undefined : json["patient_id"],
    pickup: !exists(json, "pickup") ? undefined : DeliveryPickupFromJSON(json["pickup"]),
    provider: !exists(json, "provider") ? undefined : json["provider"],
    registeredTime:
      !exists(json, "registered_time") ||
      (typeof json["registered_time"] === "string" && json["registered_time"].substring(0, 4) === "0001")
        ? undefined
        : json["registered_time"],
    shortId: json["short_id"],
    time: !exists(json, "time") ? undefined : DeliveryOrderTimeFromJSON(json["time"]),
    to: !exists(json, "to") ? undefined : DeliveryClientFromJSON(json["to"]),
    trackingUrl: !exists(json, "tracking_url") ? undefined : json["tracking_url"],
  };
}

export function DeliveryOrderToJSON(object?: DeliveryOrder | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    cancelable: value.cancelable,
    counseling: DeliveryCounselingToJSON(value.counseling),
    delivery: DeliveryDeliveryToJSON(value.delivery),
    desired_time: DeliveryDesiredTimeToJSON(value.desiredTime),
    driver: DeliveryDriverToJSON(value.driver),
    events: DeliveryOrderEventsToJSON(value.events),
    fee: value.fee,
    from: DeliveryClientToJSON(value.from),
    id: value.id,
    last_event: value.lastEvent,
    note: value.note,
    patient_id: value.patientId,
    pickup: DeliveryPickupToJSON(value.pickup),
    provider: value.provider,
    registered_time:
      value.registeredTime === undefined ? undefined : moment(value.registeredTime).tz("Asia/Tokyo").format(),
    short_id: value.shortId,
    time: DeliveryOrderTimeToJSON(value.time),
    to: DeliveryClientToJSON(value.to),
    tracking_url: value.trackingUrl,
  };
}
