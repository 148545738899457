import medicineNotebookAutoLinkApi from "@mgdx/api/lib/medicineNotebookAutoLinkApi";
import {
  MedicineNotebookAutoLink,
  MedicineNotebookAutoLinkConnection,
  MedicineNotebookAutoLinkCreateRequestTypeEnum,
  MedicineNotebookAutoLinkSearchParams,
} from "@mgdx/shared/src/models/MedicineNotebookAutoLink";
import { createRequestAction } from "@mgdx-libs/redux-requests";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";

export const createMedicineNotebookAutoLink = createRequestAction<
  { requestIds: string[]; pharmacyId: number; isLiffLogined: boolean },
  MedicineNotebookAutoLink[]
>(
  "requests/medicineNotebookAutoLink/createMedicineNotebookAutoLink",
  ({ requestIds, pharmacyId, isLiffLogined }, meta) => ({
    request: {
      promise: Promise.all(
        requestIds?.map((id) =>
          medicineNotebookAutoLinkApi.postMedicineNotebookAutoLinks({
            postMedicineNotebookAutoLinkRequestBody: {
              pharmacyId: pharmacyId,
              familyAccountId: id !== "parent" ? toInteger(id) : undefined,
              requestType: isLiffLogined
                ? MedicineNotebookAutoLinkCreateRequestTypeEnum.Line
                : MedicineNotebookAutoLinkCreateRequestTypeEnum.Web,
            },
          })
        )
      ),
    },
    meta: {
      requestKey: toString(pharmacyId),
      ...meta,
    },
  })
);

export const searchMedicineNotebookAutoLinks = createRequestAction<
  MedicineNotebookAutoLinkSearchParams,
  MedicineNotebookAutoLinkConnection
>("requests/medicineNotebookAutoLink/searchMedicineNotebookAutoLinks", (params, meta) => ({
  request: {
    promise: medicineNotebookAutoLinkApi.getMedicineNotebookAutoLinks(params),
  },
  meta,
}));
