/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { PatientMedicine, PatientMedicineFromJSON, PatientMedicineFromJSONTyped, PatientMedicineToJSON } from "./";

export const PatientUsageTypename = "PatientUsage" as const;

/**
 *
 * @export
 * @interface PatientUsage
 */
export interface PatientUsage {
  /**
   *
   * @type {Array<PatientMedicine>}
   * @memberof PatientUsage
   */
  medicines?: Array<PatientMedicine>;
  /**
   *
   * @type {number}
   * @memberof PatientUsage
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof PatientUsage
   */
  takingTime?: string;
  /**
   *
   * @type {string}
   * @memberof PatientUsage
   */
  takingType?: PatientUsageTakingTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PatientUsage
   */
  unit?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PatientUsage
   */
  usageComments?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientUsage
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientUsageTakingTypeEnum {
  Internal = "internal",
  External = "external",
  AsNeeded = "as-needed",
}

export function PatientUsageFromJSON(json: any): PatientUsage {
  return PatientUsageFromJSONTyped(json, false);
}

export function PatientUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientUsage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientUsageTypename,

    medicines: !exists(json, "medicines") ? undefined : (json["medicines"] as Array<any>).map(PatientMedicineFromJSON),
    quantity: !exists(json, "quantity") ? undefined : json["quantity"],
    takingTime: !exists(json, "taking_time") ? undefined : json["taking_time"],
    takingType: !exists(json, "taking_type") ? undefined : json["taking_type"],
    unit: !exists(json, "unit") ? undefined : json["unit"],
    usageComments: !exists(json, "usage_comments") ? undefined : json["usage_comments"],
  };
}

export function PatientUsageToJSON(object?: PatientUsage | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    medicines: value.medicines === undefined ? undefined : (value.medicines as Array<any>).map(PatientMedicineToJSON),
    quantity: value.quantity,
    taking_time: value.takingTime,
    taking_type: value.takingType,
    unit: value.unit,
    usage_comments: value.usageComments,
  };
}
