/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientServiceNeedsTypename = "PatientServiceNeeds" as const;

/**
 *
 * @export
 * @interface PatientServiceNeeds
 */
export interface PatientServiceNeeds {
  /**
   *
   * @type {string}
   * @memberof PatientServiceNeeds
   */
  communication?: PatientServiceNeedsCommunicationEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientServiceNeeds
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientServiceNeedsCommunicationEnum {
  Normal = "normal",
  Careful = "careful",
}

export function PatientServiceNeedsFromJSON(json: any): PatientServiceNeeds {
  return PatientServiceNeedsFromJSONTyped(json, false);
}

export function PatientServiceNeedsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientServiceNeeds {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientServiceNeedsTypename,

    communication: !exists(json, "communication") ? undefined : json["communication"],
  };
}

export function PatientServiceNeedsToJSON(object?: PatientServiceNeeds | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    communication: value.communication,
  };
}
