import clsx from "clsx";
import React, { PropsWithChildren, useMemo } from "react";

import * as styles from "./Title.module.css";

export const variants = [
  "weak",
  "strong",
  "strong_left",
  "basic_left",
  "basic_center",
  "normal",
  "xm_left_tertiary",
] as const;

export type Props = PropsWithChildren<{
  className?: string;
  variant: (typeof variants)[number];
  sub?: boolean;
  small?: boolean;
  noPadding?: boolean;
  noBold?: boolean;
}>;

const Title = ({ children, variant, className, sub, small, noPadding, noBold, ...props }: Props) => {
  const HTag = useMemo(() => {
    if (noBold) {
      return "div";
    }

    if (sub) {
      return "h4";
    }

    if (small) {
      return "h5";
    }

    return "h3";
  }, [noBold, small, sub]);

  return (
    <div
      className={clsx(
        styles.root,
        variant === "weak" && styles.weak,
        variant === "strong" && styles.strong,
        variant === "strong_left" && styles.strong_left,
        variant === "basic_left" && styles.basic_left,
        variant === "basic_center" && styles.basic_center,
        variant === "normal" && styles.normal,
        variant === "xm_left_tertiary" && styles.xm_left_tertiary,
        sub && styles.sub,
        small && styles.small,
        noPadding && styles.noPadding,
        className
      )}
    >
      <HTag className={clsx(styles.title, noBold && styles.noBold)} data-testid={props["data-testid"]}>
        {children}
      </HTag>
      <div className={styles.line} />
    </div>
  );
};

export default Title;
