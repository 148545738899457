/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicTemporaryClosedDate,
  ClinicTemporaryClosedDateFromJSON,
  ClinicTemporaryClosedDateFromJSONTyped,
  ClinicTemporaryClosedDateToJSON,
} from "./";

export const PutTemporaryClosedDatesRequestBodyTypename = "PutTemporaryClosedDatesRequestBody" as const;

/**
 *
 * @export
 * @interface PutTemporaryClosedDatesRequestBody
 */
export interface PutTemporaryClosedDatesRequestBody {
  /**
   *
   * @type {Array<ClinicTemporaryClosedDate>}
   * @memberof PutTemporaryClosedDatesRequestBody
   */
  temporaryClosedDates: Array<ClinicTemporaryClosedDate>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutTemporaryClosedDatesRequestBody
   */
  readonly __typename?: string;
}

export function PutTemporaryClosedDatesRequestBodyFromJSON(json: any): PutTemporaryClosedDatesRequestBody {
  return PutTemporaryClosedDatesRequestBodyFromJSONTyped(json, false);
}

export function PutTemporaryClosedDatesRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutTemporaryClosedDatesRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutTemporaryClosedDatesRequestBodyTypename,

    temporaryClosedDates: (json["temporary_closed_dates"] as Array<any>).map(ClinicTemporaryClosedDateFromJSON),
  };
}

export function PutTemporaryClosedDatesRequestBodyToJSON(object?: PutTemporaryClosedDatesRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    temporary_closed_dates: (value.temporaryClosedDates as Array<any>).map(ClinicTemporaryClosedDateToJSON),
  };
}
