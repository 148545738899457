/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyControlPanelProxyTypename = "PharmacyControlPanelProxy" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanelProxy
 */
export interface PharmacyControlPanelProxy {
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanelProxy
   */
  address?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyControlPanelProxy
   */
  setting?: PharmacyControlPanelProxySettingEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanelProxy
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyControlPanelProxySettingEnum {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
}

export function PharmacyControlPanelProxyFromJSON(json: any): PharmacyControlPanelProxy {
  return PharmacyControlPanelProxyFromJSONTyped(json, false);
}

export function PharmacyControlPanelProxyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyControlPanelProxy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelProxyTypename,

    address: !exists(json, "address") ? undefined : json["address"],
    setting: !exists(json, "setting") ? undefined : json["setting"],
  };
}

export function PharmacyControlPanelProxyToJSON(object?: PharmacyControlPanelProxy | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address: value.address,
    setting: value.setting,
  };
}
