/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorCounselingReportDispensingTypename =
  "PharmacyCounselorCounselingReportDispensing" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReportDispensing
 */
export interface PharmacyCounselorCounselingReportDispensing {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportDispensing
   */
  completedTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportDispensing
   */
  dispensedTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportDispensing
   */
  notifiedTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportDispensing
   */
  registeredTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportDispensing
   */
  status?: PharmacyCounselorCounselingReportDispensingStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportDispensing
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportDispensingStatusEnum {
  Initialized = "initialized",
  Dispensed = "dispensed",
  Notified = "notified",
  Completed = "completed",
}

export function PharmacyCounselorCounselingReportDispensingFromJSON(
  json: any
): PharmacyCounselorCounselingReportDispensing {
  return PharmacyCounselorCounselingReportDispensingFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportDispensingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReportDispensing {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportDispensingTypename,

    completedTime: !exists(json, "completed_time") ? undefined : json["completed_time"],
    dispensedTime: !exists(json, "dispensed_time") ? undefined : json["dispensed_time"],
    notifiedTime: !exists(json, "notified_time") ? undefined : json["notified_time"],
    registeredTime: !exists(json, "registered_time") ? undefined : json["registered_time"],
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function PharmacyCounselorCounselingReportDispensingToJSON(
  object?: PharmacyCounselorCounselingReportDispensing | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    completed_time: value.completedTime,
    dispensed_time: value.dispensedTime,
    notified_time: value.notifiedTime,
    registered_time: value.registeredTime,
    status: value.status,
  };
}
