/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorFamilyPharmacistQuestionnaireTypename =
  "PharmacyCounselorFamilyPharmacistQuestionnaire" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorFamilyPharmacistQuestionnaire
 */
export interface PharmacyCounselorFamilyPharmacistQuestionnaire {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorFamilyPharmacistQuestionnaire
   */
  answer: PharmacyCounselorFamilyPharmacistQuestionnaireAnswerEnum;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorFamilyPharmacistQuestionnaire
   */
  answeredTime: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorFamilyPharmacistQuestionnaire
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorFamilyPharmacistQuestionnaireAnswerEnum {
  WantingToHear = "wanting_to_hear",
  ActivelyLookingFor = "actively_looking_for",
  AlreadyRegistered = "already_registered",
  Uninteresting = "uninteresting",
}

export function PharmacyCounselorFamilyPharmacistQuestionnaireFromJSON(
  json: any
): PharmacyCounselorFamilyPharmacistQuestionnaire {
  return PharmacyCounselorFamilyPharmacistQuestionnaireFromJSONTyped(json, false);
}

export function PharmacyCounselorFamilyPharmacistQuestionnaireFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorFamilyPharmacistQuestionnaire {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorFamilyPharmacistQuestionnaireTypename,

    answer: json["answer"],
    answeredTime: json["answered_time"],
  };
}

export function PharmacyCounselorFamilyPharmacistQuestionnaireToJSON(
  object?: PharmacyCounselorFamilyPharmacistQuestionnaire | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer: value.answer,
    answered_time: moment(value.answeredTime).tz("Asia/Tokyo").format(),
  };
}
