/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientMissionRegistrableTypename = "PatientMissionRegistrable" as const;

/**
 *
 * @export
 * @interface PatientMissionRegistrable
 */
export interface PatientMissionRegistrable {
  /**
   *
   * @type {number}
   * @memberof PatientMissionRegistrable
   */
  chainId: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMissionRegistrable
   */
  readonly __typename?: string;
}

export function PatientMissionRegistrableFromJSON(json: any): PatientMissionRegistrable {
  return PatientMissionRegistrableFromJSONTyped(json, false);
}

export function PatientMissionRegistrableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMissionRegistrable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMissionRegistrableTypename,

    chainId: json["chain_id"],
  };
}

export function PatientMissionRegistrableToJSON(object?: PatientMissionRegistrable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
  };
}
