import { createFirestoreId, getFirebaseFirestore } from "@mgdx-libs/firebase";
import moment from "@mgdx-libs/moment";
import { arrayUnion, doc, increment, writeBatch } from "firebase/firestore";
import { useCallback } from "react";

import { MESSAGE_SUB_COLLECTION_NAME, ROOM_COLLECTION_NAME } from "../constants";
import {
  messageConverter,
  roomConverter,
  SenderTypeEnum,
  TalkMessageDocument,
  TalkMessageDocumentBody,
  TalkRoomDocument,
} from "../models";

export type TalkSendMessage = (body: TalkMessageDocumentBody) => Promise<void>;

export type UseTalkSendMessageProps = {
  roomId: string;
  talkSenderType: SenderTypeEnum;
};

export type UseTalkSendMessage = (props: UseTalkSendMessageProps) => TalkSendMessage;

export const useTalkSendMessage: UseTalkSendMessage = ({ roomId, talkSenderType }) => {
  return useCallback<TalkSendMessage>(
    async (body) => {
      const firebaseFirestore = getFirebaseFirestore();

      if (!firebaseFirestore) return;

      const now = moment();
      const timestamp = now.unix();
      const sentAt = now.format();
      const id = createFirestoreId();

      const batch = writeBatch(firebaseFirestore);
      const roomRef = doc(firebaseFirestore, ROOM_COLLECTION_NAME, roomId).withConverter(roomConverter);
      const messagesRef = doc(
        firebaseFirestore,
        ROOM_COLLECTION_NAME,
        roomId,
        MESSAGE_SUB_COLLECTION_NAME,
        id
      ).withConverter(messageConverter);

      const talkMessage: TalkMessageDocument = {
        senderType: talkSenderType,
        sentAt,
        timestamp,
        body,
      };

      const talkRoom: Partial<TalkRoomDocument> = {
        lastMessage: talkMessage,
        updatedAt: sentAt,
        timestamp,
      };

      if (talkSenderType === SenderTypeEnum.Patient) {
        talkRoom.medicalUnreadMessageIds = arrayUnion(
          messagesRef.id
        ) as unknown as TalkRoomDocument["medicalUnreadMessageIds"];

        talkRoom.medicalUnreadMessageCount = increment(1) as unknown as TalkRoomDocument["medicalUnreadMessageCount"];
      } else {
        talkRoom.patientUnreadMessageIds = arrayUnion(
          messagesRef.id
        ) as unknown as TalkRoomDocument["patientUnreadMessageIds"];
        talkRoom.patientUnreadMessageCount = increment(1) as unknown as TalkRoomDocument["patientUnreadMessageCount"];
      }

      batch.set<TalkMessageDocument>(messagesRef, talkMessage);
      batch.update(roomRef, talkRoom);

      await batch.commit();
    },
    [roomId, talkSenderType]
  );
};
