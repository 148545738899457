import { getAccessToken } from "@mgdx-libs/firebase";

import { ClinicApi, Configuration } from "./clinic/clinic";
import { createFetchTimeout } from "./createFetchTimeout";
import requestHeaders from "./requestHeaders";

const configuration = new Configuration({
  basePath: process.env.MGDX_API_CLINIC_URL,
  credentials: "include",
  accessToken: getAccessToken,
  headers: requestHeaders,
  fetchApi: createFetchTimeout(60000),
});

export const clinicApi = new ClinicApi(configuration);

export default clinicApi;
