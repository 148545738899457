/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorCounselingReportBilling,
  PharmacyCounselorCounselingReportBillingFromJSON,
  PharmacyCounselorCounselingReportBillingFromJSONTyped,
  PharmacyCounselorCounselingReportBillingToJSON,
  PharmacyCounselorCounselingReportDispensing,
  PharmacyCounselorCounselingReportDispensingFromJSON,
  PharmacyCounselorCounselingReportDispensingFromJSONTyped,
  PharmacyCounselorCounselingReportDispensingToJSON,
  PharmacyCounselorCounselingReportFollowUp,
  PharmacyCounselorCounselingReportFollowUpFromJSON,
  PharmacyCounselorCounselingReportFollowUpFromJSONTyped,
  PharmacyCounselorCounselingReportFollowUpToJSON,
  PharmacyCounselorCounselingReportMedicationReconciliationForm,
  PharmacyCounselorCounselingReportMedicationReconciliationFormFromJSON,
  PharmacyCounselorCounselingReportMedicationReconciliationFormFromJSONTyped,
  PharmacyCounselorCounselingReportMedicationReconciliationFormToJSON,
  PharmacyCounselorCounselingReportOrder,
  PharmacyCounselorCounselingReportOrderFromJSON,
  PharmacyCounselorCounselingReportOrderFromJSONTyped,
  PharmacyCounselorCounselingReportOrderToJSON,
  PharmacyCounselorCounselingReportPatient,
  PharmacyCounselorCounselingReportPatientFromJSON,
  PharmacyCounselorCounselingReportPatientFromJSONTyped,
  PharmacyCounselorCounselingReportPatientToJSON,
} from "./";

export const PharmacyCounselorCounselingReportTypename = "PharmacyCounselorCounselingReport" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReport
 */
export interface PharmacyCounselorCounselingReport {
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReport
   */
  acceptedTime?: string;
  /**
   *
   * @type {PharmacyCounselorCounselingReportBilling}
   * @memberof PharmacyCounselorCounselingReport
   */
  billing?: PharmacyCounselorCounselingReportBilling;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReport
   */
  canceledTime?: string;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReport
   */
  completedTime?: string;
  /**
   *
   * @type {PharmacyCounselorCounselingReportDispensing}
   * @memberof PharmacyCounselorCounselingReport
   */
  dispensing?: PharmacyCounselorCounselingReportDispensing;
  /**
   *
   * @type {PharmacyCounselorCounselingReportFollowUp}
   * @memberof PharmacyCounselorCounselingReport
   */
  followUp?: PharmacyCounselorCounselingReportFollowUp;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  hasMedicineNotebook?: PharmacyCounselorCounselingReportHasMedicineNotebookEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  managementNo: string;
  /**
   *
   * @type {PharmacyCounselorCounselingReportMedicationReconciliationForm}
   * @memberof PharmacyCounselorCounselingReport
   */
  medicationReconciliationForm?: PharmacyCounselorCounselingReportMedicationReconciliationForm;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  messageToPatient?: string;
  /**
   *
   * @type {PharmacyCounselorCounselingReportOrder}
   * @memberof PharmacyCounselorCounselingReport
   */
  order?: PharmacyCounselorCounselingReportOrder;
  /**
   *
   * @type {PharmacyCounselorCounselingReportPatient}
   * @memberof PharmacyCounselorCounselingReport
   */
  patient: PharmacyCounselorCounselingReportPatient;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReport
   */
  pharmacistId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  pharmacyExternalId?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  pharmacyName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyCounselorCounselingReport
   */
  preferredTimes?: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReport
   */
  reAcceptedTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  requestType?: PharmacyCounselorCounselingReportRequestTypeEnum;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReport
   */
  requestedTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  status: PharmacyCounselorCounselingReportStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  statusDetail?: string;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReport
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  type: PharmacyCounselorCounselingReportTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  wantsGeneric?: PharmacyCounselorCounselingReportWantsGenericEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReport
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportHasMedicineNotebookEnum {
  None = "none",
  Paper = "paper",
  Digital = "digital",
  Yqb = "yqb",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportRequestTypeEnum {
  Web = "web",
  Line = "line",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportStatusEnum {
  Requested = "requested",
  Accepted = "accepted",
  Started = "started",
  Completed = "completed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  FollowUpOnly = "follow_up_only",
  DeliveryOnly = "delivery_only",
  DirectVisit = "direct_visit",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportWantsGenericEnum {
  None = "none",
  Want = "want",
  Consultation = "consultation",
}

export function PharmacyCounselorCounselingReportFromJSON(json: any): PharmacyCounselorCounselingReport {
  return PharmacyCounselorCounselingReportFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportTypename,

    acceptedTime:
      !exists(json, "accepted_time") ||
      (typeof json["accepted_time"] === "string" && json["accepted_time"].substring(0, 4) === "0001")
        ? undefined
        : json["accepted_time"],
    billing: !exists(json, "billing") ? undefined : PharmacyCounselorCounselingReportBillingFromJSON(json["billing"]),
    canceledTime:
      !exists(json, "canceled_time") ||
      (typeof json["canceled_time"] === "string" && json["canceled_time"].substring(0, 4) === "0001")
        ? undefined
        : json["canceled_time"],
    completedTime:
      !exists(json, "completed_time") ||
      (typeof json["completed_time"] === "string" && json["completed_time"].substring(0, 4) === "0001")
        ? undefined
        : json["completed_time"],
    dispensing: !exists(json, "dispensing")
      ? undefined
      : PharmacyCounselorCounselingReportDispensingFromJSON(json["dispensing"]),
    followUp: !exists(json, "follow_up")
      ? undefined
      : PharmacyCounselorCounselingReportFollowUpFromJSON(json["follow_up"]),
    hasMedicineNotebook: !exists(json, "has_medicine_notebook") ? undefined : json["has_medicine_notebook"],
    managementNo: json["management_no"],
    medicationReconciliationForm: !exists(json, "medication_reconciliation_form")
      ? undefined
      : PharmacyCounselorCounselingReportMedicationReconciliationFormFromJSON(json["medication_reconciliation_form"]),
    message: !exists(json, "message") ? undefined : json["message"],
    messageToPatient: !exists(json, "message_to_patient") ? undefined : json["message_to_patient"],
    order: !exists(json, "order") ? undefined : PharmacyCounselorCounselingReportOrderFromJSON(json["order"]),
    patient: PharmacyCounselorCounselingReportPatientFromJSON(json["patient"]),
    pharmacistId: !exists(json, "pharmacist_id") ? undefined : json["pharmacist_id"],
    pharmacyExternalId: !exists(json, "pharmacy_external_id") ? undefined : json["pharmacy_external_id"],
    pharmacyName: json["pharmacy_name"],
    preferredTimes: !exists(json, "preferred_times") ? undefined : json["preferred_times"],
    reAcceptedTime:
      !exists(json, "re_accepted_time") ||
      (typeof json["re_accepted_time"] === "string" && json["re_accepted_time"].substring(0, 4) === "0001")
        ? undefined
        : json["re_accepted_time"],
    requestType: !exists(json, "request_type") ? undefined : json["request_type"],
    requestedTime:
      !exists(json, "requested_time") ||
      (typeof json["requested_time"] === "string" && json["requested_time"].substring(0, 4) === "0001")
        ? undefined
        : json["requested_time"],
    status: json["status"],
    statusDetail: !exists(json, "status_detail") ? undefined : json["status_detail"],
    time:
      !exists(json, "time") || (typeof json["time"] === "string" && json["time"].substring(0, 4) === "0001")
        ? undefined
        : json["time"],
    type: json["type"],
    wantsGeneric: !exists(json, "wants_generic") ? undefined : json["wants_generic"],
  };
}

export function PharmacyCounselorCounselingReportToJSON(object?: PharmacyCounselorCounselingReport | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    accepted_time: value.acceptedTime === undefined ? undefined : moment(value.acceptedTime).tz("Asia/Tokyo").format(),
    billing: PharmacyCounselorCounselingReportBillingToJSON(value.billing),
    canceled_time: value.canceledTime === undefined ? undefined : moment(value.canceledTime).tz("Asia/Tokyo").format(),
    completed_time:
      value.completedTime === undefined ? undefined : moment(value.completedTime).tz("Asia/Tokyo").format(),
    dispensing: PharmacyCounselorCounselingReportDispensingToJSON(value.dispensing),
    follow_up: PharmacyCounselorCounselingReportFollowUpToJSON(value.followUp),
    has_medicine_notebook: value.hasMedicineNotebook,
    management_no: value.managementNo,
    medication_reconciliation_form: PharmacyCounselorCounselingReportMedicationReconciliationFormToJSON(
      value.medicationReconciliationForm
    ),
    message: value.message,
    message_to_patient: value.messageToPatient,
    order: PharmacyCounselorCounselingReportOrderToJSON(value.order),
    patient: PharmacyCounselorCounselingReportPatientToJSON(value.patient),
    pharmacist_id: value.pharmacistId,
    pharmacy_external_id: value.pharmacyExternalId,
    pharmacy_name: value.pharmacyName,
    preferred_times: value.preferredTimes,
    re_accepted_time:
      value.reAcceptedTime === undefined ? undefined : moment(value.reAcceptedTime).tz("Asia/Tokyo").format(),
    request_type: value.requestType,
    requested_time:
      value.requestedTime === undefined ? undefined : moment(value.requestedTime).tz("Asia/Tokyo").format(),
    status: value.status,
    status_detail: value.statusDetail,
    time: value.time === undefined ? undefined : moment(value.time).tz("Asia/Tokyo").format(),
    type: value.type,
    wants_generic: value.wantsGeneric,
  };
}
