/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorAuthorizedAcceptCodeTypename = "PharmacyCounselorAuthorizedAcceptCode" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorAuthorizedAcceptCode
 */
export interface PharmacyCounselorAuthorizedAcceptCode {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorAuthorizedAcceptCode
   */
  acceptCode?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorAuthorizedAcceptCode
   */
  counselingId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorAuthorizedAcceptCode
   */
  dispensingStatus?: PharmacyCounselorAuthorizedAcceptCodeDispensingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorAuthorizedAcceptCode
   */
  dispensingType?: PharmacyCounselorAuthorizedAcceptCodeDispensingTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorAuthorizedAcceptCode
   */
  pharmacyId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorAuthorizedAcceptCode
   */
  tel?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorAuthorizedAcceptCode
   */
  type: PharmacyCounselorAuthorizedAcceptCodeTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorAuthorizedAcceptCode
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorAuthorizedAcceptCodeDispensingStatusEnum {
  Initialized = "initialized",
  Dispensed = "dispensed",
  Notified = "notified",
  Completed = "completed",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorAuthorizedAcceptCodeDispensingTypeEnum {
  Normal = "normal",
  Quick = "quick",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorAuthorizedAcceptCodeTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  DirectVisit = "direct_visit",
}

export function PharmacyCounselorAuthorizedAcceptCodeFromJSON(json: any): PharmacyCounselorAuthorizedAcceptCode {
  return PharmacyCounselorAuthorizedAcceptCodeFromJSONTyped(json, false);
}

export function PharmacyCounselorAuthorizedAcceptCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorAuthorizedAcceptCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorAuthorizedAcceptCodeTypename,

    acceptCode: !exists(json, "accept_code") ? undefined : json["accept_code"],
    counselingId: !exists(json, "counseling_id") ? undefined : json["counseling_id"],
    dispensingStatus: !exists(json, "dispensing_status") ? undefined : json["dispensing_status"],
    dispensingType: !exists(json, "dispensing_type") ? undefined : json["dispensing_type"],
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
    type: json["type"],
  };
}

export function PharmacyCounselorAuthorizedAcceptCodeToJSON(
  object?: PharmacyCounselorAuthorizedAcceptCode | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    accept_code: value.acceptCode,
    counseling_id: value.counselingId,
    dispensing_status: value.dispensingStatus,
    dispensing_type: value.dispensingType,
    pharmacy_id: value.pharmacyId,
    tel: value.tel,
    type: value.type,
  };
}
