/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientOTCMedicineUpdatableTypename = "PatientOTCMedicineUpdatable" as const;

/**
 *
 * @export
 * @interface PatientOTCMedicineUpdatable
 */
export interface PatientOTCMedicineUpdatable {
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineUpdatable
   */
  finishedOn?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineUpdatable
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineUpdatable
   */
  startedOn?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientOTCMedicineUpdatable
   */
  readonly __typename?: string;
}

export function PatientOTCMedicineUpdatableFromJSON(json: any): PatientOTCMedicineUpdatable {
  return PatientOTCMedicineUpdatableFromJSONTyped(json, false);
}

export function PatientOTCMedicineUpdatableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientOTCMedicineUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientOTCMedicineUpdatableTypename,

    finishedOn: !exists(json, "finished_on") ? undefined : json["finished_on"],
    name: !exists(json, "name") ? undefined : json["name"],
    startedOn: !exists(json, "started_on") ? undefined : json["started_on"],
  };
}

export function PatientOTCMedicineUpdatableToJSON(object?: PatientOTCMedicineUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    finished_on: value.finishedOn,
    name: value.name,
    started_on: value.startedOn,
  };
}
