/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientAllergy,
  PatientAllergyFromJSON,
  PatientAllergyFromJSONTyped,
  PatientAllergyToJSON,
  PatientMedicalHistory,
  PatientMedicalHistoryFromJSON,
  PatientMedicalHistoryFromJSONTyped,
  PatientMedicalHistoryToJSON,
  PatientSideEffect,
  PatientSideEffectFromJSON,
  PatientSideEffectFromJSONTyped,
  PatientSideEffectToJSON,
  PatientVaccine,
  PatientVaccineFromJSON,
  PatientVaccineFromJSONTyped,
  PatientVaccineToJSON,
} from "./";

export const PatientUpdatableMedicationReconciliationFormTypename =
  "PatientUpdatableMedicationReconciliationForm" as const;

/**
 *
 * @export
 * @interface PatientUpdatableMedicationReconciliationForm
 */
export interface PatientUpdatableMedicationReconciliationForm {
  /**
   *
   * @type {Array<PatientAllergy>}
   * @memberof PatientUpdatableMedicationReconciliationForm
   */
  allergies?: Array<PatientAllergy>;
  /**
   *
   * @type {Array<PatientMedicalHistory>}
   * @memberof PatientUpdatableMedicationReconciliationForm
   */
  medicalHistories?: Array<PatientMedicalHistory>;
  /**
   *
   * @type {Array<PatientSideEffect>}
   * @memberof PatientUpdatableMedicationReconciliationForm
   */
  sideEffects?: Array<PatientSideEffect>;
  /**
   *
   * @type {Array<PatientVaccine>}
   * @memberof PatientUpdatableMedicationReconciliationForm
   */
  vaccines?: Array<PatientVaccine>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientUpdatableMedicationReconciliationForm
   */
  readonly __typename?: string;
}

export function PatientUpdatableMedicationReconciliationFormFromJSON(
  json: any
): PatientUpdatableMedicationReconciliationForm {
  return PatientUpdatableMedicationReconciliationFormFromJSONTyped(json, false);
}

export function PatientUpdatableMedicationReconciliationFormFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientUpdatableMedicationReconciliationForm {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientUpdatableMedicationReconciliationFormTypename,

    allergies: !exists(json, "allergies") ? undefined : (json["allergies"] as Array<any>).map(PatientAllergyFromJSON),
    medicalHistories: !exists(json, "medical_histories")
      ? undefined
      : (json["medical_histories"] as Array<any>).map(PatientMedicalHistoryFromJSON),
    sideEffects: !exists(json, "side_effects")
      ? undefined
      : (json["side_effects"] as Array<any>).map(PatientSideEffectFromJSON),
    vaccines: !exists(json, "vaccines") ? undefined : (json["vaccines"] as Array<any>).map(PatientVaccineFromJSON),
  };
}

export function PatientUpdatableMedicationReconciliationFormToJSON(
  object?: PatientUpdatableMedicationReconciliationForm | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    allergies: value.allergies === undefined ? undefined : (value.allergies as Array<any>).map(PatientAllergyToJSON),
    medical_histories:
      value.medicalHistories === undefined
        ? undefined
        : (value.medicalHistories as Array<any>).map(PatientMedicalHistoryToJSON),
    side_effects:
      value.sideEffects === undefined ? undefined : (value.sideEffects as Array<any>).map(PatientSideEffectToJSON),
    vaccines: value.vaccines === undefined ? undefined : (value.vaccines as Array<any>).map(PatientVaccineToJSON),
  };
}
