/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ChatterVideoChatPatientTypename = "ChatterVideoChatPatient" as const;

/**
 *
 * @export
 * @interface ChatterVideoChatPatient
 */
export interface ChatterVideoChatPatient {
  /**
   *
   * @type {number}
   * @memberof ChatterVideoChatPatient
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChatterVideoChatPatient
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ChatterVideoChatPatient
   */
  tel: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterVideoChatPatient
   */
  readonly __typename?: string;
}

export function ChatterVideoChatPatientFromJSON(json: any): ChatterVideoChatPatient {
  return ChatterVideoChatPatientFromJSONTyped(json, false);
}

export function ChatterVideoChatPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatterVideoChatPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterVideoChatPatientTypename,

    id: !exists(json, "id") ? undefined : json["id"],
    name: json["name"],
    tel: json["tel"],
  };
}

export function ChatterVideoChatPatientToJSON(object?: ChatterVideoChatPatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    name: value.name,
    tel: value.tel,
  };
}
