import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const CameraIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M108,237 L104.83,237 L103.59,235.65 C103.22,235.24 102.68,235 102.12,235 L97.88,235 C97.32,235 96.78,235.24 96.4,235.65 L95.17,237 L92,237 C90.9,237 90,237.9 90,239 L90,251 C90,252.1 90.9,253 92,253 L108,253 C109.1,253 110,252.1 110,251 L110,239 C110,237.9 109.1,237 108,237 Z M100,240 C102.76,240 105,242.24 105,245 C105,247.76 102.76,250 100,250 C97.24,250 95,247.76 95,245 C95,242.24 97.24,240 100,240 Z M100,242 C98.3431458,242 97,243.343146 97,245 C97,246.656854 98.3431458,248 100,248 C101.656854,248 103,246.656854 103,245 C103,243.343146 101.656854,242 100,242 Z"
        transform="translate(-88 -232)"
      />
    </svg>
  );
};

export default CameraIcon;
