import moment, { Moment } from "@mgdx-libs/moment";
import isEmpty from "lodash/isEmpty";

export type ParseDate = (dateText?: string | Moment | null | undefined) => Moment | null;

function isISOFormat(dateString: string) {
  const isoFormatPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(([+-]\d{2}:\d{2})|Z)?$/;
  return isoFormatPattern.test(dateString);
}

function isDateFormat(dateString: string) {
  const dateFormatPattern = /^\d{4}-\d{2}-\d{2}$/;
  return dateFormatPattern.test(dateString);
}

const parseDate: ParseDate = (dateText) => {
  if (isEmpty(dateText) || dateText === null) return null;
  if (typeof dateText !== "string" && typeof dateText !== "object") return null;

  if (typeof dateText === "string") {
    var date: Moment;
    if (isISOFormat(dateText)) {
      date = moment(dateText);
    } else if (isDateFormat(dateText)) {
      date = moment(`${dateText}T00:00:00+09:00`);
    } else {
      return moment(dateText);
    }
    if (!date?.isValid()) return null;
    return date;
  }

  return dateText.clone();
};

export default parseDate;
