/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorMedicineNotebookAccountTypename = "PharmacyCounselorMedicineNotebookAccount" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorMedicineNotebookAccount
 */
export interface PharmacyCounselorMedicineNotebookAccount {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAccount
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAccount
   */
  gender?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAccount
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAccount
   */
  url?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAccount
   */
  readonly __typename?: string;
}

export function PharmacyCounselorMedicineNotebookAccountFromJSON(json: any): PharmacyCounselorMedicineNotebookAccount {
  return PharmacyCounselorMedicineNotebookAccountFromJSONTyped(json, false);
}

export function PharmacyCounselorMedicineNotebookAccountFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorMedicineNotebookAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorMedicineNotebookAccountTypename,

    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    name: !exists(json, "name") ? undefined : json["name"],
    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function PharmacyCounselorMedicineNotebookAccountToJSON(
  object?: PharmacyCounselorMedicineNotebookAccount | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    gender: value.gender,
    name: value.name,
    url: value.url,
  };
}
