export const responseToErrorMessage = async (response: Response) => {
  try {
    const json = await response.json();
    return JSON.stringify({
      url: response.url,
      status: response.status,
      body: json,
    });
  } catch (e) {
    return `[${response.status}] ${response.url}`;
  }
};
