/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyCountTypename = "PharmacyPharmacyCount" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyCount
 */
export interface PharmacyPharmacyCount {
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyCount
   */
  count: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyCount
   */
  id: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyCount
   */
  readonly __typename?: string;
}

export function PharmacyPharmacyCountFromJSON(json: any): PharmacyPharmacyCount {
  return PharmacyPharmacyCountFromJSONTyped(json, false);
}

export function PharmacyPharmacyCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPharmacyCount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyCountTypename,

    count: json["count"],
    id: json["id"],
  };
}

export function PharmacyPharmacyCountToJSON(object?: PharmacyPharmacyCount | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    id: value.id,
  };
}
