/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorOption,
  PharmacyCounselorOptionFromJSON,
  PharmacyCounselorOptionFromJSONTyped,
  PharmacyCounselorOptionToJSON,
} from "./";

export const PharmacyCounselorQuestionnaireTypename = "PharmacyCounselorQuestionnaire" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorQuestionnaire
 */
export interface PharmacyCounselorQuestionnaire {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorQuestionnaire
   */
  answerPattern?: PharmacyCounselorQuestionnaireAnswerPatternEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorQuestionnaire
   */
  category?: PharmacyCounselorQuestionnaireCategoryEnum;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorQuestionnaire
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorQuestionnaire
   */
  isRequiredAnswer?: boolean;
  /**
   *
   * @type {Array<PharmacyCounselorOption>}
   * @memberof PharmacyCounselorQuestionnaire
   */
  options?: Array<PharmacyCounselorOption>;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorQuestionnaire
   */
  question: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorQuestionnaire
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorQuestionnaireAnswerPatternEnum {
  SingleSelection = "single_selection",
  MultipleSelection = "multiple_selection",
  FreeEntry = "free_entry",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorQuestionnaireCategoryEnum {
  ConcomitantMedication = "concomitant_medication",
  MedicationEffect = "medication_effect",
  MedicationStatus = "medication_status",
  Consultation = "consultation",
  PatientInformation = "patient_information",
  LifeFunctioning = "life_functioning",
  LifeCharacteristic = "life_characteristic",
}

export function PharmacyCounselorQuestionnaireFromJSON(json: any): PharmacyCounselorQuestionnaire {
  return PharmacyCounselorQuestionnaireFromJSONTyped(json, false);
}

export function PharmacyCounselorQuestionnaireFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorQuestionnaire {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorQuestionnaireTypename,

    answerPattern: !exists(json, "answer_pattern") ? undefined : json["answer_pattern"],
    category: !exists(json, "category") ? undefined : json["category"],
    id: json["id"],
    isRequiredAnswer: !exists(json, "is_required_answer") ? undefined : json["is_required_answer"],
    options: !exists(json, "options")
      ? undefined
      : (json["options"] as Array<any>).map(PharmacyCounselorOptionFromJSON),
    question: json["question"],
  };
}

export function PharmacyCounselorQuestionnaireToJSON(object?: PharmacyCounselorQuestionnaire | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer_pattern: value.answerPattern,
    category: value.category,
    id: value.id,
    is_required_answer: value.isRequiredAnswer,
    options: value.options === undefined ? undefined : (value.options as Array<any>).map(PharmacyCounselorOptionToJSON),
    question: value.question,
  };
}
