import { firebaseErrorHandler, firebaseErrorReport } from "@mgdx-libs/error-handler";
import { logger } from "@mgdx-libs/logger";
import * as Sentry from "@sentry/react";
import { signOut } from "firebase/auth";

import { getFirebaseAuth } from "./getFirebaseAuth";

export const signoutFirebase = async (): Promise<void> => {
  const firebaseAuth = getFirebaseAuth();

  await signOut(firebaseAuth).catch(async (error) => {
    firebaseErrorReport(error);
    firebaseErrorHandler(error);
    throw error;
  });

  logger.debug("Signed out Firebase");

  if (process.env.ENABLE_SENTRY === "true") {
    const scope = Sentry.getCurrentScope();
    scope.setUser(null);
  }
};
