import { exists } from "@mgdx/api/lib/runtime";

import {
  FamilyAccountUpserGenderEnum,
  PatientUpdateHasMedicineNotebookEnum,
  PatientUpdateWantsGenericEnum,
} from "./Patient";
import { QolAccountQuestion, QolAccountQuestionFromJSON } from "./QolAccountQuestion";
export const QolAccountTypename = "QolAccount" as const;

/**
 *
 * @export
 * @interface QolAccount
 * @see QolAccount
 */
export interface QolAccount {
  /**
   *
   * @type {string}
   * @memberof QolAccount
   */
  qolCardNum?: string;
  /**
   *
   * @type {string}
   * @memberof QolAccount
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof QolAccount
   */
  kana: string;
  /**
   *
   * @type {string}
   * @memberof QolAccount
   */
  birthday: string;
  /**
   *
   * @type {number}
   * @memberof QolAccount
   */
  sex: number;
  /**
   *
   * @type {boolean}
   * @memberof QolAccount
   */
  isParent: boolean;
  /**
   *
   * @type {Array<QolAccountQuestion>}
   * @memberof QolAccount
   */
  questions: Array<QolAccountQuestion>;
  /**
   *
   * @type {Array<number>}
   * @memberof QolAccount
   */
  favoriteStores: Array<number>;
  /**
   * Resource type name
   * @type {string}
   * @memberof QolAccount
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolAccountFromJSON(json: any): QolAccount {
  return QolAccountFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): QolAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: QolAccountTypename,
    qolCardNum: !exists(json, "qolCardNum") ? undefined : json["qolCardNum"] === null ? undefined : json["qolCardNum"],
    name: json["name"],
    kana: json["kana"],
    birthday: json["birthday"],
    sex: json["sex"],
    isParent: json["isParent"],
    questions: !exists(json, "questions") ? [] : (json["questions"] as Array<any>).map(QolAccountQuestionFromJSON),
    favoriteStores: !exists(json, "favoriteStores") ? [] : json["favoriteStores"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function QolAccountToJSON(object?: QolAccount | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    qolCardNum: value.qolCardNum,
    name: value.name,
    kana: value.kana,
    birthday: value.birthday,
    sex: value.sex,
    isParent: value.isParent,
    questions: value.questions,
    favoriteStores: value.favoriteStores,
  };
}

export type ConvertQolAccount = (
  qolAccount?: QolAccount,
  chainId?: number
) => {
  lastName?: string;
  firstName?: string;
  kanaLastName?: string;
  kanaFirstName?: string;
  dateOfBirth?: string;
  sex?: FamilyAccountUpserGenderEnum;
  externalId?: object;
  wantsGeneric?: PatientUpdateWantsGenericEnum;
  hasMedicineNotebook?: PatientUpdateHasMedicineNotebookEnum;
};

export const convertQolAccount: ConvertQolAccount = (qolAccount, chainId) => {
  if (!qolAccount) return {};

  const [lastName, firstName] = qolAccount.name.split(" ") ?? [];
  const [kanaLastName, kanaFirstName] = qolAccount.kana.split(" ") ?? [];
  const sex =
    qolAccount.sex === 1
      ? FamilyAccountUpserGenderEnum.Male
      : qolAccount.sex === 2
      ? FamilyAccountUpserGenderEnum.Female
      : FamilyAccountUpserGenderEnum.None;

  const externalId = qolAccount.qolCardNum && chainId ? { [chainId]: qolAccount?.qolCardNum } : undefined;
  const wantsGeneric = qolAccount.questions.find((value) => value.questionId === 1)?.answers[0].value;
  const hasMedicineNotebook = qolAccount.questions.find((value) => value.questionId === 2)?.answers[0].value;

  return {
    lastName: lastName,
    firstName: firstName,
    kanaLastName: kanaLastName,
    kanaFirstName: kanaFirstName,
    dateOfBirth: qolAccount.birthday,
    sex: sex,
    externalId: externalId,
    wantsGeneric: (wantsGeneric as PatientUpdateWantsGenericEnum) || undefined,
    hasMedicineNotebook:
      hasMedicineNotebook === "yes"
        ? PatientUpdateHasMedicineNotebookEnum.Yqb
        : hasMedicineNotebook === "no"
        ? PatientUpdateHasMedicineNotebookEnum.None
        : undefined,
  };
};

export const unselectedMedicineNotebook = (qolAccount?: QolAccount): boolean => {
  if (!qolAccount) return false;
  const hasMedicineNotebook = qolAccount.questions.find((value) => value.questionId === 2)?.answers[0].value;

  return hasMedicineNotebook === "no" ?? false;
};
