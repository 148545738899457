// extracted by mini-css-extract-plugin
export var iconKey = "SvgIcon-module--iconKey--de53c";
export var iconLink = "SvgIcon-module--iconLink--3078f";
export var iconPrimary = "SvgIcon-module--iconPrimary--1c8d6";
export var iconSecondary = "SvgIcon-module--iconSecondary--41d08";
export var iconSizeL = "SvgIcon-module--iconSizeL--42219";
export var iconSizeM = "SvgIcon-module--iconSizeM--70287";
export var iconSizeS = "SvgIcon-module--iconSizeS--c2802";
export var iconSizeXM = "SvgIcon-module--iconSizeXM--a44d9";
export var iconSizeXS = "SvgIcon-module--iconSizeXS--1fb8f";
export var iconSizeXXM = "SvgIcon-module--iconSizeXXM--01d92";
export var iconTertiary = "SvgIcon-module--iconTertiary--36807";