// extracted by mini-css-extract-plugin
export var basic_center = "Title-module--basic_center--3581d";
export var basic_left = "Title-module--basic_left--2eef0";
export var line = "Title-module--line--df258";
export var noBold = "Title-module--noBold--a2168";
export var noPadding = "Title-module--noPadding--23307";
export var normal = "Title-module--normal--28935";
export var root = "Title-module--root--72dc1";
export var small = "Title-module--small--cb9d7";
export var strong = "Title-module--strong--8fcde";
export var strong_left = "Title-module--strong_left--fcecf";
export var sub = "Title-module--sub--42020";
export var title = "Title-module--title--a98b2";
export var weak = "Title-module--weak--d5141";
export var xm_left_tertiary = "Title-module--xm_left_tertiary--787ec";