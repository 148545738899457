import ArrowDownwardIcon from "@mgdx/assets/icons/ArrowDownwardIcon";
import { OvalButton } from "@mgdx/ui/components/OvalButton";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import last from "lodash/last";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Scroll, { Element } from "react-scroll";
import usePrevious from "react-use/lib/usePrevious";

import * as styles from "./TalkBox.module.css";
import { TalkContent, TalkContentProps } from "./TalkContent";

const containerId = "talk-box-container";
const hiddenScrollButtonOffset = 24;

export type OnVisibleUnreadMessage = (messageId: string) => void;

export type TalkBoxProps = Omit<TalkContentProps, "containment" | "onDidMount"> & {
  roomId: string;
};

export const TalkBox = ({ roomId, room, messages, talkSenderType, onVisibleUnreadMessage }: TalkBoxProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const prevLastMessage = usePrevious(last(messages));
  const [hiddenScrollButton, setHiddenScrollButton] = useState(true);

  const spyScroll = useCallback(() => {
    if (!containerRef.current) return;

    const { offsetHeight, scrollTop, scrollHeight } = containerRef.current;

    const value =
      scrollTop === 0 && offsetHeight === scrollHeight
        ? true
        : offsetHeight + scrollTop > scrollHeight - hiddenScrollButtonOffset;

    if (hiddenScrollButton === value) return;

    setHiddenScrollButton(value);
  }, [hiddenScrollButton]);

  const animateScrollToBottom = useCallback(() => {
    Scroll.animateScroll.scrollToBottom({
      containerId: containerId,
      duration: 250,
    });
  }, []);

  const scrollToBottom = useCallback(() => {
    Scroll.scroller.scrollTo("bottom", {
      containerId: containerId,
    });
  }, []);

  useEffect(() => {
    if (isEqual(prevLastMessage, last(messages))) return;

    scrollToBottom();
  }, [messages, prevLastMessage, scrollToBottom]);

  useEffect(() => {
    const container = containerRef?.current;

    container?.addEventListener("scroll", spyScroll);

    return () => {
      container?.removeEventListener("scroll", spyScroll);
    };
  }, [spyScroll]);

  return (
    <div key={roomId} className={styles.talkBox}>
      <div ref={containerRef} className={styles.talkInner} id={containerId}>
        <TalkContent
          talkSenderType={talkSenderType}
          onVisibleUnreadMessage={onVisibleUnreadMessage}
          room={room}
          messages={messages}
          containment={containerRef.current}
          onDidMount={() => scrollToBottom()}
        />

        <Element name="bottom" className={styles.bottom} />
      </div>

      <div className={clsx(styles.scrollButton, hiddenScrollButton && styles.hiddenScrollButton)}>
        <OvalButton variant="key-basic" onClick={animateScrollToBottom} aria-label="最新の位置に移動する">
          <ArrowDownwardIcon />
        </OvalButton>
      </div>
    </div>
  );
};
