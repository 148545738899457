/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ChatterAttendees,
  ChatterAttendeesFromJSON,
  ChatterAttendeesFromJSONTyped,
  ChatterAttendeesToJSON,
  ChatterMediaPlacement,
  ChatterMediaPlacementFromJSON,
  ChatterMediaPlacementFromJSONTyped,
  ChatterMediaPlacementToJSON,
} from "./";

export const ChatterVideoChatInformationTypename = "ChatterVideoChatInformation" as const;

/**
 *
 * @export
 * @interface ChatterVideoChatInformation
 */
export interface ChatterVideoChatInformation {
  /**
   *
   * @type {ChatterAttendees}
   * @memberof ChatterVideoChatInformation
   */
  attendees: ChatterAttendees;
  /**
   *
   * @type {ChatterMediaPlacement}
   * @memberof ChatterVideoChatInformation
   */
  mediaPlacement: ChatterMediaPlacement;
  /**
   *
   * @type {string}
   * @memberof ChatterVideoChatInformation
   */
  mediaRegion: string;
  /**
   *
   * @type {string}
   * @memberof ChatterVideoChatInformation
   */
  meetingId: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterVideoChatInformation
   */
  readonly __typename?: string;
}

export function ChatterVideoChatInformationFromJSON(json: any): ChatterVideoChatInformation {
  return ChatterVideoChatInformationFromJSONTyped(json, false);
}

export function ChatterVideoChatInformationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChatterVideoChatInformation {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterVideoChatInformationTypename,

    attendees: ChatterAttendeesFromJSON(json["attendees"]),
    mediaPlacement: ChatterMediaPlacementFromJSON(json["media_placement"]),
    mediaRegion: json["media_region"],
    meetingId: json["meeting_id"],
  };
}

export function ChatterVideoChatInformationToJSON(object?: ChatterVideoChatInformation | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    attendees: ChatterAttendeesToJSON(value.attendees),
    media_placement: ChatterMediaPlacementToJSON(value.mediaPlacement),
    media_region: value.mediaRegion,
    meeting_id: value.meetingId,
  };
}
