import {
  useTalkReadMessage,
  useTalkRoomAndMessageCollection,
  useTalkSendImage,
  useTalkSendMessage,
} from "@mgdx/talk/hooks";
import { SenderTypeEnum } from "@mgdx/talk/models";
import { OnVisibleUnreadMessage } from "@mgdx/talk/ui/TalkBox";
import { TalkFormValues } from "@mgdx/talk/ui/TalkForm";
import { TalkMessageListView } from "@mgdx/talk/views/TalkMessageListView";
import React, { useCallback } from "react";

export type PageProps = { roomId: string };

export type Props = {
  roomId: string;
};

const TalkMessageListContent = ({ roomId }: Props) => {
  const { room, messages, isLoading } = useTalkRoomAndMessageCollection({ roomId });
  const readMessage = useTalkReadMessage({ roomId, talkSenderType: SenderTypeEnum.Patient });
  const sendMessage = useTalkSendMessage({ roomId, talkSenderType: SenderTypeEnum.Patient });
  const sendImage = useTalkSendImage({ roomId, room, talkSenderType: SenderTypeEnum.Patient });

  const handleSubmit = useCallback(
    async (values: TalkFormValues) => {
      if (values.file) {
        await sendImage(values.file);
      } else if (values.message) {
        await sendMessage({
          content: values.message,
        });
      }
    },
    [sendImage, sendMessage]
  );

  const handleVisibleUnreadMessage = useCallback<OnVisibleUnreadMessage>(
    async (messageId) => {
      await readMessage(messageId);
    },
    [readMessage]
  );

  return (
    <TalkMessageListView
      talkSenderType={SenderTypeEnum.Patient}
      roomId={roomId}
      room={room}
      messages={messages}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      onVisibleUnreadMessage={handleVisibleUnreadMessage}
    />
  );
};

export default TalkMessageListContent;
