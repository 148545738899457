/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientDispenseRecordPDFTypename = "PatientDispenseRecordPDF" as const;

/**
 *
 * @export
 * @interface PatientDispenseRecordPDF
 */
export interface PatientDispenseRecordPDF {
  /**
   *
   * @type {string}
   * @memberof PatientDispenseRecordPDF
   */
  url?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientDispenseRecordPDF
   */
  readonly __typename?: string;
}

export function PatientDispenseRecordPDFFromJSON(json: any): PatientDispenseRecordPDF {
  return PatientDispenseRecordPDFFromJSONTyped(json, false);
}

export function PatientDispenseRecordPDFFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientDispenseRecordPDF {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientDispenseRecordPDFTypename,

    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function PatientDispenseRecordPDFToJSON(object?: PatientDispenseRecordPDF | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    url: value.url,
  };
}
