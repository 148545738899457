import { AuthError } from "firebase/auth";

import { FirebaseAuthError } from "../errors/fireErrors";
import { isFirebaseError } from "./isFirebaseError";

export type FireErrorHandler = (error: AuthError | any) => never | void;

export const firebaseErrorHandler: FireErrorHandler = async (error) => {
  if (isFirebaseError(error)) {
    throw new FirebaseAuthError(error);
  }
};
