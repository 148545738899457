/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyFamilyPharmacistImageTypename = "PharmacyFamilyPharmacistImage" as const;

/**
 *
 * @export
 * @interface PharmacyFamilyPharmacistImage
 */
export interface PharmacyFamilyPharmacistImage {
  /**
   *
   * @type {string}
   * @memberof PharmacyFamilyPharmacistImage
   */
  type: PharmacyFamilyPharmacistImageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyFamilyPharmacistImage
   */
  url?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyFamilyPharmacistImage
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyFamilyPharmacistImageTypeEnum {
  Template1 = "template1",
  Template2 = "template2",
  Template3 = "template3",
  Template4 = "template4",
  Specified = "specified",
}

export function PharmacyFamilyPharmacistImageFromJSON(json: any): PharmacyFamilyPharmacistImage {
  return PharmacyFamilyPharmacistImageFromJSONTyped(json, false);
}

export function PharmacyFamilyPharmacistImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyFamilyPharmacistImage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyFamilyPharmacistImageTypename,

    type: json["type"],
    url: !exists(json, "url") ? undefined : json["url"],
  };
}

export function PharmacyFamilyPharmacistImageToJSON(object?: PharmacyFamilyPharmacistImage | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    type: value.type,
    url: value.url,
  };
}
