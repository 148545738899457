/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientMedicineTakenLogTypename = "PatientMedicineTakenLog" as const;

/**
 *
 * @export
 * @interface PatientMedicineTakenLog
 */
export interface PatientMedicineTakenLog {
  /**
   *
   * @type {string}
   * @memberof PatientMedicineTakenLog
   */
  date: string;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineTakenLog
   */
  dispenseRecordId?: number;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineTakenLog
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineTakenLog
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineTakenLog
   */
  otcMedicineId?: number;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineTakenLog
   */
  patientId: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineTakenLog
   */
  status: PatientMedicineTakenLogStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineTakenLog
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMedicineTakenLogStatusEnum {
  Unregistered = "unregistered",
  Taken = "taken",
  Forgotten = "forgotten",
}

export function PatientMedicineTakenLogFromJSON(json: any): PatientMedicineTakenLog {
  return PatientMedicineTakenLogFromJSONTyped(json, false);
}

export function PatientMedicineTakenLogFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMedicineTakenLog {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineTakenLogTypename,

    date: json["date"],
    dispenseRecordId: !exists(json, "dispense_record_id") ? undefined : json["dispense_record_id"],
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    id: json["id"],
    otcMedicineId: !exists(json, "otc_medicine_id") ? undefined : json["otc_medicine_id"],
    patientId: json["patient_id"],
    status: json["status"],
  };
}

export function PatientMedicineTakenLogToJSON(object?: PatientMedicineTakenLog | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date: value.date,
    dispense_record_id: value.dispenseRecordId,
    family_account_id: value.familyAccountId,
    id: value.id,
    otc_medicine_id: value.otcMedicineId,
    patient_id: value.patientId,
    status: value.status,
  };
}
