/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientAllergyTypename = "PatientAllergy" as const;

/**
 *
 * @export
 * @interface PatientAllergy
 */
export interface PatientAllergy {
  /**
   *
   * @type {string}
   * @memberof PatientAllergy
   */
  content?: string;
  /**
   *
   * @type {string}
   * @memberof PatientAllergy
   */
  masterCode: string;
  /**
   *
   * @type {string}
   * @memberof PatientAllergy
   */
  masterGroup?: string;
  /**
   *
   * @type {string}
   * @memberof PatientAllergy
   */
  masterName?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientAllergy
   */
  readonly __typename?: string;
}

export function PatientAllergyFromJSON(json: any): PatientAllergy {
  return PatientAllergyFromJSONTyped(json, false);
}

export function PatientAllergyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientAllergy {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientAllergyTypename,

    content: !exists(json, "content") ? undefined : json["content"],
    masterCode: json["master_code"],
    masterGroup: !exists(json, "master_group") ? undefined : json["master_group"],
    masterName: !exists(json, "master_name") ? undefined : json["master_name"],
  };
}

export function PatientAllergyToJSON(object?: PatientAllergy | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    content: value.content,
    master_code: value.masterCode,
    master_group: value.masterGroup,
    master_name: value.masterName,
  };
}
