import { createFirestoreDataConverter, getFirebaseFirestore } from "@mgdx-libs/firebase";
import { arrayRemove, doc, increment, updateDoc } from "firebase/firestore";
import { useCallback } from "react";

import { ROOM_COLLECTION_NAME } from "../constants";
import { SenderTypeEnum, TalkRoomDocument } from "../models";

export type TalkReadMessage = (messageId: string) => Promise<void>;

export type UseTalkReadMessageProps = {
  roomId: string;
  talkSenderType: SenderTypeEnum;
};

export type UseTalkReadMessage = (props: UseTalkReadMessageProps) => TalkReadMessage;

export const useTalkReadMessage: UseTalkReadMessage = ({ roomId, talkSenderType }) => {
  return useCallback<TalkReadMessage>(
    async (messageId) => {
      const firebaseFirestore = getFirebaseFirestore();

      if (!firebaseFirestore) return;
      const roomRef = doc(firebaseFirestore, ROOM_COLLECTION_NAME, roomId).withConverter(
        createFirestoreDataConverter<TalkRoomDocument>()
      );

      if (talkSenderType === SenderTypeEnum.Patient) {
        await updateDoc(roomRef, {
          patientUnreadMessageIds: arrayRemove(messageId),
          patientUnreadMessageCount: increment(-1),
        });
      } else {
        await updateDoc(roomRef, {
          medicalUnreadMessageIds: arrayRemove(messageId),
          medicalUnreadMessageCount: increment(-1),
        });
      }
    },
    [roomId, talkSenderType]
  );
};
