/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCurrentInformation,
  PharmacyCurrentInformationFromJSON,
  PharmacyCurrentInformationFromJSONTyped,
  PharmacyCurrentInformationToJSON,
} from "./";

export const PharmacyCurrentInformationsTypename = "PharmacyCurrentInformations" as const;

/**
 *
 * @export
 * @interface PharmacyCurrentInformations
 */
export interface PharmacyCurrentInformations {
  /**
   *
   * @type {PharmacyCurrentInformation}
   * @memberof PharmacyCurrentInformations
   */
  counseling?: PharmacyCurrentInformation;
  /**
   *
   * @type {PharmacyCurrentInformation}
   * @memberof PharmacyCurrentInformations
   */
  dispensing?: PharmacyCurrentInformation;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCurrentInformations
   */
  readonly __typename?: string;
}

export function PharmacyCurrentInformationsFromJSON(json: any): PharmacyCurrentInformations {
  return PharmacyCurrentInformationsFromJSONTyped(json, false);
}

export function PharmacyCurrentInformationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCurrentInformations {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCurrentInformationsTypename,

    counseling: !exists(json, "counseling") ? undefined : PharmacyCurrentInformationFromJSON(json["counseling"]),
    dispensing: !exists(json, "dispensing") ? undefined : PharmacyCurrentInformationFromJSON(json["dispensing"]),
  };
}

export function PharmacyCurrentInformationsToJSON(object?: PharmacyCurrentInformations | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling: PharmacyCurrentInformationToJSON(value.counseling),
    dispensing: PharmacyCurrentInformationToJSON(value.dispensing),
  };
}
