/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutMedicineNotebookRequestBodyTypename = "PutMedicineNotebookRequestBody" as const;

/**
 *
 * @export
 * @interface PutMedicineNotebookRequestBody
 */
export interface PutMedicineNotebookRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutMedicineNotebookRequestBody
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof PutMedicineNotebookRequestBody
   */
  gender: PutMedicineNotebookRequestBodyGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PutMedicineNotebookRequestBody
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PutMedicineNotebookRequestBody
   */
  pharmacyId?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutMedicineNotebookRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PutMedicineNotebookRequestBodyGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PutMedicineNotebookRequestBodyFromJSON(json: any): PutMedicineNotebookRequestBody {
  return PutMedicineNotebookRequestBodyFromJSONTyped(json, false);
}

export function PutMedicineNotebookRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutMedicineNotebookRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutMedicineNotebookRequestBodyTypename,

    dateOfBirth: json["date_of_birth"],
    gender: json["gender"],
    name: json["name"],
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
  };
}

export function PutMedicineNotebookRequestBodyToJSON(object?: PutMedicineNotebookRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    gender: value.gender,
    name: value.name,
    pharmacy_id: value.pharmacyId,
  };
}
