/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ClinicClinic,
  ClinicClinicFromJSON,
  ClinicClinicToJSON,
  ClinicClinicRegistrable,
  ClinicClinicRegistrableFromJSON,
  ClinicClinicRegistrableToJSON,
  ClinicClinicUpdatable,
  ClinicClinicUpdatableFromJSON,
  ClinicClinicUpdatableToJSON,
} from "../models";

export interface GetClinicRequest {
  clinicId: number;
}

export interface GetClinicAllowCidrsRequest {
  clinicId: number;
}

export interface GetClinicsRequest {
  isScreening?: boolean;
  visibility?: Array<GetClinicsVisibilityEnum>;
}

export interface PatchClinicRequest {
  clinicId: number;
  clinicClinicUpdatable: ClinicClinicUpdatable;
}

export interface PatchClinicAllowCidrsRequest {
  clinicId: number;
  requestBody: Array<string>;
}

export interface PostClinicRequest {
  clinicClinicRegistrable: Array<ClinicClinicRegistrable>;
}

/**
 *
 */
export class ClinicApi extends runtime.BaseAPI {
  /**
   * Get Clinic
   */
  async getClinicRaw(
    requestParameters: GetClinicRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicClinic>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling getClinic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/clinics/{clinic_id}`.replace(
          `{${"clinic_id"}}`,
          encodeURIComponent(String(requestParameters.clinicId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicClinicFromJSON(jsonValue));
  }

  /**
   * Get Clinic
   */
  async getClinic(requestParameters: GetClinicRequest, initOverrides?: RequestInit): Promise<ClinicClinic> {
    return promiseRetry(
      (retry) =>
        this.getClinicRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get Clinic allow cidrs
   */
  async getClinicAllowCidrsRaw(
    requestParameters: GetClinicAllowCidrsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling getClinicAllowCidrs."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/allow-cidrs`.replace(
          `{${"clinic_id"}}`,
          encodeURIComponent(String(requestParameters.clinicId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Get Clinic allow cidrs
   */
  async getClinicAllowCidrs(
    requestParameters: GetClinicAllowCidrsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<string>> {
    return promiseRetry(
      (retry) =>
        this.getClinicAllowCidrsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get Clinics
   */
  async getClinicsRaw(
    requestParameters: GetClinicsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ClinicClinic>>> {
    const queryParameters: any = {};

    if (requestParameters.isScreening !== undefined) {
      queryParameters["is_screening"] = requestParameters.isScreening;
    }

    if (requestParameters.visibility) {
      queryParameters["visibility"] = requestParameters.visibility;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/clinics`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicClinicFromJSON));
  }

  /**
   * Get Clinics
   */
  async getClinics(requestParameters: GetClinicsRequest, initOverrides?: RequestInit): Promise<Array<ClinicClinic>> {
    return promiseRetry(
      (retry) =>
        this.getClinicsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update Clinic
   */
  async patchClinicRaw(
    requestParameters: PatchClinicRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicClinic>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling patchClinic."
      );
    }

    if (requestParameters.clinicClinicUpdatable === null || requestParameters.clinicClinicUpdatable === undefined) {
      throw new runtime.RequiredError(
        "clinicClinicUpdatable",
        "Required parameter requestParameters.clinicClinicUpdatable was null or undefined when calling patchClinic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}`.replace(
          `{${"clinic_id"}}`,
          encodeURIComponent(String(requestParameters.clinicId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ClinicClinicUpdatableToJSON(requestParameters.clinicClinicUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicClinicFromJSON(jsonValue));
  }

  /**
   * Update Clinic
   */
  async patchClinic(requestParameters: PatchClinicRequest, initOverrides?: RequestInit): Promise<ClinicClinic> {
    return promiseRetry(
      (retry) =>
        this.patchClinicRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update Clinic allow cidrs
   */
  async patchClinicAllowCidrsRaw(
    requestParameters: PatchClinicAllowCidrsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling patchClinicAllowCidrs."
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        "requestBody",
        "Required parameter requestParameters.requestBody was null or undefined when calling patchClinicAllowCidrs."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/allow-cidrs`.replace(
          `{${"clinic_id"}}`,
          encodeURIComponent(String(requestParameters.clinicId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Update Clinic allow cidrs
   */
  async patchClinicAllowCidrs(
    requestParameters: PatchClinicAllowCidrsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<string>> {
    return promiseRetry(
      (retry) =>
        this.patchClinicAllowCidrsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register Clinic
   */
  async postClinicRaw(
    requestParameters: PostClinicRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ClinicClinic>>> {
    if (requestParameters.clinicClinicRegistrable === null || requestParameters.clinicClinicRegistrable === undefined) {
      throw new runtime.RequiredError(
        "clinicClinicRegistrable",
        "Required parameter requestParameters.clinicClinicRegistrable was null or undefined when calling postClinic."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.clinicClinicRegistrable.map(ClinicClinicRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicClinicFromJSON));
  }

  /**
   * Register Clinic
   */
  async postClinic(requestParameters: PostClinicRequest, initOverrides?: RequestInit): Promise<Array<ClinicClinic>> {
    return promiseRetry(
      (retry) =>
        this.postClinicRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetClinicsVisibilityEnum {
  Public = "public",
  Private = "private",
  Limited = "limited",
}
