import { getFirebaseFirestore, useDocument } from "@mgdx-libs/firebase";
import { doc } from "firebase/firestore";
import { useMemo } from "react";

import { ROOM_COLLECTION_NAME } from "../constants";
import { roomConverter, TalkRoomDocumentWithId } from "../models";

export type UseTalkRoomDocumentProps = {
  roomId?: string;
};

export type UseTalkRoomDocument = (props: UseTalkRoomDocumentProps) => {
  room?: TalkRoomDocumentWithId;
  isLoading: boolean;
};

export const useTalkRoomDocument: UseTalkRoomDocument = ({ roomId = "none" }) => {
  const [room, isLoading] = useDocument(
    doc(getFirebaseFirestore(), ROOM_COLLECTION_NAME, roomId).withConverter(roomConverter)
  );

  return useMemo(
    () => ({
      room: room?.data(),
      isLoading,
    }),
    [isLoading, room]
  );
};
