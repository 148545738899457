/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientDispenseRecordImageRequest,
  PatientDispenseRecordImageRequestFromJSON,
  PatientDispenseRecordImageRequestFromJSONTyped,
  PatientDispenseRecordImageRequestToJSON,
} from "./";

export const PostDispenseRecordImagesRequestBodyTypename = "PostDispenseRecordImagesRequestBody" as const;

/**
 *
 * @export
 * @interface PostDispenseRecordImagesRequestBody
 */
export interface PostDispenseRecordImagesRequestBody {
  /**
   *
   * @type {Array<PatientDispenseRecordImageRequest>}
   * @memberof PostDispenseRecordImagesRequestBody
   */
  imageData?: Array<PatientDispenseRecordImageRequest>;
  /**
   *
   * @type {string}
   * @memberof PostDispenseRecordImagesRequestBody
   */
  supplyDate: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostDispenseRecordImagesRequestBody
   */
  readonly __typename?: string;
}

export function PostDispenseRecordImagesRequestBodyFromJSON(json: any): PostDispenseRecordImagesRequestBody {
  return PostDispenseRecordImagesRequestBodyFromJSONTyped(json, false);
}

export function PostDispenseRecordImagesRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostDispenseRecordImagesRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostDispenseRecordImagesRequestBodyTypename,

    imageData: !exists(json, "image_data")
      ? undefined
      : (json["image_data"] as Array<any>).map(PatientDispenseRecordImageRequestFromJSON),
    supplyDate: json["supply_date"],
  };
}

export function PostDispenseRecordImagesRequestBodyToJSON(object?: PostDispenseRecordImagesRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    image_data:
      value.imageData === undefined
        ? undefined
        : (value.imageData as Array<any>).map(PatientDispenseRecordImageRequestToJSON),
    supply_date: value.supplyDate,
  };
}
