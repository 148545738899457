/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientComment,
  PatientCommentFromJSON,
  PatientCommentFromJSONTyped,
  PatientCommentToJSON,
  PatientDispensary,
  PatientDispensaryFromJSON,
  PatientDispensaryFromJSONTyped,
  PatientDispensaryToJSON,
  PatientDispenseRecordImage,
  PatientDispenseRecordImageFromJSON,
  PatientDispenseRecordImageFromJSONTyped,
  PatientDispenseRecordImageToJSON,
  PatientUsage,
  PatientUsageFromJSON,
  PatientUsageFromJSONTyped,
  PatientUsageToJSON,
  PatientUserMemo,
  PatientUserMemoFromJSON,
  PatientUserMemoFromJSONTyped,
  PatientUserMemoToJSON,
} from "./";

export const PatientDispenseRecordTypename = "PatientDispenseRecord" as const;

/**
 *
 * @export
 * @interface PatientDispenseRecord
 */
export interface PatientDispenseRecord {
  /**
   *
   * @type {Array<PatientComment>}
   * @memberof PatientDispenseRecord
   */
  comments?: Array<PatientComment>;
  /**
   *
   * @type {string}
   * @memberof PatientDispenseRecord
   */
  departmentName?: string;
  /**
   *
   * @type {PatientDispensary}
   * @memberof PatientDispenseRecord
   */
  dispensary?: PatientDispensary;
  /**
   *
   * @type {string}
   * @memberof PatientDispenseRecord
   */
  doctorName?: string;
  /**
   *
   * @type {Array<PatientDispenseRecordImage>}
   * @memberof PatientDispenseRecord
   */
  images?: Array<PatientDispenseRecordImage>;
  /**
   *
   * @type {string}
   * @memberof PatientDispenseRecord
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PatientDispenseRecord
   */
  notes?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PatientDispenseRecord
   */
  pharmacist?: string;
  /**
   *
   * @type {string}
   * @memberof PatientDispenseRecord
   */
  publisherName?: string;
  /**
   *
   * @type {number}
   * @memberof PatientDispenseRecord
   */
  recordId: number;
  /**
   *
   * @type {string}
   * @memberof PatientDispenseRecord
   */
  supplyDate?: string;
  /**
   *
   * @type {Array<PatientUsage>}
   * @memberof PatientDispenseRecord
   */
  usages?: Array<PatientUsage>;
  /**
   *
   * @type {Array<PatientUserMemo>}
   * @memberof PatientDispenseRecord
   */
  userMemos?: Array<PatientUserMemo>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientDispenseRecord
   */
  readonly __typename?: string;
}

export function PatientDispenseRecordFromJSON(json: any): PatientDispenseRecord {
  return PatientDispenseRecordFromJSONTyped(json, false);
}

export function PatientDispenseRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientDispenseRecord {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientDispenseRecordTypename,

    comments: !exists(json, "comments") ? undefined : (json["comments"] as Array<any>).map(PatientCommentFromJSON),
    departmentName: !exists(json, "department_name") ? undefined : json["department_name"],
    dispensary: !exists(json, "dispensary") ? undefined : PatientDispensaryFromJSON(json["dispensary"]),
    doctorName: !exists(json, "doctor_name") ? undefined : json["doctor_name"],
    images: !exists(json, "images")
      ? undefined
      : (json["images"] as Array<any>).map(PatientDispenseRecordImageFromJSON),
    name: !exists(json, "name") ? undefined : json["name"],
    notes: !exists(json, "notes") ? undefined : json["notes"],
    pharmacist: !exists(json, "pharmacist") ? undefined : json["pharmacist"],
    publisherName: !exists(json, "publisher_name") ? undefined : json["publisher_name"],
    recordId: json["record_id"],
    supplyDate: !exists(json, "supply_date") ? undefined : json["supply_date"],
    usages: !exists(json, "usages") ? undefined : (json["usages"] as Array<any>).map(PatientUsageFromJSON),
    userMemos: !exists(json, "user_memos")
      ? undefined
      : (json["user_memos"] as Array<any>).map(PatientUserMemoFromJSON),
  };
}

export function PatientDispenseRecordToJSON(object?: PatientDispenseRecord | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    comments: value.comments === undefined ? undefined : (value.comments as Array<any>).map(PatientCommentToJSON),
    department_name: value.departmentName,
    dispensary: PatientDispensaryToJSON(value.dispensary),
    doctor_name: value.doctorName,
    images: value.images === undefined ? undefined : (value.images as Array<any>).map(PatientDispenseRecordImageToJSON),
    name: value.name,
    notes: value.notes,
    pharmacist: value.pharmacist,
    publisher_name: value.publisherName,
    record_id: value.recordId,
    supply_date: value.supplyDate,
    usages: value.usages === undefined ? undefined : (value.usages as Array<any>).map(PatientUsageToJSON),
    user_memos: value.userMemos === undefined ? undefined : (value.userMemos as Array<any>).map(PatientUserMemoToJSON),
  };
}
