/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineNotebookShare,
  PatientMedicineNotebookShareFromJSON,
  PatientMedicineNotebookShareFromJSONTyped,
  PatientMedicineNotebookShareToJSON,
} from "./";

export const PatientMedicineNotebookSharePairTypename = "PatientMedicineNotebookSharePair" as const;

/**
 *
 * @export
 * @interface PatientMedicineNotebookSharePair
 */
export interface PatientMedicineNotebookSharePair {
  /**
   *
   * @type {PatientMedicineNotebookShare}
   * @memberof PatientMedicineNotebookSharePair
   */
  mine?: PatientMedicineNotebookShare;
  /**
   *
   * @type {PatientMedicineNotebookShare}
   * @memberof PatientMedicineNotebookSharePair
   */
  theirs?: PatientMedicineNotebookShare;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineNotebookSharePair
   */
  readonly __typename?: string;
}

export function PatientMedicineNotebookSharePairFromJSON(json: any): PatientMedicineNotebookSharePair {
  return PatientMedicineNotebookSharePairFromJSONTyped(json, false);
}

export function PatientMedicineNotebookSharePairFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineNotebookSharePair {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineNotebookSharePairTypename,

    mine: !exists(json, "mine") ? undefined : PatientMedicineNotebookShareFromJSON(json["mine"]),
    theirs: !exists(json, "theirs") ? undefined : PatientMedicineNotebookShareFromJSON(json["theirs"]),
  };
}

export function PatientMedicineNotebookSharePairToJSON(object?: PatientMedicineNotebookSharePair | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    mine: PatientMedicineNotebookShareToJSON(value.mine),
    theirs: PatientMedicineNotebookShareToJSON(value.theirs),
  };
}
