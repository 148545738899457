/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostPharmacistResetPasswordRequestBodyTypename = "PostPharmacistResetPasswordRequestBody" as const;

/**
 *
 * @export
 * @interface PostPharmacistResetPasswordRequestBody
 */
export interface PostPharmacistResetPasswordRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostPharmacistResetPasswordRequestBody
   */
  email: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostPharmacistResetPasswordRequestBody
   */
  readonly __typename?: string;
}

export function PostPharmacistResetPasswordRequestBodyFromJSON(json: any): PostPharmacistResetPasswordRequestBody {
  return PostPharmacistResetPasswordRequestBodyFromJSONTyped(json, false);
}

export function PostPharmacistResetPasswordRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostPharmacistResetPasswordRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostPharmacistResetPasswordRequestBodyTypename,

    email: json["email"],
  };
}

export function PostPharmacistResetPasswordRequestBodyToJSON(
  object?: PostPharmacistResetPasswordRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    email: value.email,
  };
}
