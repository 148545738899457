/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorPublicAcceptCodeTypename = "PharmacyCounselorPublicAcceptCode" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorPublicAcceptCode
 */
export interface PharmacyCounselorPublicAcceptCode {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorPublicAcceptCode
   */
  acceptCode?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPublicAcceptCode
   */
  dispensingType?: PharmacyCounselorPublicAcceptCodeDispensingTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPublicAcceptCode
   */
  type: PharmacyCounselorPublicAcceptCodeTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorPublicAcceptCode
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorPublicAcceptCodeDispensingTypeEnum {
  Normal = "normal",
  Quick = "quick",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorPublicAcceptCodeTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  DirectVisit = "direct_visit",
}

export function PharmacyCounselorPublicAcceptCodeFromJSON(json: any): PharmacyCounselorPublicAcceptCode {
  return PharmacyCounselorPublicAcceptCodeFromJSONTyped(json, false);
}

export function PharmacyCounselorPublicAcceptCodeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorPublicAcceptCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorPublicAcceptCodeTypename,

    acceptCode: !exists(json, "accept_code") ? undefined : json["accept_code"],
    dispensingType: !exists(json, "dispensing_type") ? undefined : json["dispensing_type"],
    type: json["type"],
  };
}

export function PharmacyCounselorPublicAcceptCodeToJSON(object?: PharmacyCounselorPublicAcceptCode | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    accept_code: value.acceptCode,
    dispensing_type: value.dispensingType,
    type: value.type,
  };
}
