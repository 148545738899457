import { useCallback } from "react";

export type LoadImage = (src: string) => Promise<HTMLImageElement>;

export type UseTalkReadImage = () => LoadImage;

export const useTalkReadImage: UseTalkReadImage = () =>
  useCallback<LoadImage>(
    (src) =>
      new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = (): void => resolve(img);
        img.onerror = (e): void => reject(e);
        img.src = src;
      }),
    []
  );
