import OpenInNewIcon from "@mgdx/assets/icons/OpenInNewIcon";
import clsx from "clsx";
import React from "react";

import ButtonGroup from "../ButtonGroup";
import {
  FilledButton,
  FilledButtonProps,
  FilledExternalLinkButton,
  FilledExternalLinkButtonProps,
} from "../FilledButton";
import { MessagePlain } from "../MessagePlain";
import ModalDialog, { Props as ModalDialogProps } from "../ModalDialog";
import Nl2Br from "../Nl2Br";
import { OutlinedButton, OutlinedExternalButton } from "../OutlinedButton";
import Title from "../Title";
import * as styles from "./ConfirmDialog.module.css";

type ButtonProps = FilledButtonProps & { outline?: boolean };
type ExternalButtonProps = FilledExternalLinkButtonProps & { outline?: boolean };

/**
 * @deprecated
 */
export type ConfirmDialogOptions = Partial<ModalDialogProps> & {
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  confirmationText?: string;
  cancellationText?: string;
  extraText?: string;
  confirmationButtonProps?: Partial<Omit<ButtonProps, "onClick">>;
  cancellationButtonProps?: Partial<Omit<ButtonProps, "onClick">>;
  extraButtonProps?: { type: "button" | "external" } & Partial<
    Pick<ExternalButtonProps, "variant" | "hidden" | "href" | "outline">
  > &
    Partial<Pick<ButtonProps, "onClick">>;
  isReject?: boolean;
  isDescriptionPositionLeft?: boolean;
  isVerticalButtons?: boolean;
  dialogClassName?: string;
  overlayClassName?: string;
};

const Button = ({ outline, variant, onClick, children, ...props }: ButtonProps) => {
  if (outline) {
    return (
      <OutlinedButton {...props} variant="key" widthType="full-width" onClick={onClick}>
        {children}
      </OutlinedButton>
    );
  } else {
    return (
      <FilledButton {...props} variant={variant} widthType="full-width" onClick={onClick}>
        {children}
      </FilledButton>
    );
  }
};

const ExternalButton = ({ outline, variant, children, ...props }: ExternalButtonProps) => {
  if (outline) {
    return (
      <OutlinedExternalButton {...props} variant="key" widthType="full-width">
        {children}
      </OutlinedExternalButton>
    );
  } else {
    return (
      <FilledExternalLinkButton {...props} variant={variant} widthType="full-width">
        {children}
      </FilledExternalLinkButton>
    );
  }
};

/**
 * @deprecated
 */
export type ConfirmDialogProps = {
  isOpen?: boolean;
  options?: ConfirmDialogOptions;
  onCancel?(): void;
  onConfirm?(): void;
  onClose?(): void;
};

/**
 * @deprecated use ConfirmDialog from ui/components/ConfirmDialogNew instead
 */
export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  isOpen,
  options = {},
  onCancel,
  onConfirm,
  onClose,
  ...props
}) => {
  const {
    title,
    description,
    confirmationText,
    cancellationText,
    extraText,
    confirmationButtonProps,
    cancellationButtonProps,
    extraButtonProps,
    isReject,
    isDescriptionPositionLeft,
    isVerticalButtons,
    dialogClassName,
    overlayClassName,
  } = options;

  return (
    <ModalDialog
      {...props}
      variant="confirm"
      className={clsx(styles.dialog, dialogClassName && dialogClassName)}
      overlayClassName={overlayClassName}
      isOpen={isOpen ?? false}
      onRequestClose={isReject ? onCancel : onClose}
    >
      {typeof title === "string" ? <Title variant="basic_center">{title}</Title> : <>{title}</>}
      {typeof description === "string" ? (
        <MessagePlain variant="info" textAlign={isDescriptionPositionLeft ? "left" : "center"} className="mt-2">
          <Nl2Br body={description} />
        </MessagePlain>
      ) : (
        <>{description}</>
      )}
      <div className="mt-4">
        {isVerticalButtons ? (
          <ButtonGroup vertical={true} className={"mx-auto w-[266px]"}>
            {!(extraButtonProps && extraButtonProps.hidden) && (
              <>
                {extraButtonProps?.type === "external" ? (
                  <ExternalButton
                    outline={extraButtonProps?.outline}
                    variant={extraButtonProps?.variant ?? "secondary"}
                    title={extraText ?? ""}
                    widthType="full-width"
                    href={extraButtonProps?.href}
                    data-testid="confirm-dialog-external-button"
                  >
                    {extraText}
                    <OpenInNewIcon size="s" variant="text_link" />
                  </ExternalButton>
                ) : (
                  <Button
                    outline={extraButtonProps?.outline}
                    variant={extraButtonProps?.variant ?? "primary"}
                    widthType="full-width"
                    onClick={extraButtonProps?.onClick}
                    data-testid="confirm-dialog-external-button"
                  >
                    {extraText}
                  </Button>
                )}
              </>
            )}

            {!(confirmationButtonProps && confirmationButtonProps.hidden) && (
              <Button
                variant="primary"
                widthType="full-width"
                onClick={onConfirm}
                data-testid="confirm-dialog-confirm-button"
                {...confirmationButtonProps}
              >
                {confirmationText}
              </Button>
            )}

            {!(cancellationButtonProps && cancellationButtonProps.hidden) && (
              <Button
                variant="secondary"
                widthType="full-width"
                onClick={onCancel}
                data-testid="confirm-dialog-cancel-button"
                {...cancellationButtonProps}
              >
                {cancellationText}
              </Button>
            )}
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            {!(cancellationButtonProps && cancellationButtonProps.hidden) && (
              <Button
                variant="secondary"
                widthType="full-width"
                onClick={onCancel}
                data-testid="confirm-dialog-cancel-button"
                {...cancellationButtonProps}
              >
                {cancellationText}
              </Button>
            )}

            {!(confirmationButtonProps && confirmationButtonProps.hidden) && (
              <Button
                variant="primary"
                widthType="full-width"
                onClick={onConfirm}
                data-testid="confirm-dialog-confirm-button"
                {...confirmationButtonProps}
              >
                {confirmationText}
              </Button>
            )}
          </ButtonGroup>
        )}
      </div>
    </ModalDialog>
  );
};
