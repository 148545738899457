/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicCounselorPharmacyCounselingTypename = "ClinicCounselorPharmacyCounseling" as const;

/**
 *
 * @export
 * @interface ClinicCounselorPharmacyCounseling
 */
export interface ClinicCounselorPharmacyCounseling {
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorPharmacyCounseling
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPharmacyCounseling
   */
  status: ClinicCounselorPharmacyCounselingStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorPharmacyCounseling
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ClinicCounselorPharmacyCounselingStatusEnum {
  None = "none",
  Required = "required",
  Requested = "requested",
  NotRequired = "not_required",
}

export function ClinicCounselorPharmacyCounselingFromJSON(json: any): ClinicCounselorPharmacyCounseling {
  return ClinicCounselorPharmacyCounselingFromJSONTyped(json, false);
}

export function ClinicCounselorPharmacyCounselingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClinicCounselorPharmacyCounseling {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorPharmacyCounselingTypename,

    id: !exists(json, "id") ? undefined : json["id"],
    status: json["status"],
  };
}

export function ClinicCounselorPharmacyCounselingToJSON(object?: ClinicCounselorPharmacyCounseling | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    status: value.status,
  };
}
