import { UserCredential } from "@firebase/auth";
import { errorHandlerReport, firebaseErrorHandler, isFirebaseError } from "@mgdx-libs/error-handler";
import { getFirebaseAuth } from "@mgdx-libs/firebase";
import { logger } from "@mgdx-libs/logger";
import { getIdToken } from "firebase/auth";

const ACCESS_TOKEN_STORAGE_KEY = "accessToken";

const hasSessionStorage: () => boolean = (): boolean =>
  typeof window !== "undefined" && typeof window.sessionStorage !== "undefined";

export const getAccessToken = (): string => {
  if (hasSessionStorage()) {
    return sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) || "";
  }

  return "";
};

export const setAccessToken = (accessToken: string): void => {
  if (hasSessionStorage()) {
    sessionStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, accessToken);
  }
};

export const clearAccessToken = (): void => {
  if (hasSessionStorage()) {
    sessionStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
  }
};

export const refreshAccessToken = async (
  userCredential?: Pick<UserCredential, "user">
): Promise<string | undefined> => {
  const firebaseAuth = getFirebaseAuth();
  const firebaseUser = userCredential ? userCredential.user : firebaseAuth?.currentUser;

  if (firebaseUser) {
    logger.debug("@mgdx-libs/firebase refreshAccessToken User: %o", firebaseUser);
    const accessToken = await getIdToken(firebaseUser).catch(async (errorOrFirebaseError) => {
      if (isFirebaseError(errorOrFirebaseError)) {
        try {
          firebaseErrorHandler(errorOrFirebaseError);
        } catch (error) {
          await errorHandlerReport(error);
        }
      } else {
        await errorHandlerReport(errorOrFirebaseError);
      }
    });

    logger.debug("@mgdx-libs/firebase refreshAccessToken getIdToken: %s", accessToken);
    if (accessToken) {
      setAccessToken(accessToken);
      return accessToken;
    }
  }

  return undefined;
};

export const forceRefreshAccessToken = async (
  userCredential?: Pick<UserCredential, "user">
): Promise<string | undefined> => {
  const firebaseAuth = getFirebaseAuth();
  const firebaseUser = userCredential ? userCredential.user : firebaseAuth?.currentUser;

  if (firebaseUser) {
    logger.debug("@mgdx-libs/firebase forceRefreshAccessToken User: %o", firebaseUser);
    const accessToken = await getIdToken(firebaseUser, true).catch(async (errorOrFirebaseError) => {
      if (isFirebaseError(errorOrFirebaseError)) {
        try {
          firebaseErrorHandler(errorOrFirebaseError);
        } catch (error) {
          await errorHandlerReport(error);
        }
      } else {
        await errorHandlerReport(errorOrFirebaseError);
      }
    });

    logger.debug("@mgdx-libs/firebase forceRefreshAccessToken getIdToken: %s", accessToken);
    if (accessToken) {
      setAccessToken(accessToken);
      return accessToken;
    }
  }

  return undefined;
};
