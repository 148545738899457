/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacistRegistrableTypename = "PharmacyPharmacistRegistrable" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacistRegistrable
 */
export interface PharmacyPharmacistRegistrable {
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacistRegistrable
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacistRegistrable
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacistRegistrable
   */
  lastName: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacistRegistrable
   */
  pharmacyId: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacistRegistrable
   */
  readonly __typename?: string;
}

export function PharmacyPharmacistRegistrableFromJSON(json: any): PharmacyPharmacistRegistrable {
  return PharmacyPharmacistRegistrableFromJSONTyped(json, false);
}

export function PharmacyPharmacistRegistrableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPharmacistRegistrable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacistRegistrableTypename,

    email: json["email"],
    firstName: json["first_name"],
    lastName: json["last_name"],
    pharmacyId: json["pharmacy_id"],
  };
}

export function PharmacyPharmacistRegistrableToJSON(object?: PharmacyPharmacistRegistrable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    email: value.email,
    first_name: value.firstName,
    last_name: value.lastName,
    pharmacy_id: value.pharmacyId,
  };
}
