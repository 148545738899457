import { talkReducer, TalkState } from "@mgdx/talk/ducks";
import { videoCallingReducer, VideoCallingState } from "@mgdx/video-calling/ducks";
import { createMigrate, persistReducer, session, storage } from "@mgdx-libs/redux-persist-ssr";
import { combineReducers, Reducer } from "@reduxjs/toolkit";

import currentUserReducer, { CurrentUserState } from "./currentUser";
import liffReducer, { LiffState } from "./liff";
import loadingReducer, { LoadingState } from "./loading";
import requestsReducer from "./requests";
import uiReducer, { UiState } from "./ui";

export interface RootState {
  requests: Reducer;
  currentUser: CurrentUserState;
  ui: UiState;
  talk: TalkState;
  videoCalling: VideoCallingState;
  liff: LiffState;
  loading: LoadingState;
}

declare module "react-redux" {
  interface DefaultRootState extends RootState {} // eslint-disable-line @typescript-eslint/no-empty-interface
}

const currentUserPersistConfig = {
  key: "currentUser",
  storage,
  blacklist: ["userInfo", "isLoggedInAndValidAccessToken", "inProcessOfSignup"],
  version: 1,
  migrate: createMigrate(
    {
      1: ({ inProcessOfSignup, ...state }: any) => state,
    },
    { debug: true }
  ),
};

const uiPersistConfig = {
  key: "ui",
  storage,
  whitelist: [],
};

const talkPersistConfig = {
  key: "talk",
  storage,
  blacklist: [],
};

const videoCallingConfig = {
  key: "videoCalling",
  storage,
  blacklist: [],
};

const liffConfig = {
  key: "liff",
  storage: session,
  blacklist: [],
};

const loadingConfig = {
  key: "loading",
  storage: session,
  blacklist: [],
};

const rootReducer = combineReducers<RootState>({
  requests: requestsReducer,
  currentUser: persistReducer(currentUserPersistConfig, currentUserReducer) as any,
  ui: persistReducer(uiPersistConfig, uiReducer) as any,
  talk: persistReducer(talkPersistConfig, talkReducer) as any,
  videoCalling: persistReducer(videoCallingConfig, videoCallingReducer) as any,
  liff: persistReducer(liffConfig, liffReducer) as any,
  loading: persistReducer(loadingConfig, loadingReducer) as any,
});

export default rootReducer;
