import clsx from "clsx";
import React from "react";

import * as styles from "./Badge.module.css";

export const sizes = ["s", "m", "l", "xl"] as const;

export type Props = React.HTMLAttributes<HTMLDivElement> & {
  size?: (typeof sizes)[number];
};

const Badge = ({ className, size = "m", ...props }: Props) => (
  <div
    {...props}
    className={clsx(
      styles.badge,
      size === "s" && styles.sizeS,
      size === "m" && styles.sizeM,
      size === "l" && styles.sizeL,
      size === "xl" && styles.sizeXL,
      className
    )}
  />
);

export default Badge;
