/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  GetCounselingReportsResponse,
  GetCounselingReportsResponseFromJSON,
  GetCounselingReportsResponseToJSON,
  PharmacyCounselorBillingReport,
  PharmacyCounselorBillingReportFromJSON,
  PharmacyCounselorBillingReportToJSON,
} from "../models";

export interface GetBillingReportRequest {
  from: string;
  to: string;
  pharmacyGroupId?: number;
  pharmacyId?: number;
  limit?: number;
  offset?: number;
}

export interface GetCounselingReportRequest {
  from: string;
  to: string;
  pharmacyId?: number;
  status?: GetCounselingReportStatusEnum;
  followUpStatus?: Array<GetCounselingReportFollowUpStatusEnum>;
  type?: Array<GetCounselingReportTypeEnum>;
  limit?: number;
  offset?: number;
}

/**
 *
 */
export class ReportApi extends runtime.BaseAPI {
  /**
   * Get billing report
   */
  async getBillingReportRaw(
    requestParameters: GetBillingReportRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyCounselorBillingReport>>> {
    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling getBillingReport."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling getBillingReport."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.pharmacyGroupId !== undefined) {
      queryParameters["pharmacy_group_id"] = requestParameters.pharmacyGroupId;
    }

    if (requestParameters.pharmacyId !== undefined) {
      queryParameters["pharmacy_id"] = requestParameters.pharmacyId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/billing-reports`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyCounselorBillingReportFromJSON));
  }

  /**
   * Get billing report
   */
  async getBillingReport(
    requestParameters: GetBillingReportRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyCounselorBillingReport>> {
    return promiseRetry(
      (retry) =>
        this.getBillingReportRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get counseling report
   */
  async getCounselingReportRaw(
    requestParameters: GetCounselingReportRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetCounselingReportsResponse>> {
    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling getCounselingReport."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling getCounselingReport."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.pharmacyId !== undefined) {
      queryParameters["pharmacy_id"] = requestParameters.pharmacyId;
    }

    if (requestParameters.status !== undefined) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.followUpStatus) {
      queryParameters["follow_up_status"] = requestParameters.followUpStatus;
    }

    if (requestParameters.type) {
      queryParameters["type"] = requestParameters.type;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["X-MGDX-REPORT-AUTHENTICATION"] = this.configuration.apiKey("X-MGDX-REPORT-AUTHENTICATION"); // ReportAPIKey authentication
    }

    const response = await this.request(
      {
        path: `/counseling-reports`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetCounselingReportsResponseFromJSON(jsonValue));
  }

  /**
   * Get counseling report
   */
  async getCounselingReport(
    requestParameters: GetCounselingReportRequest,
    initOverrides?: RequestInit
  ): Promise<GetCounselingReportsResponse> {
    return promiseRetry(
      (retry) =>
        this.getCounselingReportRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetCounselingReportStatusEnum {
  Requested = "requested",
  Accepted = "accepted",
  Started = "started",
  Completed = "completed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingReportFollowUpStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Notified = "notified",
  Answered = "answered",
  AnswerConfirmed = "answer_confirmed",
  Completed = "completed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingReportTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  FollowUpOnly = "follow_up_only",
  DeliveryOnly = "delivery_only",
  DirectVisit = "direct_visit",
}
