/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPrefectureTypename = "PharmacyPrefecture" as const;

/**
 *
 * @export
 * @interface PharmacyPrefecture
 */
export interface PharmacyPrefecture {
  /**
   *
   * @type {string}
   * @memberof PharmacyPrefecture
   */
  name: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PharmacyPrefecture
   */
  pharmacyIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof PharmacyPrefecture
   */
  prefCode: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPrefecture
   */
  readonly __typename?: string;
}

export function PharmacyPrefectureFromJSON(json: any): PharmacyPrefecture {
  return PharmacyPrefectureFromJSONTyped(json, false);
}

export function PharmacyPrefectureFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPrefecture {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPrefectureTypename,

    name: json["name"],
    pharmacyIds: !exists(json, "pharmacy_ids") ? undefined : json["pharmacy_ids"],
    prefCode: json["pref_code"],
  };
}

export function PharmacyPrefectureToJSON(object?: PharmacyPrefecture | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    name: value.name,
    pharmacy_ids: value.pharmacyIds,
    pref_code: value.prefCode,
  };
}
