/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyControlPanelAutoPrintTypename = "PharmacyControlPanelAutoPrint" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanelAutoPrint
 */
export interface PharmacyControlPanelAutoPrint {
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelAutoPrint
   */
  enableBillingCompletedOrder?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelAutoPrint
   */
  enableCounseling?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelAutoPrint
   */
  enableCounselingCancel?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelAutoPrint
   */
  enableCounselingSummary?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelAutoPrint
   */
  enableFinishedOrder?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelAutoPrint
   */
  enableFollowUp?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelAutoPrint
   */
  enableInterview?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelAutoPrint
   */
  enablePrescription?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanelAutoPrint
   */
  readonly __typename?: string;
}

export function PharmacyControlPanelAutoPrintFromJSON(json: any): PharmacyControlPanelAutoPrint {
  return PharmacyControlPanelAutoPrintFromJSONTyped(json, false);
}

export function PharmacyControlPanelAutoPrintFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyControlPanelAutoPrint {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelAutoPrintTypename,

    enableBillingCompletedOrder: !exists(json, "enable_billing_completed_order")
      ? undefined
      : json["enable_billing_completed_order"],
    enableCounseling: !exists(json, "enable_counseling") ? undefined : json["enable_counseling"],
    enableCounselingCancel: !exists(json, "enable_counseling_cancel") ? undefined : json["enable_counseling_cancel"],
    enableCounselingSummary: !exists(json, "enable_counseling_summary") ? undefined : json["enable_counseling_summary"],
    enableFinishedOrder: !exists(json, "enable_finished_order") ? undefined : json["enable_finished_order"],
    enableFollowUp: !exists(json, "enable_follow_up") ? undefined : json["enable_follow_up"],
    enableInterview: !exists(json, "enable_interview") ? undefined : json["enable_interview"],
    enablePrescription: !exists(json, "enable_prescription") ? undefined : json["enable_prescription"],
  };
}

export function PharmacyControlPanelAutoPrintToJSON(object?: PharmacyControlPanelAutoPrint | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    enable_billing_completed_order: value.enableBillingCompletedOrder,
    enable_counseling: value.enableCounseling,
    enable_counseling_cancel: value.enableCounselingCancel,
    enable_counseling_summary: value.enableCounselingSummary,
    enable_finished_order: value.enableFinishedOrder,
    enable_follow_up: value.enableFollowUp,
    enable_interview: value.enableInterview,
    enable_prescription: value.enablePrescription,
  };
}
