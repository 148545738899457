/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyUsablesTypename = "PharmacyPharmacyUsables" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyUsables
 */
export interface PharmacyPharmacyUsables {
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyUsables
   */
  name?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyUsables
   */
  readonly __typename?: string;
}

export function PharmacyPharmacyUsablesFromJSON(json: any): PharmacyPharmacyUsables {
  return PharmacyPharmacyUsablesFromJSONTyped(json, false);
}

export function PharmacyPharmacyUsablesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPharmacyUsables {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyUsablesTypename,

    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function PharmacyPharmacyUsablesToJSON(object?: PharmacyPharmacyUsables | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    name: value.name,
  };
}
