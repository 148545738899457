/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  GetControlPanelsResponse,
  GetControlPanelsResponseFromJSON,
  GetControlPanelsResponseToJSON,
  PharmacyControlPanel,
  PharmacyControlPanelFromJSON,
  PharmacyControlPanelToJSON,
  PharmacyControlPanelUpdatable,
  PharmacyControlPanelUpdatableFromJSON,
  PharmacyControlPanelUpdatableToJSON,
} from "../models";

export interface GetAllControlPanelsRequest {
  chainId?: number;
  limit?: number;
  offset?: number;
}

export interface PutControlPanelRequest {
  controlPanelId: string;
  pharmacyControlPanelUpdatable: PharmacyControlPanelUpdatable;
}

/**
 *
 */
export class ControlPanelApi extends runtime.BaseAPI {
  /**
   * Get all control panels
   */
  async getAllControlPanelsRaw(
    requestParameters: GetAllControlPanelsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetControlPanelsResponse>> {
    const queryParameters: any = {};

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/control-panels`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetControlPanelsResponseFromJSON(jsonValue));
  }

  /**
   * Get all control panels
   */
  async getAllControlPanels(
    requestParameters: GetAllControlPanelsRequest,
    initOverrides?: RequestInit
  ): Promise<GetControlPanelsResponse> {
    return promiseRetry(
      (retry) =>
        this.getAllControlPanelsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * update control panel
   */
  async putControlPanelRaw(
    requestParameters: PutControlPanelRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyControlPanel>> {
    if (requestParameters.controlPanelId === null || requestParameters.controlPanelId === undefined) {
      throw new runtime.RequiredError(
        "controlPanelId",
        "Required parameter requestParameters.controlPanelId was null or undefined when calling putControlPanel."
      );
    }

    if (
      requestParameters.pharmacyControlPanelUpdatable === null ||
      requestParameters.pharmacyControlPanelUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyControlPanelUpdatable",
        "Required parameter requestParameters.pharmacyControlPanelUpdatable was null or undefined when calling putControlPanel."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/control-panels/{control_panel_id}`.replace(
          `{${"control_panel_id"}}`,
          encodeURIComponent(String(requestParameters.controlPanelId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyControlPanelUpdatableToJSON(requestParameters.pharmacyControlPanelUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyControlPanelFromJSON(jsonValue));
  }

  /**
   * update control panel
   */
  async putControlPanel(
    requestParameters: PutControlPanelRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyControlPanel> {
    return promiseRetry(
      (retry) =>
        this.putControlPanelRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
