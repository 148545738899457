import { ZendeskVia, ZendeskViaFromJSON, ZendeskViaToJSON } from "./ZendeskVia";

export const ZendeskTicketCommentTypename = "ZendeskTicketComment" as const;

/**
 *
 * @export
 * @interface ZendeskTicketComment
 * @see https://developer.zendesk.com/api-reference/ticketing/tickets/ticket_comments/
 */
export interface ZendeskTicketComment {
  /**
   * Attachments, if any. See Attachment
   * @type {string[]}
   * @memberof ZendeskTicketComment
   */
  attachments: string[];
  /**
   * The id of the comment author. See Author id
   * @type {number}
   * @memberof ZendeskTicketComment
   */
  author_id: number;
  /**
   * The comment string
   * @type {string}
   * @memberof ZendeskTicketComment
   */
  body: string;
  /**
   * The time the comment was created
   * @type {string}
   * @memberof ZendeskTicketComment
   */
  created_at: string;
  /**
   * The comment formatted as HTML
   * @type {string}
   * @memberof ZendeskTicketComment
   */
  html_body: string;
  /**
   * Automatically assigned when the comment is created
   * @type {number}
   * @memberof ZendeskTicketComment
   */
  id: number;
  /**
   * System information (web client, IP address, etc.) and comment flags, if any. See Comment flags
   * @type {boolean}
   * @memberof ZendeskTicketComment
   */
  metadata: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  /**
   * The comment as plain text
   * @type {string}
   * @memberof ZendeskTicketComment
   */
  plain_body: string;
  /**
   * if a public comment; false if an internal note. The initial value set on ticket creation persists for any additional comment unless you change it
   * @type {boolean}
   * @memberof ZendeskTicketComment
   */
  public: boolean;
  /**
   * Comment or VoiceComment. The JSON object for adding voice comments to tickets is different. See Adding voice comments to tickets
   * @type {string}
   * @memberof ZendeskTicketComment
   */
  type: string;
  /**
   * List of tokens received from uploading files for comment attachments. The files are attached by creating or updating tickets with the tokens. See Attaching files in Tickets
   * @type {string[]}
   * @memberof ZendeskTicketComment
   */
  uploads: string[];
  /**
   * Describes how the object was created. See the Via object reference
   * @type {boolean}
   * @memberof ZendeskTicketComment
   */
  via: ZendeskVia;
  /**
   * Resource type name
   * @type {boolean}
   * @memberof ZendeskTicketComment
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskTicketCommentFromJSON(json: any): ZendeskTicketComment {
  return ZendeskTicketCommentFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskTicketCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskTicketComment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ZendeskTicketCommentTypename,
    attachments: json["attachments"],
    author_id: json["author_id"],
    body: json["body"],
    created_at: json["created_at"],
    html_body: json["html_body"],
    id: json["id"],
    metadata: json["metadata"],
    plain_body: json["plain_body"],
    public: json["public"],
    type: json["type"],
    uploads: json["uploads"],
    via: ZendeskViaFromJSON(json["via"]),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskTicketCommentToJSON(object?: ZendeskTicketComment | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    attachments: value.attachments,
    author_id: value.author_id,
    body: value.body,
    created_at: value.created_at,
    html_body: value.html_body,
    id: value.id,
    metadata: value.metadata,
    plain_body: value.plain_body,
    public: value.public,
    type: value.type,
    uploads: value.uploads,
    via: ZendeskViaToJSON(value.via),
  };
}
