/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorCounselingReportAnswer,
  PharmacyCounselorCounselingReportAnswerFromJSON,
  PharmacyCounselorCounselingReportAnswerFromJSONTyped,
  PharmacyCounselorCounselingReportAnswerToJSON,
  PharmacyCounselorCounselingReportQuestionnaire,
  PharmacyCounselorCounselingReportQuestionnaireFromJSON,
  PharmacyCounselorCounselingReportQuestionnaireFromJSONTyped,
  PharmacyCounselorCounselingReportQuestionnaireToJSON,
} from "./";

export const PharmacyCounselorCounselingReportFollowUpTypename = "PharmacyCounselorCounselingReportFollowUp" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReportFollowUp
 */
export interface PharmacyCounselorCounselingReportFollowUp {
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  answerConfirmedTime?: string;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  answeredTime?: string;
  /**
   *
   * @type {Array<PharmacyCounselorCounselingReportAnswer>}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  answers?: Array<PharmacyCounselorCounselingReportAnswer>;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  completedTime?: string;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  isNotifyAnswered?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  message?: string;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  notificationTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  notifyType?: PharmacyCounselorCounselingReportFollowUpNotifyTypeEnum;
  /**
   *
   * @type {Array<PharmacyCounselorCounselingReportQuestionnaire>}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  questionnaires?: Array<PharmacyCounselorCounselingReportQuestionnaire>;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  status: PharmacyCounselorCounselingReportFollowUpStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  thanksMessage?: string;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  thanksMessageSentTime?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportFollowUp
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportFollowUpNotifyTypeEnum {
  Email = "email",
  Sms = "sms",
  LineServiceMessage = "line_service_message",
  LineOfficialAccount = "line_official_account",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportFollowUpStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Notified = "notified",
  Answered = "answered",
  AnswerConfirmed = "answer_confirmed",
  Completed = "completed",
  Canceled = "canceled",
}

export function PharmacyCounselorCounselingReportFollowUpFromJSON(
  json: any
): PharmacyCounselorCounselingReportFollowUp {
  return PharmacyCounselorCounselingReportFollowUpFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportFollowUpFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReportFollowUp {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportFollowUpTypename,

    answerConfirmedTime:
      !exists(json, "answer_confirmed_time") ||
      (typeof json["answer_confirmed_time"] === "string" && json["answer_confirmed_time"].substring(0, 4) === "0001")
        ? undefined
        : json["answer_confirmed_time"],
    answeredTime:
      !exists(json, "answered_time") ||
      (typeof json["answered_time"] === "string" && json["answered_time"].substring(0, 4) === "0001")
        ? undefined
        : json["answered_time"],
    answers: !exists(json, "answers")
      ? undefined
      : (json["answers"] as Array<any>).map(PharmacyCounselorCounselingReportAnswerFromJSON),
    completedTime:
      !exists(json, "completed_time") ||
      (typeof json["completed_time"] === "string" && json["completed_time"].substring(0, 4) === "0001")
        ? undefined
        : json["completed_time"],
    isNotifyAnswered: !exists(json, "is_notify_answered") ? undefined : json["is_notify_answered"],
    message: !exists(json, "message") ? undefined : json["message"],
    notificationTime:
      !exists(json, "notification_time") ||
      (typeof json["notification_time"] === "string" && json["notification_time"].substring(0, 4) === "0001")
        ? undefined
        : json["notification_time"],
    notifyType: !exists(json, "notify_type") ? undefined : json["notify_type"],
    questionnaires: !exists(json, "questionnaires")
      ? undefined
      : (json["questionnaires"] as Array<any>).map(PharmacyCounselorCounselingReportQuestionnaireFromJSON),
    status: json["status"],
    thanksMessage: !exists(json, "thanks_message") ? undefined : json["thanks_message"],
    thanksMessageSentTime:
      !exists(json, "thanks_message_sent_time") ||
      (typeof json["thanks_message_sent_time"] === "string" &&
        json["thanks_message_sent_time"].substring(0, 4) === "0001")
        ? undefined
        : json["thanks_message_sent_time"],
  };
}

export function PharmacyCounselorCounselingReportFollowUpToJSON(
  object?: PharmacyCounselorCounselingReportFollowUp | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer_confirmed_time:
      value.answerConfirmedTime === undefined ? undefined : moment(value.answerConfirmedTime).tz("Asia/Tokyo").format(),
    answered_time: value.answeredTime === undefined ? undefined : moment(value.answeredTime).tz("Asia/Tokyo").format(),
    answers:
      value.answers === undefined
        ? undefined
        : (value.answers as Array<any>).map(PharmacyCounselorCounselingReportAnswerToJSON),
    completed_time:
      value.completedTime === undefined ? undefined : moment(value.completedTime).tz("Asia/Tokyo").format(),
    is_notify_answered: value.isNotifyAnswered,
    message: value.message,
    notification_time:
      value.notificationTime === undefined ? undefined : moment(value.notificationTime).tz("Asia/Tokyo").format(),
    notify_type: value.notifyType,
    questionnaires:
      value.questionnaires === undefined
        ? undefined
        : (value.questionnaires as Array<any>).map(PharmacyCounselorCounselingReportQuestionnaireToJSON),
    status: value.status,
    thanks_message: value.thanksMessage,
    thanks_message_sent_time:
      value.thanksMessageSentTime === undefined
        ? undefined
        : moment(value.thanksMessageSentTime).tz("Asia/Tokyo").format(),
  };
}
