/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ChatterTextMessagePatientTypename = "ChatterTextMessagePatient" as const;

/**
 *
 * @export
 * @interface ChatterTextMessagePatient
 */
export interface ChatterTextMessagePatient {
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessagePatient
   */
  firstName: string;
  /**
   *
   * @type {number}
   * @memberof ChatterTextMessagePatient
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessagePatient
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessagePatient
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessagePatient
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessagePatient
   */
  tel: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterTextMessagePatient
   */
  readonly __typename?: string;
}

export function ChatterTextMessagePatientFromJSON(json: any): ChatterTextMessagePatient {
  return ChatterTextMessagePatientFromJSONTyped(json, false);
}

export function ChatterTextMessagePatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChatterTextMessagePatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterTextMessagePatientTypename,

    firstName: json["first_name"],
    id: !exists(json, "id") ? undefined : json["id"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: json["last_name"],
    tel: json["tel"],
  };
}

export function ChatterTextMessagePatientToJSON(object?: ChatterTextMessagePatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    first_name: value.firstName,
    id: value.id,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    tel: value.tel,
  };
}
