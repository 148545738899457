/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorDeliveryUpdatableTypename = "PharmacyCounselorDeliveryUpdatable" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorDeliveryUpdatable
 */
export interface PharmacyCounselorDeliveryUpdatable {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorDeliveryUpdatable
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorDeliveryUpdatable
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorDeliveryUpdatable
   */
  addressType?: PharmacyCounselorDeliveryUpdatableAddressTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorDeliveryUpdatable
   */
  orderId?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorDeliveryUpdatable
   */
  postalCode?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorDeliveryUpdatable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorDeliveryUpdatableAddressTypeEnum {
  Registered = "registered",
  Specified = "specified",
  PickUp = "pick_up",
}

export function PharmacyCounselorDeliveryUpdatableFromJSON(json: any): PharmacyCounselorDeliveryUpdatable {
  return PharmacyCounselorDeliveryUpdatableFromJSONTyped(json, false);
}

export function PharmacyCounselorDeliveryUpdatableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorDeliveryUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorDeliveryUpdatableTypename,

    addressLine1: !exists(json, "address_line1") ? undefined : json["address_line1"],
    addressLine2: !exists(json, "address_line2") ? undefined : json["address_line2"],
    addressType: !exists(json, "address_type") ? undefined : json["address_type"],
    orderId: !exists(json, "order_id") ? undefined : json["order_id"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
  };
}

export function PharmacyCounselorDeliveryUpdatableToJSON(object?: PharmacyCounselorDeliveryUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    address_type: value.addressType,
    order_id: value.orderId,
    postal_code: value.postalCode,
  };
}
