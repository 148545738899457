/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineNotebookSharePatient,
  PatientMedicineNotebookSharePatientFromJSON,
  PatientMedicineNotebookSharePatientFromJSONTyped,
  PatientMedicineNotebookSharePatientToJSON,
  PatientMedicineNotebookShareScope,
  PatientMedicineNotebookShareScopeFromJSON,
  PatientMedicineNotebookShareScopeFromJSONTyped,
  PatientMedicineNotebookShareScopeToJSON,
} from "./";

export const PatientMedicineNotebookShareRequestTypename = "PatientMedicineNotebookShareRequest" as const;

/**
 *
 * @export
 * @interface PatientMedicineNotebookShareRequest
 */
export interface PatientMedicineNotebookShareRequest {
  /**
   *
   * @type {PatientMedicineNotebookSharePatient}
   * @memberof PatientMedicineNotebookShareRequest
   */
  fromPatient: PatientMedicineNotebookSharePatient;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebookShareRequest
   */
  id: string;
  /**
   *
   * @type {PatientMedicineNotebookShareScope}
   * @memberof PatientMedicineNotebookShareRequest
   */
  scope?: PatientMedicineNotebookShareScope;
  /**
   *
   * @type {PatientMedicineNotebookSharePatient}
   * @memberof PatientMedicineNotebookShareRequest
   */
  toPatient: PatientMedicineNotebookSharePatient;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineNotebookShareRequest
   */
  readonly __typename?: string;
}

export function PatientMedicineNotebookShareRequestFromJSON(json: any): PatientMedicineNotebookShareRequest {
  return PatientMedicineNotebookShareRequestFromJSONTyped(json, false);
}

export function PatientMedicineNotebookShareRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineNotebookShareRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineNotebookShareRequestTypename,

    fromPatient: PatientMedicineNotebookSharePatientFromJSON(json["from_patient"]),
    id: json["id"],
    scope: !exists(json, "scope") ? undefined : PatientMedicineNotebookShareScopeFromJSON(json["scope"]),
    toPatient: PatientMedicineNotebookSharePatientFromJSON(json["to_patient"]),
  };
}

export function PatientMedicineNotebookShareRequestToJSON(object?: PatientMedicineNotebookShareRequest | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    from_patient: PatientMedicineNotebookSharePatientToJSON(value.fromPatient),
    id: value.id,
    scope: PatientMedicineNotebookShareScopeToJSON(value.scope),
    to_patient: PatientMedicineNotebookSharePatientToJSON(value.toPatient),
  };
}
