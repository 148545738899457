import { Clinic } from "@mgdx/shared/src/models/Clinic";
import { Patient } from "@mgdx/shared/src/models/Patient";
import { Pharmacy } from "@mgdx/shared/src/models/Pharmacy";
import { createFirestoreDataConverter, TypeWithId } from "@mgdx-libs/firebase";

import { TalkMessageDocument } from "./TalkMessageDocument";

export type TalkRoomDocumentPatient = Pick<Patient, "id" | "firstName" | "lastName" | "kanaFirstName" | "kanaLastName">;
export type TalkRoomDocumentPharmacy = Pick<Pharmacy, "id" | "name">;
export type TalkRoomDocumentClinic = Pick<Clinic, "id" | "name">;

export type TalkRoomDocument = {
  patientId: number;
  clinicId: number;
  pharmacyId: number;
  counselingId: number;
  createdAt?: string;
  updatedAt?: string;
  timestamp?: number;
  patientUnreadMessageIds?: string[];
  patientUnreadMessageCount?: number;
  medicalUnreadMessageIds?: string[];
  medicalUnreadMessageCount?: number;
  lastMessage?: TalkMessageDocument;
  patient?: TalkRoomDocumentPatient;
  pharmacy?: TalkRoomDocumentPharmacy | null;
  clinic?: TalkRoomDocumentClinic | null;
};

export type TalkRoomDocumentWithId = TypeWithId<TalkRoomDocument>;

export const roomConverter = createFirestoreDataConverter<TalkRoomDocument>();
