/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicMeritTagsFeedTypename = "ClinicMeritTagsFeed" as const;

/**
 *
 * @export
 * @interface ClinicMeritTagsFeed
 */
export interface ClinicMeritTagsFeed {
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicMeritTagsFeed
   */
  normal?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicMeritTagsFeed
   */
  primary: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicMeritTagsFeed
   */
  readonly __typename?: string;
}

export function ClinicMeritTagsFeedFromJSON(json: any): ClinicMeritTagsFeed {
  return ClinicMeritTagsFeedFromJSONTyped(json, false);
}

export function ClinicMeritTagsFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicMeritTagsFeed {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicMeritTagsFeedTypename,

    normal: !exists(json, "normal") ? undefined : json["normal"],
    primary: json["primary"],
  };
}

export function ClinicMeritTagsFeedToJSON(object?: ClinicMeritTagsFeed | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    normal: value.normal,
    primary: value.primary,
  };
}
