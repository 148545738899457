import { getAccessToken } from "@mgdx-libs/firebase";

import { Configuration, RecaptchaApi } from "./bff/admin";
import { createFetchTimeout } from "./createFetchTimeout";
import requestHeaders from "./requestHeaders";

const recaptchaApi = new RecaptchaApi(
  new Configuration({
    basePath: process.env.MGDX_API_ADMIN_URL,
    credentials: "include",
    accessToken: getAccessToken,
    headers: requestHeaders,
    fetchApi: createFetchTimeout(60000),
  })
);

export default recaptchaApi;
