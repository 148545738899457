import { QolAccounts, QolAccountsFromJSON } from "@mgdx/shared/src/models/QolAccounts";
import { QolDxAccount, QolDxAccountFromJSON } from "@mgdx/shared/src/models/QolDxAccount";
import { logger } from "@mgdx-libs/logger";
import { createRequestAction } from "@mgdx-libs/redux-requests";

export const findQolAccount = createRequestAction<{ token: string }, Response, QolDxAccount | undefined>(
  "requests/qol-okusuri/findQolAccount",
  ({ token }, meta) => ({
    request: {
      promise: fetch(`${process.env.QOL_OKUSURI_API_URL}/dxid/line/dxids/myinfo`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    },
    meta: {
      ...meta,
      onSuccess: async (response, action, store) => {
        try {
          const json = await response.data.json();
          return { ...response, data: QolDxAccountFromJSON(json) };
        } catch {
          return { ...response, data: undefined };
        }
      },
      onError: (error, action, store): Error => {
        logger.debug("requests/qol-okusuri/findQolAccount %o", { error });
        return error;
      },
    },
  })
);

export const findQolAccounts = createRequestAction<{ token: string }, Response, QolAccounts | undefined>(
  "requests/qol-okusuri/findQolAccounts",
  ({ token }, meta) => ({
    request: {
      promise: fetch(`${process.env.QOL_OKUSURI_API_URL}/dxid/line/dxids/myinfo?include_children=1`, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    },
    meta: {
      ...meta,
      onSuccess: async (response, action, store) => {
        try {
          const json = await response.data.json();
          return { ...response, data: QolAccountsFromJSON(json) };
        } catch {
          return { ...response, data: undefined };
        }
      },
      onError: (error, action, store): Error => {
        logger.debug("requests/qol-okusuri/findQolAccounts %o", { error });
        return error;
      },
    },
  })
);
