/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { ClinicDoctor, ClinicDoctorFromJSON, ClinicDoctorFromJSONTyped, ClinicDoctorToJSON } from "./";

export const ClinicDoctorsTypename = "ClinicDoctors" as const;

/**
 *
 * @export
 * @interface ClinicDoctors
 */
export interface ClinicDoctors {
  /**
   *
   * @type {number}
   * @memberof ClinicDoctors
   */
  count?: number;
  /**
   *
   * @type {Array<ClinicDoctor>}
   * @memberof ClinicDoctors
   */
  doctors?: Array<ClinicDoctor>;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicDoctors
   */
  readonly __typename?: string;
}

export function ClinicDoctorsFromJSON(json: any): ClinicDoctors {
  return ClinicDoctorsFromJSONTyped(json, false);
}

export function ClinicDoctorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicDoctors {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicDoctorsTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    doctors: !exists(json, "doctors") ? undefined : (json["doctors"] as Array<any>).map(ClinicDoctorFromJSON),
  };
}

export function ClinicDoctorsToJSON(object?: ClinicDoctors | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    doctors: value.doctors === undefined ? undefined : (value.doctors as Array<any>).map(ClinicDoctorToJSON),
  };
}
