/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorPersonalHealthRecordTypename = "PharmacyCounselorPersonalHealthRecord" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorPersonalHealthRecord
 */
export interface PharmacyCounselorPersonalHealthRecord {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPersonalHealthRecord
   */
  authToken?: string;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorPersonalHealthRecord
   */
  permittedSharing?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorPersonalHealthRecord
   */
  readonly __typename?: string;
}

export function PharmacyCounselorPersonalHealthRecordFromJSON(json: any): PharmacyCounselorPersonalHealthRecord {
  return PharmacyCounselorPersonalHealthRecordFromJSONTyped(json, false);
}

export function PharmacyCounselorPersonalHealthRecordFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorPersonalHealthRecord {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorPersonalHealthRecordTypename,

    authToken: !exists(json, "auth_token") ? undefined : json["auth_token"],
    permittedSharing: !exists(json, "permitted_sharing") ? undefined : json["permitted_sharing"],
  };
}

export function PharmacyCounselorPersonalHealthRecordToJSON(
  object?: PharmacyCounselorPersonalHealthRecord | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    auth_token: value.authToken,
    permitted_sharing: value.permittedSharing,
  };
}
