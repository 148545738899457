/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientFamilyPharmacistQuestionnaireTypename = "PatientFamilyPharmacistQuestionnaire" as const;

/**
 *
 * @export
 * @interface PatientFamilyPharmacistQuestionnaire
 */
export interface PatientFamilyPharmacistQuestionnaire {
  /**
   *
   * @type {string}
   * @memberof PatientFamilyPharmacistQuestionnaire
   */
  answer: PatientFamilyPharmacistQuestionnaireAnswerEnum;
  /**
   *
   * @type {Date}
   * @memberof PatientFamilyPharmacistQuestionnaire
   */
  answeredTime: string;
  /**
   *
   * @type {number}
   * @memberof PatientFamilyPharmacistQuestionnaire
   */
  patientId?: number;
  /**
   *
   * @type {number}
   * @memberof PatientFamilyPharmacistQuestionnaire
   */
  pharmacyId?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientFamilyPharmacistQuestionnaire
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientFamilyPharmacistQuestionnaireAnswerEnum {
  WantingToHear = "wanting_to_hear",
  ActivelyLookingFor = "actively_looking_for",
  AlreadyRegistered = "already_registered",
  Uninteresting = "uninteresting",
}

export function PatientFamilyPharmacistQuestionnaireFromJSON(json: any): PatientFamilyPharmacistQuestionnaire {
  return PatientFamilyPharmacistQuestionnaireFromJSONTyped(json, false);
}

export function PatientFamilyPharmacistQuestionnaireFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientFamilyPharmacistQuestionnaire {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientFamilyPharmacistQuestionnaireTypename,

    answer: json["answer"],
    answeredTime: json["answered_time"],
    patientId: !exists(json, "patient_id") ? undefined : json["patient_id"],
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
  };
}

export function PatientFamilyPharmacistQuestionnaireToJSON(object?: PatientFamilyPharmacistQuestionnaire | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer: value.answer,
    answered_time: moment(value.answeredTime).tz("Asia/Tokyo").format(),
    patient_id: value.patientId,
    pharmacy_id: value.pharmacyId,
  };
}
