/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { ClinicExpense, ClinicExpenseFromJSON, ClinicExpenseFromJSONTyped, ClinicExpenseToJSON } from "./";

export const ClinicDepartmentTypename = "ClinicDepartment" as const;

/**
 *
 * @export
 * @interface ClinicDepartment
 */
export interface ClinicDepartment {
  /**
   *
   * @type {number}
   * @memberof ClinicDepartment
   */
  calendarDisplayDays?: number;
  /**
   *
   * @type {number}
   * @memberof ClinicDepartment
   */
  clinicId: number;
  /**
   *
   * @type {string}
   * @memberof ClinicDepartment
   */
  description?: string;
  /**
   *
   * @type {ClinicExpense}
   * @memberof ClinicDepartment
   */
  expense: ClinicExpense;
  /**
   *
   * @type {number}
   * @memberof ClinicDepartment
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ClinicDepartment
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof ClinicDepartment
   */
  requestableAfterMinutes?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicDepartment
   */
  symptomExample?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicDepartment
   */
  readonly __typename?: string;
}

export function ClinicDepartmentFromJSON(json: any): ClinicDepartment {
  return ClinicDepartmentFromJSONTyped(json, false);
}

export function ClinicDepartmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicDepartment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicDepartmentTypename,

    calendarDisplayDays: !exists(json, "calendar_display_days") ? undefined : json["calendar_display_days"],
    clinicId: json["clinic_id"],
    description: !exists(json, "description") ? undefined : json["description"],
    expense: ClinicExpenseFromJSON(json["expense"]),
    id: json["id"],
    name: json["name"],
    requestableAfterMinutes: !exists(json, "requestable_after_minutes") ? undefined : json["requestable_after_minutes"],
    symptomExample: !exists(json, "symptom_example") ? undefined : json["symptom_example"],
  };
}

export function ClinicDepartmentToJSON(object?: ClinicDepartment | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    calendar_display_days: value.calendarDisplayDays,
    clinic_id: value.clinicId,
    description: value.description,
    expense: ClinicExpenseToJSON(value.expense),
    id: value.id,
    name: value.name,
    requestable_after_minutes: value.requestableAfterMinutes,
    symptom_example: value.symptomExample,
  };
}
