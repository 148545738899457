/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicineNotebookSharePatient,
  PatientMedicineNotebookSharePatientFromJSON,
  PatientMedicineNotebookSharePatientFromJSONTyped,
  PatientMedicineNotebookSharePatientToJSON,
  PatientMedicineNotebookShareScope,
  PatientMedicineNotebookShareScopeFromJSON,
  PatientMedicineNotebookShareScopeFromJSONTyped,
  PatientMedicineNotebookShareScopeToJSON,
} from "./";

export const PatientMedicineNotebookShareTypename = "PatientMedicineNotebookShare" as const;

/**
 *
 * @export
 * @interface PatientMedicineNotebookShare
 */
export interface PatientMedicineNotebookShare {
  /**
   *
   * @type {PatientMedicineNotebookSharePatient}
   * @memberof PatientMedicineNotebookShare
   */
  fromPatient: PatientMedicineNotebookSharePatient;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebookShare
   */
  id: string;
  /**
   *
   * @type {PatientMedicineNotebookShareScope}
   * @memberof PatientMedicineNotebookShare
   */
  scope: PatientMedicineNotebookShareScope;
  /**
   *
   * @type {PatientMedicineNotebookSharePatient}
   * @memberof PatientMedicineNotebookShare
   */
  toPatient: PatientMedicineNotebookSharePatient;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineNotebookShare
   */
  readonly __typename?: string;
}

export function PatientMedicineNotebookShareFromJSON(json: any): PatientMedicineNotebookShare {
  return PatientMedicineNotebookShareFromJSONTyped(json, false);
}

export function PatientMedicineNotebookShareFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineNotebookShare {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineNotebookShareTypename,

    fromPatient: PatientMedicineNotebookSharePatientFromJSON(json["from_patient"]),
    id: json["id"],
    scope: PatientMedicineNotebookShareScopeFromJSON(json["scope"]),
    toPatient: PatientMedicineNotebookSharePatientFromJSON(json["to_patient"]),
  };
}

export function PatientMedicineNotebookShareToJSON(object?: PatientMedicineNotebookShare | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    from_patient: PatientMedicineNotebookSharePatientToJSON(value.fromPatient),
    id: value.id,
    scope: PatientMedicineNotebookShareScopeToJSON(value.scope),
    to_patient: PatientMedicineNotebookSharePatientToJSON(value.toPatient),
  };
}
