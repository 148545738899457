// extracted by mini-css-extract-plugin
export var cameraButton = "TalkForm-module--cameraButton--f8ba7";
export var fields = "TalkForm-module--fields--650a5";
export var form = "TalkForm-module--form--cf992";
export var hasFile = "TalkForm-module--hasFile--0f62b";
export var hidden = "TalkForm-module--hidden--96086";
export var inputMessage = "TalkForm-module--inputMessage--7cb0a";
export var isEntering = "TalkForm-module--isEntering--4f9be";
export var pictureButton = "TalkForm-module--pictureButton--60192";
export var previewFile = "TalkForm-module--previewFile--2f089";
export var previewImage = "TalkForm-module--previewImage--cc16c";
export var sendButton = "TalkForm-module--sendButton--c35f6";