/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ChatterTextMessagePatient,
  ChatterTextMessagePatientFromJSON,
  ChatterTextMessagePatientFromJSONTyped,
  ChatterTextMessagePatientToJSON,
} from "./";

export const ChatterTextMessageTypename = "ChatterTextMessage" as const;

/**
 *
 * @export
 * @interface ChatterTextMessage
 */
export interface ChatterTextMessage {
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessage
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessage
   */
  message: string;
  /**
   *
   * @type {ChatterTextMessagePatient}
   * @memberof ChatterTextMessage
   */
  patient: ChatterTextMessagePatient;
  /**
   *
   * @type {number}
   * @memberof ChatterTextMessage
   */
  pharmacyId?: number;
  /**
   *
   * @type {Date}
   * @memberof ChatterTextMessage
   */
  sendTime: string;
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessage
   */
  status?: ChatterTextMessageStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterTextMessage
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ChatterTextMessageStatusEnum {
  Registered = "registered",
  Sent = "sent",
  Canceled = "canceled",
}

export function ChatterTextMessageFromJSON(json: any): ChatterTextMessage {
  return ChatterTextMessageFromJSONTyped(json, false);
}

export function ChatterTextMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatterTextMessage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterTextMessageTypename,

    id: !exists(json, "id") ? undefined : json["id"],
    message: json["message"],
    patient: ChatterTextMessagePatientFromJSON(json["patient"]),
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
    sendTime: json["send_time"],
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function ChatterTextMessageToJSON(object?: ChatterTextMessage | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    message: value.message,
    patient: ChatterTextMessagePatientToJSON(value.patient),
    pharmacy_id: value.pharmacyId,
    send_time: moment(value.sendTime).tz("Asia/Tokyo").format(),
    status: value.status,
  };
}
