import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const CloseIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M5.28252 5.28288C4.89199 5.67341 4.89199 6.30657 5.28252 6.6971L10.5858 12.0004L5.28252 17.3037C4.89199 17.6942 4.89199 18.3274 5.28252 18.7179C5.67304 19.1084 6.30621 19.1084 6.69673 18.7179L12 13.4146L17.3033 18.7179C17.6939 19.1084 18.327 19.1084 18.7175 18.7179C19.1081 18.3274 19.1081 17.6942 18.7175 17.3037L13.4142 12.0004L18.7175 6.6971C19.1081 6.30657 19.1081 5.67341 18.7175 5.28288C18.327 4.89236 17.6939 4.89236 17.3033 5.28288L12 10.5862L6.69673 5.28288C6.30621 4.89236 5.67304 4.89236 5.28252 5.28288Z" />
    </svg>
  );
};

export default CloseIcon;
