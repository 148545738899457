/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { DeliveryAddress, DeliveryAddressFromJSON, DeliveryAddressFromJSONTyped, DeliveryAddressToJSON } from "./";

export const DeliveryClientTypename = "DeliveryClient" as const;

/**
 *
 * @export
 * @interface DeliveryClient
 */
export interface DeliveryClient {
  /**
   *
   * @type {DeliveryAddress}
   * @memberof DeliveryClient
   */
  address: DeliveryAddress;
  /**
   *
   * @type {string}
   * @memberof DeliveryClient
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryClient
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryClient
   */
  staffName?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryClient
   */
  tel?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryClient
   */
  readonly __typename?: string;
}

export function DeliveryClientFromJSON(json: any): DeliveryClient {
  return DeliveryClientFromJSONTyped(json, false);
}

export function DeliveryClientFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryClient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryClientTypename,

    address: DeliveryAddressFromJSON(json["address"]),
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    name: !exists(json, "name") ? undefined : json["name"],
    staffName: !exists(json, "staff_name") ? undefined : json["staff_name"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
  };
}

export function DeliveryClientToJSON(object?: DeliveryClient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address: DeliveryAddressToJSON(value.address),
    first_name: value.firstName,
    name: value.name,
    staff_name: value.staffName,
    tel: value.tel,
  };
}
