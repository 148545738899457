/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PayerCreditCardRegisterRequestTypename = "PayerCreditCardRegisterRequest" as const;

/**
 *
 * @export
 * @interface PayerCreditCardRegisterRequest
 */
export interface PayerCreditCardRegisterRequest {
  /**
   *
   * @type {boolean}
   * @memberof PayerCreditCardRegisterRequest
   */
  defaultCard: boolean;
  /**
   *
   * @type {string}
   * @memberof PayerCreditCardRegisterRequest
   */
  token: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PayerCreditCardRegisterRequest
   */
  readonly __typename?: string;
}

export function PayerCreditCardRegisterRequestFromJSON(json: any): PayerCreditCardRegisterRequest {
  return PayerCreditCardRegisterRequestFromJSONTyped(json, false);
}

export function PayerCreditCardRegisterRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayerCreditCardRegisterRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PayerCreditCardRegisterRequestTypename,

    defaultCard: json["default_card"],
    token: json["token"],
  };
}

export function PayerCreditCardRegisterRequestToJSON(object?: PayerCreditCardRegisterRequest | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    default_card: value.defaultCard,
    token: value.token,
  };
}
