/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyControlPanelSound,
  PharmacyControlPanelSoundFromJSON,
  PharmacyControlPanelSoundFromJSONTyped,
  PharmacyControlPanelSoundToJSON,
} from "./";

export const PharmacyControlPanelSoundsTypename = "PharmacyControlPanelSounds" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanelSounds
 */
export interface PharmacyControlPanelSounds {
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  billingCompletedOrder?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  cancel?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  finishOrder?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  followUp?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  interview?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  offline?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  online?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  request?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  unread?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  unreadRequestMedicineNotebook?: PharmacyControlPanelSound;
  /**
   *
   * @type {PharmacyControlPanelSound}
   * @memberof PharmacyControlPanelSounds
   */
  unreadTalk?: PharmacyControlPanelSound;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanelSounds
   */
  readonly __typename?: string;
}

export function PharmacyControlPanelSoundsFromJSON(json: any): PharmacyControlPanelSounds {
  return PharmacyControlPanelSoundsFromJSONTyped(json, false);
}

export function PharmacyControlPanelSoundsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyControlPanelSounds {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelSoundsTypename,

    billingCompletedOrder: !exists(json, "billing_completed_order")
      ? undefined
      : PharmacyControlPanelSoundFromJSON(json["billing_completed_order"]),
    cancel: !exists(json, "cancel") ? undefined : PharmacyControlPanelSoundFromJSON(json["cancel"]),
    finishOrder: !exists(json, "finish_order") ? undefined : PharmacyControlPanelSoundFromJSON(json["finish_order"]),
    followUp: !exists(json, "follow_up") ? undefined : PharmacyControlPanelSoundFromJSON(json["follow_up"]),
    interview: !exists(json, "interview") ? undefined : PharmacyControlPanelSoundFromJSON(json["interview"]),
    offline: !exists(json, "offline") ? undefined : PharmacyControlPanelSoundFromJSON(json["offline"]),
    online: !exists(json, "online") ? undefined : PharmacyControlPanelSoundFromJSON(json["online"]),
    request: !exists(json, "request") ? undefined : PharmacyControlPanelSoundFromJSON(json["request"]),
    unread: !exists(json, "unread") ? undefined : PharmacyControlPanelSoundFromJSON(json["unread"]),
    unreadRequestMedicineNotebook: !exists(json, "unread_request_medicine_notebook")
      ? undefined
      : PharmacyControlPanelSoundFromJSON(json["unread_request_medicine_notebook"]),
    unreadTalk: !exists(json, "unread_talk") ? undefined : PharmacyControlPanelSoundFromJSON(json["unread_talk"]),
  };
}

export function PharmacyControlPanelSoundsToJSON(object?: PharmacyControlPanelSounds | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    billing_completed_order: PharmacyControlPanelSoundToJSON(value.billingCompletedOrder),
    cancel: PharmacyControlPanelSoundToJSON(value.cancel),
    finish_order: PharmacyControlPanelSoundToJSON(value.finishOrder),
    follow_up: PharmacyControlPanelSoundToJSON(value.followUp),
    interview: PharmacyControlPanelSoundToJSON(value.interview),
    offline: PharmacyControlPanelSoundToJSON(value.offline),
    online: PharmacyControlPanelSoundToJSON(value.online),
    request: PharmacyControlPanelSoundToJSON(value.request),
    unread: PharmacyControlPanelSoundToJSON(value.unread),
    unread_request_medicine_notebook: PharmacyControlPanelSoundToJSON(value.unreadRequestMedicineNotebook),
    unread_talk: PharmacyControlPanelSoundToJSON(value.unreadTalk),
  };
}
