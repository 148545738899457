/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicCounselorRequestTimeTypename = "ClinicCounselorRequestTime" as const;

/**
 *
 * @export
 * @interface ClinicCounselorRequestTime
 */
export interface ClinicCounselorRequestTime {
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorRequestTime
   */
  date?: string;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorRequestTime
   */
  slotNumber?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorRequestTime
   */
  readonly __typename?: string;
}

export function ClinicCounselorRequestTimeFromJSON(json: any): ClinicCounselorRequestTime {
  return ClinicCounselorRequestTimeFromJSONTyped(json, false);
}

export function ClinicCounselorRequestTimeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClinicCounselorRequestTime {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorRequestTimeTypename,

    date: !exists(json, "date") ? undefined : json["date"],
    slotNumber: !exists(json, "slot_number") ? undefined : json["slot_number"],
  };
}

export function ClinicCounselorRequestTimeToJSON(object?: ClinicCounselorRequestTime | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date: value.date,
    slot_number: value.slotNumber,
  };
}
