import React from "react";

import * as styles from "./TalkGuide.module.css";

export type Props = {
  message: string;
};

const TalkGuide = ({ message }: Props) => {
  return (
    <div className={styles.guideBox}>
      <div className={styles.guideMessage}>{message}</div>
    </div>
  );
};

export default TalkGuide;
