import { createQueryKeyStore } from "@lukemorales/query-key-factory";

import type { UseAllPharmaciesQueryArgs } from "../hooks/pharmacy/useAllPharmaciesQuery";
import type { UsePharmaciesByChainQueryArgs } from "../hooks/pharmacy/usePharmaciesByChainQuery";
import type { UseSearchPharmaciesQueryArgs } from "../hooks/pharmacy/useSearchPharmaciesQuery";

export const pharmacyKeys = createQueryKeyStore({
  all: {
    list: (args: UseAllPharmaciesQueryArgs) => [args],
  },
  byChain: {
    list: (args: UsePharmaciesByChainQueryArgs) => [args],
  },
  search: {
    list: (args: UseSearchPharmaciesQueryArgs) => [args],
  },
});
