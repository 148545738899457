/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatientMission,
  PatientMissionFromJSON,
  PatientMissionToJSON,
  PatientMissionPeriod,
  PatientMissionPeriodFromJSON,
  PatientMissionPeriodToJSON,
  PatientMissionRegistrable,
  PatientMissionRegistrableFromJSON,
  PatientMissionRegistrableToJSON,
} from "../models";

export interface GetMissionsRequest {
  chainId?: number;
}

export interface PostMissionRequest {
  patientMissionRegistrable: PatientMissionRegistrable;
}

/**
 *
 */
export class MissionApi extends runtime.BaseAPI {
  /**
   * Get mission period
   */
  async getMissionPeriodRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PatientMissionPeriod>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/mission-period`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMissionPeriodFromJSON(jsonValue));
  }

  /**
   * Get mission period
   */
  async getMissionPeriod(initOverrides?: RequestInit): Promise<PatientMissionPeriod> {
    return promiseRetry(
      (retry) =>
        this.getMissionPeriodRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get missions
   */
  async getMissionsRaw(
    requestParameters: GetMissionsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PatientMission>>> {
    const queryParameters: any = {};

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/missions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientMissionFromJSON));
  }

  /**
   * Get missions
   */
  async getMissions(
    requestParameters: GetMissionsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PatientMission>> {
    return promiseRetry(
      (retry) =>
        this.getMissionsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register mission
   */
  async postMissionRaw(
    requestParameters: PostMissionRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMission>> {
    if (
      requestParameters.patientMissionRegistrable === null ||
      requestParameters.patientMissionRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "patientMissionRegistrable",
        "Required parameter requestParameters.patientMissionRegistrable was null or undefined when calling postMission."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/missions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PatientMissionRegistrableToJSON(requestParameters.patientMissionRegistrable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMissionFromJSON(jsonValue));
  }

  /**
   * Register mission
   */
  async postMission(requestParameters: PostMissionRequest, initOverrides?: RequestInit): Promise<PatientMission> {
    return promiseRetry(
      (retry) =>
        this.postMissionRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
