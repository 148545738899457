/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ChatterAttendeeTypename = "ChatterAttendee" as const;

/**
 *
 * @export
 * @interface ChatterAttendee
 */
export interface ChatterAttendee {
  /**
   *
   * @type {string}
   * @memberof ChatterAttendee
   */
  attendeeId?: string;
  /**
   *
   * @type {string}
   * @memberof ChatterAttendee
   */
  joinToken?: string;
  /**
   *
   * @type {string}
   * @memberof ChatterAttendee
   */
  status: ChatterAttendeeStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterAttendee
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ChatterAttendeeStatusEnum {
  Online = "online",
  Offline = "offline",
}

export function ChatterAttendeeFromJSON(json: any): ChatterAttendee {
  return ChatterAttendeeFromJSONTyped(json, false);
}

export function ChatterAttendeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatterAttendee {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterAttendeeTypename,

    attendeeId: !exists(json, "attendee_id") ? undefined : json["attendee_id"],
    joinToken: !exists(json, "join_token") ? undefined : json["join_token"],
    status: json["status"],
  };
}

export function ChatterAttendeeToJSON(object?: ChatterAttendee | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    attendee_id: value.attendeeId,
    join_token: value.joinToken,
    status: value.status,
  };
}
