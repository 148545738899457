/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostVerifyTokenResponseTypename = "PostVerifyTokenResponse" as const;

/**
 *
 * @export
 * @interface PostVerifyTokenResponse
 */
export interface PostVerifyTokenResponse {
  /**
   *
   * @type {boolean}
   * @memberof PostVerifyTokenResponse
   */
  valid?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostVerifyTokenResponse
   */
  readonly __typename?: string;
}

export function PostVerifyTokenResponseFromJSON(json: any): PostVerifyTokenResponse {
  return PostVerifyTokenResponseFromJSONTyped(json, false);
}

export function PostVerifyTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostVerifyTokenResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostVerifyTokenResponseTypename,

    valid: !exists(json, "valid") ? undefined : json["valid"],
  };
}

export function PostVerifyTokenResponseToJSON(object?: PostVerifyTokenResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    valid: value.valid,
  };
}
