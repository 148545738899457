import clsx from "clsx";
import React from "react";

import CircularProgress, { Props as CircularProgressProps } from "../CircularProgress";
import * as styles from "./Preloader.module.css";

export const variants = ["float", "relative", "center"] as const;

export type Props = {
  loading?: boolean;
  label?: string;
  variant?: (typeof variants)[number];
  className?: string;
} & Pick<CircularProgressProps, "size">;

const Preloader = ({ loading, label = "読み込み中", variant = "float", className, size = "l" }: Props) => {
  return (
    <div
      className={clsx(
        styles.preloader,
        variant === "float" && styles.float,
        variant === "relative" && styles.relative,
        variant === "center" && styles.center,
        loading && styles.loading,
        className
      )}
    >
      <CircularProgress size={size} variant="secondary" />
      <div className={clsx(styles.preloaderText, size === "m" && styles.sizeM)}>{label}</div>
    </div>
  );
};

export default Preloader;
