import CameraIcon from "@mgdx/assets/icons/CameraIcon";
import DeleteIcon from "@mgdx/assets/icons/DeleteIcon";
import FileImageIcon from "@mgdx/assets/icons/FileImageIcon";
import SendIcon from "@mgdx/assets/icons/SendIcon";
import { useIsPCPlatform } from "@mgdx/shared/src/hooks/useIsPCPlatform";
import { InputTextareaFieldMin } from "@mgdx/ui/components/InputTextareaFieldMin";
import { OvalButton } from "@mgdx/ui/components/OvalButton";
import clsx from "clsx";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useCallback, useState } from "react";

import InputFile from "./InputFile";
import readFile from "./readFile";
import * as styles from "./TalkForm.module.css";

export type TalkFormValues = {
  message: string;
  file: File | undefined;
};

const initialValues: TalkFormValues = {
  message: "",
  file: undefined,
};

export type TalkFormProps = {
  onSubmit: (values: TalkFormValues) => void | Promise<void>;
};

export const TalkForm = ({ onSubmit }: TalkFormProps) => {
  const [isEntering, setIsEntering] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [key, setKey] = useState(0);
  const isPCPlatform = useIsPCPlatform();
  const resetInputFile = useCallback(() => {
    setKey((x) => x + 1);
  }, []);

  return (
    <Formik<TalkFormValues>
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);

        await onSubmit(values);

        setSubmitting(false);
        resetForm();
        resetInputFile();
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form className={styles.form}>
          <div className={clsx(styles.fields, isEntering && styles.isEntering, values.file && styles.hasFile)}>
            <div className={clsx(styles.cameraButton, isPCPlatform && styles.hidden)}>
              <Field name="file">
                {({ field: { onChange, value, ...field }, meta, form }: FieldProps) => (
                  <InputFile
                    {...field}
                    key={`camera-${key}`}
                    accept={{ "image/*": [] }}
                    capture={true}
                    icon={<CameraIcon />}
                    title="写真を撮る"
                    aria-label="写真を撮る"
                    disabled={isSubmitting}
                    onChangeFile={async (file: File) => {
                      setImageSrc(await readFile(file));
                      setFieldValue("file", file);
                    }}
                  />
                )}
              </Field>
            </div>

            <div className={styles.pictureButton}>
              <Field name="file">
                {({ field: { onChange, value, ...field }, meta, form }: FieldProps) => (
                  <InputFile
                    {...field}
                    key={`picture-${key}`}
                    accept={{ "image/*": [] }}
                    icon={<FileImageIcon />}
                    title="写真を添付する"
                    aria-label="写真を添付する"
                    disabled={isSubmitting}
                    onChangeFile={async (file: File) => {
                      setImageSrc(await readFile(file));
                      setFieldValue("file", file);
                    }}
                  />
                )}
              </Field>
            </div>

            <div className={styles.previewFile}>
              <img src={imageSrc} alt="画像" className={styles.previewImage} />
              <OvalButton
                type="normal"
                size="s"
                aria-label="削除する"
                variant="key-weak"
                onClick={() => {
                  setImageSrc("");
                  setFieldValue("file", undefined);
                  resetInputFile();
                }}
                data-testid="delete-button"
              >
                <DeleteIcon />
              </OvalButton>
            </div>

            <div className={styles.inputMessage}>
              <Field name="message">
                {({ field }: FieldProps): React.ReactNode => (
                  <InputTextareaFieldMin
                    {...field}
                    key={`message-${key}`}
                    aria-label="メッセージ"
                    disabled={isSubmitting}
                    placeholder="メッセージ入力"
                    noMessageArea={true}
                    rows={1}
                    onFocus={() => {
                      setIsEntering(true);
                    }}
                    onBlur={() => setIsEntering(false)}
                    data-testid="talk-message-field"
                  />
                )}
              </Field>
            </div>

            <div className={styles.sendButton}>
              <OvalButton
                type="submit"
                size="s"
                aria-label="送信する"
                variant="key-weak"
                disabled={isSubmitting || (!values.file && !values.message)}
                data-testid="submit-button"
              >
                <SendIcon />
              </OvalButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
