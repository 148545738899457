/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicCounselorDoctorTypename = "ClinicCounselorDoctor" as const;

/**
 *
 * @export
 * @interface ClinicCounselorDoctor
 */
export interface ClinicCounselorDoctor {
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorDoctor
   */
  clinicEmail: string;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorDoctor
   */
  clinicId: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorDoctor
   */
  clinicName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorDoctor
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorDoctor
   */
  firstName: string;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorDoctor
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorDoctor
   */
  lastName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorDoctor
   */
  readonly __typename?: string;
}

export function ClinicCounselorDoctorFromJSON(json: any): ClinicCounselorDoctor {
  return ClinicCounselorDoctorFromJSONTyped(json, false);
}

export function ClinicCounselorDoctorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicCounselorDoctor {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorDoctorTypename,

    clinicEmail: json["clinic_email"],
    clinicId: json["clinic_id"],
    clinicName: json["clinic_name"],
    email: !exists(json, "email") ? undefined : json["email"],
    firstName: json["first_name"],
    id: json["id"],
    lastName: json["last_name"],
  };
}

export function ClinicCounselorDoctorToJSON(object?: ClinicCounselorDoctor | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    clinic_email: value.clinicEmail,
    clinic_id: value.clinicId,
    clinic_name: value.clinicName,
    email: value.email,
    first_name: value.firstName,
    id: value.id,
    last_name: value.lastName,
  };
}
