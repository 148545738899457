/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyFollowUpNotificationPatternUpdatableTypename =
  "PharmacyFollowUpNotificationPatternUpdatable" as const;

/**
 *
 * @export
 * @interface PharmacyFollowUpNotificationPatternUpdatable
 */
export interface PharmacyFollowUpNotificationPatternUpdatable {
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPatternUpdatable
   */
  messageTemplate?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPatternUpdatable
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpNotificationPatternUpdatable
   */
  notificationDays?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPatternUpdatable
   */
  notificationTime?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpNotificationPatternUpdatable
   */
  orderNumber?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpNotificationPatternUpdatable
   */
  questionnairePatternId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPatternUpdatable
   */
  sendMethod?: PharmacyFollowUpNotificationPatternUpdatableSendMethodEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyFollowUpNotificationPatternUpdatable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyFollowUpNotificationPatternUpdatableSendMethodEnum {
  Sms = "sms",
  Line = "line",
}

export function PharmacyFollowUpNotificationPatternUpdatableFromJSON(
  json: any
): PharmacyFollowUpNotificationPatternUpdatable {
  return PharmacyFollowUpNotificationPatternUpdatableFromJSONTyped(json, false);
}

export function PharmacyFollowUpNotificationPatternUpdatableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyFollowUpNotificationPatternUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyFollowUpNotificationPatternUpdatableTypename,

    messageTemplate: !exists(json, "message_template") ? undefined : json["message_template"],
    name: !exists(json, "name") ? undefined : json["name"],
    notificationDays: !exists(json, "notification_days") ? undefined : json["notification_days"],
    notificationTime: !exists(json, "notification_time") ? undefined : json["notification_time"],
    orderNumber: !exists(json, "order_number") ? undefined : json["order_number"],
    questionnairePatternId: !exists(json, "questionnaire_pattern_id") ? undefined : json["questionnaire_pattern_id"],
    sendMethod: !exists(json, "send_method") ? undefined : json["send_method"],
  };
}

export function PharmacyFollowUpNotificationPatternUpdatableToJSON(
  object?: PharmacyFollowUpNotificationPatternUpdatable | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    message_template: value.messageTemplate,
    name: value.name,
    notification_days: value.notificationDays,
    notification_time: value.notificationTime,
    order_number: value.orderNumber,
    questionnaire_pattern_id: value.questionnairePatternId,
    send_method: value.sendMethod,
  };
}
