/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutDispenseRecordHiddenRequestBodyTypename = "PutDispenseRecordHiddenRequestBody" as const;

/**
 *
 * @export
 * @interface PutDispenseRecordHiddenRequestBody
 */
export interface PutDispenseRecordHiddenRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof PutDispenseRecordHiddenRequestBody
   */
  hidden: boolean;
  /**
   * medicine_idとimage_idのどちらかが必須
   * @type {number}
   * @memberof PutDispenseRecordHiddenRequestBody
   */
  imageId?: number;
  /**
   * medicine_idとimage_idのどちらかが必須
   * @type {number}
   * @memberof PutDispenseRecordHiddenRequestBody
   */
  medicineId?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutDispenseRecordHiddenRequestBody
   */
  readonly __typename?: string;
}

export function PutDispenseRecordHiddenRequestBodyFromJSON(json: any): PutDispenseRecordHiddenRequestBody {
  return PutDispenseRecordHiddenRequestBodyFromJSONTyped(json, false);
}

export function PutDispenseRecordHiddenRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDispenseRecordHiddenRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutDispenseRecordHiddenRequestBodyTypename,

    hidden: json["hidden"],
    imageId: !exists(json, "image_id") ? undefined : json["image_id"],
    medicineId: !exists(json, "medicine_id") ? undefined : json["medicine_id"],
  };
}

export function PutDispenseRecordHiddenRequestBodyToJSON(object?: PutDispenseRecordHiddenRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    hidden: value.hidden,
    image_id: value.imageId,
    medicine_id: value.medicineId,
  };
}
