// extracted by mini-css-extract-plugin
export var base = "OutlinedButton-module--base--6d7ae";
export var button = "OutlinedButton-module--button--b500d";
export var disabled = "OutlinedButton-module--disabled--fb466";
export var fitContent = "OutlinedButton-module--fitContent--ae953";
export var fullWidth = "OutlinedButton-module--fullWidth--5c41a";
export var key = "OutlinedButton-module--key--dcbf6";
export var link = "OutlinedButton-module--link--3812e";
export var multiline = "OutlinedButton-module--multiline--17ac9";
export var sizeL = "OutlinedButton-module--sizeL--34595";
export var sizeM = "OutlinedButton-module--sizeM--0d0de";
export var sizeS = "OutlinedButton-module--sizeS--f2507";
export var weak = "OutlinedButton-module--weak--c167b";