/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ClinicDepartment,
  ClinicDepartmentFromJSON,
  ClinicDepartmentToJSON,
  ClinicDepartmentRegistrable,
  ClinicDepartmentRegistrableFromJSON,
  ClinicDepartmentRegistrableToJSON,
  ClinicDepartmentUpdatable,
  ClinicDepartmentUpdatableFromJSON,
  ClinicDepartmentUpdatableToJSON,
} from "../models";

export interface PatchDepartmentRequest {
  clinicId: number;
  departmentId: number;
  clinicDepartmentUpdatable: ClinicDepartmentUpdatable;
}

export interface PostDepartmentRequest {
  clinicId: number;
  clinicDepartmentRegistrable: Array<ClinicDepartmentRegistrable>;
}

/**
 *
 */
export class DepartmentApi extends runtime.BaseAPI {
  /**
   * Update the Clinic department
   */
  async patchDepartmentRaw(
    requestParameters: PatchDepartmentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ClinicDepartment>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling patchDepartment."
      );
    }

    if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
      throw new runtime.RequiredError(
        "departmentId",
        "Required parameter requestParameters.departmentId was null or undefined when calling patchDepartment."
      );
    }

    if (
      requestParameters.clinicDepartmentUpdatable === null ||
      requestParameters.clinicDepartmentUpdatable === undefined
    ) {
      throw new runtime.RequiredError(
        "clinicDepartmentUpdatable",
        "Required parameter requestParameters.clinicDepartmentUpdatable was null or undefined when calling patchDepartment."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments/{department_id}`
          .replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId)))
          .replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ClinicDepartmentUpdatableToJSON(requestParameters.clinicDepartmentUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ClinicDepartmentFromJSON(jsonValue));
  }

  /**
   * Update the Clinic department
   */
  async patchDepartment(
    requestParameters: PatchDepartmentRequest,
    initOverrides?: RequestInit
  ): Promise<ClinicDepartment> {
    return promiseRetry(
      (retry) =>
        this.patchDepartmentRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register a Clinic department
   */
  async postDepartmentRaw(
    requestParameters: PostDepartmentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ClinicDepartment>>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling postDepartment."
      );
    }

    if (
      requestParameters.clinicDepartmentRegistrable === null ||
      requestParameters.clinicDepartmentRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "clinicDepartmentRegistrable",
        "Required parameter requestParameters.clinicDepartmentRegistrable was null or undefined when calling postDepartment."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments`.replace(
          `{${"clinic_id"}}`,
          encodeURIComponent(String(requestParameters.clinicId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.clinicDepartmentRegistrable.map(ClinicDepartmentRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicDepartmentFromJSON));
  }

  /**
   * Register a Clinic department
   */
  async postDepartment(
    requestParameters: PostDepartmentRequest,
    initOverrides?: RequestInit
  ): Promise<Array<ClinicDepartment>> {
    return promiseRetry(
      (retry) =>
        this.postDepartmentRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
