/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const DeliveryCounselingTypename = "DeliveryCounseling" as const;

/**
 *
 * @export
 * @interface DeliveryCounseling
 */
export interface DeliveryCounseling {
  /**
   *
   * @type {number}
   * @memberof DeliveryCounseling
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof DeliveryCounseling
   */
  requestToken?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryCounseling
   */
  requestType: DeliveryCounselingRequestTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryCounseling
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DeliveryCounselingRequestTypeEnum {
  Web = "web",
  Line = "line",
}

export function DeliveryCounselingFromJSON(json: any): DeliveryCounseling {
  return DeliveryCounselingFromJSONTyped(json, false);
}

export function DeliveryCounselingFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryCounseling {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryCounselingTypename,

    id: json["id"],
    requestToken: !exists(json, "request_token") ? undefined : json["request_token"],
    requestType: json["request_type"],
  };
}

export function DeliveryCounselingToJSON(object?: DeliveryCounseling | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    request_token: value.requestToken,
    request_type: value.requestType,
  };
}
