/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutMemoRequestBodyTypename = "PutMemoRequestBody" as const;

/**
 *
 * @export
 * @interface PutMemoRequestBody
 */
export interface PutMemoRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutMemoRequestBody
   */
  content?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutMemoRequestBody
   */
  readonly __typename?: string;
}

export function PutMemoRequestBodyFromJSON(json: any): PutMemoRequestBody {
  return PutMemoRequestBodyFromJSONTyped(json, false);
}

export function PutMemoRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutMemoRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutMemoRequestBodyTypename,

    content: !exists(json, "content") ? undefined : json["content"],
  };
}

export function PutMemoRequestBodyToJSON(object?: PutMemoRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    content: value.content,
  };
}
