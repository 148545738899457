/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutOperatorPasswordRequestBodyTypename = "PutOperatorPasswordRequestBody" as const;

/**
 *
 * @export
 * @interface PutOperatorPasswordRequestBody
 */
export interface PutOperatorPasswordRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutOperatorPasswordRequestBody
   */
  password: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutOperatorPasswordRequestBody
   */
  readonly __typename?: string;
}

export function PutOperatorPasswordRequestBodyFromJSON(json: any): PutOperatorPasswordRequestBody {
  return PutOperatorPasswordRequestBodyFromJSONTyped(json, false);
}

export function PutOperatorPasswordRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutOperatorPasswordRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutOperatorPasswordRequestBodyTypename,

    password: json["password"],
  };
}

export function PutOperatorPasswordRequestBodyToJSON(object?: PutOperatorPasswordRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    password: value.password,
  };
}
