import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const ArrowBackBriefIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M191.6275,108.015 C192.1175,108.505 192.9075,108.505 193.3975,108.015 L200.7075,100.705 C201.0975,100.315 201.0975,99.685 200.7075,99.295 L193.3975,91.985 C192.9075,91.495 192.1175,91.495 191.6275,91.985 C191.1375,92.475 191.1375,93.265 191.6275,93.755 L197.8675,100.005 L191.6175,106.255 C191.1375,106.735 191.1375,107.535 191.6275,108.015 Z"
        transform="matrix(-1 0 0 1 208.259 -88)"
      />
    </svg>
  );
};

export default ArrowBackBriefIcon;
