/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ChatterTextMessagePatient,
  ChatterTextMessagePatientFromJSON,
  ChatterTextMessagePatientFromJSONTyped,
  ChatterTextMessagePatientToJSON,
} from "./";

export const ChatterTextMessageUpdatableTypename = "ChatterTextMessageUpdatable" as const;

/**
 *
 * @export
 * @interface ChatterTextMessageUpdatable
 */
export interface ChatterTextMessageUpdatable {
  /**
   *
   * @type {string}
   * @memberof ChatterTextMessageUpdatable
   */
  message?: string;
  /**
   *
   * @type {ChatterTextMessagePatient}
   * @memberof ChatterTextMessageUpdatable
   */
  patient?: ChatterTextMessagePatient;
  /**
   *
   * @type {Date}
   * @memberof ChatterTextMessageUpdatable
   */
  sendTime?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterTextMessageUpdatable
   */
  readonly __typename?: string;
}

export function ChatterTextMessageUpdatableFromJSON(json: any): ChatterTextMessageUpdatable {
  return ChatterTextMessageUpdatableFromJSONTyped(json, false);
}

export function ChatterTextMessageUpdatableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChatterTextMessageUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterTextMessageUpdatableTypename,

    message: !exists(json, "message") ? undefined : json["message"],
    patient: !exists(json, "patient") ? undefined : ChatterTextMessagePatientFromJSON(json["patient"]),
    sendTime:
      !exists(json, "send_time") ||
      (typeof json["send_time"] === "string" && json["send_time"].substring(0, 4) === "0001")
        ? undefined
        : json["send_time"],
  };
}

export function ChatterTextMessageUpdatableToJSON(object?: ChatterTextMessageUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    message: value.message,
    patient: ChatterTextMessagePatientToJSON(value.patient),
    send_time: value.sendTime === undefined ? undefined : moment(value.sendTime).tz("Asia/Tokyo").format(),
  };
}
