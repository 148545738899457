import { createAction, createReducer } from "@reduxjs/toolkit";

export type TalkState = {
  isEnabledTalk: boolean;
  isOpenTalk: boolean;
  selectedTalkRoomId?: string;
};

const initialState: TalkState = {
  isEnabledTalk: false,
  isOpenTalk: false,
  selectedTalkRoomId: undefined,
};

export const enableTalk = createAction("@@talk/enableTalk");
export const disableTalk = createAction("@@talk/disableTalk");
export const openTalk = createAction("@@talk/openTalk");
export const closeTalk = createAction("@@talk/closeTalk");
export const setSelectedTalkRoomId = createAction<string>("@@talk/setSelectedTalkRoomId");
export const clearSelectedTalkRoomId = createAction("@@talk/clearSelectedTalkRoomId");
export const clearTalkState = createAction("@@talk/clearState");

export const talkReducer = createReducer<TalkState>(initialState, {
  [enableTalk.type]: (state) => ({
    ...state,
    isEnabledTalk: true,
  }),
  [disableTalk.type]: (state) => ({
    ...state,
    isEnabledTalk: false,
  }),
  [openTalk.type]: (state) => ({
    ...state,
    isOpenTalk: true,
  }),
  [closeTalk.type]: (state) => ({
    ...state,
    isOpenTalk: false,
  }),
  [setSelectedTalkRoomId.type]: (state, action) => ({
    ...state,
    selectedTalkRoomId: action.payload,
  }),
  [clearSelectedTalkRoomId.type]: (state) => ({
    ...state,
    selectedTalkRoomId: undefined,
  }),
  [clearTalkState.type]: () => initialState,
});
