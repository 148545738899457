/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientClinicDate,
  PatientClinicDateFromJSON,
  PatientClinicDateFromJSONTyped,
  PatientClinicDateToJSON,
} from "./";

export const PatientClinicVerifyEmailOptionTypename = "PatientClinicVerifyEmailOption" as const;

/**
 *
 * @export
 * @interface PatientClinicVerifyEmailOption
 */
export interface PatientClinicVerifyEmailOption {
  /**
   *
   * @type {number}
   * @memberof PatientClinicVerifyEmailOption
   */
  clinicId?: number;
  /**
   *
   * @type {number}
   * @memberof PatientClinicVerifyEmailOption
   */
  departmentId?: number;
  /**
   *
   * @type {Array<PatientClinicDate>}
   * @memberof PatientClinicVerifyEmailOption
   */
  preferredDates?: Array<PatientClinicDate>;
  /**
   *
   * @type {string}
   * @memberof PatientClinicVerifyEmailOption
   */
  visit?: PatientClinicVerifyEmailOptionVisitEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientClinicVerifyEmailOption
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientClinicVerifyEmailOptionVisitEnum {
  First = "first",
  Return = "return",
}

export function PatientClinicVerifyEmailOptionFromJSON(json: any): PatientClinicVerifyEmailOption {
  return PatientClinicVerifyEmailOptionFromJSONTyped(json, false);
}

export function PatientClinicVerifyEmailOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientClinicVerifyEmailOption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientClinicVerifyEmailOptionTypename,

    clinicId: !exists(json, "clinic_id") ? undefined : json["clinic_id"],
    departmentId: !exists(json, "department_id") ? undefined : json["department_id"],
    preferredDates: !exists(json, "preferred_dates")
      ? undefined
      : (json["preferred_dates"] as Array<any>).map(PatientClinicDateFromJSON),
    visit: !exists(json, "visit") ? undefined : json["visit"],
  };
}

export function PatientClinicVerifyEmailOptionToJSON(object?: PatientClinicVerifyEmailOption | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    clinic_id: value.clinicId,
    department_id: value.departmentId,
    preferred_dates:
      value.preferredDates === undefined
        ? undefined
        : (value.preferredDates as Array<any>).map(PatientClinicDateToJSON),
    visit: value.visit,
  };
}
