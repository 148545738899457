/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientPostFamilyAccountUpsert,
  PatientPostFamilyAccountUpsertFromJSON,
  PatientPostFamilyAccountUpsertFromJSONTyped,
  PatientPostFamilyAccountUpsertToJSON,
} from "./";

export const PostFamilyAccountsUpsertRequestBodyTypename = "PostFamilyAccountsUpsertRequestBody" as const;

/**
 *
 * @export
 * @interface PostFamilyAccountsUpsertRequestBody
 */
export interface PostFamilyAccountsUpsertRequestBody {
  /**
   *
   * @type {Array<PatientPostFamilyAccountUpsert>}
   * @memberof PostFamilyAccountsUpsertRequestBody
   */
  familyAccounts: Array<PatientPostFamilyAccountUpsert>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostFamilyAccountsUpsertRequestBody
   */
  readonly __typename?: string;
}

export function PostFamilyAccountsUpsertRequestBodyFromJSON(json: any): PostFamilyAccountsUpsertRequestBody {
  return PostFamilyAccountsUpsertRequestBodyFromJSONTyped(json, false);
}

export function PostFamilyAccountsUpsertRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostFamilyAccountsUpsertRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostFamilyAccountsUpsertRequestBodyTypename,

    familyAccounts: (json["family_accounts"] as Array<any>).map(PatientPostFamilyAccountUpsertFromJSON),
  };
}

export function PostFamilyAccountsUpsertRequestBodyToJSON(object?: PostFamilyAccountsUpsertRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    family_accounts: (value.familyAccounts as Array<any>).map(PatientPostFamilyAccountUpsertToJSON),
  };
}
