import { getRedirectResult } from "firebase/auth";
import { useCallback } from "react";

import { getFirebaseAuth } from "../utils/getFirebaseAuth";

export const useGetRedirectResult = () => {
  return useCallback<() => ReturnType<typeof getRedirectResult> | undefined>(() => {
    const firebaseAuth = getFirebaseAuth();
    return getRedirectResult(firebaseAuth);
  }, []);
};
