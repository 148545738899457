/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { AdminOperator, AdminOperatorFromJSON, AdminOperatorFromJSONTyped, AdminOperatorToJSON } from "./";

export const AdminOperatorsTypename = "AdminOperators" as const;

/**
 *
 * @export
 * @interface AdminOperators
 */
export interface AdminOperators {
  /**
   *
   * @type {number}
   * @memberof AdminOperators
   */
  count?: number;
  /**
   *
   * @type {Array<AdminOperator>}
   * @memberof AdminOperators
   */
  operators: Array<AdminOperator>;
  /**
   * Resource type name
   * @type {string}
   * @memberof AdminOperators
   */
  readonly __typename?: string;
}

export function AdminOperatorsFromJSON(json: any): AdminOperators {
  return AdminOperatorsFromJSONTyped(json, false);
}

export function AdminOperatorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminOperators {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: AdminOperatorsTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    operators: (json["operators"] as Array<any>).map(AdminOperatorFromJSON),
  };
}

export function AdminOperatorsToJSON(object?: AdminOperators | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    operators: (value.operators as Array<any>).map(AdminOperatorToJSON),
  };
}
