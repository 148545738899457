/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyOperatorUpdatableTypename = "PharmacyOperatorUpdatable" as const;

/**
 *
 * @export
 * @interface PharmacyOperatorUpdatable
 */
export interface PharmacyOperatorUpdatable {
  /**
   *
   * @type {string}
   * @memberof PharmacyOperatorUpdatable
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyOperatorUpdatable
   */
  lastName?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyOperatorUpdatable
   */
  readonly __typename?: string;
}

export function PharmacyOperatorUpdatableFromJSON(json: any): PharmacyOperatorUpdatable {
  return PharmacyOperatorUpdatableFromJSONTyped(json, false);
}

export function PharmacyOperatorUpdatableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyOperatorUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyOperatorUpdatableTypename,

    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
  };
}

export function PharmacyOperatorUpdatableToJSON(object?: PharmacyOperatorUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    first_name: value.firstName,
    last_name: value.lastName,
  };
}
