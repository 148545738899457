import { logger } from "@mgdx-libs/logger";
import { Mutex } from "async-mutex";

const AUDIO_CONTEXT_FUNCTION_NAMES = [
  "AudioContext",
  "mozAudioContext",
  "msAudioContext",
  "webkitAudioContext",
] as const;

const initialConstraints: MediaStreamConstraints = { audio: true, video: true };

type CreateConstraintOptions = {
  aspectRatio?: number;
  advanced?: Array<
    | { brightness: number }
    | { contrast: number }
    | { exposureMode: "continuous" | "manual" }
    | { focusMode: "manual" | "single-shot" | "continuous" }
    | { height?: number }
    | { ideal: number }
    | { whiteBalanceMode: "continuous" | "manual" }
    | { width?: number }
  >;
};

type GetUserMediaCache = {
  constraints?: MediaStreamConstraints;
  mediaDevices: MediaDeviceInfo[];
  mediaStream: MediaStream;
  videoDeviceId?: string;
};

export class MediaDeviceManager {
  private mutex: Mutex;
  private getUserMediaCache: GetUserMediaCache | null = null;
  private defaultRearInputVideoDeviceId: string | null = null;

  supportedConstraints: MediaTrackSupportedConstraints = {};

  isAudioContextSupported = false;
  isMediaDevicesSupported = false;
  isGetUserMediaSupported = false;
  isWebsiteHasMicrophonePermissions = false;
  isWebsiteHasWebcamPermissions = false;

  audioInputDevices: MediaDeviceInfo[] = [];
  audioOutputDevices: MediaDeviceInfo[] = [];
  videoInputDevices: MediaDeviceInfo[] = [];
  videoInputRearDevices: MediaDeviceInfo[] = [];

  isAnalyzed = false;

  constructor() {
    this.mutex = new Mutex();
    this.detectWebRtcAPI();
  }

  detectWebRtcAPI = () => {
    if (typeof window === "undefined") return;
    if (typeof window.navigator === "undefined") return;

    this.isAudioContextSupported = AUDIO_CONTEXT_FUNCTION_NAMES.some((funcName) => funcName in window);
    this.isMediaDevicesSupported = !!navigator?.mediaDevices;
    this.isGetUserMediaSupported = !!navigator?.mediaDevices?.getUserMedia;

    const supportedConstraints = this.isMediaDevicesSupported ? navigator.mediaDevices.getSupportedConstraints() : {};
    logger.debug("[MediaDeviceManager] supportedConstraints: %o", supportedConstraints);
    this.supportedConstraints = supportedConstraints;
  };

  private videoTrackSettings = (mediaStream?: MediaStream): MediaTrackSettings | undefined => {
    if (mediaStream) {
      const tracks = mediaStream.getVideoTracks();
      if (tracks.length > 0) {
        const settings = tracks[0].getSettings();
        logger.debug("[MediaDeviceManager] videoTrackSettings: %o", settings);
        return settings;
      }
    }

    if (this.getUserMediaCache) {
      const tracks = this.getUserMediaCache.mediaStream.getVideoTracks();
      if (tracks.length > 0) {
        const settings = tracks[0].getSettings();
        logger.debug("[MediaDeviceManager] videoTrackSettings: %o", settings);
        return settings;
      }
    }
  };

  private createConstraintsByVideoDeviceId = (
    videoDeviceId: string | undefined,
    options: CreateConstraintOptions
  ): MediaStreamConstraints => {
    const aspectRatio = this.supportedConstraints.aspectRatio ? options.aspectRatio : undefined;
    const frameRate = this.supportedConstraints.frameRate ? 60 : undefined;

    const additionalConstraints: MediaTrackConstraints = {
      aspectRatio,
      frameRate,
    };

    logger.debug(
      "[MediaDeviceManager] createConstraintsByVideoDeviceId: videoDeviceId %s, additionalConstraints: %s",
      videoDeviceId,
      additionalConstraints
    );

    if (this.supportedConstraints.deviceId && videoDeviceId) {
      return {
        video: {
          deviceId: videoDeviceId,
          ...additionalConstraints,
        },
      };
    }

    if (this.supportedConstraints.facingMode) {
      return {
        video: {
          facingMode: "environment",
          ...additionalConstraints,
        },
      };
    }

    return { video: true };
  };

  currentVideoDeviceId = (mediaStream?: MediaStream): string | undefined =>
    this.videoTrackSettings(mediaStream)?.deviceId;

  fetchMediaStream = (mediaStreamConstraints?: MediaStreamConstraints): Promise<MediaStream> =>
    this.mutex.runExclusive(async () => {
      const constraints = mediaStreamConstraints || initialConstraints;

      if (this.getUserMediaCache) {
        if (this.getUserMediaCache.constraints === constraints) {
          logger.debug("[MediaDeviceManager] fetchMediaStream: Found cache %o", this.getUserMediaCache);
          this.analyzeEnumerateDevices();
          return this.getUserMediaCache.mediaStream;
        }

        logger.debug("[MediaDeviceManager] fetchMediaStream: Mutex release.");
        this.mutex.release();
      }

      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      logger.debug("[MediaDeviceManager] fetchMediaStream: New MediaStream %o by %o", mediaStream, constraints);

      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      this.analyzeEnumerateDevices(mediaDevices);
      logger.debug("[MediaDeviceManager] fetchMediaStream: New MediaDevices %o", mediaDevices);

      this.getUserMediaCache = {
        constraints,
        mediaDevices,
        mediaStream,
        videoDeviceId: undefined,
      };
      logger.debug("[MediaDeviceManager] fetchMediaStream: Set cache %o", this.getUserMediaCache);

      return mediaStream;
    });

  fetchMediaStreamByVideoDeviceId = (
    videoDeviceId: string | undefined,
    options: CreateConstraintOptions
  ): Promise<MediaStream> =>
    this.mutex.runExclusive(async () => {
      if (this.getUserMediaCache) {
        if (this.getUserMediaCache.videoDeviceId === videoDeviceId) {
          logger.debug("[MediaDeviceManager] fetchMediaStreamByVideoDeviceId: Found cache %o", this.getUserMediaCache);
          this.analyzeEnumerateDevices();
          return this.getUserMediaCache.mediaStream;
        }

        logger.debug("[MediaDeviceManager] fetchMediaStreamByVideoDeviceId: Mutex release.");
        this.mutex.release();

        logger.debug("[MediaDeviceManager] fetchMediaStreamByVideoDeviceId: Stop MediaStream.");
        this.stopMediaStream();
      }

      const constraints = this.createConstraintsByVideoDeviceId(videoDeviceId, options || {});

      const mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      logger.debug(
        "[MediaDeviceManager] fetchMediaStreamByVideoDeviceId: New MediaStream %o by %o",
        mediaStream,
        constraints
      );

      if (!this.defaultRearInputVideoDeviceId) {
        if (
          typeof constraints.video === "object" &&
          "facingMode" in constraints.video &&
          constraints.video.facingMode === "environment"
        ) {
          const defaultRearInputVideoDeviceId = this.currentVideoDeviceId(mediaStream);
          if (defaultRearInputVideoDeviceId) {
            this.defaultRearInputVideoDeviceId = defaultRearInputVideoDeviceId;
          }
        }
      }

      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      this.analyzeEnumerateDevices(mediaDevices);
      logger.debug("[MediaDeviceManager] fetchMediaStreamByVideoDeviceId: New MediaDevices %o", mediaDevices);

      this.getUserMediaCache = {
        constraints: undefined,
        mediaDevices,
        mediaStream,
        videoDeviceId: videoDeviceId || this.currentVideoDeviceId(mediaStream),
      };
      logger.debug("[MediaDeviceManager] fetchMediaStreamByVideoDeviceId: Set cache %o", this.getUserMediaCache);

      return mediaStream;
    });

  clearMediaStream = () => {
    logger.debug("[MediaDeviceManager] clearMediaStream %o", this.getUserMediaCache);
    this.isAnalyzed = false;
    this.clearEnumerateDevices();
    this.stopMediaStream();
  };

  stopMediaStream = () => {
    logger.debug("[MediaDeviceManager] stopMediaStream %o", this.getUserMediaCache);
    this.mutex.release();
    this.getUserMediaCache?.mediaStream.getTracks().forEach((track) => {
      track.stop();
    });
    this.getUserMediaCache = null;
  };

  analyzeMediaDevice = async (): Promise<MediaDeviceManager> => {
    if (!this.isGetUserMediaSupported) return this;
    if (this.isAnalyzed) return this;

    await this.fetchMediaStream();

    if (this.getUserMediaCache) {
      await this.analyzeEnumerateDevices();
      this.analyzeMediaStream(this.getUserMediaCache.mediaStream);
    }

    this.isAnalyzed = true;

    return this;
  };

  private analyzeEnumerateDevices = (mediaDevices?: MediaDeviceInfo[]) => {
    const targetMediaDevices = mediaDevices || this.getUserMediaCache?.mediaDevices || [];

    this.audioInputDevices = targetMediaDevices.filter((device) => device.kind === "audioinput");
    this.audioOutputDevices = targetMediaDevices.filter((device) => device.kind === "audiooutput");
    this.videoInputDevices = targetMediaDevices.filter((device) => device.kind === "videoinput");
    this.videoInputRearDevices = this.videoInputDevices.filter((device) => device.label.match(/背面|back/gi));
  };

  private clearEnumerateDevices = () => {
    this.audioInputDevices = [];
    this.audioOutputDevices = [];
    this.videoInputDevices = [];
    this.videoInputRearDevices = [];
  };

  private analyzeMediaStream = (mediaStream: MediaStream) => {
    const tracks = mediaStream.getTracks();
    this.isWebsiteHasMicrophonePermissions = tracks.some((track) => track.kind === "audio");
    this.isWebsiteHasWebcamPermissions = tracks.some((track) => track.kind === "video");
  };
}
