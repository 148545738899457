import React, { useMemo } from "react";

import { NEWLINE_REGEX, splitNewline } from "./splitNewline";

export type Props = {
  body?: string;
};

const Nl2Br: React.FC<Props> = ({ body }) => {
  const lines = useMemo(() => splitNewline(body, { isIncludeNewline: true }), [body]);

  return (
    <>
      {lines.map((line: string, index: number) =>
        line.match(NEWLINE_REGEX) ? <br key={index} /> : <span key={index}>{line}</span>
      )}
    </>
  );
};

export default Nl2Br;
