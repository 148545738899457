import ArrowBackBriefIcon from "@mgdx/assets/icons/ArrowBackBriefIcon";
import CloseIcon from "@mgdx/assets/icons/CloseIcon";
import React from "react";

import { OvalButton } from "../OvalButton";
import { RefreshButton } from "../RefreshButton";
import * as styles from "./DialogLayout.module.css";

export type Props = React.PropsWithChildren<{
  title?: string;
  onRequestBack?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  onRequestRefresh?: (event: React.MouseEvent | React.KeyboardEvent) => void;
}>;

const DialogLayout = ({ title, onRequestBack, onRequestClose, onRequestRefresh, children }: Props) => {
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {onRequestBack && (
            <OvalButton variant="plain" size="s" aria-label="戻る" onClick={onRequestBack}>
              <ArrowBackBriefIcon variant="secondary" />
            </OvalButton>
          )}
        </div>

        <h2 className={styles.headerCenter}>{title}</h2>

        <div className={styles.headerRight}>
          {onRequestRefresh && (
            <RefreshButton variant="plain" iconVariant="secondary" size="s" onClick={onRequestRefresh} />
          )}

          {onRequestClose && (
            <OvalButton variant="plain" size="s" aria-label="閉じる" onClick={onRequestClose}>
              <CloseIcon variant="secondary" />
            </OvalButton>
          )}
        </div>
      </div>

      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default DialogLayout;
