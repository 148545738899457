import { useTalk } from "@mgdx/talk/hooks";
import React from "react";

import useCurrentUser from "../../hooks/useCurrentUser";
import TalkMessageListDialog from "./TalkMessageListDialog";

const TalkMessageListApp = () => {
  const currentUser = useCurrentUser();
  const { isEnabledTalk } = useTalk();

  if (currentUser.isLoggedIn && isEnabledTalk) {
    return <TalkMessageListDialog />;
  }

  return null;
};

export default TalkMessageListApp;
