/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const DeliveryOrderEventDetailTypename = "DeliveryOrderEventDetail" as const;

/**
 *
 * @export
 * @interface DeliveryOrderEventDetail
 */
export interface DeliveryOrderEventDetail {
  /**
   *
   * @type {string}
   * @memberof DeliveryOrderEventDetail
   */
  detail?: string;
  /**
   *
   * @type {Date}
   * @memberof DeliveryOrderEventDetail
   */
  time?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryOrderEventDetail
   */
  readonly __typename?: string;
}

export function DeliveryOrderEventDetailFromJSON(json: any): DeliveryOrderEventDetail {
  return DeliveryOrderEventDetailFromJSONTyped(json, false);
}

export function DeliveryOrderEventDetailFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeliveryOrderEventDetail {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryOrderEventDetailTypename,

    detail: !exists(json, "detail") ? undefined : json["detail"],
    time:
      !exists(json, "time") || (typeof json["time"] === "string" && json["time"].substring(0, 4) === "0001")
        ? undefined
        : json["time"],
  };
}

export function DeliveryOrderEventDetailToJSON(object?: DeliveryOrderEventDetail | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    detail: value.detail,
    time: value.time === undefined ? undefined : moment(value.time).tz("Asia/Tokyo").format(),
  };
}
