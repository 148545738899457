import { detect } from "detect-browser";
import { useMemo } from "react";

export const useIsAndroid = (): boolean => {
  return useMemo<boolean>(() => {
    const browser = detect();
    if (browser?.os === undefined) return false;
    return browser.os === "Android OS";
  }, []);
};

export default useIsAndroid;
