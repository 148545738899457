/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPatchPharmaciesTypename = "PharmacyPatchPharmacies" as const;

/**
 *
 * @export
 * @interface PharmacyPatchPharmacies
 */
export interface PharmacyPatchPharmacies {
  /**
   *
   * @type {number}
   * @memberof PharmacyPatchPharmacies
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPatchPharmacies
   */
  visibility: PharmacyPatchPharmaciesVisibilityEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPatchPharmacies
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyPatchPharmaciesVisibilityEnum {
  Public = "public",
  Private = "private",
  Limited = "limited",
}

export function PharmacyPatchPharmaciesFromJSON(json: any): PharmacyPatchPharmacies {
  return PharmacyPatchPharmaciesFromJSONTyped(json, false);
}

export function PharmacyPatchPharmaciesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPatchPharmacies {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPatchPharmaciesTypename,

    id: json["id"],
    visibility: json["visibility"],
  };
}

export function PharmacyPatchPharmaciesToJSON(object?: PharmacyPatchPharmacies | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    visibility: value.visibility,
  };
}
