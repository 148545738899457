/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PayerShopAuthentication,
  PayerShopAuthenticationFromJSON,
  PayerShopAuthenticationFromJSONTyped,
  PayerShopAuthenticationToJSON,
  PayerShopTestResult,
  PayerShopTestResultFromJSON,
  PayerShopTestResultFromJSONTyped,
  PayerShopTestResultToJSON,
} from "./";

export const PayerClinicShopTypename = "PayerClinicShop" as const;

/**
 *
 * @export
 * @interface PayerClinicShop
 */
export interface PayerClinicShop {
  /**
   *
   * @type {PayerShopAuthentication}
   * @memberof PayerClinicShop
   */
  authentication: PayerShopAuthentication;
  /**
   *
   * @type {number}
   * @memberof PayerClinicShop
   */
  chainId?: number;
  /**
   *
   * @type {number}
   * @memberof PayerClinicShop
   */
  clinicId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof PayerClinicShop
   */
  clinicIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof PayerClinicShop
   */
  gmoShopId: string;
  /**
   *
   * @type {number}
   * @memberof PayerClinicShop
   */
  groupId?: number;
  /**
   *
   * @type {string}
   * @memberof PayerClinicShop
   */
  name?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PayerClinicShop
   */
  pharmacyIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof PayerClinicShop
   */
  serviceId: string;
  /**
   *
   * @type {number}
   * @memberof PayerClinicShop
   */
  shopId: number;
  /**
   *
   * @type {string}
   * @memberof PayerClinicShop
   */
  status: PayerClinicShopStatusEnum;
  /**
   *
   * @type {PayerShopTestResult}
   * @memberof PayerClinicShop
   */
  test?: PayerShopTestResult;
  /**
   * Resource type name
   * @type {string}
   * @memberof PayerClinicShop
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PayerClinicShopStatusEnum {
  Enabled = "enabled",
  Disabled = "disabled",
}

export function PayerClinicShopFromJSON(json: any): PayerClinicShop {
  return PayerClinicShopFromJSONTyped(json, false);
}

export function PayerClinicShopFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayerClinicShop {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PayerClinicShopTypename,

    authentication: PayerShopAuthenticationFromJSON(json["authentication"]),
    chainId: !exists(json, "chain_id") ? undefined : json["chain_id"],
    clinicId: json["clinic_id"],
    clinicIds: !exists(json, "clinic_ids") ? undefined : json["clinic_ids"],
    gmoShopId: json["gmo_shop_id"],
    groupId: !exists(json, "group_id") ? undefined : json["group_id"],
    name: !exists(json, "name") ? undefined : json["name"],
    pharmacyIds: !exists(json, "pharmacy_ids") ? undefined : json["pharmacy_ids"],
    serviceId: json["service_id"],
    shopId: json["shop_id"],
    status: json["status"],
    test: !exists(json, "test") ? undefined : PayerShopTestResultFromJSON(json["test"]),
  };
}

export function PayerClinicShopToJSON(object?: PayerClinicShop | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    authentication: PayerShopAuthenticationToJSON(value.authentication),
    chain_id: value.chainId,
    clinic_id: value.clinicId,
    clinic_ids: value.clinicIds,
    gmo_shop_id: value.gmoShopId,
    group_id: value.groupId,
    name: value.name,
    pharmacy_ids: value.pharmacyIds,
    service_id: value.serviceId,
    shop_id: value.shopId,
    status: value.status,
    test: PayerShopTestResultToJSON(value.test),
  };
}
