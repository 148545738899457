/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyControlPanelSetting,
  PharmacyControlPanelSettingFromJSON,
  PharmacyControlPanelSettingFromJSONTyped,
  PharmacyControlPanelSettingToJSON,
} from "./";

export const PharmacyControlPanelUpdatableTypename = "PharmacyControlPanelUpdatable" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanelUpdatable
 */
export interface PharmacyControlPanelUpdatable {
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanelUpdatable
   */
  platform?: PharmacyControlPanelUpdatablePlatformEnum;
  /**
   *
   * @type {PharmacyControlPanelSetting}
   * @memberof PharmacyControlPanelUpdatable
   */
  setting?: PharmacyControlPanelSetting;
  /**
   *
   * @type {string}
   * @memberof PharmacyControlPanelUpdatable
   */
  version?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanelUpdatable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyControlPanelUpdatablePlatformEnum {
  Ios = "ios",
  Windows = "windows",
}

export function PharmacyControlPanelUpdatableFromJSON(json: any): PharmacyControlPanelUpdatable {
  return PharmacyControlPanelUpdatableFromJSONTyped(json, false);
}

export function PharmacyControlPanelUpdatableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyControlPanelUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelUpdatableTypename,

    platform: !exists(json, "platform") ? undefined : json["platform"],
    setting: !exists(json, "setting") ? undefined : PharmacyControlPanelSettingFromJSON(json["setting"]),
    version: !exists(json, "version") ? undefined : json["version"],
  };
}

export function PharmacyControlPanelUpdatableToJSON(object?: PharmacyControlPanelUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    platform: value.platform,
    setting: PharmacyControlPanelSettingToJSON(value.setting),
    version: value.version,
  };
}
