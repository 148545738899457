import RefreshIcon from "@mgdx/assets/icons/RefreshIcon";
import clsx from "clsx";
import React, { useState } from "react";

import { OvalButton, OvalButtonProps } from "../OvalButton";
import * as styles from "./RefreshButton.module.css";

export type RefreshButtonProps = Partial<Pick<OvalButtonProps, "variant" | "size">> &
  Required<Pick<OvalButtonProps, "onClick">> & {
    iconVariant?: "primary" | "secondary";
    className?: string;
  };

export const RefreshButton: React.FC<RefreshButtonProps> = ({
  variant,
  iconVariant,
  size,
  onClick,
  className,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <OvalButton
      variant={variant || "variable-weak"}
      aria-label={props["aria-label"] || "更新する"}
      size={size}
      onClick={async (e) => {
        setIsLoading(true);
        onClick && (await onClick(e));
        setIsLoading(false);
      }}
      className={className}
    >
      <RefreshIcon variant={iconVariant} className={clsx(isLoading && styles.circularProgress)} />
    </OvalButton>
  );
};
