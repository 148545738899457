/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorMedicineNotebookAccount,
  PharmacyCounselorMedicineNotebookAccountFromJSON,
  PharmacyCounselorMedicineNotebookAccountFromJSONTyped,
  PharmacyCounselorMedicineNotebookAccountToJSON,
  PharmacyCounselorMedicineNotebookAutoLinkPatient,
  PharmacyCounselorMedicineNotebookAutoLinkPatientFromJSON,
  PharmacyCounselorMedicineNotebookAutoLinkPatientFromJSONTyped,
  PharmacyCounselorMedicineNotebookAutoLinkPatientToJSON,
} from "./";

export const PharmacyCounselorMedicineNotebookAutoLinkTypename = "PharmacyCounselorMedicineNotebookAutoLink" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorMedicineNotebookAutoLink
 */
export interface PharmacyCounselorMedicineNotebookAutoLink {
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  completedTime?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  counselingId?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  managementNo?: string;
  /**
   *
   * @type {PharmacyCounselorMedicineNotebookAccount}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  medicineNotebookAccount?: PharmacyCounselorMedicineNotebookAccount;
  /**
   *
   * @type {PharmacyCounselorMedicineNotebookAutoLinkPatient}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  patient: PharmacyCounselorMedicineNotebookAutoLinkPatient;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  pharmacyId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  requestType?: PharmacyCounselorMedicineNotebookAutoLinkRequestTypeEnum;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  requestedTime: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  status: PharmacyCounselorMedicineNotebookAutoLinkStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLink
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorMedicineNotebookAutoLinkRequestTypeEnum {
  Web = "web",
  Line = "line",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorMedicineNotebookAutoLinkStatusEnum {
  Requested = "requested",
  Completed = "completed",
  Canceled = "canceled",
}

export function PharmacyCounselorMedicineNotebookAutoLinkFromJSON(
  json: any
): PharmacyCounselorMedicineNotebookAutoLink {
  return PharmacyCounselorMedicineNotebookAutoLinkFromJSONTyped(json, false);
}

export function PharmacyCounselorMedicineNotebookAutoLinkFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorMedicineNotebookAutoLink {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorMedicineNotebookAutoLinkTypename,

    completedTime:
      !exists(json, "completed_time") ||
      (typeof json["completed_time"] === "string" && json["completed_time"].substring(0, 4) === "0001")
        ? undefined
        : json["completed_time"],
    counselingId: !exists(json, "counseling_id") ? undefined : json["counseling_id"],
    id: json["id"],
    managementNo: !exists(json, "management_no") ? undefined : json["management_no"],
    medicineNotebookAccount: !exists(json, "medicine_notebook_account")
      ? undefined
      : PharmacyCounselorMedicineNotebookAccountFromJSON(json["medicine_notebook_account"]),
    patient: PharmacyCounselorMedicineNotebookAutoLinkPatientFromJSON(json["patient"]),
    pharmacyId: json["pharmacy_id"],
    requestType: !exists(json, "request_type") ? undefined : json["request_type"],
    requestedTime: json["requested_time"],
    status: json["status"],
  };
}

export function PharmacyCounselorMedicineNotebookAutoLinkToJSON(
  object?: PharmacyCounselorMedicineNotebookAutoLink | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    completed_time:
      value.completedTime === undefined ? undefined : moment(value.completedTime).tz("Asia/Tokyo").format(),
    counseling_id: value.counselingId,
    id: value.id,
    management_no: value.managementNo,
    medicine_notebook_account: PharmacyCounselorMedicineNotebookAccountToJSON(value.medicineNotebookAccount),
    patient: PharmacyCounselorMedicineNotebookAutoLinkPatientToJSON(value.patient),
    pharmacy_id: value.pharmacyId,
    request_type: value.requestType,
    requested_time: moment(value.requestedTime).tz("Asia/Tokyo").format(),
    status: value.status,
  };
}
