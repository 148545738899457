/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchBookmarksRequestBody,
  PatchBookmarksRequestBodyFromJSON,
  PatchBookmarksRequestBodyToJSON,
  PharmacyBookmark,
  PharmacyBookmarkFromJSON,
  PharmacyBookmarkToJSON,
  PharmacyChain,
  PharmacyChainFromJSON,
  PharmacyChainToJSON,
  PharmacyChainRegistrable,
  PharmacyChainRegistrableFromJSON,
  PharmacyChainRegistrableToJSON,
  PharmacyChainUpdatable,
  PharmacyChainUpdatableFromJSON,
  PharmacyChainUpdatableToJSON,
  PharmacyChainUsageSummary,
  PharmacyChainUsageSummaryFromJSON,
  PharmacyChainUsageSummaryToJSON,
  PharmacyPharmacyCount,
  PharmacyPharmacyCountFromJSON,
  PharmacyPharmacyCountToJSON,
} from "../models";

export interface CreateChainRequest {
  pharmacyChainRegistrable: Array<PharmacyChainRegistrable>;
}

export interface GetAllChainRequest {
  appName?: string;
}

export interface GetBookmarksRequest {
  chainId: number;
}

export interface GetChainRequest {
  chainId: number;
}

export interface GetChainUsageSummariesRequest {
  chainId: number;
  month: string;
  cumulate?: boolean;
}

export interface GetPharmacyCountsRequest {
  isScreening?: boolean;
  visibility?: Array<GetPharmacyCountsVisibilityEnum>;
}

export interface UpdateBookmarksRequest {
  chainId: number;
  patchBookmarksRequestBody: PatchBookmarksRequestBody;
}

export interface UpdateChainRequest {
  chainId: number;
  pharmacyChainUpdatable: PharmacyChainUpdatable;
}

/**
 *
 */
export class ChainApi extends runtime.BaseAPI {
  /**
   * Register chain
   */
  async createChainRaw(
    requestParameters: CreateChainRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyChain>>> {
    if (
      requestParameters.pharmacyChainRegistrable === null ||
      requestParameters.pharmacyChainRegistrable === undefined
    ) {
      throw new runtime.RequiredError(
        "pharmacyChainRegistrable",
        "Required parameter requestParameters.pharmacyChainRegistrable was null or undefined when calling createChain."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.pharmacyChainRegistrable.map(PharmacyChainRegistrableToJSON),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyChainFromJSON));
  }

  /**
   * Register chain
   */
  async createChain(requestParameters: CreateChainRequest, initOverrides?: RequestInit): Promise<Array<PharmacyChain>> {
    return promiseRetry(
      (retry) =>
        this.createChainRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get all chain
   */
  async getAllChainRaw(
    requestParameters: GetAllChainRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyChain>>> {
    const queryParameters: any = {};

    if (requestParameters.appName !== undefined) {
      queryParameters["app_name"] = requestParameters.appName;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/chains`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyChainFromJSON));
  }

  /**
   * Get all chain
   */
  async getAllChain(requestParameters: GetAllChainRequest, initOverrides?: RequestInit): Promise<Array<PharmacyChain>> {
    return promiseRetry(
      (retry) =>
        this.getAllChainRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get bookmarks
   */
  async getBookmarksRaw(
    requestParameters: GetBookmarksRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyBookmark>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getBookmarks."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/bookmarks`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyBookmarkFromJSON));
  }

  /**
   * Get bookmarks
   */
  async getBookmarks(
    requestParameters: GetBookmarksRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyBookmark>> {
    return promiseRetry(
      (retry) =>
        this.getBookmarksRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain
   */
  async getChainRaw(
    requestParameters: GetChainRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyChain>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChain."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/chains/{chain_id}`.replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyChainFromJSON(jsonValue));
  }

  /**
   * Get chain
   */
  async getChain(requestParameters: GetChainRequest, initOverrides?: RequestInit): Promise<PharmacyChain> {
    return promiseRetry(
      (retry) =>
        this.getChainRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get chain usage summaries
   */
  async getChainUsageSummariesRaw(
    requestParameters: GetChainUsageSummariesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyChainUsageSummary>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getChainUsageSummaries."
      );
    }

    if (requestParameters.month === null || requestParameters.month === undefined) {
      throw new runtime.RequiredError(
        "month",
        "Required parameter requestParameters.month was null or undefined when calling getChainUsageSummaries."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.chainId !== undefined) {
      queryParameters["chain_id"] = requestParameters.chainId;
    }

    if (requestParameters.month !== undefined) {
      queryParameters["month"] = requestParameters.month;
    }

    if (requestParameters.cumulate !== undefined) {
      queryParameters["cumulate"] = requestParameters.cumulate;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chain-usage-summaries`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyChainUsageSummaryFromJSON(jsonValue));
  }

  /**
   * Get chain usage summaries
   */
  async getChainUsageSummaries(
    requestParameters: GetChainUsageSummariesRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyChainUsageSummary> {
    return promiseRetry(
      (retry) =>
        this.getChainUsageSummariesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get the number of pharmacies grouped by chain id
   */
  async getPharmacyCountsRaw(
    requestParameters: GetPharmacyCountsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyPharmacyCount>>> {
    const queryParameters: any = {};

    if (requestParameters.isScreening !== undefined) {
      queryParameters["is_screening"] = requestParameters.isScreening;
    }

    if (requestParameters.visibility) {
      queryParameters["visibility"] = requestParameters.visibility;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/chains-pharmacy-counts`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyPharmacyCountFromJSON));
  }

  /**
   * Get the number of pharmacies grouped by chain id
   */
  async getPharmacyCounts(
    requestParameters: GetPharmacyCountsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyPharmacyCount>> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyCountsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update bookmarks
   */
  async updateBookmarksRaw(
    requestParameters: UpdateBookmarksRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyBookmark>>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling updateBookmarks."
      );
    }

    if (
      requestParameters.patchBookmarksRequestBody === null ||
      requestParameters.patchBookmarksRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchBookmarksRequestBody",
        "Required parameter requestParameters.patchBookmarksRequestBody was null or undefined when calling updateBookmarks."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}/bookmarks`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchBookmarksRequestBodyToJSON(requestParameters.patchBookmarksRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyBookmarkFromJSON));
  }

  /**
   * Update bookmarks
   */
  async updateBookmarks(
    requestParameters: UpdateBookmarksRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyBookmark>> {
    return promiseRetry(
      (retry) =>
        this.updateBookmarksRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update chain
   */
  async updateChainRaw(
    requestParameters: UpdateChainRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyChain>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling updateChain."
      );
    }

    if (requestParameters.pharmacyChainUpdatable === null || requestParameters.pharmacyChainUpdatable === undefined) {
      throw new runtime.RequiredError(
        "pharmacyChainUpdatable",
        "Required parameter requestParameters.pharmacyChainUpdatable was null or undefined when calling updateChain."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/chains/{chain_id}`.replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PharmacyChainUpdatableToJSON(requestParameters.pharmacyChainUpdatable),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyChainFromJSON(jsonValue));
  }

  /**
   * Update chain
   */
  async updateChain(requestParameters: UpdateChainRequest, initOverrides?: RequestInit): Promise<PharmacyChain> {
    return promiseRetry(
      (retry) =>
        this.updateChainRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetPharmacyCountsVisibilityEnum {
  Public = "public",
  Private = "private",
  Limited = "limited",
}
