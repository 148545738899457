import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const DeleteIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M59,238.5 C59.5522847,238.5 60,238.947715 60,239.5 C60,240.052285 59.5522847,240.5 59,240.5 L59,240.5 L58,240.5 L58,251.5 C58,252.604569 57.1045695,253.5 56,253.5 L56,253.5 L48,253.5 C46.8954305,253.5 46,252.604569 46,251.5 L46,251.5 L46,240.5 L45,240.5 C44.4477153,240.5 44,240.052285 44,239.5 C44,238.947715 44.4477153,238.5 45,238.5 L45,238.5 Z M50,242.5 C49.4477153,242.5 49,242.947715 49,243.5 L49,243.5 L49,249.5 C49,250.052285 49.4477153,250.5 50,250.5 C50.5522847,250.5 51,250.052285 51,249.5 L51,249.5 L51,243.5 C51,242.947715 50.5522847,242.5 50,242.5 Z M54,242.5 C53.4477153,242.5 53,242.947715 53,243.5 L53,243.5 L53,249.5 C53,250.052285 53.4477153,250.5 54,250.5 C54.5522847,250.5 55,250.052285 55,249.5 L55,249.5 L55,243.5 C55,242.947715 54.5522847,242.5 54,242.5 Z M54,234.5 C55.1045695,234.5 56,235.395431 56,236.5 L56,237.5 L54,237.5 L54,236.5 L50,236.5 L50,237.5 L48,237.5 L48,236.5 C48,235.395431 48.8954305,234.5 50,234.5 L54,234.5 Z"
        transform="translate(-40 -232)"
      />
    </svg>
  );
};

export default DeleteIcon;
