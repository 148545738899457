/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  GetInterviewResponse,
  GetInterviewResponseFromJSON,
  GetInterviewResponseToJSON,
  GetPatientsInterviewResponse,
  GetPatientsInterviewResponseFromJSON,
  GetPatientsInterviewResponseToJSON,
  PatchPatientRequestBody,
  PatchPatientRequestBodyFromJSON,
  PatchPatientRequestBodyToJSON,
  PatientCustomBadRequest3,
  PatientCustomBadRequest3FromJSON,
  PatientCustomBadRequest3ToJSON,
  PatientCustomBadRequest5,
  PatientCustomBadRequest5FromJSON,
  PatientCustomBadRequest5ToJSON,
  PatientCustomBadRequest6,
  PatientCustomBadRequest6FromJSON,
  PatientCustomBadRequest6ToJSON,
  PatientCustomBadRequest7,
  PatientCustomBadRequest7FromJSON,
  PatientCustomBadRequest7ToJSON,
  PatientInterview,
  PatientInterviewFromJSON,
  PatientInterviewToJSON,
  PatientPatient,
  PatientPatientFromJSON,
  PatientPatientToJSON,
  PostPatientCustomTokenResponse,
  PostPatientCustomTokenResponseFromJSON,
  PostPatientCustomTokenResponseToJSON,
  PostPatientRequestBody,
  PostPatientRequestBodyFromJSON,
  PostPatientRequestBodyToJSON,
  PostPatientResetPasswordRequestBody,
  PostPatientResetPasswordRequestBodyFromJSON,
  PostPatientResetPasswordRequestBodyToJSON,
  PutPatientEmailRequestBody,
  PutPatientEmailRequestBodyFromJSON,
  PutPatientEmailRequestBodyToJSON,
  PutPatientPHRAgreementRequestBody,
  PutPatientPHRAgreementRequestBodyFromJSON,
  PutPatientPHRAgreementRequestBodyToJSON,
  PutPatientPasswordRequestBody,
  PutPatientPasswordRequestBodyFromJSON,
  PutPatientPasswordRequestBodyToJSON,
  PutPatientStatusRequestBody,
  PutPatientStatusRequestBodyFromJSON,
  PutPatientStatusRequestBodyToJSON,
} from "../models";

export interface CreateCurrentPatientRequest {
  mode: CreateCurrentPatientModeEnum;
  postPatientRequestBody: PostPatientRequestBody;
}

export interface GetInterviewRequest {
  chainId: number;
  familyAccountId?: number;
}

export interface GetPatientsRequest {
  patientId: number;
  type?: string;
  withDeleted?: boolean;
}

export interface GetPatientsByIDsRequest {
  id?: Array<number>;
}

export interface GetPatientsInterviewRequest {
  patientId: number;
  chainId: number;
  familyAccountId?: number;
}

export interface HeadPatientRequest {
  field: HeadPatientFieldEnum;
}

export interface PostResetPasswordRequest {
  postPatientResetPasswordRequestBody: PostPatientResetPasswordRequestBody;
  referrer?: string;
}

export interface PostVerifyEmailRequest {
  referrer?: string;
}

export interface PutInterviewRequest {
  chainId: number;
  patientInterview: PatientInterview;
  familyAccountId?: number;
}

export interface PutPHRAgreementRequest {
  putPatientPHRAgreementRequestBody: PutPatientPHRAgreementRequestBody;
}

export interface PutPatientEmailRequest {
  putPatientEmailRequestBody: PutPatientEmailRequestBody;
}

export interface PutPatientPasswordRequest {
  putPatientPasswordRequestBody: PutPatientPasswordRequestBody;
}

export interface PutPatientStatusRequest {
  putPatientStatusRequestBody: PutPatientStatusRequestBody;
}

export interface PutPatientsInterviewRequest {
  patientId: number;
  chainId: number;
  patientInterview: PatientInterview;
  familyAccountId?: number;
}

export interface UpdateCurrentPatientRequest {
  patchPatientRequestBody: PatchPatientRequestBody;
  mode?: UpdateCurrentPatientModeEnum;
}

/**
 *
 */
export class PatientApi extends runtime.BaseAPI {
  /**
   * Register patient
   */
  async createCurrentPatientRaw(
    requestParameters: CreateCurrentPatientRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (requestParameters.mode === null || requestParameters.mode === undefined) {
      throw new runtime.RequiredError(
        "mode",
        "Required parameter requestParameters.mode was null or undefined when calling createCurrentPatient."
      );
    }

    if (requestParameters.postPatientRequestBody === null || requestParameters.postPatientRequestBody === undefined) {
      throw new runtime.RequiredError(
        "postPatientRequestBody",
        "Required parameter requestParameters.postPatientRequestBody was null or undefined when calling createCurrentPatient."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.mode !== undefined) {
      queryParameters["mode"] = requestParameters.mode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/patients`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostPatientRequestBodyToJSON(requestParameters.postPatientRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Register patient
   */
  async createCurrentPatient(
    requestParameters: CreateCurrentPatientRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.createCurrentPatientRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Delete patient by auth_id in JWT (resignation)
   */
  async deleteCurrentPatientRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete patient by auth_id in JWT (resignation)
   */
  async deleteCurrentPatient(initOverrides?: RequestInit): Promise<void> {
    await this.deleteCurrentPatientRaw(initOverrides);
  }

  /**
   * Delete session
   */
  async deleteSessionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/session`,
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete session
   */
  async deleteSession(initOverrides?: RequestInit): Promise<void> {
    await this.deleteSessionRaw(initOverrides);
  }

  /**
   * Get patient by auth_id in JWT
   */
  async getCurrentPatientRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PatientPatient>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Get patient by auth_id in JWT
   */
  async getCurrentPatient(initOverrides?: RequestInit): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.getCurrentPatientRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Authentication by patient\'s JWT
   */
  async getInterviewRaw(
    requestParameters: GetInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetInterviewResponse>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getInterview."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/interviews/{chain_id}`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetInterviewResponseFromJSON(jsonValue));
  }

  /**
   * Authentication by patient\'s JWT
   */
  async getInterview(
    requestParameters: GetInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<GetInterviewResponse> {
    return promiseRetry(
      (retry) =>
        this.getInterviewRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get patient by patient_id in path
   */
  async getPatientsRaw(
    requestParameters: GetPatientsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getPatients."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.type !== undefined) {
      queryParameters["type"] = requestParameters.type;
    }

    if (requestParameters.withDeleted !== undefined) {
      queryParameters["with_deleted"] = requestParameters.withDeleted;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}`.replace(
          `{${"patient_id"}}`,
          encodeURIComponent(String(requestParameters.patientId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Get patient by patient_id in path
   */
  async getPatients(requestParameters: GetPatientsRequest, initOverrides?: RequestInit): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.getPatientsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get patient by ids
   */
  async getPatientsByIDsRaw(
    requestParameters: GetPatientsByIDsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PatientPatient>>> {
    const queryParameters: any = {};

    if (requestParameters.id) {
      queryParameters["id"] = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientPatientFromJSON));
  }

  /**
   * Get patient by ids
   */
  async getPatientsByIDs(
    requestParameters: GetPatientsByIDsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PatientPatient>> {
    return promiseRetry(
      (retry) =>
        this.getPatientsByIDsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Authentication by pharmacist\'s or patient\'s, doctor\'s JWT
   */
  async getPatientsInterviewRaw(
    requestParameters: GetPatientsInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetPatientsInterviewResponse>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getPatientsInterview."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getPatientsInterview."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/interviews/{chain_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetPatientsInterviewResponseFromJSON(jsonValue));
  }

  /**
   * Authentication by pharmacist\'s or patient\'s, doctor\'s JWT
   */
  async getPatientsInterview(
    requestParameters: GetPatientsInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<GetPatientsInterviewResponse> {
    return promiseRetry(
      (retry) =>
        this.getPatientsInterviewRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Check patient exists or not by some fields in JWT
   */
  async headPatientRaw(
    requestParameters: HeadPatientRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.field === null || requestParameters.field === undefined) {
      throw new runtime.RequiredError(
        "field",
        "Required parameter requestParameters.field was null or undefined when calling headPatient."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.field !== undefined) {
      queryParameters["field"] = requestParameters.field;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient`,
        method: "HEAD",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Check patient exists or not by some fields in JWT
   */
  async headPatient(requestParameters: HeadPatientRequest, initOverrides?: RequestInit): Promise<void> {
    await this.headPatientRaw(requestParameters, initOverrides);
  }

  /**
   * create custom token by auth_id in JWT
   */
  async postCustomTokenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<PostPatientCustomTokenResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/custom-tokens`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PostPatientCustomTokenResponseFromJSON(jsonValue));
  }

  /**
   * create custom token by auth_id in JWT
   */
  async postCustomToken(initOverrides?: RequestInit): Promise<PostPatientCustomTokenResponse> {
    return promiseRetry(
      (retry) =>
        this.postCustomTokenRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Accept password reset complete by auth_id from JWT
   */
  async postPatientResetPasswordCompletionRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/reset-password-completions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accept password reset complete by auth_id from JWT
   */
  async postPatientResetPasswordCompletion(initOverrides?: RequestInit): Promise<void> {
    await this.postPatientResetPasswordCompletionRaw(initOverrides);
  }

  /**
   * Send reset password email
   */
  async postResetPasswordRaw(
    requestParameters: PostResetPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.postPatientResetPasswordRequestBody === null ||
      requestParameters.postPatientResetPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postPatientResetPasswordRequestBody",
        "Required parameter requestParameters.postPatientResetPasswordRequestBody was null or undefined when calling postResetPassword."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.referrer !== undefined && requestParameters.referrer !== null) {
      headerParameters["referrer"] = String(requestParameters.referrer);
    }

    const response = await this.request(
      {
        path: `/patient-reset-passwords`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostPatientResetPasswordRequestBodyToJSON(requestParameters.postPatientResetPasswordRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send reset password email
   */
  async postResetPassword(requestParameters: PostResetPasswordRequest, initOverrides?: RequestInit): Promise<void> {
    await this.postResetPasswordRaw(requestParameters, initOverrides);
  }

  /**
   * Resend verify email by auth_id in JWT
   */
  async postVerifyEmailRaw(
    requestParameters: PostVerifyEmailRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.referrer !== undefined && requestParameters.referrer !== null) {
      headerParameters["referrer"] = String(requestParameters.referrer);
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/verify-emails`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Resend verify email by auth_id in JWT
   */
  async postVerifyEmail(requestParameters: PostVerifyEmailRequest, initOverrides?: RequestInit): Promise<void> {
    await this.postVerifyEmailRaw(requestParameters, initOverrides);
  }

  /**
   * Authentication by patient\'s JWT
   */
  async putInterviewRaw(
    requestParameters: PutInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientInterview>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putInterview."
      );
    }

    if (requestParameters.patientInterview === null || requestParameters.patientInterview === undefined) {
      throw new runtime.RequiredError(
        "patientInterview",
        "Required parameter requestParameters.patientInterview was null or undefined when calling putInterview."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/interviews/{chain_id}`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientInterviewToJSON(requestParameters.patientInterview),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientInterviewFromJSON(jsonValue));
  }

  /**
   * Authentication by patient\'s JWT
   */
  async putInterview(requestParameters: PutInterviewRequest, initOverrides?: RequestInit): Promise<PatientInterview> {
    return promiseRetry(
      (retry) =>
        this.putInterviewRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * agree phr function with auth_id in JWT
   */
  async putPHRAgreementRaw(
    requestParameters: PutPHRAgreementRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (
      requestParameters.putPatientPHRAgreementRequestBody === null ||
      requestParameters.putPatientPHRAgreementRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putPatientPHRAgreementRequestBody",
        "Required parameter requestParameters.putPatientPHRAgreementRequestBody was null or undefined when calling putPHRAgreement."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/phr-agreement`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutPatientPHRAgreementRequestBodyToJSON(requestParameters.putPatientPHRAgreementRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * agree phr function with auth_id in JWT
   */
  async putPHRAgreement(
    requestParameters: PutPHRAgreementRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.putPHRAgreementRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update own e-mail with auth_id in JWT
   */
  async putPatientEmailRaw(
    requestParameters: PutPatientEmailRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (
      requestParameters.putPatientEmailRequestBody === null ||
      requestParameters.putPatientEmailRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putPatientEmailRequestBody",
        "Required parameter requestParameters.putPatientEmailRequestBody was null or undefined when calling putPatientEmail."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/email`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutPatientEmailRequestBodyToJSON(requestParameters.putPatientEmailRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Update own e-mail with auth_id in JWT
   */
  async putPatientEmail(
    requestParameters: PutPatientEmailRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.putPatientEmailRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update own password with auth_id in JWT
   */
  async putPatientPasswordRaw(
    requestParameters: PutPatientPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (
      requestParameters.putPatientPasswordRequestBody === null ||
      requestParameters.putPatientPasswordRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putPatientPasswordRequestBody",
        "Required parameter requestParameters.putPatientPasswordRequestBody was null or undefined when calling putPatientPassword."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/password`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutPatientPasswordRequestBodyToJSON(requestParameters.putPatientPasswordRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Update own password with auth_id in JWT
   */
  async putPatientPassword(
    requestParameters: PutPatientPasswordRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.putPatientPasswordRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update own status with auth_id in JWT
   */
  async putPatientStatusRaw(
    requestParameters: PutPatientStatusRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (
      requestParameters.putPatientStatusRequestBody === null ||
      requestParameters.putPatientStatusRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putPatientStatusRequestBody",
        "Required parameter requestParameters.putPatientStatusRequestBody was null or undefined when calling putPatientStatus."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/status`,
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutPatientStatusRequestBodyToJSON(requestParameters.putPatientStatusRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Update own status with auth_id in JWT
   */
  async putPatientStatus(
    requestParameters: PutPatientStatusRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.putPatientStatusRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Authentication by patient\'s JWT
   */
  async putPatientsInterviewRaw(
    requestParameters: PutPatientsInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientInterview>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling putPatientsInterview."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling putPatientsInterview."
      );
    }

    if (requestParameters.patientInterview === null || requestParameters.patientInterview === undefined) {
      throw new runtime.RequiredError(
        "patientInterview",
        "Required parameter requestParameters.patientInterview was null or undefined when calling putPatientsInterview."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/interviews/{chain_id}`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PatientInterviewToJSON(requestParameters.patientInterview),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientInterviewFromJSON(jsonValue));
  }

  /**
   * Authentication by patient\'s JWT
   */
  async putPatientsInterview(
    requestParameters: PutPatientsInterviewRequest,
    initOverrides?: RequestInit
  ): Promise<PatientInterview> {
    return promiseRetry(
      (retry) =>
        this.putPatientsInterviewRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Edit patient with auth_id in JWT
   */
  async updateCurrentPatientRaw(
    requestParameters: UpdateCurrentPatientRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientPatient>> {
    if (requestParameters.patchPatientRequestBody === null || requestParameters.patchPatientRequestBody === undefined) {
      throw new runtime.RequiredError(
        "patchPatientRequestBody",
        "Required parameter requestParameters.patchPatientRequestBody was null or undefined when calling updateCurrentPatient."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.mode !== undefined) {
      queryParameters["mode"] = requestParameters.mode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient`,
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchPatientRequestBodyToJSON(requestParameters.patchPatientRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientPatientFromJSON(jsonValue));
  }

  /**
   * Edit patient with auth_id in JWT
   */
  async updateCurrentPatient(
    requestParameters: UpdateCurrentPatientRequest,
    initOverrides?: RequestInit
  ): Promise<PatientPatient> {
    return promiseRetry(
      (retry) =>
        this.updateCurrentPatientRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum CreateCurrentPatientModeEnum {
  Register = "register",
  Clinic = "clinic",
  PharmacyOffline = "pharmacy_offline",
  PharmacyOnline = "pharmacy_online",
}
/**
 * @export
 * @enum {string}
 */
export enum HeadPatientFieldEnum {
  Email = "email",
}
/**
 * @export
 * @enum {string}
 */
export enum UpdateCurrentPatientModeEnum {
  Password = "password",
  Email = "email",
  Profile = "profile",
  EmailVerified = "email_verified",
  InsuranceCard = "insurance_card",
}
