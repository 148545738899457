/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientMCCMJAHISSyncJobTypename = "PatientMCCMJAHISSyncJob" as const;

/**
 *
 * @export
 * @interface PatientMCCMJAHISSyncJob
 */
export interface PatientMCCMJAHISSyncJob {
  /**
   *
   * @type {number}
   * @memberof PatientMCCMJAHISSyncJob
   */
  chainId: number;
  /**
   *
   * @type {string}
   * @memberof PatientMCCMJAHISSyncJob
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof PatientMCCMJAHISSyncJob
   */
  patientId: number;
  /**
   *
   * @type {Date}
   * @memberof PatientMCCMJAHISSyncJob
   */
  registeredTime: string;
  /**
   *
   * @type {string}
   * @memberof PatientMCCMJAHISSyncJob
   */
  status: PatientMCCMJAHISSyncJobStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof PatientMCCMJAHISSyncJob
   */
  syncedTime?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMCCMJAHISSyncJob
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMCCMJAHISSyncJobStatusEnum {
  Initialized = "initialized",
  Processing = "processing",
  Synced = "synced",
  Failed = "failed",
}

export function PatientMCCMJAHISSyncJobFromJSON(json: any): PatientMCCMJAHISSyncJob {
  return PatientMCCMJAHISSyncJobFromJSONTyped(json, false);
}

export function PatientMCCMJAHISSyncJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMCCMJAHISSyncJob {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMCCMJAHISSyncJobTypename,

    chainId: json["chain_id"],
    id: json["id"],
    patientId: json["patient_id"],
    registeredTime: json["registered_time"],
    status: json["status"],
    syncedTime:
      !exists(json, "synced_time") ||
      (typeof json["synced_time"] === "string" && json["synced_time"].substring(0, 4) === "0001")
        ? undefined
        : json["synced_time"],
  };
}

export function PatientMCCMJAHISSyncJobToJSON(object?: PatientMCCMJAHISSyncJob | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    id: value.id,
    patient_id: value.patientId,
    registered_time: moment(value.registeredTime).tz("Asia/Tokyo").format(),
    status: value.status,
    synced_time: value.syncedTime === undefined ? undefined : moment(value.syncedTime).tz("Asia/Tokyo").format(),
  };
}
