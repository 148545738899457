import parseDate from "@mgdx/shared/src/utils/parseDate";
import { SenderTypeEnum, TalkMessageDocumentWithId, TalkRoomDocument } from "@mgdx/talk/models";
import moment from "@mgdx-libs/moment";
import includes from "lodash/includes";
import React, { useCallback, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";

import TalkGuide from "../TalkGuide";
import TalkMessage, { MessageTypeEnum } from "../TalkMessage";

export type OnVisibleUnreadMessage = (messageId: string) => void;

export type TalkContentProps = {
  room?: TalkRoomDocument;
  messages?: TalkMessageDocumentWithId[];
  talkSenderType: SenderTypeEnum;
  onVisibleUnreadMessage: OnVisibleUnreadMessage;
  containment: HTMLDivElement | null;
  onDidMount: () => void;
};

export const TalkContent = ({
  room,
  messages,
  talkSenderType,
  onVisibleUnreadMessage,
  containment,
  onDidMount,
}: TalkContentProps) => {
  const getIsSameDate = useCallback(
    (message: TalkMessageDocumentWithId, index: number) => {
      const prevMessage = messages && messages[index - 1];

      if (!prevMessage) return false;

      const prevSentDate = parseDate(prevMessage.sentAt);
      if (!prevSentDate) return false;

      const sentDate = parseDate(message.sentAt);
      if (!sentDate) return false;

      return prevSentDate.isSame(sentDate, "date");
    },
    [messages]
  );

  const getDateGuide = useCallback((message: TalkMessageDocumentWithId) => {
    const sentDate = parseDate(message.sentAt);
    if (!sentDate) return "";

    if (sentDate.isSame(moment(), "date")) {
      return "本日";
    }

    if (sentDate.isSame(moment().add(-1, "days"))) {
      return "昨日";
    }

    if (sentDate.isAfter(moment().add(-7, "days"))) {
      return sentDate.format("ddd");
    }

    if (sentDate.isSame(moment(), "years")) {
      return sentDate.format("MM月DD日 (ddd)");
    }

    return sentDate.format("YYYY年MM月DD日 (ddd)");
  }, []);

  const getMessageType = useCallback(
    (message: TalkMessageDocumentWithId) => {
      if (talkSenderType === message.senderType) {
        return MessageTypeEnum.Sent;
      }

      return MessageTypeEnum.Received;
    },
    [talkSenderType]
  );

  const getIsFirstMessage = useCallback(
    (message: TalkMessageDocumentWithId, index: number) => {
      const prevMessage = messages && messages[index - 1];

      if (!prevMessage) return true;
      if (prevMessage.senderType !== message.senderType) return true;

      const prevSentDate = parseDate(prevMessage.sentAt);
      if (!prevSentDate) return true;

      const sentDate = parseDate(message.sentAt);
      if (!sentDate) return true;

      return sentDate.diff(prevSentDate, "seconds") > 60;
    },
    [messages]
  );

  const getIsLastMessage = useCallback(
    (message: TalkMessageDocumentWithId, index: number) => {
      const nextMessage = messages && messages[index + 1];

      if (!nextMessage) return true;
      if (nextMessage.senderType !== message.senderType) return true;

      const sentDate = parseDate(message.sentAt);
      if (!sentDate) return true;

      const nextSentDate = parseDate(nextMessage.sentAt);
      if (!nextSentDate) return true;

      return nextSentDate.diff(sentDate, "seconds") > 60;
    },
    [messages]
  );

  const getIsOtherSideUnread = useCallback(
    (message: TalkMessageDocumentWithId) => {
      if (talkSenderType === SenderTypeEnum.Patient) {
        return includes(room?.medicalUnreadMessageIds, message.id);
      }

      return includes(room?.patientUnreadMessageIds, message.id);
    },
    [talkSenderType, room]
  );

  const getIsSenderUnread = useCallback(
    (message: TalkMessageDocumentWithId) => {
      if (talkSenderType === SenderTypeEnum.Patient) {
        return includes(room?.patientUnreadMessageIds, message.id);
      }

      return includes(room?.medicalUnreadMessageIds, message.id);
    },
    [talkSenderType, room]
  );

  useEffect(() => {
    onDidMount && onDidMount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!room) {
    return null;
  }

  if (!messages) {
    return null;
  }

  if (messages.length === 0) {
    return <TalkGuide message="トークルームが作成されました" />;
  }

  return (
    <React.Fragment>
      {messages.map((message, index) => {
        const isSameDate = getIsSameDate(message, index);
        const messageType = getMessageType(message);
        const isOtherSideUnread = getIsOtherSideUnread(message);
        const isSenderUnread = getIsSenderUnread(message);
        const isFirstMessage = getIsFirstMessage(message, index);
        const isLastMessage = getIsLastMessage(message, index);
        const handleVisibleChange = (isVisible: boolean) => {
          if (!isSenderUnread) return;
          if (!isVisible) return;

          onVisibleUnreadMessage(message.id);
        };

        return (
          <VisibilitySensor
            onChange={handleVisibleChange}
            key={message.id}
            scrollCheck={true}
            active={isSenderUnread}
            scrollThrottle={100}
            partialVisibility={true}
            containment={containment}
          >
            <React.Fragment>
              {!isSameDate && <TalkGuide message={getDateGuide(message)} />}
              <TalkMessage
                message={message}
                messageType={messageType}
                isFirstMessage={isFirstMessage}
                isLastMessage={isLastMessage}
                isUnread={isOtherSideUnread}
              />
            </React.Fragment>
          </VisibilitySensor>
        );
      })}
    </React.Fragment>
  );
};
