import { UseTalkRoomAndMessageCollection } from "@mgdx/talk/hooks";
import { TalkBox, TalkBoxProps } from "@mgdx/talk/ui/TalkBox";
import { TalkForm, TalkFormValues } from "@mgdx/talk/ui/TalkForm";
import React from "react";

import * as styles from "./TalkMessageListView.module.css";

export type TalkMessageListViewProps = Pick<TalkBoxProps, "onVisibleUnreadMessage" | "talkSenderType"> &
  ReturnType<UseTalkRoomAndMessageCollection> & {
    roomId: string;
    onSubmit: (values: TalkFormValues) => void | Promise<void>;
  };

export const TalkMessageListView: React.FC<TalkMessageListViewProps> = ({
  talkSenderType,
  room,
  messages,
  roomId,
  onSubmit,
  onVisibleUnreadMessage,
}) => (
  <div className={styles.layout}>
    <div className={styles.content}>
      <TalkBox
        roomId={roomId}
        talkSenderType={talkSenderType}
        room={room}
        messages={messages}
        onVisibleUnreadMessage={onVisibleUnreadMessage}
      />
    </div>

    <div className={styles.form}>
      <TalkForm onSubmit={onSubmit} />
    </div>
  </div>
);
