import React from "react";
import { PersistGate as PersistGateClient, PersistGateProps } from "redux-persist/integration/react";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";

export { persistStore } from "redux-persist";
export { persistReducer } from "redux-persist";
export { createMigrate } from "redux-persist";

const isBrowser = typeof window !== "undefined";

export const PersistGate: React.FC<PersistGateProps> = ({ children, ...props }) => {
  if (isBrowser) {
    return <PersistGateClient {...props}>{children}</PersistGateClient>;
  }

  return <>{children}</>;
};

const createNoopStorage = () => {
  return {
    getItem(_key: never) {
      return Promise.resolve(null);
    },
    setItem(_key: never, value: never) {
      return Promise.resolve(value);
    },
    removeItem(_key: never) {
      return Promise.resolve();
    },
  };
};

export const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();
export const session = typeof window !== "undefined" ? createWebStorage("session") : createNoopStorage();
