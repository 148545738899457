/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientVaccineTypename = "PatientVaccine" as const;

/**
 *
 * @export
 * @interface PatientVaccine
 */
export interface PatientVaccine {
  /**
   *
   * @type {string}
   * @memberof PatientVaccine
   */
  doctorName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientVaccine
   */
  inoculatedOn: string;
  /**
   *
   * @type {string}
   * @memberof PatientVaccine
   */
  location?: string;
  /**
   *
   * @type {string}
   * @memberof PatientVaccine
   */
  lotNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PatientVaccine
   */
  masterCode: string;
  /**
   *
   * @type {string}
   * @memberof PatientVaccine
   */
  masterName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientVaccine
   */
  quantity?: string;
  /**
   *
   * @type {string}
   * @memberof PatientVaccine
   */
  vaccineName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientVaccine
   */
  readonly __typename?: string;
}

export function PatientVaccineFromJSON(json: any): PatientVaccine {
  return PatientVaccineFromJSONTyped(json, false);
}

export function PatientVaccineFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientVaccine {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientVaccineTypename,

    doctorName: !exists(json, "doctor_name") ? undefined : json["doctor_name"],
    inoculatedOn: json["inoculated_on"],
    location: !exists(json, "location") ? undefined : json["location"],
    lotNumber: !exists(json, "lot_number") ? undefined : json["lot_number"],
    masterCode: json["master_code"],
    masterName: !exists(json, "master_name") ? undefined : json["master_name"],
    quantity: !exists(json, "quantity") ? undefined : json["quantity"],
    vaccineName: json["vaccine_name"],
  };
}

export function PatientVaccineToJSON(object?: PatientVaccine | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    doctor_name: value.doctorName,
    inoculated_on: value.inoculatedOn,
    location: value.location,
    lot_number: value.lotNumber,
    master_code: value.masterCode,
    master_name: value.masterName,
    quantity: value.quantity,
    vaccine_name: value.vaccineName,
  };
}
