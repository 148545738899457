/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorPatchCounselingRequestExaminer,
  PharmacyCounselorPatchCounselingRequestExaminerFromJSON,
  PharmacyCounselorPatchCounselingRequestExaminerFromJSONTyped,
  PharmacyCounselorPatchCounselingRequestExaminerToJSON,
} from "./";

export const PatchPatientRequestBodyTypename = "PatchPatientRequestBody" as const;

/**
 *
 * @export
 * @interface PatchPatientRequestBody
 */
export interface PatchPatientRequestBody {
  /**
   *
   * @type {PharmacyCounselorPatchCounselingRequestExaminer}
   * @memberof PatchPatientRequestBody
   */
  examiner?: PharmacyCounselorPatchCounselingRequestExaminer;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  firstName?: string;
  /**
   *
   * @type {number}
   * @memberof PatchPatientRequestBody
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  requestToken?: string;
  /**
   *
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  tel?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchPatientRequestBody
   */
  readonly __typename?: string;
}

export function PatchPatientRequestBodyFromJSON(json: any): PatchPatientRequestBody {
  return PatchPatientRequestBodyFromJSONTyped(json, false);
}

export function PatchPatientRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchPatientRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchPatientRequestBodyTypename,

    examiner: !exists(json, "examiner")
      ? undefined
      : PharmacyCounselorPatchCounselingRequestExaminerFromJSON(json["examiner"]),
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    id: !exists(json, "id") ? undefined : json["id"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    requestToken: !exists(json, "request_token") ? undefined : json["request_token"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
  };
}

export function PatchPatientRequestBodyToJSON(object?: PatchPatientRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    examiner: PharmacyCounselorPatchCounselingRequestExaminerToJSON(value.examiner),
    first_name: value.firstName,
    id: value.id,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    request_token: value.requestToken,
    tel: value.tel,
  };
}
