/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyGroupTypename = "PharmacyPharmacyGroup" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyGroup
 */
export interface PharmacyPharmacyGroup {
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyGroup
   */
  chainId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyGroup
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyGroup
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyGroup
   */
  name?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyGroup
   */
  readonly __typename?: string;
}

export function PharmacyPharmacyGroupFromJSON(json: any): PharmacyPharmacyGroup {
  return PharmacyPharmacyGroupFromJSONTyped(json, false);
}

export function PharmacyPharmacyGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPharmacyGroup {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyGroupTypename,

    chainId: !exists(json, "chain_id") ? undefined : json["chain_id"],
    description: !exists(json, "description") ? undefined : json["description"],
    id: json["id"],
    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function PharmacyPharmacyGroupToJSON(object?: PharmacyPharmacyGroup | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    description: value.description,
    id: value.id,
    name: value.name,
  };
}
