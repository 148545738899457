/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { PatientSymptom, PatientSymptomFromJSON, PatientSymptomFromJSONTyped, PatientSymptomToJSON } from "./";

export const PatientSideEffectTypename = "PatientSideEffect" as const;

/**
 *
 * @export
 * @interface PatientSideEffect
 */
export interface PatientSideEffect {
  /**
   *
   * @type {string}
   * @memberof PatientSideEffect
   */
  drugName: string;
  /**
   *
   * @type {string}
   * @memberof PatientSideEffect
   */
  occurredOn: string;
  /**
   *
   * @type {Array<PatientSymptom>}
   * @memberof PatientSideEffect
   */
  symptoms?: Array<PatientSymptom>;
  /**
   *
   * @type {string}
   * @memberof PatientSideEffect
   */
  yjCode?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientSideEffect
   */
  readonly __typename?: string;
}

export function PatientSideEffectFromJSON(json: any): PatientSideEffect {
  return PatientSideEffectFromJSONTyped(json, false);
}

export function PatientSideEffectFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientSideEffect {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientSideEffectTypename,

    drugName: json["drug_name"],
    occurredOn: json["occurred_on"],
    symptoms: !exists(json, "symptoms") ? undefined : (json["symptoms"] as Array<any>).map(PatientSymptomFromJSON),
    yjCode: !exists(json, "yj_code") ? undefined : json["yj_code"],
  };
}

export function PatientSideEffectToJSON(object?: PatientSideEffect | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    drug_name: value.drugName,
    occurred_on: value.occurredOn,
    symptoms: value.symptoms === undefined ? undefined : (value.symptoms as Array<any>).map(PatientSymptomToJSON),
    yj_code: value.yjCode,
  };
}
