import { ZendeskCollabrator, ZendeskCollabratorFromJSON, ZendeskCollabratorToJSON } from "./ZendeskCollabrator";
import {
  ZendeskCustomInputField,
  ZendeskCustomInputFieldFromJSON,
  ZendeskCustomInputFieldToJSON,
} from "./ZendeskCustomInputField";
import {
  ZendeskSatisfactionRating,
  ZendeskSatisfactionRatingFromJSON,
  ZendeskSatisfactionRatingToJSON,
} from "./ZendeskSatisfactionRating";
import { ZendeskVia, ZendeskViaFromJSON, ZendeskViaToJSON } from "./ZendeskVia";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function exists(json: any, key: string): boolean {
  const value = json[key];
  return value !== null && value !== undefined;
}

export const ZendeskTicketTypename = "ZendeskTicket" as const;

/**
 *
 * @export
 * @interface ZendeskTicket
 * @see https://developer.zendesk.com/api-reference/ticketing/tickets/tickets/#json-format
 */
export interface ZendeskTicket {
  /**
   * Permission for agents to add add attachments to a comment. Defaults to true
   * @type {boolean}
   * @memberof ZendeskTicket
   */
  allow_attachments: boolean;
  /**
   * Is false if channelback is disabled, true otherwise. Only applicable for channels framework ticket
   * @type {boolean}
   * @memberof ZendeskTicket
   */
  allow_channelback: boolean;
  /**
   * The agent currently assigned to the ticket
   * @type {number}
   * @memberof ZendeskTicket
   */
  assignee_id: number;
  /**
   * Enterprise only. The id of the brand this ticket is associated with
   * @type {number}
   * @memberof ZendeskTicket
   */
  brand_id: number;
  /**
   * The ids of users currently CC'ed on the ticket
   * @type {number[]}
   * @memberof ZendeskTicket
   */
  collaborator_ids: number[];
  /**
   * POST requests only. Users to add as cc's when creating a ticket. See Setting Collaborators
   * @type {ZendeskCollabrator|number|string}
   * @memberof ZendeskTicket
   */
  collaborators?: (ZendeskCollabrator | number | string)[];
  /**
   * When this record was created
   * @type {string}
   * @memberof ZendeskTicket
   */
  created_at: string;
  /**
   * Custom fields for the ticket. See Setting custom field values
   * @type {string}
   * @memberof ZendeskTicket
   */
  custom_fields?: ZendeskCustomInputField[];
  /**
   * Read-only first comment on the ticket. When creating a ticket, use comment to set the description. See Description and first comment
   * @type {string}
   * @memberof ZendeskTicket
   */
  description: string;
  /**
   * If this is a ticket of type "task" it has a due date. Due date format uses ISO 8601 format.
   * @type {string}
   * @memberof ZendeskTicket
   */
  due_at: string;
  /**
   * The ids of agents or end users currently CC'ed on the ticket. See CCs and followers resources in the Support Help Center
   * @type {string}
   * @memberof ZendeskTicket
   */
  email_cc_ids: string[];
  /**
   * An id you can use to link Zendesk Support tickets to local records
   * @type {string}
   * @memberof ZendeskTicket
   */
  external_id: string;
  /**
   * The ids of agents currently following the ticket. See CCs and followers resources
   * @type {number[]}
   * @memberof ZendeskTicket
   */
  follower_ids: number[];
  /**
   * The ids of the followups created from this ticket. Ids are only visible once the ticket is closed
   * @type {number[]}
   * @memberof ZendeskTicket
   */
  followup_ids: number[];
  /**
   * The topic in the Zendesk Web portal this ticket originated from, if any. The Web portal is deprecated
   * @type {number}
   * @memberof ZendeskTicket
   */
  forum_topic_id: number;
  /**
   * The group this ticket is assigned to
   * @type {number}
   * @memberof ZendeskTicket
   */
  group_id: number;
  /**
   * Is true if a ticket is a problem type and has one or more incidents linked to it. Otherwise, the value is false.
   * @type {boolean}
   * @memberof ZendeskTicket
   */
  has_incidents: boolean;
  /**
   * Automatically assigned when the ticket is created
   * @type {number}
   * @memberof ZendeskTicket
   */
  id: number;
  /**
   * Is true if any comments are public, false otherwise
   * @type {boolean}
   * @memberof ZendeskTicket
   */
  is_public: boolean;
  /**
   * POST requests only. List of macro IDs to be recorded in the ticket audit
   * @type {number[]}
   * @memberof ZendeskTicket
   */
  macro_ids: number[];
  /**
   * The organization of the requester. You can only specify the ID of an organization associated with the requester. See Organization Memberships
   * @type {number}
   * @memberof ZendeskTicket
   */
  organization_id: number;
  /**
   * The urgency with which the ticket should be addressed. Allowed values are "urgent", "high", "normal", or "low".
   * @type {string}
   * @memberof ZendeskTicket
   */
  priority: ZendeskTicketPriorityEnum;
  /**
   * For tickets of type "incident", the ID of the problem the incident is linked to
   * @type {number}
   * @memberof ZendeskTicket
   */
  problem_id: number;
  /**
   * The dynamic content placeholder, if present, or the "subject" value, if not. See Dynamic Content
   * @type {string}
   * @memberof ZendeskTicket
   */
  raw_subject: string;
  /**
   * The original recipient e-mail address of the ticket
   * @type {string}
   * @memberof ZendeskTicket
   */
  recipient: string;
  /**
   * The user who requested this ticket
   * @type {number}
   * @memberof ZendeskTicket
   */
  requester_id: number;
  /**
   * The satisfaction rating of the ticket, if it exists, or the state of satisfaction, "offered" or "unoffered". The value is null for plan types that don't support CSAT
   * @type {ZendeskSatisfactionRating | string | undefined}
   * @memberof ZendeskTicket
   */
  satisfaction_rating: ZendeskSatisfactionRating | string | undefined;
  /**
   * The ids of the sharing agreements used for this ticket
   * @type {number[]}
   * @memberof ZendeskTicket
   */
  sharing_agreement_ids: number[];
  /**
   * The state of the ticket. Allowed values are "new", "open", "pending", "hold", "solved", or "closed".
   * @type {string}
   * @memberof ZendeskTicket
   */
  status: ZendeskTicketStatusEnum;
  /**
   * The value of the subject field for this ticket
   * @type {string}
   * @memberof ZendeskTicket
   */
  subject: string;
  /**
   * The user who submitted the ticket. The submitter always becomes the author of the first comment on the ticket
   * @type {number}
   * @memberof ZendeskTicket
   */
  submitter_id: number;
  /**
   * The array of tags applied to this ticket
   * @type {number}
   * @memberof ZendeskTicket
   */
  tags: string[];
  /**
   * Enterprise only. The id of the ticket form to render for the ticket
   * @type {number}
   * @memberof ZendeskTicket
   */
  ticket_form_id: number;
  /**
   * The type of this ticket. Allowed values are "problem", "incident", "question", or "task".
   * @type {string}
   * @memberof ZendeskTicket
   */
  type: ZendeskTicketTypeEnum;
  /**
   * When this record last got updated
   * @type {string}
   * @memberof ZendeskTicket
   */
  updated_at: string;
  /**
   * The API url of this ticket
   * @type {string}
   * @memberof ZendeskTicket
   */
  url: string;
  /**
   * For more information, see the Via object reference
   * @type {ZendeskVia}
   * @memberof ZendeskTicket
   */
  via: ZendeskVia;
  /**
   * POST requests only. The id of a closed ticket when creating a follow-up ticket. See Creating a follow-up ticket
   * @type {number}
   * @memberof ZendeskTicket
   */
  via_followup_source_id: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskTicket
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskTicketFromJSON(json: any): ZendeskTicket {
  return ZendeskTicketFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskTicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskTicket {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ZendeskTicketTypename,
    allow_attachments: json["allow_attachments"],
    allow_channelback: json["allow_channelback"],
    assignee_id: json["assignee_id"],
    brand_id: json["brand_id"],
    collaborator_ids: json["collaborator_ids"],
    collaborators: !exists(json, "collaborators")
      ? undefined
      : (json["collaborators"] as Array<any>).map(ZendeskCollabratorFromJSON), // eslint-disable-line @typescript-eslint/no-explicit-any
    created_at: json["created_at"],
    custom_fields: !exists(json, "custom_fields")
      ? undefined
      : (json["custom_fields"] as Array<any>).map(ZendeskCustomInputFieldFromJSON), // eslint-disable-line @typescript-eslint/no-explicit-any
    description: json["description"],
    due_at: json["due_at"],
    email_cc_ids: json["email_cc_ids"],
    external_id: json["external_id"],
    follower_ids: json["follower_ids"],
    followup_ids: json["followup_ids"],
    forum_topic_id: json["forum_topic_id"],
    group_id: json["group_id"],
    has_incidents: json["has_incidents"],
    id: json["id"],
    is_public: json["is_public"],
    macro_ids: json["macro_ids"],
    organization_id: json["organization_id"],
    priority: json["priority"],
    problem_id: json["problem_id"],
    raw_subject: json["raw_subject"],
    recipient: json["recipient"],
    requester_id: json["requester_id"],
    satisfaction_rating: !exists(json, "satisfaction_rating")
      ? undefined
      : ZendeskSatisfactionRatingFromJSON(json["satisfaction_rating"]),
    sharing_agreement_ids: json["sharing_agreement_ids"],
    status: json["status"],
    subject: json["subject"],
    submitter_id: json["submitter_id"],
    tags: json["tags"],
    ticket_form_id: json["ticket_form_id"],
    type: json["type"],
    updated_at: json["updated_at"],
    url: json["url"],
    via: ZendeskViaFromJSON(json["via"]),
    via_followup_source_id: json["via_followup_source_id"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskTicketToJSON(object?: ZendeskTicket | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    allow_attachments: value.allow_attachments,
    allow_channelback: value.allow_channelback,
    assignee_id: value.assignee_id,
    brand_id: value.brand_id,
    collaborator_ids: value.collaborator_ids,
    collaborators: Array.isArray(value.collaborators)
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (value.collaborators as Array<any>).map((collaborator) =>
          typeof collaborator === "string" || typeof collaborator === "number"
            ? collaborator
            : ZendeskCollabratorToJSON(collaborator)
        )
      : undefined,
    created_at: value.created_at,
    custom_fields: Array.isArray(value.custom_fields)
      ? (value.custom_fields as Array<any>).map(ZendeskCustomInputFieldToJSON) // eslint-disable-line @typescript-eslint/no-explicit-any
      : undefined,
    description: value.description,
    due_at: value.due_at,
    email_cc_ids: value.email_cc_ids,
    external_id: value.external_id,
    follower_ids: value.follower_ids,
    followup_ids: value.followup_ids,
    forum_topic_id: value.forum_topic_id,
    group_id: value.group_id,
    has_incidents: value.has_incidents,
    id: value.id,
    is_public: value.is_public,
    macro_ids: value.macro_ids,
    organization_id: value.organization_id,
    priority: value.priority,
    problem_id: value.problem_id,
    raw_subject: value.raw_subject,
    recipient: value.recipient,
    requester_id: value.requester_id,
    satisfaction_rating:
      typeof value.satisfaction_rating === "string"
        ? value.satisfaction_rating
        : ZendeskSatisfactionRatingToJSON(value.satisfaction_rating),
    sharing_agreement_ids: value.sharing_agreement_ids,
    status: value.status,
    subject: value.subject,
    submitter_id: value.submitter_id,
    tags: value.tags,
    ticket_form_id: value.ticket_form_id,
    type: value.type,
    updated_at: value.updated_at,
    url: value.url,
    via: ZendeskViaToJSON(value.via),
    via_followup_source_id: value.via_followup_source_id,
  };
}

export enum ZendeskTicketStatusEnum {
  New = "new",
  Open = "open",
  Pending = "pending",
  Hold = "hold",
  Solved = "solved",
  Closed = "closed",
}

export enum ZendeskTicketTypeEnum {
  Problem = "problem",
  Incident = "incident",
  Question = "question",
  Task = "task",
}

export enum ZendeskTicketPriorityEnum {
  Urgent = "urgent",
  High = "high",
  Normal = "normal",
  Low = "low",
}
