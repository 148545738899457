import { ClinicCounselorExaminer } from "@mgdx/api/lib/clinic/counselor";
import {
  CreateCurrentPatientModeEnum as PatientCreateModeEnum,
  PatchFamilyAccountRequestBody,
  PatchFamilyAccountRequestBodyGenderEnum as FamilyAccountUpdateGenderEnum,
  PatchPatientRequestBody,
  PatchPatientRequestBodyGenderEnum,
  PatchPatientRequestBodyGenderEnum as PatientUpdateGenderEnum,
  PatchPatientRequestBodyHasMedicineNotebookEnum as PatientUpdateHasMedicineNotebookEnum,
  PatchPatientRequestBodyNotifyTypeEnum as PatientRegisteryNotifyTypeEnum,
  PatchPatientRequestBodyNotifyTypeEnum as PatientUpdateNotifyTypeEnum,
  PatchPatientRequestBodyWantsGenericEnum as PatientUpdateWantsGenericEnum,
  PatientClinicVerifyEmailOptionVisitEnum,
  PatientFamilyAccount,
  PatientFamilyAccountGenderEnum as FamilyAccountGenderEnum,
  PatientInterview,
  PatientMedicalHistoryStatusEnum,
  PatientPatient,
  PatientPatientGenderEnum as PatientGenderEnum,
  PatientPatientHasMedicineNotebookEnum as PatientHasMedicineNotebookEnum,
  PatientPatientLoginProviderTypeEnum as PatientProviderTypeEnum,
  PatientPatientNotifyTypeEnum as PatientNotifyTypeEnum,
  PatientPatientStatusEnum as PatientStatusEnum,
  PatientPatientWantsGenericEnum as PatientWantsGenericEnum,
  PatientPostFamilyAccountUpsert,
  PatientPostFamilyAccountUpsertGenderEnum as FamilyAccountUpserGenderEnum,
  PatientVerifyEmailOption as _PatientVerifyEmailOption,
  PostFamilyAccountRequestBody,
  PostFamilyAccountRequestBodyGenderEnum as FamilyAccountCreateGenderEnum,
  PostFamilyAccountsUpsertRequestBody,
  PostPatientRequestBodyGenderEnum as PatientCreateGenderEnum,
  PutInterviewRequest,
  PutMedicineNotebookByPatientRequestBodyGenderEnum as MedicineNotebookGenderEnum,
  PutMedicineNotebookByPatientRequestBodyGenderEnum,
  PutPatientPasswordRequestBody,
  PutPatientStatusRequestBodyStatusEnum as PatientUpdateStatusEnum,
} from "@mgdx/api/lib/patient/patient";
import { PharmacyCounselorExaminer } from "@mgdx/api/lib/pharmacy/counselor";
import { TalkRoomDocumentPatient } from "@mgdx/talk/models";
import { Moment } from "@mgdx-libs/moment";
import flatten from "lodash/flatten";
import padStart from "lodash/padStart";
import toInteger from "lodash/toInteger";
import toString from "lodash/toString";
import moment from "moment";

import parseDate from "../utils/parseDate";
import { ClinicPatient } from "./ClinicCounseling";
import { PharmacyPatient } from "./PharmacyCounseling";
import { TextMessagePatient } from "./TextMessage";

export type Patient = PatientPatient;
export type PatientUpdateAttributes = PatchPatientRequestBody;
export type PatientUpdatePasswordAttributes = PutPatientPasswordRequestBody;
export type PatientVerifyEmailOption = _PatientVerifyEmailOption;
export type FamilyAccount = PatientFamilyAccount;
export type FamilyAccountCreateAttributes = PostFamilyAccountRequestBody;
export type FamilyAccountUpdateAttributes = PatchFamilyAccountRequestBody;
export type FamilyAccountUpsertAttributes = PatientPostFamilyAccountUpsert;
export type FamilyAccountsUpsertAttributes = PostFamilyAccountsUpsertRequestBody;
export type PatientCustomInterview = PatientInterview;
export type PatientUpdateInterviewAttributes = PutInterviewRequest;

export {
  FamilyAccountCreateGenderEnum,
  FamilyAccountGenderEnum,
  FamilyAccountUpdateGenderEnum,
  FamilyAccountUpserGenderEnum,
  MedicineNotebookGenderEnum,
  PatientClinicVerifyEmailOptionVisitEnum,
  PatientCreateGenderEnum,
  PatientCreateModeEnum,
  PatientGenderEnum,
  PatientHasMedicineNotebookEnum,
  PatientNotifyTypeEnum,
  PatientProviderTypeEnum,
  PatientRegisteryNotifyTypeEnum,
  PatientStatusEnum,
  PatientUpdateGenderEnum,
  PatientUpdateHasMedicineNotebookEnum,
  PatientUpdateNotifyTypeEnum,
  PatientUpdateStatusEnum,
  PatientUpdateWantsGenericEnum,
  PatientWantsGenericEnum,
};

export type FullNameOptions = {
  withSeparator?: boolean;
  hiraToKata?: boolean;
};

type CounselorPatient = PharmacyPatient | ClinicPatient | undefined | null;

export type FullName = (
  patient:
    | Patient
    | PharmacyPatient
    | ClinicPatient
    | TalkRoomDocumentPatient
    | PharmacyCounselorExaminer
    | ClinicCounselorExaminer
    | FamilyAccount
    | TextMessagePatient
    | undefined
    | null,
  options?: FullNameOptions
) => string;

export const fullName: FullName = (patient, options = {}) => {
  if (!patient) return "";
  if (!patient.lastName && !patient.firstName) return "";

  if (options.withSeparator) {
    return `${patient.lastName} ${patient.firstName}`;
  }

  return `${patient.lastName}${patient.firstName}`;
};

export const fullKanaName: FullName = (patient, options = {}) => {
  if (!patient) return "";
  if (!patient.kanaLastName && !patient.kanaFirstName) return "";

  const kanaLastName = options.hiraToKata ? convertHiraToKata(patient.kanaLastName) : patient.kanaLastName || "";
  const kanaFirstName = options.hiraToKata ? convertHiraToKata(patient.kanaFirstName) : patient.kanaFirstName || "";

  let kanaName;
  if (options.withSeparator) {
    kanaName = `${kanaLastName} ${kanaFirstName}`;
  } else {
    kanaName = `${kanaLastName}${kanaFirstName}`;
  }

  return kanaName;
};

export const fullNameWithKana: FullName = (patient, options = {}) => {
  if (!patient) return "";

  if (!patient.kanaLastName) return fullName(patient, options);
  if (!patient.kanaFirstName) return fullName(patient, options);

  return `${fullName(patient, options)} (${fullKanaName(patient, options)})`;
};

export type FullExaminerName = (patient: CounselorPatient, options?: FullNameOptions) => string;

export const fullExaminerName: FullExaminerName = (patient, options = {}) => {
  if (!patient) return "";

  if (patient.examiner) {
    return fullName(patient.examiner, options);
  }

  return fullName(patient, options);
};

export const fullExaminerKanaName: FullExaminerName = (patient, options = {}) => {
  if (!patient) return "";

  if (patient.examiner) {
    return fullKanaName(patient.examiner, options);
  }

  return fullKanaName(patient, options);
};

export const fullExaminerNameWithKana: FullExaminerName = (patient, options = {}) => {
  if (!patient) return "";

  if (patient.examiner) {
    return fullNameWithKana(patient.examiner, options);
  }

  return fullNameWithKana(patient, options);
};

export const convertHiraToKata = (str?: string): string => {
  if (!str) return "";

  return str.replace(/[\u3041-\u3096]/g, (char) => String.fromCharCode(char.charCodeAt(0) + 0x60));
};

export const statusEnumToText = (status: string): string => {
  if (status === PatientStatusEnum.Registered) {
    return "登録済み (メール未認証)";
  }

  if (status === PatientStatusEnum.Verified) {
    return "登録済み";
  }

  return "";
};

export const examinerBirthdayToText = (patient: CounselorPatient): string => {
  if (patient?.examiner?.dateOfBirth) {
    return birthdayToText(patient.examiner.dateOfBirth);
  }

  return "";
};

export const birthdayToText = (birthday?: string): string => {
  if (!birthday) return "";

  const date = parseDate(birthday);

  if (!date?.isValid()) return "";

  return date.format("YYYY 年 MM 月 DD 日");
};

export const birthdayToSingleDigitText = (birthday?: string): string => {
  if (!birthday) return "";

  const date = parseDate(birthday);

  if (!date?.isValid()) return "";

  return date.format("YYYY 年 Mo 月 D 日");
};

export const examinerBirthdayToAdminText = (patient: CounselorPatient): string => {
  if (patient?.examiner?.dateOfBirth) {
    return birthdayToAdminText(patient.examiner.dateOfBirth);
  }

  return "";
};

export const birthdayToAdminText = (birthday?: string): string => {
  if (!birthday) return "";

  const date = parseDate(birthday);

  if (!date?.isValid()) return "";

  return date.format("YYYY/MM/DD");
};

export const birthdayToString = (birthday?: string): string => {
  if (!birthday) return "";

  const date = parseDate(birthday);

  if (!date?.isValid()) return "";

  return date.format("YYYY-MM-DD");
};

export const examinerBirthdayToAgeText = (patient: CounselorPatient): string => {
  if (patient?.examiner?.dateOfBirth) {
    return birthdayToAgeText(patient.examiner.dateOfBirth);
  }

  return "";
};

export const birthdayToAgeText = (birthday?: string): string => {
  const dateOfBirth = parseDate(`${birthday}T00:00:00+09:00`);
  if (!dateOfBirth) return "";

  const today = moment();
  const durationMonths = Math.floor(moment.duration(today.diff(dateOfBirth)).as("months"));
  const years = Math.floor(durationMonths / 12);
  const months = Math.floor(durationMonths % 12);

  if (years > 11) {
    return `${years}歳`;
  }

  return `${years}歳${months}か月`;
};

export const birthdayToParams = (
  birthday?: string
): { year: string; month: string; day: string; dateOfBirth: string } | undefined => {
  if (!birthday) return undefined;

  const date = moment(birthday);

  if (!date.isValid()) return undefined;

  return {
    year: toString(date.year()),
    month: toString(date.month() + 1),
    day: toString(date.date()),
    dateOfBirth: date.format("YYYY-MM-DD"),
  };
};

export const paramsToBirthday = (
  year: string | number,
  month: string | number,
  date: string | number
): Moment | undefined => {
  if (!year || !month || !date) return undefined;

  const d = moment().set({ year: toInteger(year), month: toInteger(month) - 1, date: toInteger(date) });

  if (!d.isValid()) return undefined;

  return d;
};

export const paramsToDateString = (year?: string | number, month?: string | number, date?: string | number): string => {
  if (!year && !month && !date) return "";

  const month2digits = month ? padStart(String(month), 2, "0") : "00";
  const date2digits = date ? padStart(String(date), 2, "0") : "00";

  return `${year}-${month2digits}-${date2digits}`;
};

export const telToText = (tel?: string): string => {
  if (!tel) return "";

  return tel.replace("+81", "0");
};

export const smsAvailableToText = (smsAvailable?: boolean): string => {
  if (smsAvailable) return "利用できる";

  return "利用できない";
};

export const sendFollowUpToText = (sendFollowUp?: boolean): string => {
  if (sendFollowUp) return "通知する";

  return "通知しない";
};

export const notifyTypeToText = (notifyType?: PatientNotifyTypeEnum): string => {
  if (!notifyType) return "";

  switch (notifyType) {
    case PatientNotifyTypeEnum.EmailOrSms:
      return "メールとSMSで受け取る";
    case PatientNotifyTypeEnum.App:
      return "プッシュ通知で受け取る";
    case PatientNotifyTypeEnum.All:
      return "メールとSMS、プッシュ通知で受け取る";
  }
};

type GenderOption = {
  isShorten?: boolean;
};

export const genderToText = (
  gender?:
    | PatientGenderEnum
    | PatientCreateGenderEnum
    | FamilyAccountGenderEnum
    | FamilyAccountUpserGenderEnum
    | string,
  option?: GenderOption
) => {
  switch (gender) {
    case PatientGenderEnum.Female:
    case PatientCreateGenderEnum.Female:
    case FamilyAccountGenderEnum.Female:
    case FamilyAccountUpserGenderEnum.Female:
      return option?.isShorten ? "女" : "女性";
    case PatientGenderEnum.Male:
    case PatientCreateGenderEnum.Male:
    case FamilyAccountGenderEnum.Male:
    case FamilyAccountUpserGenderEnum.Male:
      return option?.isShorten ? "男" : "男性";
    case PatientGenderEnum.None:
    case PatientCreateGenderEnum.None:
    case FamilyAccountGenderEnum.None:
    case FamilyAccountUpserGenderEnum.None:
      return option?.isShorten ? "" : "未回答";
  }

  return option?.isShorten ? "" : "未設定";
};

export const convertPatientGenderEnumToMedicineNotebookGenderEnum = (
  gender?: PatientGenderEnum
): MedicineNotebookGenderEnum => {
  switch (gender) {
    case PatientGenderEnum.Female:
      return MedicineNotebookGenderEnum.Female;
    case PatientGenderEnum.Male:
      return MedicineNotebookGenderEnum.Male;
    case PatientGenderEnum.None:
      return MedicineNotebookGenderEnum.None;
    default:
      return MedicineNotebookGenderEnum.None;
  }
};

export const convertMedicineNotebookGenderEnumToPatchPatientRequestBodyGenderEnum = (
  gender?: MedicineNotebookGenderEnum
): PatchPatientRequestBodyGenderEnum => {
  switch (gender) {
    case MedicineNotebookGenderEnum.Female:
      return PatchPatientRequestBodyGenderEnum.Female;
    case MedicineNotebookGenderEnum.Male:
      return PatchPatientRequestBodyGenderEnum.Male;
    case MedicineNotebookGenderEnum.None:
      return PatchPatientRequestBodyGenderEnum.None;
    default:
      return PatchPatientRequestBodyGenderEnum.None;
  }
};

export const convertPutMedicineNotebookRequestGenderEnumToCreateMedicineNotebookRequestGenderEnum = (
  gender?: FamilyAccountGenderEnum
): PutMedicineNotebookByPatientRequestBodyGenderEnum => {
  switch (gender) {
    case FamilyAccountGenderEnum.Female:
      return PutMedicineNotebookByPatientRequestBodyGenderEnum.Female;
    case FamilyAccountGenderEnum.Male:
      return PutMedicineNotebookByPatientRequestBodyGenderEnum.Male;
    case FamilyAccountGenderEnum.None:
      return PutMedicineNotebookByPatientRequestBodyGenderEnum.None;
    default:
      return PutMedicineNotebookByPatientRequestBodyGenderEnum.None;
  }
};

export const hasMedicineNotebookAutoLink = (
  patient: Patient,
  pharmacyId: number,
  options?: {
    familyAccountId?: number;
  }
): boolean => {
  const familyAccountId = toInteger(options?.familyAccountId);
  const familyAccounts = patient.familyAccount || [];
  const medicineNotebooks =
    (familyAccountId
      ? familyAccounts.find(({ id }) => id === familyAccountId)?.medicineNotebooks
      : patient.medicineNotebooks) || [];
  const autoLinkPharmacyIds = flatten(medicineNotebooks.map(({ autoLinkPharmacyIds }) => autoLinkPharmacyIds || []));
  return autoLinkPharmacyIds.includes(toInteger(pharmacyId));
};

export const medicalHistoryStatusEnumToText = (status: PatientMedicalHistoryStatusEnum): string => {
  switch (status) {
    case PatientMedicalHistoryStatusEnum.Attending:
      return "通院中";
    case PatientMedicalHistoryStatusEnum.Healed:
      return "治療済み";
  }
};
