/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyFollowUpQuestionnaire,
  PharmacyFollowUpQuestionnaireFromJSON,
  PharmacyFollowUpQuestionnaireFromJSONTyped,
  PharmacyFollowUpQuestionnaireToJSON,
} from "./";

export const PharmacyFollowUpQuestionnairePatternRegistrableTypename =
  "PharmacyFollowUpQuestionnairePatternRegistrable" as const;

/**
 *
 * @export
 * @interface PharmacyFollowUpQuestionnairePatternRegistrable
 */
export interface PharmacyFollowUpQuestionnairePatternRegistrable {
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpQuestionnairePatternRegistrable
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpQuestionnairePatternRegistrable
   */
  orderNumber?: number;
  /**
   *
   * @type {Array<PharmacyFollowUpQuestionnaire>}
   * @memberof PharmacyFollowUpQuestionnairePatternRegistrable
   */
  questionnaires: Array<PharmacyFollowUpQuestionnaire>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyFollowUpQuestionnairePatternRegistrable
   */
  readonly __typename?: string;
}

export function PharmacyFollowUpQuestionnairePatternRegistrableFromJSON(
  json: any
): PharmacyFollowUpQuestionnairePatternRegistrable {
  return PharmacyFollowUpQuestionnairePatternRegistrableFromJSONTyped(json, false);
}

export function PharmacyFollowUpQuestionnairePatternRegistrableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyFollowUpQuestionnairePatternRegistrable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyFollowUpQuestionnairePatternRegistrableTypename,

    name: json["name"],
    orderNumber: !exists(json, "order_number") ? undefined : json["order_number"],
    questionnaires: (json["questionnaires"] as Array<any>).map(PharmacyFollowUpQuestionnaireFromJSON),
  };
}

export function PharmacyFollowUpQuestionnairePatternRegistrableToJSON(
  object?: PharmacyFollowUpQuestionnairePatternRegistrable | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    name: value.name,
    order_number: value.orderNumber,
    questionnaires: (value.questionnaires as Array<any>).map(PharmacyFollowUpQuestionnaireToJSON),
  };
}
