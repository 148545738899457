/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorCounselingPatient,
  PharmacyCounselorCounselingPatientFromJSON,
  PharmacyCounselorCounselingPatientFromJSONTyped,
  PharmacyCounselorCounselingPatientToJSON,
  PharmacyCounselorFollowUp,
  PharmacyCounselorFollowUpFromJSON,
  PharmacyCounselorFollowUpFromJSONTyped,
  PharmacyCounselorFollowUpToJSON,
} from "./";

export const PutFollowUpAnswerResponseTypename = "PutFollowUpAnswerResponse" as const;

/**
 *
 * @export
 * @interface PutFollowUpAnswerResponse
 */
export interface PutFollowUpAnswerResponse {
  /**
   *
   * @type {PharmacyCounselorFollowUp}
   * @memberof PutFollowUpAnswerResponse
   */
  followUp?: PharmacyCounselorFollowUp;
  /**
   *
   * @type {PharmacyCounselorCounselingPatient}
   * @memberof PutFollowUpAnswerResponse
   */
  patient?: PharmacyCounselorCounselingPatient;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutFollowUpAnswerResponse
   */
  readonly __typename?: string;
}

export function PutFollowUpAnswerResponseFromJSON(json: any): PutFollowUpAnswerResponse {
  return PutFollowUpAnswerResponseFromJSONTyped(json, false);
}

export function PutFollowUpAnswerResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutFollowUpAnswerResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutFollowUpAnswerResponseTypename,

    followUp: !exists(json, "follow_up") ? undefined : PharmacyCounselorFollowUpFromJSON(json["follow_up"]),
    patient: !exists(json, "patient") ? undefined : PharmacyCounselorCounselingPatientFromJSON(json["patient"]),
  };
}

export function PutFollowUpAnswerResponseToJSON(object?: PutFollowUpAnswerResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    follow_up: PharmacyCounselorFollowUpToJSON(value.followUp),
    patient: PharmacyCounselorCounselingPatientToJSON(value.patient),
  };
}
