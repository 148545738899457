/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ChatterVideoChatInformation,
  ChatterVideoChatInformationFromJSON,
  ChatterVideoChatInformationFromJSONTyped,
  ChatterVideoChatInformationToJSON,
  ChatterVideoChatPatient,
  ChatterVideoChatPatientFromJSON,
  ChatterVideoChatPatientFromJSONTyped,
  ChatterVideoChatPatientToJSON,
} from "./";

export const ChatterVideoChatTypename = "ChatterVideoChat" as const;

/**
 *
 * @export
 * @interface ChatterVideoChat
 */
export interface ChatterVideoChat {
  /**
   *
   * @type {number}
   * @memberof ChatterVideoChat
   */
  clinicId?: number;
  /**
   *
   * @type {number}
   * @memberof ChatterVideoChat
   */
  counselingId?: number;
  /**
   *
   * @type {Date}
   * @memberof ChatterVideoChat
   */
  expiredTime: string;
  /**
   *
   * @type {string}
   * @memberof ChatterVideoChat
   */
  id: string;
  /**
   *
   * @type {ChatterVideoChatInformation}
   * @memberof ChatterVideoChat
   */
  information: ChatterVideoChatInformation;
  /**
   *
   * @type {ChatterVideoChatPatient}
   * @memberof ChatterVideoChat
   */
  patient: ChatterVideoChatPatient;
  /**
   *
   * @type {number}
   * @memberof ChatterVideoChat
   */
  pharmacyId?: number;
  /**
   *
   * @type {Date}
   * @memberof ChatterVideoChat
   */
  registeredTime: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterVideoChat
   */
  readonly __typename?: string;
}

export function ChatterVideoChatFromJSON(json: any): ChatterVideoChat {
  return ChatterVideoChatFromJSONTyped(json, false);
}

export function ChatterVideoChatFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatterVideoChat {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterVideoChatTypename,

    clinicId: !exists(json, "clinic_id") ? undefined : json["clinic_id"],
    counselingId: !exists(json, "counseling_id") ? undefined : json["counseling_id"],
    expiredTime: json["expired_time"],
    id: json["id"],
    information: ChatterVideoChatInformationFromJSON(json["information"]),
    patient: ChatterVideoChatPatientFromJSON(json["patient"]),
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
    registeredTime: json["registered_time"],
  };
}

export function ChatterVideoChatToJSON(object?: ChatterVideoChat | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    clinic_id: value.clinicId,
    counseling_id: value.counselingId,
    expired_time: moment(value.expiredTime).tz("Asia/Tokyo").format(),
    id: value.id,
    information: ChatterVideoChatInformationToJSON(value.information),
    patient: ChatterVideoChatPatientToJSON(value.patient),
    pharmacy_id: value.pharmacyId,
    registered_time: moment(value.registeredTime).tz("Asia/Tokyo").format(),
  };
}
