/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientMissionCompletedEventTypename = "PatientMissionCompletedEvent" as const;

/**
 *
 * @export
 * @interface PatientMissionCompletedEvent
 */
export interface PatientMissionCompletedEvent {
  /**
   *
   * @type {string}
   * @memberof PatientMissionCompletedEvent
   */
  eventType: PatientMissionCompletedEventEventTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMissionCompletedEvent
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMissionCompletedEventEventTypeEnum {
  MedicineNotebookAutoLinked = "medicine_notebook_auto_linked",
  FollowUpAnswered = "follow_up_answered",
  CounselingDispensingCompleted = "counseling_dispensing_completed",
  CounselingOnlineCounselingCompleted = "counseling_online_counseling_completed",
}

export function PatientMissionCompletedEventFromJSON(json: any): PatientMissionCompletedEvent {
  return PatientMissionCompletedEventFromJSONTyped(json, false);
}

export function PatientMissionCompletedEventFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMissionCompletedEvent {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMissionCompletedEventTypename,

    eventType: json["event_type"],
  };
}

export function PatientMissionCompletedEventToJSON(object?: PatientMissionCompletedEvent | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    event_type: value.eventType,
  };
}
