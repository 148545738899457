/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicDepartmentFeed,
  ClinicDepartmentFeedFromJSON,
  ClinicDepartmentFeedFromJSONTyped,
  ClinicDepartmentFeedToJSON,
  ClinicMeritTagsFeed,
  ClinicMeritTagsFeedFromJSON,
  ClinicMeritTagsFeedFromJSONTyped,
  ClinicMeritTagsFeedToJSON,
  ClinicSNSFeed,
  ClinicSNSFeedFromJSON,
  ClinicSNSFeedFromJSONTyped,
  ClinicSNSFeedToJSON,
  ClinicSymptomFeed,
  ClinicSymptomFeedFromJSON,
  ClinicSymptomFeedFromJSONTyped,
  ClinicSymptomFeedToJSON,
} from "./";

export const ClinicClinicFeedTypename = "ClinicClinicFeed" as const;

/**
 *
 * @export
 * @interface ClinicClinicFeed
 */
export interface ClinicClinicFeed {
  /**
   *
   * @type {boolean}
   * @memberof ClinicClinicFeed
   */
  acceptFirstVisit?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  address?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClinicClinicFeed
   */
  availableToday?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicFeed
   */
  businessHours?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  cityCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicFeed
   */
  closedDays?: Array<string>;
  /**
   *
   * @type {Array<ClinicDepartmentFeed>}
   * @memberof ClinicClinicFeed
   */
  departments?: Array<ClinicDepartmentFeed>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  homepageUrl?: string;
  /**
   *
   * @type {number}
   * @memberof ClinicClinicFeed
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  imageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  institutionCode?: string;
  /**
   *
   * @type {number}
   * @memberof ClinicClinicFeed
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof ClinicClinicFeed
   */
  longitude?: number;
  /**
   *
   * @type {ClinicMeritTagsFeed}
   * @memberof ClinicClinicFeed
   */
  meritTags?: ClinicMeritTagsFeed;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  name?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicFeed
   */
  nearestStations?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  note?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicFeed
   */
  onlinePaymentMethods?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinicFeed
   */
  paymentMethods?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  prefCode?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  recommendation?: string;
  /**
   *
   * @type {ClinicSNSFeed}
   * @memberof ClinicClinicFeed
   */
  sns?: ClinicSNSFeed;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  sourceClinicId: string;
  /**
   *
   * @type {Array<ClinicSymptomFeed>}
   * @memberof ClinicClinicFeed
   */
  symptoms?: Array<ClinicSymptomFeed>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  tel?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicClinicFeed
   */
  readonly __typename?: string;
}

export function ClinicClinicFeedFromJSON(json: any): ClinicClinicFeed {
  return ClinicClinicFeedFromJSONTyped(json, false);
}

export function ClinicClinicFeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicClinicFeed {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicClinicFeedTypename,

    acceptFirstVisit: !exists(json, "accept_first_visit") ? undefined : json["accept_first_visit"],
    address: !exists(json, "address") ? undefined : json["address"],
    availableToday: !exists(json, "available_today") ? undefined : json["available_today"],
    businessHours: !exists(json, "business_hours") ? undefined : json["business_hours"],
    cityCode: !exists(json, "city_code") ? undefined : json["city_code"],
    closedDays: !exists(json, "closed_days") ? undefined : json["closed_days"],
    departments: !exists(json, "departments")
      ? undefined
      : (json["departments"] as Array<any>).map(ClinicDepartmentFeedFromJSON),
    homepageUrl: !exists(json, "homepage_url") ? undefined : json["homepage_url"],
    id: json["id"],
    imageUrl: !exists(json, "image_url") ? undefined : json["image_url"],
    institutionCode: !exists(json, "institution_code") ? undefined : json["institution_code"],
    latitude: !exists(json, "latitude") ? undefined : json["latitude"] == "" ? undefined : json["latitude"],
    longitude: !exists(json, "longitude") ? undefined : json["longitude"] == "" ? undefined : json["longitude"],
    meritTags: !exists(json, "merit_tags") ? undefined : ClinicMeritTagsFeedFromJSON(json["merit_tags"]),
    name: !exists(json, "name") ? undefined : json["name"],
    nearestStations: !exists(json, "nearest_stations") ? undefined : json["nearest_stations"],
    note: !exists(json, "note") ? undefined : json["note"],
    onlinePaymentMethods: !exists(json, "online_payment_methods") ? undefined : json["online_payment_methods"],
    paymentMethods: !exists(json, "payment_methods") ? undefined : json["payment_methods"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    prefCode: !exists(json, "pref_code") ? undefined : json["pref_code"],
    recommendation: !exists(json, "recommendation") ? undefined : json["recommendation"],
    sns: !exists(json, "sns") ? undefined : ClinicSNSFeedFromJSON(json["sns"]),
    source: json["source"],
    sourceClinicId: json["source_clinic_id"],
    symptoms: !exists(json, "symptoms") ? undefined : (json["symptoms"] as Array<any>).map(ClinicSymptomFeedFromJSON),
    tel: !exists(json, "tel") ? undefined : json["tel"],
  };
}

export function ClinicClinicFeedToJSON(object?: ClinicClinicFeed | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    accept_first_visit: value.acceptFirstVisit,
    address: value.address,
    available_today: value.availableToday,
    business_hours: value.businessHours,
    city_code: value.cityCode,
    closed_days: value.closedDays,
    departments:
      value.departments === undefined ? undefined : (value.departments as Array<any>).map(ClinicDepartmentFeedToJSON),
    homepage_url: value.homepageUrl,
    id: value.id,
    image_url: value.imageUrl,
    institution_code: value.institutionCode,
    latitude: (value.latitude as unknown as string) == "" ? undefined : value.latitude,
    longitude: (value.longitude as unknown as string) == "" ? undefined : value.longitude,
    merit_tags: ClinicMeritTagsFeedToJSON(value.meritTags),
    name: value.name,
    nearest_stations: value.nearestStations,
    note: value.note,
    online_payment_methods: value.onlinePaymentMethods,
    payment_methods: value.paymentMethods,
    postal_code: value.postalCode,
    pref_code: value.prefCode,
    recommendation: value.recommendation,
    sns: ClinicSNSFeedToJSON(value.sns),
    source: value.source,
    source_clinic_id: value.sourceClinicId,
    symptoms: value.symptoms === undefined ? undefined : (value.symptoms as Array<any>).map(ClinicSymptomFeedToJSON),
    tel: value.tel,
  };
}
