/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyMeritTags,
  PharmacyMeritTagsFromJSON,
  PharmacyMeritTagsFromJSONTyped,
  PharmacyMeritTagsToJSON,
  PharmacyPaymentMethod,
  PharmacyPaymentMethodFromJSON,
  PharmacyPaymentMethodFromJSONTyped,
  PharmacyPaymentMethodToJSON,
  PharmacyPharmacyDeliverySettings,
  PharmacyPharmacyDeliverySettingsFromJSON,
  PharmacyPharmacyDeliverySettingsFromJSONTyped,
  PharmacyPharmacyDeliverySettingsToJSON,
  PharmacyPharmacyHours,
  PharmacyPharmacyHoursFromJSON,
  PharmacyPharmacyHoursFromJSONTyped,
  PharmacyPharmacyHoursToJSON,
  PharmacyPharmacyStations,
  PharmacyPharmacyStationsFromJSON,
  PharmacyPharmacyStationsFromJSONTyped,
  PharmacyPharmacyStationsToJSON,
  PharmacyTokushoho,
  PharmacyTokushohoFromJSON,
  PharmacyTokushohoFromJSONTyped,
  PharmacyTokushohoToJSON,
} from "./";

export const PharmacyPharmacyRegistrableTypename = "PharmacyPharmacyRegistrable" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyRegistrable
 */
export interface PharmacyPharmacyRegistrable {
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  calendarType: PharmacyPharmacyRegistrableCalendarTypeEnum;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyRegistrable
   */
  chainId?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyRegistrable
   */
  closedDays: Array<string>;
  /**
   *
   * @type {PharmacyPharmacyHours}
   * @memberof PharmacyPharmacyRegistrable
   */
  counselingReceptionHours?: PharmacyPharmacyHours;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  createTalkroom?: boolean;
  /**
   *
   * @type {PharmacyPharmacyDeliverySettings}
   * @memberof PharmacyPharmacyRegistrable
   */
  deliverySetting?: PharmacyPharmacyDeliverySettings;
  /**
   *
   * @type {PharmacyPharmacyHours}
   * @memberof PharmacyPharmacyRegistrable
   */
  dispensingReceptionHours?: PharmacyPharmacyHours;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  externalId?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  fax: string;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  faxPrescriptionWithImage?: boolean;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyRegistrable
   */
  groupId?: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  hasLineOfficialAccount?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  ignorePublicHoliday?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  institutionCode: string;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isCounselingReceptionUntilClosing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isDispensing?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isDisplayVisitWithinOneHour?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isOnline?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isOnlinePayment?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isParking?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isPrescription?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isScreening?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  isTell?: boolean;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyRegistrable
   */
  latitude: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyRegistrable
   */
  longitude: number;
  /**
   *
   * @type {PharmacyMeritTags}
   * @memberof PharmacyPharmacyRegistrable
   */
  meritTag?: PharmacyMeritTags;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  name: string;
  /**
   *
   * @type {PharmacyPharmacyStations}
   * @memberof PharmacyPharmacyRegistrable
   */
  nearestStation: PharmacyPharmacyStations;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  note: string;
  /**
   *
   * @type {PharmacyPaymentMethod}
   * @memberof PharmacyPharmacyRegistrable
   */
  onlinePaymentMethod?: PharmacyPaymentMethod;
  /**
   *
   * @type {PharmacyPaymentMethod}
   * @memberof PharmacyPharmacyRegistrable
   */
  paymentMethod?: PharmacyPaymentMethod;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  printerIp?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  printerType: PharmacyPharmacyRegistrablePrinterTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  recommendation?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  sendFollowUpMessage?: PharmacyPharmacyRegistrableSendFollowUpMessageEnum;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyRegistrable
   */
  talkroomExpireDays: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  tel: string;
  /**
   *
   * @type {PharmacyTokushoho}
   * @memberof PharmacyPharmacyRegistrable
   */
  tokushoho?: PharmacyTokushoho;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  usableAcceptCode?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  usableAiocr?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  usableCrowdStatus?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  usableDigitalPrescription?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  usableDispensingFeature?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  usableDispensingType?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  usableWaitingLabel?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  visibility: PharmacyPharmacyRegistrableVisibilityEnum;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  wantsEmailNotification?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  wantsFaxCounselingRequestedRemind?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacyRegistrable
   */
  wantsFaxNotification?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyRegistrable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyPharmacyRegistrableCalendarTypeEnum {
  Gregorian = "gregorian",
  Japanese = "japanese",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyPharmacyRegistrablePrinterTypeEnum {
  Default = "default",
  StarM = "star_m",
  StarMLan = "star_m_lan",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyPharmacyRegistrableSendFollowUpMessageEnum {
  None = "none",
  Auto = "auto",
  Manual = "manual",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyPharmacyRegistrableVisibilityEnum {
  Public = "public",
  Private = "private",
  Limited = "limited",
}

export function PharmacyPharmacyRegistrableFromJSON(json: any): PharmacyPharmacyRegistrable {
  return PharmacyPharmacyRegistrableFromJSONTyped(json, false);
}

export function PharmacyPharmacyRegistrableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPharmacyRegistrable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyRegistrableTypename,

    address: json["address"],
    calendarType: json["calendar_type"],
    chainId: !exists(json, "chain_id") ? undefined : json["chain_id"],
    closedDays: json["closed_days"],
    counselingReceptionHours: !exists(json, "counseling_reception_hours")
      ? undefined
      : PharmacyPharmacyHoursFromJSON(json["counseling_reception_hours"]),
    createTalkroom: !exists(json, "create_talkroom") ? undefined : json["create_talkroom"],
    deliverySetting: !exists(json, "delivery_setting")
      ? undefined
      : PharmacyPharmacyDeliverySettingsFromJSON(json["delivery_setting"]),
    dispensingReceptionHours: !exists(json, "dispensing_reception_hours")
      ? undefined
      : PharmacyPharmacyHoursFromJSON(json["dispensing_reception_hours"]),
    email: json["email"],
    externalId: !exists(json, "external_id") ? undefined : json["external_id"],
    fax: json["fax"],
    faxPrescriptionWithImage: !exists(json, "fax_prescription_with_image")
      ? undefined
      : json["fax_prescription_with_image"],
    groupId: !exists(json, "group_id") ? undefined : json["group_id"],
    hasLineOfficialAccount: !exists(json, "has_line_official_account") ? undefined : json["has_line_official_account"],
    ignorePublicHoliday: !exists(json, "ignore_public_holiday") ? undefined : json["ignore_public_holiday"],
    institutionCode: json["institution_code"],
    isCounselingReceptionUntilClosing: !exists(json, "is_counseling_reception_until_closing")
      ? undefined
      : json["is_counseling_reception_until_closing"],
    isDispensing: !exists(json, "is_dispensing") ? undefined : json["is_dispensing"],
    isDisplayVisitWithinOneHour: !exists(json, "is_display_visit_within_one_hour")
      ? undefined
      : json["is_display_visit_within_one_hour"],
    isOnline: !exists(json, "is_online") ? undefined : json["is_online"],
    isOnlinePayment: !exists(json, "is_online_payment") ? undefined : json["is_online_payment"],
    isParking: !exists(json, "is_parking") ? undefined : json["is_parking"],
    isPrescription: !exists(json, "is_prescription") ? undefined : json["is_prescription"],
    isScreening: !exists(json, "is_screening") ? undefined : json["is_screening"],
    isTell: !exists(json, "is_tell") ? undefined : json["is_tell"],
    latitude: json["latitude"] == "" ? undefined : json["latitude"],
    longitude: json["longitude"] == "" ? undefined : json["longitude"],
    meritTag: !exists(json, "merit_tag") ? undefined : PharmacyMeritTagsFromJSON(json["merit_tag"]),
    name: json["name"],
    nearestStation: PharmacyPharmacyStationsFromJSON(json["nearest_station"]),
    note: json["note"],
    onlinePaymentMethod: !exists(json, "online_payment_method")
      ? undefined
      : PharmacyPaymentMethodFromJSON(json["online_payment_method"]),
    paymentMethod: !exists(json, "payment_method") ? undefined : PharmacyPaymentMethodFromJSON(json["payment_method"]),
    postalCode: json["postal_code"],
    printerIp: !exists(json, "printer_ip") ? undefined : json["printer_ip"],
    printerType: json["printer_type"],
    recommendation: !exists(json, "recommendation") ? undefined : json["recommendation"],
    sendFollowUpMessage: !exists(json, "send_follow_up_message") ? undefined : json["send_follow_up_message"],
    talkroomExpireDays: json["talkroom_expire_days"],
    tel: json["tel"],
    tokushoho: !exists(json, "tokushoho") ? undefined : PharmacyTokushohoFromJSON(json["tokushoho"]),
    usableAcceptCode: !exists(json, "usable_accept_code") ? undefined : json["usable_accept_code"],
    usableAiocr: !exists(json, "usable_aiocr") ? undefined : json["usable_aiocr"],
    usableCrowdStatus: !exists(json, "usable_crowd_status") ? undefined : json["usable_crowd_status"],
    usableDigitalPrescription: !exists(json, "usable_digital_prescription")
      ? undefined
      : json["usable_digital_prescription"],
    usableDispensingFeature: !exists(json, "usable_dispensing_feature") ? undefined : json["usable_dispensing_feature"],
    usableDispensingType: !exists(json, "usable_dispensing_type") ? undefined : json["usable_dispensing_type"],
    usableWaitingLabel: !exists(json, "usable_waiting_label") ? undefined : json["usable_waiting_label"],
    visibility: json["visibility"],
    wantsEmailNotification: !exists(json, "wants_email_notification") ? undefined : json["wants_email_notification"],
    wantsFaxCounselingRequestedRemind: !exists(json, "wants_fax_counseling_requested_remind")
      ? undefined
      : json["wants_fax_counseling_requested_remind"],
    wantsFaxNotification: !exists(json, "wants_fax_notification") ? undefined : json["wants_fax_notification"],
  };
}

export function PharmacyPharmacyRegistrableToJSON(object?: PharmacyPharmacyRegistrable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address: value.address,
    calendar_type: value.calendarType,
    chain_id: value.chainId,
    closed_days: value.closedDays,
    counseling_reception_hours: PharmacyPharmacyHoursToJSON(value.counselingReceptionHours),
    create_talkroom: value.createTalkroom,
    delivery_setting: PharmacyPharmacyDeliverySettingsToJSON(value.deliverySetting),
    dispensing_reception_hours: PharmacyPharmacyHoursToJSON(value.dispensingReceptionHours),
    email: value.email,
    external_id: value.externalId,
    fax: value.fax,
    fax_prescription_with_image: value.faxPrescriptionWithImage,
    group_id: value.groupId,
    has_line_official_account: value.hasLineOfficialAccount,
    ignore_public_holiday: value.ignorePublicHoliday,
    institution_code: value.institutionCode,
    is_counseling_reception_until_closing: value.isCounselingReceptionUntilClosing,
    is_dispensing: value.isDispensing,
    is_display_visit_within_one_hour: value.isDisplayVisitWithinOneHour,
    is_online: value.isOnline,
    is_online_payment: value.isOnlinePayment,
    is_parking: value.isParking,
    is_prescription: value.isPrescription,
    is_screening: value.isScreening,
    is_tell: value.isTell,
    latitude: (value.latitude as unknown as string) == "" ? undefined : value.latitude,
    longitude: (value.longitude as unknown as string) == "" ? undefined : value.longitude,
    merit_tag: PharmacyMeritTagsToJSON(value.meritTag),
    name: value.name,
    nearest_station: PharmacyPharmacyStationsToJSON(value.nearestStation),
    note: value.note,
    online_payment_method: PharmacyPaymentMethodToJSON(value.onlinePaymentMethod),
    payment_method: PharmacyPaymentMethodToJSON(value.paymentMethod),
    postal_code: value.postalCode,
    printer_ip: value.printerIp,
    printer_type: value.printerType,
    recommendation: value.recommendation,
    send_follow_up_message: value.sendFollowUpMessage,
    talkroom_expire_days: value.talkroomExpireDays,
    tel: value.tel,
    tokushoho: PharmacyTokushohoToJSON(value.tokushoho),
    usable_accept_code: value.usableAcceptCode,
    usable_aiocr: value.usableAiocr,
    usable_crowd_status: value.usableCrowdStatus,
    usable_digital_prescription: value.usableDigitalPrescription,
    usable_dispensing_feature: value.usableDispensingFeature,
    usable_dispensing_type: value.usableDispensingType,
    usable_waiting_label: value.usableWaitingLabel,
    visibility: value.visibility,
    wants_email_notification: value.wantsEmailNotification,
    wants_fax_counseling_requested_remind: value.wantsFaxCounselingRequestedRemind,
    wants_fax_notification: value.wantsFaxNotification,
  };
}
