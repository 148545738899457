/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const DeliveryAddressTypename = "DeliveryAddress" as const;

/**
 *
 * @export
 * @interface DeliveryAddress
 */
export interface DeliveryAddress {
  /**
   *
   * @type {string}
   * @memberof DeliveryAddress
   */
  addressLine1: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddress
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddress
   */
  addressLine3?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddress
   */
  addressLine4?: string;
  /**
   *
   * @type {number}
   * @memberof DeliveryAddress
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof DeliveryAddress
   */
  longitude?: number;
  /**
   *
   * @type {string}
   * @memberof DeliveryAddress
   */
  postalCode: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryAddress
   */
  readonly __typename?: string;
}

export function DeliveryAddressFromJSON(json: any): DeliveryAddress {
  return DeliveryAddressFromJSONTyped(json, false);
}

export function DeliveryAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryAddress {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryAddressTypename,

    addressLine1: json["address_line1"],
    addressLine2: !exists(json, "address_line2") ? undefined : json["address_line2"],
    addressLine3: !exists(json, "address_line3") ? undefined : json["address_line3"],
    addressLine4: !exists(json, "address_line4") ? undefined : json["address_line4"],
    latitude: !exists(json, "latitude") ? undefined : json["latitude"] == "" ? undefined : json["latitude"],
    longitude: !exists(json, "longitude") ? undefined : json["longitude"] == "" ? undefined : json["longitude"],
    postalCode: json["postal_code"],
  };
}

export function DeliveryAddressToJSON(object?: DeliveryAddress | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    address_line3: value.addressLine3,
    address_line4: value.addressLine4,
    latitude: (value.latitude as unknown as string) == "" ? undefined : value.latitude,
    longitude: (value.longitude as unknown as string) == "" ? undefined : value.longitude,
    postal_code: value.postalCode,
  };
}
