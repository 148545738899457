/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutMedicineNotebookByPatientRequestBodyTypename = "PutMedicineNotebookByPatientRequestBody" as const;

/**
 *
 * @export
 * @interface PutMedicineNotebookByPatientRequestBody
 */
export interface PutMedicineNotebookByPatientRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutMedicineNotebookByPatientRequestBody
   */
  dateOfBirth: string;
  /**
   *
   * @type {string}
   * @memberof PutMedicineNotebookByPatientRequestBody
   */
  gender: PutMedicineNotebookByPatientRequestBodyGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PutMedicineNotebookByPatientRequestBody
   */
  name: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutMedicineNotebookByPatientRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PutMedicineNotebookByPatientRequestBodyGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PutMedicineNotebookByPatientRequestBodyFromJSON(json: any): PutMedicineNotebookByPatientRequestBody {
  return PutMedicineNotebookByPatientRequestBodyFromJSONTyped(json, false);
}

export function PutMedicineNotebookByPatientRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutMedicineNotebookByPatientRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutMedicineNotebookByPatientRequestBodyTypename,

    dateOfBirth: json["date_of_birth"],
    gender: json["gender"],
    name: json["name"],
  };
}

export function PutMedicineNotebookByPatientRequestBodyToJSON(
  object?: PutMedicineNotebookByPatientRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    gender: value.gender,
    name: value.name,
  };
}
