/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorVideoChatTypename = "PharmacyCounselorVideoChat" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorVideoChat
 */
export interface PharmacyCounselorVideoChat {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorVideoChat
   */
  id: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorVideoChat
   */
  readonly __typename?: string;
}

export function PharmacyCounselorVideoChatFromJSON(json: any): PharmacyCounselorVideoChat {
  return PharmacyCounselorVideoChatFromJSONTyped(json, false);
}

export function PharmacyCounselorVideoChatFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorVideoChat {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorVideoChatTypename,

    id: json["id"],
  };
}

export function PharmacyCounselorVideoChatToJSON(object?: PharmacyCounselorVideoChat | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
  };
}
