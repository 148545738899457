/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostVerifyTokenRequestBodyTypename = "PostVerifyTokenRequestBody" as const;

/**
 *
 * @export
 * @interface PostVerifyTokenRequestBody
 */
export interface PostVerifyTokenRequestBody {
  /**
   *
   * @type {string}
   * @memberof PostVerifyTokenRequestBody
   */
  email: string;
  /**
   * recapchaで取得したtoken
   * @type {string}
   * @memberof PostVerifyTokenRequestBody
   */
  token: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostVerifyTokenRequestBody
   */
  readonly __typename?: string;
}

export function PostVerifyTokenRequestBodyFromJSON(json: any): PostVerifyTokenRequestBody {
  return PostVerifyTokenRequestBodyFromJSONTyped(json, false);
}

export function PostVerifyTokenRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVerifyTokenRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostVerifyTokenRequestBodyTypename,

    email: json["email"],
    token: json["token"],
  };
}

export function PostVerifyTokenRequestBodyToJSON(object?: PostVerifyTokenRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    email: value.email,
    token: value.token,
  };
}
