/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ChatterVideoChat,
  ChatterVideoChatFromJSON,
  ChatterVideoChatToJSON,
  PostVideoChatRequestBody,
  PostVideoChatRequestBodyFromJSON,
  PostVideoChatRequestBodyToJSON,
} from "../models";

export interface GetVideoChatRequest {
  videoChatId: string;
  refresh?: boolean;
}

export interface GetVideoChatsRequest {
  from?: string;
  to?: string;
  id?: Array<string>;
  counselingId?: Array<number>;
}

export interface PostVideoChatRequest {
  postVideoChatRequestBody: PostVideoChatRequestBody;
}

/**
 *
 */
export class VideoChatApi extends runtime.BaseAPI {
  /**
   * Get a video chat
   */
  async getVideoChatRaw(
    requestParameters: GetVideoChatRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ChatterVideoChat>> {
    if (requestParameters.videoChatId === null || requestParameters.videoChatId === undefined) {
      throw new runtime.RequiredError(
        "videoChatId",
        "Required parameter requestParameters.videoChatId was null or undefined when calling getVideoChat."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.refresh !== undefined) {
      queryParameters["refresh"] = requestParameters.refresh;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/video-chats/{video_chat_id}`.replace(
          `{${"video_chat_id"}}`,
          encodeURIComponent(String(requestParameters.videoChatId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChatterVideoChatFromJSON(jsonValue));
  }

  /**
   * Get a video chat
   */
  async getVideoChat(requestParameters: GetVideoChatRequest, initOverrides?: RequestInit): Promise<ChatterVideoChat> {
    return promiseRetry(
      (retry) =>
        this.getVideoChatRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Search for video chats by multiple conditions
   */
  async getVideoChatsRaw(
    requestParameters: GetVideoChatsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ChatterVideoChat>>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.id) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.counselingId) {
      queryParameters["counseling_id"] = requestParameters.counselingId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/video-chats`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChatterVideoChatFromJSON));
  }

  /**
   * Search for video chats by multiple conditions
   */
  async getVideoChats(
    requestParameters: GetVideoChatsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<ChatterVideoChat>> {
    return promiseRetry(
      (retry) =>
        this.getVideoChatsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register a video chat
   */
  async postVideoChatRaw(
    requestParameters: PostVideoChatRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ChatterVideoChat>> {
    if (
      requestParameters.postVideoChatRequestBody === null ||
      requestParameters.postVideoChatRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postVideoChatRequestBody",
        "Required parameter requestParameters.postVideoChatRequestBody was null or undefined when calling postVideoChat."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/video-chats`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostVideoChatRequestBodyToJSON(requestParameters.postVideoChatRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ChatterVideoChatFromJSON(jsonValue));
  }

  /**
   * Register a video chat
   */
  async postVideoChat(requestParameters: PostVideoChatRequest, initOverrides?: RequestInit): Promise<ChatterVideoChat> {
    return promiseRetry(
      (retry) =>
        this.postVideoChatRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
