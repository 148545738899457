/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatchMedicineNotebookRequestBodyTypename = "PatchMedicineNotebookRequestBody" as const;

/**
 *
 * @export
 * @interface PatchMedicineNotebookRequestBody
 */
export interface PatchMedicineNotebookRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof PatchMedicineNotebookRequestBody
   */
  agreed: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchMedicineNotebookRequestBody
   */
  readonly __typename?: string;
}

export function PatchMedicineNotebookRequestBodyFromJSON(json: any): PatchMedicineNotebookRequestBody {
  return PatchMedicineNotebookRequestBodyFromJSONTyped(json, false);
}

export function PatchMedicineNotebookRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchMedicineNotebookRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchMedicineNotebookRequestBodyTypename,

    agreed: json["agreed"],
  };
}

export function PatchMedicineNotebookRequestBodyToJSON(object?: PatchMedicineNotebookRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    agreed: value.agreed,
  };
}
