/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const AdminDisabledAccountTypename = "AdminDisabledAccount" as const;

/**
 *
 * @export
 * @interface AdminDisabledAccount
 */
export interface AdminDisabledAccount {
  /**
   *
   * @type {boolean}
   * @memberof AdminDisabledAccount
   */
  disabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof AdminDisabledAccount
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof AdminDisabledAccount
   */
  uuid?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof AdminDisabledAccount
   */
  readonly __typename?: string;
}

export function AdminDisabledAccountFromJSON(json: any): AdminDisabledAccount {
  return AdminDisabledAccountFromJSONTyped(json, false);
}

export function AdminDisabledAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminDisabledAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: AdminDisabledAccountTypename,

    disabled: !exists(json, "disabled") ? undefined : json["disabled"],
    email: !exists(json, "email") ? undefined : json["email"],
    uuid: !exists(json, "uuid") ? undefined : json["uuid"],
  };
}

export function AdminDisabledAccountToJSON(object?: AdminDisabledAccount | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    disabled: value.disabled,
    email: value.email,
    uuid: value.uuid,
  };
}
