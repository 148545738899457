/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyUsageSummaryTypename = "PharmacyPharmacyUsageSummary" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyUsageSummary
 */
export interface PharmacyPharmacyUsageSummary {
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  appPushMessage?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  chainId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyUsageSummary
   */
  chainName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyUsageSummary
   */
  day: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  directVisit?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  dispensing?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  fax?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  groupId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyUsageSummary
   */
  groupName?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  lineOaMessage?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  onlineCounseling?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  patientCs?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  pharmacyCs?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  pharmacyId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyUsageSummary
   */
  pharmacyName: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyUsageSummary
   */
  sms?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyUsageSummary
   */
  readonly __typename?: string;
}

export function PharmacyPharmacyUsageSummaryFromJSON(json: any): PharmacyPharmacyUsageSummary {
  return PharmacyPharmacyUsageSummaryFromJSONTyped(json, false);
}

export function PharmacyPharmacyUsageSummaryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPharmacyUsageSummary {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyUsageSummaryTypename,

    appPushMessage: !exists(json, "app_push_message") ? undefined : json["app_push_message"],
    chainId: json["chain_id"],
    chainName: json["chain_name"],
    day: json["day"],
    directVisit: !exists(json, "direct_visit") ? undefined : json["direct_visit"],
    dispensing: !exists(json, "dispensing") ? undefined : json["dispensing"],
    fax: !exists(json, "fax") ? undefined : json["fax"],
    groupId: !exists(json, "group_id") ? undefined : json["group_id"],
    groupName: !exists(json, "group_name") ? undefined : json["group_name"],
    lineOaMessage: !exists(json, "line_oa_message") ? undefined : json["line_oa_message"],
    onlineCounseling: !exists(json, "online_counseling") ? undefined : json["online_counseling"],
    patientCs: !exists(json, "patient_cs") ? undefined : json["patient_cs"],
    pharmacyCs: !exists(json, "pharmacy_cs") ? undefined : json["pharmacy_cs"],
    pharmacyId: json["pharmacy_id"],
    pharmacyName: json["pharmacy_name"],
    sms: !exists(json, "sms") ? undefined : json["sms"],
  };
}

export function PharmacyPharmacyUsageSummaryToJSON(object?: PharmacyPharmacyUsageSummary | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    app_push_message: value.appPushMessage,
    chain_id: value.chainId,
    chain_name: value.chainName,
    day: value.day,
    direct_visit: value.directVisit,
    dispensing: value.dispensing,
    fax: value.fax,
    group_id: value.groupId,
    group_name: value.groupName,
    line_oa_message: value.lineOaMessage,
    online_counseling: value.onlineCounseling,
    patient_cs: value.patientCs,
    pharmacy_cs: value.pharmacyCs,
    pharmacy_id: value.pharmacyId,
    pharmacy_name: value.pharmacyName,
    sms: value.sms,
  };
}
