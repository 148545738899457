/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorOptionTypename = "PharmacyCounselorOption" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorOption
 */
export interface PharmacyCounselorOption {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorOption
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorOption
   */
  isFreeEntry?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorOption
   */
  optionName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorOption
   */
  readonly __typename?: string;
}

export function PharmacyCounselorOptionFromJSON(json: any): PharmacyCounselorOption {
  return PharmacyCounselorOptionFromJSONTyped(json, false);
}

export function PharmacyCounselorOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyCounselorOption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorOptionTypename,

    id: json["id"],
    isFreeEntry: !exists(json, "is_free_entry") ? undefined : json["is_free_entry"],
    optionName: json["option_name"],
  };
}

export function PharmacyCounselorOptionToJSON(object?: PharmacyCounselorOption | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    is_free_entry: value.isFreeEntry,
    option_name: value.optionName,
  };
}
