/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyFollowUpQuestionnaire,
  PharmacyFollowUpQuestionnaireFromJSON,
  PharmacyFollowUpQuestionnaireFromJSONTyped,
  PharmacyFollowUpQuestionnaireToJSON,
} from "./";

export const PharmacyFollowUpQuestionnairePatternTypename = "PharmacyFollowUpQuestionnairePattern" as const;

/**
 *
 * @export
 * @interface PharmacyFollowUpQuestionnairePattern
 */
export interface PharmacyFollowUpQuestionnairePattern {
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpQuestionnairePattern
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpQuestionnairePattern
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpQuestionnairePattern
   */
  orderNumber?: number;
  /**
   *
   * @type {Array<PharmacyFollowUpQuestionnaire>}
   * @memberof PharmacyFollowUpQuestionnairePattern
   */
  questionnaires: Array<PharmacyFollowUpQuestionnaire>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyFollowUpQuestionnairePattern
   */
  readonly __typename?: string;
}

export function PharmacyFollowUpQuestionnairePatternFromJSON(json: any): PharmacyFollowUpQuestionnairePattern {
  return PharmacyFollowUpQuestionnairePatternFromJSONTyped(json, false);
}

export function PharmacyFollowUpQuestionnairePatternFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyFollowUpQuestionnairePattern {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyFollowUpQuestionnairePatternTypename,

    id: json["id"],
    name: json["name"],
    orderNumber: !exists(json, "order_number") ? undefined : json["order_number"],
    questionnaires: (json["questionnaires"] as Array<any>).map(PharmacyFollowUpQuestionnaireFromJSON),
  };
}

export function PharmacyFollowUpQuestionnairePatternToJSON(object?: PharmacyFollowUpQuestionnairePattern | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    name: value.name,
    order_number: value.orderNumber,
    questionnaires: (value.questionnaires as Array<any>).map(PharmacyFollowUpQuestionnaireToJSON),
  };
}
