/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ChatterMediaPlacementTypename = "ChatterMediaPlacement" as const;

/**
 *
 * @export
 * @interface ChatterMediaPlacement
 */
export interface ChatterMediaPlacement {
  /**
   *
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  audioFallbackUrl: string;
  /**
   *
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  audioHostUrl: string;
  /**
   *
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  eventIngestionUrl: string;
  /**
   *
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  screenDataUrl: string;
  /**
   *
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  screenSharingUrl: string;
  /**
   *
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  screenViewingUrl: string;
  /**
   *
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  signalingUrl: string;
  /**
   *
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  turnControlUrl: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterMediaPlacement
   */
  readonly __typename?: string;
}

export function ChatterMediaPlacementFromJSON(json: any): ChatterMediaPlacement {
  return ChatterMediaPlacementFromJSONTyped(json, false);
}

export function ChatterMediaPlacementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatterMediaPlacement {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterMediaPlacementTypename,

    audioFallbackUrl: json["audio_fallback_url"],
    audioHostUrl: json["audio_host_url"],
    eventIngestionUrl: json["event_ingestion_url"],
    screenDataUrl: json["screen_data_url"],
    screenSharingUrl: json["screen_sharing_url"],
    screenViewingUrl: json["screen_viewing_url"],
    signalingUrl: json["signaling_url"],
    turnControlUrl: json["turn_control_url"],
  };
}

export function ChatterMediaPlacementToJSON(object?: ChatterMediaPlacement | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    audio_fallback_url: value.audioFallbackUrl,
    audio_host_url: value.audioHostUrl,
    event_ingestion_url: value.eventIngestionUrl,
    screen_data_url: value.screenDataUrl,
    screen_sharing_url: value.screenSharingUrl,
    screen_viewing_url: value.screenViewingUrl,
    signaling_url: value.signalingUrl,
    turn_control_url: value.turnControlUrl,
  };
}
