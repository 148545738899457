/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientOTCMedicineMasterTypename = "PatientOTCMedicineMaster" as const;

/**
 *
 * @export
 * @interface PatientOTCMedicineMaster
 */
export interface PatientOTCMedicineMaster {
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  consultation?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  contraindication?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  dosage?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  imageUrl?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  indication?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  ingredient?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  janCode?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  name?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientOTCMedicineMaster
   */
  readonly __typename?: string;
}

export function PatientOTCMedicineMasterFromJSON(json: any): PatientOTCMedicineMaster {
  return PatientOTCMedicineMasterFromJSONTyped(json, false);
}

export function PatientOTCMedicineMasterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientOTCMedicineMaster {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientOTCMedicineMasterTypename,

    companyName: !exists(json, "company_name") ? undefined : json["company_name"],
    consultation: !exists(json, "consultation") ? undefined : json["consultation"],
    contraindication: !exists(json, "contraindication") ? undefined : json["contraindication"],
    dosage: !exists(json, "dosage") ? undefined : json["dosage"],
    imageUrl: !exists(json, "image_url") ? undefined : json["image_url"],
    indication: !exists(json, "indication") ? undefined : json["indication"],
    ingredient: !exists(json, "ingredient") ? undefined : json["ingredient"],
    janCode: !exists(json, "jan_code") ? undefined : json["jan_code"],
    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function PatientOTCMedicineMasterToJSON(object?: PatientOTCMedicineMaster | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    company_name: value.companyName,
    consultation: value.consultation,
    contraindication: value.contraindication,
    dosage: value.dosage,
    image_url: value.imageUrl,
    indication: value.indication,
    ingredient: value.ingredient,
    jan_code: value.janCode,
    name: value.name,
  };
}
