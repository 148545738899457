/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  DeliveryOrderEventDetail,
  DeliveryOrderEventDetailFromJSON,
  DeliveryOrderEventDetailFromJSONTyped,
  DeliveryOrderEventDetailToJSON,
} from "./";

export const DeliveryOrderEventsTypename = "DeliveryOrderEvents" as const;

/**
 *
 * @export
 * @interface DeliveryOrderEvents
 */
export interface DeliveryOrderEvents {
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  absence?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  accepted?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  arrived?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  canceled?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  departed?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  finished?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  registered?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  rejected?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  requested?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  returned?: DeliveryOrderEventDetail;
  /**
   *
   * @type {DeliveryOrderEventDetail}
   * @memberof DeliveryOrderEvents
   */
  started?: DeliveryOrderEventDetail;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryOrderEvents
   */
  readonly __typename?: string;
}

export function DeliveryOrderEventsFromJSON(json: any): DeliveryOrderEvents {
  return DeliveryOrderEventsFromJSONTyped(json, false);
}

export function DeliveryOrderEventsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryOrderEvents {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryOrderEventsTypename,

    absence: !exists(json, "absence") ? undefined : DeliveryOrderEventDetailFromJSON(json["absence"]),
    accepted: !exists(json, "accepted") ? undefined : DeliveryOrderEventDetailFromJSON(json["accepted"]),
    arrived: !exists(json, "arrived") ? undefined : DeliveryOrderEventDetailFromJSON(json["arrived"]),
    canceled: !exists(json, "canceled") ? undefined : DeliveryOrderEventDetailFromJSON(json["canceled"]),
    departed: !exists(json, "departed") ? undefined : DeliveryOrderEventDetailFromJSON(json["departed"]),
    finished: !exists(json, "finished") ? undefined : DeliveryOrderEventDetailFromJSON(json["finished"]),
    registered: !exists(json, "registered") ? undefined : DeliveryOrderEventDetailFromJSON(json["registered"]),
    rejected: !exists(json, "rejected") ? undefined : DeliveryOrderEventDetailFromJSON(json["rejected"]),
    requested: !exists(json, "requested") ? undefined : DeliveryOrderEventDetailFromJSON(json["requested"]),
    returned: !exists(json, "returned") ? undefined : DeliveryOrderEventDetailFromJSON(json["returned"]),
    started: !exists(json, "started") ? undefined : DeliveryOrderEventDetailFromJSON(json["started"]),
  };
}

export function DeliveryOrderEventsToJSON(object?: DeliveryOrderEvents | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    absence: DeliveryOrderEventDetailToJSON(value.absence),
    accepted: DeliveryOrderEventDetailToJSON(value.accepted),
    arrived: DeliveryOrderEventDetailToJSON(value.arrived),
    canceled: DeliveryOrderEventDetailToJSON(value.canceled),
    departed: DeliveryOrderEventDetailToJSON(value.departed),
    finished: DeliveryOrderEventDetailToJSON(value.finished),
    registered: DeliveryOrderEventDetailToJSON(value.registered),
    rejected: DeliveryOrderEventDetailToJSON(value.rejected),
    requested: DeliveryOrderEventDetailToJSON(value.requested),
    returned: DeliveryOrderEventDetailToJSON(value.returned),
    started: DeliveryOrderEventDetailToJSON(value.started),
  };
}
