import moment from "moment-timezone";

export const QolDxAccountTypename = "QolDxAccount" as const;

/**
 *
 * @export
 * @interface QolDxAccount
 * @see QolDxAccount
 */
export interface QolDxAccount {
  /**
   *
   * @type {string}
   * @memberof QolDxAccount
   */
  dxInnerId?: string;
  /**
   *
   * @type {string}
   * @memberof QolDxAccount
   */
  qolDxId?: string;
  /**
   *
   * @type {string}
   * @memberof QolDxAccount
   */
  qolCardNum?: string;
  /**
   *
   * @type {string}
   * @memberof QolDxAccount
   */
  pontaCardNum?: string;
  /**
   *
   * @type {string}
   * @memberof QolDxAccount
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof QolDxAccount
   */
  updatedAt?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof QolDxAccount
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolDxAccountFromJSON(json: any): QolDxAccount {
  return QolDxAccountFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolDxAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): QolDxAccount {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: QolDxAccountTypename,
    dxInnerId: json["dxInnerId"],
    qolDxId: json["qolDxId"],
    qolCardNum: json["qolCardNum"],
    pontaCardNum: json["pontaCardNum"],
    createdAt: json["createdAt"],
    updatedAt: json["updatedAt"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function QolDxAccountToJSON(object?: QolDxAccount | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    dxInnerId: value.dxInnerId,
    qolDxId: value.qolDxId,
    qolCardNum: value.qolCardNum,
    pontaCardNum: value.pontaCardNum,
    createdAt: value.createdAt === undefined ? undefined : moment(value.createdAt).tz("Asia/Tokyo").format(),
    updatedAt: value.updatedAt === undefined ? undefined : moment(value.updatedAt).tz("Asia/Tokyo").format(),
  };
}
