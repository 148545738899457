/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientOTCMedicine,
  PatientOTCMedicineFromJSON,
  PatientOTCMedicineFromJSONTyped,
  PatientOTCMedicineToJSON,
} from "./";

export const PatientOTCMedicinesTypename = "PatientOTCMedicines" as const;

/**
 *
 * @export
 * @interface PatientOTCMedicines
 */
export interface PatientOTCMedicines {
  /**
   *
   * @type {Array<PatientOTCMedicine>}
   * @memberof PatientOTCMedicines
   */
  otcMedicines?: Array<PatientOTCMedicine>;
  /**
   *
   * @type {number}
   * @memberof PatientOTCMedicines
   */
  totalCount?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientOTCMedicines
   */
  readonly __typename?: string;
}

export function PatientOTCMedicinesFromJSON(json: any): PatientOTCMedicines {
  return PatientOTCMedicinesFromJSONTyped(json, false);
}

export function PatientOTCMedicinesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientOTCMedicines {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientOTCMedicinesTypename,

    otcMedicines: !exists(json, "otc_medicines")
      ? undefined
      : (json["otc_medicines"] as Array<any>).map(PatientOTCMedicineFromJSON),
    totalCount: !exists(json, "total_count") ? undefined : json["total_count"],
  };
}

export function PatientOTCMedicinesToJSON(object?: PatientOTCMedicines | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    otc_medicines:
      value.otcMedicines === undefined ? undefined : (value.otcMedicines as Array<any>).map(PatientOTCMedicineToJSON),
    total_count: value.totalCount,
  };
}
