import isClient from "@mgdx/shared/src/utils/isClient";
import moment from "@mgdx-libs/moment";

const IS_PROCESS_OF_OIDC_SIGN_UP_STORAGE_KEY = "inProcessOfOIDCSignup";
const IS_PROCESS_OF_OIDC_SIGN_UP_STORAGE__TIME_KEY = "inProcessOfOIDCSignupTime";
const SESSION_EXPIRE_MINITE = 5;

export const hasSessionStorage = (): boolean => isClient() && typeof window.sessionStorage !== "undefined";

export const checkExpireTime = (key: string): boolean => {
  if (!hasSessionStorage()) return false;

  const saveTimeString = sessionStorage.getItem(key);
  if (!saveTimeString) return false;
  const saveTime = moment(saveTimeString);
  const current = moment();
  return saveTime && current.diff(saveTime, "minute") > SESSION_EXPIRE_MINITE;
};

export const saveExpiredTime = (key: string): void => {
  sessionStorage.setItem(key, moment().toISOString());
};

export const setInProcessOfOIDCSignup = (signUp: boolean): void => {
  if (hasSessionStorage()) {
    sessionStorage.setItem(IS_PROCESS_OF_OIDC_SIGN_UP_STORAGE_KEY, signUp ? "yes" : "no");
    saveExpiredTime(IS_PROCESS_OF_OIDC_SIGN_UP_STORAGE__TIME_KEY);
  }
};

export const getInProcessOfOIDCSignup = (): boolean => {
  if (!hasSessionStorage()) return false;

  const isExpired = checkExpireTime(IS_PROCESS_OF_OIDC_SIGN_UP_STORAGE__TIME_KEY);
  if (isExpired) {
    clearInProcessOfOIDCSignup();
  } else {
    return sessionStorage.getItem(IS_PROCESS_OF_OIDC_SIGN_UP_STORAGE_KEY) === "yes" || false;
  }

  return false;
};

export const clearInProcessOfOIDCSignup = (): void => {
  if (hasSessionStorage()) {
    sessionStorage.removeItem(IS_PROCESS_OF_OIDC_SIGN_UP_STORAGE_KEY);
    sessionStorage.removeItem(IS_PROCESS_OF_OIDC_SIGN_UP_STORAGE__TIME_KEY);
  }
};
