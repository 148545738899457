/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const DeliveryDesiredTimeTypename = "DeliveryDesiredTime" as const;

/**
 *
 * @export
 * @interface DeliveryDesiredTime
 */
export interface DeliveryDesiredTime {
  /**
   *
   * @type {number}
   * @memberof DeliveryDesiredTime
   */
  pickup?: DeliveryDesiredTimePickupEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryDesiredTime
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum DeliveryDesiredTimePickupEnum {
  NUMBER_0 = 0,
  NUMBER_15 = 15,
  NUMBER_30 = 30,
  NUMBER_60 = 60,
}

export function DeliveryDesiredTimeFromJSON(json: any): DeliveryDesiredTime {
  return DeliveryDesiredTimeFromJSONTyped(json, false);
}

export function DeliveryDesiredTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryDesiredTime {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryDesiredTimeTypename,

    pickup: !exists(json, "pickup") ? undefined : json["pickup"],
  };
}

export function DeliveryDesiredTimeToJSON(object?: DeliveryDesiredTime | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    pickup: value.pickup,
  };
}
