import Debug from "debug";

const debug = Debug("app:debug");
const error = Debug("app:error");

export const initializeLogger = () => {
  debug.log = console.log.bind(console);
  error.log = console.error.bind(console);
};

initializeLogger();

export const logger = {
  debug,
  error,
};
