/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorCounselingReportDesiredTime,
  PharmacyCounselorCounselingReportDesiredTimeFromJSON,
  PharmacyCounselorCounselingReportDesiredTimeFromJSONTyped,
  PharmacyCounselorCounselingReportDesiredTimeToJSON,
} from "./";

export const PharmacyCounselorCounselingReportOrderTypename = "PharmacyCounselorCounselingReportOrder" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReportOrder
 */
export interface PharmacyCounselorCounselingReportOrder {
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportOrder
   */
  canceledTime?: string;
  /**
   *
   * @type {PharmacyCounselorCounselingReportDesiredTime}
   * @memberof PharmacyCounselorCounselingReportOrder
   */
  desiredTime?: PharmacyCounselorCounselingReportDesiredTime;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportOrder
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportOrder
   */
  provider?: string;
  /**
   *
   * @type {object}
   * @memberof PharmacyCounselorCounselingReportOrder
   */
  providerEvents?: object;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportOrder
   */
  providerLastEvent?: string;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportOrder
   */
  registeredTime?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportOrder
   */
  readonly __typename?: string;
}

export function PharmacyCounselorCounselingReportOrderFromJSON(json: any): PharmacyCounselorCounselingReportOrder {
  return PharmacyCounselorCounselingReportOrderFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportOrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReportOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportOrderTypename,

    canceledTime:
      !exists(json, "canceled_time") ||
      (typeof json["canceled_time"] === "string" && json["canceled_time"].substring(0, 4) === "0001")
        ? undefined
        : json["canceled_time"],
    desiredTime: !exists(json, "desired_time")
      ? undefined
      : PharmacyCounselorCounselingReportDesiredTimeFromJSON(json["desired_time"]),
    id: !exists(json, "id") ? undefined : json["id"],
    provider: !exists(json, "provider") ? undefined : json["provider"],
    providerEvents: !exists(json, "provider_events") ? undefined : json["provider_events"],
    providerLastEvent: !exists(json, "provider_last_event") ? undefined : json["provider_last_event"],
    registeredTime:
      !exists(json, "registered_time") ||
      (typeof json["registered_time"] === "string" && json["registered_time"].substring(0, 4) === "0001")
        ? undefined
        : json["registered_time"],
  };
}

export function PharmacyCounselorCounselingReportOrderToJSON(
  object?: PharmacyCounselorCounselingReportOrder | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    canceled_time: value.canceledTime === undefined ? undefined : moment(value.canceledTime).tz("Asia/Tokyo").format(),
    desired_time: PharmacyCounselorCounselingReportDesiredTimeToJSON(value.desiredTime),
    id: value.id,
    provider: value.provider,
    provider_events: value.providerEvents,
    provider_last_event: value.providerLastEvent,
    registered_time:
      value.registeredTime === undefined ? undefined : moment(value.registeredTime).tz("Asia/Tokyo").format(),
  };
}
