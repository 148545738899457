import { ClinicDate } from "@mgdx/api/lib/clinic/clinic";
import clinicApi from "@mgdx/api/lib/clinicApi";
import clinicCalendarApi from "@mgdx/api/lib/clinicCalendarApi";
import { Clinic } from "@mgdx/shared/src/models/Clinic";
import { createRequestAction } from "@mgdx-libs/redux-requests";
import toInteger from "lodash/toInteger";

export const findClinicById = createRequestAction<{ clinicId: string }, Clinic>(
  "requests/counseling/findClinicById",
  ({ clinicId }, meta) => ({
    request: {
      promise: clinicApi.getClinic({
        clinicId: toInteger(clinicId),
      }),
    },
    meta: {
      requestKey: clinicId,
      ...meta,
    },
  })
);

export const findClinicsByIds = createRequestAction<{ clinicIds: number[] }, Clinic[]>(
  "requests/clinic/findClinicsByIds",
  ({ clinicIds }, meta) => ({
    request: {
      promise: Promise.all(
        clinicIds.map((clinicId) =>
          clinicApi.getClinic({
            clinicId,
          })
        )
      ),
    },
    meta: {
      requestsCapacity: 3,
      requestKey: clinicIds.join(","),
      ...meta,
    },
  })
);

export const findClinicDateById = createRequestAction<{ clinicId: number; departmentId: number }, ClinicDate[]>(
  "requests/clinic/findClinicDateById",
  ({ clinicId, departmentId }, meta) => ({
    request: {
      promise: clinicCalendarApi.getCalendar({ clinicId, departmentId }),
    },
    meta: {
      requestsCapacity: 3,
      requestKey: `${clinicId}-${departmentId}`,
      ...meta,
    },
  })
);
