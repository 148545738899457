import {
  GetMedicineNotebookAutoLinksOrderByEnum as SearchMedicineNotebookAutoLinkOrderByEnum,
  GetMedicineNotebookAutoLinksRequest,
  GetMedicineNotebookAutoLinksStatusEnum as SearchMedicineNotebookAutoLinkStatusEnum,
  PatchMedicineNotebookAutoLinkRequestBody,
  PatchMedicineNotebookAutoLinkRequestBodyGenderEnum as MedicineNotebookAutoLinkEditGenderEnum,
  PatchMedicineNotebookAutoLinkRequestBodyStatusEnum as MedicineNotebookAutoLinkEditStatusEnum,
  PharmacyCounselorExaminer,
  PharmacyCounselorMedicineNotebookAutoLink,
  PharmacyCounselorMedicineNotebookAutoLinkPatient,
  PharmacyCounselorMedicineNotebookAutoLinks,
  PharmacyCounselorMedicineNotebookAutoLinkStatusEnum as MedicineNotebookAutoLinkStatusEnum,
  PostMedicineNotebookAutoLinkRequestBody,
  PostMedicineNotebookAutoLinkRequestBodyRequestTypeEnum as MedicineNotebookAutoLinkCreateRequestTypeEnum,
} from "@mgdx/api/lib/pharmacy/counselor";

export type MedicineNotebookAutoLink = PharmacyCounselorMedicineNotebookAutoLink;

export type MedicineNotebookAutoLinkWithType = Omit<MedicineNotebookAutoLink, "requestedTime"> &
  Partial<Pick<MedicineNotebookAutoLink, "requestedTime">> & {
    examiner?: PharmacyCounselorExaminer;
    managementNo?: string;
  };

export type MedicineNotebookAutoLinkCreateAttributes = PostMedicineNotebookAutoLinkRequestBody;

export type MedicineNotebookAutoLinkEditAttributes = PatchMedicineNotebookAutoLinkRequestBody;

export type MedicineNotebookAutoLinkConnection = PharmacyCounselorMedicineNotebookAutoLinks;

export const defaultMedicineNotebookAutoLinkConnectionData: Omit<MedicineNotebookAutoLinkConnection, "__typename"> = {
  count: 0,
  medicineNotebookAutoLinks: [],
};

export type MedicineNotebookAutoLinkSearchParams = GetMedicineNotebookAutoLinksRequest;

export {
  MedicineNotebookAutoLinkCreateRequestTypeEnum,
  MedicineNotebookAutoLinkEditGenderEnum,
  MedicineNotebookAutoLinkEditStatusEnum,
  MedicineNotebookAutoLinkStatusEnum,
  SearchMedicineNotebookAutoLinkOrderByEnum,
  SearchMedicineNotebookAutoLinkStatusEnum,
};

export type MedicineNotebookAutoLinkPatient = PharmacyCounselorMedicineNotebookAutoLinkPatient;
