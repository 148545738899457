/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyTemporaryClosedDate,
  PharmacyTemporaryClosedDateFromJSON,
  PharmacyTemporaryClosedDateFromJSONTyped,
  PharmacyTemporaryClosedDateToJSON,
} from "./";

export const PharmacyTemporaryClosedDatesTypename = "PharmacyTemporaryClosedDates" as const;

/**
 *
 * @export
 * @interface PharmacyTemporaryClosedDates
 */
export interface PharmacyTemporaryClosedDates {
  /**
   *
   * @type {Array<PharmacyTemporaryClosedDate>}
   * @memberof PharmacyTemporaryClosedDates
   */
  dates?: Array<PharmacyTemporaryClosedDate>;
  /**
   *
   * @type {string}
   * @memberof PharmacyTemporaryClosedDates
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyTemporaryClosedDates
   */
  to?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyTemporaryClosedDates
   */
  readonly __typename?: string;
}

export function PharmacyTemporaryClosedDatesFromJSON(json: any): PharmacyTemporaryClosedDates {
  return PharmacyTemporaryClosedDatesFromJSONTyped(json, false);
}

export function PharmacyTemporaryClosedDatesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyTemporaryClosedDates {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyTemporaryClosedDatesTypename,

    dates: !exists(json, "dates") ? undefined : (json["dates"] as Array<any>).map(PharmacyTemporaryClosedDateFromJSON),
    from: !exists(json, "from") ? undefined : json["from"],
    to: !exists(json, "to") ? undefined : json["to"],
  };
}

export function PharmacyTemporaryClosedDatesToJSON(object?: PharmacyTemporaryClosedDates | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    dates: value.dates === undefined ? undefined : (value.dates as Array<any>).map(PharmacyTemporaryClosedDateToJSON),
    from: value.from,
    to: value.to,
  };
}
