/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorTalkroomTypename = "PharmacyCounselorTalkroom" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorTalkroom
 */
export interface PharmacyCounselorTalkroom {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorTalkroom
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorTalkroom
   */
  status: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorTalkroom
   */
  readonly __typename?: string;
}

export function PharmacyCounselorTalkroomFromJSON(json: any): PharmacyCounselorTalkroom {
  return PharmacyCounselorTalkroomFromJSONTyped(json, false);
}

export function PharmacyCounselorTalkroomFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorTalkroom {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorTalkroomTypename,

    id: json["id"],
    status: json["status"],
  };
}

export function PharmacyCounselorTalkroomToJSON(object?: PharmacyCounselorTalkroom | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    status: value.status,
  };
}
