/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const DeliveryDriverTypename = "DeliveryDriver" as const;

/**
 *
 * @export
 * @interface DeliveryDriver
 */
export interface DeliveryDriver {
  /**
   *
   * @type {string}
   * @memberof DeliveryDriver
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDriver
   */
  tel?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryDriver
   */
  readonly __typename?: string;
}

export function DeliveryDriverFromJSON(json: any): DeliveryDriver {
  return DeliveryDriverFromJSONTyped(json, false);
}

export function DeliveryDriverFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryDriver {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryDriverTypename,

    name: !exists(json, "name") ? undefined : json["name"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
  };
}

export function DeliveryDriverToJSON(object?: DeliveryDriver | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    name: value.name,
    tel: value.tel,
  };
}
