/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientReceiptCode,
  PatientReceiptCodeFromJSON,
  PatientReceiptCodeFromJSONTyped,
  PatientReceiptCodeToJSON,
} from "./";

export const PutReceiptCodesResponseTypename = "PutReceiptCodesResponse" as const;

/**
 *
 * @export
 * @interface PutReceiptCodesResponse
 */
export interface PutReceiptCodesResponse {
  /**
   *
   * @type {Array<PatientReceiptCode>}
   * @memberof PutReceiptCodesResponse
   */
  receiptCodes?: Array<PatientReceiptCode>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutReceiptCodesResponse
   */
  readonly __typename?: string;
}

export function PutReceiptCodesResponseFromJSON(json: any): PutReceiptCodesResponse {
  return PutReceiptCodesResponseFromJSONTyped(json, false);
}

export function PutReceiptCodesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutReceiptCodesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutReceiptCodesResponseTypename,

    receiptCodes: !exists(json, "receipt_codes")
      ? undefined
      : (json["receipt_codes"] as Array<any>).map(PatientReceiptCodeFromJSON),
  };
}

export function PutReceiptCodesResponseToJSON(object?: PutReceiptCodesResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    receipt_codes:
      value.receiptCodes === undefined ? undefined : (value.receiptCodes as Array<any>).map(PatientReceiptCodeToJSON),
  };
}
