/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicTemporaryClosedDate,
  ClinicTemporaryClosedDateFromJSON,
  ClinicTemporaryClosedDateFromJSONTyped,
  ClinicTemporaryClosedDateToJSON,
} from "./";

export const GetTemporaryClosedDatesResponseTypename = "GetTemporaryClosedDatesResponse" as const;

/**
 *
 * @export
 * @interface GetTemporaryClosedDatesResponse
 */
export interface GetTemporaryClosedDatesResponse {
  /**
   *
   * @type {Array<ClinicTemporaryClosedDate>}
   * @memberof GetTemporaryClosedDatesResponse
   */
  dates?: Array<ClinicTemporaryClosedDate>;
  /**
   *
   * @type {string}
   * @memberof GetTemporaryClosedDatesResponse
   */
  from?: string;
  /**
   *
   * @type {string}
   * @memberof GetTemporaryClosedDatesResponse
   */
  to?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof GetTemporaryClosedDatesResponse
   */
  readonly __typename?: string;
}

export function GetTemporaryClosedDatesResponseFromJSON(json: any): GetTemporaryClosedDatesResponse {
  return GetTemporaryClosedDatesResponseFromJSONTyped(json, false);
}

export function GetTemporaryClosedDatesResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetTemporaryClosedDatesResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: GetTemporaryClosedDatesResponseTypename,

    dates: !exists(json, "dates") ? undefined : (json["dates"] as Array<any>).map(ClinicTemporaryClosedDateFromJSON),
    from: !exists(json, "from") ? undefined : json["from"],
    to: !exists(json, "to") ? undefined : json["to"],
  };
}

export function GetTemporaryClosedDatesResponseToJSON(object?: GetTemporaryClosedDatesResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    dates: value.dates === undefined ? undefined : (value.dates as Array<any>).map(ClinicTemporaryClosedDateToJSON),
    from: value.from,
    to: value.to,
  };
}
