import { logger } from "@mgdx-libs/logger";
import { onAuthStateChanged, User } from "firebase/auth";
import { useEffect } from "react";

import { getFirebaseAuth } from "../utils/getFirebaseAuth";

let isBooting = true;

const INITIALIZE_FIREBASE_AUTH_KEY = "bootingFirebaseAuth" as const;

const BOOTING_CHECK_DELAY_MS = 1000;

export type FirebaseAuthObserver = (user: User | null) => void;

export const useInitializeFirebaseAuth = (observer: FirebaseAuthObserver) => {
  useEffect(() => {
    window?.localStorage?.setItem(INITIALIZE_FIREBASE_AUTH_KEY, "true");
  }, []);

  useEffect(() => {
    const firebaseAuth = getFirebaseAuth();

    onAuthStateChanged(firebaseAuth, () => {
      if (isBooting && window.localStorage.getItem(INITIALIZE_FIREBASE_AUTH_KEY) !== "false") {
        setTimeout(() => {
          isBooting = false;
          window.localStorage.setItem(INITIALIZE_FIREBASE_AUTH_KEY, "false");
        }, BOOTING_CHECK_DELAY_MS);
      }
    });
  }, []);

  useEffect(() => {
    const firebaseAuth = getFirebaseAuth();

    onAuthStateChanged(firebaseAuth, (user) => {
      const isBootingAnother = !isBooting && window.localStorage.getItem(INITIALIZE_FIREBASE_AUTH_KEY) !== "false";

      logger.debug(
        "useInitializeFirebaseAuth.onAuthStateChanged isBootingAnother: %s, user: %o",
        isBootingAnother,
        user
      );

      if (isBootingAnother && !user) return;

      observer(user);
    });
  }, [observer]);
};
