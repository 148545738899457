/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorNextDispensingScheduleTypename = "PharmacyCounselorNextDispensingSchedule" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorNextDispensingSchedule
 */
export interface PharmacyCounselorNextDispensingSchedule {
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorNextDispensingSchedule
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorNextDispensingSchedule
   */
  prescriptionDate?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorNextDispensingSchedule
   */
  prescriptionDays?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorNextDispensingSchedule
   */
  readonly __typename?: string;
}

export function PharmacyCounselorNextDispensingScheduleFromJSON(json: any): PharmacyCounselorNextDispensingSchedule {
  return PharmacyCounselorNextDispensingScheduleFromJSONTyped(json, false);
}

export function PharmacyCounselorNextDispensingScheduleFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorNextDispensingSchedule {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorNextDispensingScheduleTypename,

    date: json["date"],
    prescriptionDate: !exists(json, "prescription_date") ? undefined : json["prescription_date"],
    prescriptionDays: !exists(json, "prescription_days") ? undefined : json["prescription_days"],
  };
}

export function PharmacyCounselorNextDispensingScheduleToJSON(
  object?: PharmacyCounselorNextDispensingSchedule | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date: moment(value.date).tz("Asia/Tokyo").format(),
    prescription_date: value.prescriptionDate,
    prescription_days: value.prescriptionDays,
  };
}
