import { logger } from "@mgdx-libs/logger";
import * as Sentry from "@sentry/react";
import StackdriverErrorReporter from "stackdriver-errors-js";

import { localErrorHandler } from "../utils/localErrorHandler";

export type ErrorReporter = (
  error: string | Error,
  options?: { skipLocalFrames?: number; skipSentry?: boolean }
) => Promise<void>;

export type CreateErrorHandler = () => ErrorReporter;

export const createErrorHandler: CreateErrorHandler = () => {
  if (process.env.NODE_ENV !== "production") {
    return localErrorHandler;
  }

  const errorHandler = new StackdriverErrorReporter();

  if (
    typeof window !== "undefined" &&
    process.env.FIREBASE_API_KEY &&
    process.env.FIREBASE_PROJECT_ID &&
    process.env.STACKDRIVER_SERVICE_NAME &&
    process.env.npm_package_version
  ) {
    errorHandler.start({
      // Stackdriver API Key
      key: process.env.FIREBASE_API_KEY,

      // GCP Project ID
      projectId: process.env.FIREBASE_PROJECT_ID,

      // The following optional arguments can also be provided:

      service: process.env.STACKDRIVER_SERVICE_NAME,
      // Name of the service reporting the error, defaults to 'web'.

      version: process.env.npm_package_version,
      // Version identifier of the service reporting the error.

      // reportUncaughtExceptions: false
      // Set to false to prevent reporting unhandled exceptions, default: `true`.

      reportUnhandledPromiseRejections: false,
      // Set to false to prevent reporting unhandled promise rejections, default: `true`.

      // disabled: true
      // Set to true to not send error reports, this can be used when developing locally, default: `false`.

      // context: { user: userId }
      // You can set the user later using setUser()
    });
  }

  return async (error, options) => {
    try {
      if (process.env.ENABLE_SENTRY === "true" && !options?.skipSentry) {
        if (error instanceof Error) {
          Sentry.captureException(error);
        } else {
          Sentry.captureMessage(error, "error");
        }
      }
      await errorHandler.report(error, options);
    } catch (e) {
      logger.error(e);
    }
  };
};
