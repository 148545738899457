/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicTemporaryClosedDateTypename = "ClinicTemporaryClosedDate" as const;

/**
 *
 * @export
 * @interface ClinicTemporaryClosedDate
 */
export interface ClinicTemporaryClosedDate {
  /**
   *
   * @type {boolean}
   * @memberof ClinicTemporaryClosedDate
   */
  atAllTimes: boolean;
  /**
   *
   * @type {string}
   * @memberof ClinicTemporaryClosedDate
   */
  date: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ClinicTemporaryClosedDate
   */
  slotNumbers?: Array<number>;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicTemporaryClosedDate
   */
  readonly __typename?: string;
}

export function ClinicTemporaryClosedDateFromJSON(json: any): ClinicTemporaryClosedDate {
  return ClinicTemporaryClosedDateFromJSONTyped(json, false);
}

export function ClinicTemporaryClosedDateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClinicTemporaryClosedDate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicTemporaryClosedDateTypename,

    atAllTimes: json["at_all_times"],
    date: json["date"],
    slotNumbers: !exists(json, "slot_numbers") ? undefined : json["slot_numbers"],
  };
}

export function ClinicTemporaryClosedDateToJSON(object?: ClinicTemporaryClosedDate | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    at_all_times: value.atAllTimes,
    date: value.date,
    slot_numbers: value.slotNumbers,
  };
}
