/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyControlPanelSoundTypename = "PharmacyControlPanelSound" as const;

/**
 *
 * @export
 * @interface PharmacyControlPanelSound
 */
export interface PharmacyControlPanelSound {
  /**
   *
   * @type {boolean}
   * @memberof PharmacyControlPanelSound
   */
  enable?: boolean;
  /**
   *
   * @type {number}
   * @memberof PharmacyControlPanelSound
   */
  index?: PharmacyControlPanelSoundIndexEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyControlPanelSound
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyControlPanelSoundIndexEnum {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2,
  NUMBER_3 = 3,
  NUMBER_4 = 4,
  NUMBER_5 = 5,
  NUMBER_6 = 6,
  NUMBER_7 = 7,
  NUMBER_8 = 8,
  NUMBER_9 = 9,
}

export function PharmacyControlPanelSoundFromJSON(json: any): PharmacyControlPanelSound {
  return PharmacyControlPanelSoundFromJSONTyped(json, false);
}

export function PharmacyControlPanelSoundFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyControlPanelSound {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyControlPanelSoundTypename,

    enable: !exists(json, "enable") ? undefined : json["enable"],
    index: !exists(json, "index") ? undefined : json["index"],
  };
}

export function PharmacyControlPanelSoundToJSON(object?: PharmacyControlPanelSound | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    enable: value.enable,
    index: value.index,
  };
}
