import { ClinicCounselorPatientTypename } from "@mgdx/api/lib/clinic/counselor";
import {
  PharmacyCounselorCounselingPatientTypename,
  PharmacyCounselorMedicineNotebookAutoLinkPatientTypename,
  PharmacyCounselorOptionTypename,
  PharmacyCounselorQuestionnaireTypename,
} from "@mgdx/api/lib/pharmacy/counselor";
import {
  PharmacyFollowUpQuestionnaireOptionTypename,
  PharmacyFollowUpQuestionnaireTypename,
} from "@mgdx/api/lib/pharmacy/pharmacy";
import { ChatterTextMessagePatientTypename } from "@mgdx/api/lib/platform/chatter";
import hasAbortController from "@mgdx/shared/src/utils/hasAbortController";
import { apiErrorHandler, createErrorHandler } from "@mgdx-libs/error-handler";
import { logger } from "@mgdx-libs/logger";
import { HandleRequestConfig } from "@redux-requests/core";
import { createDriver as createPromiseDriver } from "@redux-requests/promise";

const errorHandlerReport = createErrorHandler();

export type CreateHandleRequestConfig = () => HandleRequestConfig;

export const createHandleRequestConfig: CreateHandleRequestConfig = () => ({
  driver: {
    default: createPromiseDriver({
      AbortController: (hasAbortController() ? AbortController : undefined) as any,
      processResponse: (data: any) => {
        logger.debug("response.data: %o", data);

        return {
          data,
        };
      },
    }),
  },
  normalize: true,
  getNormalisationObjectKey: (obj) => `${obj.__typename}/${obj.id}`,
  shouldObjectBeNormalized: (obj) =>
    Object.prototype.hasOwnProperty.call(obj, "id") &&
    Object.prototype.hasOwnProperty.call(obj, "__typename") &&
    obj.__typename !== PharmacyCounselorCounselingPatientTypename &&
    obj.__typename !== ClinicCounselorPatientTypename &&
    obj.__typename !== ChatterTextMessagePatientTypename &&
    obj.__typename !== PharmacyFollowUpQuestionnaireTypename &&
    obj.__typename !== PharmacyFollowUpQuestionnaireOptionTypename &&
    obj.__typename !== PharmacyCounselorQuestionnaireTypename &&
    obj.__typename !== PharmacyCounselorOptionTypename &&
    obj.__typename !== PharmacyCounselorMedicineNotebookAutoLinkPatientTypename,
  onError: async (errorOrResponse: Response | Error, action, store) => {
    if (errorOrResponse instanceof Response || errorOrResponse instanceof DOMException) {
      try {
        throw await apiErrorHandler(errorOrResponse);
      } catch (e) {
        await errorHandlerReport(e);
      }
    } else if (errorOrResponse instanceof Error) {
      await errorHandlerReport(errorOrResponse);
    }

    throw errorOrResponse;
  },
});
