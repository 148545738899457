/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PayerCreditCardPayment,
  PayerCreditCardPaymentFromJSON,
  PayerCreditCardPaymentFromJSONTyped,
  PayerCreditCardPaymentToJSON,
} from "./";

export const PostPaymentRequestBodyTypename = "PostPaymentRequestBody" as const;

/**
 *
 * @export
 * @interface PostPaymentRequestBody
 */
export interface PostPaymentRequestBody {
  /**
   *
   * @type {number}
   * @memberof PostPaymentRequestBody
   */
  amount: number;
  /**
   *
   * @type {number}
   * @memberof PostPaymentRequestBody
   */
  clinicId?: number;
  /**
   *
   * @type {number}
   * @memberof PostPaymentRequestBody
   */
  counselingId?: number;
  /**
   *
   * @type {PayerCreditCardPayment}
   * @memberof PostPaymentRequestBody
   */
  creditCard?: PayerCreditCardPayment;
  /**
   *
   * @type {number}
   * @memberof PostPaymentRequestBody
   */
  patientId?: number;
  /**
   *
   * @type {number}
   * @memberof PostPaymentRequestBody
   */
  pharmacyId?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostPaymentRequestBody
   */
  readonly __typename?: string;
}

export function PostPaymentRequestBodyFromJSON(json: any): PostPaymentRequestBody {
  return PostPaymentRequestBodyFromJSONTyped(json, false);
}

export function PostPaymentRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostPaymentRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostPaymentRequestBodyTypename,

    amount: json["amount"],
    clinicId: !exists(json, "clinic_id") ? undefined : json["clinic_id"],
    counselingId: !exists(json, "counseling_id") ? undefined : json["counseling_id"],
    creditCard: !exists(json, "credit_card") ? undefined : PayerCreditCardPaymentFromJSON(json["credit_card"]),
    patientId: !exists(json, "patient_id") ? undefined : json["patient_id"],
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
  };
}

export function PostPaymentRequestBodyToJSON(object?: PostPaymentRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    amount: value.amount,
    clinic_id: value.clinicId,
    counseling_id: value.counselingId,
    credit_card: PayerCreditCardPaymentToJSON(value.creditCard),
    patient_id: value.patientId,
    pharmacy_id: value.pharmacyId,
  };
}
