import { GetInterviewResponse, PatchCounselingRequestBodyStatusEnum } from "@mgdx/api/lib/pharmacy/counselor";
import pharmacyCounselingApi from "@mgdx/api/lib/pharmacyCounselingApi";
import { pharmacyCounselingPatientApi } from "@mgdx/api/lib/pharmacyCounselingPatientApi";
import pharmacyInterViewApi from "@mgdx/api/lib/pharmacyInterViewApi";
import {
  PharmacyCounseling,
  PharmacyCounselingConnection,
  PharmacyCounselingSearchParams,
  PharmacyPatient,
  SearchCounselingTypeEnum,
} from "@mgdx/shared/src/models/PharmacyCounseling";
import { PharmacyInterview } from "@mgdx/shared/src/models/PharmacyInterview";
import { toastInfo } from "@mgdx/ui/components/Toast";
import { createRequestAction } from "@mgdx-libs/redux-requests";
import toInteger from "lodash/toInteger";

export const findPharmacyCounselingById = createRequestAction<
  { counselingId: string; requestToken?: string },
  PharmacyCounseling
>("requests/counseling/findPharmacyCounselingById", ({ counselingId, requestToken }, meta) => ({
  request: {
    promise: pharmacyCounselingApi.getCounseling({
      counselingId: toInteger(counselingId),
      requestToken,
    }),
  },
  meta: {
    requestsCapacity: 3,
    requestKey: counselingId,
    ...meta,
  },
}));

export const paginatePharmacyCounselings = createRequestAction<
  { per?: number; page: number; patientId: number },
  PharmacyCounselingConnection
>("requests/counseling/paginatePharmacyCounselings", ({ per = 10, page, patientId }, meta) => ({
  request: {
    promise: pharmacyCounselingApi.getCounselings({
      patientId,
      limit: per,
      offset: (page - 1) * per,
      type: [
        SearchCounselingTypeEnum.Dispensing,
        SearchCounselingTypeEnum.OnlineCounseling,
        SearchCounselingTypeEnum.DirectVisit,
      ],
    }),
  },
  meta,
}));

export const recentlyPharmacyCounselings = createRequestAction<{ patientId: number }, PharmacyCounselingConnection>(
  "requests/counseling/recentlyPharmacyCounselings",
  ({ patientId }, meta) => ({
    request: {
      promise: pharmacyCounselingApi.getCounselings({
        patientId,
        limit: 3,
        type: [
          SearchCounselingTypeEnum.Dispensing,
          SearchCounselingTypeEnum.OnlineCounseling,
          SearchCounselingTypeEnum.DirectVisit,
        ],
      }),
    },
    meta: {
      requestsCapacity: 3,
      ...meta,
    },
  })
);

export const summaryPharmacyCounselings = createRequestAction<{ patientId: number }, PharmacyCounselingConnection>(
  "requests/counseling/summaryPharmacyCounselings",
  ({ patientId }, meta) => ({
    request: {
      promise: pharmacyCounselingApi.getCounselings({
        patientId,
        limit: 10,
        type: [
          SearchCounselingTypeEnum.Dispensing,
          SearchCounselingTypeEnum.OnlineCounseling,
          SearchCounselingTypeEnum.DirectVisit,
        ],
      }),
    },
    meta: {
      requestsCapacity: 3,
      ...meta,
    },
  })
);

export const cancelPharmacyCounseling = createRequestAction<{ counselingId: string }, PharmacyCounseling>(
  "requests/counseling/cancelPharmacyCounseling",
  ({ counselingId }, meta) => ({
    request: {
      promise: pharmacyCounselingApi.patchCounseling({
        counselingId: toInteger(counselingId),
        patchCounselingRequestBody: {
          status: PatchCounselingRequestBodyStatusEnum.Canceled,
        },
      }),
    },
    meta: {
      requestKey: counselingId,
      onSuccess: (response, action, store) => {
        toastInfo("予約をキャンセルしました。");
        return response;
      },
      ...meta,
    },
  })
);

export const fetchInterview = createRequestAction<{ counselingId: string }, GetInterviewResponse>(
  "requests/counseling/fetchInterview",
  ({ counselingId }, meta) => ({
    request: {
      promise: pharmacyInterViewApi.getInterview({
        counselingId: toInteger(counselingId),
      }),
    },
    meta: {
      requestKey: counselingId,
      ...meta,
    },
  })
);

export const updateInterview = createRequestAction<
  {
    counselingId: string;
    interview: PharmacyInterview;
  },
  PharmacyInterview
>("requests/counseling/updateInterview", ({ counselingId, interview }, meta) => ({
  request: {
    promise: pharmacyInterViewApi.putInterview({
      counselingId: toInteger(counselingId),
      pharmacyCounselorInterview: interview,
    }),
  },
  meta: {
    requestKey: counselingId,
    ...meta,
  },
}));

export const searchPharmacyCounselings = createRequestAction<
  Omit<PharmacyCounselingSearchParams, "type">,
  PharmacyCounselingConnection
>("requests/counseling/searchPharmacyCounselings", (params, meta) => ({
  request: {
    promise: pharmacyCounselingApi.getCounselings({
      ...params,
      type: [
        SearchCounselingTypeEnum.Dispensing,
        SearchCounselingTypeEnum.OnlineCounseling,
        SearchCounselingTypeEnum.DirectVisit,
      ],
    }),
  },
  meta: {
    requestsCapacity: 3,
    ...meta,
  },
}));

export const changePharmacyCounselingStatus = createRequestAction<
  {
    counselingId: string;
    status: PatchCounselingRequestBodyStatusEnum;
  },
  PharmacyCounseling
>("requests/counseling/changePharmacyCounselingStatus", ({ counselingId, status }, meta) => ({
  request: {
    promise: pharmacyCounselingApi.patchCounseling({
      counselingId: toInteger(counselingId),
      patchCounselingRequestBody: {
        status,
      },
    }),
  },
  meta: {
    requestKey: counselingId,
    ...meta,
  },
}));

export const linkPharmacyCounseling = createRequestAction<
  { counselingId: string; requestToken: string },
  PharmacyPatient
>("requests/counseling/linkPharmacyCounseling", ({ counselingId, requestToken }, meta) => ({
  request: {
    promise: pharmacyCounselingPatientApi.putCounselingPatient({
      counselingId: toInteger(counselingId),
      putPatientRequestBody: { requestToken },
    }),
  },
  meta: {
    requestKey: counselingId,
    ...meta,
  },
}));

export type RegisterTelCounselingParams = { counselingId: string; tel: string; requestToken: string };

export const registerTelCounseling = createRequestAction<RegisterTelCounselingParams, PharmacyPatient>(
  "requests/counseling/registerTelCounseling",
  ({ counselingId, tel, requestToken }, meta) => ({
    request: {
      promise: pharmacyCounselingPatientApi.patchCounselingPatient({
        counselingId: toInteger(counselingId),
        patchPatientRequestBody: {
          tel,
          requestToken,
        },
      }),
    },
    meta,
  })
);
