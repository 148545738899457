/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorCounselingReport,
  PharmacyCounselorCounselingReportFromJSON,
  PharmacyCounselorCounselingReportFromJSONTyped,
  PharmacyCounselorCounselingReportToJSON,
} from "./";

export const GetCounselingReportsResponseTypename = "GetCounselingReportsResponse" as const;

/**
 *
 * @export
 * @interface GetCounselingReportsResponse
 */
export interface GetCounselingReportsResponse {
  /**
   *
   * @type {Array<PharmacyCounselorCounselingReport>}
   * @memberof GetCounselingReportsResponse
   */
  counselingReports: Array<PharmacyCounselorCounselingReport>;
  /**
   *
   * @type {number}
   * @memberof GetCounselingReportsResponse
   */
  count?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof GetCounselingReportsResponse
   */
  readonly __typename?: string;
}

export function GetCounselingReportsResponseFromJSON(json: any): GetCounselingReportsResponse {
  return GetCounselingReportsResponseFromJSONTyped(json, false);
}

export function GetCounselingReportsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCounselingReportsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: GetCounselingReportsResponseTypename,

    counselingReports: (json["counseling_reports"] as Array<any>).map(PharmacyCounselorCounselingReportFromJSON),
    count: !exists(json, "count") ? undefined : json["count"],
  };
}

export function GetCounselingReportsResponseToJSON(object?: GetCounselingReportsResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling_reports: (value.counselingReports as Array<any>).map(PharmacyCounselorCounselingReportToJSON),
    count: value.count,
  };
}
