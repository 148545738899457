// extracted by mini-css-extract-plugin
export var badge = "OvalButton-module--badge--a42e9";
export var button = "OvalButton-module--button--be492";
export var container = "OvalButton-module--container--ae734";
export var disabled = "OvalButton-module--disabled--9518d";
export var keyBasic = "OvalButton-module--keyBasic--94152";
export var keyWeak = "OvalButton-module--keyWeak--a2f08";
export var label = "OvalButton-module--label--903c7";
export var plain = "OvalButton-module--plain--b4045";
export var primary = "OvalButton-module--primary--98a59";
export var sizeM = "OvalButton-module--sizeM--29fa0";
export var sizeS = "OvalButton-module--sizeS--16cf3";
export var sizeXXM = "OvalButton-module--sizeXXM--a712e";
export var variableBasic = "OvalButton-module--variableBasic--57fbf";
export var variableWeak = "OvalButton-module--variableWeak--3e01f";