/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientPatientProviderTypeTypename = "PatientPatientProviderType" as const;

/**
 *
 * @export
 * @interface PatientPatientProviderType
 */
export interface PatientPatientProviderType {
  /**
   *
   * @type {number}
   * @memberof PatientPatientProviderType
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PatientPatientProviderType
   */
  name: string;
  /**
   *
   * @type {object}
   * @memberof PatientPatientProviderType
   */
  notificationAuthorization?: object;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientPatientProviderType
   */
  readonly __typename?: string;
}

export function PatientPatientProviderTypeFromJSON(json: any): PatientPatientProviderType {
  return PatientPatientProviderTypeFromJSONTyped(json, false);
}

export function PatientPatientProviderTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientPatientProviderType {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientPatientProviderTypeTypename,

    id: json["id"],
    name: json["name"],
    notificationAuthorization: !exists(json, "notification_authorization")
      ? undefined
      : json["notification_authorization"],
  };
}

export function PatientPatientProviderTypeToJSON(object?: PatientPatientProviderType | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    name: value.name,
    notification_authorization: value.notificationAuthorization,
  };
}
