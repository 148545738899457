/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { DeliveryOrder, DeliveryOrderFromJSON, DeliveryOrderFromJSONTyped, DeliveryOrderToJSON } from "./";

export const GetOrdersResponseTypename = "GetOrdersResponse" as const;

/**
 *
 * @export
 * @interface GetOrdersResponse
 */
export interface GetOrdersResponse {
  /**
   *
   * @type {number}
   * @memberof GetOrdersResponse
   */
  count?: number;
  /**
   *
   * @type {Array<DeliveryOrder>}
   * @memberof GetOrdersResponse
   */
  orders: Array<DeliveryOrder>;
  /**
   * Resource type name
   * @type {string}
   * @memberof GetOrdersResponse
   */
  readonly __typename?: string;
}

export function GetOrdersResponseFromJSON(json: any): GetOrdersResponse {
  return GetOrdersResponseFromJSONTyped(json, false);
}

export function GetOrdersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOrdersResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: GetOrdersResponseTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    orders: (json["orders"] as Array<any>).map(DeliveryOrderFromJSON),
  };
}

export function GetOrdersResponseToJSON(object?: GetOrdersResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    orders: (value.orders as Array<any>).map(DeliveryOrderToJSON),
  };
}
