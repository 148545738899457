export const QolAccountAnswerTypename = "QolAccountAnswer" as const;

/**
 *
 * @export
 * @interface QolAccountAnswer
 * @see QolAccountAnswer
 */
export interface QolAccountAnswer {
  /**
   *
   * @type {string}
   * @memberof QolAccountAnswer
   */
  lable: string;
  /**
   *
   * @type {string}
   * @memberof QolAccountAnswer
   */
  value: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof QolAccountAnswer
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolAccountAnswerFromJSON(json: any): QolAccountAnswer {
  return QolAccountAnswerFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolAccountAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): QolAccountAnswer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: QolAccountAnswerTypename,
    lable: json["lable"],
    value: json["value"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function QolAccountAnswerToJSON(object?: QolAccountAnswer | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    lable: value.lable,
    value: value.value,
  };
}
