/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostMedicineAlarmsRequestBodyTypename = "PostMedicineAlarmsRequestBody" as const;

/**
 *
 * @export
 * @interface PostMedicineAlarmsRequestBody
 */
export interface PostMedicineAlarmsRequestBody {
  /**
   *
   * @type {Array<string>}
   * @memberof PostMedicineAlarmsRequestBody
   */
  medicineAlarmSettingIds?: Array<string>;
  /**
   *
   * @type {Date}
   * @memberof PostMedicineAlarmsRequestBody
   */
  settingTime: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostMedicineAlarmsRequestBody
   */
  readonly __typename?: string;
}

export function PostMedicineAlarmsRequestBodyFromJSON(json: any): PostMedicineAlarmsRequestBody {
  return PostMedicineAlarmsRequestBodyFromJSONTyped(json, false);
}

export function PostMedicineAlarmsRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostMedicineAlarmsRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostMedicineAlarmsRequestBodyTypename,

    medicineAlarmSettingIds: !exists(json, "medicine_alarm_setting_ids")
      ? undefined
      : json["medicine_alarm_setting_ids"],
    settingTime: json["setting_time"],
  };
}

export function PostMedicineAlarmsRequestBodyToJSON(object?: PostMedicineAlarmsRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    medicine_alarm_setting_ids: value.medicineAlarmSettingIds,
    setting_time: moment(value.settingTime).tz("Asia/Tokyo").format(),
  };
}
