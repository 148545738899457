/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchAcceptCodeRequestBody,
  PatchAcceptCodeRequestBodyFromJSON,
  PatchAcceptCodeRequestBodyToJSON,
  PatchAcceptCodeResponse,
  PatchAcceptCodeResponseFromJSON,
  PatchAcceptCodeResponseToJSON,
  PatchCounselingRequestBody,
  PatchCounselingRequestBodyFromJSON,
  PatchCounselingRequestBodyToJSON,
  PatchDispensingRequestBody,
  PatchDispensingRequestBodyFromJSON,
  PatchDispensingRequestBodyToJSON,
  PharmacyCounselorAuthorizedAcceptCode,
  PharmacyCounselorAuthorizedAcceptCodeFromJSON,
  PharmacyCounselorAuthorizedAcceptCodeToJSON,
  PharmacyCounselorCounseling,
  PharmacyCounselorCounselingFromJSON,
  PharmacyCounselorCounselingToJSON,
  PharmacyCounselorCounselings,
  PharmacyCounselorCounselingsFromJSON,
  PharmacyCounselorCounselingsToJSON,
  PharmacyCounselorCustomBadRequest1,
  PharmacyCounselorCustomBadRequest1FromJSON,
  PharmacyCounselorCustomBadRequest1ToJSON,
  PharmacyCounselorCustomBadRequest2,
  PharmacyCounselorCustomBadRequest2FromJSON,
  PharmacyCounselorCustomBadRequest2ToJSON,
  PharmacyCounselorCustomEntityTooLarge,
  PharmacyCounselorCustomEntityTooLargeFromJSON,
  PharmacyCounselorCustomEntityTooLargeToJSON,
  PharmacyCounselorDispensing,
  PharmacyCounselorDispensingFromJSON,
  PharmacyCounselorDispensingToJSON,
  PostCounselingRequestBody,
  PostCounselingRequestBodyFromJSON,
  PostCounselingRequestBodyToJSON,
  PostCounselingResponse,
  PostCounselingResponseFromJSON,
  PostCounselingResponseToJSON,
} from "../models";

export interface GetAcceptCodeRequest {
  counselingId: number;
  requestToken?: string;
}

export interface GetCounselingRequest {
  counselingId: number;
  requestToken?: string;
  answerToken?: string;
}

export interface GetCounselingsRequest {
  from?: string;
  to?: string;
  requestedFrom?: string;
  requestedTo?: string;
  pharmacistId?: number;
  patientId?: number;
  keyword?: string;
  familyAccountId?: number;
  followUpStatus?: Array<GetCounselingsFollowUpStatusEnum>;
  followUpQuestionnairesRegistered?: boolean;
  followUpNotificationTimeFrom?: string;
  followUpNotificationTimeTo?: string;
  followUpIsNotifyAnswered?: boolean;
  status?: Array<GetCounselingsStatusEnum>;
  billingStatus?: GetCounselingsBillingStatusEnum;
  dispensingStatus?: Array<GetCounselingsDispensingStatusEnum>;
  limit?: number;
  offset?: number;
  orderBy?: GetCounselingsOrderByEnum;
  type?: Array<GetCounselingsTypeEnum>;
  requestType?: GetCounselingsRequestTypeEnum;
  autoLinkStatus?: Array<GetCounselingsAutoLinkStatusEnum>;
  acceptCode?: number;
  dateOfBirth?: string;
}

export interface GetQRCodeRequest {
  counselingId: number;
  size: number;
  apiKey: string;
}

export interface PatchAcceptCodeRequest {
  counselingId: number;
  patchAcceptCodeRequestBody: PatchAcceptCodeRequestBody;
}

export interface PatchCounselingRequest {
  counselingId: number;
  patchCounselingRequestBody: PatchCounselingRequestBody;
}

export interface PatchDispensingRequest {
  counselingId: number;
  patchDispensingRequestBody: PatchDispensingRequestBody;
}

export interface PostCounselingRequest {
  postCounselingRequestBody: PostCounselingRequestBody;
}

/**
 *
 */
export class CounselingApi extends runtime.BaseAPI {
  /**
   * Authentication by patient\'s JWT or RequestToken is required
   */
  async getAcceptCodeRaw(
    requestParameters: GetAcceptCodeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorAuthorizedAcceptCode>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling getAcceptCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.requestToken !== undefined) {
      queryParameters["request_token"] = requestParameters.requestToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/accept-code`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      PharmacyCounselorAuthorizedAcceptCodeFromJSON(jsonValue)
    );
  }

  /**
   * Authentication by patient\'s JWT or RequestToken is required
   */
  async getAcceptCode(
    requestParameters: GetAcceptCodeRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorAuthorizedAcceptCode> {
    return promiseRetry(
      (retry) =>
        this.getAcceptCodeRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get counseling
   */
  async getCounselingRaw(
    requestParameters: GetCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorCounseling>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling getCounseling."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.requestToken !== undefined) {
      queryParameters["request_token"] = requestParameters.requestToken;
    }

    if (requestParameters.answerToken !== undefined) {
      queryParameters["answer_token"] = requestParameters.answerToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorCounselingFromJSON(jsonValue));
  }

  /**
   * Get counseling
   */
  async getCounseling(
    requestParameters: GetCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorCounseling> {
    return promiseRetry(
      (retry) =>
        this.getCounselingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get counselings using JWT. If you are a patient, you can get only your own, if you are a pharmacist, you can get all of your pharmacy\'s.
   */
  async getCounselingsRaw(
    requestParameters: GetCounselingsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorCounselings>> {
    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = moment(requestParameters.from).tz("Asia/Tokyo").format();
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = moment(requestParameters.to).tz("Asia/Tokyo").format();
    }

    if (requestParameters.requestedFrom !== undefined) {
      queryParameters["requested_from"] = moment(requestParameters.requestedFrom).tz("Asia/Tokyo").format();
    }

    if (requestParameters.requestedTo !== undefined) {
      queryParameters["requested_to"] = moment(requestParameters.requestedTo).tz("Asia/Tokyo").format();
    }

    if (requestParameters.pharmacistId !== undefined) {
      queryParameters["pharmacist_id"] = requestParameters.pharmacistId;
    }

    if (requestParameters.patientId !== undefined) {
      queryParameters["patient_id"] = requestParameters.patientId;
    }

    if (requestParameters.keyword !== undefined) {
      queryParameters["keyword"] = requestParameters.keyword;
    }

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    if (requestParameters.followUpStatus) {
      queryParameters["follow_up_status"] = requestParameters.followUpStatus;
    }

    if (requestParameters.followUpQuestionnairesRegistered !== undefined) {
      queryParameters["follow_up_questionnaires_registered"] = requestParameters.followUpQuestionnairesRegistered;
    }

    if (requestParameters.followUpNotificationTimeFrom !== undefined) {
      queryParameters["follow_up_notification_time_from"] = moment(requestParameters.followUpNotificationTimeFrom)
        .tz("Asia/Tokyo")
        .format();
    }

    if (requestParameters.followUpNotificationTimeTo !== undefined) {
      queryParameters["follow_up_notification_time_to"] = moment(requestParameters.followUpNotificationTimeTo)
        .tz("Asia/Tokyo")
        .format();
    }

    if (requestParameters.followUpIsNotifyAnswered !== undefined) {
      queryParameters["follow_up_is_notify_answered"] = requestParameters.followUpIsNotifyAnswered;
    }

    if (requestParameters.status) {
      queryParameters["status"] = requestParameters.status;
    }

    if (requestParameters.billingStatus !== undefined) {
      queryParameters["billing_status"] = requestParameters.billingStatus;
    }

    if (requestParameters.dispensingStatus) {
      queryParameters["dispensing_status"] = requestParameters.dispensingStatus;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.offset !== undefined) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.orderBy !== undefined) {
      queryParameters["order_by"] = requestParameters.orderBy;
    }

    if (requestParameters.type) {
      queryParameters["type"] = requestParameters.type;
    }

    if (requestParameters.requestType !== undefined) {
      queryParameters["request_type"] = requestParameters.requestType;
    }

    if (requestParameters.autoLinkStatus) {
      queryParameters["auto_link_status"] = requestParameters.autoLinkStatus;
    }

    if (requestParameters.acceptCode !== undefined) {
      queryParameters["accept_code"] = requestParameters.acceptCode;
    }

    if (requestParameters.dateOfBirth !== undefined) {
      queryParameters["date_of_birth"] = requestParameters.dateOfBirth;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorCounselingsFromJSON(jsonValue));
  }

  /**
   * Get counselings using JWT. If you are a patient, you can get only your own, if you are a pharmacist, you can get all of your pharmacy\'s.
   */
  async getCounselings(
    requestParameters: GetCounselingsRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorCounselings> {
    return promiseRetry(
      (retry) =>
        this.getCounselingsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get QRCode image
   */
  async getQRCodeRaw(
    requestParameters: GetQRCodeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Blob>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling getQRCode."
      );
    }

    if (requestParameters.size === null || requestParameters.size === undefined) {
      throw new runtime.RequiredError(
        "size",
        "Required parameter requestParameters.size was null or undefined when calling getQRCode."
      );
    }

    if (requestParameters.apiKey === null || requestParameters.apiKey === undefined) {
      throw new runtime.RequiredError(
        "apiKey",
        "Required parameter requestParameters.apiKey was null or undefined when calling getQRCode."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.size !== undefined) {
      queryParameters["size"] = requestParameters.size;
    }

    if (requestParameters.apiKey !== undefined) {
      queryParameters["api_key"] = requestParameters.apiKey;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/qr-code`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.BlobApiResponse(response);
  }

  /**
   * Get QRCode image
   */
  async getQRCode(requestParameters: GetQRCodeRequest, initOverrides?: RequestInit): Promise<Blob> {
    return promiseRetry(
      (retry) =>
        this.getQRCodeRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Authentication by pharmacist\'s JWT. Update counseling accept-code visible
   */
  async patchAcceptCodeRaw(
    requestParameters: PatchAcceptCodeRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatchAcceptCodeResponse>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling patchAcceptCode."
      );
    }

    if (
      requestParameters.patchAcceptCodeRequestBody === null ||
      requestParameters.patchAcceptCodeRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchAcceptCodeRequestBody",
        "Required parameter requestParameters.patchAcceptCodeRequestBody was null or undefined when calling patchAcceptCode."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/accept-code`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchAcceptCodeRequestBodyToJSON(requestParameters.patchAcceptCodeRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatchAcceptCodeResponseFromJSON(jsonValue));
  }

  /**
   * Authentication by pharmacist\'s JWT. Update counseling accept-code visible
   */
  async patchAcceptCode(
    requestParameters: PatchAcceptCodeRequest,
    initOverrides?: RequestInit
  ): Promise<PatchAcceptCodeResponse> {
    return promiseRetry(
      (retry) =>
        this.patchAcceptCodeRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update counseling
   */
  async patchCounselingRaw(
    requestParameters: PatchCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorCounseling>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling patchCounseling."
      );
    }

    if (
      requestParameters.patchCounselingRequestBody === null ||
      requestParameters.patchCounselingRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchCounselingRequestBody",
        "Required parameter requestParameters.patchCounselingRequestBody was null or undefined when calling patchCounseling."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchCounselingRequestBodyToJSON(requestParameters.patchCounselingRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorCounselingFromJSON(jsonValue));
  }

  /**
   * Update counseling
   */
  async patchCounseling(
    requestParameters: PatchCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorCounseling> {
    return promiseRetry(
      (retry) =>
        this.patchCounselingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Update dispensing information
   */
  async patchDispensingRaw(
    requestParameters: PatchDispensingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCounselorDispensing>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling patchDispensing."
      );
    }

    if (
      requestParameters.patchDispensingRequestBody === null ||
      requestParameters.patchDispensingRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchDispensingRequestBody",
        "Required parameter requestParameters.patchDispensingRequestBody was null or undefined when calling patchDispensing."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/dispensing`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchDispensingRequestBodyToJSON(requestParameters.patchDispensingRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCounselorDispensingFromJSON(jsonValue));
  }

  /**
   * Update dispensing information
   */
  async patchDispensing(
    requestParameters: PatchDispensingRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCounselorDispensing> {
    return promiseRetry(
      (retry) =>
        this.patchDispensingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Request Counseling Reservation
   */
  async postCounselingRaw(
    requestParameters: PostCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PostCounselingResponse>> {
    if (
      requestParameters.postCounselingRequestBody === null ||
      requestParameters.postCounselingRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postCounselingRequestBody",
        "Required parameter requestParameters.postCounselingRequestBody was null or undefined when calling postCounseling."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostCounselingRequestBodyToJSON(requestParameters.postCounselingRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PostCounselingResponseFromJSON(jsonValue));
  }

  /**
   * Request Counseling Reservation
   */
  async postCounseling(
    requestParameters: PostCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<PostCounselingResponse> {
    return promiseRetry(
      (retry) =>
        this.postCounselingRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsFollowUpStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Notified = "notified",
  Answered = "answered",
  AnswerConfirmed = "answer_confirmed",
  Completed = "completed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsStatusEnum {
  Requested = "requested",
  Canceled = "canceled",
  Accepted = "accepted",
  Waiting = "waiting",
  CounselingStarted = "counseling_started",
  CounselingCompleted = "counseling_completed",
  BillingProcessing = "billing_processing",
  BillingCompleted = "billing_completed",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsBillingStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Started = "started",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsDispensingStatusEnum {
  Initialized = "initialized",
  Dispensed = "dispensed",
  Notified = "notified",
  Completed = "completed",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsOrderByEnum {
  Date = "date",
  DateDesc = "date_desc",
  FollowUpNotificationDate = "follow_up_notification_date",
  FollowUpNotificationDateDesc = "follow_up_notification_date_desc",
  FollowUpAnsweredDate = "follow_up_answered_date",
  FollowUpAnsweredDateDesc = "follow_up_answered_date_desc",
  RequestedTime = "requested_time",
  RequestedTimeDesc = "requested_time_desc",
  AcceptedTime = "accepted_time",
  AcceptedTimeDesc = "accepted_time_desc",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsTypeEnum {
  OnlineCounseling = "online_counseling",
  Dispensing = "dispensing",
  FollowUpOnly = "follow_up_only",
  DeliveryOnly = "delivery_only",
  DirectVisit = "direct_visit",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsRequestTypeEnum {
  Web = "web",
  Line = "line",
}
/**
 * @export
 * @enum {string}
 */
export enum GetCounselingsAutoLinkStatusEnum {
  Initialized = "initialized",
  Completed = "completed",
  Canceled = "canceled",
}
