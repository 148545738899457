/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyFamilyPharmacistLicenseTypename = "PharmacyFamilyPharmacistLicense" as const;

/**
 *
 * @export
 * @interface PharmacyFamilyPharmacistLicense
 */
export interface PharmacyFamilyPharmacistLicense {
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  cancer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  child?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  chineseMedicine?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  diabetes?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  homeCare?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  kidneyDisease?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  others?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  pregnantWoman?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  primaryCare?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  sports?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyFamilyPharmacistLicense
   */
  readonly __typename?: string;
}

export function PharmacyFamilyPharmacistLicenseFromJSON(json: any): PharmacyFamilyPharmacistLicense {
  return PharmacyFamilyPharmacistLicenseFromJSONTyped(json, false);
}

export function PharmacyFamilyPharmacistLicenseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyFamilyPharmacistLicense {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyFamilyPharmacistLicenseTypename,

    cancer: !exists(json, "cancer") ? undefined : json["cancer"],
    child: !exists(json, "child") ? undefined : json["child"],
    chineseMedicine: !exists(json, "chinese_medicine") ? undefined : json["chinese_medicine"],
    diabetes: !exists(json, "diabetes") ? undefined : json["diabetes"],
    homeCare: !exists(json, "home_care") ? undefined : json["home_care"],
    kidneyDisease: !exists(json, "kidney_disease") ? undefined : json["kidney_disease"],
    others: !exists(json, "others") ? undefined : json["others"],
    pregnantWoman: !exists(json, "pregnant_woman") ? undefined : json["pregnant_woman"],
    primaryCare: !exists(json, "primary_care") ? undefined : json["primary_care"],
    sports: !exists(json, "sports") ? undefined : json["sports"],
  };
}

export function PharmacyFamilyPharmacistLicenseToJSON(object?: PharmacyFamilyPharmacistLicense | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    cancer: value.cancer,
    child: value.child,
    chinese_medicine: value.chineseMedicine,
    diabetes: value.diabetes,
    home_care: value.homeCare,
    kidney_disease: value.kidneyDisease,
    others: value.others,
    pregnant_woman: value.pregnantWoman,
    primary_care: value.primaryCare,
    sports: value.sports,
  };
}
