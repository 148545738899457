/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMasterAllergy,
  PatientMasterAllergyFromJSON,
  PatientMasterAllergyFromJSONTyped,
  PatientMasterAllergyToJSON,
  PatientMasterSymptom,
  PatientMasterSymptomFromJSON,
  PatientMasterSymptomFromJSONTyped,
  PatientMasterSymptomToJSON,
  PatientMasterVaccine,
  PatientMasterVaccineFromJSON,
  PatientMasterVaccineFromJSONTyped,
  PatientMasterVaccineToJSON,
} from "./";

export const PatientMedicationReconciliationFormMasterTypename = "PatientMedicationReconciliationFormMaster" as const;

/**
 *
 * @export
 * @interface PatientMedicationReconciliationFormMaster
 */
export interface PatientMedicationReconciliationFormMaster {
  /**
   *
   * @type {Array<PatientMasterAllergy>}
   * @memberof PatientMedicationReconciliationFormMaster
   */
  allergies?: Array<PatientMasterAllergy>;
  /**
   *
   * @type {Array<PatientMasterSymptom>}
   * @memberof PatientMedicationReconciliationFormMaster
   */
  symptoms?: Array<PatientMasterSymptom>;
  /**
   *
   * @type {Array<PatientMasterVaccine>}
   * @memberof PatientMedicationReconciliationFormMaster
   */
  vaccines?: Array<PatientMasterVaccine>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicationReconciliationFormMaster
   */
  readonly __typename?: string;
}

export function PatientMedicationReconciliationFormMasterFromJSON(
  json: any
): PatientMedicationReconciliationFormMaster {
  return PatientMedicationReconciliationFormMasterFromJSONTyped(json, false);
}

export function PatientMedicationReconciliationFormMasterFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicationReconciliationFormMaster {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicationReconciliationFormMasterTypename,

    allergies: !exists(json, "allergies")
      ? undefined
      : (json["allergies"] as Array<any>).map(PatientMasterAllergyFromJSON),
    symptoms: !exists(json, "symptoms")
      ? undefined
      : (json["symptoms"] as Array<any>).map(PatientMasterSymptomFromJSON),
    vaccines: !exists(json, "vaccines")
      ? undefined
      : (json["vaccines"] as Array<any>).map(PatientMasterVaccineFromJSON),
  };
}

export function PatientMedicationReconciliationFormMasterToJSON(
  object?: PatientMedicationReconciliationFormMaster | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    allergies:
      value.allergies === undefined ? undefined : (value.allergies as Array<any>).map(PatientMasterAllergyToJSON),
    symptoms: value.symptoms === undefined ? undefined : (value.symptoms as Array<any>).map(PatientMasterSymptomToJSON),
    vaccines: value.vaccines === undefined ? undefined : (value.vaccines as Array<any>).map(PatientMasterVaccineToJSON),
  };
}
