/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PayerShopAuthenticationTypename = "PayerShopAuthentication" as const;

/**
 *
 * @export
 * @interface PayerShopAuthentication
 */
export interface PayerShopAuthentication {
  /**
   *
   * @type {string}
   * @memberof PayerShopAuthentication
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof PayerShopAuthentication
   */
  user: string;
  /**
   *
   * @type {string}
   * @memberof PayerShopAuthentication
   */
  userPoolId: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PayerShopAuthentication
   */
  readonly __typename?: string;
}

export function PayerShopAuthenticationFromJSON(json: any): PayerShopAuthentication {
  return PayerShopAuthenticationFromJSONTyped(json, false);
}

export function PayerShopAuthenticationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayerShopAuthentication {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PayerShopAuthenticationTypename,

    clientId: json["client_id"],
    user: json["user"],
    userPoolId: json["user_pool_id"],
  };
}

export function PayerShopAuthenticationToJSON(object?: PayerShopAuthentication | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    client_id: value.clientId,
    user: value.user,
    user_pool_id: value.userPoolId,
  };
}
