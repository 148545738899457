/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PayerPaymentTypename = "PayerPayment" as const;

/**
 *
 * @export
 * @interface PayerPayment
 */
export interface PayerPayment {
  /**
   *
   * @type {number}
   * @memberof PayerPayment
   */
  amount: number;
  /**
   * JWT value takes precedences if exists
   * @type {number}
   * @memberof PayerPayment
   */
  clinicId?: number;
  /**
   *
   * @type {number}
   * @memberof PayerPayment
   */
  counselingId: number;
  /**
   *
   * @type {string}
   * @memberof PayerPayment
   */
  detail?: string;
  /**
   *
   * @type {string}
   * @memberof PayerPayment
   */
  id: string;
  /**
   * JWT value takes precedences if exists
   * @type {number}
   * @memberof PayerPayment
   */
  patientId?: number;
  /**
   * JWT value takes precedences if exists
   * @type {number}
   * @memberof PayerPayment
   */
  pharmacyId?: number;
  /**
   *
   * @type {string}
   * @memberof PayerPayment
   */
  status?: PayerPaymentStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PayerPayment
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PayerPaymentStatusEnum {
  Started = "started",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}

export function PayerPaymentFromJSON(json: any): PayerPayment {
  return PayerPaymentFromJSONTyped(json, false);
}

export function PayerPaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayerPayment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PayerPaymentTypename,

    amount: json["amount"],
    clinicId: !exists(json, "clinic_id") ? undefined : json["clinic_id"],
    counselingId: json["counseling_id"],
    detail: !exists(json, "detail") ? undefined : json["detail"],
    id: json["id"],
    patientId: !exists(json, "patient_id") ? undefined : json["patient_id"],
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function PayerPaymentToJSON(object?: PayerPayment | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    amount: value.amount,
    clinic_id: value.clinicId,
    counseling_id: value.counselingId,
    detail: value.detail,
    id: value.id,
    patient_id: value.patientId,
    pharmacy_id: value.pharmacyId,
    status: value.status,
  };
}
