/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMissionCompletedEvent,
  PatientMissionCompletedEventFromJSON,
  PatientMissionCompletedEventFromJSONTyped,
  PatientMissionCompletedEventToJSON,
} from "./";

export const PatientMissionTypename = "PatientMission" as const;

/**
 *
 * @export
 * @interface PatientMission
 */
export interface PatientMission {
  /**
   *
   * @type {number}
   * @memberof PatientMission
   */
  chainId: number;
  /**
   *
   * @type {Array<PatientMissionCompletedEvent>}
   * @memberof PatientMission
   */
  completedEvents?: Array<PatientMissionCompletedEvent>;
  /**
   *
   * @type {string}
   * @memberof PatientMission
   */
  id: string;
  /**
   *
   * @type {Date}
   * @memberof PatientMission
   */
  participationTime: string;
  /**
   *
   * @type {number}
   * @memberof PatientMission
   */
  patientId: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMission
   */
  readonly __typename?: string;
}

export function PatientMissionFromJSON(json: any): PatientMission {
  return PatientMissionFromJSONTyped(json, false);
}

export function PatientMissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMission {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMissionTypename,

    chainId: json["chain_id"],
    completedEvents: !exists(json, "completed_events")
      ? undefined
      : (json["completed_events"] as Array<any>).map(PatientMissionCompletedEventFromJSON),
    id: json["id"],
    participationTime: json["participation_time"],
    patientId: json["patient_id"],
  };
}

export function PatientMissionToJSON(object?: PatientMission | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    completed_events:
      value.completedEvents === undefined
        ? undefined
        : (value.completedEvents as Array<any>).map(PatientMissionCompletedEventToJSON),
    id: value.id,
    participation_time: moment(value.participationTime).tz("Asia/Tokyo").format(),
    patient_id: value.patientId,
  };
}
