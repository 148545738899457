/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientMedicationReconciliationForm,
  PatientMedicationReconciliationFormFromJSON,
  PatientMedicationReconciliationFormFromJSONTyped,
  PatientMedicationReconciliationFormToJSON,
} from "./";

export const MedicationReconciliationFormResponseTypename = "MedicationReconciliationFormResponse" as const;

/**
 *
 * @export
 * @interface MedicationReconciliationFormResponse
 */
export interface MedicationReconciliationFormResponse {
  /**
   *
   * @type {PatientMedicationReconciliationForm}
   * @memberof MedicationReconciliationFormResponse
   */
  medicationReconciliationForm?: PatientMedicationReconciliationForm;
  /**
   * Resource type name
   * @type {string}
   * @memberof MedicationReconciliationFormResponse
   */
  readonly __typename?: string;
}

export function MedicationReconciliationFormResponseFromJSON(json: any): MedicationReconciliationFormResponse {
  return MedicationReconciliationFormResponseFromJSONTyped(json, false);
}

export function MedicationReconciliationFormResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MedicationReconciliationFormResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: MedicationReconciliationFormResponseTypename,

    medicationReconciliationForm: !exists(json, "medication_reconciliation_form")
      ? undefined
      : PatientMedicationReconciliationFormFromJSON(json["medication_reconciliation_form"]),
  };
}

export function MedicationReconciliationFormResponseToJSON(object?: MedicationReconciliationFormResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    medication_reconciliation_form: PatientMedicationReconciliationFormToJSON(value.medicationReconciliationForm),
  };
}
