import { exists } from "@mgdx/api/lib/runtime";

export const PharmacyInterviewQuestionnaireTypename = "PharmacyInterviewQuestionnaire" as const;

/**
 *
 * @export
 * @interface PharmacyInterviewQuestionnaire
 */
export interface PharmacyInterviewQuestionnaire {
  /**
   *
   * @type {string}
   * @memberof PharmacyInterviewQuestionnaire
   */
  answer?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyInterviewQuestionnaire
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyInterviewQuestionnaire
   */
  question: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyInterviewQuestionnaire
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PharmacyInterviewQuestionnaireFromJSON(json: any): PharmacyInterviewQuestionnaire {
  return PharmacyInterviewQuestionnaireFromJSONTyped(json, false);
}

export function PharmacyInterviewQuestionnaireFromJSONTyped(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: any,
  ignoreDiscriminator: boolean
): PharmacyInterviewQuestionnaire {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyInterviewQuestionnaireTypename,

    answer: !exists(json, "answer") ? undefined : json["answer"],
    id: !exists(json, "id") ? undefined : json["id"],
    question: json["question"],
  };
}

export function PharmacyInterviewQuestionnaireToJSON(object?: PharmacyInterviewQuestionnaire | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer: value.answer,
    id: value.id,
    question: value.question,
  };
}
