/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutOrderPatientRequestBodyTypename = "PutOrderPatientRequestBody" as const;

/**
 *
 * @export
 * @interface PutOrderPatientRequestBody
 */
export interface PutOrderPatientRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutOrderPatientRequestBody
   */
  requestToken: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutOrderPatientRequestBody
   */
  readonly __typename?: string;
}

export function PutOrderPatientRequestBodyFromJSON(json: any): PutOrderPatientRequestBody {
  return PutOrderPatientRequestBodyFromJSONTyped(json, false);
}

export function PutOrderPatientRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutOrderPatientRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutOrderPatientRequestBodyTypename,

    requestToken: json["request_token"],
  };
}

export function PutOrderPatientRequestBodyToJSON(object?: PutOrderPatientRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    request_token: value.requestToken,
  };
}
