import { ZendeskArticle, ZendeskArticleFromJSON } from "@mgdx/shared/src/models/ZendeskArticle";
import { ZendeskRequest, ZendeskRequestToJSON } from "@mgdx/shared/src/models/ZendeskRequest";
import { ZendeskTicket, ZendeskTicketFromJSON } from "@mgdx/shared/src/models/ZendeskTicket";
import { toastError } from "@mgdx/ui/components/Toast";
import { logger } from "@mgdx-libs/logger";
import { createRequestAction } from "@mgdx-libs/redux-requests";

/**
 * @see https://developer.zendesk.com/api-reference/help_center/help-center-api/articles/#list-articles
 *
 */
export const findZendeskArticle = createRequestAction<{ labelName: string }, Response, ZendeskArticle[]>(
  "requests/zendesk/findZendeskArticle",
  ({ labelName }, meta) => ({
    request: {
      promise: fetch(`${process.env.ZENDESK_URL}/api/v2/help_center/ja/articles.json?label_names=${labelName}`),
    },
    meta: {
      ...meta,
      requestKey: labelName,
      onSuccess: async (response, action, store) => {
        try {
          const json = await response.data.json();
          if (!json.articles) return { ...response, data: [] };
          return { ...response, data: (json.articles as Array<any>).map(ZendeskArticleFromJSON) };
        } catch {
          return { ...response, data: [] };
        }
      },
      onError: (error, action, store): Error => {
        logger.debug("requests/zendesk/findZendeskArticle %o", { error });
        return error;
      },
    },
  })
);

/**
 * @see https://developer.zendesk.com/api-reference/ticketing/tickets/ticket-requests/#creating-follow-up-requests
 */
export const postZendeskRequest = createRequestAction<ZendeskRequest, Response, ZendeskTicket>(
  "requests/zendesk/postZendeskRequest",
  (request, meta) => ({
    request: {
      promise: fetch(`https://mgdx.zendesk.com/api/v2/requests.json`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(ZendeskRequestToJSON(request)),
      }),
    },
    meta: {
      ...meta,
      onSuccess: async (response, action, store) => {
        try {
          const json = await response.data.json();
          return { ...response, data: ZendeskTicketFromJSON(json.request) };
        } catch {
          return { ...response, data: undefined };
        }
      },
      onError: (error, action, store): Error => {
        logger.debug("requests/zendesk/postZendeskRequest %o", { error });

        toastError("送信に失敗しました。しばらくしてからお試しください。");

        return error;
      },
    },
  })
);
