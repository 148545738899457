import "moment/locale/ja";

import { extendMoment } from "moment-range";
import originalMoment from "moment-timezone";

export { DateRange } from "moment-range";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const moment = extendMoment(originalMoment as any);

moment.tz.setDefault("Asia/Tokyo");
moment.locale("ja");

export type Moment = originalMoment.Moment;

export type MaybeMoment = Moment | Date | string;

export default moment;
