/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyTemporaryClosedDateTypename = "PharmacyTemporaryClosedDate" as const;

/**
 *
 * @export
 * @interface PharmacyTemporaryClosedDate
 */
export interface PharmacyTemporaryClosedDate {
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyTemporaryClosedDate
   */
  counselingReceptionClosedHours?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PharmacyTemporaryClosedDate
   */
  counselingReceptionClosedType: PharmacyTemporaryClosedDateCounselingReceptionClosedTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyTemporaryClosedDate
   */
  date: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyTemporaryClosedDate
   */
  dispensingReceptionClosedHours?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PharmacyTemporaryClosedDate
   */
  dispensingReceptionClosedType: PharmacyTemporaryClosedDateDispensingReceptionClosedTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyTemporaryClosedDate
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyTemporaryClosedDateCounselingReceptionClosedTypeEnum {
  All = "all",
  Part = "part",
  None = "none",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyTemporaryClosedDateDispensingReceptionClosedTypeEnum {
  All = "all",
  Part = "part",
  None = "none",
}

export function PharmacyTemporaryClosedDateFromJSON(json: any): PharmacyTemporaryClosedDate {
  return PharmacyTemporaryClosedDateFromJSONTyped(json, false);
}

export function PharmacyTemporaryClosedDateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyTemporaryClosedDate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyTemporaryClosedDateTypename,

    counselingReceptionClosedHours: !exists(json, "counseling_reception_closed_hours")
      ? undefined
      : json["counseling_reception_closed_hours"],
    counselingReceptionClosedType: json["counseling_reception_closed_type"],
    date: json["date"],
    dispensingReceptionClosedHours: !exists(json, "dispensing_reception_closed_hours")
      ? undefined
      : json["dispensing_reception_closed_hours"],
    dispensingReceptionClosedType: json["dispensing_reception_closed_type"],
  };
}

export function PharmacyTemporaryClosedDateToJSON(object?: PharmacyTemporaryClosedDate | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling_reception_closed_hours: value.counselingReceptionClosedHours,
    counseling_reception_closed_type: value.counselingReceptionClosedType,
    date: value.date,
    dispensing_reception_closed_hours: value.dispensingReceptionClosedHours,
    dispensing_reception_closed_type: value.dispensingReceptionClosedType,
  };
}
