/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientMedicineNotebookSharePatientTypename = "PatientMedicineNotebookSharePatient" as const;

/**
 *
 * @export
 * @interface PatientMedicineNotebookSharePatient
 */
export interface PatientMedicineNotebookSharePatient {
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebookSharePatient
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebookSharePatient
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebookSharePatient
   */
  gender?: PatientMedicineNotebookSharePatientGenderEnum;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineNotebookSharePatient
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebookSharePatient
   */
  lastName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineNotebookSharePatient
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMedicineNotebookSharePatientGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PatientMedicineNotebookSharePatientFromJSON(json: any): PatientMedicineNotebookSharePatient {
  return PatientMedicineNotebookSharePatientFromJSONTyped(json, false);
}

export function PatientMedicineNotebookSharePatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicineNotebookSharePatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineNotebookSharePatientTypename,

    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    firstName: json["first_name"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    id: json["id"],
    lastName: json["last_name"],
  };
}

export function PatientMedicineNotebookSharePatientToJSON(object?: PatientMedicineNotebookSharePatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    first_name: value.firstName,
    gender: value.gender,
    id: value.id,
    last_name: value.lastName,
  };
}
