import { QolAccountAnswer, QolAccountAnswerFromJSON } from "./QolAccountAnswer";
export const QolAccountQuestionTypename = "QolAccountQuestion" as const;

/**
 *
 * @export
 * @interface QolAccountQuestion
 * @see QolAccountQuestion
 */
export interface QolAccountQuestion {
  /**
   *
   * @type {string}
   * @memberof QolAccountQuestion
   */
  question: string;
  /**
   *
   * @type {number}
   * @memberof QolAccountQuestion
   */
  questionId: number;
  /**
   *
   * @type {Array<QolAccountAnswer>}
   * @memberof QolAccountQuestion
   */
  answers: Array<QolAccountAnswer>;
  /**
   * Resource type name
   * @type {string}
   * @memberof QolAccountQuestion
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolAccountQuestionFromJSON(json: any): QolAccountQuestion {
  return QolAccountQuestionFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function QolAccountQuestionFromJSONTyped(json: any, ignoreDiscriminator: boolean): QolAccountQuestion {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: QolAccountQuestionTypename,
    question: json["question"],
    questionId: json["questionId"],
    answers: (json["answers"] as Array<any>).map(QolAccountAnswerFromJSON),
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function QolAccountQuestionToJSON(object?: QolAccountQuestion | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    question: value.question,
    questionId: value.questionId,
    answers: value.answers,
  };
}
