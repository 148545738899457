/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorBillingTypename = "PharmacyCounselorBilling" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorBilling
 */
export interface PharmacyCounselorBilling {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBilling
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBilling
   */
  deliveryCost?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyCounselorBilling
   */
  imagePaths?: Array<string>;
  /**
   * exists only if requested patient is non YQB member
   * @type {Array<string>}
   * @memberof PharmacyCounselorBilling
   */
  images?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBilling
   */
  insuranceMedicalExpense?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBilling
   */
  ownExpense?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBilling
   */
  paymentId?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBilling
   */
  paymentMethod?: PharmacyCounselorBillingPaymentMethodEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBilling
   */
  status: PharmacyCounselorBillingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBilling
   */
  statusDatail?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorBilling
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorBillingPaymentMethodEnum {
  Counter = "counter",
  Online = "online",
  MedicalSupport = "medical_support",
}
/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorBillingStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Started = "started",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}

export function PharmacyCounselorBillingFromJSON(json: any): PharmacyCounselorBilling {
  return PharmacyCounselorBillingFromJSONTyped(json, false);
}

export function PharmacyCounselorBillingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorBilling {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorBillingTypename,

    amount: !exists(json, "amount") ? undefined : json["amount"],
    deliveryCost: !exists(json, "delivery_cost") ? undefined : json["delivery_cost"],
    imagePaths: !exists(json, "image_paths") ? undefined : json["image_paths"],
    images: !exists(json, "images") ? undefined : json["images"],
    insuranceMedicalExpense: !exists(json, "insurance_medical_expense") ? undefined : json["insurance_medical_expense"],
    ownExpense: !exists(json, "own_expense") ? undefined : json["own_expense"],
    paymentId: !exists(json, "payment_id") ? undefined : json["payment_id"],
    paymentMethod: !exists(json, "payment_method") ? undefined : json["payment_method"],
    status: json["status"],
    statusDatail: !exists(json, "status_datail") ? undefined : json["status_datail"],
  };
}

export function PharmacyCounselorBillingToJSON(object?: PharmacyCounselorBilling | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    amount: value.amount,
    delivery_cost: value.deliveryCost,
    image_paths: value.imagePaths,
    images: value.images,
    insurance_medical_expense: value.insuranceMedicalExpense,
    own_expense: value.ownExpense,
    payment_id: value.paymentId,
    payment_method: value.paymentMethod,
    status: value.status,
    status_datail: value.statusDatail,
  };
}
