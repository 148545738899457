/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorQuestionnaire,
  PharmacyCounselorQuestionnaireFromJSON,
  PharmacyCounselorQuestionnaireFromJSONTyped,
  PharmacyCounselorQuestionnaireToJSON,
} from "./";

export const PharmacyCounselorFollowUpUpdatableTypename = "PharmacyCounselorFollowUpUpdatable" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorFollowUpUpdatable
 */
export interface PharmacyCounselorFollowUpUpdatable {
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorFollowUpUpdatable
   */
  isNotifyAnswered?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorFollowUpUpdatable
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorFollowUpUpdatable
   */
  notificationPatternId?: number;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorFollowUpUpdatable
   */
  notificationTime?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorFollowUpUpdatable
   */
  questionnairePatternId?: number;
  /**
   *
   * @type {Array<PharmacyCounselorQuestionnaire>}
   * @memberof PharmacyCounselorFollowUpUpdatable
   */
  questionnaires?: Array<PharmacyCounselorQuestionnaire>;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorFollowUpUpdatable
   */
  status?: PharmacyCounselorFollowUpUpdatableStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorFollowUpUpdatable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorFollowUpUpdatableStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Notified = "notified",
  Answered = "answered",
  AnswerConfirmed = "answer_confirmed",
  Completed = "completed",
  Canceled = "canceled",
}

export function PharmacyCounselorFollowUpUpdatableFromJSON(json: any): PharmacyCounselorFollowUpUpdatable {
  return PharmacyCounselorFollowUpUpdatableFromJSONTyped(json, false);
}

export function PharmacyCounselorFollowUpUpdatableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorFollowUpUpdatable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorFollowUpUpdatableTypename,

    isNotifyAnswered: !exists(json, "is_notify_answered") ? undefined : json["is_notify_answered"],
    message: !exists(json, "message") ? undefined : json["message"],
    notificationPatternId: !exists(json, "notification_pattern_id") ? undefined : json["notification_pattern_id"],
    notificationTime:
      !exists(json, "notification_time") ||
      (typeof json["notification_time"] === "string" && json["notification_time"].substring(0, 4) === "0001")
        ? undefined
        : json["notification_time"],
    questionnairePatternId: !exists(json, "questionnaire_pattern_id") ? undefined : json["questionnaire_pattern_id"],
    questionnaires: !exists(json, "questionnaires")
      ? undefined
      : (json["questionnaires"] as Array<any>).map(PharmacyCounselorQuestionnaireFromJSON),
    status: !exists(json, "status") ? undefined : json["status"],
  };
}

export function PharmacyCounselorFollowUpUpdatableToJSON(object?: PharmacyCounselorFollowUpUpdatable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    is_notify_answered: value.isNotifyAnswered,
    message: value.message,
    notification_pattern_id: value.notificationPatternId,
    notification_time:
      value.notificationTime === undefined ? undefined : moment(value.notificationTime).tz("Asia/Tokyo").format(),
    questionnaire_pattern_id: value.questionnairePatternId,
    questionnaires:
      value.questionnaires === undefined
        ? undefined
        : (value.questionnaires as Array<any>).map(PharmacyCounselorQuestionnaireToJSON),
    status: value.status,
  };
}
