import { createAction, createReducer } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export type VideoCallingState = {
  isOpenSubWindow: boolean;
  currentCounselingId: string;
  isCheckedVideoCalling: boolean;
  isMoveSubWindow: boolean;
};

const initialState: VideoCallingState = {
  isOpenSubWindow: false,
  currentCounselingId: "",
  isCheckedVideoCalling: false,
  isMoveSubWindow: false,
};

export const openVideoCallingSubWindow = createAction("@@video-calling/openVideoCallingSubWindow");
export const closeVideoCallingSubWindow = createAction("@@video-calling/closeVideoCallingSubWindow");
export const setVideoCallingCounselingId = createAction<string>("@@video-calling/setVideoCallingCounselingId");
export const clearVideoCallingCounselingId = createAction("@@video-calling/clearVideoCallingCounselingId");
export const setIsCheckedVideoCalling = createAction<boolean>("@@video-calling/setIsCheckedVideoCalling");
export const moveCallingSubWindow = createAction("@video-calling/moveCallingSubWindow");
export const clearVideoCallingState = createAction("@@video-calling/clearState");

export const videoCallingReducer = createReducer<VideoCallingState>(initialState, {
  [openVideoCallingSubWindow.type]: (state) => ({
    ...state,
    isOpenSubWindow: true,
  }),
  [closeVideoCallingSubWindow.type]: (state) => ({
    ...state,
    isOpenSubWindow: false,
    isMoveSubWindow: false,
  }),
  [setVideoCallingCounselingId.type]: (state, action) => ({
    ...state,
    currentCounselingId: action.payload,
  }),
  [clearVideoCallingCounselingId.type]: (state) => ({
    ...state,
    currentCounselingId: "",
  }),
  [setIsCheckedVideoCalling.type]: (state, action) => ({
    ...state,
    isCheckedVideoCalling: action.payload,
  }),
  [moveCallingSubWindow.type]: (state) => ({
    ...state,
    isMoveSubWindow: true,
  }),
  [clearVideoCallingState.type]: () => initialState,
});

export const useVideoCalling = (): VideoCallingState =>
  useSelector<{ videoCalling: VideoCallingState }, VideoCallingState>(({ videoCalling }) => videoCalling);
