/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatchLinkedPatientEmailRequestBodyTypename = "PatchLinkedPatientEmailRequestBody" as const;

/**
 *
 * @export
 * @interface PatchLinkedPatientEmailRequestBody
 */
export interface PatchLinkedPatientEmailRequestBody {
  /**
   *
   * @type {string}
   * @memberof PatchLinkedPatientEmailRequestBody
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PatchLinkedPatientEmailRequestBody
   */
  password: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchLinkedPatientEmailRequestBody
   */
  readonly __typename?: string;
}

export function PatchLinkedPatientEmailRequestBodyFromJSON(json: any): PatchLinkedPatientEmailRequestBody {
  return PatchLinkedPatientEmailRequestBodyFromJSONTyped(json, false);
}

export function PatchLinkedPatientEmailRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchLinkedPatientEmailRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchLinkedPatientEmailRequestBodyTypename,

    email: json["email"],
    password: json["password"],
  };
}

export function PatchLinkedPatientEmailRequestBodyToJSON(object?: PatchLinkedPatientEmailRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    email: value.email,
    password: value.password,
  };
}
