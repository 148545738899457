/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorInterviewPatientTypename = "PharmacyCounselorInterviewPatient" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorInterviewPatient
 */
export interface PharmacyCounselorInterviewPatient {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorInterviewPatient
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  firstName?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorInterviewPatient
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  postalCode?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  tel?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  wantsGeneric?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorInterviewPatient
   */
  readonly __typename?: string;
}

export function PharmacyCounselorInterviewPatientFromJSON(json: any): PharmacyCounselorInterviewPatient {
  return PharmacyCounselorInterviewPatientFromJSONTyped(json, false);
}

export function PharmacyCounselorInterviewPatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorInterviewPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorInterviewPatientTypename,

    addressLine1: !exists(json, "address_line1") ? undefined : json["address_line1"],
    addressLine2: !exists(json, "address_line2") ? undefined : json["address_line2"],
    email: !exists(json, "email") ? undefined : json["email"],
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    id: !exists(json, "id") ? undefined : json["id"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
    wantsGeneric: !exists(json, "wants_generic") ? undefined : json["wants_generic"],
  };
}

export function PharmacyCounselorInterviewPatientToJSON(object?: PharmacyCounselorInterviewPatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    email: value.email,
    family_account_id: value.familyAccountId,
    first_name: value.firstName,
    id: value.id,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    postal_code: value.postalCode,
    tel: value.tel,
    wants_generic: value.wantsGeneric,
  };
}
