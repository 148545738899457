/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorBillingReportTypename = "PharmacyCounselorBillingReport" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorBillingReport
 */
export interface PharmacyCounselorBillingReport {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBillingReport
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBillingReport
   */
  deliveryCost?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  insuranceCoverage: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBillingReport
   */
  insuranceMedicalExpense?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  kana: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  managementNo: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBillingReport
   */
  ownExpense?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBillingReport
   */
  pharmacyGroupId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  pharmacyGroupName?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorBillingReport
   */
  pharmacyId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  pharmacyName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  time: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  type: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorBillingReport
   */
  readonly __typename?: string;
}

export function PharmacyCounselorBillingReportFromJSON(json: any): PharmacyCounselorBillingReport {
  return PharmacyCounselorBillingReportFromJSONTyped(json, false);
}

export function PharmacyCounselorBillingReportFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorBillingReport {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorBillingReportTypename,

    amount: !exists(json, "amount") ? undefined : json["amount"],
    deliveryCost: !exists(json, "delivery_cost") ? undefined : json["delivery_cost"],
    insuranceCoverage: json["insurance_coverage"],
    insuranceMedicalExpense: !exists(json, "insurance_medical_expense") ? undefined : json["insurance_medical_expense"],
    kana: json["kana"],
    managementNo: json["management_no"],
    name: json["name"],
    ownExpense: !exists(json, "own_expense") ? undefined : json["own_expense"],
    pharmacyGroupId: !exists(json, "pharmacy_group_id") ? undefined : json["pharmacy_group_id"],
    pharmacyGroupName: !exists(json, "pharmacy_group_name") ? undefined : json["pharmacy_group_name"],
    pharmacyId: json["pharmacy_id"],
    pharmacyName: json["pharmacy_name"],
    status: json["status"],
    time: json["time"],
    type: json["type"],
  };
}

export function PharmacyCounselorBillingReportToJSON(object?: PharmacyCounselorBillingReport | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    amount: value.amount,
    delivery_cost: value.deliveryCost,
    insurance_coverage: value.insuranceCoverage,
    insurance_medical_expense: value.insuranceMedicalExpense,
    kana: value.kana,
    management_no: value.managementNo,
    name: value.name,
    own_expense: value.ownExpense,
    pharmacy_group_id: value.pharmacyGroupId,
    pharmacy_group_name: value.pharmacyGroupName,
    pharmacy_id: value.pharmacyId,
    pharmacy_name: value.pharmacyName,
    status: value.status,
    time: value.time,
    type: value.type,
  };
}
