/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutCounselingNoteRequestBodyTypename = "PutCounselingNoteRequestBody" as const;

/**
 *
 * @export
 * @interface PutCounselingNoteRequestBody
 */
export interface PutCounselingNoteRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutCounselingNoteRequestBody
   */
  counselingNote?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutCounselingNoteRequestBody
   */
  readonly __typename?: string;
}

export function PutCounselingNoteRequestBodyFromJSON(json: any): PutCounselingNoteRequestBody {
  return PutCounselingNoteRequestBodyFromJSONTyped(json, false);
}

export function PutCounselingNoteRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutCounselingNoteRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutCounselingNoteRequestBodyTypename,

    counselingNote: !exists(json, "counseling_note") ? undefined : json["counseling_note"],
  };
}

export function PutCounselingNoteRequestBodyToJSON(object?: PutCounselingNoteRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling_note: value.counselingNote,
  };
}
