import React from "react";
import { FallbackProps } from "react-error-boundary";

export type ErrorFallbackProps = FallbackProps;

export const ErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => (
  <div role="alert">
    <p>Error Message</p>
    <pre>{error.message}</pre>
    <button onClick={resetErrorBoundary}>Try again</button>
  </div>
);
