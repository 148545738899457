/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyCrowdStatusTypename = "PharmacyPharmacyCrowdStatus" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyCrowdStatus
 */
export interface PharmacyPharmacyCrowdStatus {
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacyCrowdStatus
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacyCrowdStatus
   */
  unitType: PharmacyPharmacyCrowdStatusUnitTypeEnum;
  /**
   *
   * @type {Date}
   * @memberof PharmacyPharmacyCrowdStatus
   */
  updatedTime?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyCrowdStatus
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyPharmacyCrowdStatusUnitTypeEnum {
  People = "people",
  Minute = "minute",
}

export function PharmacyPharmacyCrowdStatusFromJSON(json: any): PharmacyPharmacyCrowdStatus {
  return PharmacyPharmacyCrowdStatusFromJSONTyped(json, false);
}

export function PharmacyPharmacyCrowdStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPharmacyCrowdStatus {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyCrowdStatusTypename,

    quantity: !exists(json, "quantity") ? undefined : json["quantity"],
    unitType: json["unit_type"],
    updatedTime:
      !exists(json, "updated_time") ||
      (typeof json["updated_time"] === "string" && json["updated_time"].substring(0, 4) === "0001")
        ? undefined
        : json["updated_time"],
  };
}

export function PharmacyPharmacyCrowdStatusToJSON(object?: PharmacyPharmacyCrowdStatus | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    quantity: value.quantity,
    unit_type: value.unitType,
    updated_time: value.updatedTime === undefined ? undefined : moment(value.updatedTime).tz("Asia/Tokyo").format(),
  };
}
