import clsx from "clsx";
import React from "react";

import Badge from "../Badge";
import * as styles from "./OvalButton.module.css";

export type OvalButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type" | "children"> & {
  variant: `${Variant}`;
  type?: `${ButtonType}`;
  size?: `${Size}`;
  variableClassName?: string;
  badgeLabel?: string;
} & (
    | {
        children: React.ReactElement;
        ["aria-label"]: string;
      }
    | {
        children: string | number;
      }
  );

export enum Variant {
  Plain = "plain",
  KeyBasic = "key-basic",
  KeyWeak = "key-weak",
  VariableBasic = "variable-basic",
  VariableWeak = "variable-weak",
}

export enum ButtonType {
  Normal = "normal",
  Submit = "submit",
}

export enum Size {
  S = "s",
  M = "m",
  XXM = "xxm",
}

/**
 * @description string,number以外の型(SVGなどを想定)がchildrenに渡される場合は、aria-labelを必ず指定する
 */
export const OvalButton: React.FC<OvalButtonProps> = ({
  variant,
  type = ButtonType.Normal,
  size = Size.M,
  children,
  variableClassName,
  className,
  badgeLabel,
  ...props
}) => (
  <div
    className={clsx(
      styles.container,
      variant === Variant.Plain && styles.plain,
      variant === Variant.KeyBasic && styles.keyBasic,
      variant === Variant.KeyWeak && styles.keyWeak,
      variant === Variant.VariableBasic && styles.variableBasic,
      variant === Variant.VariableWeak && styles.variableWeak,
      (variant === Variant.VariableBasic || variant === Variant.VariableWeak) && variableClassName,
      props.disabled && styles.disabled,
      size === Size.S && styles.sizeS,
      size === Size.M && styles.sizeM,
      size === Size.XXM && styles.sizeXXM,
      className
    )}
  >
    <button
      type={type === ButtonType.Submit ? "submit" : "button"}
      className={styles.button}
      {...props}
      aria-label={typeof children === "string" || typeof children === "number" ? undefined : props["aria-label"]}
    >
      {children}
      {badgeLabel && (
        <Badge className={styles.badge} size="s">
          {badgeLabel}
        </Badge>
      )}
    </button>
  </div>
);
