/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPatchPharmacyListImageTypename = "PharmacyPatchPharmacyListImage" as const;

/**
 *
 * @export
 * @interface PharmacyPatchPharmacyListImage
 */
export interface PharmacyPatchPharmacyListImage {
  /**
   *
   * @type {string}
   * @memberof PharmacyPatchPharmacyListImage
   */
  data?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPatchPharmacyListImage
   */
  detail?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPatchPharmacyListImage
   */
  id: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPatchPharmacyListImage
   */
  readonly __typename?: string;
}

export function PharmacyPatchPharmacyListImageFromJSON(json: any): PharmacyPatchPharmacyListImage {
  return PharmacyPatchPharmacyListImageFromJSONTyped(json, false);
}

export function PharmacyPatchPharmacyListImageFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPatchPharmacyListImage {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPatchPharmacyListImageTypename,

    data: !exists(json, "data") ? undefined : json["data"],
    detail: !exists(json, "detail") ? undefined : json["detail"],
    id: json["id"],
  };
}

export function PharmacyPatchPharmacyListImageToJSON(object?: PharmacyPatchPharmacyListImage | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    data: value.data,
    detail: value.detail,
    id: value.id,
  };
}
