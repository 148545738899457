/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorAnswer,
  PharmacyCounselorAnswerFromJSON,
  PharmacyCounselorAnswerFromJSONTyped,
  PharmacyCounselorAnswerToJSON,
} from "./";

export const PutFollowUpAnswerRequestBodyTypename = "PutFollowUpAnswerRequestBody" as const;

/**
 *
 * @export
 * @interface PutFollowUpAnswerRequestBody
 */
export interface PutFollowUpAnswerRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutFollowUpAnswerRequestBody
   */
  answerToken?: string;
  /**
   *
   * @type {Array<PharmacyCounselorAnswer>}
   * @memberof PutFollowUpAnswerRequestBody
   */
  answers: Array<PharmacyCounselorAnswer>;
  /**
   *
   * @type {string}
   * @memberof PutFollowUpAnswerRequestBody
   */
  requestToken?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutFollowUpAnswerRequestBody
   */
  readonly __typename?: string;
}

export function PutFollowUpAnswerRequestBodyFromJSON(json: any): PutFollowUpAnswerRequestBody {
  return PutFollowUpAnswerRequestBodyFromJSONTyped(json, false);
}

export function PutFollowUpAnswerRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutFollowUpAnswerRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutFollowUpAnswerRequestBodyTypename,

    answerToken: !exists(json, "answer_token") ? undefined : json["answer_token"],
    answers: (json["answers"] as Array<any>).map(PharmacyCounselorAnswerFromJSON),
    requestToken: !exists(json, "request_token") ? undefined : json["request_token"],
  };
}

export function PutFollowUpAnswerRequestBodyToJSON(object?: PutFollowUpAnswerRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer_token: value.answerToken,
    answers: (value.answers as Array<any>).map(PharmacyCounselorAnswerToJSON),
    request_token: value.requestToken,
  };
}
