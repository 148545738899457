/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicDepartment,
  ClinicDepartmentFromJSON,
  ClinicDepartmentFromJSONTyped,
  ClinicDepartmentToJSON,
  ClinicMeritTags,
  ClinicMeritTagsFromJSON,
  ClinicMeritTagsFromJSONTyped,
  ClinicMeritTagsToJSON,
  ClinicSNS,
  ClinicSNSFromJSON,
  ClinicSNSFromJSONTyped,
  ClinicSNSToJSON,
  ClinicTokushoho,
  ClinicTokushohoFromJSON,
  ClinicTokushohoFromJSONTyped,
  ClinicTokushohoToJSON,
} from "./";

export const ClinicClinicTypename = "ClinicClinic" as const;

/**
 *
 * @export
 * @interface ClinicClinic
 */
export interface ClinicClinic {
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  address: string;
  /**
   *
   * @type {Array<number>}
   * @memberof ClinicClinic
   */
  alliancePharmacyIds: Array<number>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinic
   */
  businessHours: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinic
   */
  clinicalDepartments: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinic
   */
  closedDays: Array<string>;
  /**
   *
   * @type {Array<ClinicDepartment>}
   * @memberof ClinicClinic
   */
  departments?: Array<ClinicDepartment>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  fax: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  homepageUrl?: string;
  /**
   *
   * @type {number}
   * @memberof ClinicClinic
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  imageUrl: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  institutionCode: string;
  /**
   *
   * @type {number}
   * @memberof ClinicClinic
   */
  latitude?: number;
  /**
   *
   * @type {number}
   * @memberof ClinicClinic
   */
  longitude?: number;
  /**
   *
   * @type {ClinicMeritTags}
   * @memberof ClinicClinic
   */
  meritTag?: ClinicMeritTags;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  name: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinic
   */
  nearestStations: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  note: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinic
   */
  onlinePaymentMethods: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ClinicClinic
   */
  paymentMethods: Array<string>;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  recommendation?: string;
  /**
   *
   * @type {boolean}
   * @memberof ClinicClinic
   */
  screeningStatus?: boolean;
  /**
   *
   * @type {ClinicSNS}
   * @memberof ClinicClinic
   */
  sns: ClinicSNS;
  /**
   *
   * @type {number}
   * @memberof ClinicClinic
   */
  talkroomExpireDays: number;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  tel: string;
  /**
   *
   * @type {ClinicTokushoho}
   * @memberof ClinicClinic
   */
  tokushoho?: ClinicTokushoho;
  /**
   *
   * @type {string}
   * @memberof ClinicClinic
   */
  visibility?: ClinicClinicVisibilityEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicClinic
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ClinicClinicVisibilityEnum {
  Public = "public",
  Private = "private",
  Limited = "limited",
}

export function ClinicClinicFromJSON(json: any): ClinicClinic {
  return ClinicClinicFromJSONTyped(json, false);
}

export function ClinicClinicFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicClinic {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicClinicTypename,

    address: json["address"],
    alliancePharmacyIds: json["alliance_pharmacy_ids"],
    businessHours: json["business_hours"],
    clinicalDepartments: json["clinical_departments"],
    closedDays: json["closed_days"],
    departments: !exists(json, "departments")
      ? undefined
      : (json["departments"] as Array<any>).map(ClinicDepartmentFromJSON),
    email: json["email"],
    fax: json["fax"],
    homepageUrl: !exists(json, "homepage_url") ? undefined : json["homepage_url"],
    id: json["id"],
    imageUrl: json["image_url"],
    institutionCode: json["institution_code"],
    latitude: !exists(json, "latitude") ? undefined : json["latitude"] == "" ? undefined : json["latitude"],
    longitude: !exists(json, "longitude") ? undefined : json["longitude"] == "" ? undefined : json["longitude"],
    meritTag: !exists(json, "merit_tag") ? undefined : ClinicMeritTagsFromJSON(json["merit_tag"]),
    name: json["name"],
    nearestStations: json["nearest_stations"],
    note: json["note"],
    onlinePaymentMethods: json["online_payment_methods"],
    paymentMethods: json["payment_methods"],
    postalCode: json["postal_code"],
    recommendation: !exists(json, "recommendation") ? undefined : json["recommendation"],
    screeningStatus: !exists(json, "screening_status") ? undefined : json["screening_status"],
    sns: ClinicSNSFromJSON(json["sns"]),
    talkroomExpireDays: json["talkroom_expire_days"],
    tel: json["tel"],
    tokushoho: !exists(json, "tokushoho") ? undefined : ClinicTokushohoFromJSON(json["tokushoho"]),
    visibility: !exists(json, "visibility") ? undefined : json["visibility"],
  };
}

export function ClinicClinicToJSON(object?: ClinicClinic | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address: value.address,
    alliance_pharmacy_ids: value.alliancePharmacyIds,
    business_hours: value.businessHours,
    clinical_departments: value.clinicalDepartments,
    closed_days: value.closedDays,
    departments:
      value.departments === undefined ? undefined : (value.departments as Array<any>).map(ClinicDepartmentToJSON),
    email: value.email,
    fax: value.fax,
    homepage_url: value.homepageUrl,
    id: value.id,
    image_url: value.imageUrl,
    institution_code: value.institutionCode,
    latitude: (value.latitude as unknown as string) == "" ? undefined : value.latitude,
    longitude: (value.longitude as unknown as string) == "" ? undefined : value.longitude,
    merit_tag: ClinicMeritTagsToJSON(value.meritTag),
    name: value.name,
    nearest_stations: value.nearestStations,
    note: value.note,
    online_payment_methods: value.onlinePaymentMethods,
    payment_methods: value.paymentMethods,
    postal_code: value.postalCode,
    recommendation: value.recommendation,
    screening_status: value.screeningStatus,
    sns: ClinicSNSToJSON(value.sns),
    talkroom_expire_days: value.talkroomExpireDays,
    tel: value.tel,
    tokushoho: ClinicTokushohoToJSON(value.tokushoho),
    visibility: value.visibility,
  };
}
