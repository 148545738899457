import { clearSelectedTalkRoomId, closeTalk } from "@mgdx/talk/ducks";
import { useTalk, useTalkRoomDocument, useTalkRoomTitle } from "@mgdx/talk/hooks";
import { SenderTypeEnum } from "@mgdx/talk/models";
import DialogLayout from "@mgdx/ui/components/DialogLayout";
import FullscreenDialog from "@mgdx/ui/components/FullscreenDialog";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";

import TalkMessageListContent from "./TalkMessageListContent";

const TalkMessageListDialog = () => {
  const dispatch = useDispatch();
  const handleRequestRefresh = useCallback(async () => {
    window.location.reload();
  }, []);

  const handleRequestBackOrClose = useCallback(async () => {
    await dispatch(clearSelectedTalkRoomId());
    await dispatch(closeTalk());
  }, [dispatch]);

  const { isEnabledTalk, isOpenTalk, selectedTalkRoomId } = useTalk();

  const { room } = useTalkRoomDocument({
    roomId: selectedTalkRoomId,
  });

  const title = useTalkRoomTitle({
    room,
    talkSenderType: SenderTypeEnum.Patient,
  });

  return (
    <FullscreenDialog isOpen={isEnabledTalk && isOpenTalk}>
      <DialogLayout
        title={title}
        onRequestClose={handleRequestBackOrClose}
        onRequestBack={handleRequestBackOrClose}
        onRequestRefresh={handleRequestRefresh}
      >
        {selectedTalkRoomId && <TalkMessageListContent roomId={selectedTalkRoomId} />}
      </DialogLayout>
    </FullscreenDialog>
  );
};

export default TalkMessageListDialog;
