/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorAnswerTypename = "PharmacyCounselorAnswer" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorAnswer
 */
export interface PharmacyCounselorAnswer {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorAnswer
   */
  freeEntry?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorAnswer
   */
  optionId?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorAnswer
   */
  questionnaireId?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorAnswer
   */
  readonly __typename?: string;
}

export function PharmacyCounselorAnswerFromJSON(json: any): PharmacyCounselorAnswer {
  return PharmacyCounselorAnswerFromJSONTyped(json, false);
}

export function PharmacyCounselorAnswerFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyCounselorAnswer {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorAnswerTypename,

    freeEntry: !exists(json, "free_entry") ? undefined : json["free_entry"],
    optionId: !exists(json, "option_id") ? undefined : json["option_id"],
    questionnaireId: !exists(json, "questionnaire_id") ? undefined : json["questionnaire_id"],
  };
}

export function PharmacyCounselorAnswerToJSON(object?: PharmacyCounselorAnswer | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    free_entry: value.freeEntry,
    option_id: value.optionId,
    questionnaire_id: value.questionnaireId,
  };
}
