/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const DeliveryDeliveryTypename = "DeliveryDelivery" as const;

/**
 *
 * @export
 * @interface DeliveryDelivery
 */
export interface DeliveryDelivery {
  /**
   *
   * @type {string}
   * @memberof DeliveryDelivery
   */
  coolType?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDelivery
   */
  deliveryType: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDelivery
   */
  desiredDate: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDelivery
   */
  desiredTimePeriod?: string;
  /**
   *
   * @type {string}
   * @memberof DeliveryDelivery
   */
  invoiceNumber?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryDelivery
   */
  readonly __typename?: string;
}

export function DeliveryDeliveryFromJSON(json: any): DeliveryDelivery {
  return DeliveryDeliveryFromJSONTyped(json, false);
}

export function DeliveryDeliveryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryDelivery {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryDeliveryTypename,

    coolType: !exists(json, "cool_type") ? undefined : json["cool_type"],
    deliveryType: json["delivery_type"],
    desiredDate: json["desired_date"],
    desiredTimePeriod: !exists(json, "desired_time_period") ? undefined : json["desired_time_period"],
    invoiceNumber: !exists(json, "invoice_number") ? undefined : json["invoice_number"],
  };
}

export function DeliveryDeliveryToJSON(object?: DeliveryDelivery | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    cool_type: value.coolType,
    delivery_type: value.deliveryType,
    desired_date: value.desiredDate,
    desired_time_period: value.desiredTimePeriod,
    invoice_number: value.invoiceNumber,
  };
}
