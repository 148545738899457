/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicCounselorRequestTime,
  ClinicCounselorRequestTimeFromJSON,
  ClinicCounselorRequestTimeFromJSONTyped,
  ClinicCounselorRequestTimeToJSON,
} from "./";

export const PostCounselingRequestBodyTypename = "PostCounselingRequestBody" as const;

/**
 *
 * @export
 * @interface PostCounselingRequestBody
 */
export interface PostCounselingRequestBody {
  /**
   *
   * @type {number}
   * @memberof PostCounselingRequestBody
   */
  clinicId: number;
  /**
   *
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  creditCardId: string;
  /**
   * not required for backward compatibility
   * @type {number}
   * @memberof PostCounselingRequestBody
   */
  departmentId?: number;
  /**
   *
   * @type {number}
   * @memberof PostCounselingRequestBody
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  note?: string;
  /**
   *
   * @type {number}
   * @memberof PostCounselingRequestBody
   */
  pastCounselingId?: number;
  /**
   *
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  pharmacyNote?: string;
  /**
   *
   * @type {Array<ClinicCounselorRequestTime>}
   * @memberof PostCounselingRequestBody
   */
  preferredTimes: Array<ClinicCounselorRequestTime>;
  /**
   *
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  visit: PostCounselingRequestBodyVisitEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostCounselingRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PostCounselingRequestBodyVisitEnum {
  First = "first",
  Return = "return",
}

export function PostCounselingRequestBodyFromJSON(json: any): PostCounselingRequestBody {
  return PostCounselingRequestBodyFromJSONTyped(json, false);
}

export function PostCounselingRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostCounselingRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostCounselingRequestBodyTypename,

    clinicId: json["clinic_id"],
    creditCardId: json["credit_card_id"],
    departmentId: !exists(json, "department_id") ? undefined : json["department_id"],
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    note: !exists(json, "note") ? undefined : json["note"],
    pastCounselingId: !exists(json, "past_counseling_id") ? undefined : json["past_counseling_id"],
    pharmacyNote: !exists(json, "pharmacy_note") ? undefined : json["pharmacy_note"],
    preferredTimes: (json["preferred_times"] as Array<any>).map(ClinicCounselorRequestTimeFromJSON),
    visit: json["visit"],
  };
}

export function PostCounselingRequestBodyToJSON(object?: PostCounselingRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    clinic_id: value.clinicId,
    credit_card_id: value.creditCardId,
    department_id: value.departmentId,
    family_account_id: value.familyAccountId,
    note: value.note,
    past_counseling_id: value.pastCounselingId,
    pharmacy_note: value.pharmacyNote,
    preferred_times: (value.preferredTimes as Array<any>).map(ClinicCounselorRequestTimeToJSON),
    visit: value.visit,
  };
}
