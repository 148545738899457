/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientAllPatientsFamilyPharmacistQuestionnaireTypename =
  "PatientAllPatientsFamilyPharmacistQuestionnaire" as const;

/**
 *
 * @export
 * @interface PatientAllPatientsFamilyPharmacistQuestionnaire
 */
export interface PatientAllPatientsFamilyPharmacistQuestionnaire {
  /**
   *
   * @type {string}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  answer: PatientAllPatientsFamilyPharmacistQuestionnaireAnswerEnum;
  /**
   *
   * @type {Date}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  answeredTime: string;
  /**
   *
   * @type {string}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  lastName?: string;
  /**
   *
   * @type {number}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  patientId?: number;
  /**
   *
   * @type {number}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  pharmacyId?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaire
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientAllPatientsFamilyPharmacistQuestionnaireAnswerEnum {
  WantingToHear = "wanting_to_hear",
  ActivelyLookingFor = "actively_looking_for",
  AlreadyRegistered = "already_registered",
  Uninteresting = "uninteresting",
}

export function PatientAllPatientsFamilyPharmacistQuestionnaireFromJSON(
  json: any
): PatientAllPatientsFamilyPharmacistQuestionnaire {
  return PatientAllPatientsFamilyPharmacistQuestionnaireFromJSONTyped(json, false);
}

export function PatientAllPatientsFamilyPharmacistQuestionnaireFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientAllPatientsFamilyPharmacistQuestionnaire {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientAllPatientsFamilyPharmacistQuestionnaireTypename,

    answer: json["answer"],
    answeredTime: json["answered_time"],
    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    patientId: !exists(json, "patient_id") ? undefined : json["patient_id"],
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
  };
}

export function PatientAllPatientsFamilyPharmacistQuestionnaireToJSON(
  object?: PatientAllPatientsFamilyPharmacistQuestionnaire | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    answer: value.answer,
    answered_time: moment(value.answeredTime).tz("Asia/Tokyo").format(),
    date_of_birth: value.dateOfBirth,
    first_name: value.firstName,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    patient_id: value.patientId,
    pharmacy_id: value.pharmacyId,
  };
}
