/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatchCounselingRequestBodyTypename = "PatchCounselingRequestBody" as const;

/**
 *
 * @export
 * @interface PatchCounselingRequestBody
 */
export interface PatchCounselingRequestBody {
  /**
   *
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  dispensingType?: PatchCounselingRequestBodyDispensingTypeEnum;
  /**
   * E-Mail text
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  messageToPatient?: string;
  /**
   *
   * @type {number}
   * @memberof PatchCounselingRequestBody
   */
  pharmacistId?: number;
  /**
   *
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  pharmacyMessageType?: PatchCounselingRequestBodyPharmacyMessageTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatchCounselingRequestBody
   */
  skipNotifyToPatient?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  status?: PatchCounselingRequestBodyStatusEnum;
  /**
   * Only evaluated when the status is canceled.
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  statusDetail?: string;
  /**
   *
   * @type {Date}
   * @memberof PatchCounselingRequestBody
   */
  time?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchCounselingRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchCounselingRequestBodyDispensingTypeEnum {
  Normal = "normal",
  Quick = "quick",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchCounselingRequestBodyPharmacyMessageTypeEnum {
  Preferred = "preferred",
  Busy = "busy",
  LongTimeDispensing = "long_time_dispensing",
  DoubtfulItem = "doubtful_item",
  OutOfStock = "out_of_stock",
  Other = "other",
}
/**
 * @export
 * @enum {string}
 */
export enum PatchCounselingRequestBodyStatusEnum {
  Accepted = "accepted",
  CounselingStarted = "counseling_started",
  CounselingCompleted = "counseling_completed",
  Canceled = "canceled",
}

export function PatchCounselingRequestBodyFromJSON(json: any): PatchCounselingRequestBody {
  return PatchCounselingRequestBodyFromJSONTyped(json, false);
}

export function PatchCounselingRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchCounselingRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchCounselingRequestBodyTypename,

    dispensingType: !exists(json, "dispensing_type") ? undefined : json["dispensing_type"],
    messageToPatient: !exists(json, "message_to_patient") ? undefined : json["message_to_patient"],
    pharmacistId: !exists(json, "pharmacist_id") ? undefined : json["pharmacist_id"],
    pharmacyMessageType: !exists(json, "pharmacy_message_type") ? undefined : json["pharmacy_message_type"],
    skipNotifyToPatient: !exists(json, "skip_notify_to_patient") ? undefined : json["skip_notify_to_patient"],
    status: !exists(json, "status") ? undefined : json["status"],
    statusDetail: !exists(json, "status_detail") ? undefined : json["status_detail"],
    time:
      !exists(json, "time") || (typeof json["time"] === "string" && json["time"].substring(0, 4) === "0001")
        ? undefined
        : json["time"],
  };
}

export function PatchCounselingRequestBodyToJSON(object?: PatchCounselingRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    dispensing_type: value.dispensingType,
    message_to_patient: value.messageToPatient,
    pharmacist_id: value.pharmacistId,
    pharmacy_message_type: value.pharmacyMessageType,
    skip_notify_to_patient: value.skipNotifyToPatient,
    status: value.status,
    status_detail: value.statusDetail,
    time: value.time === undefined ? undefined : moment(value.time).tz("Asia/Tokyo").format(),
  };
}
