import linkedPatientApi from "@mgdx/api/lib/linkedPatientApi";
import { apiErrorHandler, errorHandlerReport } from "@mgdx-libs/error-handler";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import { logger } from "@mgdx-libs/logger";
import { Dispatch } from "redux";

import { setCurrentUserWithDispatch } from "../../ducks/currentUser";

export const createPatientWithLinkingToFirebaseUser = async (dispatch: Dispatch) => {
  const response = await linkedPatientApi
    .postLinkedPatients({
      postLinkedPatientRequestBody: {},
    })
    .catch(async (errorOrResponse) => {
      if (errorOrResponse instanceof Response) {
        const apiError = await apiErrorHandler(errorOrResponse);
        errorHandlerReport(apiError);
        throw apiError;
      } else if (errorOrResponse instanceof Error) {
        await errorHandlerReport(errorOrResponse);
        throw errorOrResponse;
      }
      throw errorOrResponse;
    });

  const patient = response.patient;

  if (!patient) {
    throw Error("会員登録に失敗しました。");
  }

  logger.debug("patient: %o", patient);

  await forceRefreshAccessToken();
  setCurrentUserWithDispatch(dispatch, patient);

  return patient;
};
