/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PharmacyCalendar,
  PharmacyCalendarFromJSON,
  PharmacyCalendarToJSON,
  PutPharmacyCalendarRequestBody,
  PutPharmacyCalendarRequestBodyFromJSON,
  PutPharmacyCalendarRequestBodyToJSON,
} from "../models";

export interface GetPharmacyCalendarRequest {
  pharmacyId: number;
  date: string;
  ignoreTemporary?: boolean;
  splitHours?: boolean;
}

export interface GetPharmacyCalendarsRequest {
  pharmacyId: number;
  from: string;
}

export interface PutPharmacyCalendarRequest {
  pharmacyId: number;
  date: string;
  putPharmacyCalendarRequestBody: PutPharmacyCalendarRequestBody;
}

/**
 *
 */
export class CalendarApi extends runtime.BaseAPI {
  /**
   * Get Pharmacy calendar by date
   */
  async getPharmacyCalendarRaw(
    requestParameters: GetPharmacyCalendarRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PharmacyCalendar>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyCalendar."
      );
    }

    if (requestParameters.date === null || requestParameters.date === undefined) {
      throw new runtime.RequiredError(
        "date",
        "Required parameter requestParameters.date was null or undefined when calling getPharmacyCalendar."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.ignoreTemporary !== undefined) {
      queryParameters["ignore_temporary"] = requestParameters.ignoreTemporary;
    }

    if (requestParameters.splitHours !== undefined) {
      queryParameters["split_hours"] = requestParameters.splitHours;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/calendars/{date}`
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId)))
          .replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PharmacyCalendarFromJSON(jsonValue));
  }

  /**
   * Get Pharmacy calendar by date
   */
  async getPharmacyCalendar(
    requestParameters: GetPharmacyCalendarRequest,
    initOverrides?: RequestInit
  ): Promise<PharmacyCalendar> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyCalendarRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get Pharmacy calendars
   */
  async getPharmacyCalendarsRaw(
    requestParameters: GetPharmacyCalendarsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PharmacyCalendar>>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling getPharmacyCalendars."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling getPharmacyCalendars."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = requestParameters.from;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/calendars`.replace(
          `{${"pharmacy_id"}}`,
          encodeURIComponent(String(requestParameters.pharmacyId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyCalendarFromJSON));
  }

  /**
   * Get Pharmacy calendars
   */
  async getPharmacyCalendars(
    requestParameters: GetPharmacyCalendarsRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PharmacyCalendar>> {
    return promiseRetry(
      (retry) =>
        this.getPharmacyCalendarsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Put Pharmacy calendar
   */
  async putPharmacyCalendarRaw(
    requestParameters: PutPharmacyCalendarRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
      throw new runtime.RequiredError(
        "pharmacyId",
        "Required parameter requestParameters.pharmacyId was null or undefined when calling putPharmacyCalendar."
      );
    }

    if (requestParameters.date === null || requestParameters.date === undefined) {
      throw new runtime.RequiredError(
        "date",
        "Required parameter requestParameters.date was null or undefined when calling putPharmacyCalendar."
      );
    }

    if (
      requestParameters.putPharmacyCalendarRequestBody === null ||
      requestParameters.putPharmacyCalendarRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putPharmacyCalendarRequestBody",
        "Required parameter requestParameters.putPharmacyCalendarRequestBody was null or undefined when calling putPharmacyCalendar."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/pharmacies/{pharmacy_id}/calendars/{date}`
          .replace(`{${"pharmacy_id"}}`, encodeURIComponent(String(requestParameters.pharmacyId)))
          .replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutPharmacyCalendarRequestBodyToJSON(requestParameters.putPharmacyCalendarRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Put Pharmacy calendar
   */
  async putPharmacyCalendar(requestParameters: PutPharmacyCalendarRequest, initOverrides?: RequestInit): Promise<void> {
    await this.putPharmacyCalendarRaw(requestParameters, initOverrides);
  }
}
