/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorCounselingReportBillingTypename = "PharmacyCounselorCounselingReportBilling" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReportBilling
 */
export interface PharmacyCounselorCounselingReportBilling {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReportBilling
   */
  amount?: number;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportBilling
   */
  completedTime?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReportBilling
   */
  deliveryCost?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReportBilling
   */
  insuranceMedicalExpense?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReportBilling
   */
  ownExpense?: number;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorCounselingReportBilling
   */
  registeredTime?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportBilling
   */
  status: PharmacyCounselorCounselingReportBillingStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportBilling
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportBillingStatusEnum {
  Initialized = "initialized",
  Registered = "registered",
  Started = "started",
  Succeeded = "succeeded",
  Failed = "failed",
  Canceled = "canceled",
}

export function PharmacyCounselorCounselingReportBillingFromJSON(json: any): PharmacyCounselorCounselingReportBilling {
  return PharmacyCounselorCounselingReportBillingFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportBillingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReportBilling {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportBillingTypename,

    amount: !exists(json, "amount") ? undefined : json["amount"],
    completedTime:
      !exists(json, "completed_time") ||
      (typeof json["completed_time"] === "string" && json["completed_time"].substring(0, 4) === "0001")
        ? undefined
        : json["completed_time"],
    deliveryCost: !exists(json, "delivery_cost") ? undefined : json["delivery_cost"],
    insuranceMedicalExpense: !exists(json, "insurance_medical_expense") ? undefined : json["insurance_medical_expense"],
    ownExpense: !exists(json, "own_expense") ? undefined : json["own_expense"],
    registeredTime:
      !exists(json, "registered_time") ||
      (typeof json["registered_time"] === "string" && json["registered_time"].substring(0, 4) === "0001")
        ? undefined
        : json["registered_time"],
    status: json["status"],
  };
}

export function PharmacyCounselorCounselingReportBillingToJSON(
  object?: PharmacyCounselorCounselingReportBilling | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    amount: value.amount,
    completed_time:
      value.completedTime === undefined ? undefined : moment(value.completedTime).tz("Asia/Tokyo").format(),
    delivery_cost: value.deliveryCost,
    insurance_medical_expense: value.insuranceMedicalExpense,
    own_expense: value.ownExpense,
    registered_time:
      value.registeredTime === undefined ? undefined : moment(value.registeredTime).tz("Asia/Tokyo").format(),
    status: value.status,
  };
}
