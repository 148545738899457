import clsx from "clsx";
import React from "react";
import ReactModal, { Props as ReactModalProps } from "react-modal";

import * as styles from "./ModalDialog.module.css";

ReactModal.setAppElement("body");

export type Props = React.PropsWithChildren<ReactModalProps> & { variant?: "modal" | "confirm" };

const ModalDialog = ({
  variant = "modal",
  className,
  overlayClassName,
  bodyOpenClassName,
  htmlOpenClassName,
  ...props
}: Props) => (
  <ReactModal
    {...props}
    className={clsx(styles.modal, variant === "confirm" && styles.confirm, className)}
    overlayClassName={clsx(styles.overlay, overlayClassName)}
    bodyOpenClassName={clsx(styles.bodyOpen, bodyOpenClassName)}
    htmlOpenClassName={clsx("modal-dialog-open", htmlOpenClassName)}
    ariaHideApp={false}
  />
);

export default ModalDialog;
