/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorPrescriptionTypename = "PharmacyCounselorPrescription" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorPrescription
 */
export interface PharmacyCounselorPrescription {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPrescription
   */
  exchangeNumber?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyCounselorPrescription
   */
  images?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyCounselorPrescription
   */
  paths?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPrescription
   */
  type?: PharmacyCounselorPrescriptionTypeEnum;
  /**
   *
   * @type {Date}
   * @memberof PharmacyCounselorPrescription
   */
  updatedTime?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorPrescription
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorPrescriptionTypeEnum {
  Paper = "paper",
  Digital = "digital",
}

export function PharmacyCounselorPrescriptionFromJSON(json: any): PharmacyCounselorPrescription {
  return PharmacyCounselorPrescriptionFromJSONTyped(json, false);
}

export function PharmacyCounselorPrescriptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorPrescription {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorPrescriptionTypename,

    exchangeNumber: !exists(json, "exchange_number") ? undefined : json["exchange_number"],
    images: !exists(json, "images") ? undefined : json["images"],
    paths: !exists(json, "paths") ? undefined : json["paths"],
    type: !exists(json, "type") ? undefined : json["type"],
    updatedTime:
      !exists(json, "updated_time") ||
      (typeof json["updated_time"] === "string" && json["updated_time"].substring(0, 4) === "0001")
        ? undefined
        : json["updated_time"],
  };
}

export function PharmacyCounselorPrescriptionToJSON(object?: PharmacyCounselorPrescription | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    exchange_number: value.exchangeNumber,
    images: value.images,
    paths: value.paths,
    type: value.type,
    updated_time: value.updatedTime === undefined ? undefined : moment(value.updatedTime).tz("Asia/Tokyo").format(),
  };
}
