/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientMissionPeriodTypename = "PatientMissionPeriod" as const;

/**
 *
 * @export
 * @interface PatientMissionPeriod
 */
export interface PatientMissionPeriod {
  /**
   *
   * @type {Date}
   * @memberof PatientMissionPeriod
   */
  endTime: string;
  /**
   *
   * @type {Date}
   * @memberof PatientMissionPeriod
   */
  participationEndTime: string;
  /**
   *
   * @type {Date}
   * @memberof PatientMissionPeriod
   */
  participationStartTime: string;
  /**
   *
   * @type {Date}
   * @memberof PatientMissionPeriod
   */
  startTime: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMissionPeriod
   */
  readonly __typename?: string;
}

export function PatientMissionPeriodFromJSON(json: any): PatientMissionPeriod {
  return PatientMissionPeriodFromJSONTyped(json, false);
}

export function PatientMissionPeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMissionPeriod {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMissionPeriodTypename,

    endTime: json["end_time"],
    participationEndTime: json["participation_end_time"],
    participationStartTime: json["participation_start_time"],
    startTime: json["start_time"],
  };
}

export function PatientMissionPeriodToJSON(object?: PatientMissionPeriod | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    end_time: moment(value.endTime).tz("Asia/Tokyo").format(),
    participation_end_time: moment(value.participationEndTime).tz("Asia/Tokyo").format(),
    participation_start_time: moment(value.participationStartTime).tz("Asia/Tokyo").format(),
    start_time: moment(value.startTime).tz("Asia/Tokyo").format(),
  };
}
