export const ZendeskCustomInputFieldTypename = "ZendeskCustomInputField" as const;

/**
 *
 * @export
 * @interface ZendeskCustomInputField
 * @see https://developer.zendesk.com/documentation/ticketing/managing-tickets/creating-and-updating-tickets/#setting-custom-field-values
 */
export interface ZendeskCustomInputField {
  /**
   *
   * @type {number}
   * @memberof ZendeskCustomInputField
   */
  id: number;
  /**
   *
   * @type {string | string[]}
   * @memberof ZendeskCustomInputField
   */
  value: string | string[];
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskCustomInputField
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskCustomInputFieldFromJSON(json: any): ZendeskCustomInputField {
  return ZendeskCustomInputFieldFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskCustomInputFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZendeskCustomInputField {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ZendeskCustomInputFieldTypename,
    id: json["id"],
    value: json["value"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskCustomInputFieldToJSON(object?: ZendeskCustomInputField | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    value: value.value,
  };
}
