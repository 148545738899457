/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicVisitAvailableTypename = "ClinicVisitAvailable" as const;

/**
 *
 * @export
 * @interface ClinicVisitAvailable
 */
export interface ClinicVisitAvailable {
  /**
   *
   * @type {boolean}
   * @memberof ClinicVisitAvailable
   */
  first?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ClinicVisitAvailable
   */
  _return?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicVisitAvailable
   */
  readonly __typename?: string;
}

export function ClinicVisitAvailableFromJSON(json: any): ClinicVisitAvailable {
  return ClinicVisitAvailableFromJSONTyped(json, false);
}

export function ClinicVisitAvailableFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicVisitAvailable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicVisitAvailableTypename,

    first: !exists(json, "first") ? undefined : json["first"],
    _return: !exists(json, "return") ? undefined : json["return"],
  };
}

export function ClinicVisitAvailableToJSON(object?: ClinicVisitAvailable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    first: value.first,
    return: value._return,
  };
}
