/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicVisitAvailable,
  ClinicVisitAvailableFromJSON,
  ClinicVisitAvailableFromJSONTyped,
  ClinicVisitAvailableToJSON,
} from "./";

export const ClinicSlotTypename = "ClinicSlot" as const;

/**
 *
 * @export
 * @interface ClinicSlot
 */
export interface ClinicSlot {
  /**
   *
   * @type {number}
   * @memberof ClinicSlot
   */
  counselingCount?: number;
  /**
   *
   * @type {string}
   * @memberof ClinicSlot
   */
  from: string;
  /**
   *
   * @type {number}
   * @memberof ClinicSlot
   */
  max?: number;
  /**
   * serial number of counseling time slot
   * @type {number}
   * @memberof ClinicSlot
   */
  number: number;
  /**
   *
   * @type {string}
   * @memberof ClinicSlot
   */
  to: string;
  /**
   *
   * @type {ClinicVisitAvailable}
   * @memberof ClinicSlot
   */
  visitAvailable?: ClinicVisitAvailable;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicSlot
   */
  readonly __typename?: string;
}

export function ClinicSlotFromJSON(json: any): ClinicSlot {
  return ClinicSlotFromJSONTyped(json, false);
}

export function ClinicSlotFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicSlot {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicSlotTypename,

    counselingCount: !exists(json, "counseling_count") ? undefined : json["counseling_count"],
    from: json["from"],
    max: !exists(json, "max") ? undefined : json["max"],
    number: json["number"],
    to: json["to"],
    visitAvailable: !exists(json, "visit_available")
      ? undefined
      : ClinicVisitAvailableFromJSON(json["visit_available"]),
  };
}

export function ClinicSlotToJSON(object?: ClinicSlot | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling_count: value.counselingCount,
    from: value.from,
    max: value.max,
    number: value.number,
    to: value.to,
    visit_available: ClinicVisitAvailableToJSON(value.visitAvailable),
  };
}
