/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientRefreshToken,
  PatientRefreshTokenFromJSON,
  PatientRefreshTokenFromJSONTyped,
  PatientRefreshTokenToJSON,
} from "./";

export const PatientMedicineNotebookTypename = "PatientMedicineNotebook" as const;

/**
 *
 * @export
 * @interface PatientMedicineNotebook
 */
export interface PatientMedicineNotebook {
  /**
   *
   * @type {boolean}
   * @memberof PatientMedicineNotebook
   */
  agreed?: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof PatientMedicineNotebook
   */
  autoLinkPharmacyIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineNotebook
   */
  chainId?: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebook
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebook
   */
  gender?: string;
  /**
   *
   * @type {number}
   * @memberof PatientMedicineNotebook
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebook
   */
  idToken?: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebook
   */
  name?: string;
  /**
   *
   * @type {PatientRefreshToken}
   * @memberof PatientMedicineNotebook
   */
  refreshToken?: PatientRefreshToken;
  /**
   *
   * @type {string}
   * @memberof PatientMedicineNotebook
   */
  userManageUrl?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicineNotebook
   */
  readonly __typename?: string;
}

export function PatientMedicineNotebookFromJSON(json: any): PatientMedicineNotebook {
  return PatientMedicineNotebookFromJSONTyped(json, false);
}

export function PatientMedicineNotebookFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMedicineNotebook {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicineNotebookTypename,

    agreed: !exists(json, "agreed") ? undefined : json["agreed"],
    autoLinkPharmacyIds: !exists(json, "auto_link_pharmacy_ids") ? undefined : json["auto_link_pharmacy_ids"],
    chainId: !exists(json, "chain_id") ? undefined : json["chain_id"],
    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    id: !exists(json, "id") ? undefined : json["id"],
    idToken: !exists(json, "id_token") ? undefined : json["id_token"],
    name: !exists(json, "name") ? undefined : json["name"],
    refreshToken: !exists(json, "refresh_token") ? undefined : PatientRefreshTokenFromJSON(json["refresh_token"]),
    userManageUrl: !exists(json, "user_manage_url") ? undefined : json["user_manage_url"],
  };
}

export function PatientMedicineNotebookToJSON(object?: PatientMedicineNotebook | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    agreed: value.agreed,
    auto_link_pharmacy_ids: value.autoLinkPharmacyIds,
    chain_id: value.chainId,
    date_of_birth: value.dateOfBirth,
    gender: value.gender,
    id: value.id,
    id_token: value.idToken,
    name: value.name,
    refresh_token: PatientRefreshTokenToJSON(value.refreshToken),
    user_manage_url: value.userManageUrl,
  };
}
