/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  AdminDisabledAccount,
  AdminDisabledAccountFromJSON,
  AdminDisabledAccountToJSON,
  PostDisabledAccountsRequestBody,
  PostDisabledAccountsRequestBodyFromJSON,
  PostDisabledAccountsRequestBodyToJSON,
} from "../models";

export interface DeleteDisabledAccountsRequest {
  uuid: string;
}

export interface PostDisabledAccountsRequest {
  postDisabledAccountsRequestBody: PostDisabledAccountsRequestBody;
}

/**
 *
 */
export class DisabledAccountsApi extends runtime.BaseAPI {
  /**
   * enalbed account
   */
  async deleteDisabledAccountsRaw(
    requestParameters: DeleteDisabledAccountsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
      throw new runtime.RequiredError(
        "uuid",
        "Required parameter requestParameters.uuid was null or undefined when calling deleteDisabledAccounts."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/disabled-accounts/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * enalbed account
   */
  async deleteDisabledAccounts(
    requestParameters: DeleteDisabledAccountsRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteDisabledAccountsRaw(requestParameters, initOverrides);
  }

  /**
   * get disabled accounts
   */
  async getDisabledAccountsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<AdminDisabledAccount>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/disabled-accounts`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminDisabledAccountFromJSON));
  }

  /**
   * get disabled accounts
   */
  async getDisabledAccounts(initOverrides?: RequestInit): Promise<Array<AdminDisabledAccount>> {
    return promiseRetry(
      (retry) =>
        this.getDisabledAccountsRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * disable account
   */
  async postDisabledAccountsRaw(
    requestParameters: PostDisabledAccountsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<AdminDisabledAccount>> {
    if (
      requestParameters.postDisabledAccountsRequestBody === null ||
      requestParameters.postDisabledAccountsRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postDisabledAccountsRequestBody",
        "Required parameter requestParameters.postDisabledAccountsRequestBody was null or undefined when calling postDisabledAccounts."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/disabled-accounts`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostDisabledAccountsRequestBodyToJSON(requestParameters.postDisabledAccountsRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AdminDisabledAccountFromJSON(jsonValue));
  }

  /**
   * disable account
   */
  async postDisabledAccounts(
    requestParameters: PostDisabledAccountsRequest,
    initOverrides?: RequestInit
  ): Promise<AdminDisabledAccount> {
    return promiseRetry(
      (retry) =>
        this.postDisabledAccountsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
