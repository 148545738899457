/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientClinicVerifyEmailOption,
  PatientClinicVerifyEmailOptionFromJSON,
  PatientClinicVerifyEmailOptionFromJSONTyped,
  PatientClinicVerifyEmailOptionToJSON,
  PatientPharmacyVerifyEmailOption,
  PatientPharmacyVerifyEmailOptionFromJSON,
  PatientPharmacyVerifyEmailOptionFromJSONTyped,
  PatientPharmacyVerifyEmailOptionToJSON,
} from "./";

export const PatientVerifyEmailOptionTypename = "PatientVerifyEmailOption" as const;

/**
 *
 * @export
 * @interface PatientVerifyEmailOption
 */
export interface PatientVerifyEmailOption {
  /**
   *
   * @type {PatientClinicVerifyEmailOption}
   * @memberof PatientVerifyEmailOption
   */
  clinic?: PatientClinicVerifyEmailOption;
  /**
   *
   * @type {PatientPharmacyVerifyEmailOption}
   * @memberof PatientVerifyEmailOption
   */
  pharmacy?: PatientPharmacyVerifyEmailOption;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientVerifyEmailOption
   */
  readonly __typename?: string;
}

export function PatientVerifyEmailOptionFromJSON(json: any): PatientVerifyEmailOption {
  return PatientVerifyEmailOptionFromJSONTyped(json, false);
}

export function PatientVerifyEmailOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientVerifyEmailOption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientVerifyEmailOptionTypename,

    clinic: !exists(json, "clinic") ? undefined : PatientClinicVerifyEmailOptionFromJSON(json["clinic"]),
    pharmacy: !exists(json, "pharmacy") ? undefined : PatientPharmacyVerifyEmailOptionFromJSON(json["pharmacy"]),
  };
}

export function PatientVerifyEmailOptionToJSON(object?: PatientVerifyEmailOption | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    clinic: PatientClinicVerifyEmailOptionToJSON(value.clinic),
    pharmacy: PatientPharmacyVerifyEmailOptionToJSON(value.pharmacy),
  };
}
