/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorPostCounselingRequestExaminer,
  PharmacyCounselorPostCounselingRequestExaminerFromJSON,
  PharmacyCounselorPostCounselingRequestExaminerFromJSONTyped,
  PharmacyCounselorPostCounselingRequestExaminerToJSON,
} from "./";

export const PharmacyCounselorPostCounselingRequestPatientTypename =
  "PharmacyCounselorPostCounselingRequestPatient" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorPostCounselingRequestPatient
 */
export interface PharmacyCounselorPostCounselingRequestPatient {
  /**
   *
   * @type {PharmacyCounselorPostCounselingRequestExaminer}
   * @memberof PharmacyCounselorPostCounselingRequestPatient
   */
  examiner?: PharmacyCounselorPostCounselingRequestExaminer;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPostCounselingRequestPatient
   */
  firstName?: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorPostCounselingRequestPatient
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPostCounselingRequestPatient
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPostCounselingRequestPatient
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPostCounselingRequestPatient
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPostCounselingRequestPatient
   */
  tel?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorPostCounselingRequestPatient
   */
  readonly __typename?: string;
}

export function PharmacyCounselorPostCounselingRequestPatientFromJSON(
  json: any
): PharmacyCounselorPostCounselingRequestPatient {
  return PharmacyCounselorPostCounselingRequestPatientFromJSONTyped(json, false);
}

export function PharmacyCounselorPostCounselingRequestPatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorPostCounselingRequestPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorPostCounselingRequestPatientTypename,

    examiner: !exists(json, "examiner")
      ? undefined
      : PharmacyCounselorPostCounselingRequestExaminerFromJSON(json["examiner"]),
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    id: !exists(json, "id") ? undefined : json["id"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
  };
}

export function PharmacyCounselorPostCounselingRequestPatientToJSON(
  object?: PharmacyCounselorPostCounselingRequestPatient | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    examiner: PharmacyCounselorPostCounselingRequestExaminerToJSON(value.examiner),
    first_name: value.firstName,
    id: value.id,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    tel: value.tel,
  };
}
