/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientCompanyTypename = "PatientCompany" as const;

/**
 *
 * @export
 * @interface PatientCompany
 */
export interface PatientCompany {
  /**
   *
   * @type {number}
   * @memberof PatientCompany
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof PatientCompany
   */
  name?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientCompany
   */
  readonly __typename?: string;
}

export function PatientCompanyFromJSON(json: any): PatientCompany {
  return PatientCompanyFromJSONTyped(json, false);
}

export function PatientCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientCompany {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientCompanyTypename,

    code: !exists(json, "code") ? undefined : json["code"],
    name: !exists(json, "name") ? undefined : json["name"],
  };
}

export function PatientCompanyToJSON(object?: PatientCompany | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    code: value.code,
    name: value.name,
  };
}
