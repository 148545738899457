/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientFamilyAccount,
  PatientFamilyAccountFromJSON,
  PatientFamilyAccountFromJSONTyped,
  PatientFamilyAccountToJSON,
  PatientMedicineNotebook,
  PatientMedicineNotebookFromJSON,
  PatientMedicineNotebookFromJSONTyped,
  PatientMedicineNotebookToJSON,
  PatientServiceNeeds,
  PatientServiceNeedsFromJSON,
  PatientServiceNeedsFromJSONTyped,
  PatientServiceNeedsToJSON,
} from "./";

export const PatientPatientTypename = "PatientPatient" as const;

/**
 *
 * @export
 * @interface PatientPatient
 */
export interface PatientPatient {
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  authId?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  dateOfBirth?: string;
  /**
   *
   * @type {Date}
   * @memberof PatientPatient
   */
  deletedAt?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  email?: string;
  /**
   *
   * @type {object}
   * @memberof PatientPatient
   */
  externalIds?: object;
  /**
   *
   * @type {Array<PatientFamilyAccount>}
   * @memberof PatientPatient
   */
  familyAccount?: Array<PatientFamilyAccount>;
  /**
   *
   * @type {object}
   * @memberof PatientPatient
   */
  familyPharmacistQuestionnaire?: object;
  /**
   *
   * @type {Array<number>}
   * @memberof PatientPatient
   */
  favoritePharmacies?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  gender?: PatientPatientGenderEnum;
  /**
   *
   * @type {boolean}
   * @memberof PatientPatient
   */
  hasLineAccount?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  hasMedicineNotebook?: PatientPatientHasMedicineNotebookEnum;
  /**
   *
   * @type {number}
   * @memberof PatientPatient
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  insuranceCardPath?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  invitationId?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  loginProviderType: PatientPatientLoginProviderTypeEnum;
  /**
   *
   * @type {Array<PatientMedicineNotebook>}
   * @memberof PatientPatient
   */
  medicineNotebooks?: Array<PatientMedicineNotebook>;
  /**
   *
   * @type {object}
   * @memberof PatientPatient
   */
  notificationAuthorization?: object;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  notifyType?: PatientPatientNotifyTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof PatientPatient
   */
  otherMedicalImagePaths?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof PatientPatient
   */
  phrAgreement?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  postalCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PatientPatient
   */
  providerTypes: Array<string>;
  /**
   *
   * @type {object}
   * @memberof PatientPatient
   */
  receiptCodes?: object;
  /**
   *
   * @type {boolean}
   * @memberof PatientPatient
   */
  sendFollowUp?: boolean;
  /**
   *
   * @type {PatientServiceNeeds}
   * @memberof PatientPatient
   */
  serviceNeeds?: PatientServiceNeeds;
  /**
   *
   * @type {boolean}
   * @memberof PatientPatient
   */
  smsAvailable?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  status: PatientPatientStatusEnum;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  tel?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPatient
   */
  wantsGeneric?: PatientPatientWantsGenericEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientPatient
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientPatientGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientPatientHasMedicineNotebookEnum {
  None = "none",
  Paper = "paper",
  Digital = "digital",
  Yqb = "yqb",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientPatientLoginProviderTypeEnum {
  Email = "email",
  LineSundrug = "line-sundrug",
  OidcSatudora = "oidc-satudora",
  LineQol = "line-qol",
  LineTanpopo = "line-tanpopo",
  OidcMccm = "oidc-mccm",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientPatientNotifyTypeEnum {
  EmailOrSms = "email_or_sms",
  App = "app",
  All = "all",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientPatientStatusEnum {
  Registered = "registered",
  Verified = "verified",
  Withdrew = "withdrew",
  AutoWithdrew = "auto_withdrew",
}
/**
 * @export
 * @enum {string}
 */
export enum PatientPatientWantsGenericEnum {
  None = "none",
  Want = "want",
  Consultation = "consultation",
}

export function PatientPatientFromJSON(json: any): PatientPatient {
  return PatientPatientFromJSONTyped(json, false);
}

export function PatientPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientPatientTypename,

    addressLine1: !exists(json, "address_line1") ? undefined : json["address_line1"],
    addressLine2: !exists(json, "address_line2") ? undefined : json["address_line2"],
    authId: !exists(json, "auth_id") ? undefined : json["auth_id"],
    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    deletedAt:
      !exists(json, "deleted_at") ||
      (typeof json["deleted_at"] === "string" && json["deleted_at"].substring(0, 4) === "0001")
        ? undefined
        : json["deleted_at"],
    email: !exists(json, "email") ? undefined : json["email"],
    externalIds: !exists(json, "external_ids") ? undefined : json["external_ids"],
    familyAccount: !exists(json, "family_account")
      ? undefined
      : (json["family_account"] as Array<any>).map(PatientFamilyAccountFromJSON),
    familyPharmacistQuestionnaire: !exists(json, "family_pharmacist_questionnaire")
      ? undefined
      : json["family_pharmacist_questionnaire"],
    favoritePharmacies: !exists(json, "favorite_pharmacies") ? undefined : json["favorite_pharmacies"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    hasLineAccount: !exists(json, "has_line_account") ? undefined : json["has_line_account"],
    hasMedicineNotebook: !exists(json, "has_medicine_notebook") ? undefined : json["has_medicine_notebook"],
    id: json["id"],
    insuranceCardPath: !exists(json, "insurance_card_path") ? undefined : json["insurance_card_path"],
    invitationId: !exists(json, "invitation_id") ? undefined : json["invitation_id"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    loginProviderType: json["login_provider_type"],
    medicineNotebooks: !exists(json, "medicine_notebooks")
      ? undefined
      : (json["medicine_notebooks"] as Array<any>).map(PatientMedicineNotebookFromJSON),
    notificationAuthorization: !exists(json, "notification_authorization")
      ? undefined
      : json["notification_authorization"],
    notifyType: !exists(json, "notify_type") ? undefined : json["notify_type"],
    otherMedicalImagePaths: !exists(json, "other_medical_image_paths") ? undefined : json["other_medical_image_paths"],
    phrAgreement: !exists(json, "phr_agreement") ? undefined : json["phr_agreement"],
    postalCode: !exists(json, "postal_code") ? undefined : json["postal_code"],
    providerTypes: json["provider_types"],
    receiptCodes: !exists(json, "receipt_codes") ? undefined : json["receipt_codes"],
    sendFollowUp: !exists(json, "send_follow_up") ? undefined : json["send_follow_up"],
    serviceNeeds: !exists(json, "service_needs") ? undefined : PatientServiceNeedsFromJSON(json["service_needs"]),
    smsAvailable: !exists(json, "sms_available") ? undefined : json["sms_available"],
    status: json["status"],
    tel: !exists(json, "tel") ? undefined : json["tel"],
    wantsGeneric: !exists(json, "wants_generic") ? undefined : json["wants_generic"],
  };
}

export function PatientPatientToJSON(object?: PatientPatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    auth_id: value.authId,
    date_of_birth: value.dateOfBirth,
    deleted_at: value.deletedAt === undefined ? undefined : moment(value.deletedAt).tz("Asia/Tokyo").format(),
    email: value.email,
    external_ids: value.externalIds,
    family_account:
      value.familyAccount === undefined
        ? undefined
        : (value.familyAccount as Array<any>).map(PatientFamilyAccountToJSON),
    family_pharmacist_questionnaire: value.familyPharmacistQuestionnaire,
    favorite_pharmacies: value.favoritePharmacies,
    first_name: value.firstName,
    gender: value.gender,
    has_line_account: value.hasLineAccount,
    has_medicine_notebook: value.hasMedicineNotebook,
    id: value.id,
    insurance_card_path: value.insuranceCardPath,
    invitation_id: value.invitationId,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    login_provider_type: value.loginProviderType,
    medicine_notebooks:
      value.medicineNotebooks === undefined
        ? undefined
        : (value.medicineNotebooks as Array<any>).map(PatientMedicineNotebookToJSON),
    notification_authorization: value.notificationAuthorization,
    notify_type: value.notifyType,
    other_medical_image_paths: value.otherMedicalImagePaths,
    phr_agreement: value.phrAgreement,
    postal_code: value.postalCode,
    provider_types: value.providerTypes,
    receipt_codes: value.receiptCodes,
    send_follow_up: value.sendFollowUp,
    service_needs: PatientServiceNeedsToJSON(value.serviceNeeds),
    sms_available: value.smsAvailable,
    status: value.status,
    tel: value.tel,
    wants_generic: value.wantsGeneric,
  };
}
