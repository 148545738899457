export const ZendeskSatisfactionRatingTypename = "ZendeskSatisfactionRating" as const;

/**
 *
 * @export
 * @interface ZendeskSatisfactionRating
 * @see https://developer.zendesk.com/api-reference/ticketing/ticket-management/satisfaction_ratings/#json-format
 */
export interface ZendeskSatisfactionRating {
  /**
   * The id of agent assigned to at the time of rating
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  assignee_id: number;
  /**
   * The comment received with this rating, if available
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  comment: string;
  /**
   * The time the satisfaction rating got created
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  created_at: string;
  /**
   * The id of group assigned to at the time of rating
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  group_id: number;
  /**
   * 	Automatically assigned upon creation
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  id: number;
  /**
   * The reason for a bad rating given by the requester in a follow-up question. Satisfaction reasons must be enabled
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  reason: string;
  /**
   * The default reasons the user can select from a list menu for giving a negative rating. See Reason codes in the Satisfaction Reasons API. Can only be set on ratings with a score of "bad".
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  reason_code: number;
  /**
   * The id of ticket requester submitting the rating
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  requester_id: number;
  /**
   * The rating "offered", "unoffered", "good" or "bad"
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  score: string;
  /**
   * The id of ticket being rated
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  ticket_id: number;
  /**
   * The time the satisfaction rating got updated
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  updated_at: string;
  /**
   * The API url of this rating
   * @type {number}
   * @memberof ZendeskSatisfactionRating
   */
  url: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ZendeskSatisfactionRating
   */
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function ZendeskSatisfactionRatingFromJSON(json: any): ZendeskSatisfactionRating {
  return ZendeskSatisfactionRatingFromJSONTyped(json, false);
}

export function ZendeskSatisfactionRatingFromJSONTyped(
  json: any, // eslint-disable-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  ignoreDiscriminator: boolean
): ZendeskSatisfactionRating {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ZendeskSatisfactionRatingTypename,
    assignee_id: json["assignee_id"],
    comment: json["comment"],
    created_at: json["created_at"],
    group_id: json["group_id"],
    id: json["id"],
    reason: json["reason"],
    reason_code: json["reason_code"],
    requester_id: json["requester_id"],
    score: json["score"],
    ticket_id: json["ticket_id"],
    updated_at: json["updated_at"],
    url: json["url"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ZendeskSatisfactionRatingToJSON(object?: ZendeskSatisfactionRating | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    assignee_id: value.assignee_id,
    comment: value.comment,
    created_at: value.created_at,
    group_id: value.group_id,
    id: value.id,
    reason: value.reason,
    reason_code: value.reason_code,
    requester_id: value.requester_id,
    score: value.score,
    ticket_id: value.ticket_id,
    updated_at: value.updated_at,
    url: value.url,
  };
}
