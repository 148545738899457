/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicCounselorExaminer,
  ClinicCounselorExaminerFromJSON,
  ClinicCounselorExaminerFromJSONTyped,
  ClinicCounselorExaminerToJSON,
} from "./";

export const ClinicCounselorPatientTypename = "ClinicCounselorPatient" as const;

/**
 *
 * @export
 * @interface ClinicCounselorPatient
 */
export interface ClinicCounselorPatient {
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  addressLine1: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  email: string;
  /**
   *
   * @type {ClinicCounselorExaminer}
   * @memberof ClinicCounselorPatient
   */
  examiner?: ClinicCounselorExaminer;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  firstName: string;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorPatient
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  kanaFirstName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  kanaLastName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  lastName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  status: ClinicCounselorPatientStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  tel: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorPatient
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ClinicCounselorPatientStatusEnum {
  Registered = "registered",
  Verified = "verified",
  Withdrew = "withdrew",
}

export function ClinicCounselorPatientFromJSON(json: any): ClinicCounselorPatient {
  return ClinicCounselorPatientFromJSONTyped(json, false);
}

export function ClinicCounselorPatientFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicCounselorPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorPatientTypename,

    addressLine1: json["address_line1"],
    addressLine2: !exists(json, "address_line2") ? undefined : json["address_line2"],
    email: json["email"],
    examiner: !exists(json, "examiner") ? undefined : ClinicCounselorExaminerFromJSON(json["examiner"]),
    firstName: json["first_name"],
    id: json["id"],
    kanaFirstName: json["kana_first_name"],
    kanaLastName: json["kana_last_name"],
    lastName: json["last_name"],
    postalCode: json["postal_code"],
    status: json["status"],
    tel: json["tel"],
  };
}

export function ClinicCounselorPatientToJSON(object?: ClinicCounselorPatient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    address_line1: value.addressLine1,
    address_line2: value.addressLine2,
    email: value.email,
    examiner: ClinicCounselorExaminerToJSON(value.examiner),
    first_name: value.firstName,
    id: value.id,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    postal_code: value.postalCode,
    status: value.status,
    tel: value.tel,
  };
}
