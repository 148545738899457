import paymentApi from "@mgdx/api/lib/paymentApi";
import { GetPaymentRequest, PostPaymentRequestBody } from "@mgdx/api/lib/platform/payer";
import { Payment, PaymentStatusEnum } from "@mgdx/shared/src/models/Payment";
import { createRequestAction } from "@mgdx-libs/redux-requests";
import toString from "lodash/toString";

export const findPaymentById = createRequestAction<GetPaymentRequest, Payment>(
  "requests/payment/findPaymentById",
  ({ paymentId }, meta) => ({
    request: {
      promise: paymentApi.getPayment({
        paymentId,
      }),
    },
    meta: {
      requestKey: paymentId,
      ...meta,
    },
  })
);

export const findPaymentStatusById = createRequestAction<GetPaymentRequest, Payment, PaymentStatusEnum>(
  "requests/payment/findClinicPaymentStatusByCounselingId",
  ({ paymentId }, meta) => ({
    request: {
      promise: paymentApi.getPayment({
        paymentId,
      }),
    },
    meta: {
      requestKey: paymentId,
      onSuccess: (response) => {
        return { ...response, data: response.data.status };
      },
      ...meta,
    },
  })
);

export type RegisterPaymentParams = Omit<PostPaymentRequestBody, "creditCard"> & PostPaymentRequestBody["creditCard"];

export const registerPayment = createRequestAction<RegisterPaymentParams, Payment>(
  "requests/payment/registerPayment",
  ({ counselingId, cardId, token, ...values }, meta) => ({
    request: {
      promise: paymentApi.postPayments({
        postPaymentRequestBody: {
          ...values,
          counselingId,
          creditCard: { cardId, token },
        },
      }),
    },
    meta: {
      requestKey: toString(counselingId),
      ...meta,
    },
  })
);
