/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientAllergy,
  PatientAllergyFromJSON,
  PatientAllergyFromJSONTyped,
  PatientAllergyToJSON,
  PatientMedicalHistory,
  PatientMedicalHistoryFromJSON,
  PatientMedicalHistoryFromJSONTyped,
  PatientMedicalHistoryToJSON,
  PatientSideEffect,
  PatientSideEffectFromJSON,
  PatientSideEffectFromJSONTyped,
  PatientSideEffectToJSON,
  PatientVaccine,
  PatientVaccineFromJSON,
  PatientVaccineFromJSONTyped,
  PatientVaccineToJSON,
} from "./";

export const PatientMedicationReconciliationFormTypename = "PatientMedicationReconciliationForm" as const;

/**
 *
 * @export
 * @interface PatientMedicationReconciliationForm
 */
export interface PatientMedicationReconciliationForm {
  /**
   *
   * @type {Array<PatientAllergy>}
   * @memberof PatientMedicationReconciliationForm
   */
  allergies?: Array<PatientAllergy>;
  /**
   *
   * @type {string}
   * @memberof PatientMedicationReconciliationForm
   */
  id?: string;
  /**
   *
   * @type {Array<PatientMedicalHistory>}
   * @memberof PatientMedicationReconciliationForm
   */
  medicalHistories?: Array<PatientMedicalHistory>;
  /**
   *
   * @type {Array<PatientSideEffect>}
   * @memberof PatientMedicationReconciliationForm
   */
  sideEffects?: Array<PatientSideEffect>;
  /**
   *
   * @type {Array<PatientVaccine>}
   * @memberof PatientMedicationReconciliationForm
   */
  vaccines?: Array<PatientVaccine>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicationReconciliationForm
   */
  readonly __typename?: string;
}

export function PatientMedicationReconciliationFormFromJSON(json: any): PatientMedicationReconciliationForm {
  return PatientMedicationReconciliationFormFromJSONTyped(json, false);
}

export function PatientMedicationReconciliationFormFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientMedicationReconciliationForm {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicationReconciliationFormTypename,

    allergies: !exists(json, "allergies") ? undefined : (json["allergies"] as Array<any>).map(PatientAllergyFromJSON),
    id: !exists(json, "id") ? undefined : json["id"],
    medicalHistories: !exists(json, "medical_histories")
      ? undefined
      : (json["medical_histories"] as Array<any>).map(PatientMedicalHistoryFromJSON),
    sideEffects: !exists(json, "side_effects")
      ? undefined
      : (json["side_effects"] as Array<any>).map(PatientSideEffectFromJSON),
    vaccines: !exists(json, "vaccines") ? undefined : (json["vaccines"] as Array<any>).map(PatientVaccineFromJSON),
  };
}

export function PatientMedicationReconciliationFormToJSON(object?: PatientMedicationReconciliationForm | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    allergies: value.allergies === undefined ? undefined : (value.allergies as Array<any>).map(PatientAllergyToJSON),
    id: value.id,
    medical_histories:
      value.medicalHistories === undefined
        ? undefined
        : (value.medicalHistories as Array<any>).map(PatientMedicalHistoryToJSON),
    side_effects:
      value.sideEffects === undefined ? undefined : (value.sideEffects as Array<any>).map(PatientSideEffectToJSON),
    vaccines: value.vaccines === undefined ? undefined : (value.vaccines as Array<any>).map(PatientVaccineToJSON),
  };
}
