/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyFamilyPharmacistImage,
  PharmacyFamilyPharmacistImageFromJSON,
  PharmacyFamilyPharmacistImageFromJSONTyped,
  PharmacyFamilyPharmacistImageToJSON,
  PharmacyFamilyPharmacistLicense,
  PharmacyFamilyPharmacistLicenseFromJSON,
  PharmacyFamilyPharmacistLicenseFromJSONTyped,
  PharmacyFamilyPharmacistLicenseToJSON,
  PharmacyFamilyPharmacistLink,
  PharmacyFamilyPharmacistLinkFromJSON,
  PharmacyFamilyPharmacistLinkFromJSONTyped,
  PharmacyFamilyPharmacistLinkToJSON,
} from "./";

export const PharmacyFamilyPharmacistTypename = "PharmacyFamilyPharmacist" as const;

/**
 *
 * @export
 * @interface PharmacyFamilyPharmacist
 */
export interface PharmacyFamilyPharmacist {
  /**
   *
   * @type {string}
   * @memberof PharmacyFamilyPharmacist
   */
  aboutMyself?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyFamilyPharmacist
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyFamilyPharmacist
   */
  id: string;
  /**
   *
   * @type {PharmacyFamilyPharmacistImage}
   * @memberof PharmacyFamilyPharmacist
   */
  image: PharmacyFamilyPharmacistImage;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFamilyPharmacist
   */
  isPublic?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyFamilyPharmacist
   */
  kanaFirstName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyFamilyPharmacist
   */
  kanaLastName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyFamilyPharmacist
   */
  lastName: string;
  /**
   *
   * @type {PharmacyFamilyPharmacistLicense}
   * @memberof PharmacyFamilyPharmacist
   */
  license: PharmacyFamilyPharmacistLicense;
  /**
   *
   * @type {Array<PharmacyFamilyPharmacistLink>}
   * @memberof PharmacyFamilyPharmacist
   */
  links?: Array<PharmacyFamilyPharmacistLink>;
  /**
   *
   * @type {number}
   * @memberof PharmacyFamilyPharmacist
   */
  pharmacyId: number;
  /**
   *
   * @type {Date}
   * @memberof PharmacyFamilyPharmacist
   */
  updatedTime: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyFamilyPharmacist
   */
  readonly __typename?: string;
}

export function PharmacyFamilyPharmacistFromJSON(json: any): PharmacyFamilyPharmacist {
  return PharmacyFamilyPharmacistFromJSONTyped(json, false);
}

export function PharmacyFamilyPharmacistFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyFamilyPharmacist {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyFamilyPharmacistTypename,

    aboutMyself: !exists(json, "about_myself") ? undefined : json["about_myself"],
    firstName: json["first_name"],
    id: json["id"],
    image: PharmacyFamilyPharmacistImageFromJSON(json["image"]),
    isPublic: !exists(json, "is_public") ? undefined : json["is_public"],
    kanaFirstName: json["kana_first_name"],
    kanaLastName: json["kana_last_name"],
    lastName: json["last_name"],
    license: PharmacyFamilyPharmacistLicenseFromJSON(json["license"]),
    links: !exists(json, "links") ? undefined : (json["links"] as Array<any>).map(PharmacyFamilyPharmacistLinkFromJSON),
    pharmacyId: json["pharmacy_id"],
    updatedTime: json["updated_time"],
  };
}

export function PharmacyFamilyPharmacistToJSON(object?: PharmacyFamilyPharmacist | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    about_myself: value.aboutMyself,
    first_name: value.firstName,
    id: value.id,
    image: PharmacyFamilyPharmacistImageToJSON(value.image),
    is_public: value.isPublic,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    license: PharmacyFamilyPharmacistLicenseToJSON(value.license),
    links: value.links === undefined ? undefined : (value.links as Array<any>).map(PharmacyFamilyPharmacistLinkToJSON),
    pharmacy_id: value.pharmacyId,
    updated_time: moment(value.updatedTime).tz("Asia/Tokyo").format(),
  };
}
