import { getFirebaseStorage } from "@mgdx-libs/firebase";
import moment from "@mgdx-libs/moment";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useCallback, useMemo } from "react";

import { ROOM_COLLECTION_NAME } from "../constants";
import { SenderTypeEnum, TalkRoomDocument } from "../models";
import { useTalkReadImage } from "./useTalkReadImage";
import { useTalkSendMessage } from "./useTalkSendMessage";

export type TalkSendImage = (image: File) => Promise<void>;

export type UseTalkSendImageProps = {
  roomId: string;
  room?: TalkRoomDocument;
  talkSenderType: SenderTypeEnum;
};

export type UseTalkSendImage = (props: UseTalkSendImageProps) => TalkSendImage;

export const useTalkSendImage: UseTalkSendImage = ({ roomId, room, talkSenderType }) => {
  const loadImage = useTalkReadImage();

  const sendMessage = useTalkSendMessage({ roomId, talkSenderType });

  const medicalNamespace = useMemo(() => {
    if (room?.pharmacy?.id) {
      return "pharmacies";
    }

    if (room?.clinic?.id) {
      return "clinics";
    }

    return "";
  }, [room?.clinic?.id, room?.pharmacy?.id]);

  const medicalId = useMemo(() => {
    if (room?.pharmacy?.id) {
      return room?.pharmacy?.id;
    }

    if (room?.clinic?.id) {
      return room?.clinic?.id;
    }

    return 0;
  }, [room?.clinic?.id, room?.pharmacy?.id]);

  return useCallback<TalkSendImage>(
    async (image) => {
      const firebaseStorage = getFirebaseStorage();
      const now = moment();
      const timestamp = now.unix();

      const imagePath = [
        ROOM_COLLECTION_NAME,
        "patients",
        room?.patientId,
        medicalNamespace,
        medicalId,
        "counselings",
        room?.counselingId,
        "contents",
        `${timestamp}.${image.name}`,
      ].join("/");

      const imageRef = ref(firebaseStorage, imagePath);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);
      const imageTag = await loadImage(imageUrl);

      await sendMessage({
        image: {
          name: image.name,
          path: imagePath,
          url: imageUrl,
          height: imageTag.naturalHeight,
          width: imageTag.naturalWidth,
        },
      });
    },
    [loadImage, medicalId, medicalNamespace, room?.counselingId, room?.patientId, sendMessage]
  );
};
