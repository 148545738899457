import { exists } from "@mgdx/api/lib/runtime";

import {
  PharmacyCustomInterviewOption,
  PharmacyCustomInterviewOptionFromJSON,
  PharmacyCustomInterviewOptionToJSON,
} from "./PharmacyCustomInterviewOption";
export const PharmacyCustomInterviewQuestionnaireOptionsTypename =
  "PharmacyCustomInterviewQuestionnaireOptions" as const;

/**
 *
 * @export
 * @interface PharmacyCustomInterviewQuestionnaireOptions
 * @see PharmacyCustomInterviewQuestionnaireOptions
 */
export interface PharmacyCustomInterviewQuestionnaireOptions {
  id: number;
  type: string;
  label: string;
  value: string;
  options: PharmacyCustomInterviewOption[];
  answers: string[];
  readonly __typename?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewQuestionnaireOptionsFromJSON(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: any
): PharmacyCustomInterviewQuestionnaireOptions {
  return PharmacyCustomInterviewQuestionnaireOptionsFromJSONTyped(json, false);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewQuestionnaireOptionsFromJSONTyped(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCustomInterviewQuestionnaireOptions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCustomInterviewQuestionnaireOptionsTypename,
    id: json["id"],
    type: json["type"],
    label: json["label"],
    value: json["value"],
    options: !exists(json, "options") ? [] : json["options"].map(PharmacyCustomInterviewOptionFromJSON),
    answers: !exists(json, "answers") ? [] : json["answers"],
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PharmacyCustomInterviewQuestionnaireOptionsToJSON(
  object?: PharmacyCustomInterviewQuestionnaireOptions | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    type: value.type,
    label: value.label,
    value: value.value,
    options: value.options.map(PharmacyCustomInterviewOptionToJSON),
    answers: value.answers,
  };
}
