import * as Sentry from "@sentry/react";
import React from "react";

export const ErrorFallbackSentry = ({ error, resetError }: Parameters<Sentry.FallbackRender>[0]) => (
  <div role="alert">
    <p>Error Message</p>
    <pre>{error.message}</pre>
    <button onClick={resetError}>Try again</button>
  </div>
);
