/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientOTCMedicineMaster,
  PatientOTCMedicineMasterFromJSON,
  PatientOTCMedicineMasterFromJSONTyped,
  PatientOTCMedicineMasterToJSON,
} from "./";

export const PatientOTCMedicineTypename = "PatientOTCMedicine" as const;

/**
 *
 * @export
 * @interface PatientOTCMedicine
 */
export interface PatientOTCMedicine {
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicine
   */
  companyName?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicine
   */
  finishedOn?: string;
  /**
   *
   * @type {boolean}
   * @memberof PatientOTCMedicine
   */
  hidden?: boolean;
  /**
   *
   * @type {number}
   * @memberof PatientOTCMedicine
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicine
   */
  janCode: string;
  /**
   *
   * @type {PatientOTCMedicineMaster}
   * @memberof PatientOTCMedicine
   */
  master?: PatientOTCMedicineMaster;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicine
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOTCMedicine
   */
  startedOn: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientOTCMedicine
   */
  readonly __typename?: string;
}

export function PatientOTCMedicineFromJSON(json: any): PatientOTCMedicine {
  return PatientOTCMedicineFromJSONTyped(json, false);
}

export function PatientOTCMedicineFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientOTCMedicine {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientOTCMedicineTypename,

    companyName: !exists(json, "company_name") ? undefined : json["company_name"],
    finishedOn: !exists(json, "finished_on") ? undefined : json["finished_on"],
    hidden: !exists(json, "hidden") ? undefined : json["hidden"],
    id: !exists(json, "id") ? undefined : json["id"],
    janCode: json["jan_code"],
    master: !exists(json, "master") ? undefined : PatientOTCMedicineMasterFromJSON(json["master"]),
    name: !exists(json, "name") ? undefined : json["name"],
    startedOn: json["started_on"],
  };
}

export function PatientOTCMedicineToJSON(object?: PatientOTCMedicine | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    company_name: value.companyName,
    finished_on: value.finishedOn,
    hidden: value.hidden,
    id: value.id,
    jan_code: value.janCode,
    master: PatientOTCMedicineMasterToJSON(value.master),
    name: value.name,
    started_on: value.startedOn,
  };
}
