/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorCounselingReportOptionTypename = "PharmacyCounselorCounselingReportOption" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReportOption
 */
export interface PharmacyCounselorCounselingReportOption {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReportOption
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorCounselingReportOption
   */
  isFreeEntry?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportOption
   */
  optionName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportOption
   */
  readonly __typename?: string;
}

export function PharmacyCounselorCounselingReportOptionFromJSON(json: any): PharmacyCounselorCounselingReportOption {
  return PharmacyCounselorCounselingReportOptionFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReportOption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportOptionTypename,

    id: json["id"],
    isFreeEntry: !exists(json, "is_free_entry") ? undefined : json["is_free_entry"],
    optionName: json["option_name"],
  };
}

export function PharmacyCounselorCounselingReportOptionToJSON(
  object?: PharmacyCounselorCounselingReportOption | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    is_free_entry: value.isFreeEntry,
    option_name: value.optionName,
  };
}
