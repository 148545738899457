/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import { ClinicDate, ClinicDateFromJSON, ClinicDateToJSON } from "../models";

export interface GetCalendarRequest {
  clinicId: number;
  departmentId: number;
  withClosedDate?: boolean;
  withWholeTerm?: boolean;
}

/**
 *
 */
export class CalendarApi extends runtime.BaseAPI {
  /**
   * Get a department calendar
   */
  async getCalendarRaw(
    requestParameters: GetCalendarRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<ClinicDate>>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling getCalendar."
      );
    }

    if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
      throw new runtime.RequiredError(
        "departmentId",
        "Required parameter requestParameters.departmentId was null or undefined when calling getCalendar."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.withClosedDate !== undefined) {
      queryParameters["with_closed_date"] = requestParameters.withClosedDate;
    }

    if (requestParameters.withWholeTerm !== undefined) {
      queryParameters["with_whole_term"] = requestParameters.withWholeTerm;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments/{department_id}/calendar`
          .replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId)))
          .replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClinicDateFromJSON));
  }

  /**
   * Get a department calendar
   */
  async getCalendar(requestParameters: GetCalendarRequest, initOverrides?: RequestInit): Promise<Array<ClinicDate>> {
    return promiseRetry(
      (retry) =>
        this.getCalendarRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
