/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { ChatterAttendee, ChatterAttendeeFromJSON, ChatterAttendeeFromJSONTyped, ChatterAttendeeToJSON } from "./";

export const ChatterAttendeesTypename = "ChatterAttendees" as const;

/**
 *
 * @export
 * @interface ChatterAttendees
 */
export interface ChatterAttendees {
  /**
   *
   * @type {ChatterAttendee}
   * @memberof ChatterAttendees
   */
  doctor?: ChatterAttendee;
  /**
   *
   * @type {ChatterAttendee}
   * @memberof ChatterAttendees
   */
  patient?: ChatterAttendee;
  /**
   *
   * @type {ChatterAttendee}
   * @memberof ChatterAttendees
   */
  pharmacist?: ChatterAttendee;
  /**
   * Resource type name
   * @type {string}
   * @memberof ChatterAttendees
   */
  readonly __typename?: string;
}

export function ChatterAttendeesFromJSON(json: any): ChatterAttendees {
  return ChatterAttendeesFromJSONTyped(json, false);
}

export function ChatterAttendeesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatterAttendees {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ChatterAttendeesTypename,

    doctor: !exists(json, "doctor") ? undefined : ChatterAttendeeFromJSON(json["doctor"]),
    patient: !exists(json, "patient") ? undefined : ChatterAttendeeFromJSON(json["patient"]),
    pharmacist: !exists(json, "pharmacist") ? undefined : ChatterAttendeeFromJSON(json["pharmacist"]),
  };
}

export function ChatterAttendeesToJSON(object?: ChatterAttendees | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    doctor: ChatterAttendeeToJSON(value.doctor),
    patient: ChatterAttendeeToJSON(value.patient),
    pharmacist: ChatterAttendeeToJSON(value.pharmacist),
  };
}
