/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PatientAllPatientsFamilyPharmacistQuestionnaire,
  PatientAllPatientsFamilyPharmacistQuestionnaireFromJSON,
  PatientAllPatientsFamilyPharmacistQuestionnaireFromJSONTyped,
  PatientAllPatientsFamilyPharmacistQuestionnaireToJSON,
} from "./";

export const PatientAllPatientsFamilyPharmacistQuestionnairesTypename =
  "PatientAllPatientsFamilyPharmacistQuestionnaires" as const;

/**
 *
 * @export
 * @interface PatientAllPatientsFamilyPharmacistQuestionnaires
 */
export interface PatientAllPatientsFamilyPharmacistQuestionnaires {
  /**
   *
   * @type {Array<PatientAllPatientsFamilyPharmacistQuestionnaire>}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaires
   */
  familyPharmacistQuestionnaires?: Array<PatientAllPatientsFamilyPharmacistQuestionnaire>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientAllPatientsFamilyPharmacistQuestionnaires
   */
  readonly __typename?: string;
}

export function PatientAllPatientsFamilyPharmacistQuestionnairesFromJSON(
  json: any
): PatientAllPatientsFamilyPharmacistQuestionnaires {
  return PatientAllPatientsFamilyPharmacistQuestionnairesFromJSONTyped(json, false);
}

export function PatientAllPatientsFamilyPharmacistQuestionnairesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientAllPatientsFamilyPharmacistQuestionnaires {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientAllPatientsFamilyPharmacistQuestionnairesTypename,

    familyPharmacistQuestionnaires: !exists(json, "family_pharmacist_questionnaires")
      ? undefined
      : (json["family_pharmacist_questionnaires"] as Array<any>).map(
          PatientAllPatientsFamilyPharmacistQuestionnaireFromJSON
        ),
  };
}

export function PatientAllPatientsFamilyPharmacistQuestionnairesToJSON(
  object?: PatientAllPatientsFamilyPharmacistQuestionnaires | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    family_pharmacist_questionnaires:
      value.familyPharmacistQuestionnaires === undefined
        ? undefined
        : (value.familyPharmacistQuestionnaires as Array<any>).map(
            PatientAllPatientsFamilyPharmacistQuestionnaireToJSON
          ),
  };
}
