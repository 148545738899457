/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientOnetimeCodeTypename = "PatientOnetimeCode" as const;

/**
 *
 * @export
 * @interface PatientOnetimeCode
 */
export interface PatientOnetimeCode {
  /**
   *
   * @type {string}
   * @memberof PatientOnetimeCode
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof PatientOnetimeCode
   */
  onetimeCode?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientOnetimeCode
   */
  readonly __typename?: string;
}

export function PatientOnetimeCodeFromJSON(json: any): PatientOnetimeCode {
  return PatientOnetimeCodeFromJSONTyped(json, false);
}

export function PatientOnetimeCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientOnetimeCode {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientOnetimeCodeTypename,

    code: !exists(json, "code") ? undefined : json["code"],
    onetimeCode: !exists(json, "onetime_code") ? undefined : json["onetime_code"],
  };
}

export function PatientOnetimeCodeToJSON(object?: PatientOnetimeCode | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    code: value.code,
    onetime_code: value.onetimeCode,
  };
}
