import * as Sentry from "@sentry/react";
import React from "react";

import { ErrorReporter } from "./createErrorHandler";
import { ErrorFallbackSentry } from "./ErrorFallbackSentry";

export type SentryErrorBoundaryProps = React.PropsWithChildren<{
  errorHandlerReport: ErrorReporter;
}>;

export const SentryErrorBoundary: React.FC<SentryErrorBoundaryProps> = ({ errorHandlerReport, children }) => (
  <Sentry.ErrorBoundary
    fallback={ErrorFallbackSentry}
    onError={(error) => errorHandlerReport(error, { skipSentry: true })}
    showDialog={true}
  >
    {children}
  </Sentry.ErrorBoundary>
);
