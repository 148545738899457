import { TalkMessageDocumentImage } from "@mgdx/talk/models";
import React, { useMemo } from "react";
import { Img } from "react-image";

import * as styles from "./TalkMessageImage.module.css";

const containerWidth = 192;
const containerHeight = 254;

export type TalkMessageImageProps = {
  image: TalkMessageDocumentImage;
};

export const TalkMessageImage: React.FC<TalkMessageImageProps> = ({ image }) => {
  const { width, height } = useMemo(() => {
    const imageRatio = image.width / image.height;
    const containerRatio = containerWidth / containerHeight;

    if (imageRatio > containerRatio) {
      return {
        width: containerWidth,
        height: containerWidth / imageRatio,
      };
    }

    return {
      width: containerHeight * imageRatio,
      height: containerHeight,
    };
  }, [image.height, image.width]);

  return (
    <div style={{ minHeight: height, minWidth: width }}>
      <Img src={image.url} alt={image.name} className={styles.image} width={width} height={height} />
    </div>
  );
};
