/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutBillingRequestBodyTypename = "PutBillingRequestBody" as const;

/**
 *
 * @export
 * @interface PutBillingRequestBody
 */
export interface PutBillingRequestBody {
  /**
   *
   * @type {number}
   * @memberof PutBillingRequestBody
   */
  amount?: number;
  /**
   *
   * @type {number}
   * @memberof PutBillingRequestBody
   */
  deliveryCost?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof PutBillingRequestBody
   */
  images?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PutBillingRequestBody
   */
  insuranceMedicalExpense?: number;
  /**
   *
   * @type {number}
   * @memberof PutBillingRequestBody
   */
  ownExpense?: number;
  /**
   *
   * @type {string}
   * @memberof PutBillingRequestBody
   */
  paymentMethod: PutBillingRequestBodyPaymentMethodEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutBillingRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PutBillingRequestBodyPaymentMethodEnum {
  Counter = "counter",
  Online = "online",
  MedicalSupport = "medical_support",
}

export function PutBillingRequestBodyFromJSON(json: any): PutBillingRequestBody {
  return PutBillingRequestBodyFromJSONTyped(json, false);
}

export function PutBillingRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutBillingRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutBillingRequestBodyTypename,

    amount: !exists(json, "amount") ? undefined : json["amount"],
    deliveryCost: !exists(json, "delivery_cost") ? undefined : json["delivery_cost"],
    images: !exists(json, "images") ? undefined : json["images"],
    insuranceMedicalExpense: !exists(json, "insurance_medical_expense") ? undefined : json["insurance_medical_expense"],
    ownExpense: !exists(json, "own_expense") ? undefined : json["own_expense"],
    paymentMethod: json["payment_method"],
  };
}

export function PutBillingRequestBodyToJSON(object?: PutBillingRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    amount: value.amount,
    delivery_cost: value.deliveryCost,
    images: value.images,
    insurance_medical_expense: value.insuranceMedicalExpense,
    own_expense: value.ownExpense,
    payment_method: value.paymentMethod,
  };
}
