/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutReceiptCodesRequestBodyTypename = "PutReceiptCodesRequestBody" as const;

/**
 *
 * @export
 * @interface PutReceiptCodesRequestBody
 */
export interface PutReceiptCodesRequestBody {
  /**
   *
   * @type {number}
   * @memberof PutReceiptCodesRequestBody
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof PutReceiptCodesRequestBody
   */
  receiptCode: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutReceiptCodesRequestBody
   */
  readonly __typename?: string;
}

export function PutReceiptCodesRequestBodyFromJSON(json: any): PutReceiptCodesRequestBody {
  return PutReceiptCodesRequestBodyFromJSONTyped(json, false);
}

export function PutReceiptCodesRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutReceiptCodesRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutReceiptCodesRequestBodyTypename,

    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    receiptCode: json["receipt_code"],
  };
}

export function PutReceiptCodesRequestBodyToJSON(object?: PutReceiptCodesRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    family_account_id: value.familyAccountId,
    receipt_code: value.receiptCode,
  };
}
