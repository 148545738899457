// extracted by mini-css-extract-plugin
export var caution = "MessagePlain-module--caution--20a37";
export var fontBold = "MessagePlain-module--fontBold--96cd6";
export var fontNormal = "MessagePlain-module--fontNormal--69c1d";
export var info = "MessagePlain-module--info--f3226";
export var message = "MessagePlain-module--message--2cfa4";
export var notice = "MessagePlain-module--notice--1b109";
export var responsive = "MessagePlain-module--responsive--6e5ca";
export var success = "MessagePlain-module--success--1425f";
export var textCenter = "MessagePlain-module--textCenter--cce24";
export var textLeft = "MessagePlain-module--textLeft--edec1";
export var textRight = "MessagePlain-module--textRight--1a68b";
export var weak = "MessagePlain-module--weak--4fe6e";
export var withPaddingBottom = "MessagePlain-module--withPaddingBottom--0c2b7";