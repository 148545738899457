/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacistTypename = "PharmacyPharmacist" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacist
 */
export interface PharmacyPharmacist {
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacist
   */
  chainId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacist
   */
  chainName: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacist
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacist
   */
  firstName: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacist
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyPharmacist
   */
  initialPasswordChanged?: boolean;
  /**
   *
   * @type {Date}
   * @memberof PharmacyPharmacist
   */
  lastActivityAt?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacist
   */
  lastName: string;
  /**
   *
   * @type {Date}
   * @memberof PharmacyPharmacist
   */
  passwordUpdatedAt: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacist
   */
  pharmacyEmail: string;
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacist
   */
  pharmacyId: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyPharmacist
   */
  pharmacyName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacist
   */
  readonly __typename?: string;
}

export function PharmacyPharmacistFromJSON(json: any): PharmacyPharmacist {
  return PharmacyPharmacistFromJSONTyped(json, false);
}

export function PharmacyPharmacistFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPharmacist {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacistTypename,

    chainId: json["chain_id"],
    chainName: json["chain_name"],
    email: json["email"],
    firstName: json["first_name"],
    id: json["id"],
    initialPasswordChanged: !exists(json, "initial_password_changed") ? undefined : json["initial_password_changed"],
    lastActivityAt:
      !exists(json, "last_activity_at") ||
      (typeof json["last_activity_at"] === "string" && json["last_activity_at"].substring(0, 4) === "0001")
        ? undefined
        : json["last_activity_at"],
    lastName: json["last_name"],
    passwordUpdatedAt: json["password_updated_at"],
    pharmacyEmail: json["pharmacy_email"],
    pharmacyId: json["pharmacy_id"],
    pharmacyName: json["pharmacy_name"],
  };
}

export function PharmacyPharmacistToJSON(object?: PharmacyPharmacist | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    chain_id: value.chainId,
    chain_name: value.chainName,
    email: value.email,
    first_name: value.firstName,
    id: value.id,
    initial_password_changed: value.initialPasswordChanged,
    last_activity_at:
      value.lastActivityAt === undefined ? undefined : moment(value.lastActivityAt).tz("Asia/Tokyo").format(),
    last_name: value.lastName,
    password_updated_at: moment(value.passwordUpdatedAt).tz("Asia/Tokyo").format(),
    pharmacy_email: value.pharmacyEmail,
    pharmacy_id: value.pharmacyId,
    pharmacy_name: value.pharmacyName,
  };
}
