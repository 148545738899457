/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  AdminPostBulkEmail,
  AdminPostBulkEmailFromJSON,
  AdminPostBulkEmailToJSON,
  PostBulkEmailResponse,
  PostBulkEmailResponseFromJSON,
  PostBulkEmailResponseToJSON,
} from "../models";

export interface PostBulkEmailRequest {
  adminPostBulkEmail: AdminPostBulkEmail;
}

/**
 *
 */
export class BulkEmailApi extends runtime.BaseAPI {
  /**
   * send bulk email
   */
  async postBulkEmailRaw(
    requestParameters: PostBulkEmailRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PostBulkEmailResponse>> {
    if (requestParameters.adminPostBulkEmail === null || requestParameters.adminPostBulkEmail === undefined) {
      throw new runtime.RequiredError(
        "adminPostBulkEmail",
        "Required parameter requestParameters.adminPostBulkEmail was null or undefined when calling postBulkEmail."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/bulk-email`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: AdminPostBulkEmailToJSON(requestParameters.adminPostBulkEmail),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PostBulkEmailResponseFromJSON(jsonValue));
  }

  /**
   * send bulk email
   */
  async postBulkEmail(
    requestParameters: PostBulkEmailRequest,
    initOverrides?: RequestInit
  ): Promise<PostBulkEmailResponse> {
    return promiseRetry(
      (retry) =>
        this.postBulkEmailRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
