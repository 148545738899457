import React from "react";

import { ReceivedMessage, ReceivedMessageProps } from "./ReceivedMessage";
import { SentMessage, SentMessageProps } from "./SentMessage";

export enum MessageTypeEnum {
  Sent = "sent",
  Received = "received",
}

export type Props = SentMessageProps &
  ReceivedMessageProps & {
    messageType: MessageTypeEnum;
  };

const TalkMessage = ({ messageType, message, isUnread, isFirstMessage, isLastMessage }: Props) => {
  if (messageType === MessageTypeEnum.Sent) {
    return (
      <SentMessage
        message={message}
        isFirstMessage={isFirstMessage}
        isLastMessage={isLastMessage}
        isUnread={isUnread}
      />
    );
  }

  if (messageType === MessageTypeEnum.Received) {
    return <ReceivedMessage message={message} isFirstMessage={isFirstMessage} isLastMessage={isLastMessage} />;
  }

  return null;
};

export default TalkMessage;
