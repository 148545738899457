/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyFamilyPharmacistLicense,
  PharmacyFamilyPharmacistLicenseFromJSON,
  PharmacyFamilyPharmacistLicenseFromJSONTyped,
  PharmacyFamilyPharmacistLicenseToJSON,
  PharmacyFamilyPharmacistLink,
  PharmacyFamilyPharmacistLinkFromJSON,
  PharmacyFamilyPharmacistLinkFromJSONTyped,
  PharmacyFamilyPharmacistLinkToJSON,
  PharmacyRequestFamilyPharmacistImage,
  PharmacyRequestFamilyPharmacistImageFromJSON,
  PharmacyRequestFamilyPharmacistImageFromJSONTyped,
  PharmacyRequestFamilyPharmacistImageToJSON,
} from "./";

export const PatchFamilyPharmacistRequestBodyTypename = "PatchFamilyPharmacistRequestBody" as const;

/**
 *
 * @export
 * @interface PatchFamilyPharmacistRequestBody
 */
export interface PatchFamilyPharmacistRequestBody {
  /**
   *
   * @type {string}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  aboutMyself?: string;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  firstName?: string;
  /**
   *
   * @type {PharmacyRequestFamilyPharmacistImage}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  image?: PharmacyRequestFamilyPharmacistImage;
  /**
   *
   * @type {boolean}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  isPublic?: boolean;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  lastName?: string;
  /**
   *
   * @type {PharmacyFamilyPharmacistLicense}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  license?: PharmacyFamilyPharmacistLicense;
  /**
   *
   * @type {Array<PharmacyFamilyPharmacistLink>}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  links?: Array<PharmacyFamilyPharmacistLink>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchFamilyPharmacistRequestBody
   */
  readonly __typename?: string;
}

export function PatchFamilyPharmacistRequestBodyFromJSON(json: any): PatchFamilyPharmacistRequestBody {
  return PatchFamilyPharmacistRequestBodyFromJSONTyped(json, false);
}

export function PatchFamilyPharmacistRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchFamilyPharmacistRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchFamilyPharmacistRequestBodyTypename,

    aboutMyself: !exists(json, "about_myself") ? undefined : json["about_myself"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    image: !exists(json, "image") ? undefined : PharmacyRequestFamilyPharmacistImageFromJSON(json["image"]),
    isPublic: !exists(json, "is_public") ? undefined : json["is_public"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    license: !exists(json, "license") ? undefined : PharmacyFamilyPharmacistLicenseFromJSON(json["license"]),
    links: !exists(json, "links") ? undefined : (json["links"] as Array<any>).map(PharmacyFamilyPharmacistLinkFromJSON),
  };
}

export function PatchFamilyPharmacistRequestBodyToJSON(object?: PatchFamilyPharmacistRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    about_myself: value.aboutMyself,
    first_name: value.firstName,
    image: PharmacyRequestFamilyPharmacistImageToJSON(value.image),
    is_public: value.isPublic,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    license: PharmacyFamilyPharmacistLicenseToJSON(value.license),
    links: value.links === undefined ? undefined : (value.links as Array<any>).map(PharmacyFamilyPharmacistLinkToJSON),
  };
}
