/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyCounselorMedicineNotebookAutoLink,
  PharmacyCounselorMedicineNotebookAutoLinkFromJSON,
  PharmacyCounselorMedicineNotebookAutoLinkFromJSONTyped,
  PharmacyCounselorMedicineNotebookAutoLinkToJSON,
} from "./";

export const PharmacyCounselorMedicineNotebookAutoLinksTypename = "PharmacyCounselorMedicineNotebookAutoLinks" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorMedicineNotebookAutoLinks
 */
export interface PharmacyCounselorMedicineNotebookAutoLinks {
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinks
   */
  count?: number;
  /**
   *
   * @type {Array<PharmacyCounselorMedicineNotebookAutoLink>}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinks
   */
  medicineNotebookAutoLinks: Array<PharmacyCounselorMedicineNotebookAutoLink>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorMedicineNotebookAutoLinks
   */
  readonly __typename?: string;
}

export function PharmacyCounselorMedicineNotebookAutoLinksFromJSON(
  json: any
): PharmacyCounselorMedicineNotebookAutoLinks {
  return PharmacyCounselorMedicineNotebookAutoLinksFromJSONTyped(json, false);
}

export function PharmacyCounselorMedicineNotebookAutoLinksFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorMedicineNotebookAutoLinks {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorMedicineNotebookAutoLinksTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    medicineNotebookAutoLinks: (json["medicine_notebook_auto_links"] as Array<any>).map(
      PharmacyCounselorMedicineNotebookAutoLinkFromJSON
    ),
  };
}

export function PharmacyCounselorMedicineNotebookAutoLinksToJSON(
  object?: PharmacyCounselorMedicineNotebookAutoLinks | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    medicine_notebook_auto_links: (value.medicineNotebookAutoLinks as Array<any>).map(
      PharmacyCounselorMedicineNotebookAutoLinkToJSON
    ),
  };
}
