import { User } from "@firebase/auth";
import patientApi from "@mgdx/api/lib/patientApi";
import { PatientProviderTypeEnum, PatientUpdateStatusEnum } from "@mgdx/shared/src/models/Patient";
import { PatientStatusEnum } from "@mgdx/shared/src/models/Patient";
import { disableTalk } from "@mgdx/talk/ducks";
import Preloader from "@mgdx/ui/components/Preloader";
import { apiErrorHandler, errorHandlerReport, firebaseErrorHandler, isFirebaseError } from "@mgdx-libs/error-handler";
import {
  clearAccessToken,
  FirebaseAuthObserver,
  getFirebaseAuth,
  setAccessToken,
  useInitializeFirebaseAuth,
} from "@mgdx-libs/firebase";
import { navigate } from "@mgdx-libs/link";
import { logger } from "@mgdx-libs/logger";
import { clearPersist } from "@mgdx-libs/redux-persist-ssr";
import { useLocation } from "@reach/router";
import { abortRequests, clearRequestsCache, resetRequests } from "@redux-requests/core";
import { EnhancedStore } from "@reduxjs/toolkit";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { Dispatch } from "redux";

import { clearCurrentUser, setCurrentUserWithDispatch } from "./ducks/currentUser";
import { getInProcessOfOIDCSignup, setInProcessOfOIDCSignup } from "./hooks/useInProcessOfOIDCSignup";
import { canUseAppBridge } from "./utils/app-bridge/canUseAppBridge";
import { getAppNetUserIdFromApp } from "./utils/app-bridge/getFromApp";
import { callShowBackButtonInApp } from "./utils/app-bridge/publisher";
import {
  clearTokenAndState,
  getPatientFromFirebaseUserAndSetToStorage,
  signoutAndClearToken,
  updatePatientToEmailVerifiedAndSetToStore,
} from "./utils/user";
import { isInDaifukuApp } from "./utils/user-agent/isInDaifukuApp";

const firebaseAuthObserver = async (dispatch: Dispatch, firebaseUser: User | null) => {
  if (firebaseUser) {
    logger.debug("firebase.User: %o", firebaseUser);
    const result = await firebaseUser.getIdTokenResult().catch(async (tokenError) => {
      if (isFirebaseError(tokenError)) {
        try {
          firebaseErrorHandler(tokenError);
        } catch (e) {
          await errorHandlerReport(e as Error);
        }
      } else {
        await errorHandlerReport(tokenError);
      }
      return undefined;
    });

    if (result === undefined) {
      await navigate("/sign-out/", { replace: true });
      return;
    }

    if (result.claims.role !== "patient") {
      logger.debug("firebase.User: different role");
      return;
    }

    logger.debug("getIdTokenResult: %o", result);
    setAccessToken(result.token);

    logger.debug("firebase.user.emailVerified: %s", firebaseUser.emailVerified);
    const patient = await patientApi.getCurrentPatient().catch(async (errorOrResponse) => {
      if (errorOrResponse instanceof Response) {
        await getFirebaseAuth().signOut();
        dispatch(disableTalk());
        dispatch(abortRequests());
        dispatch(clearRequestsCache());
        dispatch(resetRequests());
        dispatch(clearCurrentUser());
        clearAccessToken();
        clearPersist();
        try {
          throw await apiErrorHandler(errorOrResponse);
        } catch (e) {
          await errorHandlerReport(e);
        }
      } else {
        if (errorOrResponse instanceof DOMException) {
          try {
            throw await apiErrorHandler(errorOrResponse);
          } catch (e) {
            await errorHandlerReport(e as Error);
          }
        } else if (errorOrResponse instanceof Error) {
          await errorHandlerReport(errorOrResponse);
        }
      }

      return undefined;
    });

    if (!patient) return;

    setCurrentUserWithDispatch(dispatch, patient);

    const isEmailVerifiedStatus = patient.status === PatientStatusEnum.Verified;
    logger.debug("patient.status.emailVerified: %s", isEmailVerifiedStatus);

    if (
      firebaseUser.emailVerified !== isEmailVerifiedStatus &&
      patient.loginProviderType === PatientProviderTypeEnum.Email
    ) {
      if (firebaseUser.emailVerified) {
        logger.debug("update patient.status: %s", PatientUpdateStatusEnum.Verified);
        await patientApi
          .putPatientStatus({
            putPatientStatusRequestBody: {
              status: PatientUpdateStatusEnum.Verified,
            },
          })
          .then((patient) => {
            setCurrentUserWithDispatch(dispatch, patient);
          })
          .catch(async (errorOrResponse) => {
            if (errorOrResponse instanceof Response) {
              try {
                throw await apiErrorHandler(errorOrResponse);
              } catch (e) {
                await errorHandlerReport(e as Error);
              }
            } else if (errorOrResponse instanceof Error) {
              await errorHandlerReport(errorOrResponse);
            }
          });
      }
    }
  } else {
    dispatch(disableTalk());
    dispatch(clearCurrentUser());
    clearAccessToken();
    clearPersist();
  }
};

// memo: firebaseAuthObserverと同様の処理を行っている
const firebaseAuthWithEmail = async (dispatch: Dispatch, firebaseUser: User | null) => {
  logger.debug("observer's auth type: email");

  if (!firebaseUser) {
    clearTokenAndState(dispatch);
    clearPersist();
    return;
  }

  const patient = await getPatientFromFirebaseUserAndSetToStorage(firebaseUser, dispatch).catch(async (error) => {
    logger.error(error);
    await signoutAndClearToken(dispatch);
    return undefined;
  });

  if (!patient) {
    navigate("/sign-in");
    return;
  }

  logger.debug("firebase.user.emailVerified: %s", firebaseUser.emailVerified);

  const isPatientEmailVerifield = patient.status === PatientStatusEnum.Verified;
  logger.debug("patient.status.emailVerified: %s", isPatientEmailVerifield);

  if (patient.loginProviderType !== PatientProviderTypeEnum.Email) return;

  if (firebaseUser.emailVerified && !isPatientEmailVerifield) {
    logger.debug("update patient.status: %s", PatientUpdateStatusEnum.Verified);
    await updatePatientToEmailVerifiedAndSetToStore(dispatch).catch(() => {
      return;
    });
  }
};

const firebaseAuthWithMccmOidc = async (dispatch: Dispatch, firebaseUser: User | null) => {
  logger.debug("observer's auth type: mccm oidc");
  const redirectUrl = window.location.href;

  const redirectAndStartOIDCAuth = async () => {
    setInProcessOfOIDCSignup(true);
    await navigate("/oidc/matsukiyococokara/redirect/?redirect_url=" + encodeURIComponent(redirectUrl), {
      replace: true,
    });
  };

  if (!firebaseUser) {
    clearTokenAndState(dispatch);
    clearPersist();
    await redirectAndStartOIDCAuth();

    return;
  }

  const patient = await getPatientFromFirebaseUserAndSetToStorage(firebaseUser, dispatch).catch(
    async (error: Error) => {
      logger.error(error);
      await signoutAndClearToken(dispatch);
      return undefined;
    }
  );

  if (!patient) {
    redirectAndStartOIDCAuth();
    return;
  }

  const yqbNetUserId = firebaseUser.uid.match(/oidc:matsukiyococokara-yqb:(\w+)/)?.[1];

  if (canUseAppBridge()) {
    getAppNetUserIdFromApp(async ({ netUserId }) => {
      if (netUserId !== yqbNetUserId) {
        redirectAndStartOIDCAuth();
      } else {
        callShowBackButtonInApp();
      }
    });
  } else {
    logger.error("unexpected: can't use app bridge in daifuku app");
    logger.debug("yqbNetUserId: %s", yqbNetUserId);
    return;
  }
};

const authHandler = async (dispatch: Dispatch, firebaseUser: User | null) => {
  switch (process.env.MGDX_APP_NAME) {
    case "matsukiyococokara-yqb":
      if (isInDaifukuApp()) {
        await firebaseAuthWithMccmOidc(dispatch, firebaseUser);
        break;
        // await firebaseAuthWithEmail(dispatch, firebaseUser);
      } else {
        await firebaseAuthWithEmail(dispatch, firebaseUser);
        break;
        // return firebaseAuthWithMccmOidc(dispatch, firebaseUser, () => setIsInitialAuthCompleted(true));
      }
    default:
      await firebaseAuthObserver(dispatch, firebaseUser);
  }
};

const authSkipPathList = ["/oidc/matsukiyococokara", "/debug/skip-auth", "/sign-out"];

export const AuthProvider: React.FC<PropsWithChildren & { store: EnhancedStore }> = ({ children, store }) => {
  const location = useLocation();

  const [isInitialAuthCheckCompleted, setIsInitialAuthCheckCompleted] = useState<boolean>(false);

  const isInSkipAuthPage = authSkipPathList.some((path) => location.pathname.startsWith(path));
  const shouldSkipAuth = isInSkipAuthPage || (getInProcessOfOIDCSignup() && isInDaifukuApp());
  const isBeforeRedirectToOIDCAuthPage = !isInSkipAuthPage && getInProcessOfOIDCSignup() && isInDaifukuApp();

  const onChangeFirebaseAuth: FirebaseAuthObserver = useCallback(
    async (firebaseUser) => {
      if (shouldSkipAuth) return;

      const state = await store.getState();
      if (state.currentUser.inProcessOfSignup) return;

      const dispatch = store.dispatch;
      await authHandler(dispatch, firebaseUser);
    },
    [shouldSkipAuth, store]
  );

  useInitializeFirebaseAuth(async (firebaseUser) => {
    onChangeFirebaseAuth(firebaseUser);
    setIsInitialAuthCheckCompleted(true);
  });

  return process.env.MGDX_APP_NAME !== "matsukiyococokara-yqb" ||
    isInSkipAuthPage ||
    (isInitialAuthCheckCompleted && !isBeforeRedirectToOIDCAuthPage) ? (
    <>{children}</>
  ) : (
    <Preloader loading={true} variant="center" />
  );
};
