import { persistStore } from "@mgdx-libs/redux-persist-ssr";
import { configureStore as toolkitConfigureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";

import { requestsMiddleware } from "./ducks/requests";
import rootReducer, { RootState } from "./ducks/root";

const configureStore = () => {
  const store = toolkitConfigureStore<RootState>({
    reducer: rootReducer,
    middleware: [thunkMiddleware, ...requestsMiddleware] as any,
    devTools: true,
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
