/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Chatter API
 * API for Chatter
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ChatterVideoChatPatient,
  ChatterVideoChatPatientFromJSON,
  ChatterVideoChatPatientFromJSONTyped,
  ChatterVideoChatPatientToJSON,
} from "./";

export const PostVideoChatRequestBodyTypename = "PostVideoChatRequestBody" as const;

/**
 *
 * @export
 * @interface PostVideoChatRequestBody
 */
export interface PostVideoChatRequestBody {
  /**
   *
   * @type {number}
   * @memberof PostVideoChatRequestBody
   */
  clinicId?: number;
  /**
   *
   * @type {number}
   * @memberof PostVideoChatRequestBody
   */
  counselingId?: number;
  /**
   *
   * @type {Date}
   * @memberof PostVideoChatRequestBody
   */
  expiredTime: string;
  /**
   * notify registered to patient or not
   * @type {boolean}
   * @memberof PostVideoChatRequestBody
   */
  notify?: boolean;
  /**
   *
   * @type {ChatterVideoChatPatient}
   * @memberof PostVideoChatRequestBody
   */
  patient: ChatterVideoChatPatient;
  /**
   *
   * @type {number}
   * @memberof PostVideoChatRequestBody
   */
  pharmacyId?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostVideoChatRequestBody
   */
  readonly __typename?: string;
}

export function PostVideoChatRequestBodyFromJSON(json: any): PostVideoChatRequestBody {
  return PostVideoChatRequestBodyFromJSONTyped(json, false);
}

export function PostVideoChatRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostVideoChatRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostVideoChatRequestBodyTypename,

    clinicId: !exists(json, "clinic_id") ? undefined : json["clinic_id"],
    counselingId: !exists(json, "counseling_id") ? undefined : json["counseling_id"],
    expiredTime: json["expired_time"],
    notify: !exists(json, "notify") ? undefined : json["notify"],
    patient: ChatterVideoChatPatientFromJSON(json["patient"]),
    pharmacyId: !exists(json, "pharmacy_id") ? undefined : json["pharmacy_id"],
  };
}

export function PostVideoChatRequestBodyToJSON(object?: PostVideoChatRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    clinic_id: value.clinicId,
    counseling_id: value.counselingId,
    expired_time: moment(value.expiredTime).tz("Asia/Tokyo").format(),
    notify: value.notify,
    patient: ChatterVideoChatPatientToJSON(value.patient),
    pharmacy_id: value.pharmacyId,
  };
}
