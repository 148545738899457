/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorCounselingRequestFollowUpTypename = "PharmacyCounselorCounselingRequestFollowUp" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingRequestFollowUp
 */
export interface PharmacyCounselorCounselingRequestFollowUp {
  /**
   *
   * @type {boolean}
   * @memberof PharmacyCounselorCounselingRequestFollowUp
   */
  isNotifyAnswered?: boolean;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingRequestFollowUp
   */
  notificationPatternId?: number;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingRequestFollowUp
   */
  questionnairePatternId?: number;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingRequestFollowUp
   */
  readonly __typename?: string;
}

export function PharmacyCounselorCounselingRequestFollowUpFromJSON(
  json: any
): PharmacyCounselorCounselingRequestFollowUp {
  return PharmacyCounselorCounselingRequestFollowUpFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingRequestFollowUpFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingRequestFollowUp {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingRequestFollowUpTypename,

    isNotifyAnswered: !exists(json, "is_notify_answered") ? undefined : json["is_notify_answered"],
    notificationPatternId: !exists(json, "notification_pattern_id") ? undefined : json["notification_pattern_id"],
    questionnairePatternId: !exists(json, "questionnaire_pattern_id") ? undefined : json["questionnaire_pattern_id"],
  };
}

export function PharmacyCounselorCounselingRequestFollowUpToJSON(
  object?: PharmacyCounselorCounselingRequestFollowUp | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    is_notify_answered: value.isNotifyAnswered,
    notification_pattern_id: value.notificationPatternId,
    questionnaire_pattern_id: value.questionnairePatternId,
  };
}
