/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatchAcceptCodeRequestBodyTypename = "PatchAcceptCodeRequestBody" as const;

/**
 *
 * @export
 * @interface PatchAcceptCodeRequestBody
 */
export interface PatchAcceptCodeRequestBody {
  /**
   *
   * @type {boolean}
   * @memberof PatchAcceptCodeRequestBody
   */
  isVisible?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchAcceptCodeRequestBody
   */
  readonly __typename?: string;
}

export function PatchAcceptCodeRequestBodyFromJSON(json: any): PatchAcceptCodeRequestBody {
  return PatchAcceptCodeRequestBodyFromJSONTyped(json, false);
}

export function PatchAcceptCodeRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchAcceptCodeRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchAcceptCodeRequestBodyTypename,

    isVisible: !exists(json, "is_visible") ? undefined : json["is_visible"],
  };
}

export function PatchAcceptCodeRequestBodyToJSON(object?: PatchAcceptCodeRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    is_visible: value.isVisible,
  };
}
