/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";

export interface DeleteDepartmentCounselingRequest {
  clinicId: number;
  departmentId: number;
  date: string;
  slotNumber: number;
}

export interface PostDepartmentCounselingRequest {
  clinicId: number;
  departmentId: number;
  date: string;
  slotNumber: number;
}

/**
 *
 */
export class CounselingApi extends runtime.BaseAPI {
  /**
   * Delete a Clinic Counseling
   */
  async deleteDepartmentCounselingRaw(
    requestParameters: DeleteDepartmentCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling deleteDepartmentCounseling."
      );
    }

    if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
      throw new runtime.RequiredError(
        "departmentId",
        "Required parameter requestParameters.departmentId was null or undefined when calling deleteDepartmentCounseling."
      );
    }

    if (requestParameters.date === null || requestParameters.date === undefined) {
      throw new runtime.RequiredError(
        "date",
        "Required parameter requestParameters.date was null or undefined when calling deleteDepartmentCounseling."
      );
    }

    if (requestParameters.slotNumber === null || requestParameters.slotNumber === undefined) {
      throw new runtime.RequiredError(
        "slotNumber",
        "Required parameter requestParameters.slotNumber was null or undefined when calling deleteDepartmentCounseling."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments/{department_id}/{date}/{slot_number}/counselings`
          .replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId)))
          .replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId)))
          .replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date)))
          .replace(`{${"slot_number"}}`, encodeURIComponent(String(requestParameters.slotNumber))),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete a Clinic Counseling
   */
  async deleteDepartmentCounseling(
    requestParameters: DeleteDepartmentCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.deleteDepartmentCounselingRaw(requestParameters, initOverrides);
  }

  /**
   * Register a Clinic Counseling
   */
  async postDepartmentCounselingRaw(
    requestParameters: PostDepartmentCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling postDepartmentCounseling."
      );
    }

    if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
      throw new runtime.RequiredError(
        "departmentId",
        "Required parameter requestParameters.departmentId was null or undefined when calling postDepartmentCounseling."
      );
    }

    if (requestParameters.date === null || requestParameters.date === undefined) {
      throw new runtime.RequiredError(
        "date",
        "Required parameter requestParameters.date was null or undefined when calling postDepartmentCounseling."
      );
    }

    if (requestParameters.slotNumber === null || requestParameters.slotNumber === undefined) {
      throw new runtime.RequiredError(
        "slotNumber",
        "Required parameter requestParameters.slotNumber was null or undefined when calling postDepartmentCounseling."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments/{department_id}/{date}/{slot_number}/counselings`
          .replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId)))
          .replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId)))
          .replace(`{${"date"}}`, encodeURIComponent(String(requestParameters.date)))
          .replace(`{${"slot_number"}}`, encodeURIComponent(String(requestParameters.slotNumber))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Register a Clinic Counseling
   */
  async postDepartmentCounseling(
    requestParameters: PostDepartmentCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.postDepartmentCounselingRaw(requestParameters, initOverrides);
  }
}
