/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { PharmacyOperator, PharmacyOperatorFromJSON, PharmacyOperatorFromJSONTyped, PharmacyOperatorToJSON } from "./";

export const PharmacyOperatorsTypename = "PharmacyOperators" as const;

/**
 *
 * @export
 * @interface PharmacyOperators
 */
export interface PharmacyOperators {
  /**
   *
   * @type {number}
   * @memberof PharmacyOperators
   */
  count?: number;
  /**
   *
   * @type {Array<PharmacyOperator>}
   * @memberof PharmacyOperators
   */
  operators: Array<PharmacyOperator>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyOperators
   */
  readonly __typename?: string;
}

export function PharmacyOperatorsFromJSON(json: any): PharmacyOperators {
  return PharmacyOperatorsFromJSONTyped(json, false);
}

export function PharmacyOperatorsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyOperators {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyOperatorsTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    operators: (json["operators"] as Array<any>).map(PharmacyOperatorFromJSON),
  };
}

export function PharmacyOperatorsToJSON(object?: PharmacyOperators | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    operators: (value.operators as Array<any>).map(PharmacyOperatorToJSON),
  };
}
