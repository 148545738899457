/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { DeliveryAddress, DeliveryAddressFromJSON, DeliveryAddressFromJSONTyped, DeliveryAddressToJSON } from "./";

export const DeliveryEstimationRegistrableTypename = "DeliveryEstimationRegistrable" as const;

/**
 *
 * @export
 * @interface DeliveryEstimationRegistrable
 */
export interface DeliveryEstimationRegistrable {
  /**
   *
   * @type {DeliveryAddress}
   * @memberof DeliveryEstimationRegistrable
   */
  to: DeliveryAddress;
  /**
   * Resource type name
   * @type {string}
   * @memberof DeliveryEstimationRegistrable
   */
  readonly __typename?: string;
}

export function DeliveryEstimationRegistrableFromJSON(json: any): DeliveryEstimationRegistrable {
  return DeliveryEstimationRegistrableFromJSONTyped(json, false);
}

export function DeliveryEstimationRegistrableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DeliveryEstimationRegistrable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: DeliveryEstimationRegistrableTypename,

    to: DeliveryAddressFromJSON(json["to"]),
  };
}

export function DeliveryEstimationRegistrableToJSON(object?: DeliveryEstimationRegistrable | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    to: DeliveryAddressToJSON(value.to),
  };
}
