import { AuthError } from "firebase/auth";

import { FirebaseAuthError } from "../errors/fireErrors";
import { createErrorHandler } from "./createErrorHandler";
import { isFirebaseError } from "./isFirebaseError";

export const firebaseErrorReport = (error: AuthError | any) => {
  const errorHandlerReport = createErrorHandler();

  // Other firebase errors
  if (isFirebaseError(error)) {
    errorHandlerReport(new FirebaseAuthError(error));
  } else {
    // Other errors
    errorHandlerReport(error as Error);
  }
};
