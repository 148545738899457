/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicCounselorTalkroomTypename = "ClinicCounselorTalkroom" as const;

/**
 *
 * @export
 * @interface ClinicCounselorTalkroom
 */
export interface ClinicCounselorTalkroom {
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorTalkroom
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorTalkroom
   */
  status: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorTalkroom
   */
  readonly __typename?: string;
}

export function ClinicCounselorTalkroomFromJSON(json: any): ClinicCounselorTalkroom {
  return ClinicCounselorTalkroomFromJSONTyped(json, false);
}

export function ClinicCounselorTalkroomFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicCounselorTalkroom {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorTalkroomTypename,

    id: json["id"],
    status: json["status"],
  };
}

export function ClinicCounselorTalkroomToJSON(object?: ClinicCounselorTalkroom | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    status: value.status,
  };
}
