import clsx from "clsx";
import React, { useCallback, useId, useRef, useState } from "react";

import { BaseInputMin, BaseInputMinProps } from "../core/BaseInputMin";
import { CommonInputWithMessageProps, InputFieldMessage } from "../InputFieldMessage";
import * as styles from "./InputTextareaFieldMin.module.css";

export type InputTextareaFieldMinProps = Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, "type" | "children"> &
  Pick<BaseInputMinProps, "disabled" | "completed" | "error"> &
  CommonInputWithMessageProps & {
    hasCounter?: boolean;
    maxCount?: number;
  };

export const InputTextareaFieldMin = React.forwardRef<HTMLTextAreaElement, InputTextareaFieldMinProps>(
  (
    {
      /* BaseInputMinProps */
      disabled,
      completed,
      error,
      /* CommonInputWithMessageProps */
      message,
      noMessageArea,
      /* my props */
      id,
      hasCounter,
      maxCount = 200,
      rows = 2,
      onChange,
      className,
      value,
      ...textareaProps
    },
    ref
  ) => {
    const controlId = useId();
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const [count, setCount] = useState(() => (value ? value.toString().length : 0));

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const text = event.target.value || "";

        setCount(text.length);
        onChange && onChange(event);

        const resetHeight = new Promise((resolve) => {
          if (textareaRef.current) {
            resolve((textareaRef.current.style.height = "auto"));
          }
        });

        resetHeight
          .then(() => {
            const scrollHeight = textareaRef.current?.scrollHeight;
            if (scrollHeight && textareaRef.current) {
              textareaRef.current.style.height = `${scrollHeight}px`;
            }
          })
          .catch(() => {});
      },
      [onChange]
    );

    return (
      <div className={clsx(styles.container, className)}>
        <BaseInputMin
          disabled={disabled}
          completed={completed}
          error={error}
          aria-describedby={!noMessageArea ? `describe-${id || controlId}` : undefined}
        >
          <textarea
            id={id || controlId}
            ref={(node) => {
              textareaRef.current = node;
              if (typeof ref === "function") {
                ref(node);
              } else if (ref) {
                ref.current = node;
              }
            }}
            disabled={disabled}
            onChange={handleChange}
            rows={rows}
            className={styles.textarea}
            value={value}
            aria-invalid={error || undefined}
            {...textareaProps}
          />
          {hasCounter && (
            <div className={clsx(styles.counter, count > maxCount && styles.caution)}>
              {count}/{maxCount}
            </div>
          )}
        </BaseInputMin>
        {!noMessageArea && (
          <InputFieldMessage id={`describe-${id || controlId}`} error={error} className={styles.message}>
            {message}
          </InputFieldMessage>
        )}
      </div>
    );
  }
);
