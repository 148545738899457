/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientMedicalHistoryTypename = "PatientMedicalHistory" as const;

/**
 *
 * @export
 * @interface PatientMedicalHistory
 */
export interface PatientMedicalHistory {
  /**
   *
   * @type {string}
   * @memberof PatientMedicalHistory
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicalHistory
   */
  occurredOn: string;
  /**
   *
   * @type {string}
   * @memberof PatientMedicalHistory
   */
  status: PatientMedicalHistoryStatusEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientMedicalHistory
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientMedicalHistoryStatusEnum {
  Attending = "attending",
  Healed = "healed",
}

export function PatientMedicalHistoryFromJSON(json: any): PatientMedicalHistory {
  return PatientMedicalHistoryFromJSONTyped(json, false);
}

export function PatientMedicalHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatientMedicalHistory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientMedicalHistoryTypename,

    name: json["name"],
    occurredOn: json["occurred_on"],
    status: json["status"],
  };
}

export function PatientMedicalHistoryToJSON(object?: PatientMedicalHistory | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    name: value.name,
    occurred_on: value.occurredOn,
    status: value.status,
  };
}
