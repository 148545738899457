/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutDoctorPasswordRequestBodyTypename = "PutDoctorPasswordRequestBody" as const;

/**
 *
 * @export
 * @interface PutDoctorPasswordRequestBody
 */
export interface PutDoctorPasswordRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutDoctorPasswordRequestBody
   */
  password?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutDoctorPasswordRequestBody
   */
  readonly __typename?: string;
}

export function PutDoctorPasswordRequestBodyFromJSON(json: any): PutDoctorPasswordRequestBody {
  return PutDoctorPasswordRequestBodyFromJSONTyped(json, false);
}

export function PutDoctorPasswordRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutDoctorPasswordRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutDoctorPasswordRequestBodyTypename,

    password: !exists(json, "password") ? undefined : json["password"],
  };
}

export function PutDoctorPasswordRequestBodyToJSON(object?: PutDoctorPasswordRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    password: value.password,
  };
}
