/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorPrescriptionRegistrableTypename = "PharmacyCounselorPrescriptionRegistrable" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorPrescriptionRegistrable
 */
export interface PharmacyCounselorPrescriptionRegistrable {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPrescriptionRegistrable
   */
  exchangeNumber?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyCounselorPrescriptionRegistrable
   */
  images?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorPrescriptionRegistrable
   */
  type?: PharmacyCounselorPrescriptionRegistrableTypeEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorPrescriptionRegistrable
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorPrescriptionRegistrableTypeEnum {
  Paper = "paper",
  Digital = "digital",
}

export function PharmacyCounselorPrescriptionRegistrableFromJSON(json: any): PharmacyCounselorPrescriptionRegistrable {
  return PharmacyCounselorPrescriptionRegistrableFromJSONTyped(json, false);
}

export function PharmacyCounselorPrescriptionRegistrableFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorPrescriptionRegistrable {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorPrescriptionRegistrableTypename,

    exchangeNumber: !exists(json, "exchange_number") ? undefined : json["exchange_number"],
    images: !exists(json, "images") ? undefined : json["images"],
    type: !exists(json, "type") ? undefined : json["type"],
  };
}

export function PharmacyCounselorPrescriptionRegistrableToJSON(
  object?: PharmacyCounselorPrescriptionRegistrable | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    exchange_number: value.exchangeNumber,
    images: value.images,
    type: value.type,
  };
}
