import orderApi from "@mgdx/api/lib/orderApi";
import { GetOrderRequest } from "@mgdx/api/lib/platform/deliverer";
import { PlatformDelivery } from "@mgdx/shared/src/models/PlatformDelivery";
import { createRequestAction } from "@mgdx-libs/redux-requests";

export const findOrderById = createRequestAction<GetOrderRequest, PlatformDelivery>(
  "requests/platform/findOrderById",
  ({ orderId, requestToken }, meta) => ({
    request: {
      promise: orderApi.getOrder({
        orderId,
        requestToken,
      }),
    },
    meta: {
      requestKey: orderId,
      ...meta,
    },
  })
);
