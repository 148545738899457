import { FirestoreDataConverter } from "firebase/firestore";

import { TypeWithId } from "../types/TypeWithId";

export const createFirestoreDataConverter = <T>(): FirestoreDataConverter<TypeWithId<T>> => ({
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options) as T;

    return {
      id: snapshot.id,
      ...data,
    };
  },
  toFirestore: (modelObject) => modelObject,
});
