import loadable from "@loadable/component";
import hasLocalStorage from "@mgdx/shared/src/utils/hasLocalStorage";
import { noop } from "@mgdx/shared/src/utils/noop";
import { initializeLogger } from "@mgdx-libs/logger";
import React, { createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useRef, useState } from "react";

const VConsoleLoader = loadable(() => import("./VConsoleLoader"), {
  resolveComponent: (components) => components.VConsoleLoader,
});

type VConsoleContextValue = {
  toggleVConsole: () => void;
  enableVConsole: boolean;
};

const VConsoleContext = createContext<VConsoleContextValue>({
  toggleVConsole: noop,
  enableVConsole: false,
});

export type VConsoleProviderProps = PropsWithChildren<EmptyObject>;

export const VConsoleProvider: FC<VConsoleProviderProps> = ({ children }) => {
  const [enableVConsole, setEnableVConsole] = useState<boolean>(false);

  const timeoutIdRef = useRef<number | null>(null);
  const clickedCountRef = useRef<number>(0);

  const toggleVConsole = useCallback(() => {
    const isUpdateDebugPattern = process.env.ENABLE_DEBUG !== "true" && hasLocalStorage();

    if (enableVConsole) {
      setEnableVConsole(false);
      if (isUpdateDebugPattern) {
        localStorage.debug = null;
      }
    } else {
      setEnableVConsole(true);
      if (isUpdateDebugPattern) {
        localStorage.debug = "app:*";
      }
    }
  }, [enableVConsole]);

  const toggleVConsoleHandler = useCallback(() => {
    if (timeoutIdRef.current) {
      window.clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = null;
    }

    timeoutIdRef.current = window.setTimeout(() => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = null;
        clickedCountRef.current = 0;
      }
    }, 150);

    clickedCountRef.current += 1;

    if (clickedCountRef.current === 8) {
      toggleVConsole();
    }
  }, [toggleVConsole]);

  const value = useMemo<VConsoleContextValue>(
    () => ({
      toggleVConsole: toggleVConsoleHandler,
      enableVConsole,
    }),
    [enableVConsole, toggleVConsoleHandler]
  );

  return (
    <VConsoleContext.Provider value={value}>
      {enableVConsole && <VConsoleLoader onReady={initializeLogger} />}
      {children}
    </VConsoleContext.Provider>
  );
};

export const useVConsole = (): VConsoleContextValue => useContext(VConsoleContext);
