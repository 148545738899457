/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  ClinicCounselorPharmacyCounseling,
  ClinicCounselorPharmacyCounselingFromJSON,
  ClinicCounselorPharmacyCounselingToJSON,
} from "../models";

export interface PatchtPharmacyCounselingRequest {
  counselingId: number;
  clinicCounselorPharmacyCounseling: ClinicCounselorPharmacyCounseling;
}

/**
 *
 */
export class PharmacyCounselingApi extends runtime.BaseAPI {
  /**
   * Update phamracy request status
   */
  async patchtPharmacyCounselingRaw(
    requestParameters: PatchtPharmacyCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling patchtPharmacyCounseling."
      );
    }

    if (
      requestParameters.clinicCounselorPharmacyCounseling === null ||
      requestParameters.clinicCounselorPharmacyCounseling === undefined
    ) {
      throw new runtime.RequiredError(
        "clinicCounselorPharmacyCounseling",
        "Required parameter requestParameters.clinicCounselorPharmacyCounseling was null or undefined when calling patchtPharmacyCounseling."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/pharmacy-counseling`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: ClinicCounselorPharmacyCounselingToJSON(requestParameters.clinicCounselorPharmacyCounseling),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update phamracy request status
   */
  async patchtPharmacyCounseling(
    requestParameters: PatchtPharmacyCounselingRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.patchtPharmacyCounselingRaw(requestParameters, initOverrides);
  }
}
