/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyCounselorCounselingReportPatientTypename = "PharmacyCounselorCounselingReportPatient" as const;

/**
 *
 * @export
 * @interface PharmacyCounselorCounselingReportPatient
 */
export interface PharmacyCounselorCounselingReportPatient {
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  birthDay?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  dateOfBirth?: string;
  /**
   *
   * @type {object}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  externalId?: object;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  familyAccountId?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  gender?: PharmacyCounselorCounselingReportPatientGenderEnum;
  /**
   *
   * @type {number}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  kana?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  receiptCode?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyCounselorCounselingReportPatient
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PharmacyCounselorCounselingReportPatientGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PharmacyCounselorCounselingReportPatientFromJSON(json: any): PharmacyCounselorCounselingReportPatient {
  return PharmacyCounselorCounselingReportPatientFromJSONTyped(json, false);
}

export function PharmacyCounselorCounselingReportPatientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyCounselorCounselingReportPatient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyCounselorCounselingReportPatientTypename,

    birthDay: !exists(json, "birth_day") ? undefined : json["birth_day"],
    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    externalId: !exists(json, "external_id") ? undefined : json["external_id"],
    familyAccountId: !exists(json, "family_account_id") ? undefined : json["family_account_id"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    id: !exists(json, "id") ? undefined : json["id"],
    kana: !exists(json, "kana") ? undefined : json["kana"],
    name: !exists(json, "name") ? undefined : json["name"],
    receiptCode: !exists(json, "receipt_code") ? undefined : json["receipt_code"],
  };
}

export function PharmacyCounselorCounselingReportPatientToJSON(
  object?: PharmacyCounselorCounselingReportPatient | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    birth_day: value.birthDay,
    date_of_birth: value.dateOfBirth,
    external_id: value.externalId,
    family_account_id: value.familyAccountId,
    gender: value.gender,
    id: value.id,
    kana: value.kana,
    name: value.name,
    receipt_code: value.receiptCode,
  };
}
