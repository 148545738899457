/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyFollowUpQuestionnaireOptionTypename = "PharmacyFollowUpQuestionnaireOption" as const;

/**
 *
 * @export
 * @interface PharmacyFollowUpQuestionnaireOption
 */
export interface PharmacyFollowUpQuestionnaireOption {
  /**
   *
   * @type {number}
   * @memberof PharmacyFollowUpQuestionnaireOption
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof PharmacyFollowUpQuestionnaireOption
   */
  isFreeEntry?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyFollowUpQuestionnaireOption
   */
  optionName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyFollowUpQuestionnaireOption
   */
  readonly __typename?: string;
}

export function PharmacyFollowUpQuestionnaireOptionFromJSON(json: any): PharmacyFollowUpQuestionnaireOption {
  return PharmacyFollowUpQuestionnaireOptionFromJSONTyped(json, false);
}

export function PharmacyFollowUpQuestionnaireOptionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyFollowUpQuestionnaireOption {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyFollowUpQuestionnaireOptionTypename,

    id: json["id"],
    isFreeEntry: !exists(json, "is_free_entry") ? undefined : json["is_free_entry"],
    optionName: json["option_name"],
  };
}

export function PharmacyFollowUpQuestionnaireOptionToJSON(object?: PharmacyFollowUpQuestionnaireOption | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    id: value.id,
    is_free_entry: value.isFreeEntry,
    option_name: value.optionName,
  };
}
