import { ClinicCounselorExaminer, ClinicCounselorPatient } from "@mgdx/api/lib/clinic/counselor";
import { PostCounselingResponse } from "@mgdx/api/lib/pharmacy/counselor";
import { Clinic } from "@mgdx/shared/src/models/Clinic";
import { ClinicCounseling } from "@mgdx/shared/src/models/ClinicCounseling";
import { FamilyAccount, Patient } from "@mgdx/shared/src/models/Patient";
import { Pharmacy } from "@mgdx/shared/src/models/Pharmacy";
import { PharmacyCounseling } from "@mgdx/shared/src/models/PharmacyCounseling";
import { getFirebaseFirestore } from "@mgdx-libs/firebase";
import { logger } from "@mgdx-libs/logger";
import moment from "@mgdx-libs/moment";
import { collection, doc, writeBatch } from "firebase/firestore";
import { useCallback } from "react";

import { ROOM_COLLECTION_NAME } from "../constants";
import { TalkRoomDocument } from "../models";

export type TalkInitializeRoomDocumentArgs = {
  counseling?: PharmacyCounseling | ClinicCounseling | PostCounselingResponse;
  patient?: Patient | ClinicCounselorPatient;
  familyAccount?: ClinicCounselorExaminer | FamilyAccount;
  pharmacy?: Pharmacy;
  clinic?: Clinic;
};

export type TalkInitializeRoomDocument = (args: TalkInitializeRoomDocumentArgs) => Promise<void>;

export type UseTalkInitializeRoomDocument = () => TalkInitializeRoomDocument;

export const useTalkInitializeRoomDocument: UseTalkInitializeRoomDocument = () => {
  return useCallback<TalkInitializeRoomDocument>(async ({ counseling, patient, familyAccount, pharmacy, clinic }) => {
    const firebaseFirestore = getFirebaseFirestore();

    if (!firebaseFirestore) return;
    if (!counseling?.talkroom?.id) return;
    if (!patient) return;
    if (!clinic && !pharmacy) return;

    const roomId = counseling.talkroom.id;
    const now = moment();
    const batch = writeBatch(firebaseFirestore);
    const roomsRef = collection(firebaseFirestore, ROOM_COLLECTION_NAME);
    const roomRef = doc(roomsRef, roomId);

    const talkPatient = familyAccount
      ? {
          id: patient.id,
          firstName: familyAccount.firstName,
          lastName: familyAccount.lastName,
          kanaFirstName: familyAccount.kanaFirstName,
          kanaLastName: familyAccount.kanaLastName,
        }
      : {
          id: patient.id,
          firstName: patient.firstName,
          lastName: patient.lastName,
          kanaFirstName: patient.kanaFirstName ?? "",
          kanaLastName: patient.kanaLastName ?? "",
        };

    const talkRoom: Partial<TalkRoomDocument> = {
      createdAt: now.format(),
      updatedAt: now.format(),
      timestamp: now.unix(),
      patientUnreadMessageIds: [],
      patientUnreadMessageCount: 0,
      medicalUnreadMessageIds: [],
      medicalUnreadMessageCount: 0,
      patient: talkPatient,
      pharmacy: pharmacy
        ? {
            id: pharmacy.id,
            name: pharmacy.name || "",
          }
        : null,
      clinic: clinic
        ? {
            id: clinic.id,
            name: clinic.name || "",
          }
        : null,
    };

    logger.debug("Initialize talk room: %o", { roomId, talkRoom });

    batch.update(roomRef, talkRoom);

    await batch.commit();
  }, []);
};
