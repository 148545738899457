/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { PatientPatient, PatientPatientFromJSON, PatientPatientFromJSONTyped, PatientPatientToJSON } from "./";

export const PostLinkedPatientResponseTypename = "PostLinkedPatientResponse" as const;

/**
 *
 * @export
 * @interface PostLinkedPatientResponse
 */
export interface PostLinkedPatientResponse {
  /**
   *
   * @type {string}
   * @memberof PostLinkedPatientResponse
   */
  customToken?: string;
  /**
   *
   * @type {PatientPatient}
   * @memberof PostLinkedPatientResponse
   */
  patient?: PatientPatient;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostLinkedPatientResponse
   */
  readonly __typename?: string;
}

export function PostLinkedPatientResponseFromJSON(json: any): PostLinkedPatientResponse {
  return PostLinkedPatientResponseFromJSONTyped(json, false);
}

export function PostLinkedPatientResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostLinkedPatientResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostLinkedPatientResponseTypename,

    customToken: !exists(json, "custom_token") ? undefined : json["custom_token"],
    patient: !exists(json, "patient") ? undefined : PatientPatientFromJSON(json["patient"]),
  };
}

export function PostLinkedPatientResponseToJSON(object?: PostLinkedPatientResponse | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    custom_token: value.customToken,
    patient: PatientPatientToJSON(value.patient),
  };
}
