import { logger } from "@mgdx-libs/logger";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";

import { MediaDeviceManager } from "./MediaDeviceManager";

type MediaDeviceContextValue = {
  mediaDeviceManager: MediaDeviceManager;
  videoRef: React.MutableRefObject<HTMLVideoElement | null>;
};

const MediaDeviceContext = createContext<MediaDeviceContextValue>({
  videoRef: { current: null },
  mediaDeviceManager: {} as MediaDeviceManager,
});

type MediaDeviceProviderProps = React.PropsWithChildren<EmptyObject>;

export const MediaDeviceProvider: React.FC<MediaDeviceProviderProps> = ({ children }) => {
  const [mediaDeviceManager] = useState(() => {
    logger.debug("New MediaDeviceManager");
    return new MediaDeviceManager();
  });

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const value = useMemo<MediaDeviceContextValue>(
    () => ({
      mediaDeviceManager,
      videoRef,
    }),
    [mediaDeviceManager]
  );

  return <MediaDeviceContext.Provider value={value}>{children}</MediaDeviceContext.Provider>;
};

export const useMediaDevice = (): MediaDeviceContextValue => useContext(MediaDeviceContext);

export const useAutoFetchMediaStream = () => {
  const { mediaDeviceManager } = useMediaDevice();

  useEffect(() => {
    if (!mediaDeviceManager.isGetUserMediaSupported) return;
    mediaDeviceManager.fetchMediaStream();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useAutoStopMediaStream = () => {
  const { mediaDeviceManager, videoRef } = useMediaDevice();

  useEffect(() => {
    const video = videoRef.current;

    return () => {
      mediaDeviceManager.clearMediaStream();

      if (video) {
        video.srcObject = null;
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
