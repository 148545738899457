/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatientCustomBadRequest8,
  PatientCustomBadRequest8FromJSON,
  PatientCustomBadRequest8ToJSON,
  PatientMedicineNotebookPDF,
  PatientMedicineNotebookPDFFromJSON,
  PatientMedicineNotebookPDFToJSON,
} from "../models";

export interface GetMedicineNotebookPDFRequest {
  chainId: number;
  familyAccountId?: number;
}

export interface GetSharedMedicineNotebookPDFRequest {
  patientId: number;
  chainId: number;
  familyAccountId?: number;
}

export interface PostMedicineNotebookPDFRequest {
  chainId: number;
  familyAccountId?: number;
}

export interface PostSharedMedicineNotebookPDFRequest {
  patientId: number;
  chainId: number;
  familyAccountId?: number;
}

/**
 *
 */
export class MedicineNotebookPdfApi extends runtime.BaseAPI {
  /**
   * Get medicine notebook PDF by auth_id in JWT
   */
  async getMedicineNotebookPDFRaw(
    requestParameters: GetMedicineNotebookPDFRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookPDF>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getMedicineNotebookPDF."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/pdf`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookPDFFromJSON(jsonValue));
  }

  /**
   * Get medicine notebook PDF by auth_id in JWT
   */
  async getMedicineNotebookPDF(
    requestParameters: GetMedicineNotebookPDFRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookPDF> {
    return promiseRetry(
      (retry) =>
        this.getMedicineNotebookPDFRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Get medicine notebook PDF from shared medicine notebook
   */
  async getSharedMedicineNotebookPDFRaw(
    requestParameters: GetSharedMedicineNotebookPDFRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookPDF>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling getSharedMedicineNotebookPDF."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling getSharedMedicineNotebookPDF."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/pdf`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookPDFFromJSON(jsonValue));
  }

  /**
   * Get medicine notebook PDF from shared medicine notebook
   */
  async getSharedMedicineNotebookPDF(
    requestParameters: GetSharedMedicineNotebookPDFRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookPDF> {
    return promiseRetry(
      (retry) =>
        this.getSharedMedicineNotebookPDFRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register and update medicine notebook PDF by auth_id in JWT
   */
  async postMedicineNotebookPDFRaw(
    requestParameters: PostMedicineNotebookPDFRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookPDF>> {
    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postMedicineNotebookPDF."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patient/medicine-notebooks/{chain_id}/pdf/generate`.replace(
          `{${"chain_id"}}`,
          encodeURIComponent(String(requestParameters.chainId))
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookPDFFromJSON(jsonValue));
  }

  /**
   * Register and update medicine notebook PDF by auth_id in JWT
   */
  async postMedicineNotebookPDF(
    requestParameters: PostMedicineNotebookPDFRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookPDF> {
    return promiseRetry(
      (retry) =>
        this.postMedicineNotebookPDFRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register and update medicine notebook PDF from shared medicine notebook
   */
  async postSharedMedicineNotebookPDFRaw(
    requestParameters: PostSharedMedicineNotebookPDFRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientMedicineNotebookPDF>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        "patientId",
        "Required parameter requestParameters.patientId was null or undefined when calling postSharedMedicineNotebookPDF."
      );
    }

    if (requestParameters.chainId === null || requestParameters.chainId === undefined) {
      throw new runtime.RequiredError(
        "chainId",
        "Required parameter requestParameters.chainId was null or undefined when calling postSharedMedicineNotebookPDF."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.familyAccountId !== undefined) {
      queryParameters["family_account_id"] = requestParameters.familyAccountId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/patients/{patient_id}/medicine-notebooks/{chain_id}/pdf/generate`
          .replace(`{${"patient_id"}}`, encodeURIComponent(String(requestParameters.patientId)))
          .replace(`{${"chain_id"}}`, encodeURIComponent(String(requestParameters.chainId))),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientMedicineNotebookPDFFromJSON(jsonValue));
  }

  /**
   * Register and update medicine notebook PDF from shared medicine notebook
   */
  async postSharedMedicineNotebookPDF(
    requestParameters: PostSharedMedicineNotebookPDFRequest,
    initOverrides?: RequestInit
  ): Promise<PatientMedicineNotebookPDF> {
    return promiseRetry(
      (retry) =>
        this.postSharedMedicineNotebookPDFRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
