/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicCounselorBilling,
  ClinicCounselorBillingFromJSON,
  ClinicCounselorBillingFromJSONTyped,
  ClinicCounselorBillingToJSON,
  ClinicCounselorDoctor,
  ClinicCounselorDoctorFromJSON,
  ClinicCounselorDoctorFromJSONTyped,
  ClinicCounselorDoctorToJSON,
  ClinicCounselorPatient,
  ClinicCounselorPatientFromJSON,
  ClinicCounselorPatientFromJSONTyped,
  ClinicCounselorPatientToJSON,
  ClinicCounselorPharmacyCounseling,
  ClinicCounselorPharmacyCounselingFromJSON,
  ClinicCounselorPharmacyCounselingFromJSONTyped,
  ClinicCounselorPharmacyCounselingToJSON,
  ClinicCounselorTalkroom,
  ClinicCounselorTalkroomFromJSON,
  ClinicCounselorTalkroomFromJSONTyped,
  ClinicCounselorTalkroomToJSON,
  ClinicCounselorTime,
  ClinicCounselorTimeFromJSON,
  ClinicCounselorTimeFromJSONTyped,
  ClinicCounselorTimeToJSON,
  ClinicCounselorVideoChat,
  ClinicCounselorVideoChatFromJSON,
  ClinicCounselorVideoChatFromJSONTyped,
  ClinicCounselorVideoChatToJSON,
} from "./";

export const ClinicCounselorCounselingTypename = "ClinicCounselorCounseling" as const;

/**
 *
 * @export
 * @interface ClinicCounselorCounseling
 */
export interface ClinicCounselorCounseling {
  /**
   *
   * @type {boolean}
   * @memberof ClinicCounselorCounseling
   */
  alarmed?: boolean;
  /**
   *
   * @type {ClinicCounselorBilling}
   * @memberof ClinicCounselorCounseling
   */
  billing?: ClinicCounselorBilling;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorCounseling
   */
  clinicId: number;
  /**
   * not responded to patient
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  counselingNote?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  creditCardId?: string;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorCounseling
   */
  departmentId: number;
  /**
   *
   * @type {ClinicCounselorDoctor}
   * @memberof ClinicCounselorCounseling
   */
  doctor?: ClinicCounselorDoctor;
  /**
   *
   * @type {number}
   * @memberof ClinicCounselorCounseling
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  managementNo?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  note: string;
  /**
   *
   * @type {ClinicCounselorPatient}
   * @memberof ClinicCounselorCounseling
   */
  patient: ClinicCounselorPatient;
  /**
   *
   * @type {ClinicCounselorPharmacyCounseling}
   * @memberof ClinicCounselorCounseling
   */
  pharmacyCounseling: ClinicCounselorPharmacyCounseling;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  pharmacyNote?: string;
  /**
   *
   * @type {Array<ClinicCounselorTime>}
   * @memberof ClinicCounselorCounseling
   */
  preferredTimes?: Array<ClinicCounselorTime>;
  /**
   *
   * @type {object}
   * @memberof ClinicCounselorCounseling
   */
  remindAccepted?: object;
  /**
   *
   * @type {boolean}
   * @memberof ClinicCounselorCounseling
   */
  requestedByDoctor?: boolean;
  /**
   *
   * @type {Date}
   * @memberof ClinicCounselorCounseling
   */
  requestedTime?: string;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  status: ClinicCounselorCounselingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  statusDetail?: string;
  /**
   *
   * @type {ClinicCounselorTalkroom}
   * @memberof ClinicCounselorCounseling
   */
  talkroom?: ClinicCounselorTalkroom;
  /**
   *
   * @type {ClinicCounselorTime}
   * @memberof ClinicCounselorCounseling
   */
  time?: ClinicCounselorTime;
  /**
   *
   * @type {ClinicCounselorVideoChat}
   * @memberof ClinicCounselorCounseling
   */
  videoChat?: ClinicCounselorVideoChat;
  /**
   *
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  visit: ClinicCounselorCounselingVisitEnum;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicCounselorCounseling
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ClinicCounselorCounselingStatusEnum {
  Requested = "requested",
  Canceled = "canceled",
  Accepted = "accepted",
  Waiting = "waiting",
  CounselingStarted = "counseling_started",
  CounselingCompleted = "counseling_completed",
  BillingProcessing = "billing_processing",
  BillingCompleted = "billing_completed",
}
/**
 * @export
 * @enum {string}
 */
export enum ClinicCounselorCounselingVisitEnum {
  First = "first",
  Return = "return",
}

export function ClinicCounselorCounselingFromJSON(json: any): ClinicCounselorCounseling {
  return ClinicCounselorCounselingFromJSONTyped(json, false);
}

export function ClinicCounselorCounselingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ClinicCounselorCounseling {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicCounselorCounselingTypename,

    alarmed: !exists(json, "alarmed") ? undefined : json["alarmed"],
    billing: !exists(json, "billing") ? undefined : ClinicCounselorBillingFromJSON(json["billing"]),
    clinicId: json["clinic_id"],
    counselingNote: !exists(json, "counseling_note") ? undefined : json["counseling_note"],
    creditCardId: !exists(json, "credit_card_id") ? undefined : json["credit_card_id"],
    departmentId: json["department_id"],
    doctor: !exists(json, "doctor") ? undefined : ClinicCounselorDoctorFromJSON(json["doctor"]),
    id: json["id"],
    managementNo: !exists(json, "management_no") ? undefined : json["management_no"],
    note: json["note"],
    patient: ClinicCounselorPatientFromJSON(json["patient"]),
    pharmacyCounseling: ClinicCounselorPharmacyCounselingFromJSON(json["pharmacy_counseling"]),
    pharmacyNote: !exists(json, "pharmacy_note") ? undefined : json["pharmacy_note"],
    preferredTimes: !exists(json, "preferred_times")
      ? undefined
      : (json["preferred_times"] as Array<any>).map(ClinicCounselorTimeFromJSON),
    remindAccepted: !exists(json, "remind_accepted") ? undefined : json["remind_accepted"],
    requestedByDoctor: !exists(json, "requested_by_doctor") ? undefined : json["requested_by_doctor"],
    requestedTime:
      !exists(json, "requested_time") ||
      (typeof json["requested_time"] === "string" && json["requested_time"].substring(0, 4) === "0001")
        ? undefined
        : json["requested_time"],
    status: json["status"],
    statusDetail: !exists(json, "status_detail") ? undefined : json["status_detail"],
    talkroom: !exists(json, "talkroom") ? undefined : ClinicCounselorTalkroomFromJSON(json["talkroom"]),
    time: !exists(json, "time") ? undefined : ClinicCounselorTimeFromJSON(json["time"]),
    videoChat: !exists(json, "video_chat") ? undefined : ClinicCounselorVideoChatFromJSON(json["video_chat"]),
    visit: json["visit"],
  };
}

export function ClinicCounselorCounselingToJSON(object?: ClinicCounselorCounseling | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    alarmed: value.alarmed,
    billing: ClinicCounselorBillingToJSON(value.billing),
    clinic_id: value.clinicId,
    counseling_note: value.counselingNote,
    credit_card_id: value.creditCardId,
    department_id: value.departmentId,
    doctor: ClinicCounselorDoctorToJSON(value.doctor),
    id: value.id,
    management_no: value.managementNo,
    note: value.note,
    patient: ClinicCounselorPatientToJSON(value.patient),
    pharmacy_counseling: ClinicCounselorPharmacyCounselingToJSON(value.pharmacyCounseling),
    pharmacy_note: value.pharmacyNote,
    preferred_times:
      value.preferredTimes === undefined
        ? undefined
        : (value.preferredTimes as Array<any>).map(ClinicCounselorTimeToJSON),
    remind_accepted: value.remindAccepted,
    requested_by_doctor: value.requestedByDoctor,
    requested_time:
      value.requestedTime === undefined ? undefined : moment(value.requestedTime).tz("Asia/Tokyo").format(),
    status: value.status,
    status_detail: value.statusDetail,
    talkroom: ClinicCounselorTalkroomToJSON(value.talkroom),
    time: ClinicCounselorTimeToJSON(value.time),
    video_chat: ClinicCounselorVideoChatToJSON(value.videoChat),
    visit: value.visit,
  };
}
