/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Delivery API
 * API for Delivery
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  DeliveryClient,
  DeliveryClientFromJSON,
  DeliveryClientFromJSONTyped,
  DeliveryClientToJSON,
  DeliveryCounseling,
  DeliveryCounselingFromJSON,
  DeliveryCounselingFromJSONTyped,
  DeliveryCounselingToJSON,
  DeliveryDelivery,
  DeliveryDeliveryFromJSON,
  DeliveryDeliveryFromJSONTyped,
  DeliveryDeliveryToJSON,
  DeliveryDesiredTime,
  DeliveryDesiredTimeFromJSON,
  DeliveryDesiredTimeFromJSONTyped,
  DeliveryDesiredTimeToJSON,
  DeliveryPickup,
  DeliveryPickupFromJSON,
  DeliveryPickupFromJSONTyped,
  DeliveryPickupToJSON,
} from "./";

export const PostOrderRequestBodyTypename = "PostOrderRequestBody" as const;

/**
 *
 * @export
 * @interface PostOrderRequestBody
 */
export interface PostOrderRequestBody {
  /**
   *
   * @type {DeliveryCounseling}
   * @memberof PostOrderRequestBody
   */
  counseling?: DeliveryCounseling;
  /**
   *
   * @type {DeliveryDelivery}
   * @memberof PostOrderRequestBody
   */
  delivery?: DeliveryDelivery;
  /**
   *
   * @type {DeliveryDesiredTime}
   * @memberof PostOrderRequestBody
   */
  desiredTime?: DeliveryDesiredTime;
  /**
   *
   * @type {DeliveryClient}
   * @memberof PostOrderRequestBody
   */
  from?: DeliveryClient;
  /**
   *
   * @type {string}
   * @memberof PostOrderRequestBody
   */
  note?: string;
  /**
   *
   * @type {number}
   * @memberof PostOrderRequestBody
   */
  patientId?: number;
  /**
   *
   * @type {DeliveryPickup}
   * @memberof PostOrderRequestBody
   */
  pickup?: DeliveryPickup;
  /**
   *
   * @type {string}
   * @memberof PostOrderRequestBody
   */
  provider?: PostOrderRequestBodyProviderEnum;
  /**
   *
   * @type {DeliveryClient}
   * @memberof PostOrderRequestBody
   */
  to?: DeliveryClient;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostOrderRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PostOrderRequestBodyProviderEnum {
  Cbcloud = "cbcloud",
  Wolt = "wolt",
  Yamato = "yamato",
  YamatoStandard = "yamato_standard",
}

export function PostOrderRequestBodyFromJSON(json: any): PostOrderRequestBody {
  return PostOrderRequestBodyFromJSONTyped(json, false);
}

export function PostOrderRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostOrderRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostOrderRequestBodyTypename,

    counseling: !exists(json, "counseling") ? undefined : DeliveryCounselingFromJSON(json["counseling"]),
    delivery: !exists(json, "delivery") ? undefined : DeliveryDeliveryFromJSON(json["delivery"]),
    desiredTime: !exists(json, "desired_time") ? undefined : DeliveryDesiredTimeFromJSON(json["desired_time"]),
    from: !exists(json, "from") ? undefined : DeliveryClientFromJSON(json["from"]),
    note: !exists(json, "note") ? undefined : json["note"],
    patientId: !exists(json, "patient_id") ? undefined : json["patient_id"],
    pickup: !exists(json, "pickup") ? undefined : DeliveryPickupFromJSON(json["pickup"]),
    provider: !exists(json, "provider") ? undefined : json["provider"],
    to: !exists(json, "to") ? undefined : DeliveryClientFromJSON(json["to"]),
  };
}

export function PostOrderRequestBodyToJSON(object?: PostOrderRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    counseling: DeliveryCounselingToJSON(value.counseling),
    delivery: DeliveryDeliveryToJSON(value.delivery),
    desired_time: DeliveryDesiredTimeToJSON(value.desiredTime),
    from: DeliveryClientToJSON(value.from),
    note: value.note,
    patient_id: value.patientId,
    pickup: DeliveryPickupToJSON(value.pickup),
    provider: value.provider,
    to: DeliveryClientToJSON(value.to),
  };
}
