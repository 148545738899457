import hasLocalStorage from "@mgdx/shared/src/utils/hasLocalStorage";

export const clearPersist = (): void => {
  if (hasLocalStorage()) {
    const keys = Object.keys(localStorage).filter((key) => key.match("persist:"));

    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }
};
