/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyMedicineNotebookClientTypename = "PharmacyMedicineNotebookClient" as const;

/**
 *
 * @export
 * @interface PharmacyMedicineNotebookClient
 */
export interface PharmacyMedicineNotebookClient {
  /**
   *
   * @type {boolean}
   * @memberof PharmacyMedicineNotebookClient
   */
  hasMasterLicense?: boolean;
  /**
   *
   * @type {string}
   * @memberof PharmacyMedicineNotebookClient
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof PharmacyMedicineNotebookClient
   */
  secret?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyMedicineNotebookClient
   */
  readonly __typename?: string;
}

export function PharmacyMedicineNotebookClientFromJSON(json: any): PharmacyMedicineNotebookClient {
  return PharmacyMedicineNotebookClientFromJSONTyped(json, false);
}

export function PharmacyMedicineNotebookClientFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyMedicineNotebookClient {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyMedicineNotebookClientTypename,

    hasMasterLicense: !exists(json, "has_master_license") ? undefined : json["has_master_license"],
    key: !exists(json, "key") ? undefined : json["key"],
    secret: !exists(json, "secret") ? undefined : json["secret"],
  };
}

export function PharmacyMedicineNotebookClientToJSON(object?: PharmacyMedicineNotebookClient | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    has_master_license: value.hasMasterLicense,
    key: value.key,
    secret: value.secret,
  };
}
