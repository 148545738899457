import clsx from "clsx";
import React, { forwardRef, PropsWithChildren } from "react";

import * as styles from "./BaseInputMin.module.css";

export type BaseInputMinProps = PropsWithChildren<{
  disabled?: boolean;
  completed?: boolean;
  error?: boolean;
  isGroup?: boolean;
  noFrame?: boolean;
  className?: string;
}>;

export const BaseInputMin: React.FC<BaseInputMinProps> = forwardRef<HTMLFieldSetElement, BaseInputMinProps>(
  ({ error, disabled, completed, className, isGroup, noFrame, children, ...props }, ref) => (
    <fieldset
      ref={ref}
      disabled={disabled}
      className={clsx(
        styles.base,
        !noFrame && styles.withFrame,
        error && styles.error,
        completed && styles.completed,
        isGroup && styles.group,
        className
      )}
      {...props}
    >
      {children}
    </fieldset>
  )
);
