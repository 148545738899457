/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PayerCustomConflict,
  PayerCustomConflictFromJSON,
  PayerCustomConflictToJSON,
  PayerCustomForbidden,
  PayerCustomForbiddenFromJSON,
  PayerCustomForbiddenToJSON,
  PayerPayment,
  PayerPaymentFromJSON,
  PayerPaymentToJSON,
  PostPaymentDeleteRequestBody,
  PostPaymentDeleteRequestBodyFromJSON,
  PostPaymentDeleteRequestBodyToJSON,
  PostPaymentRequestBody,
  PostPaymentRequestBodyFromJSON,
  PostPaymentRequestBodyToJSON,
} from "../models";

export interface GetPaymentRequest {
  paymentId: string;
}

export interface PostDeletePaymentRequest {
  postPaymentDeleteRequestBody: PostPaymentDeleteRequestBody;
}

export interface PostPaymentsRequest {
  postPaymentRequestBody: PostPaymentRequestBody;
}

/**
 *
 */
export class PaymentApi extends runtime.BaseAPI {
  /**
   * Get payment
   */
  async getPaymentRaw(
    requestParameters: GetPaymentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerPayment>> {
    if (requestParameters.paymentId === null || requestParameters.paymentId === undefined) {
      throw new runtime.RequiredError(
        "paymentId",
        "Required parameter requestParameters.paymentId was null or undefined when calling getPayment."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/payments/{payment_id}`.replace(
          `{${"payment_id"}}`,
          encodeURIComponent(String(requestParameters.paymentId))
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerPaymentFromJSON(jsonValue));
  }

  /**
   * Get payment
   */
  async getPayment(requestParameters: GetPaymentRequest, initOverrides?: RequestInit): Promise<PayerPayment> {
    return promiseRetry(
      (retry) =>
        this.getPaymentRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Cancel payment
   */
  async postDeletePaymentRaw(
    requestParameters: PostDeletePaymentRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.postPaymentDeleteRequestBody === null ||
      requestParameters.postPaymentDeleteRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postPaymentDeleteRequestBody",
        "Required parameter requestParameters.postPaymentDeleteRequestBody was null or undefined when calling postDeletePayment."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/payments/delete`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostPaymentDeleteRequestBodyToJSON(requestParameters.postPaymentDeleteRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Cancel payment
   */
  async postDeletePayment(requestParameters: PostDeletePaymentRequest, initOverrides?: RequestInit): Promise<void> {
    await this.postDeletePaymentRaw(requestParameters, initOverrides);
  }

  /**
   * Accepts external event
   */
  async postPaymentEventsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/payment-events`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Accepts external event
   */
  async postPaymentEvents(initOverrides?: RequestInit): Promise<void> {
    await this.postPaymentEventsRaw(initOverrides);
  }

  /**
   * Register new payment transaction
   */
  async postPaymentsRaw(
    requestParameters: PostPaymentsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerPayment>> {
    if (requestParameters.postPaymentRequestBody === null || requestParameters.postPaymentRequestBody === undefined) {
      throw new runtime.RequiredError(
        "postPaymentRequestBody",
        "Required parameter requestParameters.postPaymentRequestBody was null or undefined when calling postPayments."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/payments`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostPaymentRequestBodyToJSON(requestParameters.postPaymentRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerPaymentFromJSON(jsonValue));
  }

  /**
   * Register new payment transaction
   */
  async postPayments(requestParameters: PostPaymentsRequest, initOverrides?: RequestInit): Promise<PayerPayment> {
    return promiseRetry(
      (retry) =>
        this.postPaymentsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
