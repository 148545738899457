import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const RefreshIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M107.999613,1107 C108.551898,1106.99979 108.999788,1107.44733 109.000002,1107.99961 C109.001588,1112.07446 106.265151,1115.64199 102.329112,1116.69652 C99.3361765,1117.49838 96.2175161,1116.69508 93.9997669,1114.71218 L94.0000019,1115.5 C94.0000019,1116.01284 93.6139598,1116.43551 93.1166211,1116.49327 L93.0000019,1116.5 C92.4871642,1116.5 92.0644928,1116.11396 92.0067277,1115.61662 L92.0000019,1115.5 L92.0000019,1112 C92.0000019,1111.48716 92.3860421,1111.06449 92.8833808,1111.00673 L93.0000019,1111 L96.0000019,1111 C96.5522847,1111 97.0000019,1111.44772 97.0000019,1112 C97.0000019,1112.51284 96.6139598,1112.93551 96.1166211,1112.99327 L96.0000019,1113 L95.0979127,1113.00096 C96.833017,1114.70294 99.3758046,1115.41722 101.811532,1114.76465 C104.872896,1113.94446 107.001236,1111.16972 107.000002,1108.00039 C106.999788,1107.4481 107.447329,1107.00022 107.999613,1107 Z M106.001236,1101.28871 L106,1100.5 C106,1099.98716 106.38604,1099.56449 106.883379,1099.50673 L107,1099.5 C107.512836,1099.5 107.935507,1099.88604 107.993272,1100.38338 L107.99999,1100.5 L107.99999,1104 C107.99999,1104.51284 107.613962,1104.93551 107.116623,1104.99327 L107.000002,1105 L104.000002,1105 C103.447717,1105 103.000002,1104.55228 103.000002,1104 C103.000002,1103.48716 103.386042,1103.06449 103.883381,1103.00673 L104.000002,1103 L104.90309,1103.00002 C103.167962,1101.29733 100.624666,1100.58265 98.1884714,1101.23535 C95.1271079,1102.05554 92.9987678,1104.83028 93.0000012,1107.99961 C93.0002162,1108.5519 92.5526752,1108.99978 92.0003905,1109 C91.4481058,1109.00021 91.0002163,1108.55267 90.9999897,1108.00039 C90.9984155,1103.92554 93.7348527,1100.35801 97.6708915,1099.30348 C100.664277,1098.5015 103.783425,1099.30516 106.001236,1101.28871 Z"
        transform="translate(-88 -1096)"
      />
    </svg>
  );
};

export default RefreshIcon;
