/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PutFollowUpThanksMessageRequestBodyTypename = "PutFollowUpThanksMessageRequestBody" as const;

/**
 *
 * @export
 * @interface PutFollowUpThanksMessageRequestBody
 */
export interface PutFollowUpThanksMessageRequestBody {
  /**
   *
   * @type {string}
   * @memberof PutFollowUpThanksMessageRequestBody
   */
  thanksMessage: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PutFollowUpThanksMessageRequestBody
   */
  readonly __typename?: string;
}

export function PutFollowUpThanksMessageRequestBodyFromJSON(json: any): PutFollowUpThanksMessageRequestBody {
  return PutFollowUpThanksMessageRequestBodyFromJSONTyped(json, false);
}

export function PutFollowUpThanksMessageRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PutFollowUpThanksMessageRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PutFollowUpThanksMessageRequestBodyTypename,

    thanksMessage: json["thanks_message"],
  };
}

export function PutFollowUpThanksMessageRequestBodyToJSON(object?: PutFollowUpThanksMessageRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    thanks_message: value.thanksMessage,
  };
}
