/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyPharmacyHours,
  PharmacyPharmacyHoursFromJSON,
  PharmacyPharmacyHoursFromJSONTyped,
  PharmacyPharmacyHoursToJSON,
} from "./";

export const PharmacyPharmacyBusinessHoursTypename = "PharmacyPharmacyBusinessHours" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyBusinessHours
 */
export interface PharmacyPharmacyBusinessHours {
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyBusinessHours
   */
  closedDays?: Array<string>;
  /**
   *
   * @type {PharmacyPharmacyHours}
   * @memberof PharmacyPharmacyBusinessHours
   */
  counselingReceptionHours?: PharmacyPharmacyHours;
  /**
   *
   * @type {PharmacyPharmacyHours}
   * @memberof PharmacyPharmacyBusinessHours
   */
  dispensingReceptionHours?: PharmacyPharmacyHours;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyBusinessHours
   */
  readonly __typename?: string;
}

export function PharmacyPharmacyBusinessHoursFromJSON(json: any): PharmacyPharmacyBusinessHours {
  return PharmacyPharmacyBusinessHoursFromJSONTyped(json, false);
}

export function PharmacyPharmacyBusinessHoursFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PharmacyPharmacyBusinessHours {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyBusinessHoursTypename,

    closedDays: !exists(json, "closed_days") ? undefined : json["closed_days"],
    counselingReceptionHours: !exists(json, "counseling_reception_hours")
      ? undefined
      : PharmacyPharmacyHoursFromJSON(json["counseling_reception_hours"]),
    dispensingReceptionHours: !exists(json, "dispensing_reception_hours")
      ? undefined
      : PharmacyPharmacyHoursFromJSON(json["dispensing_reception_hours"]),
  };
}

export function PharmacyPharmacyBusinessHoursToJSON(object?: PharmacyPharmacyBusinessHours | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    closed_days: value.closedDays,
    counseling_reception_hours: PharmacyPharmacyHoursToJSON(value.counselingReceptionHours),
    dispensing_reception_hours: PharmacyPharmacyHoursToJSON(value.dispensingReceptionHours),
  };
}
