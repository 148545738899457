import { getFirebaseFirestore, useCollection } from "@mgdx-libs/firebase";
import { collection, orderBy, query } from "firebase/firestore";

import { MESSAGE_SUB_COLLECTION_NAME, ROOM_COLLECTION_NAME } from "../constants";
import { messageConverter, TalkMessageDocumentWithId } from "../models";
import { UseTalkRoomDocument, useTalkRoomDocument } from "./useTalkRoomDocument";

export type UseTalkRoomAndMessageCollectionProps = {
  roomId: string;
};

export type UseTalkRoomAndMessageCollection = (props: UseTalkRoomAndMessageCollectionProps) => {
  messages?: TalkMessageDocumentWithId[];
  isLoading: boolean;
} & ReturnType<UseTalkRoomDocument>;

export const useTalkRoomAndMessageCollection: UseTalkRoomAndMessageCollection = ({ roomId }) => {
  const { room, isLoading: roomIsLoading } = useTalkRoomDocument({ roomId });

  const [messages, isLoading] = useCollection(
    query(
      collection(getFirebaseFirestore(), ROOM_COLLECTION_NAME, roomId, MESSAGE_SUB_COLLECTION_NAME),
      orderBy("timestamp", "asc")
    ).withConverter(messageConverter),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return {
    room,
    messages: messages?.docs.map((doc) => doc.data()),
    isLoading: roomIsLoading && isLoading,
  };
};
