/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyMeritTagsTypename = "PharmacyMeritTags" as const;

/**
 *
 * @export
 * @interface PharmacyMeritTags
 */
export interface PharmacyMeritTags {
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyMeritTags
   */
  normal?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PharmacyMeritTags
   */
  primary: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyMeritTags
   */
  teriary?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyMeritTags
   */
  readonly __typename?: string;
}

export function PharmacyMeritTagsFromJSON(json: any): PharmacyMeritTags {
  return PharmacyMeritTagsFromJSONTyped(json, false);
}

export function PharmacyMeritTagsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyMeritTags {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyMeritTagsTypename,

    normal: !exists(json, "normal") ? undefined : json["normal"],
    primary: json["primary"],
    teriary: !exists(json, "teriary") ? undefined : json["teriary"],
  };
}

export function PharmacyMeritTagsToJSON(object?: PharmacyMeritTags | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    normal: value.normal,
    primary: value.primary,
    teriary: value.teriary,
  };
}
