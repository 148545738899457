/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PatchFamilyAccountRequestBody,
  PatchFamilyAccountRequestBodyFromJSON,
  PatchFamilyAccountRequestBodyToJSON,
  PatientCustomBadRequest7,
  PatientCustomBadRequest7FromJSON,
  PatientCustomBadRequest7ToJSON,
  PatientFamilyAccount,
  PatientFamilyAccountFromJSON,
  PatientFamilyAccountToJSON,
  PostFamilyAccountRequestBody,
  PostFamilyAccountRequestBodyFromJSON,
  PostFamilyAccountRequestBodyToJSON,
  PostFamilyAccountsUpsertRequestBody,
  PostFamilyAccountsUpsertRequestBodyFromJSON,
  PostFamilyAccountsUpsertRequestBodyToJSON,
} from "../models";

export interface CreateFamilyAccountRequest {
  postFamilyAccountRequestBody: PostFamilyAccountRequestBody;
}

export interface DeleteFamilyAccountRequest {
  familyAccountId: number;
}

export interface UpdateFamilyAccountRequest {
  familyAccountId: number;
  patchFamilyAccountRequestBody: PatchFamilyAccountRequestBody;
}

export interface UpsertFamilyAccountRequest {
  postFamilyAccountsUpsertRequestBody: PostFamilyAccountsUpsertRequestBody;
}

/**
 *
 */
export class FamilyAccountApi extends runtime.BaseAPI {
  /**
   * register family account
   */
  async createFamilyAccountRaw(
    requestParameters: CreateFamilyAccountRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientFamilyAccount>> {
    if (
      requestParameters.postFamilyAccountRequestBody === null ||
      requestParameters.postFamilyAccountRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postFamilyAccountRequestBody",
        "Required parameter requestParameters.postFamilyAccountRequestBody was null or undefined when calling createFamilyAccount."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-accounts`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostFamilyAccountRequestBodyToJSON(requestParameters.postFamilyAccountRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientFamilyAccountFromJSON(jsonValue));
  }

  /**
   * register family account
   */
  async createFamilyAccount(
    requestParameters: CreateFamilyAccountRequest,
    initOverrides?: RequestInit
  ): Promise<PatientFamilyAccount> {
    return promiseRetry(
      (retry) =>
        this.createFamilyAccountRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * delete family account
   */
  async deleteFamilyAccountRaw(
    requestParameters: DeleteFamilyAccountRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.familyAccountId === null || requestParameters.familyAccountId === undefined) {
      throw new runtime.RequiredError(
        "familyAccountId",
        "Required parameter requestParameters.familyAccountId was null or undefined when calling deleteFamilyAccount."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-accounts/{family_account_id}`.replace(
          `{${"family_account_id"}}`,
          encodeURIComponent(String(requestParameters.familyAccountId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * delete family account
   */
  async deleteFamilyAccount(requestParameters: DeleteFamilyAccountRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteFamilyAccountRaw(requestParameters, initOverrides);
  }

  /**
   * update family account
   */
  async updateFamilyAccountRaw(
    requestParameters: UpdateFamilyAccountRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PatientFamilyAccount>> {
    if (requestParameters.familyAccountId === null || requestParameters.familyAccountId === undefined) {
      throw new runtime.RequiredError(
        "familyAccountId",
        "Required parameter requestParameters.familyAccountId was null or undefined when calling updateFamilyAccount."
      );
    }

    if (
      requestParameters.patchFamilyAccountRequestBody === null ||
      requestParameters.patchFamilyAccountRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "patchFamilyAccountRequestBody",
        "Required parameter requestParameters.patchFamilyAccountRequestBody was null or undefined when calling updateFamilyAccount."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-accounts/{family_account_id}`.replace(
          `{${"family_account_id"}}`,
          encodeURIComponent(String(requestParameters.familyAccountId))
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: PatchFamilyAccountRequestBodyToJSON(requestParameters.patchFamilyAccountRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PatientFamilyAccountFromJSON(jsonValue));
  }

  /**
   * update family account
   */
  async updateFamilyAccount(
    requestParameters: UpdateFamilyAccountRequest,
    initOverrides?: RequestInit
  ): Promise<PatientFamilyAccount> {
    return promiseRetry(
      (retry) =>
        this.updateFamilyAccountRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * register and patch family accounts
   */
  async upsertFamilyAccountRaw(
    requestParameters: UpsertFamilyAccountRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<Array<PatientFamilyAccount>>> {
    if (
      requestParameters.postFamilyAccountsUpsertRequestBody === null ||
      requestParameters.postFamilyAccountsUpsertRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "postFamilyAccountsUpsertRequestBody",
        "Required parameter requestParameters.postFamilyAccountsUpsertRequestBody was null or undefined when calling upsertFamilyAccount."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/family-accounts/upsert`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PostFamilyAccountsUpsertRequestBodyToJSON(requestParameters.postFamilyAccountsUpsertRequestBody),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PatientFamilyAccountFromJSON));
  }

  /**
   * register and patch family accounts
   */
  async upsertFamilyAccount(
    requestParameters: UpsertFamilyAccountRequest,
    initOverrides?: RequestInit
  ): Promise<Array<PatientFamilyAccount>> {
    return promiseRetry(
      (retry) =>
        this.upsertFamilyAccountRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
