import clsx from "clsx";
import React, { HTMLAttributes, PropsWithChildren } from "react";

import * as styles from "./MessagePlain.module.css";

export type Variant = "info" | "weak" | "success" | "notice" | "caution";
type TextAlign = "left" | "center" | "right";
type FontWeight = "normal" | "bold";

export type MessagePlainProps = HTMLAttributes<HTMLDivElement> &
  PropsWithChildren<{
    id?: string;
    variant: Variant;
    textAlign?: TextAlign;
    fontWeight?: FontWeight;
    withPaddingBottom?: boolean;
    className?: string;
    responsive?: boolean;
  }>;

export const MessagePlain: React.FC<MessagePlainProps> = ({
  id,
  variant,
  textAlign,
  fontWeight,
  withPaddingBottom,
  className,
  responsive,
  children,
  ...props
}) => (
  <div
    id={id}
    className={clsx(
      styles.message,
      variant === "info" && styles.info,
      variant === "weak" && styles.weak,
      variant === "success" && styles.success,
      variant === "notice" && styles.notice,
      variant === "caution" && styles.caution,
      textAlign === "left" && styles.textLeft,
      textAlign === "center" && styles.textCenter,
      textAlign === "right" && styles.textRight,
      fontWeight === "normal" && styles.fontNormal,
      fontWeight === "bold" && styles.fontBold,
      withPaddingBottom && styles.withPaddingBottom,
      responsive && styles.responsive,
      className
    )}
    {...props}
  >
    {children}
  </div>
);
