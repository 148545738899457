/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const ClinicDoctorTypename = "ClinicDoctor" as const;

/**
 *
 * @export
 * @interface ClinicDoctor
 */
export interface ClinicDoctor {
  /**
   *
   * @type {string}
   * @memberof ClinicDoctor
   */
  authId: string;
  /**
   *
   * @type {string}
   * @memberof ClinicDoctor
   */
  clinicEmail: string;
  /**
   *
   * @type {number}
   * @memberof ClinicDoctor
   */
  clinicId: number;
  /**
   *
   * @type {string}
   * @memberof ClinicDoctor
   */
  clinicName: string;
  /**
   *
   * @type {string}
   * @memberof ClinicDoctor
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ClinicDoctor
   */
  firstName: string;
  /**
   *
   * @type {number}
   * @memberof ClinicDoctor
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof ClinicDoctor
   */
  initialPasswordChanged?: boolean;
  /**
   *
   * @type {string}
   * @memberof ClinicDoctor
   */
  lastName: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicDoctor
   */
  readonly __typename?: string;
}

export function ClinicDoctorFromJSON(json: any): ClinicDoctor {
  return ClinicDoctorFromJSONTyped(json, false);
}

export function ClinicDoctorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicDoctor {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicDoctorTypename,

    authId: json["auth_id"],
    clinicEmail: json["clinic_email"],
    clinicId: json["clinic_id"],
    clinicName: json["clinic_name"],
    email: json["email"],
    firstName: json["first_name"],
    id: json["id"],
    initialPasswordChanged: !exists(json, "initial_password_changed") ? undefined : json["initial_password_changed"],
    lastName: json["last_name"],
  };
}

export function ClinicDoctorToJSON(object?: ClinicDoctor | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    auth_id: value.authId,
    clinic_email: value.clinicEmail,
    clinic_id: value.clinicId,
    clinic_name: value.clinicName,
    email: value.email,
    first_name: value.firstName,
    id: value.id,
    initial_password_changed: value.initialPasswordChanged,
    last_name: value.lastName,
  };
}
