/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PayerCreditCard,
  PayerCreditCardFromJSON,
  PayerCreditCardToJSON,
  PayerCreditCardRegisterRequest,
  PayerCreditCardRegisterRequestFromJSON,
  PayerCreditCardRegisterRequestToJSON,
  PayerCustomForbidden,
  PayerCustomForbiddenFromJSON,
  PayerCustomForbiddenToJSON,
} from "../models";

export interface DeleteCreditCardRequest {
  creditCardId: string;
}

export interface PostCreditCardsRequest {
  payerCreditCardRegisterRequest: PayerCreditCardRegisterRequest;
}

export interface PutCreditCardRequest {
  creditCardId: string;
  payerCreditCardRegisterRequest: PayerCreditCardRegisterRequest;
}

/**
 *
 */
export class CreditCardApi extends runtime.BaseAPI {
  /**
   * Delete credit card
   */
  async deleteCreditCardRaw(
    requestParameters: DeleteCreditCardRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.creditCardId === null || requestParameters.creditCardId === undefined) {
      throw new runtime.RequiredError(
        "creditCardId",
        "Required parameter requestParameters.creditCardId was null or undefined when calling deleteCreditCard."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/credit-cards/{credit_card_id}`.replace(
          `{${"credit_card_id"}}`,
          encodeURIComponent(String(requestParameters.creditCardId))
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete credit card
   */
  async deleteCreditCard(requestParameters: DeleteCreditCardRequest, initOverrides?: RequestInit): Promise<void> {
    await this.deleteCreditCardRaw(requestParameters, initOverrides);
  }

  /**
   * Get credit cards
   */
  async getCreditCardsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PayerCreditCard>>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/credit-cards`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PayerCreditCardFromJSON));
  }

  /**
   * Get credit cards
   */
  async getCreditCards(initOverrides?: RequestInit): Promise<Array<PayerCreditCard>> {
    return promiseRetry(
      (retry) =>
        this.getCreditCardsRaw(initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register new credit card
   */
  async postCreditCardsRaw(
    requestParameters: PostCreditCardsRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerCreditCard>> {
    if (
      requestParameters.payerCreditCardRegisterRequest === null ||
      requestParameters.payerCreditCardRegisterRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "payerCreditCardRegisterRequest",
        "Required parameter requestParameters.payerCreditCardRegisterRequest was null or undefined when calling postCreditCards."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/credit-cards`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: PayerCreditCardRegisterRequestToJSON(requestParameters.payerCreditCardRegisterRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerCreditCardFromJSON(jsonValue));
  }

  /**
   * Register new credit card
   */
  async postCreditCards(
    requestParameters: PostCreditCardsRequest,
    initOverrides?: RequestInit
  ): Promise<PayerCreditCard> {
    return promiseRetry(
      (retry) =>
        this.postCreditCardsRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Edit credit card
   */
  async putCreditCardRaw(
    requestParameters: PutCreditCardRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<PayerCreditCard>> {
    if (requestParameters.creditCardId === null || requestParameters.creditCardId === undefined) {
      throw new runtime.RequiredError(
        "creditCardId",
        "Required parameter requestParameters.creditCardId was null or undefined when calling putCreditCard."
      );
    }

    if (
      requestParameters.payerCreditCardRegisterRequest === null ||
      requestParameters.payerCreditCardRegisterRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "payerCreditCardRegisterRequest",
        "Required parameter requestParameters.payerCreditCardRegisterRequest was null or undefined when calling putCreditCard."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/credit-cards/{credit_card_id}`.replace(
          `{${"credit_card_id"}}`,
          encodeURIComponent(String(requestParameters.creditCardId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PayerCreditCardRegisterRequestToJSON(requestParameters.payerCreditCardRegisterRequest),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => PayerCreditCardFromJSON(jsonValue));
  }

  /**
   * Edit credit card
   */
  async putCreditCard(requestParameters: PutCreditCardRequest, initOverrides?: RequestInit): Promise<PayerCreditCard> {
    return promiseRetry(
      (retry) =>
        this.putCreditCardRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }
}
