/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  PharmacyPostPharmacyListImage,
  PharmacyPostPharmacyListImageFromJSON,
  PharmacyPostPharmacyListImageFromJSONTyped,
  PharmacyPostPharmacyListImageToJSON,
} from "./";

export const PostPharmacyListImagesRequestBodyTypename = "PostPharmacyListImagesRequestBody" as const;

/**
 *
 * @export
 * @interface PostPharmacyListImagesRequestBody
 */
export interface PostPharmacyListImagesRequestBody {
  /**
   *
   * @type {Array<PharmacyPostPharmacyListImage>}
   * @memberof PostPharmacyListImagesRequestBody
   */
  listImageData?: Array<PharmacyPostPharmacyListImage>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostPharmacyListImagesRequestBody
   */
  readonly __typename?: string;
}

export function PostPharmacyListImagesRequestBodyFromJSON(json: any): PostPharmacyListImagesRequestBody {
  return PostPharmacyListImagesRequestBodyFromJSONTyped(json, false);
}

export function PostPharmacyListImagesRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostPharmacyListImagesRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostPharmacyListImagesRequestBodyTypename,

    listImageData: !exists(json, "list_image_data")
      ? undefined
      : (json["list_image_data"] as Array<any>).map(PharmacyPostPharmacyListImageFromJSON),
  };
}

export function PostPharmacyListImagesRequestBodyToJSON(object?: PostPharmacyListImagesRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    list_image_data:
      value.listImageData === undefined
        ? undefined
        : (value.listImageData as Array<any>).map(PharmacyPostPharmacyListImageToJSON),
  };
}
