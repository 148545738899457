// extracted by mini-css-extract-plugin
export var base = "BaseButton-module--base--03c89";
export var caution = "BaseButton-module--caution--00565";
export var disabled = "BaseButton-module--disabled--300c8";
export var fab = "BaseButton-module--fab--780b8";
export var fontSizeM = "BaseButton-module--fontSizeM--c8cb8";
export var fontSizeS = "BaseButton-module--fontSizeS--95f20";
export var fontSizeXM = "BaseButton-module--fontSizeXM--21ceb";
export var fullViewportWidth = "BaseButton-module--fullViewportWidth--43a23";
export var fullWidth = "BaseButton-module--fullWidth--3b7cb";
export var large = "BaseButton-module--large--f1948";
export var leftIcon = "BaseButton-module--leftIcon--7db55";
export var menu = "BaseButton-module--menu--4e814";
export var ovalNormal = "BaseButton-module--ovalNormal--40208";
export var ovalPrimary = "BaseButton-module--ovalPrimary--39cda";
export var ovalSecondary = "BaseButton-module--ovalSecondary--b7c25";
export var ovalSecondaryKey = "BaseButton-module--ovalSecondaryKey--55f88";
export var ovalWeak = "BaseButton-module--ovalWeak--95133";
export var paragraph = "BaseButton-module--paragraph--22a40";
export var paragraphLight = "BaseButton-module--paragraphLight--150cf";
export var paragraphLink = "BaseButton-module--paragraphLink--25989";
export var paragraphMixed = "BaseButton-module--paragraphMixed--cbdca";
export var paragraphPickUp = "BaseButton-module--paragraphPickUp--3e1f1";
export var paragraphPrimary = "BaseButton-module--paragraphPrimary--4a4c8";
export var paragraphWeak = "BaseButton-module--paragraphWeak--16fda";
export var primary = "BaseButton-module--primary--249e7";
export var primaryActive = "BaseButton-module--primaryActive--4a38c";
export var primaryWeak = "BaseButton-module--primaryWeak--3c965";
export var primaryWeakKey = "BaseButton-module--primaryWeakKey--398c6";
export var responsive = "BaseButton-module--responsive--b7d27";
export var rightIcon = "BaseButton-module--rightIcon--a46bb";
export var secondary = "BaseButton-module--secondary--32366";
export var select = "BaseButton-module--select--55727";
export var selectLight = "BaseButton-module--selectLight--e0c0f";
export var small = "BaseButton-module--small--789a8";
export var stretchHeight = "BaseButton-module--stretchHeight--2e5f4";
export var stretchWidth = "BaseButton-module--stretchWidth--e6184";
export var tertiary = "BaseButton-module--tertiary--019be";
export var tertiaryLight = "BaseButton-module--tertiaryLight--35515";
export var tertiaryLink = "BaseButton-module--tertiaryLink--5044a";
export var text = "BaseButton-module--text--ae894";
export var textInnerLink = "BaseButton-module--textInnerLink--ee3ca";
export var textLink = "BaseButton-module--textLink--0c40f";
export var unselect = "BaseButton-module--unselect--f5090";
export var unselectLight = "BaseButton-module--unselectLight--9c6ce";