import { createFirestoreDataConverter, TypeWithId } from "@mgdx-libs/firebase";

export enum SenderTypeEnum {
  Patient = "patient",
  Clinic = "clinic",
  Pharmacy = "pharmacy",
}

export type TalkMessageDocumentImage = {
  name: string;
  path: string;
  url: string;
  height: number;
  width: number;
};

export type TalkMessageDocumentBody = {
  content?: string;
  image?: TalkMessageDocumentImage;
};

export type TalkMessageDocument = {
  senderType: SenderTypeEnum;
  sentAt: string;
  timestamp: number;
  body: TalkMessageDocumentBody;
};

export type TalkMessageDocumentWithId = TypeWithId<TalkMessageDocument>;

export const messageConverter = createFirestoreDataConverter<TalkMessageDocument>();
