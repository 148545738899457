/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  GetTemporaryClosedDatesResponse,
  GetTemporaryClosedDatesResponseFromJSON,
  GetTemporaryClosedDatesResponseToJSON,
  PutTemporaryClosedDatesRequestBody,
  PutTemporaryClosedDatesRequestBodyFromJSON,
  PutTemporaryClosedDatesRequestBodyToJSON,
} from "../models";

export interface GetTemporaryClosedDatesRequest {
  clinicId: number;
  departmentId: number;
  from: string;
  to: string;
}

export interface PutTemporaryClosedDatesRequest {
  clinicId: number;
  departmentId: number;
  from: string;
  to: string;
  putTemporaryClosedDatesRequestBody: PutTemporaryClosedDatesRequestBody;
}

/**
 *
 */
export class TemporaryClosedDateApi extends runtime.BaseAPI {
  /**
   * Get counseling closed dates
   */
  async getTemporaryClosedDatesRaw(
    requestParameters: GetTemporaryClosedDatesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetTemporaryClosedDatesResponse>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling getTemporaryClosedDates."
      );
    }

    if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
      throw new runtime.RequiredError(
        "departmentId",
        "Required parameter requestParameters.departmentId was null or undefined when calling getTemporaryClosedDates."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling getTemporaryClosedDates."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling getTemporaryClosedDates."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = requestParameters.from;
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = requestParameters.to;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments/{department_id}/temporary-closed-dates`
          .replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId)))
          .replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId))),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => GetTemporaryClosedDatesResponseFromJSON(jsonValue));
  }

  /**
   * Get counseling closed dates
   */
  async getTemporaryClosedDates(
    requestParameters: GetTemporaryClosedDatesRequest,
    initOverrides?: RequestInit
  ): Promise<GetTemporaryClosedDatesResponse> {
    return promiseRetry(
      (retry) =>
        this.getTemporaryClosedDatesRaw(requestParameters, initOverrides)
          .catch(async (error: Response) => {
            if (error.status === 401) {
              await forceRefreshAccessToken();
              retry(error);
            }
            throw error;
          })
          .then(async (response) => {
            return await (response as any).value();
          }),
      {
        // The maximum amount of times to retry the operation. Default is 10.
        // retries: 20,
        // The exponential factor to use. Default is 2.
        // factor: 2,
        // The number of milliseconds before starting the first retry. Default is 1000.
        // minTimeout: 1000,
        // The maximum number of milliseconds between two retries. Default is Infinity.
        // maxTimeout: 60000,
        // Randomizes the timeouts by multiplying with a factor between 1 to 2. Default is false.
        // randomize: false,
      }
    ).catch((error) => {
      throw error;
    });
  }

  /**
   * Register and delete counseling closed dates
   */
  async putTemporaryClosedDatesRaw(
    requestParameters: PutTemporaryClosedDatesRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.clinicId === null || requestParameters.clinicId === undefined) {
      throw new runtime.RequiredError(
        "clinicId",
        "Required parameter requestParameters.clinicId was null or undefined when calling putTemporaryClosedDates."
      );
    }

    if (requestParameters.departmentId === null || requestParameters.departmentId === undefined) {
      throw new runtime.RequiredError(
        "departmentId",
        "Required parameter requestParameters.departmentId was null or undefined when calling putTemporaryClosedDates."
      );
    }

    if (requestParameters.from === null || requestParameters.from === undefined) {
      throw new runtime.RequiredError(
        "from",
        "Required parameter requestParameters.from was null or undefined when calling putTemporaryClosedDates."
      );
    }

    if (requestParameters.to === null || requestParameters.to === undefined) {
      throw new runtime.RequiredError(
        "to",
        "Required parameter requestParameters.to was null or undefined when calling putTemporaryClosedDates."
      );
    }

    if (
      requestParameters.putTemporaryClosedDatesRequestBody === null ||
      requestParameters.putTemporaryClosedDatesRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putTemporaryClosedDatesRequestBody",
        "Required parameter requestParameters.putTemporaryClosedDatesRequestBody was null or undefined when calling putTemporaryClosedDates."
      );
    }

    const queryParameters: any = {};

    if (requestParameters.from !== undefined) {
      queryParameters["from"] = requestParameters.from;
    }

    if (requestParameters.to !== undefined) {
      queryParameters["to"] = requestParameters.to;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/clinics/{clinic_id}/departments/{department_id}/temporary-closed-dates`
          .replace(`{${"clinic_id"}}`, encodeURIComponent(String(requestParameters.clinicId)))
          .replace(`{${"department_id"}}`, encodeURIComponent(String(requestParameters.departmentId))),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutTemporaryClosedDatesRequestBodyToJSON(requestParameters.putTemporaryClosedDatesRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Register and delete counseling closed dates
   */
  async putTemporaryClosedDates(
    requestParameters: PutTemporaryClosedDatesRequest,
    initOverrides?: RequestInit
  ): Promise<void> {
    await this.putTemporaryClosedDatesRaw(requestParameters, initOverrides);
  }
}
