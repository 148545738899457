/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatchFamilyAccountRequestBodyTypename = "PatchFamilyAccountRequestBody" as const;

/**
 *
 * @export
 * @interface PatchFamilyAccountRequestBody
 */
export interface PatchFamilyAccountRequestBody {
  /**
   *
   * @type {string}
   * @memberof PatchFamilyAccountRequestBody
   */
  dateOfBirth?: string;
  /**
   *
   * @type {object}
   * @memberof PatchFamilyAccountRequestBody
   */
  externalIds?: object;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyAccountRequestBody
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyAccountRequestBody
   */
  gender?: PatchFamilyAccountRequestBodyGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyAccountRequestBody
   */
  insuranceCard?: string;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyAccountRequestBody
   */
  kanaFirstName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyAccountRequestBody
   */
  kanaLastName?: string;
  /**
   *
   * @type {string}
   * @memberof PatchFamilyAccountRequestBody
   */
  lastName?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PatchFamilyAccountRequestBody
   */
  otherMedicalImages?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatchFamilyAccountRequestBody
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatchFamilyAccountRequestBodyGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PatchFamilyAccountRequestBodyFromJSON(json: any): PatchFamilyAccountRequestBody {
  return PatchFamilyAccountRequestBodyFromJSONTyped(json, false);
}

export function PatchFamilyAccountRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatchFamilyAccountRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatchFamilyAccountRequestBodyTypename,

    dateOfBirth: !exists(json, "date_of_birth") ? undefined : json["date_of_birth"],
    externalIds: !exists(json, "external_ids") ? undefined : json["external_ids"],
    firstName: !exists(json, "first_name") ? undefined : json["first_name"],
    gender: !exists(json, "gender") ? undefined : json["gender"],
    insuranceCard: !exists(json, "insurance_card") ? undefined : json["insurance_card"],
    kanaFirstName: !exists(json, "kana_first_name") ? undefined : json["kana_first_name"],
    kanaLastName: !exists(json, "kana_last_name") ? undefined : json["kana_last_name"],
    lastName: !exists(json, "last_name") ? undefined : json["last_name"],
    otherMedicalImages: !exists(json, "other_medical_images") ? undefined : json["other_medical_images"],
  };
}

export function PatchFamilyAccountRequestBodyToJSON(object?: PatchFamilyAccountRequestBody | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    external_ids: value.externalIds,
    first_name: value.firstName,
    gender: value.gender,
    insurance_card: value.insuranceCard,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    other_medical_images: value.otherMedicalImages,
  };
}
