/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PharmacyPharmacyHoursTypename = "PharmacyPharmacyHours" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacyHours
 */
export interface PharmacyPharmacyHours {
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyHours
   */
  fri?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyHours
   */
  mon?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyHours
   */
  publicHoliday?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyHours
   */
  sat?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyHours
   */
  sun?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyHours
   */
  thu?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyHours
   */
  tue?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PharmacyPharmacyHours
   */
  wed?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacyHours
   */
  readonly __typename?: string;
}

export function PharmacyPharmacyHoursFromJSON(json: any): PharmacyPharmacyHours {
  return PharmacyPharmacyHoursFromJSONTyped(json, false);
}

export function PharmacyPharmacyHoursFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPharmacyHours {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmacyHoursTypename,

    fri: !exists(json, "fri") ? undefined : json["fri"],
    mon: !exists(json, "mon") ? undefined : json["mon"],
    publicHoliday: !exists(json, "public_holiday") ? undefined : json["public_holiday"],
    sat: !exists(json, "sat") ? undefined : json["sat"],
    sun: !exists(json, "sun") ? undefined : json["sun"],
    thu: !exists(json, "thu") ? undefined : json["thu"],
    tue: !exists(json, "tue") ? undefined : json["tue"],
    wed: !exists(json, "wed") ? undefined : json["wed"],
  };
}

export function PharmacyPharmacyHoursToJSON(object?: PharmacyPharmacyHours | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    fri: value.fri,
    mon: value.mon,
    public_holiday: value.publicHoliday,
    sat: value.sat,
    sun: value.sun,
    thu: value.thu,
    tue: value.tue,
    wed: value.wed,
  };
}
