/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy Counselor API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "@mgdx-libs/moment";
import promiseRetry from "promise-retry";
import { forceRefreshAccessToken } from "@mgdx-libs/firebase";
import * as runtime from "../runtime";
import {
  PutCounselingNoteRequestBody,
  PutCounselingNoteRequestBodyFromJSON,
  PutCounselingNoteRequestBodyToJSON,
} from "../models";

export interface PutCounselingNoteRequest {
  counselingId: number;
  putCounselingNoteRequestBody: PutCounselingNoteRequestBody;
}

/**
 *
 */
export class CounselingNoteApi extends runtime.BaseAPI {
  /**
   * Update counseling note
   */
  async putCounselingNoteRaw(
    requestParameters: PutCounselingNoteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.counselingId === null || requestParameters.counselingId === undefined) {
      throw new runtime.RequiredError(
        "counselingId",
        "Required parameter requestParameters.counselingId was null or undefined when calling putCounselingNote."
      );
    }

    if (
      requestParameters.putCounselingNoteRequestBody === null ||
      requestParameters.putCounselingNoteRequestBody === undefined
    ) {
      throw new runtime.RequiredError(
        "putCounselingNoteRequestBody",
        "Required parameter requestParameters.putCounselingNoteRequestBody was null or undefined when calling putCounselingNote."
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token("Bearer", []);

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/counselings/{counseling_id}/counseling-note`.replace(
          `{${"counseling_id"}}`,
          encodeURIComponent(String(requestParameters.counselingId))
        ),
        method: "PUT",
        headers: headerParameters,
        query: queryParameters,
        body: PutCounselingNoteRequestBodyToJSON(requestParameters.putCounselingNoteRequestBody),
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update counseling note
   */
  async putCounselingNote(requestParameters: PutCounselingNoteRequest, initOverrides?: RequestInit): Promise<void> {
    await this.putCounselingNoteRaw(requestParameters, initOverrides);
  }
}
