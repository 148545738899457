import clsx from "clsx";
import React from "react";

import * as styles from "./SvgIcon.module.css";

export const sizes = ["xs", "s", "m", "xm", "xxm", "l"] as const;

export const variants = ["primary", "secondary", "tertiary", "text_link", "key"] as const;

export type Props = React.SVGProps<SVGSVGElement> & {
  size?: (typeof sizes)[number];
  variant?: (typeof variants)[number];
};

const FileImageIcon: React.FC<Props> = ({ className, size = "m", variant, ...props }) => {
  const fill = props.fill ?? "inherit";
  return (
    <svg
      fill={fill}
      role={props["aria-label"] ? "img" : undefined}
      aria-hidden={props["aria-hidden"] ?? !props["aria-label"] ? true : undefined}
      className={clsx(
        size === "xs" && styles.iconSizeXS,
        size === "s" && styles.iconSizeS,
        size === "m" && styles.iconSizeM,
        size === "xm" && styles.iconSizeXM,
        size === "xxm" && styles.iconSizeXXM,
        size === "l" && styles.iconSizeL,
        variant === "primary" && styles.iconPrimary,
        variant === "secondary" && styles.iconSecondary,
        variant === "tertiary" && styles.iconTertiary,
        variant === "text_link" && styles.iconLink,
        variant === "key" && styles.iconKey,
        className
      )}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M157,107 L157,93 C157,91.9 156.1,91 155,91 L141,91 C139.9,91 139,91.9 139,93 L139,107 C139,108.1 139.9,109 141,109 L155,109 C156.1,109 157,108.1 157,107 Z M144.9,101.98 L147,104.51 L150.1,100.52 C150.3,100.26 150.7,100.26 150.9,100.53 L154.41,105.21 C154.66,105.54 154.42,106.01 154.01,106.01 L142.02,106.01 C141.6,106.01 141.37,105.53 141.63,105.2 L144.12,102 C144.31,101.74 144.69,101.73 144.9,101.98 Z"
        transform="translate(-136 -88)"
      />
    </svg>
  );
};

export default FileImageIcon;
