/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Payer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PayerCreditCardPaymentTypename = "PayerCreditCardPayment" as const;

/**
 *
 * @export
 * @interface PayerCreditCardPayment
 */
export interface PayerCreditCardPayment {
  /**
   *
   * @type {string}
   * @memberof PayerCreditCardPayment
   */
  cardId?: string;
  /**
   *
   * @type {string}
   * @memberof PayerCreditCardPayment
   */
  token?: string;
  /**
   * Resource type name
   * @type {string}
   * @memberof PayerCreditCardPayment
   */
  readonly __typename?: string;
}

export function PayerCreditCardPaymentFromJSON(json: any): PayerCreditCardPayment {
  return PayerCreditCardPaymentFromJSONTyped(json, false);
}

export function PayerCreditCardPaymentFromJSONTyped(json: any, ignoreDiscriminator: boolean): PayerCreditCardPayment {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PayerCreditCardPaymentTypename,

    cardId: !exists(json, "card_id") ? undefined : json["card_id"],
    token: !exists(json, "token") ? undefined : json["token"],
  };
}

export function PayerCreditCardPaymentToJSON(object?: PayerCreditCardPayment | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    card_id: value.cardId,
    token: value.token,
  };
}
