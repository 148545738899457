import { RequestActionMeta } from "@redux-requests/core";

type RequestActionRequest<Data> = {
  promise?: Promise<Data>;
  response?: {
    data: Data;
  };
};

type RequestAction<Data, TransformedData> = {
  request: RequestActionRequest<Data>;
  meta?: RequestActionMeta<Data, TransformedData>;
  type: string;
};

export const createRequestAction = <Params, Data, TransformedData = Data>(
  name: string,
  action: (
    params: Params,
    meta?: RequestActionMeta<Data, TransformedData>
  ) => Omit<RequestAction<Data, TransformedData>, "type">
): ((params: Params, meta?: RequestActionMeta<Data, TransformedData>) => RequestAction<Data, TransformedData>) => {
  const actionCreator = (params: Params, meta?: RequestActionMeta<Data, TransformedData>) => ({
    type: name,
    ...action(params, meta),
  });

  actionCreator.toString = () => name;

  return actionCreator;
};
