/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PatientPostFamilyAccountUpsertTypename = "PatientPostFamilyAccountUpsert" as const;

/**
 *
 * @export
 * @interface PatientPostFamilyAccountUpsert
 */
export interface PatientPostFamilyAccountUpsert {
  /**
   *
   * @type {string}
   * @memberof PatientPostFamilyAccountUpsert
   */
  dateOfBirth: string;
  /**
   *
   * @type {object}
   * @memberof PatientPostFamilyAccountUpsert
   */
  externalIds?: object;
  /**
   *
   * @type {string}
   * @memberof PatientPostFamilyAccountUpsert
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof PatientPostFamilyAccountUpsert
   */
  gender: PatientPostFamilyAccountUpsertGenderEnum;
  /**
   *
   * @type {string}
   * @memberof PatientPostFamilyAccountUpsert
   */
  insuranceCard?: string;
  /**
   *
   * @type {string}
   * @memberof PatientPostFamilyAccountUpsert
   */
  kanaFirstName: string;
  /**
   *
   * @type {string}
   * @memberof PatientPostFamilyAccountUpsert
   */
  kanaLastName: string;
  /**
   *
   * @type {string}
   * @memberof PatientPostFamilyAccountUpsert
   */
  lastName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PatientPostFamilyAccountUpsert
   */
  otherMedicalImages?: Array<string>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PatientPostFamilyAccountUpsert
   */
  readonly __typename?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum PatientPostFamilyAccountUpsertGenderEnum {
  Male = "male",
  Female = "female",
  None = "none",
}

export function PatientPostFamilyAccountUpsertFromJSON(json: any): PatientPostFamilyAccountUpsert {
  return PatientPostFamilyAccountUpsertFromJSONTyped(json, false);
}

export function PatientPostFamilyAccountUpsertFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PatientPostFamilyAccountUpsert {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PatientPostFamilyAccountUpsertTypename,

    dateOfBirth: json["date_of_birth"],
    externalIds: !exists(json, "external_ids") ? undefined : json["external_ids"],
    firstName: json["first_name"],
    gender: json["gender"],
    insuranceCard: !exists(json, "insurance_card") ? undefined : json["insurance_card"],
    kanaFirstName: json["kana_first_name"],
    kanaLastName: json["kana_last_name"],
    lastName: json["last_name"],
    otherMedicalImages: !exists(json, "other_medical_images") ? undefined : json["other_medical_images"],
  };
}

export function PatientPostFamilyAccountUpsertToJSON(object?: PatientPostFamilyAccountUpsert | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    date_of_birth: value.dateOfBirth,
    external_ids: value.externalIds,
    first_name: value.firstName,
    gender: value.gender,
    insurance_card: value.insuranceCard,
    kana_first_name: value.kanaFirstName,
    kana_last_name: value.kanaLastName,
    last_name: value.lastName,
    other_medical_images: value.otherMedicalImages,
  };
}
