/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Patient API
 * API for Patient
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";

export const PostMedicineNotebookShareIssueRequestBodyTypename = "PostMedicineNotebookShareIssueRequestBody" as const;

/**
 *
 * @export
 * @interface PostMedicineNotebookShareIssueRequestBody
 */
export interface PostMedicineNotebookShareIssueRequestBody {
  /**
   *
   * @type {Array<number>}
   * @memberof PostMedicineNotebookShareIssueRequestBody
   */
  familyAccountIds?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof PostMedicineNotebookShareIssueRequestBody
   */
  withMyself?: boolean;
  /**
   * Resource type name
   * @type {string}
   * @memberof PostMedicineNotebookShareIssueRequestBody
   */
  readonly __typename?: string;
}

export function PostMedicineNotebookShareIssueRequestBodyFromJSON(
  json: any
): PostMedicineNotebookShareIssueRequestBody {
  return PostMedicineNotebookShareIssueRequestBodyFromJSONTyped(json, false);
}

export function PostMedicineNotebookShareIssueRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PostMedicineNotebookShareIssueRequestBody {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PostMedicineNotebookShareIssueRequestBodyTypename,

    familyAccountIds: !exists(json, "family_account_ids") ? undefined : json["family_account_ids"],
    withMyself: !exists(json, "with_myself") ? undefined : json["with_myself"],
  };
}

export function PostMedicineNotebookShareIssueRequestBodyToJSON(
  object?: PostMedicineNotebookShareIssueRequestBody | null
): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    family_account_ids: value.familyAccountIds,
    with_myself: value.withMyself,
  };
}
