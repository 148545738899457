/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Clinic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import {
  ClinicDepartmentFeed,
  ClinicDepartmentFeedFromJSON,
  ClinicDepartmentFeedFromJSONTyped,
  ClinicDepartmentFeedToJSON,
} from "./";

export const ClinicDepartmentFeedsTypename = "ClinicDepartmentFeeds" as const;

/**
 *
 * @export
 * @interface ClinicDepartmentFeeds
 */
export interface ClinicDepartmentFeeds {
  /**
   *
   * @type {Array<ClinicDepartmentFeed>}
   * @memberof ClinicDepartmentFeeds
   */
  departments: Array<ClinicDepartmentFeed>;
  /**
   * Resource type name
   * @type {string}
   * @memberof ClinicDepartmentFeeds
   */
  readonly __typename?: string;
}

export function ClinicDepartmentFeedsFromJSON(json: any): ClinicDepartmentFeeds {
  return ClinicDepartmentFeedsFromJSONTyped(json, false);
}

export function ClinicDepartmentFeedsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClinicDepartmentFeeds {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: ClinicDepartmentFeedsTypename,

    departments: (json["departments"] as Array<any>).map(ClinicDepartmentFeedFromJSON),
  };
}

export function ClinicDepartmentFeedsToJSON(object?: ClinicDepartmentFeeds | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    departments: (value.departments as Array<any>).map(ClinicDepartmentFeedToJSON),
  };
}
