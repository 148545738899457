/* tslint:disable */
/* eslint-disable */
/**
 * MG-DX Pharmacy API
 * API for Pharmacy
 *
 * The version of the OpenAPI document: 0.2.0
 * Contact: developers@mg-dx.co.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import moment from "moment-timezone";
import { exists, mapValues } from "../runtime";
import { PharmacyPharmacy, PharmacyPharmacyFromJSON, PharmacyPharmacyFromJSONTyped, PharmacyPharmacyToJSON } from "./";

export const PharmacyPharmaciesTypename = "PharmacyPharmacies" as const;

/**
 *
 * @export
 * @interface PharmacyPharmacies
 */
export interface PharmacyPharmacies {
  /**
   *
   * @type {number}
   * @memberof PharmacyPharmacies
   */
  count?: number;
  /**
   *
   * @type {Array<PharmacyPharmacy>}
   * @memberof PharmacyPharmacies
   */
  pharmacies: Array<PharmacyPharmacy>;
  /**
   * Resource type name
   * @type {string}
   * @memberof PharmacyPharmacies
   */
  readonly __typename?: string;
}

export function PharmacyPharmaciesFromJSON(json: any): PharmacyPharmacies {
  return PharmacyPharmaciesFromJSONTyped(json, false);
}

export function PharmacyPharmaciesFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyPharmacies {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    __typename: PharmacyPharmaciesTypename,

    count: !exists(json, "count") ? undefined : json["count"],
    pharmacies: (json["pharmacies"] as Array<any>).map(PharmacyPharmacyFromJSON),
  };
}

export function PharmacyPharmaciesToJSON(object?: PharmacyPharmacies | null): any {
  if (object === undefined) {
    return undefined;
  }
  if (object === null) {
    return null;
  }
  const { __typename, ...value } = object;
  return {
    count: value.count,
    pharmacies: (value.pharmacies as Array<any>).map(PharmacyPharmacyToJSON),
  };
}
